import {ICONS} from './Icons';

export let sideMenus = [
  {
    id: 1,
    title: 'Get Started',
    icon: ICONS.home_Icon,
    sub_menus: [],
    isShow: true,
    href: '/',
  },
  {
    id: 2,
    title: 'Orders',
    icon: ICONS.orderIcon,
    sub_menus: [],
    isShow: true,
    href: '/orders',
  },
  {
    id: 3,
    title: 'Accounts',
    icon: ICONS.account_Icon,
    sub_menus: [
      {
        id: 1,
        title: 'Accounts',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/accounts',
      },
      {
        id: 2,
        title: 'Account Categories',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/account-category',
      },
      {
        id: 3,
        title: 'Account Attributes',
        icon: ICONS.seoIcon,
        isShow: true,
        href: '/account-attributes',
      },
      {
        id: 4,
        title: 'Account Configuration',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/account-configs',
      },
      // {
      //   id: 5,
      //   title: 'Report Accounts',
      //   icon: ICONS.reportedIcon,
      //   sub_menus: [],
      //   isShow: true,
      //   href: '/reported-accounts',
      // },
    ],
    isShow: true,
  },
  {
    id: 4,
    title: 'Listings',
    icon: ICONS.listing_Icon,
    sub_menus: [
      {
        id: 1,
        title: 'Listings',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/listings',
      },
      {
        id: 2,
        title: 'Categories',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/listing-category',
      },
      {
        id: 3,
        title: 'Custom Attributes',
        icon: ICONS.seoIcon,
        isShow: true,
        href: '/listing-attributes',
      },
      {
        id: 4,
        title: 'Variant Types',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/listing-variants',
      },
      {
        id: 5,
        title: 'Additional Features',
        icon: ICONS.nativeintegrationIcon,
        isShow: true,
        href: '/listing-configs',
      },
      {
        id: 6,
        title: 'Reported Listings',
        icon: ICONS.reportedIcon,
        sub_menus: [],
        isShow: true,
        href: '/reported-listings',
      },
    ],
    isShow: true,
  },
  {
    id: 5,
    title: 'Users',
    icon: ICONS.usersIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Users',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/users',
      },
      {
        id: 2,
        title: 'User Attributes',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/user-attributes',
      },
      {
        id: 3,
        title: 'Reviews & Ratings',
        icon: ICONS.reviewIcon,
        sub_menus: [],
        isShow: true,
        href: '/review-ratings',
      },
    ],
    isShow: true,
  },
  {
    id: 6,
    title: 'Report',
    icon: ICONS.reportIcon,
    sub_menus: [],
    isShow: true,
    href: '/reports',
  },
  {
    id: 7,
    title: 'Marketing',
    icon: ICONS.adBanner_Icon,
    sub_menus: [
      {
        id: 0,
        title: 'Collections',
        icon: ICONS.collectionsIcon,
        isShow: true,
        href: '/collections',
      },
      {
        id: 1,
        title: 'Promotions',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/promotions',
      },
      {
        id: 2,
        title: 'Coupons',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/coupon',
      },
    ],
    isShow: true,
  },

  {
    id: 8,
    title: 'Finance',
    icon: ICONS.currencyIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Transactions',
        icon: ICONS.transactionIcon,
        isShow: true,
        href: '/transaction',
      },
      // {
      //   id: 2,
      //   title: 'Settlement',
      //   icon: ICONS.transactionIcon,
      //   isShow: false,
      //   href: '/transaction',
      // },
    ],
    isShow: true,
  },

  {
    id: 10,
    title: 'Media',
    icon: ICONS.mediaIcon,
    sub_menus: [],
    isShow: true,
    href: '/media',
  },

  {
    id: 16,
    title: 'Communication',
    icon: ICONS.communicationIcon,
    sub_menus: [],
    isShow: true,
    href: '/communications',
  },
  {
    id: 11,
    title: 'Web',
    icon: ICONS.webIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Website Editor',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/editor',
        isOpenNewTab: true,
      },
      {
        id: 2,
        title: 'Change Templates',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/templates',
        isOpenNewTab: true,
      },
      // {
      //   id: 3,
      //   title: 'Customise SEO',
      //   icon: ICONS.seoIcon,
      //   isShow: true,
      //   href: '/seo',
      // },
      {
        id: 4,
        title: 'Pages',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/p',
        isOpenNewTab: true,
      },
      {
        id: 5,
        title: 'Blog',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/blog',
        isOpenNewTab: true,
      },
      {
        id: 6,
        title: 'Customise texts',
        icon: ICONS.appTranslateIcon,
        isShow: true,
        href: '/strings-customisation',
      },
      {
        id: 7,
        title: 'Add Integrations',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: 'https://tradly.app/workspace/integrations',
        isOpenNewTab: true,
      },
      {
        id: 8,
        title: 'Domain',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/domain',
        isOpenNewTab: true,
      },
    ],
    isShow: true,
  },

  {
    id: 12,
    title: 'App',
    icon: ICONS.appIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Buy App Templates',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/templates',
        isOpenNewTab: true,
      },
      {
        id: 2,
        title: 'Customisation',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/app-customization',
      },
      {
        id: 3,
        title: 'Integrations',
        icon: ICONS.nativeintegrationIcon,
        isShow: true,
        href: '/app-integration',
      },
      {
        id: 4,
        title: 'Customise texts',
        icon: ICONS.appTranslateIcon,
        isShow: true,
        href: '/strings-customisation',
      },
    ],
    isShow: true,
  },
  {
    id: 17,
    title: '',
    icon: ICONS.arrowFromLeft,
    sub_menus: [],
    isShow: true,
    href: undefined,
  },

  //   {id: 16, title: 'More', icon: moreIcon, sub_menus: [], isShow: true},
];

export const fixed_menus = [
  {
    id: 19,
    title: 'Invite Team',
    icon: ICONS.inviteTeamIcon,
    sub_menus: [],
    isShow: true,
    href: 'https://tradly.app/workspace/user-management',
    isOpenNewTab: true,
    feature_activated: true,
  },
  {
    id: 20,
    title: 'Help',
    icon: ICONS.help_Icon,
    sub_menus: [
      {
        id: 1,
        title: 'Documentation',
        icon: ICONS.documentationIcon,
        isShow: true,
        href: 'https://tradly.app/docs/setup',
        isOpenNewTab: true,
        feature_activated: true,
        is_fixed: true,
      },
      {
        id: 2,
        title: 'Community',
        icon: ICONS.communityIcon,
        isShow: true,
        href: 'https://community.tradly.app/',
        isOpenNewTab: true,
        feature_activated: true,
        is_fixed: true,
      },
      {
        id: 3,
        title: 'API Reference',
        icon: ICONS.apiRefIcon,
        isShow: true,
        href: 'https://developer.tradly.app/',
        isOpenNewTab: true,
        feature_activated: true,
        is_fixed: true,
      },
    ],
    isShow: true,
    href: undefined,
  },
  {
    id: 15,
    title: 'Settings',
    icon: ICONS.settingIcon,
    sub_menus: [],
    isShow: true,
    href: '/settings',
  },
];

export const AllMenus = [
  {
    id: 1,
    title: 'Get Started',
    icon: ICONS.home_Icon,
    sub_menus: [],
    isShow: true,
    href: '/',
    key: 'get_started',
  },
  {
    id: 2,
    title: 'Orders',
    icon: ICONS.orderIcon,
    sub_menus: [],
    isShow: true,
    href: '/orders',
    key: 'orders',
  },
  {
    id: 3,
    title: 'Accounts',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: '/accounts',
    key: 'accounts',
  },
  {
    id: 4,
    title: 'Account Categories',
    icon: ICONS.mobileIcon,
    isShow: true,
    href: '/account-category',
    key: 'account_categories',
  },
  {
    id: 5,
    title: 'Account Attributes',
    icon: ICONS.seoIcon,
    isShow: true,
    href: '/account-attributes',
    key: 'account_attributes',
  },
  {
    id: 6,
    title: 'Account Configuration',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: '/account-configs',
    key: 'account_configuration',
  },
  {
    id: 7,
    title: 'Listings',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: '/listings',
    key: 'listings',
  },
  {
    id: 8,
    title: 'Categories',
    icon: ICONS.mobileIcon,
    isShow: true,
    href: '/listing-category',
    key: 'listing_categories',
  },
  {
    id: 9,
    title: 'Custom Attributes',
    icon: ICONS.seoIcon,
    isShow: true,
    href: '/listing-attributes',
    key: 'custom_attributes',
  },
  {
    id: 10,
    title: 'Variant Types',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: '/listing-variants',
    key: 'variant_types',
  },
  {
    id: 11,
    title: 'Additional Features',
    icon: ICONS.nativeintegrationIcon,
    isShow: true,
    href: '/listing-configs',
    key: 'additional_features',
  },
  {
    id: 12,
    title: 'Reported Listings',
    icon: ICONS.reportedIcon,
    sub_menus: [],
    isShow: true,
    href: '/reported-listings',
    key: 'reported_listings',
  },
  {
    id: 13,
    title: 'Users',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: '/users',
    key: 'users',
  },
  {
    id: 14,
    title: 'User Attributes',
    icon: ICONS.mobileIcon,
    isShow: true,
    href: '/user-attributes',
    key: 'user_attributes',
  },
  {
    id: 15,
    title: 'Reviews & Ratings',
    icon: ICONS.reviewIcon,
    sub_menus: [],
    isShow: true,
    href: '/review-ratings',
    key: 'reviews_ratings',
  },
  {
    id: 16,
    title: 'Report',
    icon: ICONS.reportIcon,
    sub_menus: [],
    isShow: true,
    href: '/reports',
    key: 'reports',
  },
  {
    id: 17,
    title: 'Collections',
    icon: ICONS.collectionsIcon,
    isShow: true,
    href: '/collections',
    key: 'collections',
  },
  {
    id: 18,
    title: 'Promotions',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: '/promotions',
    key: 'promotions',
  },
  {
    id: 19,
    title: 'Coupons',
    icon: ICONS.mobileIcon,
    isShow: true,
    href: '/coupon',
    key: 'coupons',
  },
  {
    id: 20,
    title: 'Transactions',
    icon: ICONS.transactionIcon,
    isShow: true,
    href: '/transaction',
    key: 'transactions',
  },
  {
    id: 21,
    title: 'Media',
    icon: ICONS.mediaIcon,
    sub_menus: [],
    isShow: true,
    href: '/media',
    key: 'media',
  },
  {
    id: 22,
    title: 'Communication',
    icon: ICONS.communicationIcon,
    sub_menus: [],
    isShow: true,
    href: '/communications',
    key: 'communication',
  },
  {
    id: 23,
    title: 'Website Editor',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: 'https://tradly.app/editor',
    isOpenNewTab: true,
    key: 'website_editor',
  },
  {
    id: 24,
    title: 'Change Templates',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: 'https://tradly.app/workspace/templates',
    isOpenNewTab: true,
    key: 'change_templates',
  },
  {
    id: 25,
    title: 'Pages',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: 'https://tradly.app/workspace/p',
    isOpenNewTab: true,
    key: 'pages',
  },
  {
    id: 26,
    title: 'Blog',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: 'https://tradly.app/workspace/blog',
    isOpenNewTab: true,
    key: 'blog',
  },
  {
    id: 27,
    title: 'Customise texts',
    icon: ICONS.appTranslateIcon,
    isShow: true,
    href: '/strings-customisation',
    key: 'customise_texts',
  },
  {
    id: 28,
    title: 'Add Integrations',
    icon: ICONS.mobileIcon,
    isShow: true,
    href: 'https://tradly.app/workspace/integrations',
    isOpenNewTab: true,
    key: 'add_integrations',
  },
  {
    id: 29,
    title: 'Domain',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: 'https://tradly.app/workspace/domain',
    isOpenNewTab: true,
    key: 'domain',
  },
  {
    id: 30,
    title: 'Buy App Templates',
    icon: ICONS.iconGlobal,
    isShow: true,
    href: 'https://tradly.app/workspace/templates',
    isOpenNewTab: true,
    key: 'buy_app_templates',
  },
  {
    id: 31,
    title: 'Customisation',
    icon: ICONS.mobileIcon,
    isShow: true,
    href: '/app-customization',
    key: 'customisation',
  },
  {
    id: 32,
    title: 'Integrations',
    icon: ICONS.nativeintegrationIcon,
    isShow: true,
    href: '/app-integration',
    key: 'integrations',
  },

  {
    name: 'Platform Setup',
    description: '',
    title: 'Checkout & Cart Attributes',
    id: 22,
    icon: ICONS.seoIcon,
    href: '/cart-attributes',
    is_permission_needed: false,
  },
  {
    name: 'Business Operations',
    description: '',
    title: 'Service / Shipping',
    subTitle: '',
    id: 10,
    icon: ICONS.shippingMethodIcon,
    href: '/delivery',
  },
  {
    name: 'Business Operations',
    description: '',
    title: 'Payments',
    subTitle: '',
    id: 11,
    icon: ICONS.paymentMethodIcon,
    href: '/payments',
  },
  {
    name: 'Business Operations',
    description: '',
    title: 'Commissions',
    subTitle: '',
    id: 12,
    icon: ICONS.commissionsIcon,
    href: '/commission',
  },
  {
    name: 'Business Operations',
    description: '',
    title: 'Subscription',
    subTitle: '',
    id: 13,
    icon: ICONS.subscriptionsIcon,
    href: '/subscription',
  },
  {
    name: 'Business Operations',
    description: '',
    title: 'Taxes',
    subTitle: '',
    id: 20,
    icon: ICONS.commissionsIcon,
    href: '/taxes',
  },
  {
    name: 'Business Operations',
    description: '',
    title: 'Special Fees',
    subTitle: '',
    id: 14,
    icon: ICONS.specialFeesIcon,
    href: '/special-fees',
  },
  {
    name: 'System Setup',
    description: '',
    title: 'Languages',
    subTitle: '',
    id: 15,
    icon: ICONS.languagecon,
    href: '/languages',
  },
  {
    name: 'System Setup',
    description: '',
    title: 'Currency',
    subTitle: '',
    id: 16,
    icon: ICONS.multiCurrencyIcon,
    href: '/currency',
  },
  {
    name: 'System Setup',
    description: '',
    title: 'Countries',
    subTitle: '',
    id: 17,
    icon: ICONS.mobileIcon,
    href: '/countries',
  },
  {
    name: 'System Setup',
    description: '',
    title: 'API',
    subTitle: '',
    id: 18,
    icon: ICONS.apiIcon,
    href: '/api',
  },
];
