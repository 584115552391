import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import {dateConversionFromTimeStamp} from '../../HelperClasses/SingletonClass';
import ListingDetails from '../../MainListing/ListingDetail';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import GoogleMapReact from 'google-map-react';
import AppConstants from '../../Constants/AppConstants';
import DetailStyleSheet from '../../StyleSheets/DetailStyleSheet';
import { Gradient } from 'react-gradient';
import { AppToast } from '../../HelperClasses/AppToast';
import DeliveryStatusEnum from '../../Models/DeliveryStatusEnum';
import DeliveryOrderDetail from '../Orders/DeliveryOrderDetail';
import { addMixpanelEvent, MixpanelEvent } from '../../Models/MixPannelEnum';
import radioCheckIcon from '../../assets/radioCheck.png';
import radioUncheckIcon from '../../assets/radioUncheck.png';
import closeIcon from '../../assets/closeBlackIcon.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height - 120;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class RosterDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      showOrderDetail:false,
      orderArray:[],
      orderID:'',
      driversArray:[],
      showAssignDriverBool:false,
      driverID: '',
      rosterDetailData:{},
    }
  }
  static propTypes = {
    rosterID:PropTypes.any,
    backBtnAction: PropTypes.func,
    rosterDetailData: PropTypes.any,
  };
  componentDidMount() {
    this.state.rosterDetailData = this.props.rosterDetailData
    this.loadRosterDetailApi()
    this.loadDriverApi()
  }
  loadRosterDetailApi = async (type) => {
    console.log('roster detail', this.props.rosterDetailData)
    var path = `/${this.props.rosterDetailData['id']}`
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryRoster + path, 'get')
    console.log('responseJson', responseJson)
    if (responseJson['status'] == true) {
      var rosterD = responseJson['data']['roster']
      this.state.rosterDetailData = rosterD
      this.state.orderArray = rosterD['orders']
      console.log(rosterD,'rosterD')
      this.setState({ dataLoad: true })
    }
    this.setState({ dataLoad: true })
  }
  loadDriverApi = async () => {
    this.setState({ dataLoad: false });
    var path = `?page=${1}&per_page=${100}&type=driver`
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriver + path, 'get')
    this.setState({ driversArray: [] });
    if (responseJson['status'] == true) {
      var driverD = responseJson['data']['users']
      this.setState({ stopPage: false });
      for (let objc of driverD) {
        this.state.driversArray.push(objc)
      }
    }
    this.setState({ dataLoad: true });
  }
  assignDriverAPI = async () => {
    this.setState({ dataLoad: false })
    let dict = {'driver_id': this.state.driverID}
    let statusDic = JSON.stringify(dict)
    let path =  APPURL.URLPaths.deliveryRoster + `/${this.props.rosterDetailData['id']}/assign_driver`
    const responseJson = await networkService.networkCall(path, 'PATCH',statusDic)
    this.setState({ dataLoad: true })
    if (responseJson['status'] == true) {
      this.mixpannelAssignDriver(true)
      this.loadRosterDetailApi()
      AppToast()
    }else {
      this.mixpannelAssignDriver(false)
      AppToast(responseJson)
    }
    this.setState({dataLoad: true})
  }
  mixpannelAssignDriver(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.assignDriver,properties)
  }
  // Action Buttons
  viewOrderBtnAction = id => {
    this.setState({ showOrderDetail: true, orderID: id });
  }
  assignDriverBtnAction() {
    this.assignDriverAPI()
    this.setState({showAssignDriverBool: false})
  }
  closeAssignDriverViewBtnAction() {
    this.setState({showAssignDriverBool:false,driverID: ''})
  }
  didSelectAssignDriver(id) {
    if (this.state.driverID == id) {
      this.setState({driverID: ''})
    }else {
      this.setState({driverID:id})
    }
  }
  // UI Renders
  titleLblRender = ({first, second}) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={appConfigStyle.titleTxtStyle}>{`${first} `}</Text>
        <Text style={{marginTop: 2}}>{second}</Text>
      </View>
    )
  }
  renderUpdateBtn = () => {
    var title =  'Assign Driver' 
    return <View style={tableStyle.secondButtonViewStyle}>
        <TouchableOpacity 
          onPress={() => this.assignDriverBtnAction()}>
          <Text style={tableStyle.secondBtnTextStyle}>{title}</Text>
        </TouchableOpacity>
    </View>
  }
  renderChangeOrderSatatusView = () => {
    var views = []
    if (this.state.showAssignDriverBool) {
      for (let a = 0; a < this.state.driversArray.length; a++) {
        let item = this.state.driversArray[a]
        let name = item['name']
        let check = this.state.driverID == item['id'] ? radioCheckIcon : radioUncheckIcon
        views.push(<View>
          <TouchableOpacity style={styles.statusItemViewStyle} onPress={() => this.didSelectAssignDriver(item['id'])}>
            <Text style={tableStyle.columnViewTitleStyle}>{name}</Text>
            <Image style={{ height: 16, width: 16 }} source={check} />
          </TouchableOpacity>
        </View>)
      }
    } 
    let title = 'Assign Driver' 
    var  check = this.state.showAssignDriverBool

    return <View style={[tableStyle.passwordViewStyle, { display:check ? 'flex' : 'none' }]}>
        <View style={{ backgroundColor: colors.AppWhite, paddingBottom:0, width: 300, borderRadius: 10,alignSelf:'center'}}>
          <View style={{ margin: 16 }}>
            <View style={{flexDirection:'row', justifyContent: 'space-between'}}>
              <Text style={[CommonStyleSheet.plainTextStyle,{alignSelf: 'center'}]}>{title}</Text>
              <TouchableOpacity onPress={() => this.closeAssignDriverViewBtnAction()}>
                <Image style={{height:20,width:20, marginTop:2}} source={closeIcon}/> 
              </TouchableOpacity>
            </View>
            <View style={{ marginTop: 30, height:300 }}>
              <ScrollView>
              {views}
              </ScrollView>
            </View>
            <View style={{ alignSelf: 'center', margin: 5, marginTop: 20, paddingLeft: 10,zIndex:10 }}>
              {this.renderUpdateBtn()}
            </View>
          </View>
        </View>
    </View>
  }
  renderChangeDriverBtn = () => {
    let showUpdateBtn = this.state.rosterDetailData['status'] < 2 ? true : false
    let name = this.state.rosterDetailData['driver'] ? this.state.rosterDetailData['driver']['name'] : ''
    let title = name.length == 0 ? 'Assign Driver' : 'Change Driver'
    return <View style={[tableStyle.gradientViewBGStyle,{marginTop:-5, display: showUpdateBtn ? 'flex': 'none',marginLeft:20}]}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.setState({showAssignDriverBool:true})}>
          <Text style={[tableStyle.saveTxtStyle,{textAlign:'center'}]}>{title}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
 
  renderDriverDetail = () => {
    let name = this.state.rosterDetailData['driver'] ? this.state.rosterDetailData['driver']['name'] : ''
    let mobile = this.state.rosterDetailData['driver'] ? this.state.rosterDetailData['driver']['mobile'] : ''
    return (<View>
      <Text style={tableStyle.mainTitile}>Driver Detail</Text>
      <View style={{ display: name.length == 0 ? 'none' : 'flex' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20, marginTop: 0 }}>
          <View style={{ margin: 5,marginLeft:0 }}>
            <this.titleLblRender first={'Name:'} second={name} />
            <View style={{ height: 16 }} />
            <this.titleLblRender first={'Mobile:'} second={mobile} />
          </View>
        </View>
      </View>
      {this.renderChangeDriverBtn()}
      <View style={{ height: 10 }} />
    </View>)
  }
  renderBranchDetail = () => {
    return (<View style={[commonStyle.mainView, { zIndex: 3 }]}>
      <View style={{justifyContent:'space-between',flexDirection:'row'}}>
        <View>
          <Text style={tableStyle.mainTitile}> Branch Detail</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20, marginTop: 0 }}>
            <View style={{ margin: 5 }}>
              <this.titleLblRender first={'Branch Id:'} second={this.props.rosterDetailData['branch']['id']} />
              <View style={{ height: 16 }} />
              <this.titleLblRender first={'Name:'} second={this.props.rosterDetailData['branch']['name']} />
            </View>
          </View>
        </View>
       {this.renderDriverDetail()}
      </View>
    </View>)
  }
  renderOrderListView = () => {
    return <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>
  }
  renderOrderDetail = () => {
    return (<View style={[commonStyle.mainView, { zIndex: 3 }]}>
      <View>
        <Text style={tableStyle.mainTitile}> Order Detail</Text>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20, marginTop: 0 }}>
          {this.renderOrderListView()}
        </View>
      </View>
    </View>)
  }

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
    var views = [];
    let orderList = this.state.orderArray
    if (orderList != undefined){
      for (let i = 0; i < orderList.length; i++) {
        let status = DeliveryStatusEnum.status(orderList[i]['status']);
        let amount = `${orderList[i]['currency']} ${orderList[i]['amount']}`
        views.push(
          <FlatList
            data={[
              orderList[i]['order_id'],
              orderList[i]['customer']['name'],
              orderList[i]['customer']['mobile'],
              status,
              orderList[i]['driver'] ? orderList[i]['driver']['name'] : '-',
              orderList[i]['driver'] ? orderList[i]['driver']['mobile'] : '-',
              DeliveryStatusEnum.paymentType(orderList[i]['payment_type']),
              amount,
              orderList[i]['address']['formatted_address'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 999989}
            key={'C'}
            style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white' }}
          />,
        )
      }
    }
    return views
  }
  columnCell = ({ item, index }) => {
   if (index == 0) {
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() => this.viewOrderBtnAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  }
  render() {
    if (this.state.showOrderDetail) {
      return (
          <DeliveryOrderDetail backBtnAction={() => this.setState({ showOrderDetail: false })} orderID={this.state.orderID} />
      )
    } else {
      return (
        <SafeAreaView style={styles.Container}>
                    {this.renderChangeOrderSatatusView()}
          <Header_View  title={'Roster Detail'}  backBtn={this.props.backBtnAction} showSaveBtn={false}  />
          <ScrollView style={{ height: windowHeight }}>
            <View>
              {this.renderBranchDetail()}
              {this.renderOrderDetail()}
              <View style={{height:100}}/>
            </View>
          </ScrollView>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      )
    }
  }
}
const commonWidth = 7.6;
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
    paddingBottom:20,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  statusItemViewStyle: {
    margin: 5,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
})
const tableHeaderString = [
  'Order#',
  // 'Reference',
  'Customer Name',
  'Customer Mobile',
  'Status',
  'Driver Name',
  'Driver Mobile',
  'Payment Type',
  'Amount',
  'Delivered At',
]
