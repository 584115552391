import React, {Component} from 'react';
import {StyleSheet, View, Text, TextInput, Platform, Alert} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
export default class OrdersSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      returnPolicyDays: '',
      regexp: /^[0-9\.]+$/,
      updateUI: false,
      dataLoad: false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=orders',
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        returnPolicyDays: configs['return_policy_days'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateApi = async () => {
    var configs = [];
    if (this.state.returnPolicyDays.length != 0) {
      let fDict = {
        key_group: 'orders',
        key: 'return_policy_days',
        value: this.state.returnPolicyDays,
        secured: false,
      };
      configs.push(fDict);
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.config,
        'post',
        JSON.stringify({configs: configs}),
        '',
      );
      // console.log('configs', responseJson);
      this.setState({dataLoad: true});
      if (responseJson['status'] == true) {
        // this.deleteKeysAPI(configs);
        this.alertView('Uploaded successfully');
      } else {
        this.setState({dataLoad: true});
      }
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    var deleteIndex = [];
    for (let q = 0; q < keyParameterArray.length; q++) {
      deleteParms.push(keyParameterArray[q]);
    }
    deleteParms.push('reverse_geocode_api');
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteIndex.push(index);
    }
    for (var i = deleteIndex.length - 1; i >= 0; i--) {
      deleteParms.splice(deleteIndex[i], 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(
      urlParm,
      'delete',
      '',
      '',
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      //alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateApi();
  }
  onHandleTextChange = text => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.setState({returnPolicyDays: text});
    }
  };
  //MARK:-  UI 
  render() {
    return (
      <View style={{width: '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Orders</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Return policy days
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter return policy days"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(text)}
                value={this.state.returnPolicyDays}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  verifyEmailStyle: {
    height: 30,
    width: 150,
    backgroundColor: colors.AppNewGreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
});

let keyParameterArray = ['hide_landmark', 'disable_detailed_address'];
