// setItemInStorage
export const setItemInStorage = ({key, value}) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// setItemInStorage
export const getItemFromStorage = ({key}) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : undefined;
};

// setItemInStorage
export const remobeItemFromStorage = ({key}) => {
  localStorage.removeItem(key);
};
