import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  Image,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  FlatList,
} from 'react-native';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import colors from '../../HelperClasses/AppColor';
import AppConstants from '../../Constants/AppConstants';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import radioCheckIcon from '../../assets/radioCheck.png';
import radioUncheckIcon from '../../assets/radioUncheck.png';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';
import searchIcon from '../../assets/search.png';
import SidePanView from '../../Component/SidePanView';
import SelectSearchView from 'react-select';
import DropDown from '../../Shared/DropDown';
import deleteIcon from '../../assets/deleteGrayIcon.png';
import {collections_styles} from './CollectionStyles';

// Global scope variables
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const CollectionCondition = ({
  collection_state = {},
  setCollectionState,
  showSideView,
  setShowSideView,
  account_categories,
  listing_categories,
  selectedSearchArray,
  setSelectedSearchArray,
  isEdit,
}) => {
  // destructure state
  const {
    expirable,
    medium,
    images,
    scope_type,
    condition_type,
    manual_ids,
    start_at,
    end_at,
    active,
    view_type,
    background_color,
    background_image,
    conditions,
    order_by,
    title,
    description,
    slug,
  } = collection_state;
  //
  const [selectedItems, setSelectedItems] = useState({});

  //
  useEffect(() => {
    if (isEdit && condition_type === 1) {
      Object.keys(conditions).forEach(key => {
        const conditionKey = Object.keys(conditions[key])[0];

        let selected_ids_items = [];

        if (conditionKey === 'category_id') {
          selected_ids_items = listing_categories?.filter(item =>
            conditions[key][conditionKey]?.includes(item.id),
          );
        } else if (conditionKey === 'account_category_id') {
          selected_ids_items = account_categories?.filter(item =>
            conditions[key][conditionKey]?.includes(item.id),
          );
        }

        setSelectedItems(prev => ({
          ...prev,
          [`${key}_${conditionKey}`]: selected_ids_items?.map(item => ({
            label: item.name,
            value: item.id,
          })),
        }));
      });
    }
  }, [
    account_categories,
    condition_type,
    conditions,
    isEdit,
    listing_categories,
  ]);

  // handleChangeValue
  const handleChangeValue = ({value, key_name}) => {
    setCollectionState(prev => ({...prev, [key_name]: value}));
  };

  // automaticType
  const automaticType = () => {
    return (
      <View>
        {/* Condition main section */}
        <View>
          {/* Mapping conditions */}
          {Object.keys(conditions)?.map((key, index) => {
            // Categories  for dropdown
            let categories =
              Object.keys(conditions[key])[0] === 'category_id'
                ? listing_categories?.map(item => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })
                : account_categories?.map(item => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  });

            return (
              <View
                style={{
                  flexDirection: itsMobileView ? 'column' : 'row',
                  alignItems: 'flex-start',
                  zIndex: 10000 - index,
                }}>
                <View style={{zIndex: 1000009}}>
                  {/* ConditionDropDown */}
                  <DropDown
                    selected_item={key}
                    all_items={[
                      {label: 'AND', value: 'AND'},
                      {label: 'OR', value: 'OR'},
                    ]}
                    onSelect={value => {
                      setSelectedItems({});

                      let updated_object = conditions;
                      delete updated_object[key];
                      handleChangeValue({
                        value: {
                          ...updated_object,
                          [value]: {account_category_id: []},
                        },
                        key_name: 'conditions',
                      });
                    }}
                  />
                </View>
                <View style={{width: 20}} />
                <View style={{zIndex: 1000007}}>
                  {/* categoryCustomDropDown */}
                  <DropDown
                    selected_item={Object.keys(conditions[key])[0]}
                    all_items={[
                      {
                        label: 'Accounts',
                        value: 'account_category_id',
                      },
                      {
                        label: 'Listings',
                        value: 'category_id',
                        isShow: scope_type === 4 ? true : false,
                      },
                    ]}
                    onSelect={value => {
                      setSelectedItems(prev => ({
                        ...prev,
                        [`${key}_${Object.keys(conditions[key])[0]}`]: [],
                      }));
                      handleChangeValue({
                        value: {...conditions, [key]: {[value]: []}},
                        key_name: 'conditions',
                      });
                    }}
                  />
                </View>
                <View style={{width: 20}} />
                <View
                  style={{
                    zIndex: 1000005,
                    flexDirection: 'row',
                    width: itsMobileView ? '100%' : '50%',
                  }}>
                  <View style={{marginTop: 10, flex: 1}}>
                    <SelectSearchView
                      options={categories}
                      value={
                        selectedItems[
                          `${key}_${Object.keys(conditions[key])[0]}`
                        ]
                      }
                      onChange={items => {
                        //Mapping items just id needed here

                        setSelectedItems(prev => ({
                          ...prev,
                          [`${key}_${Object.keys(conditions[key])[0]}`]: items,
                        }));
                        handleChangeValue({
                          value: {
                            ...conditions,
                            [key]: {
                              [Object.keys(conditions[key])[0]]: items?.map(
                                it => it.value,
                              ),
                            },
                          },
                          key_name: 'conditions',
                        });
                      }}
                      isMulti={true}
                      placeholder={`Choose category`}
                      styles={colourStyles}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: colors.AppGreenBorder,
                          primary: colors.AppGreenBorder,
                        },
                      })}
                    />
                  </View>
                  {/* <this.renderDeleteListingView id={a} /> */}
                </View>
              </View>
            );
          })}
        </View>
        {/* Add more button */}
        {scope_type === 4 && Object.keys(conditions)?.length !== 2 && (
          <View style={{margin: 20, alignItems: 'center', zIndex: -1}}>
            <TouchableOpacity
              style={collections_styles.addAnotherNowView}
              onPress={() => {
                handleChangeValue({
                  value: {...conditions, OR: {account_category_id: []}},
                  key_name: 'conditions',
                });
              }}>
              <Text
                style={[
                  TableCommonStyleSheet.saveTxtStyle,
                  {color: colors.AppGreenBorder},
                ]}>
                + Add another condition
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  //SelectedSearchCellItem
  const SelectedSearchCellItem = ({item, index}) => {
    let descp = scope_type == 4 ? item.list_price.formatted : item.description;
    return (
      <View style={collections_styles.cellItemStyle}>
        <TouchableOpacity
          style={{flexDirection: 'row', flex: 1}}
          //   onPress={() => this.didSelectAccountListing(item.id)}
        >
          <Image
            style={TableCommonStyleSheet.itemImageViewStyle}
            source={item.images[0] || ''}
          />
          <View style={{justifyContent: 'center', marginLeft: 10, flex: 1}}>
            <Text
              style={[
                CommonStyleSheet.plainTextStyle,
                {color: colors.AppNewGreen},
              ]}>
              {item.title || item.name}
            </Text>
            <View style={{height: 5}} />
            <Text
              style={TableCommonStyleSheet.gridPriceViewStyle}
              numberOfLines={1}>
              {descp}
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{alignItems: 'center', marginTop: 5}}>
          <TouchableOpacity
            style={TableCommonStyleSheet.deleteViewStyle}
            onPress={() => {
              setSelectedSearchArray(
                selectedSearchArray?.filter(el => el.id !== item.id),
              );
              handleChangeValue({
                value: manual_ids?.filter(el => el !== item.id),
                key_name: 'manual_ids',
              });
            }}>
            <Image
              style={TableCommonStyleSheet.iconStyle}
              source={deleteIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View style={{width: '100%'}}>
      {/*  */}
      <Text style={CommonStyleSheet.plainTextStyle}>Conditions</Text>
      <View style={collections_styles.firstViewStyle}>
        <View style={{margin: 20}}>
          <Text style={AppConfigStyleSheet.titleTxtStyle}>Condition type</Text>
          <View style={{flexDirection: 'row', marginTop: 16, marginLeft: -10}}>
            <TouchableOpacity
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={() => {
                handleChangeValue({value: 2, key_name: 'condition_type'});
                setSelectedItems({});
                setSelectedSearchArray([]);
              }}>
              <Image
                style={collections_styles.checkBoxViewStyle}
                resizeMode="contain"
                source={condition_type == 2 ? radioCheckIcon : radioUncheckIcon}
              />
              <Text>Manual</Text>
            </TouchableOpacity>
            {scope_type !== 5 && (
              <TouchableOpacity
                style={{flexDirection: 'row', alignItems: 'center'}}
                onPress={() => {
                  setCollectionState(prev => ({
                    ...prev,
                    conditions: {
                      AND: {
                        account_category_id: [],
                      },
                    },
                    condition_type: 1,
                    manual_ids: [],
                  }));
                  setSelectedItems({});
                  setSelectedSearchArray([]);
                }}>
                <Image
                  style={collections_styles.checkBoxViewStyle}
                  resizeMode="contain"
                  source={
                    condition_type == 1 ? radioCheckIcon : radioUncheckIcon
                  }
                />
                <Text>Automated</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>

        {/*  */}
        {condition_type === 2 ? (
          <View style={{margin: 20, marginTop: -6}}>
            <View
              style={[
                TableCommonStyleSheet.secondButtonViewStyle,
                {width: 160},
              ]}>
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => setShowSideView(true)}>
                <Image style={{width: 16, height: 16}} source={searchIcon} />
                <Text style={[TableCommonStyleSheet.secondBtnTextStyle]}>
                  {'Search'}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View style={{margin: 20, marginTop: -10}}>{automaticType()}</View>
        )}
      </View>
      {selectedSearchArray?.length > 0 && condition_type === 2 && (
        <View
          style={
            selectedSearchArray.length == 0
              ? {flex: 1}
              : collections_styles.selectedViewStyle
          }>
          {
            <View style={{flex: 1}}>
              <FlatList
                data={selectedSearchArray}
                horizontal={false}
                renderItem={SelectedSearchCellItem}
                extraData={selectedSearchArray}
                showsVerticalScrollIndicator={true}
                scrollEnabled={true}
              />
            </View>
          }
        </View>
      )}
      <View style={{height: 100}} />
    </View>
  );
};

export default CollectionCondition;

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
