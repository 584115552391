/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import networkService from '../Network/NetworkManager';
import ItemsSummary from './OrderDetails/ItemsSummary/ItemsSummary';
import ShipmentDetails from './OrderDetails/ShipementDetails/ShipmentDetails';
import CustomerDetails from './OrderDetails/CustomerDetails/CustomerDetails';
import OrderSummary from './OrderDetails/OrderSummary/OrderSummary';
import ShipmentDetails2 from './OrderDetails/ShipementDetails/ShipmentDetails2';
import NavigationHeader from '../Shared/Header/NavigationHeader';
import {ICONS} from '../Constants/Icons';
import ReactToPrint, {PrintContextConsumer} from 'react-to-print';
import {ToastContainer} from 'react-toastify';
import AppLoader from '../HelperClasses/AppLoaderClasses/AppLoader';
import SellerDetails from './OrderDetails/CustomerDetails/SellerDetails';
import {booking_order_types} from '../Constants/Common';
import AddressBox2 from './OrderDetails/AddressBox/AddressBox2';
import OrderDetailsHeaderView from './OrderDetailsHeaderView';
import SaveButton from '../Component/SaveButton';
const APPURL = require('../Constants/URLConstants');

const OrderDetails2 = () => {
  const {orderID} = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();

  //Order Details state:
  const [orderDetailsState, setOrderDetailsState] = useState({
    isLoading: false,
    isError: false,
    error_msg: '',
    order_details: null,
  });

  //fetch order Detils by id

  useEffect(async () => {
    setOrderDetailsState(prev => ({
      ...prev,
      isLoading: true,
    }));
    load_order_details();
  }, [orderID]);

  // destructure state
  const {isLoading, isError, error_msg, order_details} = orderDetailsState;

  //load order details
  const load_order_details = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.order + `${orderID}`,
      'get',
    );

    if (responseJson?.status) {
      setOrderDetailsState(prev => ({
        ...prev,
        isLoading: false,
        isError: false,
        order_details: responseJson?.data?.order ?? {},
      }));
    } else {
      setOrderDetailsState(prev => ({
        ...prev,
        isLoading: false,
        isError: true,
        error_msg: responseJson,
      }));
    }
  };

  return (
    <>
      <div className=" w-full     pb-10   flex flex-col   max-h-[90%] min-h-[80VH] overflow-y-auto ">
        <ToastContainer />
        <AppLoader enable={isLoading ? false : true} />
        <OrderDetailsHeaderView
          order_details={order_details}
          generate_button={
            <ReactToPrint
              content={() => contentRef.current}
              documentTitle={order_details?.reference_number}>
              <PrintContextConsumer>
                {({handlePrint}) => (
                  <SaveButton saveBtn={handlePrint} saveBtnTitle={'Generate'} />
                )}
              </PrintContextConsumer>
            </ReactToPrint>
          }
        />

        {order_details && (
          <div
            ref={contentRef}
            className=" m-[10px] p-[10px] rounded-md border border-[#e6e7e7]   max-h-[100%] overflow-auto">
            {/* Shipment details */}
            {!order_details?.legacy && (
              <div className=" ">
                <ShipmentDetails2
                  order_details={order_details}
                  load_order_details={load_order_details}
                />
              </div>
            )}
            {/*  */}
            <div className=" grid   grid-cols-[100%]  xl:grid-cols-[60%,40%]    mt-6">
              <div className="flex flex-col gpa-4">
                <CustomerDetails order_details={order_details} />
                <SellerDetails order_details={order_details} />
              </div>

              <div className="  mt-5 xl:mt-0 ltr:xl:ml-10 rtl:xl:mr-10">
                <OrderSummary order_details={order_details} />

                {order_details?.type !== 'digital' &&
                  !booking_order_types.includes(order_details?.type) &&
                  !order_details?.legacy &&
                  order_details?.account === null && (
                    <div className=" mt-5">
                      <AddressBox2
                        order_details={order_details}
                        load_order_details={load_order_details}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderDetails2;
