import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');

export default class DomainSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      dataLoad: false,
      domainName: '',
      siteUrl:'',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general', 'get');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        siteUrl: configs['site_url'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateDomainApi = async() => {
    this.setState({dataLoad: false});
    var configs = [];
    let fDict = {
      key_group: 'general',
      key: 'site_url',
      value: this.state.siteUrl,
      secured: false,
    };
    configs.push(fDict);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config, 'post',  JSON.stringify({configs: configs}), '');
    console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      AppToast();
    } else {
     this.deleteKeysAPI() 
    }
  }
  deleteKeysAPI = async Parameter => {
    let urlParm = APPURL.URLPaths.config + '?key=' + 'site_url';
    const responseJson = await networkService.networkCall(urlParm, 'delete', '', '');
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      AppToast();
    } 
  };
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // if (this.state.domainName.length == 0) {
    //   this.alertView('please enter email');
    // } else {
      this.addOrUpdateDomainApi();
    // }
  }

  render() {
    return (
      <View style={{width: '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Domain</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          This below config are applied only when you do deployment from Vercel.
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Web app deployment site URL</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter site url"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({siteUrl:text})}
                value={this.state.siteUrl}
              />
              <Text style={CommonStyleSheet.hintTitleStyle}>
                This site URL will be used in deployment platform like Vercel, sitemap URL
              </Text>
            </View>
            {/* <View style={{marginTop: 30}}>
              <View style={styles.verifyEmailStyle}>
                <Text style={{color: 'white'}}>Verify This Domain</Text>
              </View>
            </View> */}
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  verifyEmailStyle: {
    height: 30,
    width: 150,
    backgroundColor: colors.AppNewGreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
});
