import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import ReactFileReader from 'react-file-reader';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from '../HelperClasses/AppToast';
import commonStyleSheet from '../StyleSheets/CommonStyleSheet';
import Moment from 'moment';
import closeIcon from '../assets/closeIcon.png';
import AppConstants from '../Constants/AppConstants';
import ConstantArray from '../Constants/ConstantArray';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../Models/MixPannelEnum';
import FilePicker from '../Component/FilePicker';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

export default class AddMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      mediaArray: [],
      dataLoad:true,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    selectedArray:PropTypes.any,
  };
  componentDidMount() {
    let selectedArray = this.props.selectedArray;
    if (selectedArray) {
      this.state.mediaArray = selectedArray;
      this.setState({updateUI: !this.state.updateUI})
    }
  } 
  addMediaApi = async uploadMediaArray => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media, 'post',JSON.stringify({media: uploadMediaArray}))
    // console.log('promo_banner', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.addMedia,{'success':true})
      this.props.backBtnAction(true)
      this.customAlert()
    } else {
      addMixpanelEvent(MixpanelEvent.addMedia,{'success':false})
      alert(responseJson)
    }
  };
  uploadImageAPI = async () => {
    this.setState({dataLoad: false});
    var imgParm = [];
    var uploadMedia = [];
    for (let i = 0; i < this.state.mediaArray.length; i++) {
      let media = this.state.mediaArray[i];
      var dict = {
        name: media['name'].replace(/ /g,''),
        type: media['type'],
      };
      imgParm.push(dict);
    }
    if (imgParm != 0) {
        const responseJson = await networkService.networkCall(APPURL.URLPaths.S3signedUploadURL, 'POST', JSON.stringify({ files: imgParm }));
        if (responseJson['status'] == true) {
          var result = responseJson['data']['result']
          var uploadIncrement = 0
          for (let i = 0; i < imgParm.length; i++) {
            let media = this.state.mediaArray[i]
            // const data = new FormData()
            // data.append('file', media['fileList'])
            // data.append('fileName', media['fileList'])
            // const file_upload_res = await networkService.multipartFile(result[i]['signedUrl'], data)

            fetch(media.base64).then(async res => {
              const file_upload_res = await networkService.uploadFileWithSignedURL(
                result[i]['signedUrl'], imgParm[i]['type'],await res.blob())
            })
            let dic = {
              'url': result[i]['fileUri'],
              "type": 1,
              "parent": 0,
              "mime_type": imgParm[i]['type']
            }
            uploadMedia.push(dic)
            uploadIncrement++
            if (uploadIncrement === imgParm.length) {
              this.addMediaApi(uploadMedia);
            }
          }
        } else {
          this.setState({ dataLoad: true })
          AppToast(responseJson)
        }
    } 
  };

  customAlert() {
    AppToast()
  }
  //MARK:-  Button Actions
  submitButtonAction = () => {
    if (this.state.mediaArray.length == 0) {
      AppToast('Please add image');
    } else {
      this.uploadImageAPI();
    }
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
  };
  deleteMedia(index) {
    this.state.mediaArray.splice(index,1);
    this.setState({updateUI: !this.state.updateUI});
  };
  // onPick = image => {
  //   let base64 = image['base64']
  //   let fileList = image['fileList']
  //   for (let a = 0; a < fileList.length; a++) {
  //     let dic = {
  //       'fileList': fileList[a],
  //       'base64': base64[a],
  //     }
  //     this.state.mediaArray.push(dic);
  //   }
  //   this.setState({updateUI: !this.state.updateUI});
  // }

  onPick = acceptedFiles => {
    acceptedFiles.forEach((file) => {
      let check = this.state.mediaArray.find(x => x['name'] == file['name'])
      if (check == undefined) {
        this.state.mediaArray.push(file)
        this.setState({ updateUI: !this.state.updateUI })
      }
    })
  }
  
  imageView = props => {
    var view = [];
    view.push(<View style={styles.dottedViewStyle}>
      {/* <ReactFileReader fileTypes={ConstantArray.fileTypeArray}
        base64={true}
        multipleFiles={true}
        style={styles.dottedViewStyle}
        handleFiles={this.onPick}>
        <Text >Add Media</Text>
      </ReactFileReader> */}
      <FilePicker title={'Add Media'} data={files => this.onPick(files)}/>
    </View>)
    for (let a = 0; a < this.state.mediaArray.length; a++) {
      let media = this.state.mediaArray[a]
      let filename = media['name'] //media['fileList']['name'];
      var fileExt = filename.split('.').pop();
      if (fileExt == 'json' || fileExt == 'csv' ) {
        view.push(
          <View style={{ margin: 10 }}>
            <View style={styles.dottedViewStyle}>
              <Text>{filename}</Text>
            </View>
            <TouchableOpacity style={styles.cancelViewStyle} onPress={() => this.deleteMedia(a)}>
              <Image source={closeIcon} style={{width:15,height:15,marginTop:10,marginLeft:5}} />
            </TouchableOpacity>
          </View>
        )
      } else {
        view.push(
          <View style={{ margin: 10 }}>
            <Image source={{ uri: media.base64 }} style={styles.ImageViewStyle} />
            <TouchableOpacity style={styles.cancelViewStyle} onPress={() => this.deleteMedia(a)}>
              <Image source={closeIcon} style={styles.closeBtnStyle} />
            </TouchableOpacity>
          </View>
        )
      }
    }
    return view;
  };

  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    )
  }
 
  render() {

    return (
      <SafeAreaView style={styles.Container}>
        <Header_View title={'Upload Media'}  backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}  />
        <ScrollView>
          <View style={styles.mainView}>
            <View style={{ height: 15, width: '100%' }} />
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Media'} />
              <View style={{ width: '80%', height: '100%'}}>
                <ScrollView>
                  <View style={styles.containerView}>
                    {this.imageView()}
                  </View>
                </ScrollView>
              </View>
            </View>
            <View style={{ height: 100 }} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
export const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to 
  resize from the original image resolution */
  canvas.width = 250;
  canvas.height = 250;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg');
  });
};
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  containerView: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    width: windowWidth < fixedWidth ? windowWidth - 50 : windowWidth < fixedWidth ,
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
  },
  subViewStyle: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    zIndex: 1,
  },
  addImageStyle: {
    flexDirection: 'column',
  },
  ImageViewStyle: {
    height: 100,
    width: 100,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.Lightgray
  },
  dottedViewStyle: {
    margin:10,
    borderRadius: 10,
    height: 100,
    borderColor: colors.Appgreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderWidth: 1,
    width: 100,
  },
  cancelViewStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: 80,
    margin: 10,
  },
  closeBtnStyle:{ 
    width: 15,
     height: 15,
    backgroundColor: 'white',
     borderRadius: 7.5 
    }
});
