import networkService from '../Network/NetworkManager';
const APPURL = require('../Constants/URLConstants');

const getSubscriptionListings = async () => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.subscription,
      'get',
    );
    return responseJson?.data?.subscription_products;
  } catch (error) {
    return [];
  }
};

export default getSubscriptionListings;
