import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SwitchButton from '../../Shared/Switch';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import AppConstants from '../../Constants/AppConstants';
import DatePicker from 'react-custom-date-picker';
import Moment from 'moment';
import {p2} from '../../StyleSheets/TextTitleStyleSheet';
import checked_Icon from '../../assets/checkedIcon.png';
import unchecked_Icon from '../../assets/uncheckedIcon.png';
import {collections_styles} from './CollectionStyles';

// Global scope variables
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let dateFormat = 'yyyy-MM-DD';

const CollectionSetup = ({collection_state, setCollectionState}) => {
  // destructure state
  const {
    expirable,
    medium,

    start_at,
    end_at,
    active,
  } = collection_state;

  //handleChangeValue
  const handleChangeValue = ({value, key_name}) => {
    setCollectionState(prev => ({...prev, [key_name]: value}));
  };

  return (
    <View style={collections_styles.rightMainView}>
      {/* Active */}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '95%',
        }}>
        <Text style={CommonStyleSheet.plainTextStyle}>
          Status(Not Active / Active)
        </Text>
        <SwitchButton
          isChecked={active}
          setIsChecked={value =>
            handleChangeValue({value: value, key_name: 'active'})
          }
        />
      </View>

      {/* Collections availability */}
      <View style={{height: 30}} />
      <Text style={CommonStyleSheet.plainTextStyle}>
        Collections availability
      </Text>
      <View style={collections_styles.firstViewStyle}>
        <View style={{margin: 20, zIndex: 101}}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text style={AppConfigStyleSheet.titleTxtStyle}>Schedule</Text>
            <SwitchButton
              isChecked={expirable}
              setIsChecked={value =>
                handleChangeValue({value: value, key_name: 'expirable'})
              }
            />
          </View>
        </View>
        <View
          style={{
            display: expirable ? 'flex' : 'none',
          }}>
          <View style={{margin: 20, zIndex: 101, marginTop: 10}}>
            <Text style={AppConfigStyleSheet.titleTxtStyle}>Start Date</Text>
            <View style={collections_styles.dateViewStyle}>
              <DatePicker
                style={[
                  AppConfigStyleSheet.txtFieldStyle,
                  {outline: 0, height: 35},
                ]}
                date={
                  start_at
                    ? Moment(start_at).format(dateFormat)
                    : Moment().format(dateFormat)
                }
                color={colors.AppLightGreen}
                placeholder={'Select start date'}
                handleDateChange={date =>
                  handleChangeValue({
                    value: Moment(date).format(dateFormat),
                    key_name: 'start_at',
                  })
                }
                width={300}
                inputStyle={{
                  borderRadius: 0,
                  borderColor: colors.TransparentColor,
                  marginTop: -30,
                  height: 20,
                  ...p2,
                  backgroundColor: colors.TransparentColor,
                }}
              />
            </View>
          </View>
          <View style={{margin: 20, zIndex: 100}}>
            <Text style={AppConfigStyleSheet.titleTxtStyle}>End Date</Text>
            <View style={collections_styles.dateViewStyle}>
              <DatePicker
                date={
                  end_at
                    ? Moment(end_at).format(dateFormat)
                    : Moment().format(dateFormat)
                }
                color={colors.AppLightGreen}
                handleDateChange={date =>
                  handleChangeValue({
                    value: Moment(date).format(dateFormat),
                    key_name: 'end_at',
                  })
                }
                placeholder={'Select End Date'}
                width={300}
                inputStyle={{
                  borderRadius: 0,
                  borderColor: colors.TransparentColor,
                  marginTop: -30,
                  height: 20,
                  ...p2,
                  backgroundColor: colors.TransparentColor,
                }}
              />
            </View>
          </View>
        </View>
      </View>

      {/*  Select applicable channel */}
      <View style={{height: 20, zIndex: 2}} />
      <Text style={CommonStyleSheet.plainTextStyle}>
        Select applicable channel
      </Text>
      <View style={collections_styles.platformViewStyle}>
        <View style={{margin: 20}}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={() => {
                // Note: 1 for web :
                const isAvailable = medium.includes(1);
                const updated_value = isAvailable
                  ? medium.filter(it => it !== 1)
                  : [...medium, 1];
                handleChangeValue({value: updated_value, key_name: 'medium'});
              }}>
              <Image
                style={collections_styles.checkBoxViewStyle}
                resizeMode="contain"
                source={medium.includes(1) ? checked_Icon : unchecked_Icon}
              />
              <Text>Web</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={() => {
                // Note: 2for web :
                const isAvailable = medium.includes(2);
                const updated_value = isAvailable
                  ? medium.filter(it => it !== 2)
                  : [...medium, 2];
                handleChangeValue({value: updated_value, key_name: 'medium'});
              }}>
              <Image
                style={collections_styles.checkBoxViewStyle}
                resizeMode="contain"
                source={medium.includes(2) ? checked_Icon : unchecked_Icon}
              />
              <Text>iOS</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={() => {
                // Note: 3 for web :
                const isAvailable = medium.includes(3);
                const updated_value = isAvailable
                  ? medium.filter(it => it !== 3)
                  : [...medium, 3];
                handleChangeValue({value: updated_value, key_name: 'medium'});
              }}>
              <Image
                style={collections_styles.checkBoxViewStyle}
                resizeMode="contain"
                source={
                  medium.includes(3) !== false ? checked_Icon : unchecked_Icon
                }
              />
              <Text>Android</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

export default CollectionSetup;
