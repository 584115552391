import {View} from 'react-native';
import {ReactComponent as BackIcon} from '../assets/backSVG.svg';

export default function BackBtnView() {
  return (
    <View>
      <BackIcon style={{height: 15, width: 15}} />
    </View>
  );
}
