import React, {Component} from 'react';
import {
  View,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import { Calendar, momentLocalizer,  Views} from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { getRandomColor } from '../HelperClasses/SingletonClass';
import { b1 } from '../StyleSheets/TextTitleStyleSheet';
const localizer = momentLocalizer(moment);

let allViews = [Views.MONTH,Views.WEEK,Views.DAY]

export default class AppCalendarView extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    eventArray: PropTypes.any,
    didSelectEvent: PropTypes.func,
  };
  onTap(event){
    this.props.didSelectEvent(event)
  }
  eventProp = (event, start, end, isSelected) =>  {
    var style = {
      border: "none",
      ...b1,
      padding:5,
      height: 25,
      outline: 0,
    }
    if (isSelected) {
      style['backgroundColor'] = colors.BGBlueColor
      style['color'] = 'white'

    } else {
      style['backgroundColor'] = event['color']
      style['color'] = colors.Appgray
    }
    return { style: style }        

  }
  render() {
    return (<View style={{flex:1}}>
      <Calendar
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView={Views.MONTH}
        events={this.props.eventArray}
        views={allViews}
        style={{ height: '100%' }}
        onDoubleClickEvent={event => { this.onTap(event) }}
        eventPropGetter={(event, start, end, isSelected) => this.eventProp(event, start, end, isSelected)}
      />
    </View>)
  }
}