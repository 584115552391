export const configuration_items = {
  overridden_max_quantity: {
    id: 1,
    title: 'Maximum quantity per listing',
    example: '(Example: 100)',
    value: 5,
    type: 'input-number',
    group: 'rules',
  },
  listing_min_price: {
    id: 1,
    title: 'Minimum listing price ',
    example: '(Example: $5)',
    value: 0,
    type: 'input-number',
    group: 'rules',
  },
  auto_approval: {
    id: 1,
    title: 'Auto approve listings?',
    value: false,
    type: 'switch',
    group: 'rules',
    isShowVideos: true,
    vide_url:
      'https://www.youtube.com/watch?v=xozP1Pmok3U&ab_channel=Tradly-BuildMarketplaceswithno-code',
  },
  listing_pictures_count: {
    id: 1,
    title: 'Number of uploadable pictures count',
    value: 5,
    type: 'input-number',
    group: 'rules',
  },

  hide_offer_percent: {
    id: 1,
    title: 'Offer percent',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  show_max_quantity: {
    id: 1,
    title: 'Maximum quantity restrictions on listing level',
    value: false,
    type: 'switch',
    group: 'rules',
  },
  show_shipping_charges: {
    id: 1,
    title: 'Shipping charges',
    value: false,
    type: 'switch',
    group: 'feature',
    isShowVideos: true,
    vide_url:
      'https://www.youtube.com/watch?v=UtQw-hib018&ab_channel=Tradly-BuildMarketplaceswithno-code',
  },

  listing_address_label: {
    id: 1,
    title: 'Address label ',
    value: false,
    type: 'input',
    example: '(Example: Landmark)',
    group: 'ui_customization',
  },
  listing_map_location_selector_enabled: {
    id: 1,
    title: 'Use map marker instead \nof searching address',
    value: false,
    type: 'switch',
    group: 'ui_customization',
  },
  listing_hide_tags: {
    id: 1,
    title: 'Tags',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  enable_stock: {
    id: 1,
    title: 'Stocks',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  listing_address_enabled: {
    id: 1,
    title: 'Geo address',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  show_stock_out_listings: {
    id: 1,
    title: 'Show out of stock listings in feed',
    value: false,
    type: 'switch',
    group: 'rules',
  },
  enable_slug: {
    id: 1,
    title: 'Listing title slug',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  show_variants: {
    id: 1,
    title: 'Variants',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  meta_title: {
    id: 1,
    title: 'Meta title',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  meta_description: {
    id: 1,
    title: 'Meta description',
    value: false,
    type: 'switch',
    group: 'feature',
  },

  listing_enable_formatted_text_description: {
    id: 1,
    title: 'Enable Composer',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  listing_enable_weight: {
    id: 1,
    title: 'Enable Weight',
    value: false,
    type: 'switch',
    group: 'feature',
  },
  listing_enable_dimensions: {
    id: 1,
    title: 'Enable Dimensions',
    value: false,
    type: 'switch',
    group: 'feature',
  },
};
