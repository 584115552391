import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TextInput,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from "react-switch";
import { addMixpanelEvent, MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');

const KeyGroup = 'admob'
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class AdmobIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusArray: [false, false, false, false],
      negotiateValue: false,
      dataLoad: false,
      appID:'',
      unitID:'',
      listingID:'',
      listingDetailID:'',
      homeID:'',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`, 'get', '', '');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.state.statusArray[0] = configs['enable_admob'] || false;
      this.state.statusArray[1] = configs['listings_admob_enabled'] || false;
      this.state.statusArray[2] = configs['listing_detail_admob_enabled'] || false;
      this.state.statusArray[3] = configs['home_admob_enabled'] || false;
      this.setState({
        appID: configs['admob_appid'] || '',
        unitID: configs['admob_ad_unit_id'] || '',
        listingID: configs['listings_admob_id'] || '',
        listingDetailID: configs['listing_detail_admob_id'] || '',
        homeID: configs['home_admob_id'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateChatApi = async () => {
    var configs = [];
  
    var valueArray = [
      this.state.appID,
      this.state.unitID,
      this.state.listingID,
      this.state.listingDetailID,
      this.state.homeID,
    ];
    for (let a = 0; a < 3; a++) {
      if (valueArray[a].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keyStringArray[a],
          value: valueArray[a],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    for (let i = 0; i < keyParameterArray.length; i++) {
      let fDict = {
        key_group: KeyGroup,
        key: keyParameterArray[i],
        value: this.state.statusArray[i],
        secured: false,
      };
      configs.push(fDict);
    }

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,'post', JSON.stringify({configs: configs}))
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      mobileIntegrationMixPanelEvent(MixpanelEvent.admobIntegration)
      this.deleteKeysAPI(configs)
    } else {
      this.setState({dataLoad: true})
      this.alertView(responseJson);
    } 
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = []
    for (let q = 0; q < DeletekeyParameterArray.length; q++) {
      deleteParms.push(DeletekeyParameterArray[q])
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString()
      let urlParm = APPURL.URLPaths.config + '?key=' + parms
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    } else {
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    }
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {   
    this.setState({dataLoad: false});
    this.addOrUpdateChatApi();
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  //MARK:-  UI 
  renderAllSwitches = (id) => {
    var views = [];
    let i = id['id'];
    views.push(
      <View style={{flexDirection: 'row', marginTop: 20,alignItems: 'center'}}>
         <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.switchSattusBtnAction(i)}
          checked={this.state.statusArray[i]}
          height={22}
          width={50}
        />
        <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[i]}</Text>
      </View>,
    );
    
    return views;
  };
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Admob</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plain2TextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -30}}>
                <this.renderAllSwitches id={0}/>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>App ID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter App ID"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({appID:text})}
                value={this.state.appID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Unit ID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Admob Unit ID"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({unitID:text})}
                value={this.state.unitID}
              />
            </View>
            <View >
              <this.renderAllSwitches id={1}/>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Listings ID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Listings ID"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({listingID:text})}
                value={this.state.listingID}
              />
            </View>
            <View>
              <this.renderAllSwitches id={2}/>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Listings Detail ID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Listings Detail ID"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({listingDetailID:text})}
                value={this.state.listingDetailID}
              />
            </View>
            <View >
              <this.renderAllSwitches id={3}/>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Home ID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Home ID"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({homeID:text})}
                value={this.state.homeID}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const switchTitleStrings = [
  'Enable ad',
  'Enable ad in listing feed screen',
  'Enable ad in listing detail screen',
  'Enable ad in home bottom navbar',
];
let keyParameterArray = [
  'enable_admob',
  'listings_admob_enabled',
  'listing_detail_admob_enabled',
  'home_admob_enabled',
];
let keyStringArray = [
  'admob_appid',
  'admob_ad_unit_id',
  'listings_admob_id',
  'listing_detail_admob_id',
  'home_admob_id',
]
let DeletekeyParameterArray = [
  'enable_admob',
  'listings_admob_enabled',
  'listing_detail_admob_enabled',
  'home_admob_enabled',
  'admob_appid',
  'admob_ad_unit_id',
  'listings_admob_id',
  'listing_detail_admob_id',
  'home_admob_id',
];



// listings_admob_id,
// listing_detail_admob_id,
// home_admob_id