// getFormatBySelectedCurrency
export const getFormatBySelectedCurrency = ({value, selected_format}) => {
  const format = selected_format;
  const is_format_available = format?.includes('{amount}');

  if (is_format_available) {
    return format.replace('{amount}', value);
  } else {
    return value;
  }
};
