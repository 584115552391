import networkService from '../../Network/NetworkManager';
const APPURL = require('../../Constants/URLConstants');

export const getTranslations = async ({
  reference_id,
  reference_type,
  translation_type,
}) => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.translations +
        `?reference_id=${reference_id}&reference_type=${reference_type}&translation_type=${translation_type}`,
      'get',
      '',
      '',
    );

    return responseJson.data?.translation;
  } catch (error) {
    return error;
  }
};
