import AppConstants from '../Constants/AppConstants';
import GithubSlugger, {slug as slugChecker} from 'github-slugger';

export const get_listing_path = ({listing}) => {
  let slug = listing?.slug;
  var pathURL = AppConstants.domainKey;
  if (slug.length == 0) {
    let vslug = slugChecker(listing?.title);
    pathURL = pathURL + `/l/${listing?.id}-${vslug}`;
  } else {
    pathURL = pathURL + `/l/${slug}`;
  }

  return pathURL;
};
