import React from 'react';
import CloseIcon from '../../assets/close.svg';
import SaveButton from '../SaveButton';

const PageHeader = ({
  title,
  close_action,
  save_action,
  save_button_title = 'Save',
  isHideBackButton = false,
}) => {
  return (
    <div className="w-full flex items-center justify-between border border-[#e6e7e7] rounded-xl  p-4">
      <div className="flex items-center  justify-start gap-4">
        {!isHideBackButton && (
          <button
            className=" text-primary bg-[#f2f6f6] h-10 w-10 rounded-full flex items-center justify-center "
            onClick={() => close_action()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-6 h-6">
              <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        )}

        <p className=" text-2xl font-medium text-black font-sans ">{title}</p>
      </div>
      <SaveButton
        saveBtn={() => save_action()}
        saveBtnTitle={'Save'}
        showSaveBtn={true}
      />
    </div>
  );
};

export default PageHeader;
