import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Dimensions,
  Image,
  FlatList,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from 'react-switch';
import {
  addMixpanelEvent,
  MixpanelEvent,
  revenuModelMixPanelEvent,
} from '../../Models/MixPannelEnum';
import SaveButton from '../../Component/SaveButton';
import closeIcon from '../../assets/closeBlackIcon.png';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import HeaderView from '../HeaderView';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';
let AccountTypesArray = ['Standard', 'Express'];

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      hideShowPublickey: false,
      hideShowSecretkey: false,
      switchValue: false,
      message: '',
      purchasedMessage: '',
      listingExhaustedmessage: '',
      writeP: AppConstants.defaultRead,
      selectedType: '',
      selectedTypeValue: '',
      showDropDown: false,
      subscription_types_list: [
        {
          label: 'Stripe',
          value: 'stripe',
        },
        {
          label: 'Mangopay',
          value: 'mangopay',
        },
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'iOS',
          value: 'ios',
        },
        {
          label: 'Free Subscription',
          value: 'free_subscription',
        },
      ],
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        switchValue: configs['subscription_enabled'] || false,
        message: configs['subscription_not_active_message'] || '',
        purchasedMessage:
          configs['subscription_already_purchased_message'] || '',
        listingExhaustedmessage:
          configs['subscription_allow_listings_exhausted_message'] || '',
        selectedType:
          this.state.subscription_types_list.find(
            it => it.value == configs['subscription_type'],
          )?.label ?? '',
        selectedTypeValue: configs['subscription_type_value'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [
      this.state.switchValue,
      this.state.message,
      this.state.selectedTypeValue,
      this.state.purchasedMessage,
      this.state.listingExhaustedmessage,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: i == 0 || i == 2 ? false : true,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      revenuModelMixPanelEvent(MixpanelEvent.accountSubscription);
      this.deleteKeysAPI(configs);
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
    this.props.onCloseModal();
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete');
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  didSelectDropDown = item => {
    console.log('index', item);
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedTypeValue: item['value'],
      selectedType: item['label'],
    });
  };
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }

  //
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={CommonStyleSheet.showDropDownViewStyle}>
          <FlatList
            data={this.state.subscription_types_list}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            alwaysBounceVertical={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item['label']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = () => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Text
          style={
            this.state.selectedType == ''
              ? appConfigStyle.placeholderTxtStyle
              : appConfigStyle.txtFieldStyleDropDown
          }>
          {this.state.selectedType == ''
            ? 'Select Type'
            : this.state.selectedType}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? appConfigStyle.upArraowIconStyle
              : appConfigStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  //MARK:-  UIs 
  render() {
    return (
      <View
        style={{
          width: '100%',
          paddingTop: 10,
        }}>
        <HeaderView
          title={'Account Subscription'}
          saveBtnTitle={'Save'}
          saveBtn={() => this.saveButtonAction()}
          backBtn={() => this.props.onCloseModal()}
        />
        <View
          style={{
            margin: 10,
          }}
        />
        <Text style={appConfigStyle.subTitleStyle}>
          Manage account subscription below. By enabling this, every account
          owner will be verified a active subscription. If there is active
          subscription, the account allowed to post a listing. Your default
          payment gateway selected in Payment method will be used for
          Subscription Payment
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -30}}>
              <View style={{flexDirection: 'row'}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() =>
                    this.setState({switchValue: !this.state.switchValue})
                  }
                  checked={this.state.switchValue}
                  height={22}
                  width={50}
                />
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={appConfigStyle.statusTxtStyle}>
                    {'Subscription enable'}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginTop: 20,
                zIndex: 9999999,
              }}>
              <Text style={appConfigStyle.titleTxtStyle}>
                {' '}
                Subscription Type{' '}
              </Text>
              <TouchableOpacity
                style={[CommonStyleSheet.dropDownViewStyle, {width: '100%'}]}
                onPress={() =>
                  this.setState({showDropDown: !this.state.showDropDown})
                }>
                <this.renderValueLabel />
                <this.customDropDown />
              </TouchableOpacity>
            </View>
            <View style={{marginTop: 30}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                {' '}
                Subscription already purchased message
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter subscription already purchased message"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({purchasedMessage: name})}
                value={this.state.purchasedMessage}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                {' '}
                Subscription allow listings exhausted message
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter subscription allow listings exhausted message"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name =>
                  this.setState({listingExhaustedmessage: name})
                }
                value={this.state.listingExhaustedmessage}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                {' '}
                Subscription not active message
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter subscription not active message"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({message: name})}
                value={this.state.message}
              />
            </View>
          </View>
        </View>
        <View style={{height: 220}} />
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'subscription_enabled',
  'subscription_not_active_message',
  'subscription_type',
  'subscription_already_purchased_message',
  'subscription_allow_listings_exhausted_message',
];
