import React from 'react';

const Table = ({header_data, children, table_header_style, table_style}) => {
  return (
    <table
      className={[
        '  w-full border border-[#e6e7e7] rounded-md  max-h-[90%] max-w-full min-h-[400px]   mt-4  font-default',
        table_style,
      ].join(' ')}>
      <thead>
        <tr
          className={[
            ' sticky top-0 z-10      ',
            table_header_style,
            table_header_style ? '' : 'grid',
          ].join(' ')}>
          {/* table header */}
          {header_data?.map((item, index) => {
            return (
              <th
                className={[
                  ' bg-[rgb(250,251,251)]  flex-grow font-default text-sm  font-medium',
                  item.className,
                ].join(' ')}>
                {item?.title}
              </th>
            );
          })}
        </tr>
      </thead>

      {/* table data */}
      <tbody>{children}</tbody>
    </table>
  );
};

export default Table;
