import networkService from '../Network/NetworkManager';
const APPURL = require('../Constants/URLConstants');

export const uploadImageAPI = async ({image_file}) => {
  var imgParm = [];
  let fileName = image_file.base64;
  if (fileName != null) {
    var dict = {
      name: image_file.fileList[0].name.replace(/ /g, ''),
      type: image_file.fileList[0].type,
    };
    imgParm.push(dict);
  }
  if (imgParm != 0) {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.S3signedUploadURL,
      'POST',
      JSON.stringify({files: imgParm}),
    );
    if (responseJson.status == true) {
      var result = responseJson.data.result;
      return fetch(image_file.base64).then(async res => {
        const file_upload_res = await networkService.uploadFileWithSignedURL(
          result[0].signedUrl,
          imgParm[0].type,
          await res.blob(),
        );

        return result[0].fileUri;
      });
    } else {
      return null;
    }
  }
};
