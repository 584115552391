import React, {useEffect, useState} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
} from 'react-native';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import colors from '../../HelperClasses/AppColor';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';
import networkService from '../../Network/NetworkManager';
import Switch from 'react-switch';
import ReactFileReader from 'react-file-reader';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import {ICONS} from '../../Constants/Icons';
import HelperVideoView from '../../Component/HelperVideoView';
import {onHandleNumberFieldChange} from '../../HelperClasses/SingletonClass';
const APPURL = require('../../Constants/URLConstants');

const ShippingConfigs = ({shippingPageConfigs, closeSidepan}) => {
  const [showLoader, setShowLoader] = useState(true);

  // Main states
  const [shipping_configs, setShippingConfigs] = useState({
    flat_shipping_fee: {
      id: 1,
      title: 'Flat Shipping Fee',
      value: '',
      type: 'text',
      group: 'shipping',
      placeholder: 'Enter flat shipping fee',
      isShowVideoButton: true,
      link: AppConstants.FlatShippingFeeVideo,
    },

    schedule_time: {
      id: 2,
      title: 'Schedule Time',
      value: false,
      type: 'switch',
      group: 'general',
    },
    fulfillment_by_platform_enabled: {
      id: 3,
      title: 'Fulfillment by platform ',
      value: false,
      type: 'switch',
      group: 'shipping',
      isShowVideoButton: true,
      link: AppConstants.FulfillmentByPlatformVideo,
      isHideVideoTitle: true,
    },
    fulfilled_by_platform_icon_path: {
      id: 4,
      title: 'Fullfilment by Platform Icon',
      value:
        'https://media.tradly.app/images/marketplace/69164/platform-GYGyhfsY.png',
      type: 'image',
      group: 'shipping',
      isHide: true,
      imageData: null,
    },
    fulfilled_by_seller_icon_path: {
      id: 5,
      title: 'Seller Delivery Icon',
      value:
        'https://media.tradly.app/images/marketplace/11100/seller-ovNVl3fz.png',
      type: 'image',
      group: 'shipping',
      isHide: true,
      imageData: null,
    },
  });

  //set Default configs values
  // Note: this is hide functionality we should
  useEffect(() => {
    setShippingConfigs(prevState => {
      const newState = {...prevState};
      Object.entries(newState).forEach(([key, value]) => {
        if (shippingPageConfigs.hasOwnProperty(key)) {
          newState[key].value =
            shippingPageConfigs[key] ?? newState[key]?.value;
          if (key === 'fulfillment_by_platform_enabled') {
            newState.fulfilled_by_platform_icon_path.isHide =
              shippingPageConfigs[key]
                ? !shippingPageConfigs[key]
                : !newState[key]?.value;
            newState.fulfilled_by_seller_icon_path.isHide = shippingPageConfigs[
              key
            ]
              ? !shippingPageConfigs[key]
              : !newState[key]?.value;
          }
        }
      });
      return newState;
    });
  }, [shippingPageConfigs]);

  ///Upload Image API
  const uploadImageAPI = async imageData => {
    return await networkService
      .networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({
          files: [
            {
              name: imageData['fileList'][0]['name'].replace(/ /g, ''),
              type: imageData['fileList'][0]['type'],
            },
          ],
        }),
      )
      .then(async responseJson => {
        if (responseJson['status'] == true) {
          var result = responseJson['data']['result'];
          return await fetch(result[0]['signedUrl'], {
            method: 'PUT',
            headers: {
              ContentType: imageData['fileList'][0]['type'],
            },
            body: imageData['fileList'][0],
          }).then(async res => {
            if (res.status == 200) {
              return result[0]['fileUri'];
            }
          });
        }
      })
      .catch(error => {
        return '';
      });
  };

  // Save Config
  const addOrUpdateConfigApi = async () => {
    setShowLoader(false);
    var configs = [];
    var delete_configs_keys = [];

    // Loop on configs
    for (const [key, value] of Object.entries(shipping_configs)) {
      if (shipping_configs[key].value?.toString()?.length > 0) {
        let config_value =
          shipping_configs[key]?.type !== 'image'
            ? shipping_configs[key].value
            : shipping_configs[key].imageData
            ? await uploadImageAPI(shipping_configs[key].imageData).then(
                async res => {
                  return res;
                },
              )
            : shipping_configs[key].value;

        configs.push({
          key_group: shipping_configs[key].group,
          key: key,
          value: config_value,
          secured: false,
        });
      } else {
        delete_configs_keys.push(key);
      }
    }

    //   API call
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );

    if (responseJson['status'] == true) {
      if (delete_configs_keys?.length > 0) {
        deleteKeysAPI(delete_configs_keys);
      } else {
        setShowLoader(true);
        closeSidepan();
      }
    } else {
      setShowLoader(true);
    }
  };

  //delete config
  const deleteKeysAPI = async delete_configs_keys => {
    let parms = delete_configs_keys.join(',');
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete');
    setShowLoader(true);
    closeSidepan();
  };

  // Chanage Value
  const onChangeValue = (key, value, type) => {
    if (key === 'fulfillment_by_platform_enabled') {
      setShippingConfigs(prev => ({
        ...prev,
        [key]: {
          ...shipping_configs[key],
          value: value,
        },
        fulfilled_by_seller_icon_path: {
          ...shipping_configs['fulfilled_by_seller_icon_path'],
          isHide: !value,
        },
        fulfilled_by_platform_icon_path: {
          ...shipping_configs['fulfilled_by_platform_icon_path'],
          isHide: !value,
        },
      }));
    } else if (key === 'flat_shipping_fee') {
      setShippingConfigs(prev => ({
        ...prev,
        [key]: {
          ...shipping_configs[key],
          value: Number(onHandleNumberFieldChange(value)),
        },
      }));
    } else {
      if (type === 'image') {
        setShippingConfigs(prev => ({
          ...prev,
          [key]: {
            ...shipping_configs[key],
            value: value?.base64,
            imageData: value,
          },
        }));
      } else {
        setShippingConfigs(prev => ({
          ...prev,
          [key]: {
            ...shipping_configs[key],
            value: value,
          },
        }));
      }
    }
  };

  return (
    <View>
      <View style={CommonStyleSheet.sidepanSaveBtnViewStyle}>
        <View style={{display: AppConstants.defaultWrite ? 'flex' : 'none'}}>
          <SaveButton
            saveBtn={() => addOrUpdateConfigApi()}
            saveBtnTitle={'Save'}
          />
        </View>
      </View>
      <View style={{flex: 1}}>
        <View style={CommonStyleSheet.sidepanmViewStyle}>
          {Object.keys(shipping_configs).map(key => {
            if (
              shipping_configs[key].type == 'text' &&
              shipping_configs[key].isHide !== true
            ) {
              return (
                <View style={CommonStyleSheet.configContainerViewStyle}>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Text style={CommonStyleSheet.titleNameStyle}>
                      {shipping_configs[key].title}
                    </Text>
                    <View style={{width: 10, height: 10}} />
                    {shipping_configs[key].isShowVideoButton && (
                      <HelperVideoView
                        helperVideoBtnAction={() =>
                          window.open(shipping_configs[key].link, '_blank')
                        }
                      />
                    )}
                  </View>

                  <TextInput
                    style={[
                      CommonStyleSheet.txtFieldStyle,
                      {
                        outline: 0,
                        marginLeft: 0,
                        width: '100%',
                      },
                    ]}
                    value={shipping_configs[key].value}
                    placeholder={shipping_configs[key].placeholder ?? ''}
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => onChangeValue(key, name)}
                  />
                </View>
              );
            } else if (shipping_configs[key].type == 'switch') {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  <View
                    style={{
                      width: 'auto',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Text style={CommonStyleSheet.titleNameStyle}>
                      {shipping_configs[key].title}
                    </Text>
                    <View style={{width: 10, height: 10}} />
                    {shipping_configs[key].isShowVideoButton && (
                      <HelperVideoView
                        helperVideoBtnAction={() =>
                          window.open(shipping_configs[key].link, '_blank')
                        }
                        isHideVideoTitle={
                          shipping_configs[key].isHideVideoTitle
                        }
                      />
                    )}
                  </View>
                  <View>
                    <Switch
                      onColor={colors.AppNewGreen}
                      onChange={() =>
                        onChangeValue(key, !shipping_configs[key].value)
                      }
                      checked={shipping_configs[key].value}
                      height={22}
                      width={50}
                    />
                  </View>
                </View>
              );
            } else if (
              shipping_configs[key].type == 'image' &&
              shipping_configs[key].isHide !== true
            ) {
              return (
                <View style={CommonStyleSheet.configContainerViewStyle}>
                  <Text style={CommonStyleSheet.titleNameStyle}>
                    {shipping_configs[key].title}
                  </Text>
                  <View style={CommonStyleSheet.addImageViewStyle}>
                    {!shipping_configs[key]?.value?.length > 0 ? (
                      <ReactFileReader
                        fileTypes={['.png', '.jpg', '.jpeg']}
                        base64={true}
                        handleFiles={image =>
                          onChangeValue(key, image, 'image')
                        }>
                        <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
                          Add Photo
                        </Text>
                      </ReactFileReader>
                    ) : (
                      <View style={{position: 'relative'}}>
                        <Image
                          // source={{ uri: fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage }}
                          source={{
                            uri: shipping_configs[key].value,
                          }}
                          style={CommonStyleSheet.ImageViewStyle}
                        />
                        <TouchableOpacity
                          style={CommonStyleSheet.cancelViewStyle}
                          onPress={() => onChangeValue(key, '', null)}>
                          <Image
                            source={ICONS.closeIcon}
                            style={CommonStyleSheet.closeBtnStyle}
                          />
                        </TouchableOpacity>
                      </View>
                    )}
                  </View>
                </View>
              );
            }
          })}
        </View>
      </View>
      <AppLoader enable={showLoader} />
    </View>
  );
};

export default ShippingConfigs;
