export const listing_table_columns = [
  {title: 'Title', key_name: 'title', order: 1, feature_activated: true},
  {title: 'Website', key_name: 'website', order: 2, feature_activated: false},
  {
    title: 'List Price',
    key_name: 'list_price',
    order: 3,
    feature_activated: true,
  },
  {
    title: 'Offer Percent',
    key_name: 'offer_percent',
    order: 4,
    feature_activated: true,
  },
  {title: 'Image', key_name: 'image', order: 5, feature_activated: true},
  {
    title: 'Offer Price',
    key_name: 'offer_price',
    order: 6,
    feature_activated: true,
  },
  {title: 'Status', key_name: 'status', order: 7, feature_activated: true},
  {
    title: 'Listing ID',
    key_name: 'listing_id',
    order: 8,
    feature_activated: true,
  },
  {
    title: 'Listing Status',
    key_name: 'listing_status',
    order: 9,
    feature_activated: true,
  },
  {title: 'Accounts', key_name: 'accounts', order: 10, feature_activated: true},
  {title: 'Currency', key_name: 'currency', order: 11, feature_activated: true},
  {title: 'Sold', key_name: 'sold', order: 12, feature_activated: true},
  {
    title: 'Goal Amount',
    key_name: 'goal_amount',
    order: 13,
    feature_activated: true,
  },
  {title: 'Actions', key_name: 'actions', order: 14, feature_activated: true},
];
