
import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';
const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'playstore';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false



export default class PlayStoreIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      applicationID:'',
      firstName: '',
      lastName:'',
      organizationalUnit: '',
      organization: '',
      cityLocality: '',
      stateProvince: '',
      countryCode: '',
      playstoreLink:''
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + `?key_group=${KeyGroup}`,'get','','');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        applicationID: configs['application_id'] || '',
        firstName: configs['keystore_first_name'] || '',
        lastName: configs['keystore_last_name'] || '',
        organizationalUnit: configs['keystore_organization_unit'] || '',
        organization: configs['keystore_organization_name'] || '',
        cityLocality: configs['keystore_city'] || '',
        stateProvince: configs['keystore_state'] || '',
        countryCode: configs['keystore_country_code'] || '',
        playstoreLink:configs['playstore_link'] ?? '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [
      this.state.applicationID,
      this.state.firstName,
      this.state.lastName,
      this.state.organizationalUnit,
      this.state.organization,
      this.state.cityLocality,
      this.state.stateProvince,
      this.state.countryCode,
      this.state.playstoreLink
    ];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      mobileIntegrationMixPanelEvent(MixpanelEvent.playstoreIntegration)
      this.deleteKeysAPI(configs)
    } else {
      this.setState({dataLoad: true})
      this.alertView(responseJson)
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete');
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast()
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // console.log('calling api');
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Playstore Integration</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Application ID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter application ID (com.tenantname.app)"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({applicationID: name})}
                value={this.state.applicationID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>First name</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter first name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({firstName: name})}
                value={this.state.firstName}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Last name</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter last name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({lastName: name})}
                value={this.state.lastName}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Oraganizational unit</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter oraganizational unit"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({organizationalUnit: name})}
                value={this.state.organizationalUnit}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Oraganization </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter oraganization name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({organization: name})}
                value={this.state.organization}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>City or Locality</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter city or locality"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({cityLocality: name})}
                value={this.state.cityLocality}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>State or Province </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter state or province"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({stateProvince: name})}
                value={this.state.stateProvince}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Country code (XX)</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter country code"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({countryCode: name})}
                value={this.state.countryCode}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Play Store link</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter your app Playstore link"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({playstoreLink: name})}
                value={this.state.playstoreLink}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'application_id',
  'keystore_first_name',
  'keystore_last_name',
  'keystore_organization_unit',
  'keystore_organization_name',
  'keystore_city',
  'keystore_state',
  'keystore_country_code',
  'playstore_link',
];
