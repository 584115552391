module.exports = {
  SideMenuArray:['Get  Started','Accounts','Listings','Collections','Promotions','Orders','Transactions','Users','Report','Media','Reviews & Ratings','App','Web','Communication','Settings','More'],
  SideAppSubMenuArray:['App Customisation', 'App Integrations', 'Strings Customisation','Buy App Templates'],
  SideWebSubMenuArray:['Web Customisation', 'Web Integrations', 'SEO','Change Templates','Add Pages & Blog','Strings Customisation'],
  fileTypeArray : ['.png', '.jpg', '.gif', 'video/*', '.json', '.pdf','image/*','.csv','.html','.txt','.csv','.css','.doc','.zip','.webp'],
  DeliverySideMenuArray:['Get Started','Delivery Orders','Branches','Fleets','Drivers','Rosters','Dashboard','Audit Logs','More'],
  fileTypeArray : ['.png', '.jpg', '.gif', 'video/*', '.json', '.pdf','image/*','.csv','.html','.txt','.csv','.css','.doc','.zip','.webp'],
  repeatArray: [
    { 'name': 'Daily', 'id': '1,2,3,4,5,6,7' },
    { 'name': 'Weekdays (Mon-Fri)', 'id': '2,3,4,5,6' },
    { 'name': 'Weekend (Sat-Sun)', 'id': '1,7' },
    { 'name': 'Custom', 'id': '' }],
  weekDays :[
    { 'name': 'Sun', 'id': 1 },
    { 'name': 'Mon', 'id': 2 },
    { 'name': 'Tue', 'id': 3 },
    { 'name': 'Wed', 'id': 4 },
    { 'name': 'Thu', 'id': 5 },
    { 'name': 'Fri', 'id': 6 },
    { 'name': 'Sat', 'id': 7 }],
  SideMenuAccountsArray:['Accounts', 'Account Categories', 'Account Attributes','Account Configuration'],
  SideMenuListingArray:['Listings', 'Categories', 'Attributes','Variant Types', 'Configuration'],
  SidePromotionsMenuArray:['Promotions', 'Coupons'],
  SideUserMenuArray:['Users','User Attributes'],
}
 