import React from 'react';
import {Dimensions, Text, View} from 'react-native';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import AppConstants from '../Constants/AppConstants';
import PrimaryButton from '../UI/Buttons/PrimaryButton';
import SaveButton from '../Component/SaveButton';
import SecondryButton from '../Component/SecondryButton';
import {useNavigate, useSearchParams} from 'react-router-dom';
import AppColor from '../HelperClasses/AppColor';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const OrderDetailsHeaderView = ({order_details, generate_button}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'listings';
  const navigate = useNavigate();
  return (
    <View style={[tableStyle.headerViewstyle, {display: 'flex', zIndex: 10}]}>
      <View style={[tableStyle.headerTitleContainerViewStyle, {margin: 0}]}>
        <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
          Order Details
        </Text>
        {order_details?.reference_number && (
          <Text
            style={[
              tableStyle.titleTextStyle,
              {margin: 0, marginLeft: 8, color: AppColor.AppNewGreen},
            ]}>
            {`# ${order_details?.reference_number}`}
          </Text>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginTop: itsMobileView ? 5 : 0,
          zIndex: 0,
        }}>
        {generate_button}
      </View>
    </View>
  );
};

export default OrderDetailsHeaderView;
