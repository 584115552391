import React from 'react';
import {generateNextID} from '../../../utils/genarateNextID';
import SelectFromList from '../../../Component/Form/SelectFromList';
import TextInput from '../../../Component/Form/TextInput';
import {ICONS} from '../../../Constants/Icons';

const MangopayTypeSetup = ({
  user_types,
  mangoPayUserType,
  setMangoPayUserType,
}) => {
  //
  const updateType = ({data, id}) => {
    setMangoPayUserType(
      mangoPayUserType.map(types => {
        if (types.id !== id) {
          return types;
        } else {
          return data;
        }
      }),
    );
  };

  return (
    <div className="  font-sans border rounded-lg mt-4 max-h-[60vh] overflow-auto">
      <div className="flex  justify-between gap-2 sticky top-0 bg-white p-4 ">
        <h2 className=" text-sm  font-semibold font-sans">User Type</h2>
      </div>

      <div className="   space-y-2 px-4 pb-4">
        {mangoPayUserType?.map(cn => {
          return (
            <div
              className="flex  items-center justify-start gap-2"
              key={cn?.id}>
              <p className="text-lg max-w-[200px] w-full">
                {user_types[cn.type]}
              </p>
              <TextInput
                value={cn?.category_id}
                placeholder={'Category ID'}
                type={'number'}
                onChangeText={val => {
                  updateType({id: cn.id, data: {...cn, category_id: val}});
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MangopayTypeSetup;
