export var PaymentMethodEnum = {
  stripe: 'stripe',
  stripe_web: 'stripe_web',
  payulatam: 'payulatam',
  billplz: 'billplz',
  opp: 'opp',
  razorpay: 'razorpay',
  paydunya: 'paydunya',
  mangopay: 'mangopay',
};

export var ShipmentMethodEnum = {
  stuart: 'stuart',
  storageHub: 'storage_hub',
  delivery: 'delivery',
};

export var CommissionTypeEnum = {
  supply: 'Supply',
  demand: 'Demand',
  cart: 'Cart',
};

export const TypeEnum = {
  accounts: 'Accounts',
  listings: 'Listings',
  users: 'Users',
  cart_attributes: 'cart_attributes',
};
