import AppConstants from '../Constants/AppConstants';
import colors from '../HelperClasses/AppColor';
import { b2, t1, t2 } from './TextTitleStyleSheet';
// 'use strict';
var React = require('react-native');
var {StyleSheet, Dimensions} = React;
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default StyleSheet.create({
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
  },
  subTitleStyle: {
    ...t2,
    marginTop: 0,
    marginRight: 16,
    marginLeft: 16,
  },
  onBoardingtitleStyle: {
    ...t2,
    color: colors.Appgray,
  },
  subViewStyle: {
    marginTop: 30,
    marginRight: 16,
    marginLeft: 16,
    flex: 1,
    flexDirection: itsMobileView ? 'column' : 'row',
    marginBottom: 10,
  },
  dottedViewStyle: {
    marginTop: 20,
    borderRadius: 10,
    // height: 40,
    minHeight: 40,
    width: 100,
    borderColor: colors.Appgreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderWidth: 2,
    width:'100%'
  },
  chooseFileTxtStyle: {
    ...t2,
    color: 'gray',
    textAlign: 'center',
  },
  txtFieldStyle: {
    outline:0,
    ...t1,
    textAlign: 'left',
    padding: 10,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
  },
  listingTxtFieldContainerStyle: {
    margin: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'space-between',
  },
  listingTextFieldStyle:{
    marginTop:0,
    marginLeft:16, 
    width:'80%',
    ...t1,
    textAlign: 'left',
    padding: 10,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    outline:'none',
  },
  previewFrameStyle: {
    borderWidth: 10,
    borderColor: 'black',
    width: 240,
    alignSelf: 'center',
    borderRadius: 40,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 10,
    backgroundColor:colors.AppNewGreen,
    height:460,
    overflow: 'hidden',
  },
  lineViewStyle: {
    width: 1,
    backgroundColor: 'rgba(237,246,249,1)',
    margin: 20,
  },
  horizontalLineViewStyle: {
    width: '98%',
    height: 1,
    backgroundColor: colors.SimonGray,
    margin: 20,
    marginTop: 30,
  },
  onBoardingViewStyle: {
    margin: 10,
    borderRadius: 10,
    marginTop: -20,
  },
  pageViewstyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
  },
  preViewImageStyle: {
    height: 320,
    borderRadius: 10,
    width: '100%',
    marginTop:40
  },
  onBoardingTxtStyle: {
    flexDirection: 'row',
    marginTop: -40,
    justifyContent: 'center',
  },
  greenDotViewStyle: {
    height: 6,
    width: 6,
    borderRadius:4,
    margin: 5,
    backgroundColor: colors.Appgreen,
  },
  grayDotViewStyle: {
    height: 6,
    width: 6,
    borderRadius: 4,
    margin: 5,
    alignSelf: 'center',
    backgroundColor: colors.Appgray,
  },
  submitViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    backgroundColor: colors.Appgreen,
    width: 80,
    marginBottom: 20,
    alignItems: 'center',
    marginLeft: 20,
  },
  iconImage: {
    height: 15,
    width: 10,
    marginTop: 2,
  },
  pageViewBottomViewStyle: {
    marginTop: 10,
    height: 90,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nextPreviousViewStyle: {
    width: 150,
    marginTop: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectImagesViewStyle: {
    width: 100,
    height: 100,
    justifyContent: 'space-between',
    margin: 16,
  },
  SelectedImageStyle: {
    height: 100,
    width: 100,
  },
  deleteViewStyle: {
    height: 20,
    width: 20,
    marginLeft: 80,
    top: -120,
  },
  deleteImageStyle: {
    height: 20,
    width: 20,
    borderColor: 'black',
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
  titleTxtStyle: {
    ...t1,
  },
  descriptionTxtFieldStyle: {
    ...t1,
    textAlign: 'left',
    padding: 10,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 80,
    borderRadius: 5,
  },
  upArraowIconStyle: {
    width: 12,
    height: 12,
    alignSelf: 'center',
    transform: [{rotate: '180deg'}],
    marginRight: 10,
    marginTop: 2,
  },
  downArraowIconStyle: {
    width: 12,
    height: 12,
    alignSelf: 'center',
    marginRight: 10,
    marginTop:5,
  },
  textFieldAddCurrencyStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 10,
    marginLeft: itsMobileView ? 0 : 10,
    width: itsMobileView  ? '100%' : '80%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    marginTop: itsMobileView ? 10 : 0,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: itsMobileView ? '100%' : '80%',
  },
  txtFieldStyleDropDown: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 5,
    margin: 5,
    marginTop: 10,
    width: '80%',
    color: colors.AppTitleBlack,
  },
  placeholderTxtStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 5,
    margin: 10,
    width: '80%',
    color: colors.placeholderColor,
  },
  showDropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: '100%',
    marginTop: 38,
    marginLeft: 0,
    zIndex: 9000,
  },
  statusTxtStyle: {
    ...b2,
    color: colors.Appgray,
    alignSelf: 'center',
    // marginTop: 22,
    marginLeft: 20,
  },
  descriptionViewStyle:{
    padding: 10,
    paddingLeft:20,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    borderRadius: 5,
    flex:1,
    minHeight:200
  }
});
