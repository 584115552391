import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Image,

} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';

import Header_View from '../HeaderView';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { AppToast } from '../../HelperClasses/AppToast';
import AddCountry from './AddCountry';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import closeIcon from '../../assets/closeBlackIcon.png';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import placeholder from '../../assets/pPlaceholder.png';
import SidePanView from '../../Component/SidePanView';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
const configViewRightMargin = -420

export default class AppCountries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appCountryArray: [],
      dateLoad: false,
      selectedCountryIndex: 0,
      isEdit: false,
      addCountryBool: false,
      stopPage: false,
      haveData: false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadAppCountryApi();
  }
  //MARK:-  APIS Method 
  loadAppCountryApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.appCountry, 'get', '', '');
    this.setState({ appCountryArray: [] });
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['countries'];
      for (let i = 0; i < objectD.length; i++) {
        this.state.appCountryArray.push(objectD[i]);
      }
      this.state.haveData = this.state.appCountryArray.length == 0 ? true : false
    }
    this.setState({ dateLoad: true });
  };
  deleteAppCountryApi = async id => {
    this.setState({ dateLoad: false });
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.appCountry + '/' + id, 'delete', '', '');
    this.setState({ dateLoad: true });
    if (responseJson['status'] == true) {
      this.alertView('Deleted');
      this.loadAppCountryApi();
    }
  };
  alertView(title) {
    AppToast();
  }
  //MARK:- Button Actions
  addCountryBtnAction() {
    this.setState({ addCountryBool: !this.state.addCountryBool })
    this.setState({ isEdit: false })
    this.loadAppCountryApi()
  }

  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.appCountryArray.findIndex(x => x.id === index)
    this.setState({ isEdit: true, selectedCountryIndex: id_Index })
    this.setState({ addCountryBool: true })
  }
  dltPaymentMethodBtnAction = index => {
    let msg = 'Do you want to go delete this country';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteAppCountryApi(index);
      }
    }
  }
  /*  UI   */

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
    if (this.state.appCountryArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />
      } else {
        return <View />
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.appCountryArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.appCountryArray[i]['id'],
              this.state.appCountryArray[i]['default'],
              this.state.appCountryArray[i]['order_by'],
              this.state.appCountryArray[i]['active'],
              this.state.appCountryArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white' }}
          />,
        )
      }
      return views
    }
  }
  columnCell = ({ item, index }) => {
    if (index == 3 || index == 1) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={item == false ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle}>
            {index == 1 ? item ? 'Yes' : 'No' : item ? 'Active' : 'Inactive'}
          </Text>
        </View>
      )
    } else if (index == 4) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity onPress={() => this.editButtonAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon} />
            </TouchableOpacity>
            <View style={{ width: 10 }} />
            <TouchableOpacity onPress={() => this.dltPaymentMethodBtnAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon} />
            </TouchableOpacity>
          </View>
        </View>
      )
    } else if (index == 0) {
      let id_Index = this.state.appCountryArray.findIndex(x => x.id === item)
      let name = this.state.appCountryArray[id_Index]['name']
      let flag = this.state.appCountryArray[id_Index]['flag_url']
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.mainTitleStyle}>{name}</Text>
          <Image style={{ width: 30, height: 20, marginLeft: 5 }} source={flag.length == 0 ? placeholder : { uri: flag }} />
        </View>
      )
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      )
    }
  }
  renderAddCountryView = () => {
    if (this.state.addCountryBool) {
      return <SidePanView
        dissmissView={() => this.setState({ addCountryBool: !this.state.addCountryBool })}
        showSidepanView={this.state.addCountryBool}
        customView={<View>
          <AddCountry
            isEdit={this.state.isEdit}
            countryData={this.state.appCountryArray[this.state.selectedCountryIndex]}
            backBtnAction={() => this.addCountryBtnAction()}
          />        </View>}
        title={this.state.isEdit ? 'Edit Country' : 'Add Country'}
      />
    } else { return <View /> }
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{ zIndex: 101 }}>
          {this.renderAddCountryView()}
        </View>
        <View style={{ zIndex: 10 }}>
          <Header_View
            title={'Countries'}
            backBtn={this.props.backBtn}
            saveBtnTitle={'Add Country'}
            saveBtn={() => this.addCountryBtnAction()}
          />
          <View style={tableStyle.listContainerView}>
            <ToastContainer />
            <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView style={styles.tableViewHeaderStyle}>
                  <this.columnDataView />
                </ScrollView>
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dateLoad} />
        </View>
      </SafeAreaView>
    )
  }
}
const constantWidth = windowWidth < fixedWidth ? 130 : windowWidth / 5
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: constantWidth,
    height: 40,
  },
  firstTableViewTitleViewStyle: {
    padding: 0,
    width: constantWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: constantWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  firstColumnTitleViewStyle: {
    width: constantWidth,
    padding: 10,
    paddingLeft: 20,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
});

const tableHeaderString = [
  'Name',
  'Default',
  'Order',
  'Status',
  'Action',
];
