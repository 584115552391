import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import ReactFileReader from 'react-file-reader';
import close_Icon from '../../assets/closeIcon.png';
import downArrow_Icon from '../../assets/downArrowIcon.png';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'extensions';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class WebGTM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      gtm: '',
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,  'get',);
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      console.log('configs', configs);
      this.setState({
        gtm: configs['gtm'] || null,
      });
    }
    this.setState({dataLoad: true});
  };
 
 
  addOrUpdateDetailApi = async () => {
    var configs = [];
    if (this.state.gtm.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: 'gtm',
        value: this.state.gtm,
        secured: false,
      };
      configs.push(fDict)
    }
    if (configs.length != 0) {
      const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }));
      if (responseJson['status'] == true) {
        this.deleteKeysAPI(configs);
      } else {
        this.setState({ dataLoad: true });
      }
    } else {
      this.deleteKeysAPI(configs);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < parameterKeys.length; q++) {
      deleteParms.push(parameterKeys[q]);
    }

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateDetailApi();
  }
  //MARK:-  UIs 

  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Google Tag Manager </Text>
        <Text style={appConfigStyle.subTitleStyle}>{AppConstants.plainTextString}</Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>            
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>GTM</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="1ABCDEF"
                placeholderTextColor={colors.placeholderColor}
                value={this.state.gtm}
                onChangeText={text => this.setState({gtm:text})}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let parameterKeys = [
  'gtm',
];
