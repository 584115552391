import networkService from '../Network/NetworkManager';
const APPURL = require('../Constants/URLConstants');

const getTenantCurrency = async () => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.currencies,
      'get',
    );
    return responseJson?.data?.currencies;
  } catch (error) {
    return [];
  }
};

export default getTenantCurrency;
