import React, {useRef, useState} from 'react';
import {ShipmentStatus} from '../../../Constants/Status';
import networkService from '../../../Network/NetworkManager';
import {AppToast} from '../../../HelperClasses/AppToast';
import AppLoader from '../../../HelperClasses/AppLoaderClasses/AppLoader';
import ToastPopup from '../../../HelperClasses/AppLoaderClasses/ToastPopup';
const APPURL = require('../../../Constants/URLConstants');

const ShipmentOrderStatus = ({order_details, shipment, load_order_details}) => {
  const selectRef = useRef(null);
  const [isLoading, setISLoading] = useState(false);

  //Status change function
  const status_change = async (status, order_details) => {
    if (status) {
      setISLoading(true);
      if (
        Number(status) !== 4 ||
        (Number(status) === 4 &&
          Object.keys(shipment?.pickup_address).length !== 0)
      ) {
        const update_data = {
          shipment: {
            status: Number(status),
          },
        };

        const responseJson = await networkService.networkCall(
          APPURL.URLPaths.order +
            `${order_details?.id}/shipments/${shipment?.id}/status`,
          'PATCH',
          JSON.stringify(update_data),
        );
        if (responseJson?.status) {
          setISLoading(false);

          AppToast(undefined, '.Status changed successfully');
          load_order_details();
        } else {
          setISLoading(false);

          AppToast(responseJson);
        }
      } else {
        setISLoading(false);
        ToastPopup({
          type: 'warning',
          message: 'Pickup Address not available. Please Add Pickup Address',
        });
      }
    }
  };

  return (
    <>
      {/* {isChangeStatusFetching && <CustomLoading />} */}
      <AppLoader enable={isLoading ? false : true} />

      {shipment?.next_status.length > 0 && (
        <div className="flex   justify-end">
          <select
            id="all_next_status"
            ref={selectRef}
            className="
                    block
                      max-w-[254px] w-full
                    rounded-button
                    border-primary
                     text-primary
                  "
            onChange={e => {
              if (e.target.value !== '') {
                status_change(e.target.value, order_details);
              }
            }}>
            <option selected value="">
              Change shipment status
            </option>
            {shipment?.next_status.length > 0 ? (
              shipment?.next_status.map(status => {
                return (
                  <>
                    <option key={status} value={status}>
                      {ShipmentStatus(status)}
                    </option>
                  </>
                );
              })
            ) : (
              <option disabled>Not available</option>
            )}
          </select>
        </div>
      )}
    </>
  );
};

export default ShipmentOrderStatus;
