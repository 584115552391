import React from 'react';

const TextArea = ({
  current_data,
  update_data,
  title,
  note,
  id,
  placeholder,
  type,
  isRequired,
}) => {
  return (
    <div className="space-y-1  ">
      <p
        className={[
          ' text-gray-600',
          isRequired &&
            "flex-none relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[12px] ",
        ].join(' ')}>
        {' '}
        {title}
      </p>
      <textarea
        id={id}
        key={id}
        cols={5}
        value={current_data}
        className="form-input py-2 w-full"
        onChange={e => {
          update_data(e.target.value);
        }}
        placeholder={placeholder}
      />
      {note && <p className="text-xs mt-2 italic">{note}</p>}
    </div>
  );
};

export default TextArea;
