import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Image,
  Dimensions,
  Alert,
  Platform,
  SafeAreaView,
} from 'react-native';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import edit_Icon from './../assets/editIcon.png';
import deleteIcon from './../assets/delete_icon.png';
import disableIcon from './../assets/disableIcon.png';
import Appload from './../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from './../HelperClasses/AppToast';
import AddCommissions from './AddCommission';
import EmptyListUI from '../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import HelperLinkViews from '../Component/HelperLinkView';
import {checkUserRoleModule} from '../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import {Gradient} from 'react-gradient';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import SettingStyleSheet from '../StyleSheets/SettingStyleSheet';
import BackBtnView from '../Component/BackBtnView';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('./../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class SellerCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      commissionsArray: [],
      dataLoad: false,
      addCommissionsBool: false,
      isEdit: false,
      commissionID: 0,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      showFilterView: false,
      selectedType: 'All',
    };
  }
  static propTypes = {
    addSaveBtnAction: PropTypes.func,
    backBtnAction: PropTypes.func,
  };
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewCommissionList, {
      type: MixPannelEnum.listView,
    });
    this.loadCommissionApi();
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.COMMISSIONS, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  //MARK:-  APIS Method 
  loadCommissionApi = async () => {
    this.state.commissionsArray = [];
    let path =
      this.state.selectedType == 'All'
        ? ''
        : `?type=${this.state.selectedType.toLowerCase()}`;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.addCommissions + path,
      'get',
    );
    // console.log('commission', responseJson);
    if (responseJson['status'] == true) {
      var commissionD = responseJson['data']['commissions'];
      for (let i = 0; i < commissionD.length; i++) {
        this.state.commissionsArray.push(commissionD[i]);
      }
      this.state.haveData =
        this.state.commissionsArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  deleteCommissionApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.addCommissions + '/' + id,
      'delete',
    );
    // console.log('currencies', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      AppToast('delete');
      this.loadCommissionApi();
    }
  };
  //MARK:-  Buttons 
  didSelectFilter(id) {
    this.state.selectedType = id;
    this.setState({showFilterView: false, dataLoad: false});

    this.loadCommissionApi(this.state.selectedType);
  }
  deleteCommissionAction = index => {
    if (Platform.OS === 'web') {
      const r = window.confirm('Do you want to go delete this commission');
      if (r == true) {
        this.deleteCommissionApi(index);
      }
    } else {
      Alert.alert(
        'Do you want to go delete this commission?',
        '',
        [
          {
            text: 'Yes',
            onPress: () => this.deleteCommissionApi(index),
          },
          {
            text: 'No',
          },
        ],
        {cancelable: false},
      );
    }
  };

  showEntriesDropDown() {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectDropDown = item => {
    this.setState({showDropDown: false, selectedEntriesCount: item});
  };
  statusBtnAction = index => {
    // console.log('statusBtnAction', index);
    //alert(index);
    if (Platform.OS === 'web') {
      alert(index);
    } else {
      Alert.alert(index);
    }
  };
  addCommissionBtnAction() {
    this.setState({addCommissionsBool: !this.state.addCommissionsBool});
    this.setState({isEdit: false});
    this.loadCommissionApi();
  }
  editButtonAction = index => {
    // console.log('commissionID', index);
    this.setState({isEdit: true, commissionID: index});
    this.setState({addCommissionsBool: true});
  };
  /*  UI   */
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.dropDownViewStyle}>
          <FlatList
            data={['10', '25', '50', 'all']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 99989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[
              CommonStyleSheet.dropdownCellItemTextStyle,
              {
                color:
                  item == this.state.selectedEntriesCount
                    ? colors.AppTitleBlack
                    : colors.Appgray,
              },
            ]}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        keyExtractor={(item, index) => index + 29989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.commissionsArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: 200}}>
            <EmptyListUI />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.commissionsArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.commissionsArray[i]['title'],
              this.state.commissionsArray[i]['default_value'],
              this.state.commissionsArray[i]['active'] == true
                ? 'Active'
                : 'Inactive',
              this.state.commissionsArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={styles.lineViewStyle}
            keyExtractor={(item, index) => index + 9969}
            key={'C'}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 2) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item != 'Active'
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 3) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  renderEditBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.writeP}
        onPress={() => this.editButtonAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.writeP ? edit_Icon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderDeleteBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.deleteCommissionAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? deleteIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderAddCommissionBtn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.addCommissionBtnAction()}>
              <Text style={tableStyle.saveBtnTxtStyle}>{'Add Commision'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  renderTypeView = () => {
    return (
      <View style={{position: 'relative', marginRight: 40, marginTop: 5}}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() =>
            this.setState({showFilterView: !this.state.showFilterView})
          }>
          <Text
            style={[
              CommonStyleSheet.tabBtnViewStyle,
              {
                color: this.state.showFilterView
                  ? colors.AppNewGreen
                  : colors.Appgray,
                marginTop: 5,
              },
            ]}>
            {this.state.selectedType}
          </Text>
          <View style={{marginLeft: 5}}>
            <Image
              style={
                this.state.showFilterView == true
                  ? [appConfigStyle.upArraowIconStyle, {marginTop: 5}]
                  : [appConfigStyle.downArraowIconStyle, {marginTop: 5}]
              }
              resizeMode="contain"
              source={downArrow_Icon}
            />
          </View>
        </TouchableOpacity>
        <View
          style={{
            backgroundColor: this.state.showFilterView
              ? colors.AppNewGreen
              : colors.TransparentColor,
            height: 2,
            width: '100%',
            marginTop: 5,
          }}
        />
        <this.renderFilterView />
      </View>
    );
  };
  renderFilterView = () => {
    if (this.state.showFilterView) {
      return (
        <View style={tableStyle.filterContainerViewStyle}>
          <View
            style={{
              display: this.state.showFilterView ? 'flex' : 'none',
              flexDirection: 'row',
            }}>
            <View style={tableStyle.filterViewStyle}>
              <FlatList
                data={['All', 'Supply', 'Demand', 'Cart']}
                renderItem={this.renderSortCell}
                extraData={this.state}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index + 129}
                key={'FLD'}
                style={{marginBottom: 10, width: 80}}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSortCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectFilter(item)}
        style={{
          margin: 8,
          backgroundColor:
            this.state.selectedType == item
              ? colors.SimonGray
              : colors.TransparentColor,
          padding: 5,
          borderRadius: 4,
        }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    );
  };
  render() {
    console.log('data load', this.state.dataLoad);
    if (this.state.addCommissionsBool) {
      return (
        <AddCommissions
          isEdit={this.state.isEdit}
          commissionID={this.state.commissionID}
          backBtnAction={() => this.addCommissionBtnAction()}
        />
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={[tableStyle.navigationViewStyle, {zIndex: 110}]}>
            <View style={tableStyle.headerContainer}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <TouchableOpacity
                  style={tableStyle.backBtnBackViewStyle}
                  onPress={() => this.props.navigate(-1)}>
                  <BackBtnView />
                </TouchableOpacity>
                <View style={SettingStyleSheet.headerViewStyle}>
                  <Text style={tableStyle.titleTextStyle}>{`Commissions`}</Text>
                </View>
              </View>
              <View style={{paddingLeft: 40, flexDirection: 'row'}}>
                {this.renderTypeView()}
                {this.renderAddCommissionBtn()}
              </View>
            </View>
          </View>
          <ToastContainer />
          <View style={[tableStyle.listContainerView, {zIndex: 10}]}>
            <ScrollView
              style={tableStyle.mainScrollViewStyle}
              horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView style={styles.tableViewHeaderStyle}>
                  <this.columnDataView />
                </ScrollView>
              </View>
            </ScrollView>
            <Appload enable={this.state.dataLoad} />
          </View>
          <HelperLinkViews title={'Commissions'} />
        </SafeAreaView>
      );
    }
    //}
  }
}

export default withRouter(SellerCommission);

const commonWidth = windowWidth / 4.9;
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : windowWidth / 4.7,
    height: 40,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / 4.7,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  lineViewStyle: {
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: colors.SimonGray,
  },
});

const tableHeaderString = ['Title', 'Default Value', 'Status', 'Action'];
