import networkService from '../../Network/NetworkManager';

const APPURL = require('../../Constants/URLConstants');

export const fetch_digital_assets = async ({listing_id, update_value}) => {
  const responseJson = await networkService.networkCall(
    APPURL.URLPaths.listingsModerate + `/${listing_id}/digital_contents`,
    'GET',
    '',
    '',
  );
  if (responseJson['status'] == true) {
    let response = responseJson.data?.digital_contents;
    response?.length > 0 ? update_value(response[0]) : update_value({});
  } else {
    console.log('Error', responseJson);
  }
};

export const get_download_url = async ({file_url}) => {
  // axios
  //   .post('/api/getS3SignedURL', {
  //     data: {
  //       urls: items.map(item => {
  //         return {url: item.file_url, downloadable: true};
  //       }),
  //     },
  //   })
  //   .then(res => {
  //     setIsLoading(null);

  //     window.open(res.data.result[0]);
  //   })
  //   .catch(error => {
  //     setIsLoading(null);

  //     console.log('====================================');
  //     console.log(error.response);
  //     console.log('====================================');
  //   });

  const responseJson = await networkService.networkCall(
    APPURL.URLPaths.S3SignedGetURL,
    'POST',
    JSON.stringify({urls: [{url: file_url, downloadable: true}]}),
    '',
  );
  if (responseJson['status'] == true) {
    window.open(responseJson.data.result[0]);
  } else {
    console.log('Error', responseJson);
  }
};
