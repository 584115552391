import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Dimensions,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from 'react-switch';
import {
  addMixpanelEvent,
  MixpanelEvent,
  revenuModelMixPanelEvent,
} from '../../Models/MixPannelEnum';
import SaveButton from '../../Component/SaveButton';
import closeIcon from '../../assets/closeBlackIcon.png';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import HeaderView from '../HeaderView';
import ToastPopup from '../../HelperClasses/AppLoaderClasses/ToastPopup';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';
let AccountTypesArray = ['Standard', 'Express'];

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class PaymentMethodsConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,

      switchValue: false,
      message: '',
      purchasedMessage: '',
      listingExhaustedmessage: '',
      writeP: AppConstants.defaultRead,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        switchValue: configs['wallet_enabled'] || false,
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [this.state.switchValue, this.state.message];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: i == 0 ? false : true,
        };
        // console.log('fDict ==> ', fDict);
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      revenuModelMixPanelEvent(MixpanelEvent.accountSubscription);
      this.deleteKeysAPI(configs);
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
    this.props.onCloseModal();
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson =
      parms?.length > 0
        ? await networkService.networkCall(urlParm, 'delete')
        : '';
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    ToastPopup({type: 'success', message: 'Updated successfully'});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  render() {
    return (
      <View
        style={{
          width: '100%',
          paddingTop: 10,
        }}>
        <HeaderView
          title={'Payment Methods Configuaration'}
          saveBtnTitle={'Save'}
          saveBtn={() => this.saveButtonAction()}
          backBtn={() => this.props.onCloseModal()}
        />
        <View
          style={{
            margin: 10,
          }}
        />
        {/* <Text style={appConfigStyle.subTitleStyle}>
          Manage account subscription below. By enabling this, every account
          owner will be verified a active subscription. If there is active
          subscription, the account allowed to post a listing. Your default
          payment gateway selected in Payment method will be used for
          Subscription Payment
        </Text> */}
        {/* <View style={appConfigStyle.horizontalLineViewStyle} /> */}
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -30}}>
              <View style={{flexDirection: 'row'}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() =>
                    this.setState({switchValue: !this.state.switchValue})
                  }
                  checked={this.state.switchValue}
                  height={22}
                  width={50}
                />
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={appConfigStyle.statusTxtStyle}>
                    {'Wallet enable'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{height: 220}} />
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = ['wallet_enabled'];
