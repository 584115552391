import React, {useEffect, useState} from 'react';
import {
  fetch_digital_assets,
  get_download_url,
} from '../functions/fetch_digital_assets.apis';

const DownloadAssets = ({listing_id}) => {
  const [digital_contents, setDigitalContents] = useState(null);

  // fetch digitall contents
  useEffect(() => {
    if (listing_id) {
      fetch_digital_assets({
        listing_id,
        update_value: value => {
          setDigitalContents(value);
        },
      });
    }
  }, [listing_id]);

  //
  const download_click = fil_url => {
    get_download_url({file_url: fil_url});
  };

  return (
    digital_contents !== null && (
      <div className="m-[10px] bg-white border  border-SimonGray p-[21px] rounded-xl ">
        <p> Digital assets</p>

        {/* contents */}
        {digital_contents?.content?.length > 0 ? (
          <div className=" mt-4 flex flex-wrap  gap-5           ">
            {digital_contents?.content?.map(item => {
              return (
                <button
                  className=" border-2  border-dashed rounded-lg px-4 py-2 border-primary   flex-col gap-0.5"
                  onClick={() => download_click(item.file_url)}>
                  <p className="text-primary text-base font-medium">Download</p>
                  <span className=" text-xs  font-extralight text-[gray]">
                    {item.file_name}
                  </span>
                </button>
              );
            })}
          </div>
        ) : (
          <p className="text-base font-semibold text-slate-900">
            Digital assets not found
          </p>
        )}
      </div>
    )
  );
};

export default DownloadAssets;
