import Moment from 'moment';
import AppConstants from '../../Constants/AppConstants';
import networkService from '../../Network/NetworkManager';
import {all_categories_map} from '../../utils/AllCategoriesMap';
import GithubSlugger, {slug as slugChecker} from 'github-slugger';

const APPURL = require('../../Constants/URLConstants');

export const addEditCollectionApi = async ({data, isEdit}) => {
  if (data.expirable === true) {
    let frmt = 'YYYY-MM-DDTHH:mm:ss';
    data.start_at = `${Moment(data.start_at).format(frmt)}Z`;
    data.end_at = `${Moment(data.end_at).format(frmt)}Z`;
  } else if (data.expirable === false) {
    delete data.start_at;
    delete data.end_at;
  }

  // Final checking and removing empty key
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      // Check if the value is empty or meets any other condition
      if (
        value === undefined ||
        value === null ||
        value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0)
      ) {
        delete data[key]; // Remove the field from the data object
      }
    }
  }

  //path
  var path = APPURL.URLPaths.collections;
  if (isEdit) {
    path = APPURL.URLPaths.collections + '/' + data.id;
  }
  const responseJson = await networkService.networkCall(
    path,
    isEdit ? 'put' : 'post',
    JSON.stringify({collection: data}),
  );
  if (responseJson.status == true) {
    //
  } else {
    throw new Error(responseJson);
  }
};

// getCollectionDetailAPI
export const getCollectionDetailAPI = async ({
  collectionID,
  current_state_values,
  update_state_values,
}) => {
  const responseJson = await networkService.networkCall(
    APPURL.URLPaths.collections + `/${collectionID}`,
    'get',
    '',
    '',
  );
  if (responseJson.status == true) {
    var collectionData = responseJson.data.collection;
    update_state_values(collectionData);
  }
};

// getManualDataAPI
export const getManualDataAPI = async ({
  collectionID,
  current_state_values,
  update_state_values,
  collection_type,
}) => {
  const responseJson = await networkService.networkCall(
    `${
      collection_type === 1
        ? APPURL.URLPaths.accountListings
        : APPURL.URLPaths.listings
    }1&type=${
      collection_type === 1 ? 'accounts' : 'listings'
    }&collection_id=tdc-${collectionID}`,
    'get',
    '',
    '',
  );
  if (responseJson.status == true) {
    var colletionManualData =
      collection_type === 1
        ? responseJson.data.accounts
        : responseJson.data.listings;
    update_state_values(colletionManualData);
  }
};
