import networkService from '../../Network/NetworkManager';
const APPURL = require('./../../Constants/URLConstants');

export const saveTranslateData = async ({
  translations_data,
  reference_type,
  translation_type,
  reference_id,
  closeFunc,
  onErrorHappen,
}) => {
  let translations_arr = [];
  for (let key in translations_data) {
    translations_arr.push({
      locale: key,
      value: translations_data[key],
    });
  }

  let payload = {
    translation: {
      reference_type: reference_type,
      translation_type: translation_type,
      reference_id: reference_id,
      values: translations_arr,
    },
  };

  var path = APPURL.URLPaths.translations;
  var method = 'PATCH';
  // console.log('params ==>', JSON.stringify({translation: pDict}))
  try {
    const responseJson = await networkService.networkCall(
      path,
      method,
      JSON.stringify(payload),
      '',
    );
    closeFunc();

    return responseJson;
  } catch (error) {
    onErrorHappen();
  }
};
