/* eslint-disable react/react-in-jsx-scope */
import {useEffect, useState, useRef} from 'react';
import {ICONS} from '../../Constants/Icons';

const ImageSelect = ({
  current_data,
  update_data,
  title,
  note,
  component_style,
  title_style,
  field_style,
  close_button_style,
  button_style,
  image_style,
  isRequired,
}) => {
  // current_data sample
  // {
  //   value: "image_path",
  //     type: "file-upload|empty",
  //       file:"image file or null"
  // }
  return (
    <>
      <div className={'w-full space-y-4'}>
        <p
          className={[
            ' text-gray-600',
            isRequired &&
              "flex-none relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[12px] ",
          ].join(' ')}>
          {title}
        </p>
        {/* Current Data */}
        <div className={' w-auto h-auto relative   '}>
          {/* Image */}
          {current_data && current_data?.value.replace(/\s/g, '').length > 0 && (
            <div className=" relative w-[100px]">
              <img
                src={current_data?.value}
                alt="favicon"
                className={
                  'rounded   shadow-md h-[100px] w-[100px] object-cover'
                }
              />
              {/* Current Data remove */}
              <button
                type="button"
                className={
                  ' absolute top-1 right-1 text-primary hover:text-red-500  '
                }
                onClick={e => {
                  update_data('');
                }}>
                {ICONS.close_icon}
              </button>
            </div>
          )}

          <input
            required
            id={`image_select`}
            type="file"
            className=" hidden"
            accept=".png , .jpg ,.svg , .ico"
            placeholder=""
            onChange={e => {
              e.stopPropagation();
              e.target.files?.length > 0 &&
                update_data({
                  value: URL.createObjectURL(e.target.files[0]),
                  type: 'file-upload',
                  files: e.target.files[0],
                });
            }}
          />

          {/* Add Image Button */}
          {(current_data == '' ||
            current_data?.value?.replace(/\s/g, '').length === 0) && (
            <button
              type="button"
              onClick={() => document.getElementById(`image_select`).click()}
              className={
                ' w-[100px]  h-[100px] flex flex-col justify-center items-center      text-sm    border border-primary border-dashed rounded-lg'
              }>
              <span className="p-[10px] bg-primary rounded-full">
                {ICONS.camera_icon}
              </span>

              <span className="mt-2"> Add photo</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageSelect;
