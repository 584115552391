import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Image,
  Dimensions
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import {MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'general';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class BranchIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      branchAppID: '',
      branchLiveKey: '',
      branchLiveSecretKey: '',
      branchURIScheme: '',
      branchLinkDomain: '',
      branchDestinationLink: '',
      branchAlternateLinkDomain: '',
      branchLinkDescription: '',
      inviteFriendTitle: '',
      inviteFriendDescription: '',
      hideShowLivekey: false,
      hideShowSecretkey: false,
    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general', 'get',  '', '');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      console.log('this.state.configs ====>', configs);
      this.setState({
        branchAppID: configs['branch_app_id'] || '',
        branchLiveKey: configs['branch_key'] || '',
        branchLiveSecretKey: configs['branch_secret'] || '',
        branchDestinationLink: configs['branch_landing_link'] || '',
        branchURIScheme: configs['uri_scheme'] || '',
        branchLinkDomain: configs['branch_link_domain'] || '',
        branchAlternateLinkDomain:
          configs['branch_alternate_link_domain'] || '',
        branchLinkDescription: configs['branch_link_description'] || '',
        inviteFriendTitle: configs['invite_friends_collection_title'] || '',
        inviteFriendDescription:
          configs['invite_friends_collection_description'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async filesData => {
    var configs = [];
    let valueArray = [
      this.state.branchAppID,
      this.state.branchLiveKey,
      this.state.branchLiveSecretKey,
      this.state.branchDestinationLink,
      this.state.branchLinkDomain,
      this.state.branchAlternateLinkDomain,
      this.state.branchLinkDescription,
      this.state.inviteFriendTitle,
      this.state.inviteFriendDescription,
      this.state.branchURIScheme,
    ];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: i == 2 ? true : false,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config,  'post', JSON.stringify({configs: configs}))
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      mobileIntegrationMixPanelEvent(MixpanelEvent.branchpIntegration)
      this.deleteKeysAPI(configs)
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = []
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBranchButtonAction() {
    // console.log('calling api');
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  hideShowEyeBtnAction(id) {
    if (id == 1) {
      this.setState({hideShowLivekey: !this.state.hideShowLivekey});
    } else {
      this.setState({hideShowSecretkey: !this.state.hideShowSecretkey});
    }
  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Branch Integration</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plain2TextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>App id</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter app id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({branchAppID: name})}
                value={this.state.branchAppID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Live key</Text>
            </View>
            <View style={CommonStyleSheet.customTxtFieldViewStyle}>
              <TextInput
                style={[CommonStyleSheet.customTxtFieldStyle,{outline:0}]}
                placeholder="Enter live key"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({branchLiveKey: name})}
                value={this.state.branchLiveKey}
                secureTextEntry={!this.state.hideShowLivekey}
              />
              <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(1)}>
                <Image
                  style={styles.hideShowIconStyle}
                  resizeMode="contain"
                  source={this.state.hideShowLivekey ? showIcon : hideIcon}
                />
              </TouchableOpacity>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Live secret</Text>
            </View>
            <View style={CommonStyleSheet.customTxtFieldViewStyle}>
              <TextInput
                style={[CommonStyleSheet.customTxtFieldStyle,{outline:0}]}
                placeholder="Enter live secret"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({branchLiveSecretKey: name})}
                value={this.state.branchLiveSecretKey}
                secureTextEntry={!this.state.hideShowSecretkey}
              />
              <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(2)}>
                <Image
                  style={styles.hideShowIconStyle}
                  resizeMode="contain"
                  source={this.state.hideShowSecretkey ? showIcon : hideIcon}
                />
              </TouchableOpacity>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>URI Scheme</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter uri Scheme"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({branchURIScheme: name})}
                value={this.state.branchURIScheme}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Link domain</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter link domain"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({branchLinkDomain: name})}
                value={this.state.branchLinkDomain}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Alternate link domain
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter alternate link domain"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name =>
                  this.setState({branchAlternateLinkDomain: name})
                }
                value={this.state.branchAlternateLinkDomain}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Link description</Text>
              <TextInput
                style={[appConfigStyle.descriptionTxtFieldStyle,{outline:0}]}
                placeholder="Enter link description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name =>
                  this.setState({branchLinkDescription: name})
                }
                value={this.state.branchLinkDescription}
                multiline={true}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Invite friends </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({inviteFriendTitle: name})}
                value={this.state.inviteFriendTitle}
              />
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name =>
                  this.setState({inviteFriendDescription: name})
                }
                value={this.state.inviteFriendDescription}
                multiline={true}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Branch destination link(optional) 
                <Text style={CommonStyleSheet.helperTextStyle}>
                    {`  This is applicable for web app users`}
                </Text>
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter branch destination link"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({branchDestinationLink: name})}
                value={this.state.branchDestinationLink}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  hideShowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
  },
});

let keysArray = [
  'branch_app_id',
  'branch_key',
  'branch_secret',
  'branch_landing_link',
  'branch_link_domain',
  'branch_alternate_link_domain',
  'branch_link_description',
  'invite_friends_collection_title',
  'invite_friends_collection_description',
  'uri_scheme'
];
