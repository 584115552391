import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstant from '../Constants/AppConstants';
import networkService from '../Network/NetworkManager';
import { ToastContainer } from 'react-toastify';

import { checkUserRoleModule } from '../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../Models/Enum';
import { AppToast } from '../HelperClasses/AppToast';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = 800;

export default class Channel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: true,
    }
  }
  componentDidMount() {
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general', 'get', '', '');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      if (configs['auth_type'] == 2 || configs['auth_type'] == 3) {
        // titleArray.push('App Country')
      }
    }
    this.setState({ dataLoad: true });
  }

  //MARK:-  Buttons Action Method 
  submitButtonAction() { }

  backBtnHandler() {

    this.setState({ selectedTabIndex: 0 });
  }
  /*  UI   */
  renderChannelView() {
    var views = [];
    for (let a = 0; a < 10; a++) {
      views.push(<View>
        <View style={styles.subChannelViewsStyle} >
        </View>
      </View>)
    }
    return views;
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={styles.mainView}>
          <ScrollView style={{ width: '100%' }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {this.renderChannelView()}
            </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }

}
const styles = StyleSheet.create({
  Container: {
    height: '100%',
    backgroundColor:colors.AppLightGreen
  },
  mainView: {
    padding: 20,
    margin: 10,
    marginTop: 0,
    paddingLeft: 20,
    borderRadius: 10,
    height: windowWidth < fixedWidth ? '100%' : windowHeight - 115,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignItems: 'flex-start',
    paddingBottom: 0,
  },
  subChannelViewsStyle: {
    margin: 16,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    minHeight: 250,
    width: 280,
    alignItems: 'center',
    padding: 5,
    paddingRight: 10,
  },

});
