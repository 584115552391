import React from 'react';

const TutorialCard = ({image, title, onClickHandler, video_url}) => {
  return (
    <div className=" w-full border border-gray-200 rounded bg-[#FAFBFB]">
      <iframe
        width="100%"
        className="max"
        height="200px"
        src={video_url}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
      <div
        className="flex items-center justify-start  text-base font-semibold text-gray-800 gap-2   p-4"
        onClick={onClickHandler}>
        <p>{title}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          data-slot="icon"
          class="w-6 h-6">
          <path
            fill-rule="evenodd"
            d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default TutorialCard;
