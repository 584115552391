import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
  TextInput,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import {Gradient} from 'react-gradient';
import {AppToast} from '../../HelperClasses/AppToast';
import selectedListIcon from '../../assets/selectedListIcon.png';
import AppConstants from '../../Constants/AppConstants';
import {
  checkUserRoleModule,
  dateConversionFromString,
} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import closeIcon from '../../assets/closeIcon.png';
import {addMixpanelEvent, MixPannelEnum} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let perPage = 50;
export default class AuditLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auditLogArray: [],
      dataLoad: false,
      stopPage: false,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      totalRecords: 0,
      searchKey: '',
      typingTimeout: 0,
      showSearchView: false,
    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.getApi();
    this.mixPannelInfo();
  }
  mixPannelInfo() {
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.auditLog}`, {
      page: pageNo,
    });
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  getApi() {
    pageNo = 1;
    this.loadAuditLogApi();
  }
  //MARK:-  APIS Method 
  loadAuditLogApi = async type => {
    this.setState({dataLoad: false});
    var path = `?page=${pageNo}&per_page=${perPage}`;
    if (this.state.searchKey.length != 0) {
      path = path + `&search_key=${this.state.searchKey}`;
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.deliveryAuditLog + path,
      'get',
    );
    this.setState({auditLogArray: []});
    if (responseJson['status'] == true) {
      var driverD = responseJson['data']['audit_logs'];
      this.state.totalRecords = responseJson['data']['total_records'];
      // console.log('totalRecords',this.state.totalRecords)
      if (driverD.length != 0) {
        this.setState({stopPage: false});
        for (let objc of driverD) {
          this.state.auditLogArray.push(objc);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.auditLogArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({addDriverBool: false});
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadAuditLogApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadAuditLogApi();
      }
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  onSearchTextChange = text => {
    this.setState({searchKey: text});
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(
        function () {
          this.getApi();
        }.bind(this),
        1000,
      ),
    });
  };
  onCancelText() {
    if (this.state.searchKey.length != 0) {
      this.state.searchKey = '';
      this.getApi();
    }
    this.setState({showSearchView: false});
  }
  /*  UI   */

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.auditLogArray.length; i++) {
      views.push(
        <FlatList
          data={[
            this.state.auditLogArray[i]['user_name'],
            this.state.auditLogArray[i]['user_email'],
            this.state.auditLogArray[i]['action'],
            dateConversionFromString(this.state.auditLogArray[i]['created_at']),
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
          keyExtractor={index => index + 9989}
          key={'H'}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    //  if (index == 0) {
    //     return (
    //       <View style={styles.columnTitleViewStyle}>
    //         <Text style={tableStyle.mainTitleStyle}>{item}</Text>
    //       </View>
    //     );
    //   } else {
    return (
      <View style={styles.columnTitleViewStyle}>
        <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
          {item}
        </Text>
      </View>
    );
    // }
  };
  gradientButtons = name => {
    if (this.state.auditLogArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />;
      } else if (
        name['id'] == 1 &&
        this.state.auditLogArray.length == 0 &&
        pageNo > 1
      ) {
        return <View />;
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{height: 30, width: 100, borderRadius: 5, marginRight: 30}}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={CommonStyleSheet.NextBtnStyle}>
                  {name['name']}
                </Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };

  renderHeaderBtn = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          zIndex: 100,
          marginTop: itsMobileView ? 10 : 0,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          <View style={{width: 10}} />
          <View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Image
                style={tableStyle.viewIconsStyle}
                source={selectedListIcon}
              />
              <Text
                style={[
                  CommonStyleSheet.plainSubTextStyle,
                  {paddingLeft: 5, color: colors.AppNewGreen},
                ]}>
                Audit Logs
              </Text>
            </View>
            <View
              style={{
                backgroundColor: colors.AppNewGreen,
                height: 2,
                width: '100%',
                marginTop: 5,
              }}
            />
          </View>
        </View>
      </View>
    );
  };
  searchViewRender = () => {
    var sView = [];
    sView.push(
      <View style={{width: itsMobileView ? '100%' : '95%'}}>
        <TextInput
          style={{
            width: '100%',
            marginLeft: 0,
            height: 25,
            paddingLeft: 5,
            paddingRight: 18,
            outline: 'none',
          }}
          placeholder="Type to search..."
          placeholderTextColor={colors.placeholderColor}
          value={this.state.searchKey}
          autoFocus={this.state.showSearchView}
          onChangeText={text => this.onSearchTextChange(text)}
        />
        <TouchableOpacity
          style={tableStyle.textBoxButton}
          onPress={() => this.onCancelText()}>
          <Image style={{width: 16, height: 16}} source={closeIcon} />
        </TouchableOpacity>
      </View>,
    );
    return (
      <View style={[tableStyle.searchBarViewStyle, {marginLeft: 0}]}>
        {sView}
      </View>
    );
  };
  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View>{/* <this.renderHeaderBtn /> */}</View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <this.searchViewRender />
        </View>
      </View>
    );
  };
  EmptyListRender = () => {
    if (this.state.auditLogArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <EmptyListUI
              showImage={true}
              titleString={'No audit log found'}
              subtitleString={''}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };

  renderListView = () => {
    return (
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  renderBottomView = () => {
    if (this.state.totalRecords > perPage) {
      return (
        <View style={tableStyle.bottomViewStyle}>
          <this.gradientButtons name={'Next'} id={1} />
          <this.gradientButtons name={'Previous'} id={2} />
        </View>
      );
    } else {
      return <View />;
    }
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <this.renderHeaderView />
        <View style={tableStyle.containerMainView}>
          <this.renderListView />
          <this.EmptyListRender />
          <this.renderBottomView />
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const commonWidth = windowWidth / 4.24;
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
  },
});

const tableHeaderString = ['User Name', 'User Email', 'Action', 'Timestamp'];
