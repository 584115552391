import React from 'react';
import {View} from 'react-native';
import Switch from 'react-switch';
import colors from './../HelperClasses/AppColor';

const SwitchButton = ({isChecked, setIsChecked}) => {
  return (
    <View style={{flexDirection: 'row', marginLeft: 20}}>
      <Switch
        onColor={colors.AppNewGreen}
        onChange={() => setIsChecked(!isChecked)}
        checked={isChecked}
        height={22}
        width={50}
      />
    </View>
  );
};

export default SwitchButton;
