import React, {useEffect, useState} from 'react';
import ImageSelect from '../../UI/Form/ImageSelect';
import TextInput from '../../UI/Form/TextInput';
import HeaderView from '../HeaderView';
import {ToastContainer} from 'react-toastify';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';
import ToastPopup from '../../HelperClasses/AppLoaderClasses/ToastPopup';
import {checkGroupForm} from './actions/checkGroupForm';
import {createNewAttrGroup} from './actions/attributeGroupActions';
import BooleanField from '../../UI/Form/BooleanField';
import {loadCategoriesApi} from '../../Actions/getCategories';
import MultiSelectFromList from '../../Component/Form/MultiSelectFromList';
import {loadAttributesAction} from '../../Actions/getAttributes';
import {useSearchParams} from 'react-router-dom';

const AttributeGroup = ({onCloseModal}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'listings';
  const [attr_group_form, setAttrGroupForm] = useState({
    icon_path: '',
    name: '',
    order_by: 1,
    type: type,
    active: true,
  });
  const [selected_attr, setSelectedAttr] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [attr_list, setAttrList] = useState([]);

  // onUpdateForm
  const onUpdateForm = ({key, value}) => {
    setAttrGroupForm({
      ...attr_group_form,
      [key]: value,
    });
  };

  // saveListingButtonAction
  const saveButtonAction = () => {
    const checkup = checkGroupForm({
      form_data: attr_group_form,
    });
    if (checkup) {
      createNewAttrGroup({
        form_data: attr_group_form,
        selected_attr,
        setIsLoading,
        onSuccess,
      });
    }
  };

  const onSuccess = () => {
    ToastPopup({
      type: 'success',
      message: 'Attribute Group Added Successfully',
    });
    onCloseModal();
  };

  const load_attr_list = async () => {
    const attr = await loadAttributesAction({type});
    setAttrList(attr);
  };

  //
  useEffect(() => {
    load_attr_list();
  }, []);

  return (
    <div className="  w-full py-4">
      <HeaderView
        title={'Attribute Group'}
        saveBtnTitle={'Save'}
        saveBtn={() =>
          !isLoading
            ? saveButtonAction()
            : ToastPopup({type: 'warning', message: 'Please wait...'})
        }
        backBtn={() => onCloseModal()}
      />
      {/* add Listing form */}
      <div className="border border-gray-200 rounded-md py-4 px-5 w-[97%] m-3 flex flex-col gap-3 ">
        {/* Image */}
        <ImageSelect
          title={'Image'}
          current_data={attr_group_form?.icon_path}
          update_data={value => {
            onUpdateForm({key: 'icon_path', value: value});
          }}
        />
        {/* Title */}
        <TextInput
          title={'Name'}
          current_data={attr_group_form?.name}
          placeholder={'Group Name'}
          type={'text'}
          update_data={data => onUpdateForm({key: 'name', value: data})}
          isRequired={true}
        />
        {/* Active */}
        <BooleanField
          title={'Active'}
          current_data={attr_group_form?.active}
          update_data={data => onUpdateForm({key: 'active', value: data})}
        />
        {attr_list?.length > 0 && (
          <MultiSelectFromList
            title={'Select Attributes'}
            default_text={'Select Attributes...'}
            list={attr_list?.map(ct => ({value: ct?.id, label: ct?.name}))}
            setValue={setSelectedAttr}
            selected_value={selected_attr}
          />
        )}
      </div>

      <ToastContainer />
      <AppLoader enable={!isLoading} />
    </div>
  );
};

export default AttributeGroup;
