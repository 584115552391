import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
// import Domain from '../AppAcess/Domain/Domain';
// import SetupInformation from '../AppAcess/SetupInformation/SetupInformation';
// import Notifications from '../AppAcess/Notifications/Notifications';
// import advancedSettings from '../AppAcess/AdvancedSettings/AdvancedSettings';

import colors from './../HelperClasses/AppColor';
import AppConstants from '../Constants/AppConstants';
import tickIcon from './../assets/tickIcon.png';
import networkService from '../Network/NetworkManager';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let listArray = [
  {
    title: '1. Customise Website (Editor)',
    href: 'https://tradly.app/editor',
    isOpenNewTab: true,
  },
  {title: '2. Configure account category', href: '/account-category'},
  {title: '3. Listing category', href: '/listing-category'},
  {title: '4. Set up shipping / Service Methods', href: '/delivery'},
  {title: '5. Set up payments', href: '/payments'},
];

let b2cArray = [
  {title: '1. Add Listings (Products)', href: '/add-listing'},
  {
    title: '2. Customise template',
    href: 'https://tradly.app/editor',
    isOpenNewTab: true,
  },
  {title: '3. Shipping / Service Methods', href: '/delivery'},
  {title: '4. Payment Methods', href: '/payments'},
];
export default class RightView extends Component {
  static propTypes = {
    index: PropTypes.number,
    backBtn: PropTypes.func,
    didSelectIndex: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      completeArray: [false, false, false, false, false],
    };
  }
  componentDidMount() {
    this.loadConfigApi();
    this.loadCatApi(1);
    this.loadCatApi(2);
    this.loadShippingApi();
    this.loadPaymentApi();
  }
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=onboarding',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      if (configs['app_color_primary']) {
        AppConstants.appType
          ? (this.state.completeArray[1] = true)
          : (this.state.completeArray[0] = true);
      }
      this.setState({updateUI: !this.state.updateUI});
    }
  };
  loadCatApi = async type => {
    let path = type == 2 ? 'listings' : 'accounts';
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.categories + path,
      'get',
    );
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      if (listD.length != 0) {
        if (type == 1) {
          this.state.completeArray[2] = true;
        } else {
          AppConstants.appType
            ? (this.state.completeArray[0] = true)
            : (this.state.completeArray[1] = true);
        }
        this.setState({updateUI: !this.state.updateUI});
      }
    }
  };
  loadShippingApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tenantShippingMethod,
      'get',
    );
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['shipping_methods'];
      if (listD.length != 0) {
        AppConstants.appType
          ? (this.state.completeArray[2] = true)
          : (this.state.completeArray[3] = true);
      }
      this.setState({updateUI: !this.state.updateUI});
    }
  };
  loadPaymentApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tenantPaymentMethod,
      'get',
    );
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['payment_methods'];
      if (listD.length != 0) {
        AppConstants.appType
          ? (this.state.completeArray[3] = true)
          : (this.state.completeArray[4] = true);
      }
      this.setState({updateUI: !this.state.updateUI});
    }
  };

  ListView = props => {
    return (
      <FlatList
        data={AppConstants.appType ? b2cArray : listArray}
        horizontal={false}
        renderItem={this.renderDropDown}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
      />
    );
  };
  renderDropDown = ({item, index}) => {
    let isComplete = this.state.completeArray[index];
    return (
      <TouchableOpacity
        style={styles.viewStyle}
        onPress={() => this.props.didSelectIndex(item)}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text
            style={[CommonStyleSheet.themeColorTextStyle, {textAlign: 'left'}]}>
            {item.title}
          </Text>
          <View
            style={
              isComplete
                ? styles.tickViewStyle
                : styles.unCompleteStatusViewStyle
            }>
            <Image
              style={{
                width: 12,
                height: 12,
                display: isComplete ? 'flex' : 'none',
              }}
              source={tickIcon}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  render() {
    return (
      <View style={styles.container}>
        <View style={{marginTop: 5, marginBottom: 5}}>
          <this.ListView />
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.AppWhite,
    width: itsMobileView ? '90%' : '100%',
    flex: 1,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.Lightgray,
  },
  viewStyle: {
    margin: 10,
    height: 40,
    marginBottom: 5,
    backgroundColor: colors.AppWhite,
    justifyContent: 'center',
    padding: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 5,
    borderRadius: 5,
  },
  tickViewStyle: {
    width: 24,
    height: 24,
    backgroundColor: colors.Appgreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
  unCompleteStatusViewStyle: {
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    borderColor: colors.SimonGray,
    borderWidth: 2,
  },
});
