import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
  TextInput,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
// import TranslationFields from './TranslationFields'
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import { ToastContainer} from 'react-toastify';

import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import gTick_Icon from './../../assets/grayTickIcon.png';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import { TranslateEnum } from '../../Models/TranslateEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false
const textFieldWidth =  itsMobileView ? windowWidth/1.4  : windowWidth/5.2;

export default class NotificationTransaltion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      name: '',
      dataLoad: false,
      id: '',
      selectedTabIndex:0,
      selectedLanguage:0,
      languageArray:[],
      selectedValue:{},
      valueIndex: 0,
      languageFieldArray:[],
      updateUI:false,
      translationValues: [],
      reload:false,
      langaugeIndex:0,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    data: PropTypes.any,
    isEdit: PropTypes.bool,
    translationRef:PropTypes.string,
    type:PropTypes.string,
  };
  componentDidMount() {
    this.loadLanguageApi();
    this.loadApis();
  }
  loadApis() {
    // this.loadValuesApi()
  }
    //MARK:-  APIs Method 
  loadLanguageApi = async () => {
    let dict = {
      name: 'Default',
    }
    this.getTranslationsApi()
    this.state.languageArray.push(dict);
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tanantslanguage, 'get', '', '');
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages'];
      for (let obj of ppData) {
        this.state.languageArray.push(obj);
      }
      if (this.state.languageArray.length != 0) {
        this.callValuesApi();
      }
      
      this.setState({ updateUI: !this.state.updateUI,dataLoad: true});
    } else {
      this.setState({ dataLoad: true });
    }
  };

  getTranslationsApi = async () => {
    // this.setState({dataLoad: false});
    if (this.props.type == 'order') {
      for (let obj of this.state.languageArray) {
        let valueAr = [];
        for (let a = 0; a< defaultOrderValue.length; a++) {
          let dic = {
            'id': a,
            'key': keysOrderArray[a],
            'value': defaultOrderValue[a],
            'locale': 'default',
          }
          this.state.selectedValue = dic;
          valueAr.push(dic)
        }
        this.state.languageFieldArray.push(valueAr)
      }
    } else {
      for (let obj of this.state.languageArray) {
        let valueAr = [];
        for (let a = 0; a< defaultNotificationValue.length; a++) {
          let dic = {
            'id': a,
            'key': keysNotificationArray[a],
            'value': defaultNotificationValue[a],
            'locale': 'default',
          }
          this.state.selectedValue = dic;
          valueAr.push(dic)
        }
        this.state.languageFieldArray.push(valueAr)
      }
    }
    // console.log('this.state.languageFieldArray',this.state.languageFieldArray)
    this.setState({updateUI: !this.state.updateUI});
    this.setState({dataLoad: false});
  };
  callValuesApi(){
    if (this.state.langaugeIndex < this.state.languageArray.length) {
      let code = this.state.langaugeIndex == 0 ? 'default' : this.state.languageArray[this.state.langaugeIndex]['code']
      this.loadValuesApi(code);
    } else {
      this.setState({reload: true});
      this.setState({dataLoad: true});
      this.setState({updateUI: !this.state.updateUI});
    }
  }
  loadValuesApi = async (id) => {
    console.log(id ,'this.state.langaugeIndex', this.state.langaugeIndex);
    let refType = this.props.type == 'order' ? 'orders' : 'notifications';
    let path = `?key_group=${refType}`
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + path, 'get', '', '','',id);
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['configs'];
      console.log(id ,'this.state.ppData', ppData);

      var valAry = [];
      let keysA = this.props.type == 'order' ? keysOrderArray : keysNotificationArray;
      let valueA = this.props.type == 'order' ? defaultOrderValue : defaultNotificationValue;

      for (let a = 0; a< keysA.length; a++) {
        let pDic =  keysA[a];
        let dic = {
          'id': this.state.langaugeIndex,
          'key': pDic,
          'value': ppData[pDic] ?? valueA[a],
          'locale': id,
        }
        valAry.push(dic)
      }
      this.state.languageFieldArray.push(valAry)
      this.state.langaugeIndex = this.state.langaugeIndex + 1
      this.setState({updateUI: !this.state.updateUI});
      this.callValuesApi();
    } else {
      this.setState({ dataLoad: true });
    }
  }
  translateApi = async () => {
    this.setState({dataLoad: false});
    var values = [];
    var llAry = [... this.state.languageFieldArray];
    llAry.splice(0,1)
    let refType = this.props.type == 'order' ? 'orders' : 'notifications';
    for (let obj of llAry) {
      for (let dic of obj) {
        let fDict = {
          value: dic['value'],
          locale: dic['locale'],
          key_group: refType,
          key: dic['key'],
        };
        values.push(fDict)
      }
    }
    var path = APPURL.URLPaths.configsTranslations;
    var method = 'POST';
    const responseJson = await networkService.networkCall( path,method, JSON.stringify({configs: values}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      console.log('responseJson', responseJson)
      // this.loadApis();
      this.customAlert();
    }
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  didSelectMenuItem = index => {
    this.state.selectedLanguage = 0;
    this.state.selectedValue = this.state.ListingArray[index];
    this.state.valueIndex = index
    this.setState({dataLoad:false, updateUI: !this.state.updateUI});
  };
  didChangeTextField(text,id){    
    let code = this.state.languageArray[id]['code'];
    let index = this.state.translationValues.findIndex(x => x.locale === code);
    let vDic = {'locale': code, value: text}
    this.state.translationValues[index] = vDic;
    this.setState({updateUI: !this.state.updateUI});
  }
  // UI Renders
  saveButtonAction() {
    // this.setState({dataLoad: false});
    var validateValue = ''
    for (let obj of this.state.languageFieldArray) {
      for (let dic of obj) {
        if (dic['key'] == keysNotificationArray[0]) {
          if (!dic['value'].includes("{account_name}")) {
            validateValue = 'Account follow notification should contain {account_name}';
          }
        } else if (dic['key'] == keysNotificationArray[1]) {
          if (!dic['value'].includes("{user_name}")) {
            validateValue = 'Listing follow notification should contain {user_name}';
          }
        } else if (dic['key'] == keysNotificationArray[2]) {
          if (!dic['value'].includes("#{order_number}")) {
            validateValue = 'Order status follow notification should contain #{order_number}';
          }
          if (!dic['value'].includes("{order_status}")) {
            validateValue = 'Order status follow notification should contain {order_status}';
          }
        }
      }
    }
    if (validateValue.length == 0) {
      this.translateApi();
    }else {
      AppToast(validateValue)
    }
  }
  didChangeTextField(languageID,id, text){    
    let dictData = this.state.languageFieldArray[languageID];
    var itemD = dictData[`${id}`];
    itemD['value'] = text;
    this.state.languageFieldArray[languageID][`${id}`] = itemD
    this.setState({updateUI: !this.state.updateUI});
  }
  renderLanguageFields = (id) => {
    let index = id['id'];
    var fieldsViews = [];
    let dictData = this.state.languageFieldArray[index];
    if (dictData != undefined) {
      for (let a = 0; a < dictData.length; a++) {
        fieldsViews.push(
          <View style={{ width: textFieldWidth }}>
            <TextInput
              style={[appConfigStyle.txtFieldStyle,{outline:0, borderColor: index == 0 ? colors.Appgray : colors.AppGreenBorder}]}
              placeholder="Default text"
              value={dictData[a]['value']}
              placeholderTextColor={colors.placeholderColor}
              onChangeText={text => this.didChangeTextField(index, a, text)}
              editable={index == 0 ? false : true}
            />
          </View>
        )
      }
    }
    return fieldsViews;
  };
  renderLanguageTypeView = () => {
    var fieldsViews = [];
    if (this.state.reload){
      for (let a = 0; a < this.state.languageArray.length; a++) {
        fieldsViews.push(
          <View style={{width: textFieldWidth, margin: 20}}>
            <Text style={appConfigStyle.titleTxtStyle}>
              {this.state.languageArray[a]['name']}
            </Text>
            <this.renderLanguageFields id={a}/>
          </View>
        )
      }
    }else {
      fieldsViews.push(<View />)
    }
    return  <ScrollView horizontal={true} style={{width:'100%'}}>
        {fieldsViews}
    </ScrollView>
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
            title={`${this.props.translationTitle} translations`}
            backBtn={this.props.backBtnAction}
            saveBtn={() => this.saveButtonAction()}
          />
        <View style={{ marginTop: 10, zIndex: 120 }}>
          <Appload enable={this.state.dataLoad} />
        </View>
        <ScrollView style={{zIndex: 12 }}>
          <View style={commonStyle.mainView}>
          <View style={{height: 20}} />
            <View style={{ marginTop: 0, marginLeft: 10 }}>
              <View style={{ flexDirection:itsMobileView ? 'column' : 'row'}}>
                  <ToastContainer />
                <this.renderLanguageTypeView />
              </View>
            </View>
            {/* <View style={{height: 20, width: '100%'}} /> */}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const keysOrderArray = [
  'order_status_confirmed',
  'order_status_in_progress',
  'order_status_shipped',
  'order_status_customer_unreachable',
  'order_status_out_for_delivery',
  'order_status_undelivered_returned',
  'order_status_undelivered_return_confirmed',
  'order_status_delivered',
  'order_status_delivered_confirmed',
  'order_status_customer_return_initiated',
  'order_status_customer_return_picked',
  'order_status_customer_return_confirmed',
  'order_status_customer_return_disputed',
  'order_status_cancelled_by_account',
  'order_status_cancelled_by_customer',
  'order_status_ready_for_pickup',
  'order_status_completed',
]

const defaultOrderValue = [
  'Confirmed',
  'In Progress',
  'Shipped',
  'Customer Unreachable',
  'Out For Delivery',
  'Undelivered Returned',
  'Undelivered Return Confirmed',
  'Delivered',
  'Delivery Confirmed',
  'Return Initiated',
  'Return Picked',
  'Return Confirmed',
  'Return Disputed',
  'Cancelled by seller',
  'Cancelled by customer',
  'Ready for pickup',
  'Completed',
]

const keysNotificationArray = [
  'account_follow_notification_content',
  'listing_like_notification_content',
  'order_status_notification_content'
];

const defaultNotificationValue = [
  '{account_name} started following you.',
  '{user_name} liked your listing.',
  'Order #{order_number} status has been changed to {order_status}'
];
