import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  ScrollView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class LanguageFields extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: true,
      languageArray: this.props.languageArray,
      languageFieldArray: [],
      langaugeIndex: 0,
      reload: false,
      uploadCount: 1,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    keyPath: PropTypes.string,
    translationValue:PropTypes.any,
    languageArray:PropTypes.any,
    reloadAPI: PropTypes.func,
  };
  componentDidMount() {
    // this.loadLanguageApi()
    let dict = {
      name: 'Default',
      code:'default',
    }
    this.state.languageArray = [dict, ...this.state.languageArray]
    this.loadValuesApi()
  }
  //MARK:-  APIs Method 
  loadLanguageApi = async () => {
    let dict = {
      name: 'Default',
      code:'default',
    }
    this.state.languageArray.push(dict)
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tanantslanguage, 'get')
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages']
      for (let obj of ppData) {
          this.state.languageArray.push(obj)
      }
      if (this.state.languageArray.length != 0) {
        this.callValuesApi()
      }
      this.setState({updateUI: !this.state.updateUI})
    } else {
      this.setState({dataLoad: true})
    }
  };
  callValuesApi(){
    if (this.state.langaugeIndex < this.state.languageArray.length) {
      let code = this.state.langaugeIndex == 0 ? 'default' : this.state.languageArray[this.state.langaugeIndex]['code']
      this.loadValuesApi(code)
    } else {
      this.setState({reload: true})
      this.setState({dataLoad: true})
    }
  }
  loadValuesApi = async (id) => {
    // console.log('this.state.langaugeIndex', this.state.langaugeIndex);
    let translationAry = this.props.translationValue 
    for (let str of this.state.languageArray) {
      var ssAray = [];
      var a = 0;
      if (translationAry[str['code']]) {
        let pdata = translationAry[str['code']]
        for (let obj of pdata) {
          let textData = {
            id : a + 1,
            text: obj['value'],
            languageID: this.state.langaugeIndex,
            key:obj['key'],
          }
          ssAray.push(textData);
        }
      }
      let dict = {[this.state.langaugeIndex] : ssAray};
      this.state.languageFieldArray.push(dict);
      this.state.langaugeIndex = this.state.langaugeIndex + 1
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({reload: true})
    this.setState({dataLoad: true})
  }
  addAppTranslationAPI = async (key, transArray) => {
    if (transArray.length != 0) {
      var path = APPURL.URLPaths.tanantslanguage;
      path = APPURL.URLPaths.addAppTranslations + `${this.props.keyPath}/${key}`;
      const responseJson = await networkService.networkCall(
        path, 'put',
        JSON.stringify({ client_translations: transArray }),
        '',
      );
      if (responseJson['status'] == true) {
        if (this.state.uploadCount < this.state.languageArray.length - 1) {
          this.state.uploadCount = this.state.uploadCount + 1
          this.saveButtonAction();
        } else {
          this.setState({ dataLoad: true });
          AppToast();
          this.props.reloadAPI(true)
        }
      } else {
        this.setState({ dataLoad: true });
      }
    }
  } 
  reloadData() {
    this.state.dataLoad = true;
    // this.state.languageArray = [];
    this.state.languageFieldArray = [];
    this.state.langaugeIndex = 0;
    this.state.reload = true;
    this.state.uploadCount = 1;
    // this.loadLanguageApi();
    this.loadValuesApi()
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // this.setState({dataLoad: false});
    // if (this.state.languageArray != 0) {
    //   let key = this.state.languageArray[this.state.uploadCount]['code'];
    //   let dict = this.state.languageFieldArray[this.state.uploadCount][`${this.state.uploadCount}`];
    //   var transAry = [];
    //   for (let obj of dict) {
    //     let item = {
    //       key:obj['key'],
    //       value: obj['text'],
    //     }
    //     transAry.push(item);
    //   }
    //   this.addAppTranslationAPI(key,transAry);
    // }
  }
  didChangeTextField(languageID,id, text){   
    let dictData = this.state.languageFieldArray[languageID][languageID];
    var itemD = dictData[`${id}`];
    itemD['text'] = text;
    this.state.languageFieldArray[languageID][languageID][`${id}`] = itemD
    let code = this.state.languageArray[languageID]['code']
    let updateDic = {
      'code':code,
      'text':text,
      'groupKey':this.props.keyPath,
      'key':itemD['key']
    }
    this.props.updatetranslationArray(updateDic)
    this.setState({updateUI: !this.state.updateUI});
  }
  renderLanguageFields = (id) => {
    let index = id['id'];
    var fieldsViews = [];
    if (this.state.languageFieldArray.length != 0 ) {
      if (this.state.languageFieldArray[index]) {
        let dictData = this.state.languageFieldArray[index][`${index}`];
        for (let a = 0; a < dictData.length; a++) {
          fieldsViews.push(
            <View style={{ width: itsMobileView ? windowWidth/1.4 : 200 }}>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0, borderColor: index == 0 ? colors.Appgray : colors.AppGreenBorder}]}
                placeholder="Default text"
                value={dictData[a]['text']}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.didChangeTextField(index, a, text)}
                editable={index == 0 ? false : true}
              />
            </View>
          )
        }
      }
    } else {
      fieldsViews.push(<View/>)
    }
    return fieldsViews;
  };
  renderLanguageTypeView = () => {
    var fieldsViews = [];
    if (this.state.reload){
      for (let a = 0; a < this.state.languageArray.length; a++) {
        fieldsViews.push(
          <View style={{width: itsMobileView ? windowWidth/1.4 : 200, margin: 20}}>
            <Text style={appConfigStyle.titleTxtStyle}>
              {this.state.languageArray[a]['name']}
            </Text>
            <this.renderLanguageFields id={a}/>
          </View>
        )
      }
    }else {
      fieldsViews.push(<View />)
    }
    return fieldsViews
  }
  //MARK:-  UI 
  render() {
    return (
      <View style={{flex: 1}}>
        <View style={{marginTop: 10,zIndex:90}}>
          <Appload enable={this.state.dataLoad} />
        </View>
        <View style={{ width: '100%', marginBottom: 0, marginLeft: 5 ,zIndex:2}}>
          <View style={{ width:  windowWidth - 450 }}>
            <ScrollView horizontal={true}>
              <View style={{ flexDirection: itsMobileView ? 'column' : 'row' }}>
                <this.renderLanguageTypeView />
              </View>
            </ScrollView>
          </View>
        </View>
      </View>
    );
  }
}
