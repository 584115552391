import React, {Component} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  FlatList,
  Image,
  Dimensions,
  ScrollView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import {AppToast} from '../../HelperClasses/AppToast';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import Switch from 'react-switch';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import HeaderView from '../HeaderView';
import AppConstants from '../../Constants/AppConstants';
import {Gradient} from 'react-gradient';
import SaveButton from '../../Component/SaveButton';
import HelperVideoView from '../../Component/HelperVideoView';

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';
let AccountTypesArray = ['Standard', 'Express'];
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class StripeIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      showDropDown: false,
      hideShowPublickey: false,
      hideShowSecretkey: false,
      publishableKey: '',
      secretKey: '',
      connectClientID: '',
      selectedAccountType: '',
      standardConnectMsg: '',
      standardDisConnectMsg: '',
      expressConnectMsg: '',
      expressDisConnectMsg: '',
      webhook: '',
      statusEnableBool: true,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=payments',
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        publishableKey: configs['stripe_api_publishable_key'] || '',
        secretKey: configs['stripe_api_secret_key'] || '',
        connectClientID: configs['stripe_client_id'] || '',
        webhook: configs['stripe_webhook_payment_intent_secret'] || '',
        statusEnableBool: configs['stripe_enabled'] || true,
        standardConnectMsg:
          configs['stripe_config_screen_connect_message_standard'] || '',
        standardDisConnectMsg:
          configs['stripe_config_screen_disconnect_message_standard'] || '',
        expressConnectMsg:
          configs['stripe_config_screen_connect_message_express'] || '',
        expressDisConnectMsg:
          configs['stripe_config_screen_disconnect_message_express'] || '',
      });
      if (this.state.standardConnectMsg.length != 0) {
        this.state.selectedAccountType = AccountTypesArray[0];
      } else if (this.state.expressConnectMsg.length != 0) {
        this.state.selectedAccountType = AccountTypesArray[1];
      }
    }
    this.setState({dataLoad: true});
  };

  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    var valueArray = [
      this.state.publishableKey,
      this.state.secretKey,
      this.state.connectClientID,
      this.state.selectedAccountType.toLowerCase(),
      this.state.selectedAccountType == AccountTypesArray[0]
        ? this.state.standardConnectMsg
        : '',
      this.state.selectedAccountType == AccountTypesArray[0]
        ? this.state.standardDisConnectMsg
        : '',
      this.state.selectedAccountType == AccountTypesArray[1]
        ? this.state.expressConnectMsg
        : '',
      this.state.selectedAccountType == AccountTypesArray[1]
        ? this.state.expressDisConnectMsg
        : '',
      this.state.webhook,
      this.state.statusEnableBool,
    ];

    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      let scValue = false;
      if (i == 2 || i == 1 || i == 8) {
        scValue = true;
      }
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: scValue,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
      // this.alertView('Uploaded successfully');
    } else {
      this.deleteKeysAPI(configs);
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete');
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  hideShowEyeBtnAction(id) {
    if (id == 1) {
      this.setState({hideShowPublickey: !this.state.hideShowPublickey});
    } else {
      this.setState({hideShowSecretkey: !this.state.hideShowSecretkey});
    }
  }
  openDropDown = id => {
    this.setState({showDropDown: !this.state.showDropDown});
  };
  didSelectDropDown = item => {
    // console.log('index', item);
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedAccountType: item,
    });
  };
  toolTipBtnAction() {
    window.open(AppConstants.stripeYoutubeLink, '_blank');
  }
  //MARK:-  UIs 
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={styles.showDropDownViewStyle}>
          <FlatList
            data={AccountTypesArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Text
          style={
            this.state.selectedAccountType == ''
              ? [
                  commonStyle.accountTypeplaceholderTxtStyle,
                  {outline: 0, width: '100%'},
                ]
              : [
                  commonStyle.accountTypetxtFieldStyle,
                  {outline: 0, width: '100%'},
                ]
          }>
          {this.state.selectedAccountType == ''
            ? 'Select account type'
            : this.state.selectedAccountType}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? styles.upArraowIconStyle
              : styles.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };

  accountTypesTextFields = () => {
    if (this.state.selectedAccountType == AccountTypesArray[0]) {
      return (
        <View>
          <View style={[commonStyle.subContainerViewStyle]}>
            <Text style={commonStyle.titleNameStyle}>
              Connect message standard
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter connect message standard"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name => this.setState({standardConnectMsg: name})}
              value={this.state.standardConnectMsg}
            />
          </View>
          <View style={{height: 20}} />
          <View style={commonStyle.subContainerViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              Disonnect message standard
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter disonnect message standard"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name =>
                this.setState({standardDisConnectMsg: name})
              }
              value={this.state.standardDisConnectMsg}
            />
          </View>
        </View>
      );
    } else if (this.state.selectedAccountType == AccountTypesArray[1]) {
      return (
        <View>
          <View style={commonStyle.subContainerViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              Connect message express
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter connect message express"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name => this.setState({expressConnectMsg: name})}
              value={this.state.expressConnectMsg}
            />
          </View>
          <View style={{height: 20}} />
          <View style={commonStyle.subContainerViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              Disonnect message express
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter disonnect message express"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name => this.setState({expressDisConnectMsg: name})}
              value={this.state.expressDisConnectMsg}
            />
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSaveConfigBtn = () => {
    return (
      <View>
        <SaveButton
          saveBtn={() => this.submitBtnAction()}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{height: windowHeight / 1.18}}>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={{height: 20}} />
            <View
              style={[
                commonStyle.switchViewStyle,
                {marginTop: -10, marginLeft: 10},
              ]}>
              <Text style={commonStyle.titleNameStyle}>Enable Stripe</Text>
              <View style={{width: itsMobileView ? '12%' : '14%'}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() =>
                    this.setState({
                      statusEnableBool: !this.state.statusEnableBool,
                    })
                  }
                  checked={this.state.statusEnableBool}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Publishable key</Text>
              <View
                style={[
                  commonStyle.customTxtFieldViewStyle,
                  {marginTop: 0, width: '100%'},
                ]}>
                <TextInput
                  style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                  placeholder="Enter publishable key"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({publishableKey: name})}
                  value={this.state.publishableKey}
                  secureTextEntry={!this.state.hideShowPublickey}
                />
                <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(1)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowPublickey ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Secret key</Text>
              <View
                style={[
                  commonStyle.customTxtFieldViewStyle,
                  {marginTop: 0, width: '100%'},
                ]}>
                <TextInput
                  style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                  placeholder="Enter secret key"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({secretKey: name})}
                  value={this.state.secretKey}
                  secureTextEntry={!this.state.hideShowSecretkey}
                />
                <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(2)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowSecretkey ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Connect client id</Text>
              <TextInput
                style={[
                  commonStyle.txtFieldStyle,
                  {outline: 0, marginLeft: 0, width: '100%'},
                ]}
                placeholder="Enter connect client id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({connectClientID: name})}
                value={this.state.connectClientID}
              />
            </View>
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Webhook secret</Text>
              <TextInput
                style={[
                  commonStyle.txtFieldStyle,
                  {outline: 0, marginLeft: 0, width: '100%'},
                ]}
                placeholder="Enter webhook secret"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({webhook: name})}
                value={this.state.webhook}
              />
            </View>
            <View
              style={[commonStyle.configContainerViewStyle, {zIndex: 10001}]}>
              <Text style={commonStyle.titleNameStyle}>Account type</Text>
              <TouchableOpacity
                style={[
                  commonStyle.dropDownViewStyle,
                  {width: '100%', marginTop: 0},
                ]}
                onPress={() => this.openDropDown(2)}>
                <this.renderValueLabel id={2} />
                <this.customDropDown id={2} />
              </TouchableOpacity>
            </View>
            <View style={{zIndex: 150}}>
              <this.accountTypesTextFields />
            </View>
            <View style={{margin: 14}}>
              <Text style={appConfigStyle.titleTxtStyle}>Stripe reminders</Text>
              <TextInput
                style={[
                  appConfigStyle.txtFieldStyle,
                  {outline: 0, width: '100%'},
                ]}
                placeholder="Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({inviteFriendTitle: name})}
                value={this.state.inviteFriendTitle}
              />
              <TextInput
                style={[
                  appConfigStyle.descriptionTxtFieldStyle,
                  {outline: 0, width: '100%'},
                ]}
                placeholder="Description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name =>
                  this.setState({inviteFriendDescription: name})
                }
                value={this.state.inviteFriendDescription}
                multiline={true}
              />
            </View>
          </View>
          <View style={{height: 40}} />
          <View style={{display: 'flex', gap: 10}}>
            <HelperVideoView
              title={'Learn More - Stripe Payments'}
              helperVideoBtnAction={() =>
                window.open('https://tradly.app/docs/stripe-payments')
              }
            />
            <HelperVideoView
              title={'Learn More - Stripe Connect for Marketplaces'}
              helperVideoBtnAction={() =>
                window.open('https://tradly.app/docs/stripe-connect')
              }
            />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
  showDropDownViewStyle: {
    backgroundColor: colors.AppWhite,
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: '100%',
    marginTop: 38,
    marginLeft: 0,
    zIndex: 9000,
  },
  upArraowIconStyle: {
    width: 15,
    height: 15,
    marginRight: 20,
    alignSelf: 'center',
    transform: [{rotate: '180deg'}],
  },
  downArraowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
    marginRight: 20,
  },
  hideShowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
  },
});

let keysArray = [
  'stripe_api_publishable_key',
  'stripe_api_secret_key',
  'stripe_client_id',
  'stripe_connect_account_type',
  'stripe_config_screen_connect_message_standard',
  'stripe_config_screen_disconnect_message_standard',
  'stripe_config_screen_connect_message_express',
  'stripe_config_screen_disconnect_message_express',
  'stripe_webhook_payment_intent_secret',
  'stripe_enabled',
];
