import {Dimensions, StyleSheet} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import AppConstants from '../../Constants/AppConstants';
// Global scope variables
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

// Styles
export const collections_styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
    height: windowHeight,
  },
  leftMainView: {
    margin: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 20,
    flex: 1.9,
  },
  rightMainView: {
    margin: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: 20,
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
  },
  dropDownView: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    width: 150,
    marginTop: 10,
    marginLeft: 0,
    height: 40,
    alignItems: 'center',
    zIndex: 900,
    paddingLeft: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 150,
    marginTop: 65,
    marginLeft: 0,
    zIndex: 9000,
  },
  commondropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 150,
    marginTop: 50,
    marginLeft: 0,
    zIndex: 9000,
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
  firstViewStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 111,
    padding: 2,
  },
  selectedViewStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 110,
    padding: 2,
  },
  platformViewStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 6,
  },
  endNowView: {
    width: itsMobileView ? 140 : '40%',
    height: 40,
    borderColor: colors.AppRed,
    borderWidth: 1,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteViewStyle: {
    width: 40,
    height: 40,
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
  },
  addAnotherNowView: {
    width: '90%',
    height: 40,
    borderColor: colors.AppGreenBorder,
    borderWidth: 1,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateViewStyle: {
    padding: 10,
    paddingTop: 6,
    paddingLeft: 0,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    outlineWidth: 0,
    zIndex: 90,
  },
  searchBarViewStyle: {
    marginTop: 10,
    borderColor: colors.AppGreenBorder,
    borderRadius: 10,
    borderWidth: 1,
    width: '100%',
    height: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  searchDropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '85%',
    height: 200,
    marginTop: 290,
    marginLeft: 40,
    zIndex: 9001,
    marginBottom: 50,
    borderTopWidth: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  cellItemStyle: {
    flexDirection: 'row',
    backgroundColor: colors.AppWhite,
    margin: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 10,
    justifyContent: 'space-between',
  },
});
