import React, {useState} from 'react';
import tlogo from '../assets/tradlyicon.png';
import {fixed_menus, sideMenus} from '../Constants/SideMenusArray';
import SideMenu from './SideMenu/SideMenu';
import {ICONS} from '../Constants/Icons';
import AppConstants from '../Constants/AppConstants';
import {B2CMenus} from '../Constants/B2CMenus';
import {AllMenus} from '../Constants/AllMenus';
import {CONFIG_CONSTANT} from '../Constants/ConfigsConstants';

const SideMenubar = ({isExpandSet, extra_styles}) => {
  const sideMenuSetup = CONFIG_CONSTANT.SUPERADMIN?.sidebar_setup ?? [];

  const [menus, setMenus] = useState(
    AppConstants.appType
      ? B2CMenus?.filter(item =>
          sideMenuSetup?.length > 0
            ? sideMenuSetup?.filter(
                setup_menu =>
                  (setup_menu?.key_name === item.key_name &&
                    setup_menu?.feature_activated == true) ||
                  (setup_menu?.parent_key === item.key_name &&
                    setup_menu?.feature_activated == true),
              )?.length > 0
            : true,
        )
      : AllMenus?.filter(item =>
          sideMenuSetup?.length > 0
            ? sideMenuSetup?.filter(
                setup_menu =>
                  (setup_menu?.key_name === item.key_name &&
                    setup_menu?.feature_activated == true) ||
                  (setup_menu?.parent_key === item.key_name &&
                    setup_menu?.feature_activated == true),
              )?.length > 0
            : true,
        ),
  );
  const [isExpand, setIsExpand] = useState(
    isExpandSet === undefined ? true : isExpandSet,
  );

  const color_groups_list = AllMenus.filter(it => it.type === 'color');
  const background_color = sideMenuSetup?.find(
    mn => mn.key_name === 'background_color',
  );
  const text_color = sideMenuSetup?.find(mn => mn.key_name === 'text_color');

  return (
    <div
      className={`relative  h-[100dvh]  overflow-y-auto overflow-x-visible flex flex-col py-2 ${
        isExpand ? 'w-[210px]' : 'w-14'
      } transition-all duration-300 ${extra_styles}`}
      style={{background: background_color?.value ?? '#01544E'}}>
      {/* logo */}
      <div
        className={` hidden  w-full  pb-3 sticky top-0 z-10   md:flex items-center  gap-2  ${
          isExpand ? ' justify-start px-2' : 'justify-center '
        }`}>
        <button className="h-[30px] font-[FuturaLT-Book] w-[30px] md:h-[50px] md:w-[50px] cursor-text  ">
          {ICONS.tradly_logo}
        </button>
        {isExpand && (
          <p
            className="block    text-xl font-bold  line-height-1  "
            style={{color: text_color?.value ?? '#ffffff'}}>
            SuperAdmin
          </p>
        )}
      </div>

      {/* Menus */}
      <div className=" flex flex-col   flex-grow">
        {menus
          ?.filter(
            item =>
              ![15, 17, 18].includes(item.id) &&
              item.isShow &&
              item.type !== 'color',
          )
          ?.map(item => {
            return (
              <SideMenu
                menu={item}
                isExpand={isExpand}
                text_color={text_color?.value ?? '#ffffff'}
                background_color={background_color?.value ?? '#01544E'}
              />
            );
          })}
      </div>

      {/* Sticky */}
      <div
        className={`  sticky bottom-0   z-10 ${
          isExpand ? 'md:pb-6 ' : 'md:pb-12 '
        } `}>
        <div className="flex flex-col  flex-grow">
          {fixed_menus.map(item => {
            return (
              <SideMenu
                menu={item}
                isExpand={isExpand}
                text_color={text_color?.value ?? '#ffffff'}
                background_color={background_color?.value ?? '#01544E'}
              />
            );
          })}
        </div>

        {/* expand button */}
        <button
          className={` hidden md:flex absolute  bottom-0   w-full  ${
            isExpand ? 'justify-end px-4 ' : 'justify-center  pb-2 '
          }`}
          onClick={() => setIsExpand(!isExpand)}>
          <img
            className=""
            src={isExpand ? ICONS.arrowFromRight : ICONS.arrowFromLeft}
            alt=""
          />
        </button>
      </div>
    </div>
  );
};

export default SideMenubar;
