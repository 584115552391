/* eslint-disable react/prop-types */
import React from 'react';

import {CONFIG_CONSTANT} from '../../../Constants/ConfigsConstants';
import {getThumbnailImage} from '../../../HelperClasses/SingletonClass';
import {currentShipmentStatus} from '../../../Constants/Status';

const ShipmentDetails = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  const shipments = order_details?.shipments;

  return (
    <div className="flex flex-col gap-6 ">
      {shipments?.map((shipment, index) => {
        const OrderDetails = shipment.order_details;
        return (
          <div className=" w-full h-min-[300px] bg-white  shadow-c-sm rounded-card      p-4   sm:p-7   border-opacity-40  flex flex-col gap-4 ">
            {/*  */}
            <div className="">
              <p className="text-primary text-xl leading-6 font-medium   ">
                Shipment {index + 1}
              </p>
            </div>

            {/* Order Details items  */}
            <div className="flex flex-col gap-2">
              {/* Header */}
              <div className=" grid  grid-cols-[50%,20%,30%] md:grid-cols-[40%,20%,20%,20%] pb-2 border-b-2 border-gray-400     bg-white z-20">
                <p className=" text-sm sm:text-lg text-black font-semibold ">
                  Items Summary
                </p>
                <p className="  text-sm sm:text-lg text-black font-semibold  text-center">
                  QTY
                </p>
                <p className=" hidden md:block text-lg text-black font-semibold  text-center">
                  Price
                </p>
                <p className="text-sm sm:text-lg text-black font-semibold  text-center">
                  Total price
                </p>
              </div>
              {/* Items */}
              <div className="">
                {OrderDetails?.map((item, index) => {
                  let listing_details = item?.listing;
                  let listing_variant_details = item?.variant
                    ? Object?.keys(item?.variant)?.length > 0
                      ? item?.variant
                      : undefined
                    : undefined;

                  let item_details = listing_variant_details
                    ? listing_variant_details
                    : listing_details;
                  return (
                    <a
                      className={[
                        ' grid  grid-cols-[50%,20%,30%]   md:grid-cols-[40%,20%,20%,20%] items-center py-3  cursor-pointer',
                        index !== OrderDetails.length - 1 &&
                          'border-b-2 border-gray-400 border-opacity-20',
                      ].join(' ')}>
                      <div className=" flex items-center pl-1" key={item.id}>
                        {item_details?.images?.length > 0 && (
                          <img
                            src={getThumbnailImage(item_details?.images[0])}
                            width={50}
                            height={50}
                            objectFit="contain"
                            alt=""
                          />
                        )}
                        <div className="flex flex-col gap-2 ltr:ml-3 rtl:mr-3">
                          <p className=" text-sm font-semibold  ">
                            {item_details.title}
                          </p>
                          {item?.listing?.fulfilled_by_platform && (
                            <img
                              className=" w-auto h-auto max-w-[80px]"
                              alt=""
                              src={
                                CONFIG_CONSTANT.SHIPPING_CONFIGS
                                  .fulfilled_by_platform_icon_path
                              }
                            />
                          )}
                          {!item?.listing?.fulfilled_by_platform && (
                            <img
                              className="w-auto h-auto max-w-[80px]"
                              alt=""
                              src={
                                CONFIG_CONSTANT.SHIPPING_CONFIGS
                                  .fulfilled_by_seller_icon_path
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <p className=" text-sm font-semibold  text-center">
                          X {item.quantity}
                        </p>
                      </div>
                      <div className=" hidden md:block">
                        <p className=" text-sm font-semibold flex items-center flex-wrap  justify-center text-center">
                          {item.list_price.formatted}
                        </p>
                      </div>
                      <div>
                        <p className=" text-sm font-semibold  flex flex-wrap justify-center items-center text-center">
                          {item.list_price.formatted}
                        </p>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>

            <div className="flex items-center justify-end gap-3 flex-wrap mt-2">
              <div className="flex items-center gap-2 ">
                <p className="    text-primary font-semibold">
                  {currentShipmentStatus(shipment.status)}
                </p>
              </div>
              {/* Status change btn */}
              {/* <ShipmentOrderStatus
                order_details={order_details}
                shipment={shipment}
                setShowError={setShowError}
                setError_message={setError_message}
                setShowSuccess={setShowSuccess}
                setSuccess_message={setSuccess_message}
              /> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShipmentDetails;
