import React, {useState} from 'react';
import {
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from 'react-native';
import downArrow_Icon from '../assets/downArrowIcon.png';
import AppConfigStyleSheet from '../StyleSheets/AppConfigStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import colors from './../HelperClasses/AppColor';

const DropDown = ({selected_item, all_items = [], onSelect}) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const renderConditionsDropDown = ({item, index}) => {
    if (item.isShow !== false) {
      return (
        <TouchableOpacity
          onPress={() => {
            onSelect(item.value);
            setOpenDropdown(false);
          }}
          style={{margin: 5, height: 20, marginBottom: 5}}>
          <View style={{justifyContent: 'center', width: '100%'}}>
            <Text style={CommonStyleSheet.plainSubTextStyle}>{item.label}</Text>
          </View>
        </TouchableOpacity>
      );
    }
  };
  return (
    <View>
      <TouchableOpacity
        onPress={() =>
          all_items?.filter(it => it?.isShow !== false)?.length > 1 &&
          setOpenDropdown(!openDropdown)
        }
        style={styles.dropDownView}>
        <Text style={{margin: 10}}>
          {all_items?.filter(item => item.value === selected_item)[0]?.label}
        </Text>
        <Image
          style={
            openDropdown == true
              ? AppConfigStyleSheet.upArraowIconStyle
              : AppConfigStyleSheet.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </TouchableOpacity>
      {openDropdown && (
        <View style={styles.commondropDownViewStyle}>
          <FlatList
            data={all_items}
            horizontal={false}
            renderItem={renderConditionsDropDown}
            extraData={all_items}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dropDownView: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    width: 150,
    marginTop: 10,
    marginLeft: 0,
    height: 40,
    alignItems: 'center',
    zIndex: 900,
    paddingLeft: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 150,
    marginTop: 65,
    marginLeft: 0,
    zIndex: 9000,
  },
  commondropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 150,
    marginTop: 50,
    marginLeft: 0,
    zIndex: 9999999,
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
});

export default DropDown;
