import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  TextInput,
  SafeAreaView,
  Alert,
  Platform,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import Header_View from '../HeaderView';
import grayTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import FirebaseIntegrations from './FirebaseIntegration';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import BranchIntegrations from './BranchIntegration';
import MapIntegration from './MapsIntegration';
import TwiloIntegrations from './TwiloIntegration';
import IOSIntegrations from './iOSIntegration';
import {AppToast} from '../../HelperClasses/AppToast';
import {ToastContainer} from 'react-toastify';
import HelperLinkViews from '../../Component/HelperLinkView';
import AdmobIntegrations from './AdmobIntegration';
import networkService from '../../Network/NetworkManager';
import FacebookIntegrations from './FacebookIntegration';
import PlayStoreIntegrations from './PlayStoreIntegration';
import GoogleIntegrations from './GoogleIntegration';
import Switch from 'react-switch';
import {checkUserRoleModule} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import AppColor from '../../HelperClasses/AppColor';
import {ReactComponent as LockIcon} from '../../assets/lockIcon.svg';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');
var subMenuTextArray = [
  'Map',
  'Firebase',
  'Branch',
  // 'Twilio',
  'Appstore',
  'PlayStore',
  // 'Facebook Auth',
  // 'Google Auth',
  'Admob',
];

export default class NativeIntegrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      switchArray: [false, false, false, false, false],
      saveBtnCallBack: false,
      dataLoad: true,
      writeP: AppConstants.defaultRead,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.NATIVEINTEGRATIONS, res => {
      this.setState({writeP: res[1]});
    });
  };
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general',
      'get',
      '',
      '',
    );
    // console.log('configs', responseJson);
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      let enable_admob = configs['tenant_enable_admob'] || false;
      if (enable_admob) {
        subMenuTextArray.push('Admob');
      }
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
    this.setState({dataLoad: true});
  }

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    // console.log('main calling');
    if (this.state.subMenuSelectedIndex == 4) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 1) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 2) {
      this.child.saveBranchButtonAction();
    } else if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 3) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 5) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 6) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 7) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 8) {
      this.child.saveButtonAction();
    }
  }
  didSelectMenuItem = index => {
    this.setState({subMenuSelectedIndex: index});
  };
  switchSattusBtnAction(id) {
    this.state.switchArray[id] = !this.state.switchArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  dissmissSaveBtn() {
    this.setState({saveBtnCallBack: false});
  }
  /*  UI   */
  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= subMenuTextArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex == a
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image
            style={SettingsStyleSheet.subMenuIconStyle}
            source={
              this.state.subMenuSelectedIndex == a
                ? greenTickIcon
                : grayTick_Icon
            }
          />
          <Text
            style={
              this.state.subMenuSelectedIndex == a
                ? SettingsStyleSheet.subMenuSelectedtextStyle
                : SettingsStyleSheet.subMenutextStyle
            }>
            {subMenuTextArray[a]}
          </Text>
          <View style={{display: a == 0 || a == 1 || a == 6 ? 'flex' : 'none'}}>
            <LockIcon style={{height: 16, width: 16, marginLeft: 5}} />
          </View>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };
  mainViewRender = () => {
    let title = subMenuTextArray[this.state.subMenuSelectedIndex];
    let pp = 'Enter ' + title + ' ID';
    if (this.state.subMenuSelectedIndex == 1) {
      return (
        <FirebaseIntegrations
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 0) {
      return (
        <MapIntegration
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 2) {
      return (
        <BranchIntegrations
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 3) {
      return (
        <IOSIntegrations
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 4) {
      return (
        <PlayStoreIntegrations
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 5) {
      return (
        <AdmobIntegrations
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else {
      return (
        <View style={{width: '70%', marginLeft: 10}}>
          <Text style={tableStyle.listTitleText}>{title}</Text>
          <Text style={appConfigStyle.subTitleStyle}>
            {AppConstants.plainTextString}
          </Text>
          <View style={appConfigStyle.horizontalLineViewStyle} />
          <View style={appConfigStyle.subViewStyle}>
            <View style={{flexDirection: 'column', flex: 1.5}}>
              <View style={{marginTop: -20}}>
                <Text style={appConfigStyle.titleTxtStyle}>
                  Enable {title} integration
                </Text>
                <View style={{flexDirection: 'row', marginTop: 0}}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() =>
                      this.switchSattusBtnAction(
                        this.state.subMenuSelectedIndex,
                      )
                    }
                    checked={
                      this.state.switchArray[this.state.subMenuSelectedIndex]
                    }
                    height={22}
                    width={50}
                  />
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={appConfigStyle.statusTxtStyle}>{title}</Text>
                    <Text
                      style={[
                        CommonStyleSheet.hintTitleStyle,
                        {marginLeft: 10, marginRight: 30},
                      ]}>
                      (Lorem ipsum dolor sit amet,)
                    </Text>
                  </View>
                </View>
                <TextInput
                  style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                  placeholder={pp}
                  placeholderTextColor={colors.placeholderColor}
                />
              </View>
            </View>
          </View>
        </View>
      );
    }
  };
  renderHelperView = () => {
    if (this.state.subMenuSelectedIndex == 0) {
      return <HelperLinkViews title={'Location'} />;
    } else if (this.state.subMenuSelectedIndex == 1) {
      return <HelperLinkViews title={'Firebase'} />;
    } else if (this.state.subMenuSelectedIndex == 2) {
      return <HelperLinkViews title={'Branch'} />;
    } else if (this.state.subMenuSelectedIndex == 4) {
      return <HelperLinkViews title={'Appstore'} />;
    } else {
      return <View />;
    }
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={'App Integrations'}
          backBtn={this.props.backBtn}
          saveBtn={() => this.saveBtnAction()}
          showBackBtn={this.props.showBackBtn}
          showSaveBtn={this.state.writeP}
        />
        <View style={{flexDirection: 'column', marginBottom: 0}}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{flex: 2, backgroundColor: 'white'}}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <this.renderHelperView />
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
  },
});
