import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../assets/editIcon.png';
import deleteIcon from '../assets/delete_icon.png';
import disableIcon from '../assets/disableIcon.png';
import placeholder from '../assets/pPlaceholder.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import {Gradient} from 'react-gradient';
import AddPromoBanners from './AddPromoBanner';
import {AppToast} from '../HelperClasses/AppToast';
import HelperLinkViews from '../Component/HelperLinkView';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import appMessage from '../Constants/AppConstantsMessage';
import {
  capitalizeFirstLetter,
  checkUserRoleModule,
  getRandomColor,
  getThumbnailImage,
} from '../HelperClasses/SingletonClass';
import listIcon from '../assets/listIcon.png';
import gridIcon from '../assets/gridIcon.png';
import calendarIcon from '../assets/calendarIcon.png';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import AppConstants from '../Constants/AppConstants';
import selectedListIcon from '../assets/selectedListIcon.png';
import selectedGridIcon from '../assets/selectedGridIcon.png';
import {MODULEKEYS} from '../Models/Enum';
import selectedcalendarIcon from '../assets/selectedcalendarIcon.png';
import AppCalendarView from '../Component/AppCalendarView';
import Moment from 'moment';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import SegmentBtnView from '../Component/SegmentBtnView';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import copyPasteIcon from '../assets/copyPaste.png';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class PromoBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedType: 'all',
      promoBannerArray: [],
      dataLoad: false,
      selectedPromoBannerIndex: 0,
      isEdit: false,
      addPromoBannerBool: false,
      collectionID: 0,
      stopPage: false,
      haveData: false,
      showGridView: false,
      showFilterView: false,
      showCalendarView: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo();
    this.loadPromoBannerApi('all');
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  mixPannelInfo() {
    // * Mixpanel
    let view_type;
    if (this.state.showGridView) {
      view_type = MixPannelEnum.gridView;
    } else if (this.state.showCalendarView) {
      view_type = MixPannelEnum.calendarView;
    } else {
      view_type = MixPannelEnum.listView;
    }
    addMixpanelEvent(MixpanelEvent.viewPromotionList, {
      page: pageNo,
      type: view_type,
    });
  }
  //MARK:-  APIS Method 
  loadPromoBannerApi = async type => {
    this.setState({dataLoad: false});
    let path = type == 'all' ? '' : `?medium=${type}`;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.promo + path,
      'get',
    );
    this.setState({promoBannerArray: []});
    if (responseJson['status'] == true) {
      var promoB = responseJson['data']['promo_banners'];
      // console.log('promoB', promoB);
      for (let objc of promoB) {
        var colD = objc;
        colD['title'] = objc['reference'];
        colD['allDay'] = true;
        colD['start'] = `${Moment(objc['start_at']).toDate()}`;
        colD['end'] = `${Moment(objc['end_at']).toDate()}`;
        colD['color'] = getRandomColor();
        this.state.promoBannerArray.push(colD);
      }
      this.state.haveData =
        this.state.promoBannerArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  deletePromoBannerApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.promo + '/' + id,
      'delete',
    );
    // console.log('res po', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelDeleteInfo(true);
      this.alertView('delete');
      this.loadPromoBannerApi(this.state.selectedType);
    } else {
      this.mixpanelDeleteInfo(false);
    }
  };
  mixpanelDeleteInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(`${MixpanelEvent.deletePromotion}`, properties);
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({addPromoBannerBool: false});
  }
  nextPreviousBtnAction(index) {}
  addPromoBannerBtnAction() {
    this.props.navigate(`/add-promotion`);
    this.loadPromoBannerApi(this.state.selectedType);
  }
  didSelectDropDown = item => {
    this.setState({showDropDown: false, selectedType: item});
    this.loadPromoBannerApi(item);
  };
  didSelectFilter(id) {
    this.state.selectedType = FilterStaticArray[id]['key'];
    this.setState({showFilterView: false});
    this.loadPromoBannerApi(this.state.selectedType);
  }
  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.promoBannerArray.findIndex(x => x.id === index);
    this.setState({isEdit: true, selectedPromoBannerIndex: id_Index});
    this.props.navigate(`/edit-promotion/${index}`);
  };
  dtlPromoBannerBtnAction = index => {
    if (Platform.OS === 'web') {
      const r = window.confirm('Do you want to go delete this Promo Banner');
      if (r == true) {
        this.deletePromoBannerApi(index);
      }
    }
  };
  showBtnAction() {
    this.child.showHidebtnAction();
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.promoBannerArray.length; i++) {
      // var formatted = dateConversionFromTimeStamp(this.state.promoBannerArray[i]['created_at']);
      let expirable = this.state.promoBannerArray[i]['expirable']
        ? true
        : false;
      views.push(
        <FlatList
          data={[
            this.state.promoBannerArray[i]['reference'],
            this.state.promoBannerArray[i]['id'],
            this.state.promoBannerArray[i]['image_path'],
            this.state.promoBannerArray[i]['medium'],
            this.state.promoBannerArray[i]['placement'],
            capitalizeFirstLetter(
              this.state.promoBannerArray[i]['type'] ?? '',
            ).replace('_', ' '),
            this.state.promoBannerArray[i]['value'] || '',
            this.state.promoBannerArray[i]['active'] == true
              ? 'Active'
              : 'Inactive',
            this.state.promoBannerArray[i]['id'],
            expirable ? this.state.promoBannerArray[i]['start_at'] : ' - ',
            expirable ? this.state.promoBannerArray[i]['end_at'] : ' - ',
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
          keyExtractor={(item, index) => index + 9989}
          key={'H'}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 7) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item != 'Active'
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 8) {
      return (
        <View style={styles.columnTitleViewStyle}>
          {this.renderEditDeleteView(item)}
        </View>
      );
    } else if (index == 0) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </View>
      );
    } else if (index == 1) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
            <View style={{width: 10}} />
            <TouchableOpacity
              onPress={() => navigator.clipboard.writeText(`${item}`)}>
              <Image source={copyPasteIcon} style={{width: 15, height: 15}} />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (index == 2) {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(item)[1];
      var fileURl = item;
      if (ext !== 'gif') {
        fileURl = getThumbnailImage(item);
      }
      return (
        <View style={styles.columnTitleViewStyle}>
          <Image
            style={tableStyle.itemImageViewStyle}
            source={item.length == 0 ? placeholder : {uri: fileURl}}
          />
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderEditBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.writeP}
        onPress={() => this.editButtonAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.writeP ? edit_Icon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderDeleteBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.dtlPromoBannerBtnAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? deleteIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  gradientButtons = name => {
    if (this.state.promoBannerArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />;
      } else if (
        name['id'] == 1 &&
        this.state.promoBannerArray.length == 0 &&
        pageNo > 1
      ) {
        return <View />;
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{
                height: 30,
                width: 100,
                borderRadius: 10,
                marginRight: 30,
              }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{color: 'white'}}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderEditDeleteView = item => {
    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity onPress={() => this.editButtonAction(item)}>
          <Image
            style={tableStyle.iconStyle}
            resizeMode="center"
            source={edit_Icon}
          />
        </TouchableOpacity>
        <View style={{width: 10}} />
        <TouchableOpacity onPress={() => this.dtlPromoBannerBtnAction(item)}>
          <Image
            style={tableStyle.iconStyle}
            resizeMode="center"
            source={deleteIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };
  renderAddPomoBtn = () => {
    return (
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={colors.GradientColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={() => this.addPromoBannerBtnAction()}>
            <Text style={tableStyle.saveBtnTxtStyle}>{'Add Promotions'}</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    );
  };
  renderHeaderBtn = () => {
    let showList = !this.state.showCalendarView && !this.state.showGridView;
    return (
      <View
        style={{
          flexDirection: 'row',
          zIndex: 100,
          marginTop: itsMobileView ? 10 : 0,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          {/* <View style={{ width: 10 }} /> */}
          <View>
            <SegmentBtnView
              title={'List'}
              isSelected={showList}
              icon={showList ? selectedListIcon : listIcon}
              tapAction={() =>
                this.setState({showGridView: false, showCalendarView: false})
              }
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Grid'}
              isSelected={this.state.showGridView}
              icon={this.state.showGridView ? selectedGridIcon : gridIcon}
              tapAction={() =>
                this.setState({showGridView: true, showCalendarView: false})
              }
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Calendar'}
              isSelected={this.state.showCalendarView}
              icon={
                this.state.showCalendarView
                  ? selectedcalendarIcon
                  : calendarIcon
              }
              tapAction={() =>
                this.setState({showGridView: false, showCalendarView: true})
              }
            />
          </View>
        </View>
        <View style={{width: 20}} />
      </View>
    );
  };
  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View style={tableStyle.headerSubContainer}>
          <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
            <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
              Promotions{' '}
            </Text>
            <ToolTipViewBtn
              title={AppConstantsMessage.promotionsTitleToopTipMsg}
              description={AppConstantsMessage.promotionsSubTitleToolTipMsg}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              zIndex: 4,
              flex: 1,
            }}>
            <this.renderHeaderBtn />
          </View>
          <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            <View style={{position: 'relative', marginRight: 40, marginTop: 5}}>
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() =>
                  this.setState({showFilterView: !this.state.showFilterView})
                }>
                <Text
                  style={[
                    CommonStyleSheet.tabBtnViewStyle,
                    {
                      color: this.state.showFilterView
                        ? colors.AppNewGreen
                        : colors.Appgray,
                    },
                  ]}>
                  Medium
                </Text>
                <View style={{marginLeft: 5}}>
                  <Image
                    style={
                      this.state.showFilterView == true
                        ? appConfigStyle.upArraowIconStyle
                        : appConfigStyle.downArraowIconStyle
                    }
                    resizeMode="contain"
                    source={downArrow_Icon}
                  />
                </View>
              </TouchableOpacity>
              <View
                style={{
                  backgroundColor: this.state.showFilterView
                    ? colors.AppNewGreen
                    : colors.TransparentColor,
                  height: 2,
                  width: '100%',
                  marginTop: 5,
                }}
              />
              <this.renderFilterView />
            </View>
            <this.renderAddPomoBtn />
          </View>
        </View>
      </View>
    );
  };
  renderFilterView = () => {
    if (this.state.showFilterView) {
      return (
        <View style={tableStyle.filterContainerViewStyle}>
          <View
            style={{
              display: this.state.showFilterView ? 'flex' : 'none',
              flexDirection: 'row',
            }}>
            <View style={tableStyle.filterViewStyle}>
              <FlatList
                data={FilterStaticArray}
                renderItem={this.renderSortCell}
                extraData={this.state}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index + 129}
                key={'FL'}
                style={{marginBottom: 10, width: 80}}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSortCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectFilter(index)}
        style={{
          marginLeft: 8,
          marginTop: 10,
          marginRight: 8,
          backgroundColor:
            this.state.selectedType == item['key']
              ? colors.SimonGray
              : colors.TransparentColor,
          padding: 5,
          borderRadius: 2,
        }}>
        <Text>{item['value']}</Text>
      </TouchableOpacity>
    );
  };
  EmptyListRender = () => {
    if (this.state.promoBannerArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <EmptyListUI
              messageIcon={4}
              showImage={true}
              titleString={appMessage.emptyPromoBannerTitleString}
              subtitleString={appMessage.promoMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  renderGridElements = () => {
    var view = [];
    for (let a = 0; a <= this.state.promoBannerArray.length - 1; a++) {
      let item = this.state.promoBannerArray[a];
      let photo = item['image_path'] || '';
      console.log('photo', photo);
      let active = item['active'];
      let fileURl = getThumbnailImage(photo);
      view.push(
        <View style={tableStyle.gridContentViewsStyle}>
          <Image
            style={tableStyle.gridImageViewStyle}
            source={photo.length == 0 ? placeholder : fileURl}
          />
          <View style={{padding: 5}}>
            <Text style={tableStyle.themeTitleTxtStyle} numberOfLines={1}>
              {item['reference']}
            </Text>
          </View>
          <View
            style={{
              padding: 5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text numberOfLines={1} style={tableStyle.gridPriceViewStyle}>
              {' '}
              {item['medium']}
            </Text>
            {this.renderEditDeleteView(item['id'])}
          </View>
        </View>,
      );
    }
    return view;
  };
  renderGridView = () => {
    return (
      <ScrollView>
        <View style={tableStyle.gridViewStyle}>
          <this.renderGridElements />
        </View>
      </ScrollView>
    );
  };
  renderListView = () => {
    return (
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  renderCalendarView = () => {
    return (
      <View style={{flex: 1}}>
        <AppCalendarView
          eventArray={this.state.promoBannerArray}
          didSelectEvent={event => this.editButtonAction(event['id'])}
        />
      </View>
    );
  };
  renderMainView = () => {
    if (this.state.showGridView) {
      return <this.renderGridView />;
    }
    if (this.state.showCalendarView) {
      return <this.renderCalendarView />;
    } else {
      return <this.renderListView />;
    }
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <this.renderHeaderView />
        <View style={tableStyle.mainView}>
          {this.renderMainView()}
          <this.EmptyListRender />
        </View>
        <HelperLinkViews title={'Promotions'} />
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

export default withRouter(PromoBanner);

const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / 9.8,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / 9.8,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
});

const tableHeaderString = [
  'Name',
  'ID',
  'Image',
  'Medium',
  'Placement',
  'Type',
  'Value',
  'Active',
  'Action',
  'Start At',
  'End At',
];
const FilterStaticArray = [
  {key: 'all', value: 'All'},
  {key: 'app', value: 'App'},
  {key: 'web', value: 'Web'},
];
