import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import AddCurrency from './AddCurrencyClass';
import EmptyListUI from '../Component/AppEmptyList';
import { ToastContainer} from 'react-toastify';
import edit_Icon from '../assets/editIcon.png';
import deleteIcon from '../assets/delete_icon.png';
import disableIcon from '../assets/disableIcon.png';
import HelperLinkViews from '../Component/HelperLinkView';
import { checkUserRoleModule } from '../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import {AppToast} from '../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import SettingStyleSheet from '../StyleSheets/SettingStyleSheet';
import closeIcon from '../assets/closeBlackIcon.png';
import { RestrictedFeatureEnum } from '../Models/RestrictedFeatureEnum';
import SubscribePlan from '../Component/SubscribePlan';
import SidePanView from '../Component/SidePanView';

const APPURL = require('../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth
const configViewRightMargin = -420

export default class ListCurrencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      currencyArray: [],
      dataLoad: false,
      addCurrencyBool: false,
      currencyData: [],
      isEdit: false,
      selectedCurrencyIndex: 0,
      haveData:false,
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
      showUpgrade: false,
    };
  }
  static propTypes = {
    addSaveBtnAction: PropTypes.func,
    backBtnAction: PropTypes.func,
  };
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewCurrency,{'type': MixPannelEnum.listView})
    this.loadCurrencyApi()
    this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.CURRENCY, res => {
      this.setState({ writeP: res[1],deleteP :res[2]})
    })
  }
  //MARK:-  APIS Method 
  loadCurrencyApi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(APPURL.URLPaths.currencies, 'get' )
    // console.log('currencies', responseJson);
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var currencyD = responseJson['data']['currencies'];
      for (let i = 0; i < currencyD.length; i++) {
        this.state.currencyArray.push(currencyD[i]);
      }
      this.state.haveData = this.state.currencyArray.length == 0 ? true : false
    }
    this.setState({dataLoad: true});
  };
  deleteCurrencyApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(APPURL.URLPaths.currencies + '/' + id, 'delete');
    // console.log('currencies', responseJson);
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true)
      this.alertView('delete')
      this.loadCurrencyApi();
    } else {
      this.mixpanelInfo(false)
      this.alertView(responseJson);
    }
    this.setState({dataLoad: true});
  }
  mixpanelInfo(resp){
    let properties = { success:resp }
    let event =  `${MixPannelEnum.delete} ${MixpanelEvent.viewCurrency}`
    addMixpanelEvent(event,properties)
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast(title);
    } else {
      Alert.alert(title);
    }
  }
  checkRestriction(){
    let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.currencies)
      return check && this.state.currencyArray.length != 0 ? true : false
  }

  showEntriesDropDown() {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectDropDown = item => {
    this.setState({showDropDown: false, selectedEntriesCount: item});
  };
  statusBtnAction = index => {
    // console.log('statusBtnAction', index);
    //alert(index);
    if (Platform.OS === 'web') {
      alert(index);
    } else {
      Alert.alert(index);
    }
  };
  editButtonAction = index => {
    // console.log('id index =>', index);
    let id_Index = this.state.currencyArray.findIndex(x => x.id === index);
    this.setState({isEdit: true, selectedCurrencyIndex: id_Index});
    this.setState({addCurrencyBool: true});
  };
  addCurrencyBtnAction() {
    // console.log('add currency ');
    if (this.checkRestriction()) {
      AppToast('Permission')
    } else {
      this.backBtnAction()
    }
  }
  backBtnAction(){
    this.loadCurrencyApi();
    this.setState({ addCurrencyBool: !this.state.addCurrencyBool });
    this.setState({ isEdit: false });
  }
  deleteCurrencyAction = index => {
    // console.log('deleteCurrency', index);
    if (Platform.OS === 'web') {
      const r = window.confirm('Do you want to go delete this currency?')
      if (r == true) {
        this.deleteCurrencyApi(index);
      }
    }
  }
  /*  UI   */
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.dropDownViewStyle}>
          <FlatList
            data={['10', '25', '50', 'all']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 7699}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  listHeader = props => {
    return (
      <View
        style={{
          margin: 16,
          flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
          alignItems: windowWidth < fixedWidth ? 'flex-start' : 'center',
          zIndex: 777,
        }}>
        <Text>Show</Text>
        <View style={{flexDirection: 'column', zIndex: 999}}>
          <TouchableOpacity
            onPress={() => this.showEntriesDropDown()}
            style={tableStyle.dropDownView}>
            <Text> {this.state.selectedEntriesCount} </Text>
          </TouchableOpacity>
          <this.entriesDropDown />
        </View>
        <Text> entries</Text>
        <Text style={tableStyle.searchTitleStyle}> Search:</Text>
        <TextInput
          style={[tableStyle.txtFieldStyle,{outline:0}]}
          placeholder="Phone Number"
          placeholderTextColor={'white'}
          onChangeText={mobile => this.setState({mobileNo: mobile})}
        />
      </View>
    );
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[CommonStyleSheet.plainTextStyle,
              {color: item == this.state.selectedEntriesCount ? colors.AppTitleBlack : colors.Appgray}]}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        keyExtractor={(item, index) => index + 9799}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.currencyArray.length == 0) {
      if (this.state.haveData) {
        return <View style={{height: 200}}>
          <EmptyListUI titleString={'Error no currency found.'}/>
        </View>;
      }else {
        return <View />;
      }  
    } else {
      var views = [];
      for (let i = 0; i < this.state.currencyArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.currencyArray[i]['name'],
              this.state.currencyArray[i]['code'],
              this.state.currencyArray[i]['format'],
              this.state.currencyArray[i]['exchange_rate'],
              this.state.currencyArray[i]['active'],// == '1' ? 'Active' : 'Inactive',
              this.state.currencyArray[i]['default'],// == 'true' ? 'Yes' : 'No',
              this.state.currencyArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: colors.SimonGray,
              backgroundColor: 'white',backgroundColor: 'white',
            }}
            keyExtractor={(item, index) => index + 912799}
            key={'H'}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 4 || index == 5) {
      var lblStr = '';
      var boolvalue = false;
      if (index == 4 ) {
         lblStr = item == '1' ? 'Active' : 'Inactive'
         boolvalue = item == '1' ? true  : false
      } else {
        lblStr = item == true ? 'Yes' : 'No'
        boolvalue = item == true
      }
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={boolvalue == false ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle }>
            {lblStr}
          </Text>
        </View>
      );
    } else if (index == 6) {
      return (
         <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderDeleteBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.deleteCurrencyAction(item)}>
    <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? deleteIcon : disableIcon} />
    </TouchableOpacity>
  }
  loaderView = () => {
    return <Appload enable={this.state.dataLoad} />
  }
  renderUpgradePlanView() {
    return <TouchableOpacity style={[tableStyle.passwordViewStyle, { display: this.state.showUpgrade ? 'flex' : 'none' }]}
      onPress={() => this.setState({ showUpgrade: false })}>
      <SubscribePlan upgrade={true} />
    </TouchableOpacity>
  }
  renderAddCurrencyView = () => {
    if (this.state.addCurrencyBool) {
      return <SidePanView
        dissmissView={() => this.setState({ addCurrencyBool: !this.state.addCurrencyBool })}
        showSidepanView={this.state.addCurrencyBool}
        customView={<View>
        <ToastContainer />
          <AddCurrency
          backBtnAction={() => this.backBtnAction()}
          currencyData={this.state.currencyArray[this.state.selectedCurrencyIndex]}
          isEdit={this.state.isEdit}
        />   
      </View>}
        title={this.state.isEdit ? 'Edit Currency' : 'Add Currency'}
      />
    } else { return <View /> }
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{ zIndex: 101 }}>
          {this.renderAddCurrencyView()}
          {this.renderUpgradePlanView()}
        </View>
        <View style={{ zIndex: 10 }}>
        <Header_View
          title={'Currency'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.addCurrencyBtnAction()}
          saveBtnTitle={'Add Currency'}
          btnDeActive={this.checkRestriction()}
          deActiveBtnAction={() => this.setState({ showUpgrade: true })}
          showSaveBtn={this.state.writeP}
        />
        <View style={tableStyle.listContainerView}>
          <ToastContainer />
          <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
            <View>
              <View style={tableStyle.headerListContainer}>
                <this.tableViewHeader />
              </View>
              <ScrollView style={styles.tableViewHeaderStyle}>
                <this.columnDataView />
              </ScrollView>
            </View>
          </ScrollView>
        </View>
        <HelperLinkViews title={'Currency'} />
        <this.loaderView />
        </View>
      </SafeAreaView>
    )
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor:colors.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    // padding: 10,
    width: windowWidth < fixedWidth ? 130 : windowWidth / 7.4,
    height: 40,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / 7.4,
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },

});

const tableHeaderString = [
  'Name',
  'Code',
  'Format',
  'Exchange',
  'Status',
  'Default',
  'Action',
];
const columnDataString = [
  'MYR',
  'MYR',
  'MYR {amount} fdf fd ',
  '1.0000',
  'Active',
  'yes',
  'Action',
];
