import uploadImage from '../../../Actions/uploadImage';
import ToastPopup from '../../../HelperClasses/AppLoaderClasses/ToastPopup';
import networkService from '../../../Network/NetworkManager';
const APPURL = require('../../../Constants/URLConstants');

const deleteSubscriptionListing = async ({id, setIsLoading, onSuccess}) => {
  try {
    setIsLoading(true);

    //
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.subscription + `/${id}`,
      'delete',
    );

    setIsLoading(false);
    if (responseJson.status) {
      onSuccess();
    } else {
      ToastPopup({type: 'error', message: 'Something went wrong'});
    }
  } catch (error) {
    setIsLoading(false);
  }
};

export default deleteSubscriptionListing;
