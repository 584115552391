import networkService from '../../Network/NetworkManager';
const APPURL = require('./../../Constants/URLConstants');

export const saveConfigsData = async ({form_data, config_key_group}) => {
  try {
    const array_configs = Object.entries({...form_data});
    let configs = [];
    let delete_keys = '';
    for (let i = 0; i < array_configs.length; i++) {
      const element = array_configs[i];
      if (element[1].toString().length > 0) {
        configs.push({
          key: element[0],
          key_group: config_key_group,
          secured: false,
          value: element[1],
        });
      } else {
        delete_keys += element[0] + ',';
      }
    }

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );

    let urlParm = APPURL.URLPaths.config + '?key=' + delete_keys;
    const responseDeleteJson =
      delete_keys?.length > 0
        ? await networkService.networkCall(urlParm, 'delete', '', '')
        : '';

    return {responseJson, responseDeleteJson};
  } catch (error) {
    return error;
  }
};
