/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {CONFIG_CONSTANT} from '../../../../Constants/ConfigsConstants';
import AppConstants from '../../../../Constants/AppConstants';

const AddressForm = ({
  onSubmit,
  handleSubmit,
  register,
  setShowShippingAddressForm,
  pickup_address,
  setValue,
}) => {
  useEffect(() => {
    setValue(
      'name',
      Object.keys(pickup_address).length === 0
        ? AppConstants.userFullName
        : pickup_address?.name,
    );
    setValue(
      'phone_number',
      Object.keys(pickup_address).length === 0
        ? ''
        : pickup_address?.phone_number,
    );
    setValue(
      'address_line_1',
      Object.keys(pickup_address).length === 0
        ? ''
        : pickup_address?.address_line_1,
    );
    setValue(
      'address_line_2',
      Object.keys(pickup_address).length === 0
        ? ''
        : pickup_address?.address_line_2,
    );
    setValue(
      'post_code',
      Object.keys(pickup_address).length === 0 ? '' : pickup_address?.post_code,
    );
    setValue(
      'country',
      Object.keys(pickup_address).length === 0 ? '' : pickup_address?.country,
    );
    setValue(
      'state',
      Object.keys(pickup_address).length === 0 ? '' : pickup_address?.state,
    );
  }, [pickup_address]);

  return (
    <form
      className="  w-[500px]   bg-[#FEFEFE] rounded-card   p-[31px] relative"
      onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-2xl font-bold">Add Pickup Address</h2>
      <div className="mt-8  w-full">
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-700">Name</span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder=""
              {...register('name', {
                required: true,
              })}
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Phone</span>
            <input
              type="number"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder=""
              {...register('phone_number', {
                required: true,
              })}
              onWheel={event => event.currentTarget.blur()}
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Address 1</span>
            <input
              type="text"
              className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder="Address"
              {...register('address_line_1', {
                required: true,
              })}
            />
          </label>
          {
            <label className="block">
              <span className="text-gray-700">Address 2</span>
              <input
                type="text"
                className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                placeholder="Address 2"
                {...register('address_line_2', {
                  required: true,
                })}
              />
            </label>
          }
          <div className="grid grid-cols-[100%] sm:grid-cols-[30%,30%,30%] sm:gap-[3.33%] ">
            {
              <label className="block">
                <span className="text-gray-700">Country</span>
                <input
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                  placeholder=""
                  {...register('country', {
                    required: true,
                  })}
                />
              </label>
            }
            {
              <label className="block">
                <span className="text-gray-700">State</span>
                <input
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                  placeholder=""
                  {...register('state', {
                    required: true,
                  })}
                />
              </label>
            }
            {
              <label className="block">
                <span className="text-gray-700">Zip code</span>
                <input
                  type="text"
                  className="
                    mt-1
                    block
                    w-full
                    rounded-input
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
                  placeholder="123456"
                  {...register('post_code', {
                    pattern: /^[A-Z0-9]+$/i,
                    required: true,
                  })}
                  onWheel={event => event.currentTarget.blur()}
                />
              </label>
            }
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          className=" bg-primary rounded-button px-4 py-2 text-white text-base font-semibold"
          type="submit">
          Save
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
