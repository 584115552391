import React from 'react';
import Switch from 'react-switch';
import HelperVideoView from '../../../Component/HelperVideoView';

const ConfigurationBox = ({selected_config, setListingStates, current_key}) => {
  return (
    <div className="w-full flex items-center gap-4">
      {/* Switch type */}
      {selected_config?.type === 'switch' && (
        <Switch
          onColor={'#01544E'}
          onChange={() =>
            setListingStates(prev => ({
              ...prev,
              [current_key]: {
                ...selected_config,
                value: !selected_config?.value,
              },
            }))
          }
          checked={selected_config?.value}
          height={22}
          width={50}
        />
      )}

      {/* Number type */}
      {selected_config?.type === 'input-number' && (
        <input
          type="number"
          className=" w-full border border-gray-500 rounded"
          onChange={e => {
            setListingStates(prev => ({
              ...prev,
              [current_key]: {
                ...selected_config,
                value: e.target.value,
              },
            }));
          }}
        />
      )}
      {/* Number type */}
      {selected_config?.type === 'input' && (
        <input
          type="text"
          className=" w-full border border-gray-500 rounded"
          onChange={e => {
            setListingStates(prev => ({
              ...prev,
              [current_key]: {
                ...selected_config,
                value: e.target.value,
              },
            }));
          }}
        />
      )}

      {selected_config?.isShowVideos && (
        <HelperVideoView
          helperVideoBtnAction={() => window.open(selected_config?.video_url)}
        />
      )}
    </div>
  );
};

export default ConfigurationBox;
