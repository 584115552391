import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
  Image,
  TouchableOpacity,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import ListingDetails from '../../MainListing/ListingDetail';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import GoogleMapReact from 'google-map-react';
import AppConstants from '../../Constants/AppConstants';
import DetailStyleSheet from '../../StyleSheets/DetailStyleSheet';
import { Gradient } from 'react-gradient';
import { AppToast } from '../../HelperClasses/AppToast';
import SelectSearchView from 'react-select';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppConstantsMessage from '../../Constants/AppConstantsMessage';
import DeliveryStatusEnum from '../../Models/DeliveryStatusEnum';
import DriverCredit from './DriverCredit';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import BackBtnView from '../../Component/BackBtnView';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height - 120;

let fixedWidth = AppConstants.mobileMaxWidth
let itsMobileView = windowWidth < fixedWidth  ?  true : false

export default class DriverDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      driverData:{},
      newPassword:'',
      showPasswordView:false,
      fleetsArray:[],
      branchesArray:[],
      selectedBranch:'',
      selectedFleets:'',
      saveBranch:false,
      saveFleet:false,
      selectedTabIndex:1,
      driverCredit:'0',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    driverID: PropTypes.string,
  };
  componentDidMount() {
    this.loadDriverDetailApi()
  }
  loadDriverDetailApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriver + `${this.props.driverID}`, 'get');
    // console.log('Driver Driver Detail', responseJson);
    if (responseJson['status'] == true) {
      this.setState({driverData: responseJson['data']['user']})
      this.state.driverCredit = this.state.driverData['driver_credit'] ?? '0'
      if (this.state.driverData['branch']){
        this.state.selectedBranch = ''
        if (this.state.driverData['fleet']){
        } else{
          this.loadFeeltsApi(this.state.driverData['branch']['id'])
        }
      } else {
        this.loadBranchsApi()
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: false});
  }
  loadBranchsApi = async () => {
    this.setState({ dataLoad: false })
    let path = `${APPURL.URLPaths.deliveryBranches}?page=${1}&per_page=${50}`
    const responseJson = await networkService.networkCall(path, 'get');
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches']
      for (let objc of branchD){
        var dic = objc 
        dic['value'] = objc['id']
        dic['label'] = objc['name']
        this.state.branchesArray.push(dic)
        if (this.state.branchId == objc['id']) {
          this.state.selectedBranch = dic
          // this.loadFeeltsApi(dic['value'])
        }
      }
      if (this.state.branchesArray.length != 0){
          this.state.selectedBranch = this.state.branchesArray[0]
          this.loadFeeltsApi(this.state.selectedBranch['value'])
      }
      this.setState({ dataLoad: true });
    }else {
      this.setState({ dataLoad: true });
    }
  }
  loadFeeltsApi = async (branchID) => {
    this.setState({ dataLoad: false })
    let path = `${APPURL.URLPaths.deliveryFleets}?page=${1}&per_page=${50}&branch_id=${branchID}`
    const responseJson = await networkService.networkCall(path, 'get')
    this.state.fleetsArray = []
    if (responseJson['status'] == true) {
      var fleetsD = responseJson['data']['fleets']
      // console.log('fleetsD', fleetsD)
      for (let objc of fleetsD) {
        if (objc['assigned_to_driver'] == false) {
          var dic = objc
          let fleet = `${objc['fleet_type']} (${objc['plate_number']})`
          dic['value'] = objc['id']
          dic['label'] = fleet
          this.state.fleetsArray.push(dic)
        }
      }
      // if (this.state.fleetsArray.length != 0){
      //     this.state.selectedFleets = this.state.fleetsArray[0]
      // }
      this.setState({ dataLoad: true });
    }else {
      this.setState({ dataLoad: true });
    }
  }
  unAssignAPI = async (id) => {
    this.setState({dataLoad: true})
    let path =  id == 1 ? APPURL.URLPaths.deliveryUnassignBranch : APPURL.URLPaths.deliveryUnassignFleet
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriver + path, 'POST',JSON.stringify({user:{id:this.props.driverID}}));
    if (responseJson['status'] == true) {
      if (id == 1){
        this.mixpannelUnAssignBranch(true)
      } else{
        this.mixpannelUnAssignFleet(true)
      }
      this.loadDriverDetailApi()
      AppToast()
    }else {
      if (id == 1){
        this.mixpannelUnAssignBranch(false)
      } else{
        this.mixpannelUnAssignFleet(false)
      }
      AppToast(responseJson)
    }
    this.setState({dataLoad: true})
  }
  mixpannelUnAssignBranch(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.unassignBranch,properties)
  }
  mixpannelUnAssignFleet(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.unassignFleet,properties)
  }
  resetPasswordAPI = async()  => {
    this.setState({dataLoad: false})
    let path =  APPURL.URLPaths.deliveryResetPassword
    const responseJson = await networkService.networkCall(path, 'POST',JSON.stringify({user:{id:this.props.driverID}}))
    // console.log('responseJson ==>>>>> ', responseJson)
    if (responseJson['status'] == true) {
      this.mixPanelresetPassword(true)
      this.setState({newPassword: responseJson['data']['password']})
      this.setState({dataLoad: true, showPasswordView:true})
    }else {
      this.mixPanelresetPassword(false)
      AppToast(responseJson)
    }
    this.setState({dataLoad: true})
  }
  mixPanelresetPassword(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.resetPasswordDriver,properties)
  }
  updateDriverApi = async () => {
    this.setState({ dataLoad: false })
    var typeAssign = 0
    var dict = {}
    if (this.state.selectedBranch != ''){
      dict['branch'] = {'id': this.state.selectedBranch['value']}
      typeAssign = 0
    }
    if (this.state.selectedFleets != ''){
      dict['fleet'] = {'id': this.state.selectedFleets['value']}
      typeAssign = 1
    }
    var path =  APPURL.URLPaths.deliveryDriver + this.props.driverID
    const responseJson = await networkService.networkCall(path,'PATCH',JSON.stringify({ user: dict }));
    // console.log('deliveryDriver', responseJson);
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
        if (typeAssign = 0){
          this.mixpannelAssignBranch(true)
        } else {
          this.mixpannelAssignFleet(true)
        }
        AppToast()     
        if (this.state.saveBranch) {
          this.setState({saveBranch: false})
        }if (this.state.saveFleet) {
          this.setState({saveFleet: false})
        }
        this.loadDriverDetailApi()
    } else {
      if (typeAssign = 0){
        this.mixpannelAssignBranch(false)
      } else {
        this.mixpannelAssignFleet(false)
      }
      AppToast(responseJson)     
    }
  }
  mixpannelAssignBranch(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.assignBranch,properties)
  }
  mixpannelAssignFleet(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.assignFleet,properties)
  }

  // Action Buttons
  backBtnAction() {
    this.setState({viewListingDetailBool: false});
  }
  viewListingBtnAction = id => {
    this.setState({viewListingDetailBool: true, selectedID: id});
  }
  unassignBranchBtnAction(id) {
    let title = id == 1 ? AppConstantsMessage.unassignBranchMsg : AppConstantsMessage.unassignFleetMsg
    const r = window.confirm(title);
    if (r == true) {
      this.unAssignAPI(id)
    }

  }
  unAssignBtnAction(id){
    if (id == 1){
      if (this.state.driverData['branch']) {
        this.unassignBranchBtnAction(id)
      } else {
        if (this.state.saveBranch){
          this.updateDriverApi()
        } else {
          this.setState({saveBranch: true})
        }
      }
    } else {
      if (this.state.driverData['fleet']) {
        this.unassignBranchBtnAction(id)
      } else {
        if (this.state.saveFleet){
          this.updateDriverApi()
        } else {
          this.setState({saveFleet: true})
        }
      }
    }
    //this.unAssignAPI(id)

  }
  copyBtnAction = () => {
    navigator.clipboard.writeText(this.state.newPassword)
    AppToast('Copied')
    this.setState({showPasswordView: false})
  }
  resetPasswordBtnAction(){
    this.resetPasswordAPI()
  }
  depositeBtnAction(){
    this.child.depositeBtnAction()
  }
  handleSelectChange = (selectedOption, id) => {
    if(id == 1){
      this.state.selectedBranch = selectedOption;
      this.state.selectedFleets = ''
      this.state.fleetsArray = []
      this.loadFeeltsApi(selectedOption['id'])
    } else {
      this.state.selectedFleets = selectedOption;
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectSegmentTab = (index) => {
    this.setState({selectedTabIndex: index})
    if (index == 1) {
      this.loadDriverDetailApi()
    }
  }
  // UI Renders
  titleLblRender = ({first, second}) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={appConfigStyle.titleTxtStyle}>{`${first} `}</Text>
        <Text style={[CommonStyleSheet.plainTextStyle,{marginTop: 2}]}>{second}</Text>
      </View>
    );
  }
  renderUnAssignBtn = (id) => {
    var title = ''
    if (id == 1) {
        title = this.state.driverData['branch'] ? 'Unassign Branch' : 'Assign Branch'
        if (this.state.saveBranch){
          title = 'Save Branch'
        }
    } else {
      title = this.state.driverData['fleet'] ? 'Unassign Fleet' : 'Assign Fleet'
      if (this.state.saveFleet){
        title = 'Save Fleet'
      }
    }
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.unAssignBtnAction(id)}>
          <Text style={tableStyle.saveTxtStyle}>{title}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }

  renderBranchDetail = () => {
    var detailView = []
    var viewContainer = []
    if (this.state.driverData['branch']) {
      let branchData = this.state.driverData['branch']
      let titleAry = ['Branch ID', 'Name', 'Time zone', 'Coordinates', 'Status']
      let status = branchData['active'] == true ? 'Active' : 'Inactive'
      let latlng = `${branchData['latitude']}, ${branchData['longitude']}`
      let valueArray = [branchData['id'] || '', branchData['name'] || '', branchData['time_zone'] || '', latlng || '', status]
      for (let a = 0; a < titleAry.length; a++) {
        detailView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <View style={{ width: 140, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: 4 == a ? 1 : 0, borderRightWidth: 0 }}>
            <Text style={DetailStyleSheet.tableViewTitleStyle}>{titleAry[a]}</Text>
          </View>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: 4 == a ? 1 : 0 }} >
            <Text style={DetailStyleSheet.valueTitleStyle}>{valueArray[a]}</Text>
          </View>
        </View>)
      }
    
      viewContainer.push(<View style={{ margin: 16,flexDirection:itsMobileView? 'column':'row'}}>
          <View  style={{width:'65%'}}>
            {detailView}
          </View>
          <View style={{alignItems:'flex-end', width:'35%'}}>
            {this.renderGoogleMap(branchData['latitude'],branchData['longitude'])}
          </View>
        </View>)
    }  
    return <View style={[commonStyle.mainView,{zIndex:1001}]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={tableStyle.mainTitile}> Branch Detail</Text>
        <View style={{ marginTop: 16, marginRight: 10 }}>
          {this.renderUnAssignBtn(1)}
        </View>
      </View>
      <View style={{display: this.state.saveBranch ? 'flex' : 'none'}}>
        {this.renderAssignView(1)}
      </View>
      {viewContainer}
    </View>
  }
  renderGoogleMap = (lat,long) => {
    var marker = []
    let lati = Number(lat.toFixed(3))
    let longi = Number(long.toFixed(3))
    if (lat) {
      marker.push(<View lat={lati} lng={longi} 
        style={{ backgroundColor: colors.LightGreen }}>
        <View style={commonStyle.markerContainerViewStyle}>
          <View style={commonStyle.mapMarkerViewStyle} />
        </View>
      </View>)
    return (<View style={{ height: 300, width: 300}}>
      <GoogleMapReact
        style={{ borderRadius: 10 }}
        bootstrapURLKeys={{ key: AppConstants.googleMapAPIKey }}
        defaultCenter={{lat: lati ?? '30.76', lng: longi ?? '76.68'}}
        defaultZoom={7}
        yesIWantToUseGoogleMapApiInternals>
        {marker}
      </GoogleMapReact>
    </View>)
    } else {
      return <View />
    }
  }
  renderAssignView = (id) => {
    return <View style={{flexDirection: 'row', alignItems:'center',width:'70%' }}>
      <View style={{margin:20, paddingLeft:5}}>
        <Text style={CommonStyleSheet.titleNameStyle}>{id == 1 ? 'Branch' : 'Fleet'} </Text>
      </View>
      <View style={{ width: itsMobileView ? '100%' : '50%', marginTop: itsMobileView ? 10 : 0 }}>
        {this.rendeDropDownView(id)}
      </View>
    </View>
  }

  renderFleetDetail = () => {
    if (this.state.driverData['branch']) {
    if (this.state.driverData['fleet']) {
      var detailView = []
      let fleet = this.state.driverData['fleet']
      let status = fleet['active'] == true ? 'Active' : 'Inactive'
      let titleAry = ['Reference Name', 'Owner', 'Company Name', 'Fleet Type', 'Capacity', 'Plate Number', 'Status']
      let valueArray = [fleet['reference_name'], fleet['owner'], fleet['company_name'] ?? '', fleet['fleet_type'], fleet['capacity'], fleet['plate_number'], status]
      for (let a = 0; a < titleAry.length; a++) {
        detailView.push(<View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <View style={{ width: 140, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: 6 == a ? 1 : 0, borderRightWidth: 0 }}>
            <Text style={DetailStyleSheet.tableViewTitleStyle}>{titleAry[a]}</Text>
          </View>
          <View style={{ flex: 1, borderColor: colors.Lightgray, borderWidth: 1, borderBottomWidth: 6 == a ? 1 : 0 }} >
            <Text style={DetailStyleSheet.valueTitleStyle}>{valueArray[a]}</Text>
          </View>
        </View>)
      }
    }
    return <View style={[commonStyle.mainView,{zIndex:101}]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={tableStyle.mainTitile}> Fleet Detail</Text>
        <View style={{ marginTop: 16, marginRight: 10 }}>
          {this.renderUnAssignBtn(2)}
        </View>
      </View>
      <View style={{ margin: 16 }}>
        <View style={{display: this.state.saveFleet ? 'flex' : 'none'}}>
          {this.renderAssignView(2)}
        </View>
        {detailView}
      </View>
    </View>
    }else {
      return <View />
    }
  }
  renderCopyCloseBtn = () => {
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.copyBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{'Copy & Close'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderPasswordView = () => {
    return <View style={[tableStyle.passwordViewStyle,{display:this.state.showPasswordView? 'flex' : 'none'}]}>
      <View style={{backgroundColor:colors.AppWhite, height:200, width:300, borderRadius:10}}>
        <View style={{alignSelf:'center', margin: 16}}>
          <Text>New Password</Text>
          <View style={{borderRadius:5, padding:10, borderColor:colors.SimonGray, borderWidth:1, marginTop:30}}>
            <Text style={CommonStyleSheet.plainTextStyle}>{this.state.newPassword}</Text>
          </View>
        </View>
        <View style={{alignSelf:'center', margin: 16, marginTop:30,paddingLeft:10}}>
          {this.renderCopyCloseBtn()}
        </View>
      </View>
    </View>
  }
 
  rendeDropDownView = id => {
    var optionArray = ''
    var valueArray = []
    var placeHolder = ''
    if (id == 1) {
      valueArray = this.state.selectedBranch
      optionArray = this.state.branchesArray
      placeHolder = 'Select Branch'
    }  else if (id == 2) {
      valueArray = this.state.selectedFleets
      optionArray = this.state.fleetsArray
      placeHolder = 'Select Fleet'
    }
    return (
      <View style={{ width: '100%' }}>
        <SelectSearchView
          value={valueArray}
          onMenuOpen={() => console.log('open drop')}
          onChange={itm => this.handleSelectChange(itm, id)}
          options={optionArray}
          isMulti={false}
          placeholder={placeHolder}
          styles={colourStyles}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: colors.AppGreenBorder,
              primary: colors.AppGreenBorder,
            },
          })}
        />
      </View>
    )
  }

  renderSegment = ({id}) => {
    let title = id == 1 ? `Driver Detail` : 'Credit Detail'
    let borderColor = id == this.state.selectedTabIndex ? colors.AppNewGreen : colors.TransparentColor
      return (
        <TouchableOpacity style={[styles.headerViewStyle,{borderColor:borderColor}]} 
          onPress={() => this.didSelectSegmentTab(id)}>
          <Text style={CommonStyleSheet.plainTextStyle}>{title}</Text>
        </TouchableOpacity>
      )
  }
  renderResetPasswordBtn = () => {
    let tab = this.state.selectedTabIndex
    let title = 1 == tab ? 'Reset Password' : 'Deposite Cash'
    return <View style={[tableStyle.gradientViewBGStyle]}>
      <Gradient
        gradients={colors.GradientColors} // required
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => tab == 1 ? this.resetPasswordBtnAction() : this.depositeBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{title}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderHeaderView = () => {
    return (<View style={tableStyle.navigationViewStyle}>
      <View style={tableStyle.headerContainer}>
        <View style={tableStyle.headerListViewcontainer}>
          <TouchableOpacity style={tableStyle.backBtnBackViewStyle} onPress={this.props.backBtnAction}>
            <BackBtnView />
          </TouchableOpacity>
          <View style={{flexDirection:'row'}}>
            <this.renderSegment id={1}/>
            <this.renderSegment id={2}/>
          </View>
        </View>
        <View style={{marginLeft: itsMobileView ? 80 : 0}}>
          {this.renderResetPasswordBtn()}
        </View>
      </View>
    </View>)
  }
  renderDetailView = () => {
    return <ScrollView style={{ height: windowHeight }}>
      {this.renderPasswordView()}
      <View style={[commonStyle.mainView, { zIndex: 2, marginTop: 0 }]}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20 }}>
          <View style={{ margin: 5 }}>
            <this.titleLblRender first={'Name:'} second={this.state.driverData['name']} />
            <View style={{ height: 16 }} />
            <this.titleLblRender first={'Mobile Number:'} second={this.state.driverData['mobile']} />
            <View style={{ height: 16 }} />
            <this.titleLblRender first={'Email ID:'} second={this.state.driverData['email']} />
            <View style={{ height: 16 }} />
            <this.titleLblRender first={'Status:'} second={DeliveryStatusEnum.dutyStatusType(this.state.driverData['status'])} />
            <View style={{ height: 16 }} />
            <this.titleLblRender first={'Credit:'} second={this.state.driverCredit} />
          </View>
        </View>
        {this.renderBranchDetail()}
        {this.renderFleetDetail()}
      </View>
    </ScrollView>
  }
  renderMainView = () => {
    if (this.state.selectedTabIndex == 2){
      return <DriverCredit ref={child => (this.child = child)} driverID={this.props.driverID}/>
    } else {
      return <this.renderDetailView />
    }
    
  }

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <this.renderHeaderView />
        {this.renderMainView()}
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  subViewStyle: {
    margin: 5,
    borderLeftWidth: 1,
    borderLeftColor: colors.Lightgray,
    paddingLeft: 10,
    paddingBottom: 10,
    width: '30%',
  },
  subConatinerView: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    marginTop: 40,
  },
  headerViewStyle: {
    padding: 10,
    height: 40,
    margin: 10,
    borderBottomColor: colors.AppNewGreen,
    borderBottomWidth: 5,
  },
  selectedHeaderViewStyle: {
    padding: 10,
    height: 40,
    borderBottomColor: colors.TransparentColor,
    borderBottomWidth: 2,
    margin: 10,
  },

});

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
}

