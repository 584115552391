import React, {useEffect, useState} from 'react';
import {configuration_items} from './ListingsConfigarationItems';
import {getConfigsByKey} from '../../Actions/getConfigsByKey';
import SwitchButton from '../../../Shared/Switch';
import ConfigurationBox from './ConfigurationBox';
import PageHeader from '../../../Component/Layout/PageHeader';
import {saveConfigsData} from '../../Actions/SaveConfigsData';
import AppLoader from '../../../HelperClasses/AppLoaderClasses/AppLoader';

const ListingsConfiguration = () => {
  const [listing_states, setListingStates] = useState(configuration_items);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadConfigs();
  }, []);

  //loadConfigs();
  const loadConfigs = async () => {
    setIsLoading(true);

    getConfigsByKey('listings').then(config => {
      setListingStates(prevState => {
        const newState = {...prevState};
        Object.entries(newState).forEach(([key, value]) => {
          if (config?.hasOwnProperty(key)) {
            newState[key].value = config[key]
              ? config[key]
              : newState[key]?.value;
          }
        });
        return newState;
      });

      setIsLoading(false);
    });
  };

  const onSave = async () => {
    setIsLoading(true);
    const simplifiedObject = {};

    for (const key in listing_states) {
      simplifiedObject[key] = listing_states[key].value;
    }

    await saveConfigsData({
      form_data: simplifiedObject,
      config_key_group: 'listings',
    });

    loadConfigs();
  };

  return (
    <div className=" w-full p-6  relative">
      <PageHeader
        isHideBackButton={true}
        title={'Listing Configuration'}
        save_action={onSave}
      />
      <div className=" mt-2 border border-[#E6E7E7]  h-[calc(100vh-180px)] rounded-md  overflow-y-auto  p-8 flex flex-col gap-8">
        {/*  */}
        <div className=" flex flex-col gap-5">
          <p className=" text-base  font-medium  text-black   w-full font-sans">
            RULES
          </p>
          {Object.keys(listing_states)
            .filter(key => listing_states[key]?.group === 'rules')
            .map(key => {
              return (
                <div className="flex items-center  justify-start ">
                  <div className="max-w-[400px]  w-full flex ">
                    <p className=" text-base  font-normal  text-black font-sans items-center">
                      {listing_states[key].title}{' '}
                      <span className="text-xs ">
                        {listing_states[key].example}
                      </span>
                    </p>
                  </div>
                  <ConfigurationBox
                    key={key}
                    current_key={key}
                    selected_config={listing_states[key]}
                    setListingStates={setListingStates}
                  />
                </div>
              );
            })}
        </div>
        {/*  */}
        <div className=" flex flex-col gap-5">
          <p className=" text-base  font-medium  text-black   w-full font-sans">
            FEATURES
          </p>
          {Object.keys(listing_states)
            .filter(key => listing_states[key]?.group === 'feature')
            .map(key => {
              return (
                <div className="flex items-center  justify-start ">
                  <p className=" text-base  font-normal  text-black font-sans max-w-[400px] w-full">
                    {listing_states[key].title}
                  </p>
                  <ConfigurationBox
                    key={key}
                    current_key={key}
                    selected_config={listing_states[key]}
                    setListingStates={setListingStates}
                  />
                </div>
              );
            })}
        </div>
        {/*  */}
        <div className=" flex flex-col gap-5">
          <p className=" text-base  font-medium  text-black   w-full font-sans">
            UI Customisation
          </p>
          {Object.keys(listing_states)
            .filter(key => listing_states[key]?.group === 'ui_customization')
            .map(key => {
              return (
                <div className="flex items-center  justify-start ">
                  <p className=" text-base  font-normal  text-black font-sans max-w-[400px] w-full">
                    {listing_states[key].title}
                  </p>
                  <ConfigurationBox
                    key={key}
                    current_key={key}
                    selected_config={listing_states[key]}
                    setListingStates={setListingStates}
                  />
                </div>
              );
            })}
        </div>
      </div>

      {isLoading && (
        <div className="h-full w-full bg-black/5 absolute inset-0   z-0">
          <AppLoader enable={!isLoading} />
        </div>
      )}
    </div>
  );
};

export default ListingsConfiguration;
