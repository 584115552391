import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent } from '../../Models/MixPannelEnum';
import { PaidFeatureTextView } from '../../Component/CommonComponents';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'notifications';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false



export default class PersistentNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      accountString: '',
      listingString: '',
      orderString: '',
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + `?key_group=${KeyGroup}`,'get');
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      console.log('this.state.configs =>', configs);
      this.state.accountString = configs['account_follow_notification_content'] || defaultNotificationValue[0];
      this.state.listingString = configs['listing_like_notification_content'] || defaultNotificationValue[1];
      this.state.orderString = configs['order_status_notification_content'] || defaultNotificationValue[2];
    }
    this.setState({dataLoad: true});
  };
 
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    let values = [this.state.accountString,this.state.listingString,this.state.orderString];
    let keys = ['account_follow_notification_content','listing_like_notification_content','order_status_notification_content'];
    for (let a = 0; a < keys.length; a++) {
      if (values[a].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keys[a],
          value: values[a],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config,  'post',  JSON.stringify({configs: configs}));
    console.log('responseJson',responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.persistentNotification,{'success':true})
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    } else {
      addMixpanelEvent(MixpanelEvent.persistentNotification,{'success':false})
      this.alertView(responseJson)
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    if (!this.state.accountString.includes("{account_name}")) {
      AppToast('Account follow notification should contain {account_name}');
    } else if (!this.state.listingString.includes("{user_name}")) {
      AppToast('Listing like notification content should contain {user_name}');
    } else if (!this.state.orderString.includes("#{order_number}")) {
      AppToast('Order status notification content should contain #{order_number}');
    } else if (!this.state.orderString.includes("{order_status}")) {
      AppToast('Order status notification content should contain {order_status}');
    }else {
      this.setState({dataLoad: false});
      this.addOrUpdateAppDetailApi();
    }
  }
  didSelectDropDown = item => {
    this.setState({ showDropDown: false,  selectedFeedbackType: item});
    
  };
  //MARK:-  UIs 
  
  renderFeedBackView = () => {
      return (<View>
        <View style={{ marginTop: 30, zIndex: 1, }}>
          <Text style={appConfigStyle.titleTxtStyle}>Account follow notification content</Text>
          <TextInput
            style={[appConfigStyle.txtFieldStyle,{outline:0}]}
            placeholder="Enter Account follow notification content"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={email => this.setState({ accountString: email })}
            value={this.state.accountString}
          />
        </View>
        <View style={{ marginTop: 30, zIndex: 1, }}>
          <Text style={appConfigStyle.titleTxtStyle}>Listing like notification content</Text>
          <TextInput
            style={[appConfigStyle.txtFieldStyle,{outline:0}]}
            placeholder="Enter Listing like  notification content"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={email => this.setState({ listingString: email })}
            value={this.state.listingString}
          />
        </View>
        <View style={{ marginTop: 30, zIndex: 1, }}>
          <Text style={appConfigStyle.titleTxtStyle}>Order status notification content</Text>
          <TextInput
            style={[appConfigStyle.txtFieldStyle,{outline:0}]}
            placeholder="Order status follow notification content"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={email => this.setState({ orderString: email })}
            value={this.state.orderString}
          />
        </View>
      </View>
      )
  }
  render() {
    return (
      <View style={{width: itsMobileView ? '100%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Persistent Notification</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -40}}>
              <this.renderFeedBackView />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
const defaultNotificationValue = [
  '{account_name} started following you.',
  '{user_name} liked your listing.',
  'Order #{order_number} status has been changed to {order_status}'
];
