import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  FlatList,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import commonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SelectSearchView from 'react-select';
import car from '../../assets/car.png';
import bike from '../../assets/bike.png';
import truck from '../../assets/truck.png';
import van from '../../assets/vanIcon.png';

import Switch from 'react-switch';
import CustomAlertView from '../../Component/CustomAlertView';
import {ToastContainer} from 'react-toastify';
import AddDriver from '../Drivers/AddDriver';
import {addMixpanelEvent} from '../../Models/MixPannelEnum';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let regexp = /^[0-9\.]+$/;
let fleetsTypesArray = [
  {name: 'Car', image: car},
  {name: 'Bike', image: bike},
  {name: 'Van', image: van},
  {name: 'Truck', image: truck},
];
let ownedTypes = ['owned', 'rented'];

export default class AddFleet extends Component {
  constructor(props) {
    super(props);
    const isEdit = this.props.isEdit;
    this.state = {
      activeStatus: true,
      dataLoad: false,
      branchId: '',
      companyName: '',
      referenceName: '',
      fleetType: '',
      capacity: '',
      plateNumber: '',
      id: '',
      branchesArray: [],
      selectedBranch: '',
      selectedFleetType: 'car',
      ownedType: ownedTypes[0],
      showNextViewBool: false,
      addDriverBool: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    fleetData: PropTypes.any,
    isEdit: PropTypes.bool,
    closeBtnAction: PropTypes.func,
  };
  componentDidMount() {
    const fleetD = this.props.fleetData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (this.state.referenceName.length == 0) {
        if (fleetD['branch']) {
          this.state.branchId = fleetD['branch']['id'];
        }
        this.setState({
          referenceName: fleetD['reference_name'],
          capacity: fleetD['capacity'],
          ownedType: fleetD['owner'],
          plateNumber: fleetD['plate_number'],
          selectedFleetType: fleetD['fleet_type'],
          id: fleetD['id'],
          activeStatus: fleetD['active'],
        });
      }
    }
    this.loadBranchsApi();
  }
  // APIS
  loadBranchsApi = async () => {
    let path = `${APPURL.URLPaths.deliveryBranches}?page=${1}&per_page=${50}`;
    const responseJson = await networkService.networkCall(path, 'get');
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches'];
      for (let objc of branchD) {
        var dic = objc;
        dic['value'] = objc['id'];
        dic['label'] = objc['name'];
        this.state.branchesArray.push(dic);
        if (this.state.branchId == objc['id']) {
          this.state.selectedBranch = dic;
        }
      }
      if (this.state.branchesArray.length != 0) {
        console.log('this.state.branchId ', this.state.branchId.length);
        if (this.state.branchId.length == 0) {
          this.state.selectedBranch = this.state.branchesArray[0];
        }
      }
      this.setState({dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };
  addFleetApi = async () => {
    this.setState({dataLoad: false});
    var dict = {
      owner: this.state.ownedType,
      reference_name: this.state.referenceName,
      capacity: this.state.capacity,
      plate_number: this.state.plateNumber,
      active: this.state.activeStatus,
      fleet_type: this.state.selectedFleetType,
    };
    if (this.state.ownedType == ownedTypes[1]) {
      dict['company_name'] = this.state.companyName;
    }
    dict['branch'] = {id: this.state.selectedBranch['value']};

    var path = APPURL.URLPaths.deliveryFleets;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.deliveryFleets + this.state.id;
    }
    let requestMethod = this.state.id !== '' ? 'PATCH' : 'post';
    const responseJson = await networkService.networkCall(
      path,
      requestMethod,
      JSON.stringify({fleet: dict}),
    );
    console.log('deliveryFleets', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      AppToast();
      if (this.state.id !== '') {
        this.props.backBtnAction(true);
      } else {
        this.setState({showNextViewBool: true});
      }
    } else {
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(
      `${this.state.id !== '' ? 'Edit' : 'Add'} Fleet`,
      properties,
    );
  }
  //MARK:-  Button Actions
  submitButtonAction = () => {
    if (this.state.referenceName.length == 0) {
      AppToast('Please enter reference name');
    } else if (this.state.plateNumber.length == 0) {
      AppToast('Please enter plate number');
    } else if (this.state.capacity.length == 0) {
      AppToast('Please enter capacity');
    } else {
      if (
        this.state.ownedType == ownedTypes[1] &&
        this.state.companyName.length == 0
      ) {
        AppToast('Please enter company name');
      } else {
        this.addFleetApi();
      }
    }
  };
  handleSelectChange = selectedOption => {
    this.state.selectedBranch = selectedOption;
    this.setState({updateUI: !this.state.updateUI});
  };
  onHandleTextChange(text) {
    let value = text;
    if (text == '' || regexp.test(value)) {
      this.setState({capacity: text});
    }
  }
  addDriverBtnAction() {
    this.state.referenceName = '';
    this.state.plateNumber = '';
    this.state.capacity = '';
    this.setState({
      addDriverBool: !this.state.addDriverBool,
      showNextViewBool: false,
    });
  }
  // UI Renders
  renderBranchDropDownView = id => {
    return (
      <View style={styles.dropDownViewStyle}>
        <View style={{width: '100%', marginLeft: -10}}>
          <SelectSearchView
            value={this.state.selectedBranch}
            onMenuOpen={() => console.log('open drop')}
            onChange={itm => this.handleSelectChange(itm)}
            options={this.state.branchesArray}
            isMulti={false}
            placeholder={`Select Branch`}
            styles={colourStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: colors.AppGreenBorder,
                primary: colors.AppGreenBorder,
              },
            })}
          />
        </View>
      </View>
    );
  };
  renderTypeView = () => {
    var view = [];
    for (let a = 0; a <= fleetsTypesArray.length - 1; a++) {
      let item = fleetsTypesArray[a];
      let check = this.state.selectedFleetType == item['name'];
      view.push(
        <TouchableOpacity
          style={check ? styles.selecedTypesViewStyle : styles.typesViewStyle}
          onPress={() => this.setState({selectedFleetType: item['name']})}>
          <Image
            style={styles.iconImageViewStyle}
            resizeMode="contain"
            source={item['image']}
          />
          <Text
            style={[
              CommonStyleSheet.subTitleStyle,
              {
                marginTop: 5,
                color: check ? colors.Appgreen : colors.AppTitleBlack,
              },
            ]}>
            {item['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.typesViewContainerStyle}> {view}</View>;
  };
  renderOwnType = () => {
    var view = [];
    for (let a = 0; a <= ownedTypes.length - 1; a++) {
      let item = ownedTypes[a];
      let check = this.state.ownedType == item;
      view.push(
        <TouchableOpacity
          style={
            check
              ? [styles.selecedTypesViewStyle, {height: 40}]
              : [styles.typesViewStyle, {height: 40}]
          }
          onPress={() => this.setState({ownedType: item})}>
          <Text
            style={[
              commonStyleSheet.plainSubTextStyle,
              {color: check ? colors.Appgreen : colors.Lightgray},
            ]}>
            {item}
          </Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.typesViewContainerStyle}> {view}</View>;
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderCompany = () => {
    return (
      <View
        style={[
          styles.subViewStyle,
          {display: this.state.ownedType == ownedTypes[1] ? 'flex' : 'none'},
        ]}>
        <this.titleLblRender title={'Company name'} />
        <TextInput
          style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
          value={this.state.companyName}
          placeholder="Enter company name"
          placeholderTextColor={colors.placeholderColor}
          onChangeText={name => this.setState({companyName: name})}
        />
      </View>
    );
  };
  renderAlertView = () => {
    return (
      <CustomAlertView
        showNextViewBool={this.state.showNextViewBool}
        titleValue={'Nice 🎉, Successfully added fleet'}
        nextBtnTitle={'Add Driver'}
        closeBtnAction={() => this.props.closeBtnAction(true)}
        nextBtnAction={() => this.addDriverBtnAction()}
      />
    );
  };
  render() {
    let isEdit = this.props.isEdit;
    if (this.state.addDriverBool) {
      return (
        <View>
          <ToastContainer />
          <AddDriver
            backBtnAction={() => this.addDriverBtnAction()}
            closeBtnAction={() => this.props.closeBtnAction(true)}
          />
        </View>
      );
    }
    return (
      <SafeAreaView style={styles.Container}>
        {this.renderAlertView()}
        <Header_View
          title={isEdit ? 'Edit Fleet' : 'Add Fleet'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}
        />
        <ScrollView>
          <View style={styles.mainView}>
            <View style={{height: 15, width: '100%'}} />
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Reference name'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.referenceName}
                placeholder="Enter fleet reference name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({referenceName: name})}
              />
            </View>
            <View style={[styles.subViewStyle, {zIndex: 982}]}>
              <this.titleLblRender title={'Branch Name'} />
              <View
                style={{
                  width: itsMobileView ? '100%' : '80%',
                  marginTop: itsMobileView ? 10 : 0,
                }}>
                {this.renderBranchDropDownView()}
              </View>
            </View>
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Owner type'} />
              <View style={{width: '80%'}}>{this.renderOwnType()}</View>
            </View>
            {this.renderCompany()}
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Plate number'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.plateNumber}
                placeholder="Enter vehicle plate number"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({plateNumber: name})}
              />
            </View>
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Capacity'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.capacity}
                placeholder="Enter capacity of the vehicle (Example: 123)"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.onHandleTextChange(name)}
              />
            </View>
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Fleet type'} />
              <View style={{width: '80%'}}>
                <this.renderTypeView />
              </View>
            </View>
            <View style={{height: 20}} />
            <View style={[commonStyleSheet.switchViewStyle, {zIndex: 10}]}>
              <Text style={commonStyleSheet.titleNameStyle}>Status</Text>
              <View
                style={{
                  width: itsMobileView ? '12%' : '80%',
                  flexDirection: 'row',
                }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() =>
                    this.setState({activeStatus: !this.state.activeStatus})
                  }
                  checked={this.state.activeStatus}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{height: 100}} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    height: windowHeight,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    flex: 1,
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 120,
  },
  dropDownViewStyle: {
    marginTop: 5,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    width: itsMobileView ? windowWidth / 1.2 : windowWidth / 2,
    marginLeft: itsMobileView ? 0 : -10,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 2,
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  iconImageViewStyle: {
    height: 40,
    width: 40,
  },
});
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
