import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../../HelperClasses/AppColor';
import PropTypes from 'prop-types';
import tableStyle from '../../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../../Network/NetworkManager';
import Appload from '../../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../../HelperClasses/AppToast';
import commonStyle from '../../../StyleSheets/CommonStyleSheet';
import hideIcon from '../../../assets/hideEye.png';
import showIcon from '../../../assets/showEye.png';
import {Gradient} from 'react-gradient';
import SendcloudCountries from './SendcloudCountries';

const APPURL = require('../../../Constants/URLConstants');
let KeyGroup = 'shipping';

export default class SendCloudSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      sendcloud_pk_key: '',
      sendcloud_secret_key: '',
      hidePkKey: false,
      hideSecretKey: false,
      sendcloud_countries: [],
      countryArray: [],
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
    this.loadCountriesApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + KeyGroup,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        sendcloud_pk_key: configs[keysArray[0]] || '',
        sendcloud_secret_key: configs[keysArray[1]] || '',
        sendcloud_countries: configs['sendcloud_sender_addresses']
          ? Object.entries(configs['sendcloud_sender_addresses'])?.map(
              (it, index) => ({
                id: index,
                country: it[0],
                country_id: it[1],
              }),
            )
          : [],
      });
    }
    this.setState({dataLoad: true});
  };

  loadCountriesApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.countryURl,
      'get',
    );
    this.setState({countryArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['countries'];
      for (let obj of objectD) {
        var catData = obj;
        catData['label'] = obj['name'];
        catData['value'] = obj['id'];
        this.state.countryArray.push(catData);
      }
    }
    this.setState({dataLoad: true});
  };

  addOrUpdateAppDetailApi = async filesData => {
    var configs = [];
    let valueArray = [
      this.state.sendcloud_pk_key,
      this.state.sendcloud_secret_key,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: true,
        };
        configs.push(fDict);
      }
    }

    // country

    if (this.state.sendcloud_countries?.length > 0) {
      let fDict = {
        key_group: KeyGroup,
        key: 'sendcloud_sender_addresses',
        value: this.state.sendcloud_countries?.reduce((obj, item) => {
          if (item?.country) {
            return {
              ...obj,
              [item?.country]: item?.country_id ? item?.country_id : 0,
            };
          }
        }, {}),
        secured: false,
      };
      configs.push(fDict);
    }

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.deleteKeysAPI(configs);
      this.setState({dataLoad: true});
      // this.alertView(responseJson)
    }
    this.props.dissmissView();
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete');
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  renderSaveConfigBtn = () => {
    return (
      <View>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.submitBtnAction()}>
              <Text style={tableStyle.saveBtnTxtStyle}>{'Save'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={{flex: 1, padding: 10}}>
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={[commonStyle.titleNameStyle]}>Public Key</Text>
                <View
                  style={[
                    commonStyle.customTxtFieldViewStyle,
                    {width: '100%', marginTop: 0},
                  ]}>
                  <TextInput
                    style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                    placeholder="Enter api key "
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name =>
                      this.setState({sendcloud_pk_key: name})
                    }
                    value={this.state.sendcloud_pk_key}
                    secureTextEntry={!this.state.hidePkKey}
                  />
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({hidePkKey: !this.state.hidePkKey})
                    }>
                    <Image
                      style={{width: 15, height: 15}}
                      resizeMode="contain"
                      source={this.state.hidePkKey ? showIcon : hideIcon}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={commonStyle.titleNameStyle}>Secret Key</Text>
                <View
                  style={[
                    commonStyle.customTxtFieldViewStyle,
                    {width: '100%', marginTop: 0},
                  ]}>
                  <TextInput
                    style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                    placeholder="Enter notification secret"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name =>
                      this.setState({sendcloud_secret_key: name})
                    }
                    value={this.state.sendcloud_secret_key}
                    secureTextEntry={!this.state.hideSecretKey}
                  />
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({hideSecretKey: !this.state.hideSecretKey})
                    }>
                    <Image
                      style={{width: 15, height: 15}}
                      resizeMode="contain"
                      source={this.state.hideSecretKey ? showIcon : hideIcon}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>{' '}
          </View>{' '}
          <SendcloudCountries
            countries_list={this.state.countryArray}
            sendcloudCountries={this.state.sendcloud_countries}
            setSendCloudCountries={data => {
              this.setState({sendcloud_countries: data});
            }}
          />
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
});

let keysArray = ['sendcloud_pk_key', 'sendcloud_secret_key'];
