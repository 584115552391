import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions,
  Alert,
  Platform,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../assets/editIcon.png';
import deleteIcon from '../assets/delete_icon.png';
import disableIcon from '../assets/disableIcon.png';
import HeaderView from '../Component/AppHeader';
import PropTypes from 'prop-types';
import CreateCollections from './CreateCollection';
import Moment from 'moment';
import EmptyListUI from '../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import HelperLinkViews from '../Component/HelperLinkView';
import appMessage from '../Constants/AppConstantsMessage';
import listIcon from '../assets/listIcon.png';
import calendarIcon from '../assets/calendarIcon.png';
import selectedListIcon from '../assets/selectedListIcon.png';
import selectedcalendarIcon from '../assets/selectedcalendarIcon.png';
import copyPasteIcon from '../assets/copyPaste.png';
import {Gradient} from 'react-gradient';
import {getRandomColor} from '../HelperClasses/SingletonClass';
import AppCalendarView from '../Component/AppCalendarView';
import {checkUserRoleModule} from '../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import SegmentBtnView from '../Component/SegmentBtnView';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import {withRouter} from '../wrappers/withRouter';
import HelperVideoView from '../Component/HelperVideoView';

const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let dateFormat = 'DD-MM-YYYY HH:mm:ss';

class AllCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      collectionArray: [],
      dateLoad: false,
      selectedBusinessIndex: 0,
      isEdit: false,
      createCollectionBool: false,
      collectionID: 0,
      haveData: false,
      showCalendarView: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.loadCollectionsApi();
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.COLLECTIONS, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  //MARK:-  APIS Method 
  reloadApi = props => {
    this.loadCollectionsApi();
  };
  loadCollectionsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.collections,
      'get',
      '',
      '',
    );
    // console.log('collections', responseJson);
    this.setState({collectionArray: []});
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['collections'];
      for (let objc of collectionD) {
        var colD = objc;
        colD['allDay'] = true;
        colD['start'] = `${Moment(objc['start_at']).toDate()}`;
        colD['end'] = `${Moment(objc['end_at']).toDate()}`;
        colD['color'] = getRandomColor();
        this.state.collectionArray.push(colD);
      }
      this.state.haveData =
        this.state.collectionArray.length == 0 ? true : false;
    }
    this.setState({dateLoad: true});
  };
  deleteCollectionApi = async id => {
    this.setState({dateLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.collections + '/' + id,
      'delete',
    );
    this.setState({dateLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.alertView('Deleted');
      this.loadCollectionsApi();
    } else {
      this.mixpanelInfo(false);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event = `${MixPannelEnum.delete} ${MixpanelEvent.viewCollection}`;
    addMixpanelEvent(event, properties);
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({createCollectionBool: false});
  }
  createCollectionBtnAction() {
    this.props.navigate('/create-collection');
    this.loadCollectionsApi();
  }
  didSelectDropDown = item => {
    this.setState({showDropDown: false, selectedEntriesCount: item});
  };
  statusBtnAction = index => {
    this.props.saveBtnAction();
  };
  editButtonAction = index => {
    this.props.navigate(`/edit-collection/${index}`);
  };
  dtlCollectionBtnAction = index => {
    // console.log('deleteCollectionAction', index);
    if (Platform.OS === 'web') {
      const r = window.confirm('Do you want to delete this collection');
      if (r == true) {
        this.deleteCollectionApi(index);
      }
    } else {
      Alert.alert(
        'Do you want to delete this collection?',
        '',
        [
          {
            text: 'Yes',
            onPress: () => this.deleteCollectionApi(index),
          },
          {
            text: 'No',
          },
        ],
        {cancelable: false},
      );
    }
  };

  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 7809}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };

  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.collectionArray.length; i++) {
      let expirable = this.state.collectionArray[i]['expirable'] ? true : false;
      views.push(
        <FlatList
          data={[
            this.state.collectionArray[i]['id'],
            this.state.collectionArray[i]['id'],
            this.state.collectionArray[i]['condition_type'] == 2
              ? 'Manual'
              : 'Automatic',
            this.state.collectionArray[i]['scope_type'] == 4
              ? 'Listings'
              : this.state.collectionArray[i]['scope_type'] == 5
              ? 'Featured Listings'
              : 'Accounts',
            expirable
              ? `${Moment(this.state.collectionArray[i]['start_at']).format(
                  dateFormat,
                )}`
              : ' - ',
            expirable
              ? `${Moment(this.state.collectionArray[i]['end_at']).format(
                  dateFormat,
                )}`
              : ' - ',
            this.state.collectionArray[i]['active'] == true
              ? 'Active'
              : 'Inactive',
            this.state.collectionArray[i]['id'],
            this.state.collectionArray[i]['id'],
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
          keyExtractor={(item, index) => index + 78099}
          key={'C'}
        />,
      );
    }
    return views;
  };
  renderEditBtnView = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.writeP}
        onPress={() => this.editButtonAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.writeP ? edit_Icon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderDeleteBtnView = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.dtlCollectionBtnAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? deleteIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  columnCell = ({item, index}) => {
    if (index == 6) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item != 'Active'
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 8) {
      let id_Index = this.state.collectionArray.findIndex(x => x.id === item);
      let dict = this.state.collectionArray[id_Index];
      let path = `${AppConstants.domainKey}/${
        dict['scope_type'] === 1 ? 'ac' : 'cp'
      }/${dict?.slug ? dict?.slug : `tdc-${dict['id']}`}`;

      return (
        <View
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            padding: 10,
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: 'white',
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity onPress={() => window.open(path)}>
              <Text
                style={
                  (tableStyle.columnViewTitleStyle, {whiteSpace: 'nowrap'})
                }>
                {path}
              </Text>
            </TouchableOpacity>

            <View style={{width: 10}} />
            <TouchableOpacity
              onPress={() => navigator.clipboard.writeText(path)}>
              <Image source={copyPasteIcon} style={{width: 15, height: 15}} />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (index == 7) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtnView(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtnView(item)}
          </View>
        </View>
      );
    } else if (index == 1) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={tableStyle.columnViewTitleStyle}>tdc-{item}</Text>
            <View style={{width: 10}} />
            <TouchableOpacity
              onPress={() => navigator.clipboard.writeText(`tdc-${item}`)}>
              <Image source={copyPasteIcon} style={{width: 15, height: 15}} />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (index == 0) {
      let id_Index = this.state.collectionArray.findIndex(x => x.id === item);
      let dict = this.state.collectionArray[id_Index];
      return (
        <TouchableOpacity
          style={styles.columnTitleViewStyle}
          onPress={() => this.editButtonAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{dict['title']}</Text>
        </TouchableOpacity>
      );
    } else if (index == 3) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={[
              tableStyle.columnViewTitleStyle,
              {color: colors.LightDarkGray},
            ]}>
            {item}
          </Text>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };

  loaderView = () => {
    return <Appload enable={this.state.dateLoad} />;
  };
  EmptyListRender = () => {
    if (this.state.collectionArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <EmptyListUI
              messageIcon={2}
              showImage={true}
              titleString={appMessage.emptyCollectionTitleString}
              subtitleString={appMessage.collectionMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  renderCreateCollectionBtn = () => {
    return (
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={colors.GradientColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={() => this.createCollectionBtnAction()}>
            <Text style={tableStyle.saveBtnTxtStyle}>
              {'Create collections'}
            </Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    );
  };
  renderHeaderBtn = () => {
    return (
      <View style={{flexDirection: 'row', zIndex: 100, marginTop: 5}}>
        <View style={tableStyle.configurationBarViewStyle}>
          {/* <View style={{ width: 10 }} /> */}
          <View>
            <SegmentBtnView
              title={'List'}
              isSelected={!this.state.showCalendarView}
              icon={!this.state.showCalendarView ? selectedListIcon : listIcon}
              tapAction={() => this.setState({showCalendarView: false})}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Calendar'}
              isSelected={this.state.showCalendarView}
              icon={
                this.state.showCalendarView
                  ? selectedcalendarIcon
                  : calendarIcon
              }
              tapAction={() => this.setState({showCalendarView: true})}
            />
          </View>
          <View style={{width: 10}} />
        </View>
        <View style={{width: 20}} />
      </View>
    );
  };
  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View
          style={[
            tableStyle.headerSubContainer,
            {
              justifyContent: itsMobileView ? 'flex-start' : 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
            },
          ]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
              Collections{' '}
            </Text>
            <ToolTipViewBtn
              title={AppConstantsMessage.collectionsTitleToopTipMsg}
              description={AppConstantsMessage.collectionsSubTitleToolTipMsg}
            />
            <View style={{width: 10, height: 10}} />
            <HelperVideoView
              helperVideoBtnAction={() =>
                window.open(AppConstants.CollectionsVideo, '_blank')
              }
            />
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
                Index: 4,
                flex: 1,
                marginLeft: itsMobileView ? 10 : 0,
              }}>
              <this.renderHeaderBtn />
            </View>
          </View>
          <View
            style={{
              alignSelf: itsMobileView ? 'flex-start' : 'flex-end',
              marginTop: itsMobileView ? 10 : 0,
            }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <this.renderCreateCollectionBtn />
            </View>
          </View>
        </View>
      </View>
    );
  };
  renderListView = () => {
    return (
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  renderCalendarView = () => {
    return (
      <View
        style={{
          flex: 1,
          display: this.state.collectionArray.length == 0 ? 'none' : 'flex',
        }}>
        <AppCalendarView
          eventArray={this.state.collectionArray}
          didSelectEvent={event => this.editButtonAction(event['id'])}
        />
      </View>
    );
  };
  renderMainView = () => {
    if (this.state.showCalendarView) {
      return <this.renderCalendarView />;
    } else {
      return <this.renderListView />;
    }
  };
  render() {
    if (this.state.createCollectionBool) {
      let id_Index = this.state.collectionArray.findIndex(
        x => x.id === this.state.collectionID,
      );
      return (
        <View>
          <ToastContainer />
          <CreateCollections
            isEdit={this.state.isEdit}
            collectionID={this.state.collectionArray[id_Index]}
            backBtnAction={() => this.createCollectionBtnAction()}
          />
        </View>
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <this.renderHeaderView />
          <View style={tableStyle.mainView}>
            <ToastContainer />
            <this.renderMainView />
            <this.EmptyListRender />
          </View>
          <HelperLinkViews title={'Collections'} />
          <this.loaderView />
        </SafeAreaView>
      );
    }
  }
}
export default withRouter(AllCollection);
const rendererSettings = {
  preserveAspectRatio: 'xMinYMin slice',
};
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : windowWidth / 7.2,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / 7.2,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
});

const tableHeaderString = [
  'Title',
  'ID',
  'Condition Type',
  'Scope',
  'Start Date',
  'End Date',
  'Status',
  'Action',
  'URL',
];
const columnDataString = [
  'MYR',
  'MYR',
  'MYR {amount} fdf fd ',
  '1.0000',
  'Active',
  'yes',
  'Action',
];
