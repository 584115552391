import React, {useEffect, useState} from 'react';
import getTenantCurrency from '../../Actions/getTenantCurrencies';
import TextInput from '../../UI/Form/TextInput';
import TextArea from '../../UI/Form/TextArea';
import BooleanField from '../../UI/Form/BooleanField';
import ImageSelect from '../../UI/Form/ImageSelect';
import SelectDropDown from '../../UI/Form/SelectDropDown';

const SubscriptionForm = ({form_state, onUpdateForm}) => {
  const [currencys, setCurrencies] = useState([]);

  useEffect(() => {
    loadCurrency();
  }, []);

  //loadCurrency
  const loadCurrency = async () => {
    const currency = await getTenantCurrency();
    setCurrencies(currency);
  };

  const subscription_type = [
    {
      label: 'Stripe',
      value: 'stripe',
    },
    {
      label: 'Mangopay',
      value: 'mangopay',
    },
    {
      label: 'Android',
      value: 'android',
    },
    {
      label: 'iOS',
      value: 'ios',
    },
    {
      label: 'Free Subscription',
      value: 'free_subscription',
    },
  ];

  return (
    <div className=" max-h-[85dvh] h-full overflow-y-auto border border-gray-200 rounded-md py-4 px-5 w-[97%] m-3 flex flex-col gap-3  ">
      {/* Image */}
      <ImageSelect
        title={'Image'}
        current_data={form_state?.image_path}
        update_data={value => {
          onUpdateForm({key: 'image_path', value: value});
        }}
        isRequired={true}
      />
      {/* Title */}
      <TextInput
        title={'Title'}
        current_data={form_state?.title}
        placeholder={'Subscription Title'}
        type={'text'}
        update_data={data => onUpdateForm({key: 'title', value: data})}
        isRequired={true}
      />
      {/* Description */}
      <TextArea
        title={'Description'}
        current_data={form_state?.description}
        placeholder={'Subscription Description'}
        type={'text'}
        update_data={data => onUpdateForm({key: 'description', value: data})}
        isRequired={true}
      />
      {/* Amount & curency */}
      <div className="flex items-center justify-start gap-2">
        <div className="max-w-[200px] w-full">
          <SelectDropDown
            name={'Currency'}
            title={'Currency'}
            selected_value={form_state?.currency_id}
            setValue={value => onUpdateForm({key: 'currency_id', value: value})}
            list={currencys?.map(cr => ({label: cr.name, value: cr.id}))}
            default_text={'Select Currency'}
            isRequired={true}
          />
        </div>

        <TextInput
          title={'Amount'}
          current_data={form_state?.amount}
          placeholder={'Subscription Amount'}
          type={'number'}
          update_data={data => onUpdateForm({key: 'amount', value: data})}
          isRequired={true}
        />
      </div>
      {/* SKU */}
      <TextInput
        title={'SKU'}
        current_data={form_state?.sku}
        placeholder={'Subscription SKU'}
        type={'text'}
        update_data={data =>
          onUpdateForm({key: 'sku', value: data?.toUpperCase()})
        }
        isRequired={true}
      />
      {/* allowed_listings */}
      <TextInput
        title={'Allowed Listings'}
        current_data={form_state?.allowed_listings}
        placeholder={'Subscription Allowed Listings'}
        type={'number'}
        update_data={data =>
          onUpdateForm({key: 'allowed_listings', value: data})
        }
        isRequired={true}
      />
      {/* fee_fixed_per_order */}
      <TextInput
        title={'Fee Fixed Per Order'}
        current_data={form_state?.fee_fixed_per_order}
        placeholder={'Subscription Fee Fixed Per Order'}
        type={'number'}
        update_data={data =>
          onUpdateForm({key: 'fee_fixed_per_order', value: data})
        }
        isRequired={true}
      />
      {/* commission_percent_per_order */}
      <TextInput
        title={'Commission Percent Per Order'}
        current_data={form_state?.commission_percent_per_order}
        placeholder={'Subscription Commission Percent Per Order'}
        type={'number'}
        update_data={data =>
          onUpdateForm({key: 'commission_percent_per_order', value: data})
        }
        isRequired={true}
      />

      {/* expiry_days */}
      <TextInput
        title={'Expiry Days'}
        current_data={form_state?.expiry_days}
        placeholder={'Subscription Expiry Days'}
        type={'number'}
        update_data={data => onUpdateForm({key: 'expiry_days', value: data})}
        isRequired={true}
      />
      {/* type */}
      <SelectDropDown
        name={'Type'}
        title={'Subscription Type'}
        selected_value={form_state?.type}
        setValue={value => onUpdateForm({key: 'type', value: value})}
        list={subscription_type}
        default_text={'Select Type'}
        isRequired={true}
      />

      {/* active */}
      <BooleanField
        title={'Active'}
        current_data={form_state?.active ?? true}
        placeholder={'Subscription Active'}
        type={'text'}
        update_data={data => {
          onUpdateForm({key: 'active', value: data});
        }}
      />
    </div>
  );
};

export default SubscriptionForm;
