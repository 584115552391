import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { Gradient } from 'react-gradient';
import AddFleet from './AddFleet';
import { AppToast } from '../../HelperClasses/AppToast';
import selectedListIcon from '../../assets/selectedListIcon.png';
import AppConstants from '../../Constants/AppConstants';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import checked_Icon from '../../assets/checkedIcon.png';
import unchecked_Icon from '../../assets/uncheckedIcon.png';
import closeIcon from '../../assets/closeIcon.png';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false
const perPage = 50
const Unassigned = 'Unassigned'

export default class Fleets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fleetsArray: [],
      dataLoad: false,
      selectedFleetsIndex: 0,
      isEdit: false,
      addFleetsBool: false,
      stopPage: false,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      totalRecords: 0,
      branchesArray:[],
      selectedBranchAarray:[],
      appliedBranch:[],
      showFilterView: false,
      selectedFilterID: -1,
    }
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo()
    this.initApi()
    this.checkModule()
    this.loadBranchsApi()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  mixPannelInfo(){
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.fleets}`,{'page':pageNo})
  }
  //MARK:-  APIS Method 
  initApi(){
    pageNo = 1
    this.loadFleetsApi()
  }
  loadFleetsApi = async (type) => {
    this.setState({ dataLoad: false })
    var param = ''
    if (this.state.appliedBranch.length != 0) {
      param = `&branch_id=${this.state.appliedBranch.toString()}`
    }
    let path = `?page=${pageNo}&per_page=${perPage}${param}`
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryFleets + path, 'get')
    this.setState({ fleetsArray: [] });
    // console.log('responseJson', responseJson)
    if (responseJson['status'] == true) {
      var fleetD = responseJson['data']['fleets']
      this.state.totalRecords = responseJson['data']['total_records'];
      if (fleetD.length != 0) {
        this.setState({ stopPage: false });
        for (let objc of fleetD) {
          this.state.fleetsArray.push(objc);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({ stopPage: true });
      }
      this.state.haveData = this.state.fleetsArray.length == 0 ? true : false
    } else {
      AppToast(responseJson)
    }
    this.setState({ dataLoad: true });
  }
  loadBranchsApi = async () => {
    let path = `${APPURL.URLPaths.deliveryBranches}?page=${pageNo}&per_page=${100}`
    const responseJson = await networkService.networkCall(path, 'get');
    this.setState({ branchesArray: [] });
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches']
      for (let objc of branchD){
        this.state.branchesArray.push(objc)
      }
    }
  }
  deleteFleetApi = async id => {
    this.setState({ dataLoad: false });
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryFleets + '/' + id, 'delete');
    // console.log('res po', responseJson);
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.mixpanelInfoDelete(true)
      this.alertView('delete')
      this.loadFleetsApi();
    } else {
      this.mixpanelInfoDelete(false)
    }
  }
  mixpanelInfoDelete(resp){
    let dic = {'success':resp}
    addMixpanelEvent(`${MixpanelEvent.deleteFleet}`,dic)
  }
  alertView(title) {
    AppToast(title)
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({ addFleetsBool: false })
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadFleetsApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadFleetsApi();
      }
    }
    this.mixPannelInfo()
    this.setState({ updateUI: !this.state.updateUI });
  }
  addFleetBtnAction() {
    this.setState({ addFleetsBool: !this.state.addFleetsBool });
    this.setState({ isEdit: false });
    this.loadFleetsApi();
  }
  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.fleetsArray.findIndex(x => x.id === index);
    this.setState({ isEdit: true, selectedFleetsIndex: id_Index });
    this.setState({ addFleetsBool: true });
  };
  deleteFleetBtnAction = index => {
    let id_Index = this.state.fleetsArray.findIndex(x => x.id === index);
    let name = this.state.fleetsArray[id_Index]['company_name']
      const r = window.confirm(`Are you sure you want to delete`);
      if (r == true) {
        this.deleteFleetApi(index);
      }
  }
  didSelectBranch= (item) => {
    let ind = this.state.selectedBranchAarray.findIndex( x => x == item['id']) 
    if (ind != -1) {
      this.state.selectedBranchAarray.splice(ind,1)
    } else {
      this.state.selectedBranchAarray.push(item['id'])
    }
    this.setState({updateUI: !this.state.updateUI});
  }

  applyBtnAction(id) {
    this.state.appliedBranch = this.state.selectedBranchAarray
    this.setState({showFilterView: false, selectedFilterID: -1 })
    this.initApi()
  }
  clearBtnAction(id){
    if (id == 1){
      this.state.appliedBranch = []
    } else{
      this.state.appliedBranch = []
    }
    this.setState({showFilterView: false,selectedFilterID:-1})
    this.getApi()
  }
  fiterBtnAction() {
    // console.log('fiterBtnAction', this.state.appliedBranch)
    let branch = [... this.state.appliedBranch]
    this.state.selectedBranchAarray = branch
    this.setState({ showFilterView: !this.state.showFilterView, selectedFilterID: -1 })
  }
  didSelectFilter(id) {
    this.setState({ selectedFilterID: this.state.selectedFilterID == id ? -1 : id })
    if (id == 2){
      this.clearBtnAction(5)
    } 
  }
  removeFilterValueBtnAcion(item){
    if (item['type'] == 1){
      let indx = this.state.appliedBranch.findIndex(x => x == item['id']) 
      this.state.appliedBranch.splice(indx, 1)
    }  
    this.setState({updateUI: !this.state.updateUI})
    this.initApi()
  }

  /*  UI   */
  renderFilterView = () => {
    return <View style={tableStyle.filterContainerViewStyle}>
      <View style={{ display: this.state.showFilterView ? 'flex' : 'none'}}>
        <View style={[tableStyle.filterViewStyle,{marginLeft:25, flexDirection:'row-reverse'}]}>
          <FlatList
            data={FilterStaticArray}
            renderItem={this.renderFilterCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index + 9989}
            key={'FL'}
            style={{marginBottom:10}}
          />
          <View style={{height:'100%', width:1, backgroundColor: colors.SimonGray}}/>
          {this.renderSubListView(1)}
        </View>
      </View>
    </View>
  }
  renderFilterCell = ({ item, index }) => {
    return (
      <TouchableOpacity onPress={() => this.didSelectFilter(index)}
        style={{ marginLeft: 8, marginTop: 10, marginRight: 8, backgroundColor: this.state.selectedFilterID == index ? colors.SimonGray : colors.TransparentColor, padding: 5, borderRadius: 2 }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    );
  }

  renderClearApplyButton = (id) => {
    return <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <TouchableOpacity onPress={() => this.clearBtnAction(id)}>
        <Text style={tableStyle.clearTxtStyle}>Clear</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => this.applyBtnAction(id)}>
        <Text style={tableStyle.applyTxtStyle}>Apply</Text>
      </TouchableOpacity>
    </View>
  }
  
  renderSubListView = (id) => {
    var views = []
    let dynamicAry =  this.state.selectedBranchAarray
    let valueArray =  this.state.branchesArray 
    var check = this.state.selectedFilterID == 0 ? true : false
    if (id == 2) {
      check = this.state.selectedFilterID == 1 ? true : false
    }
    for (let a = 0; a < valueArray.length; a++) {
      let item = valueArray[a]
      let ind = dynamicAry.findIndex(x => x == item['id'])
      views.push(<View>
        <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          onPress={() => this.didSelectBranch(item) }>
          <Text>{item['name']}</Text>
          <Image style={{ width: 12, height: 12, marginRight:10 }} source={ind != -1 ? checked_Icon : unchecked_Icon} />
        </TouchableOpacity>
        <View style={{ height: 5, width: 5 }} />
      </View>)
    }
    return <View style={{ width: 170,display: check ? 'flex' : 'none' }}>
      <View style={{ margin: 10 }}>
        <ScrollView style={{height:150, marginTop:0}}>
          <View>
            {views}
          </View>
        </ScrollView>
        <View style={{ zIndex: 12, marginTop: 10}}>
          {this.renderClearApplyButton(id)}
        </View>
      </View>
    </View>
  }
  renderHeaderRightBtn = () => {
    return (<View style={{ flexDirection: 'row', marginLeft: 0, zIndex: 100, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View style={{ position: 'relative', flexDirection: 'row-reverse' }}>
          <View>
            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              onPress={() => this.fiterBtnAction()}>
              <Text style={[CommonStyleSheet.plainSubTextStyle,{color: this.state.showFilterView ? colors.AppNewGreen : colors.Appgray }]}>Filter</Text>
            </TouchableOpacity>
            <View style={{ backgroundColor: this.state.showFilterView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
          </View>
          {this.renderFilterView()}
        </View>
      </View>
    </View>)
  }

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}

      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.fleetsArray.length; i++) {
      var branch = Unassigned
      if (this.state.fleetsArray[i]['branch']) {
        if (this.state.fleetsArray[i]['branch'] != null){
          branch = this.state.fleetsArray[i]['branch']['name']
        }
      }
      views.push(
        <FlatList
          data={[
            this.state.fleetsArray[i]['reference_name'],
            this.state.fleetsArray[i]['owner'],
            this.state.fleetsArray[i]['company_name'],
            this.state.fleetsArray[i]['fleet_type'],
            branch,
            this.state.fleetsArray[i]['plate_number'],
            this.state.fleetsArray[i]['capacity'],
            this.state.fleetsArray[i]['active'] == true ? 'Active' : 'Inactive',
            this.state.fleetsArray[i]['id'],
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white'}}
          keyExtractor={(index) => index + 9989}
          key={'H'}
        />,
      )
    }
    return views
  }
  columnCell = ({ item, index }) => {
    if (index == 7) {
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item != 'Active' ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle}>
            {item}
          </Text>
        </View>
      );
    } else if (index == 8) {
      return (
        <View style={styles.columnTitleViewStyle}>
          {this.renderEditDeleteView(item)}
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={[tableStyle.columnViewTitleStyle, { color: item == Unassigned ? colors.AppRed : colors.blackColor }]}>
            {item}</Text>
        </View>
      )
    }
  }
  gradientButtons = name => {
    if (this.state.fleetsArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />
      } else if (name['id'] == 1 && this.state.fleetsArray.length == 0 && pageNo > 1) {
        return <View />
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{ height: 30, width: 100, borderRadius: 10, marginRight: 30 }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{ color: 'white' }}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  }
  renderEditDeleteView = (item) => {
    return <View style={{ flexDirection: 'row' }}>
      <TouchableOpacity onPress={() => this.editButtonAction(item)}>
        <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon} />
      </TouchableOpacity>
      <View style={{ width: 10 }} />
      <TouchableOpacity onPress={() => this.deleteFleetBtnAction(item)}>
        <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon}/>
      </TouchableOpacity>
    </View>
  }
  renderAddFleetBtn = () => {
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors} // required
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.addFleetBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{'Add Fleet'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderHeaderBtn = () => {
    return (<View style={{ flexDirection: 'row', zIndex: 100, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View style={{ width: 10 }} />
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image style={tableStyle.viewIconsStyle} source={selectedListIcon} />
            <Text style={CommonStyleSheet.themeColorTextStyle}>Fleets</Text>
          </View>
          <View style={{ backgroundColor: colors.AppNewGreen, height: 2, width: '100%', marginTop: 5 }} />
        </View>
      </View>
    </View>)
  }
  renderHeaderView = () => {
    return (<View style={tableStyle.headerViewstyle}>
      <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
        {/* <this.renderHeaderBtn /> */}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <this.renderHeaderRightBtn />
        <View style={{ width: 20, height:20 }} />
        <this.renderAddFleetBtn />
      </View>
    </View>)

  }
 
 
  EmptyListRender = () => {
    if (this.state.fleetsArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{ height: '100%', justifyContent: 'center' }}>
          <EmptyListUI showImage={true} titleString={'No fleet found, add and manage it'} subtitleString={''} />
        </View>);
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  }

  renderListView = () => {
    return <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>

  }
  renderBottomView = () => {
    if (this.state.totalRecords > perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
      </View>)
    } else {
      return <View />
    }
  }
  renderSelectFilterView = () => {
    var views = []
    var ssAry = []
    if (this.state.appliedBranch.length != 0) {
      for (let obj of this.state.appliedBranch){
      let indx = this.state.branchesArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.branchesArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:1})
      }
    }
   
    for (let a = 0; a < ssAry.length; a++) {
      let obj = ssAry[a]
      views.push(<View style={{ backgroundColor: obj['color'], borderRadius: 8, padding: 5, flexDirection: 'row', margin: 5 }}>
        <Text style={[CommonStyleSheet.filterTitleStyle,{paddingLeft: 2 }]}>{obj['title']}</Text>
        <TouchableOpacity onPress={() => this.removeFilterValueBtnAcion(obj)}>
          <Image style={{ width: 14, height: 14, marginLeft: 10 }} source={closeIcon} />
        </TouchableOpacity>
      </View>)
    }
    return <View style={{ width: '97%', marginLeft: 20, flexDirection:'row',}}>
      {views}
    </View>
  }

  render() {
    if (this.state.addFleetsBool) {
      return (
        <View>
          <ToastContainer />
          <AddFleet
            closeBtnAction={() => this.addFleetBtnAction()}
            isEdit={this.state.isEdit}
            fleetData={this.state.fleetsArray[this.state.selectedFleetsIndex]}
            backBtnAction={() => this.addFleetBtnAction()}
          />
        </View>
      )
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <this.renderHeaderView />
          <this.renderSelectFilterView />
          <View style={tableStyle.containerMainView}>
            <this.renderListView />
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}
const commonWidth = windowWidth/8.0
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
  },
});

const tableHeaderString = [
  'Reference Name',
  'Owner',
  'Company Name',
  'Fleet Type',
  'Branch Name',
  'Plate Number',
  'Capacity',
  'Status',
  'Action'
];

const FilterStaticArray = [
  'Branch',
  'Clear Filter',
]