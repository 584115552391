import React, {Component} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import {ReactComponent as InfoSVGIcon} from './../assets/infoSVGIcon.svg';
import {ReactComponent as Uparrow} from './../assets/uparrow.svg';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';

export default class ToolTipViewBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showView: false,
    };
  }
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
  };
  renderView = () => {
    return (
      <View
        style={[
          styles.containerStyle,
          {display: this.state.showView ? 'flex' : 'none'},
        ]}>
        <Uparrow
          style={{marginTop: -22, height: 16, width: 16, marginLeft: 5}}
        />
        <View style={{padding: 10}}>
          <Text
            style={[CommonStyleSheet.plainTextStyle, {color: colors.AppWhite}]}>
            {this.props.title}
          </Text>

          <Text
            style={[
              CommonStyleSheet.helperTextStyle,
              {color: colors.AppWhite, marginTop: 10},
            ]}>
            {this.props.description}
          </Text>
        </View>
      </View>
    );
  };
  renderToolTipBtn = () => {
    return (
      <View
        onMouseLeave={() => this.setState({showView: false})}
        onMouseEnter={() =>
          this.setState({
            showView: this.props.title == undefined ? false : true,
          })
        }>
        <InfoSVGIcon
          style={{height: 20, width: 20, marginTop: 2, marginLeft: 10}}
        />
        {this.renderView()}
      </View>
    );
  };
  render() {
    return (
      <View style={{alignItems: 'center', marginTop: 2}}>
        {this.renderToolTipBtn()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  containerStyle: {
    position: 'absolute',
    backgroundColor: colors.toolTipBGColor,
    borderRadius: 10,
    minWidth: 320,
    marginTop: 35,
    padding: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    marginLeft: -13,
    zIndex: 999999999,
  },
});
