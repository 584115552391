import axios from 'axios';

export default async function gen_open_ai_resp(params) {
  try {
    const op_resp = await axios({
      url: 'https://api.openai.com/v1/chat/completions',
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization:
          'Bearer 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',
      },
      data: {
        messages: [
          {
            role: 'system',
            content: params.system_instruction,
            name: 'system-instruction',
          },
          {
            role: 'user',
            content: params.user_message,
            name: `user-message-${params.user_id}`,
          },
        ],
        model: params.model,
        max_tokens: params.max_tokens,
        temperature: params.temperature ?? 0.1,
        user: params.user_id ?? '',
        n: 1,
        stream: false,
      },
    });
    const data_resp = {
      id: op_resp?.data?.id,
      created_at: op_resp?.data?.created ?? '',
      system_fingerprint: op_resp?.data?.system_fingerprint,
      model: params.model,
      ai_type: params.ai_type,
      ai_response: op_resp?.data?.choices[0].message.content ?? '',
      finished_reason: op_resp?.data?.choices[0].finish_reason,
      prompt_tokens: op_resp?.usage?.prompt_tokens,
      completion_tokens: op_resp?.usage?.completion_tokens,
      total_tokens: op_resp?.usage?.total_tokens,
    };

    return data_resp;
  } catch (error) {
    console.log({error});
    return {};
  }
}
