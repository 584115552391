import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Image,

} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import disableIcon from '../../assets/disableIcon.png';
import Header_View from '../HeaderView';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer} from 'react-toastify';
import {AppToast} from '../../HelperClasses/AppToast';
import AddLanguages from './AddLanguage';
import HelperLinkViews from '../../Component/HelperLinkView';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import closeIcon from '../../assets/closeBlackIcon.png';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import { RestrictedFeatureEnum } from '../../Models/RestrictedFeatureEnum';
import SubscribePlan from '../../Component/SubscribePlan';
import SidePanView from '../../Component/SidePanView';


const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
const configViewRightMargin = -420


export default class LanguageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageArray: [],
      dateLoad: false,
      selectedLanguageIndex: 0,
      isEdit: false,
      addLanguageBool: false,
      stopPage: false,
      haveData: false,
      writeP:AppConstants.defaultWrite,
      deleteP:AppConstants.defaultDelete,
      showUpgrade:false,
    }
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewLanguageList,{'type':MixPannelEnum.listView})
    this.loadLanguageApi()
    this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.LANGUAGE, res => {
      this.setState({ writeP: res[1],deleteP :res[2]})
    })
  }
  //MARK:-  APIS Method 
  loadLanguageApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tanantslanguage, 'get', '', '');
    this.setState({languageArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['languages'];
      for (let i = 0; i < objectD.length; i++) {
        this.state.languageArray.push(objectD[i]);
      }
      this.state.haveData = this.state.languageArray.length == 0 ? true : false
    }
    this.setState({dateLoad: true});
  };
  deletePaymentMethodsApi = async id => {
    this.setState({dateLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tanantslanguage + '/' + id, 'delete', '', '');
    this.setState({dateLoad: true});
    if (responseJson['status'] == true) {
      this.alertView('Deleted');
      this.loadLanguageApi();
    }
  };
  alertView(title) {
    AppToast();
  }
  //MARK:- Button Actions
  addLanguageBtnAction() {
    if (this.checkRestriction()) {
      AppToast('Permission')
    } else {
      this.backBtnAction()
    }
  }
  backBtnAction(){
    this.setState({ addLanguageBool: !this.state.addLanguageBool })
    this.setState({ isEdit: false })
    this.loadLanguageApi();
  }

  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.languageArray.findIndex(x => x.id === index);
    this.setState({isEdit: true, selectedLanguageIndex: id_Index});
    this.setState({addLanguageBool: true});
  }
  dltLanguageBtnAction = index => {
    let msg = 'Do you want to go delete this Language';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deletePaymentMethodsApi(index);
      }
    }
  } 
  helperVideoBtnAction(){
    addMixpanelEvent(MixpanelEvent.howItWorks,{'Action' : MixPannelEnum.multiLanguage})
    window.open(AppConstants.languageHelperVideoLink, '_blank')
  }
  checkRestriction(){
    let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.languages)
      return check && this.state.languageArray.length != 0 ? true : false
  }

  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    if (index == 0 ) {
      return (
        <View style={styles.tableViewTitleViewStyle}>
          <View style={{alignItems: 'flex-start', paddingLeft: 0}}>
            <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
          </View>
        </View>
      );
    } else { 
      return (
        <View style={styles.tableViewTitleViewStyle}>
          <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  columnDataView = props => {
    if (this.state.languageArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />;
      }else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.languageArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.languageArray[i]['name'],
              this.state.languageArray[i]['default'],
              this.state.languageArray[i]['order_by'] || '1',
              this.state.languageArray[i]['active'],
              this.state.languageArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{borderBottomWidth: 1, borderBottomColor: colors.SimonGray,backgroundColor:'white'}}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 1 || index == 3) {
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} >
          <Text style={item == false ? tableStyle.columnDeActiveBtnStyle  : tableStyle.columnActiveBtnStyle }>
            {index == 1 ? item ? 'Yes': 'No' : item ? 'Active' : 'Inactive'}
          </Text>
        </TouchableOpacity>
      );
    } else if (index == 4) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            {this.renderEditBtn(item)}
            <View style={{width: 10}} />
            {this.renderDeleteBtn(item)}
          </View>
        </View>
      );
    } else if (index == 0) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  }
  renderEditBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.writeP} onPress={() => this.editButtonAction(item)}>
      <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.writeP ? edit_Icon : disableIcon} />
    </TouchableOpacity>
  }
  renderDeleteBtn = (item) => {
    return <TouchableOpacity disabled={!this.state.deleteP} onPress={() => this.dltLanguageBtnAction(item)}>
    <Image style={tableStyle.iconStyle} resizeMode="center" source={this.state.deleteP ? deleteIcon : disableIcon} />
    </TouchableOpacity>
  }
  renderAddLanguageView = () => {
    if (this.state.addLanguageBool) {
      return <SidePanView
        dissmissView={() => this.setState({ addLanguageBool: !this.state.addLanguageBool })}
        showSidepanView={this.state.addLanguageBool}
        customView={<View>
          <AddLanguages
            isEdit={this.state.isEdit}
            languageData={this.state.languageArray[this.state.selectedLanguageIndex]}
            backBtnAction={() => this.backBtnAction()}
          />
        </View>}
        title={this.state.isEdit ? 'Edit Language' : 'Add Language'}
      />
    } else { return <View /> }
  }
  renderUpgradePlanView() {
    return <TouchableOpacity style={[tableStyle.passwordViewStyle, { display: this.state.showUpgrade ? 'flex' : 'none' }]}
      onPress={() => this.setState({ showUpgrade: false })}>
      <SubscribePlan upgrade={true} />
    </TouchableOpacity>
  }
  render() {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={{ zIndex: 101 }}>
            {this.renderAddLanguageView()}
            {this.renderUpgradePlanView()}
          </View>
          <View style={{ zIndex: 2 }}>
          <Header_View
            title={'Languages'}
            backBtn={this.props.backBtn}
            saveBtnTitle={'Add Language'}
            saveBtn={() => this.addLanguageBtnAction()}
            showSaveBtn={this.state.writeP}
            showHelperVideoBtn={true}
            btnDeActive={this.checkRestriction()}
            deActiveBtnAction={() => this.setState({ showUpgrade: true })}
            helperVideoBtnAction={() => this.helperVideoBtnAction()}
          />
          <View style={tableStyle.listContainerView}>
            <ToastContainer />
            <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
              <View>
                <View style={tableStyle.headerListContainer}>
                  <this.tableViewHeader />
                </View>
                <ScrollView style={styles.tableViewHeaderStyle}>
                  <this.columnDataView />
                </ScrollView>
              </View>
            </ScrollView>
          </View>
          <HelperLinkViews  title={'Languages'}/>
          <Appload enable={this.state.dateLoad} />
          </View>
        </SafeAreaView>
    )
  }
}
const constantWidth = windowWidth < fixedWidth ? 130 : windowWidth / 4.9
const styles = StyleSheet.create({
  Container: {
    backgroundColor:colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: constantWidth,
    height: 40,
  },
  firstTableViewTitleViewStyle: {
    padding: 0,
    width: constantWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: constantWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  firstColumnTitleViewStyle: {
    width: constantWidth,
    padding: 10,
    paddingLeft: 20,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
});

const tableHeaderString = [
  'Name',
  'Default',
  'Order',
  'Status',
  'Action',
];