import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Dimensions,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import {getCookiesValue, getValue} from '../../HelperClasses/UserPrefrences';
import AppConstants from '../../Constants/AppConstants';
import copyPasteIcon from '../../assets/copyPaste.png';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class Developer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      hideShowPublickey: false,
      hideShowSecretkey: false,
      publishableKey: '',
      secretKey: '',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    getValue('id', resp => {
      this.setState({dataLoad: false});
      this.loadConfigApi(resp[0]);
    })
  }

  //MARK:-  APIs Method 
  loadConfigApi = async (tID) => {
    let envCookie = getCookiesValue(AppConstants.tradlyEVN);
    var env = 'development';
    if (!AppConstants.isDevelopment) {
      env = envCookie == 'sandbox' ? 'sandbox' : 'production' ;
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.developerAPIKey,'get','' ,`keys?env=${env}`);
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['tenant_keys'];
      for (let obj of configs) {
        if (obj['type'] == 1) {
          this.setState({secretKey: obj['key']});
        } else {
          this.setState({publishableKey: obj['key']});
        }
      }
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast()
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  hideShowEyeBtnAction(id) {
    if (id == 1) {
      this.setState({hideShowPublickey: !this.state.hideShowPublickey});
      // console.log('hideShowPublickey');
    } else {
      this.setState({hideShowSecretkey: !this.state.hideShowSecretkey});
      // console.log('hideShowSecretkey yes ');
    }
  }
  copyMediaBtnAction = (url,id) => {
    addMixpanelEvent(MixpanelEvent.apiIntegration,{'Copied' :id == 2 ? MixPannelEnum.secretKey : MixPannelEnum.publishableKey })
    navigator.clipboard.writeText(url)
    AppToast('Copied')
  };
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>API Keys</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          Find below your production keys. You will need to use this keys to
          authorise and build your apps
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{ flexDirection: 'column', flex: 1.5 }}>
            <View style={{ marginTop: -20 }}>
              <Text style={appConfigStyle.titleTxtStyle}>Publishable Key</Text>
              <View style={CommonStyleSheet.customTxtFieldViewStyle}>
                <TextInput
                  style={[CommonStyleSheet.customTxtFieldStyle,{outline:0}]}
                  placeholder="Enter Publishable Key"
                  placeholderTextColor={colors.placeholderColor}
                  value={this.state.publishableKey}
                  secureTextEntry={!this.state.hideShowPublickey}
                  editable={false}
                />
                <View style={{ width: 10 }} />
                <TouchableOpacity onPress={() => this.copyMediaBtnAction(this.state.publishableKey,1)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={copyPasteIcon}
                  />
                </TouchableOpacity>
                <View style={{ width: 10 }} />
                <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(1)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowPublickey ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ marginTop: 20 }}>
              <Text style={appConfigStyle.titleTxtStyle}>Secret Key</Text>
              <View style={CommonStyleSheet.customTxtFieldViewStyle}>
                <TextInput
                  style={[CommonStyleSheet.customTxtFieldStyle,{outline:0}]}
                  placeholder="Enter Secret Key"
                  placeholderTextColor={colors.placeholderColor}
                  value={this.state.secretKey}
                  secureTextEntry={!this.state.hideShowSecretkey}
                  editable={false}
                />
                <TouchableOpacity onPress={() => this.copyMediaBtnAction(this.state.secretKey,2)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={copyPasteIcon}
                  />
                </TouchableOpacity>
                <View style={{ width: 10 }} />
                <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(2)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowSecretkey ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  hideShowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
  },
});

let keysArray = [
  'stripe_api_publishable_key',
  'stripe_api_secret_key',
  'stripe_client_id',
  'stripe_config_screen_connect_message_standard',
  'stripe_config_screen_disconnect_message_standard',
  'stripe_config_screen_connect_message_express',
  'stripe_config_screen_disconnect_message_express',
];
