import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import grayTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import AppDetails from '../../AppConfiguration/AppDetail/AppDetail';
import OnBoardingSetting from '../../AppConfiguration/OnBoardingSettings/OnBoardingSettings';
import HomepageAppearances from '../../AppConfiguration/HomeAppearance/HomepageAppearance';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import HomePageCollections from './HomePageCollection';
import ForceToUpdates from './ForceToUpdate';
import OtherSettings from './OtherSetting';
import {ToastContainer} from 'react-toastify';
import AppConstants from '../../Constants/AppConstants';
import ChatSettings from '../GlobalSettings/ChatSetting';
import {checkUserRoleModule} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import SplashScreen from './SplashScreen';
import Registration from './Registration';
import MoreScreen from './MoreScreen';
import CartSetting from '../GlobalSettings/CartSetting';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import {Gradient} from 'react-gradient';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';
import colors from '../../HelperClasses/AppColor';
import AppColor from '../../HelperClasses/AppColor';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../../Models/MixPannelEnum';
import HelperVideoView from '../../Component/HelperVideoView';
import {ReactComponent as LockIcon} from '../../assets/lockIcon.svg';
import AddressSetting from '../GlobalSettings/AddressSetting';
import BackBtnView from '../../Component/BackBtnView';
import {withRouter} from '../../wrappers/withRouter';
import OrdersSettings from '../GlobalSettings/OrdersSettings';

let subMenuTextArray = [
  'General',
  'Splash',
  'Onboarding',
  'Registration',
  'Home',
  'Chat',
  // 'Account Detail',
  // 'Listing Detail',
  'Cart',
  // 'Shipment/Service',
  // 'Checkout',
  'More Menu',
  'Force Update',
  'Address',
  'Orders',
];

class MobileAppConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      writeP: AppConstants.defaultWrite,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
  };
  componentDidMount() {
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.APPCONFIG, res => {
      this.setState({writeP: res[1]});
    });
  };
  //MARK:-  APIs Method 
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 1) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 3) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 4) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 5) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 6) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 7) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 8) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 9) {
      this.child.saveBtnAction();
    } else if (this.state.subMenuSelectedIndex == 10) {
      this.child.saveBtnAction();
    } else {
      this.child.saveBtnAction();
    }
  }
  didSelectMenuItem = index => {
    this.setState({subMenuSelectedIndex: index});
  };
  helperVideoBtnAction() {
    addMixpanelEvent(MixpanelEvent.howItWorks, {
      Action: MixPannelEnum.mobileAppCustomisation,
    });
    window.open(AppConstants.mobileAppHelperVideoLink, '_blank');
  }

  /*  UI   */

  subMenuViewRender = () => {
    let subViews = [];
    var subMenuTextArrays = [...subMenuTextArray];
    // if (AppConstants.appType){
    //   subMenuTextArrays.splice(subMenuTextArrays.length - 1,1);
    // }
    for (let a = 0; a <= subMenuTextArrays.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex == a
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image
            style={SettingsStyleSheet.subMenuIconStyle}
            source={
              this.state.subMenuSelectedIndex == a
                ? greenTickIcon
                : grayTick_Icon
            }
          />
          <Text
            style={
              this.state.subMenuSelectedIndex == a
                ? SettingsStyleSheet.subMenuSelectedtextStyle
                : SettingsStyleSheet.subMenutextStyle
            }>
            {subMenuTextArray[a]}
          </Text>
          <View style={{display: a == 5 ? 'flex' : 'none'}}>
            <LockIcon style={{height: 16, width: 16, marginLeft: 5}} />
          </View>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };

  mainViewRender = () => {
    if (this.state.subMenuSelectedIndex == 0) {
      return <AppDetails ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 1) {
      return <SplashScreen ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 2) {
      return <OnBoardingSetting ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 3) {
      return <Registration ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 4) {
      return <HomePageCollections ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 5) {
      return (
        <ChatSettings
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 16) {
      return (
        <OtherSettings
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 6) {
      return (
        <CartSetting
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 7) {
      return <MoreScreen ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 8) {
      return <ForceToUpdates ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 9) {
      return <AddressSetting ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 10) {
      return <OrdersSettings ref={child => (this.child = child)} />;
    } else {
      return <View />;
    }
  };
  renderSaveBtn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={TableCommonStyleSheet.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.saveBtnAction()}>
              <Text style={TableCommonStyleSheet.saveBtnTxtStyle}>
                {'Save'}
              </Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        {/* <Header_View
          title={'App Customisation'}
          backBtn={this.props.backBtn}
          saveBtn={() => this.saveBtnAction()}
          showSaveBtn={this.state.writeP}
        /> */}
        <View style={SettingStyleSheet.navigationViewStyle}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity
              style={[
                SettingStyleSheet.backBtnBackViewStyle,
                {display: this.props.showBackBtn ? 'none' : 'flex'},
              ]}
              onPress={() => {
                this.props.navigate(-1);
              }}>
              <BackBtnView />
            </TouchableOpacity>
            <View style={SettingStyleSheet.headerViewStyle}>
              <Text
                style={[
                  TableCommonStyleSheet.titleTextStyle,
                  {marginLeft: -10},
                ]}>
                {'App Customisation'}
              </Text>
            </View>
            <HelperVideoView
              helperVideoBtnAction={() => this.helperVideoBtnAction()}
            />
          </View>
          <View style={{paddingLeft: 40, flexDirection: 'row'}}>
            {this.renderSaveBtn()}
          </View>
        </View>
        <View style={{flexDirection: 'column'}}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mobilemMainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.sideMenuStyle}>
                <ScrollView>
                  <this.subMenuViewRender />
                </ScrollView>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{flex: 2, backgroundColor: 'white'}}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}

export default withRouter(MobileAppConfig);
const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
  },
});
