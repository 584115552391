import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { Gradient } from 'react-gradient';
import AddBranch from './AddBranch';
import { AppToast } from '../../HelperClasses/AppToast';
import appMessage from '../../Constants/AppConstantsMessage';
import selectedListIcon from '../../assets/selectedListIcon.png';
import AppConstants from '../../Constants/AppConstants';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import listIcon from '../../assets/listIcon.png';
import locationIcon from '../../assets/locationIcon.png';
import selectedlocationIcon from '../../assets/selectedlocationIcon.png';
import GoogleMapReact from 'google-map-react';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false
const perPage = 50

export default class Branches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchArray: [],
      dataLoad: false,
      selectedBranchIndex: 0,
      isEdit: false,
      addBranchBool: false,
      stopPage: false,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      totalRecords: 0,
      showMapView: false,

    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo()
    this.loadBranchesApi()
    this.checkModule()
  }
  mixPannelInfo(){
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.branch}`,{'page':pageNo})
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  //MARK:-  APIS Method 
  loadBranchesApi = async (type) => {
    this.setState({ dataLoad: false });
    let path = `?page=${pageNo}&per_page=${perPage}`
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryBranches + path, 'get')
    this.setState({ branchArray: [] });
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches']
      this.state.totalRecords = responseJson['data']['total_records'];
      if (branchD.length != 0) {
        this.setState({ stopPage: false });
        for (let objc of branchD) {
          this.state.branchArray.push(objc);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({ stopPage: true });
      }
      this.state.haveData = this.state.branchArray.length == 0 ? true : false
    } else {
      AppToast(responseJson)
    }
    this.setState({ dataLoad: true });
  }
  deleteBranchApi = async id => {
    this.setState({ dataLoad: false });
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryBranches + '/' + id, 'delete');
    // console.log('res po', responseJson);
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.mixpanelInfoDelete(true)
      this.alertView('delete')
      this.loadBranchesApi(this.state.selectedType);
    } else {
      this.mixpanelInfoDelete(false)
    }
  }
  mixpanelInfoDelete(resp){
    let dic = {'success':resp}
    addMixpanelEvent(`${MixpanelEvent.deleteBranch}`,dic)
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({ addBranchBool: false })
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadBranchesApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadBranchesApi();
      }
    }
    this.mixPannelInfo()
    this.setState({ updateUI: !this.state.updateUI });
  }
  addBranchBtnAction() {
    this.setState({ addBranchBool: !this.state.addBranchBool });
    this.setState({ isEdit: false });
    this.loadBranchesApi();
  }
  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.branchArray.findIndex(x => x.id === index);
    this.setState({ isEdit: true, selectedBranchIndex: id_Index });
    this.setState({ addBranchBool: true });
  };
  deleteBranchBtnAction = index => {
    let id_Index = this.state.branchArray.findIndex(x => x.id === index);
    let name = this.state.branchArray[id_Index]['name']
    let title = `Are you sure you want to delete this ${name} branch? Doing so will unassign all fleets under this branch and no rosters will be created for open orders for this branch`
      const r = window.confirm(title);
      if (r == true) {
        this.deleteBranchApi(index);
      } 
  }

  /*  UI   */

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}

      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.branchArray.length; i++) {
      let latlng = `${this.state.branchArray[i]['coordinates']['latitude']}, ${this.state.branchArray[i]['coordinates']['longitude']}`
      views.push(
        <FlatList
          data={[
            this.state.branchArray[i]['id'],
            this.state.branchArray[i]['name'],
            this.state.branchArray[i]['time_zone'],
            latlng,
            this.state.branchArray[i]['active'] == true ? 'Active' : 'Inactive',
            this.state.branchArray[i]['id'],
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white'}}
          keyExtractor={(item, index) => index + 9989}
          key={'H'}
        />,
      );
    }
    return views;

  }
  columnCell = ({ item, index }) => {
    if (index == 4) {
      return (
        <View style={styles.columnTitleViewStyle} >
          <Text style={item != 'Active' ? tableStyle.columnDeActiveBtnStyle : tableStyle.columnActiveBtnStyle}>
            {item}
          </Text>
        </View>
      );
    } else if (index == 5) {
      return (
        <View style={styles.columnTitleViewStyle}>
          {this.renderEditDeleteView(item)}
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  }
  gradientButtons = name => {
    if (this.state.branchArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />
      } else if (name['id'] == 1 && this.state.branchArray.length == 0 && pageNo > 1) {
        return <View />
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{ height: 30, width: 100, borderRadius: 3, marginRight: 30 }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{ color: 'white' }}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  }
  renderEditDeleteView = (item) => {
    return <View style={{ flexDirection: 'row' }}>
      <TouchableOpacity onPress={() => this.editButtonAction(item)}>
        <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon} />
      </TouchableOpacity>
      <View style={{ width: 10 }} />
      <TouchableOpacity onPress={() => this.deleteBranchBtnAction(item)}>
        <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon}/>
      </TouchableOpacity>
    </View>
  }
  renderAddPomoBtn = () => {
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors} // required
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.addBranchBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{'Add Branch'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderHeaderBtn = () => {
    let showList  = !this.state.showMapView
    return (<View style={{ flexDirection: 'row', marginRight: 10, zIndex: 100, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View>
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this.setState({showMapView: false })}>
            <Image resizeMode={'cover'} style={tableStyle.viewIconsStyle} source={showList ? selectedListIcon : listIcon} />
            <Text style={[commonStyle.tabBtnViewStyle,{ color: showList ? colors.AppNewGreen : colors.Appgray}]}>List View</Text>
          </TouchableOpacity>
          <View style={{ backgroundColor: showList ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
        </View>
        <View style={{ width: 20 }} />
        <View>
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this.setState({ showMapView: true})}>
            <Image resizeMode={'cover'} style={tableStyle.viewIconsStyle} source={this.state.showMapView ? selectedlocationIcon : locationIcon} />
            <Text style={[commonStyle.tabBtnViewStyle,{ paddingLeft: 5,color: this.state.showMapView ? colors.AppNewGreen : colors.Appgray }]}>Map View</Text>
          </TouchableOpacity>
          <View style={{ backgroundColor:this.state.showMapView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
        </View>
        <View style={{ width: 20 }} />
      </View>
    </View>)
  }
  renderHeaderView = () => {
    return (<View style={tableStyle.headerViewstyle}>
      <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
        <this.renderHeaderBtn />
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <this.renderAddPomoBtn />
      </View>
    </View>)
  }
  EmptyListRender = () => {
    if (this.state.branchArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{ height: '100%', justifyContent: 'center' }}>
          <EmptyListUI showImage={true} titleString={appMessage.emptyBranchTitleString} subtitleString={''} />
        </View>);
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  }
  renderMapView = () => {
    var marker = []
    var lat = 0
    var lng = 0
    for (let objc of this.state.branchArray) {
      let item = objc['coordinates']
      if (item['latitude']) {
        if (lat == 0){
          lat = item['latitude']
          lng = item['longitude']
        }
        marker.push(<View lat={item['latitude']} lng={item['longitude']} >
           <View style={commonStyle.markerContainerViewStyle}>
          <View style={commonStyle.mapMarkerViewStyle} />
        </View>
        </View>)
      }
    }
    return <View style={{flex:1 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: AppConstants.googleMapAPIKey }}
        defaultCenter={{lat: lat, lng:lng }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals>
       {marker}
      </GoogleMapReact>
    </View>
  }

  renderListView = () => {
    return <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>

  }
  renderBottomView = () => {
    if (this.state.totalRecords > perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
      </View>)
    } else {
      return <View />
    }
  }
  renderMainView = () => {
   if (this.state.showMapView) {
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.branch}`,{'type':MixPannelEnum.mapView})
      return <this.renderMapView />
    }else {
      addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.branch}`,{'type':MixPannelEnum.listView})
      return <this.renderListView />
    }
  }
  

  render() {
    if (this.state.addBranchBool) {
      return (
        <View>
          <ToastContainer />
          <AddBranch
            closeBtnAction={() => this.addBranchBtnAction()}
            isEdit={this.state.isEdit}
            branchData={this.state.branchArray[this.state.selectedBranchIndex]}
            backBtnAction={() => this.addBranchBtnAction()}
          />
        </View>
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <this.renderHeaderView />
          <View style={tableStyle.containerMainView}>
            <this.renderMainView />
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}
const commonWidth = windowWidth/6.0
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
  },
});

const tableHeaderString = [
  'Branch ID',
  'Name',
  'Time Zone',
  'Coordinates',
  'Status',
  'Action'
];
