import React, {Component} from 'react';
import {StyleSheet, View, Text, Platform, Alert} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from 'react-switch';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
export default class CartSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      emailId: '',
      regexp: /^[0-9\.]+$/,
      minimumOrder: '',
      maximumQuantity: '',
      statusValue: false,
      dataLoad: false,
      enableCoupon: false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=orders',
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        statusValue: configs['hide_quantity_select_in_cart'] || false,
        enableCoupon: configs['enable_coupon'] ?? false,
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateCartApi = async () => {
    var configs = [];
    let fDict = {
      key_group: 'orders',
      key: 'hide_quantity_select_in_cart',
      value: this.state.statusValue,
      secured: false,
    };
    let fDict2 = {
      key_group: 'orders',
      key: 'enable_coupon',
      value: this.state.enableCoupon,
      secured: false,
    };
    configs.push(fDict);
    configs.push(fDict2);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      AppToast();
    }
  };
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateCartApi();
  }
  onHandleTextChange = (text, id) => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      if (id == 1) {
        this.setState({minimumOrder: text});
      } else {
        this.setState({maximumQuantity: text});
      }
    }
  };
  //MARK:-  UI 
  render() {
    return (
      <View style={{width: '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Cart</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            {/* <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Minimum Order value
              </Text>
              <TextInput
                style={appConfigStyle.txtFieldStyle}
                placeholder="Enter Minimum Order value "
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(text, 1)}
                value={this.state.minimumOrder}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Maximum Cart Quantity
              </Text>
              <TextInput
                style={appConfigStyle.txtFieldStyle}
                placeholder="Enter Maximum Cart Quantity"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(text, 2)}
                value={this.state.maximumQuantity}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Cart Attributes</Text>
              <TextInput
                style={appConfigStyle.txtFieldStyle}
                placeholder="Enter Cart Attributes"
                placeholderTextColor={colors.placeholderColor}
              />
            </View> */}
            <View style={{flexDirection: 'row', marginTop: -20}}>
              <Switch
                onColor={colors.AppNewGreen}
                onChange={() =>
                  this.setState({statusValue: !this.state.statusValue})
                }
                checked={this.state.statusValue}
                height={22}
                width={50}
              />
              <Text style={appConfigStyle.statusTxtStyle}>
                {
                  'Hide quantity selection (Default: Shows field to select quantity)'
                }
              </Text>
            </View>
            <View style={{flexDirection: 'row', marginTop: 20}}>
              <Switch
                onColor={colors.AppNewGreen}
                onChange={() =>
                  this.setState({enableCoupon: !this.state.enableCoupon})
                }
                checked={this.state.enableCoupon}
                height={22}
                width={50}
              />
              <Text style={appConfigStyle.statusTxtStyle}>
                {'Enable Coupon'}
              </Text>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});
