import uploadImage from '../../../Actions/uploadImage';
import ToastPopup from '../../../HelperClasses/AppLoaderClasses/ToastPopup';
import networkService from '../../../Network/NetworkManager';
const APPURL = require('../../../Constants/URLConstants');

const submitSubscriptionListing = async ({
  form_data,
  setIsLoading,
  onSuccess,
  isEdit,
  id,
}) => {
  try {
    setIsLoading(true);
    let form_data_copy = {...form_data};

    if (form_data?.image_path?.files) {
      const file = form_data?.image_path?.files;
      let img_resp = await uploadImage({
        files: [{name: file?.name, type: file.type}],
        full_files: [file],
      });
      form_data_copy.image_path = img_resp[0];
    } else {
      form_data_copy.image_path = form_data?.image_path?.value;
    }

    //
    const responseJson = isEdit
      ? await networkService.networkCall(
          APPURL.URLPaths.subscription + `/${id}`,
          'put',
          JSON.stringify({subscription_product: form_data_copy}),
        )
      : await networkService.networkCall(
          APPURL.URLPaths.subscription,
          'post',
          JSON.stringify({subscription_product: form_data_copy}),
        );

    setIsLoading(false);
    if (responseJson.status) {
      onSuccess();
    } else {
      ToastPopup({type: 'error', message: 'Something went wrong'});
    }
  } catch (error) {
    setIsLoading(false);
  }
};

export default submitSubscriptionListing;
