import networkService from '../Network/NetworkManager';
import {fetch_all_categories} from '../utils/fetch_all_categories';
const APPURL = require('../Constants/URLConstants');

export const loadAttributesAction = async ({type}) => {
  const responseJson = await networkService.networkCall(
    APPURL.URLPaths.attributes + `?type=${type}`,
    'get',
    '',
    '',
  );

  // console.log('responseJson', responseJson);
  if (responseJson['status'] == true) {
    var listD = responseJson['data']['attributes'];
    return listD;
  } else {
    return [];
  }
};
