import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  TextInput,
  Image,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import DeliveryOrderStatus from '../../Models/DeliveryStatusEnum';
import { AppToast } from '../../HelperClasses/AppToast';
import { Gradient } from 'react-gradient';
import closeIcon from '../../assets/closeIcon.png';
import { addMixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false
let PerPage = 50

export default class DriverCredit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditArray: [],
      loader: false,
      haveData:false,
      stopPage: false,  
      totalRecords: 0,
      showDepositeView:false,
      amount:'',
    }
  }
  static propTypes = {
    driverID: PropTypes.string,
  };
  componentDidMount() {
    this.mixPannelInfo()
    this.initApi()
  }
  componentWillUnmount(){
    clearInterval(this.interval)
  }
  mixPannelInfo(){
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.credit}`,{'page':pageNo})
  }
  initApi(){
    pageNo = 1
    this.loadDriverCreditsApi()
  }
  
  //MARK:-  APIS Method 

  loadDriverCreditsApi = async () => {
    let path = `${APPURL.URLPaths.deliveryDriverCredit}?page=${pageNo}&per_page=${PerPage}&driver_id=${this.props.driverID}`
    const responseJson = await networkService.networkCall(path, 'get');
    this.setState({ creditArray: [] })
    this.setState({loader:true})
    if (responseJson['status'] == true) {
      console.log(JSON.stringify(responseJson))
      var creditD = responseJson['data']['driver_credits']
      this.state.totalRecords = responseJson['data']['total_records'];
      if (creditD.length != 0) {
        this.setState({stopPage: false});
        for (let objc of creditD){
          this.state.creditArray.push(objc)
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.creditArray.length == 0 ? true : false
      this.setState({loader:true})
    } else {
      this.setState({loader:true})
    }
  }
  depositeApi = async()  => {
    let path =  APPURL.URLPaths.deliveryDriverCredit
    let dic = {
      'driver_id': this.props.driverID,
      'amount': this.state.amount,
    }
    const responseJson = await networkService.networkCall(path, 'POST',JSON.stringify({driver_credit:dic}))
    // console.log('responseJson ==>>>>> ', responseJson)
    if (responseJson['status'] == true) {
      this.setState({loader: true, showDepositeView:false})
      this.mixPannelInfoDepositeCash(true)
      AppToast()
      this.loadDriverCreditsApi()
    }else {
      this.mixPannelInfoDepositeCash(false)
      AppToast(responseJson)
    }
    this.setState({loader: true})
  }
  mixPannelInfoDepositeCash(resp){
    addMixpanelEvent(`${MixPannelEnum.credit} Deposite cash`,{'success':resp})
  }
  //MARK:- Button Actions
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadDriverCreditsApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadDriverCreditsApi();
      }
    }
    this.mixPannelInfo()
    this.setState({ updateUI: !this.state.updateUI });
  }
  depositeBtnAction(){
    this.setState({showDepositeView:true})
  }
  submitBtnAction(){
    if (this.state.amount.length != 0) {
      this.setState({loader: false, showDepositeView:false})
      this.depositeApi()
    }
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 9989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  }
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.creditArray.length; i++) {
      let status = DeliveryOrderStatus.depositeStatus(this.state.creditArray[i]['type'])
      let amt = this.state.creditArray[i]['amount'] < 0 ? (-1 * this.state.creditArray[i]['amount']) : this.state.creditArray[i]['amount']
      let amount = `${this.state.creditArray[i]['currency']} ${amt}` 
      let item = this.state.creditArray[i]
      let orderID = !item['order'] ? '-' : item['order']['order_id']
      let delivery_type = !item['order'] ? '-' : DeliveryOrderStatus.deliveryType(item['order']['delivery_type'])

      views.push(
        <FlatList
          data={[
            item['transaction_number'],
            orderID,
            amount,
            status,
            delivery_type,
            item['created_at']
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          keyExtractor={(item, index) => index + 999989}
          key={'C'}
          style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white' }}
        />,
      )
    }
    return views;
  };
  columnCell = ({ item, index }) => {
    return (
      <View style={styles.columnTitleViewStyle}>
        <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
      </View>
    )
  }
 
  EmptyListRender = () => {
    if (this.state.creditArray.length == 0) {
      if (this.state.haveData) {
        return <View style={{ height: '100%' }}>
          <EmptyListUI showImage={false} titleString={'No Credits found'} subtitleString={''} />
        </View>
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  }
  renderListView = () => {
    return <ScrollView horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>
  }
  renderDepositeView = () => {
    return <View style={[tableStyle.passwordViewStyle,{display:this.state.showDepositeView? 'flex' : 'none'}]}>
      <View style={{backgroundColor:colors.AppWhite, height:200, width:300, borderRadius:10}}>
        <TouchableOpacity style={{alignSelf:'flex-end', padding:10}} onPress={() => this.setState({showDepositeView:false})}>
            <Image style={{ width: 20, height: 20}} source={closeIcon} />
          </TouchableOpacity>
        <View style={{alignSelf:'center', margin: 10}}>          
          <View style={{borderRadius:5, padding:10, borderColor:colors.SimonGray, borderWidth:1, marginTop:10}}>
            <TextInput 
              value={this.state.amount}
              onChangeText={text => this.setState({amount: text})}
              placeholder='Enter amount'
              style={{outline:'none'}} /> 
          </View>
        </View>
        <View style={{alignSelf:'center', margin: 10, marginTop:20,paddingLeft:10}}>
          {this.renderSubmitBtn()}
        </View>
      </View>
    </View>
  }
  renderSubmitBtn = () => {
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.submitBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{'Submit'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
      </View>)
    } else {
      return <View />
    }
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        {this.renderDepositeView()}
        <View style={[tableStyle.containerMainView,{height : windowHeight - 170}]}>
          <ToastContainer />
          {this.renderListView()}
          <this.EmptyListRender />
          <this.renderBottomView />
        </View>
        <Appload enable={this.state.loader} titleMessage={this.state.loaderTitle} />
      </SafeAreaView>
    )
  }
}
const commonWidth = 6.2;
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  filterSubItemViewStyle:{
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    paddingBottom: 5,
    alignItems:'center' 
  },

});

const tableHeaderString = [
  'Transaction Number',
  'Order#',
  'Amount',
  'Type',
  'Delivery Type',
  'Date',
]

