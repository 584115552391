/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  ScrollView,
  Text,
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import AppConstants from '../Constants/AppConstants';
import AppLoader from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import AppEmptyList from '../Component/AppEmptyList';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import GithubSlugger, {slug as slugChecker} from 'github-slugger';
import moment from 'moment';
import copyPasteIcon from '../assets/copyPaste.png';
import {Gradient} from 'react-gradient';
import AppConfigStyleSheet from '../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import whiteTick from '../assets/whiteTick.png';

const APPURL = require('../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let itsMobileView = windowWidth < AppConstants.mobileMaxWidth ? true : false;

const ReportedListings = () => {
  const [isFetchingComplete, setIsFetchingComplete] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const [reported_listings_state, setReportedListings] = useState({
    reported_listings: null,
    isFetchingComplete: false,
    isError: false,
    perPage: 30,
    pageNo: 1,
    totalRecords: 0,
  });

  // Destructure of objects
  const {
    reported_listings,

    isError,
    perPage,
    pageNo,
    totalRecords,
  } = reported_listings_state;

  // effect for fetching listings
  useEffect(() => {
    setIsFetchingComplete(false);
    fetch_reported_listings({page: pageNo});
  }, [perPage]);

  // fetch reported listings
  const fetch_reported_listings = async ({page}) => {
    var path = `?page=${page ?? pageNo}&per_page=${perPage}`;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.reportedListings + path,
      'get',
    );

    if (responseJson['status'] == true) {
      setIsFetchingComplete(true);
      setReportedListings(prev => ({
        ...prev,

        reported_listings: responseJson.data?.reported_listings,
        pageNo: responseJson.data?.page,
        totalRecords: responseJson.data?.total_records,
      }));
    }
  };

  // Table Header View strigs and header cell
  const tableHeaderString = [
    'User Name',
    'Reason',
    'Listing Title',
    'Date',
    'URL',
  ];
  const tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={TableCommonStyleSheet.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };

  //columnCell
  const columnCell = ({item, index}) => {
    if (index == 0) {
      return (
        <View style={styles.firstCellViewStyle}>
          <Text style={TableCommonStyleSheet.mainTitleStyle}>{item}</Text>
        </View>
      );
    } else if (index == 4) {
      return (
        <View
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            padding: 10,
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: 'white',
            paddingRight: 40,
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <TouchableOpacity onPress={() => window.open(item)}>
              <Text
                style={
                  (TableCommonStyleSheet.columnViewTitleStyle,
                  {whiteSpace: 'nowrap'})
                }>
                {item}
              </Text>
            </TouchableOpacity>

            <View style={{width: 10}} />
            <TouchableOpacity
              onPress={() => navigator.clipboard.writeText({item})}>
              <Image source={copyPasteIcon} style={{width: 15, height: 15}} />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.firstCellViewStyle}>
          <Text style={TableCommonStyleSheet.ratingDescriptionTextStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };

  //
  const gradientButtons = ({title, isNext, isPrev, page}) => {
    if (isPrev && pageNo == 1) {
      return <View />;
    } else if (isNext && Math.ceil(totalRecords / perPage) === pageNo) {
      return <View />;
    }
    return (
      <View>
        <View style={TableCommonStyleSheet.grandientBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
            angle="0deg">
            <TouchableOpacity
              style={TableCommonStyleSheet.nextPreviousViewStyle}
              onPress={() => {
                setIsFetchingComplete(false);

                if (isNext) {
                  fetch_reported_listings({page});
                }
                if (isPrev) {
                  fetch_reported_listings({page});
                }
              }}>
              <Text style={TableCommonStyleSheet.saveBtnTxtStyle}>{title}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };

  //renderPerView
  const renderPerView = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 20,
        }}>
        <Text>Show rows</Text>
        <View style={{flexDirection: 'column', zIndex: 9999}}>
          <TouchableOpacity
            onPress={() => setShowDropDown(!showDropDown)}
            style={TableCommonStyleSheet.perPagedropDownView}>
            <Text> {perPage} </Text>
            <Image
              style={
                showDropDown == true
                  ? AppConfigStyleSheet.upArraowIconStyle
                  : AppConfigStyleSheet.downArraowIconStyle
              }
              resizeMode="contain"
              source={downArrow_Icon}
            />
          </TouchableOpacity>
          {showDropDown && (
            <View style={TableCommonStyleSheet.perPageContainerViewStyle}>
              <View style={{height: 4}} />
              <FlatList
                data={['30', '50', '100']}
                horizontal={false}
                renderItem={renderDropDown}
                extraData={reported_listings_state}
                showsVerticalScrollIndicator={false}
                scrollEnabled={true}
                keyExtractor={(item, index) => index + 989}
                key={'E'}
              />
            </View>
          )}
        </View>
      </View>
    );
  };

  // renderDropDown
  const renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() =>
          setReportedListings(prev => ({
            ...prev,
            perPage: Number(item),
          }))
        }
        style={{margin: 3}}>
        <View
          style={[
            TableCommonStyleSheet.perPageDropDownViewStyle,
            {
              backgroundColor: item == perPage ? colors.BGBlueColor : '',
            },
          ]}>
          <Image
            source={whiteTick}
            style={{
              height: 16,
              width: item == perPage ? 16 : 0,
            }}
          />
          <Text style={CommonStyleSheet.dropdownCellWhiteTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={styles.Container}>
      {/* Header */}
      <View style={{zIndex: 10}}>
        <View
          style={[
            TableCommonStyleSheet.headerContainer,
            {
              justifyContent: itsMobileView ? 'flex-start' : 'space-between',
              flexDirection: 'row',
            },
          ]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              zIndex: 4,
              marginLeft: 10,
            }}>
            <Text style={TableCommonStyleSheet.titleTextStyle}>
              {'Reported Listings'}
            </Text>
          </View>
        </View>
      </View>
      <View style={{zIndex: 1}}>
        <View style={TableCommonStyleSheet.mainView}>
          {/*Main Body  */}

          <ScrollView
            style={TableCommonStyleSheet.mainScrollViewStyle}
            horizontal={true}>
            <View>
              <View
                style={{
                  height: 40,
                  backgroundColor: colors.AppLightGreen,
                  borderTopStartRadius: 5,
                  borderTopEndRadius: 10,
                  paddingLeft: 10,
                }}>
                <FlatList
                  data={tableHeaderString}
                  horizontal={true}
                  renderItem={tableHeaderCell}
                  // extraData={state}
                  showsVerticalScrollIndicator={false}
                  scrollEnabled={false}
                  style={{marginLeft: 0}}
                  keyExtractor={(item, index) => index + 98999}
                  key={'H'}
                />
              </View>
              <ScrollView style={styles.tableViewHeaderStyle}>
                {reported_listings?.map(item => {
                  //listings Path
                  var listingPathURL = AppConstants.domainKey;
                  if (item?.listing?.slug == 0) {
                    let vslug = slugChecker(item?.listing?.title);
                    listingPathURL =
                      listingPathURL + `/l/${item?.listing?.id}-${vslug}`;
                  } else {
                    listingPathURL =
                      listingPathURL + `/l/${item?.listing?.slug}`;
                  }
                  return (
                    <FlatList
                      data={[
                        `${item?.user?.first_name} ${item?.user?.last_name}`,
                        item?.metadata?.reason,
                        item?.listing?.title,
                        moment(item?.created_at * 1000).format(
                          'DD-MM-YYYY HH:mm:ss',
                        ),
                        listingPathURL,
                      ]}
                      horizontal={true}
                      renderItem={columnCell}
                      extraData={reported_listings}
                      showsVerticalScrollIndicator={false}
                      scrollEnabled={true}
                      style={{
                        borderBottomWidth: 1,
                        borderBottomColor: colors.SimonGray,
                        backgroundColor: 'white',
                        paddingVertical: 10,
                        paddingLeft: 10,
                      }}
                      keyExtractor={(item, index) => index + 9989}
                      key={'C'}
                    />
                  );
                })}
              </ScrollView>

              {(reported_listings === null ||
                reported_listings?.length === 0) && (
                <View style={{height: '70%'}}>
                  <AppEmptyList
                    showImage={true}
                    titleString={
                      AppConstantsMessage.emptyReportedListingTitleString
                    }
                    subtitleString={
                      AppConstantsMessage.emptyReportedListingMsgTextString
                    }
                  />
                </View>
              )}
            </View>
          </ScrollView>
        </View>
        {/*  */}
        <View style={TableCommonStyleSheet.bottomViewStyle}>
          {gradientButtons({
            title: 'Next',
            isNext: true,
            isPrev: false,
            page: pageNo + 1,
          })}
          {gradientButtons({
            title: 'Prev',
            isNext: false,
            isPrev: true,
            page: pageNo - 1,
          })}
          {totalRecords > 30 && renderPerView()}
        </View>
        <AppLoader enable={isFetchingComplete} />
      </View>
    </SafeAreaView>
  );
};

const commonWidth = 5.0;

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  headerViewstyle: {
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 777,
  },
  subViewStyle: {
    backgroundColor: 'white',
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 10,
    borderWidth: 1,
    width: windowWidth < AppConstants.mobileMaxWidth ? '100%' : '20%',
    height: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width:
      windowWidth < AppConstants.mobileMaxWidth
        ? 130
        : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
  },
  columnTitleViewStyle: {
    width:
      windowWidth < AppConstants.mobileMaxWidth
        ? 130
        : windowWidth / commonWidth,
    // justifyContent: 'center',
    // padding: 0,
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  firstCellViewStyle: {
    width:
      windowWidth < AppConstants.mobileMaxWidth
        ? 130
        : windowWidth / commonWidth,
    // padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    // justifyContent: 'center',
  },
});

export default ReportedListings;
