import React, {Component} from 'react';
import {StyleSheet, Dimensions} from 'react-native';
import PropTypes from 'prop-types';
import SellerCommissions from './SellerCommission';

export default class Commisions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddScreen: 0,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  addSaveBtnAction() {
    this.setState({isAddScreen: this.state.isAddScreen == 1 ? 0 : 1});
  }
  render() {
    return (
      <SellerCommissions
        backBtnAction={this.props.backBtn}
        addSaveBtnAction={() => this.addSaveBtnAction()}
      />
    )
  }
}

const styles = StyleSheet.create({
  Container: {
    backgroundColor: 'white',
  },
});
