import React from 'react';

const TextInput = ({
  current_data,
  update_data,
  title,
  note,
  id,
  placeholder,
  type,
  isRequired,
  input_box_style,
  component_style,
}) => {
  return (
    <div className={['space-y-1  w-full ', component_style].join(' ')}>
      {title && (
        <p
          className={[
            ' text-gray-600',
            isRequired &&
              "flex-none relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[12px] ",
          ].join(' ')}>
          {' '}
          {title}
        </p>
      )}
      <input
        id={id}
        key={id}
        value={current_data}
        className={['form-input py-2 w-full', input_box_style].join(' ')}
        type={type ?? 'text'}
        onChange={e => {
          update_data(e.target.value);
        }}
        placeholder={placeholder}
      />
      {note && <p className="text-xs mt-2 italic">{note}</p>}
    </div>
  );
};

export default TextInput;
