import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  TextInput,
  ScrollView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import edit_Icon from './../../assets/editIcon.png';
import close_Icon from './../../assets/closeIcon.png';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import RichTextEditor from 'react-rte';
import {AppToast} from '../../HelperClasses/AppToast';
import networkService from '../../Network/NetworkManager';
import Switch from 'react-switch';
import {addMixpanelEvent, MixpanelEvent} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SidePanView from '../../Component/SidePanView';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');
//'Subscription', 'Subscription Receipt'
const titleArray = [
  'Welcome Message Template',
  'OTP Message Template',
  'Subscription Link Template',
  'Subscription Success Template',
];
const mergeTags = [
  '{first_name} {last_name}',
  '{first_name} {last_name} {code}',
  '{first_name} {last_name} {link}',
  '{first_name} {last_name} {product_name} {amount} {manage_link}',
];

const KeyGroup = 'email';
const keysTitleArray = [
  'welcome_email_title',
  'verification_email_title',
  'subscription_email_subject_template',
  'subscription_success_email_subject_template',
];
const keysBodyArray = [
  'welcome_email_body_template',
  'verification_email_body_template',
  'subscription_email_body_template',
  'subscription_success_email_body_template',
];

const windowHeight = Dimensions.get('window').height;

export default class EmailNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      statusArray: [false, false, false, false],
      updateUI: false,
      bodyValue: '',
      showEditor: this.props.showEditor,
      editorIndex: 0,
      subjectTitle: '',
      dataLoad: false,
      configData: {},
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    onChange: PropTypes.func,
    editEmailNotification: PropTypes.func,
    showEditor: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + KeyGroup,
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      this.state.configData = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateApi = async () => {
    this.setState({dataLoad: false});
    var configs = [];
    if (this.state.bodyValue.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: keysBodyArray[this.state.editorIndex],
        value: this.state.bodyValue,
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.subjectTitle.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: keysTitleArray[this.state.editorIndex],
        value: this.state.subjectTitle,
        secured: false,
      };
      configs.push(fDict);
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.emailNotification, {success: true});
      AppToast();
    } else {
      addMixpanelEvent(MixpanelEvent.emailNotification, {success: false});
      this.setState({dataLoad: true});
    }
  };

  //MARK:-  Buttons Action Method 
  submitButtonAction() {
    if (this.state.showEditor) {
      let bodyValue = this.state.bodyValue;
      if (this.state.subjectTitle.length == 0) {
        AppToast('Subject should not be empty');
      } else if (bodyValue.length == 11) {
        AppToast('body should not be empty');
      } else {
        if (this.state.editorIndex == 1) {
          if (bodyValue.includes('{code}')) {
            this.addOrUpdateApi();
          } else {
            AppToast('{code} merge tag is mandatory');
          }
        } else {
          this.addOrUpdateApi();
        }
      }
    }
  }
  switchSattusBtnAction(id) {
    this.setState({switchValue: !this.state.switchValue});
    this.state.statusArray[id] = !this.state.statusArray[id];
  }
  editBtnAction(id) {
    if (id == 1) {
      this.state.subjectTitle =
        this.state.configData['verification_email_title'] || '';
      this.state.bodyValue =
        this.state.configData['verification_email_body_template'] || '';
    } else if (id == 0) {
      this.state.subjectTitle =
        this.state.configData['welcome_email_title'] || '';
      this.state.bodyValue =
        this.state.configData['welcome_email_body_template'] || '';
    } else if (id == 2) {
      this.state.subjectTitle =
        this.state.configData['subscription_email_subject_template'] || '';
      this.state.bodyValue =
        this.state.configData['subscription_email_body_template'] || '';
    } else if (id == 3) {
      this.state.subjectTitle =
        this.state.configData['subscription_success_email_subject_template'] ||
        '';
      this.state.bodyValue =
        this.state.configData['subscription_success_email_body_template'] || '';
    }
    // this.setState({showEditor: !this.state.switchValue,editorIndex: id})
    let dict = {edit: true, id: id};
    this.props.editEmailNotification(dict);
  }

  renderSettingView = () => {
    var view = [];
    for (let a = 0; a <= titleArray.length - 1; a++) {
      view.push(
        <View style={styles.settingViewsStyle}>
          <View style={styles.switchBtnViewStyle}>
            {/* <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.switchSattusBtnAction(a)}
              checked={this.state.statusArray[a]}
              height={22}
              width={50}
            /> */}
            <View />
            <TouchableOpacity onPress={() => this.editBtnAction(a)}>
              <Image
                style={SettingsStyleSheet.subMenuIconStyle}
                resizeMode="contain"
                source={edit_Icon}
              />
            </TouchableOpacity>
          </View>
          <View style={{flex: 1, backgroundColor: '#f8f9fb'}}> </View>
          <View style={styles.bottomViewStyle}>
            <Text style={tableStyle.applyTxtStyle}>{titleArray[a]}</Text>
          </View>
        </View>,
      );
    }
    // view.push(
    //   <View style={styles.settingViewsStyle}>
    //     <View style={{height: 40, padding: 10}}>
    //       <Switch
    //         trackColor={{
    //           false: colors.SwitchColor,
    //           true: colors.SwitchColor,
    //         }}
    //         thumbColor={
    //           this.state.statusArray[2] ? colors.AppNewGreen : colors.SimonGray
    //         }
    //         ios_backgroundColor={colors.SimonGray}
    //         onValueChange={() => this.switchSattusBtnAction(2)}
    //         value={this.state.statusArray[2]}
    //       />
    //     </View>
    //     <View style={{flex: 1}}>
    //       <View style={styles.emptySmallViewStyle}></View>
    //       <View style={{flex: 1, marginTop: 10}}>
    //         <View style={styles.emptyLinesViewStyle}></View>
    //         <View style={styles.emptyLinesViewStyle}></View>
    //         <View style={styles.halfLinesViewStyle}></View>
    //       </View>
    //       <View style={styles.fullLinesViewStyle}></View>
    //       <View style={{height: 10}}></View>
    //     </View>
    //     <View style={styles.bottomViewStyle}>
    //       <Text style={styles.listTitleText}>Order Receipt</Text>
    //     </View>
    //   </View>,
    // );

    return view;
  };
  renderModelView = () => {
    if (this.state.showEditor) {
      return (
        <View style={styles.modalViewStyle}>
          <View>
            <View
              style={{justifyContent: 'space-between', flexDirection: 'row'}}>
              <Text style={CommonStyleSheet.editorViewTitleStyle}>
                Edit {titleArray[this.state.editorIndex]}
              </Text>
              <TouchableOpacity
                style={{margin: 10}}
                onPress={() =>
                  this.setState({showEditor: !this.state.showEditor})
                }>
                <Image
                  style={SettingsStyleSheet.subMenuIconStyle}
                  resizeMode="contain"
                  source={close_Icon}
                />
              </TouchableOpacity>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Subject</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                value={this.state.subjectTitle}
                placeholder="Enter the Subject"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={subject => this.setState({subjectTitle: subject})}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Available merge tags:
                <Text style={CommonStyleSheet.plainSubTextStyle}>
                  {`  `}
                  {mergeTags[this.state.editorIndex]}
                </Text>
              </Text>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Body</Text>
              <View style={{height: 20}} />
              <TextInput
                style={[
                  appConfigStyle.descriptionTxtFieldStyle,
                  {outline: 0, height: windowHeight / 3},
                ]}
                placeholder="Write here..."
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({bodyValue: name})}
                value={this.state.bodyValue}
                multiline={true}
              />
              {/* <RichTextEditor
                value={this.state.value}
                onChange={value => this.onChange(value)}
                className={{height: 100}}
                placeholder={'Write here...'}
              /> */}
            </View>
            {/* <View style={{marginTop: 20, flexDirection: 'row-reverse'}}>
              <TouchableOpacity
                style={styles.submitViewBtnStyle}
                onPress={() => this.submitButtonAction()}>
                <Text style={styles.submitBtnStyle}>{'Submit'}</Text>
              </TouchableOpacity>
              <View style={{height: 0, width: 20}} />
              <TouchableOpacity style={styles.resetViewBtnStyle}>
                <Text style={styles.resetBtnStyle}>{'Reset'}</Text>
              </TouchableOpacity>
            </View> */}
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };

  render() {
    if (this.state.showEditor) {
      return (
        <View style={{width: '90%', marginLeft: 10}}>
          <SidePanView
            dissmissView={() =>
              this.setState({showEditor: !this.state.showEditor})
            }
            showSidepanView={this.state.showEditor}
            customView={
              <View>
                <this.renderModelView />
              </View>
            }
            title={'Import'}
          />
          {/* <this.renderModelView />
        <Appload enable={this.state.dataLoad} /> */}
        </View>
      );
    } else {
      return (
        <View style={{width: '90%', marginLeft: 10}}>
          <Text style={tableStyle.listTitleText}>Email Notification</Text>
          <Text style={appConfigStyle.subTitleStyle}>
            {AppConstants.plainTextString}
          </Text>
          <View style={appConfigStyle.horizontalLineViewStyle} />
          <View style={appConfigStyle.subViewStyle}>
            <View style={{flexDirection: 'column', width: '105%', padding: 10}}>
              <ScrollView>
                <View
                  style={{
                    marginTop: -20,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '105%',
                  }}>
                  <this.renderSettingView />
                </View>
              </ScrollView>
            </View>
          </View>
          <Appload enable={this.state.dataLoad} />
        </View>
      );
    }
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  settingViewsStyle: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 220,
    width: 180,
    // alignItems: 'center',
    justifyContent: 'space-between',
  },
  switchBtnViewStyle: {
    height: 40,
    width: '100%',
    backgroundColor: colors.AppLightGreen,
    flexDirection: 'row',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    alignItems: 'center',
    padding: 10,
    justifyContent: 'space-between',
  },
  bottomViewStyle: {
    height: 40,
    width: '100%',
    backgroundColor: colors.AppLightGreen,
    flexDirection: 'row',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  emptySmallViewStyle: {
    width: 90,
    height: 30,
    backgroundColor: colors.AppLightGreen,
    alignSelf: 'center',
  },
  emptyLinesViewStyle: {
    width: '80%',
    height: 5,
    backgroundColor: colors.AppLightGreen,
    alignSelf: 'center',
    marginTop: 5,
  },
  halfLinesViewStyle: {
    width: '40%',
    height: 5,
    backgroundColor: colors.AppLightGreen,
    alignSelf: 'flex-start',
    marginTop: 5,
    marginLeft: 18,
  },
  fullLinesViewStyle: {
    width: '80%',
    height: 30,
    backgroundColor: colors.AppLightGreen,
    alignSelf: 'center',
  },
  modalViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderColor: colors.SimonGray,
    borderWidth: 1,
    margin: 10,
    borderRadius: 10,
    padding: 10,
    width: '109%',
  },
  submitViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    backgroundColor: colors.Appgreen,
    alignSelf: 'center',
    width: 80,
  },
  resetViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignSelf: 'center',
    width: 80,
  },
});
