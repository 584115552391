import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions,
  SafeAreaView,
  Image,
  Animated,
  Easing,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import checked_Icon from '../assets/checkedIcon.png';
import unchecked_Icon from '../assets/uncheckedIcon.png';
import deleteIcon from '../assets/deleteGrayIcon.png';
import radioCheckIcon from '../assets/radioCheck.png';
import radioUncheckIcon from '../assets/radioUncheck.png';
import Header_View from './CollectionHeaderView';
import PropTypes from 'prop-types';
import appConfigStyle from './../StyleSheets/AppConfigStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import searchIcon from '../assets/search.png';
import close_Icon from '../assets/closeIcon.png';
import {AppToast} from './../HelperClasses/AppToast';
import DatePicker from 'react-custom-date-picker';
import Moment from 'moment';
import SelectSearchView from 'react-select';
import AppConstants from '../Constants/AppConstants';
import DescriptionView from '../Component/DescriptionView';
import Switch from 'react-switch';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import {p1, p2} from '../StyleSheets/TextTitleStyleSheet';
import SettingStyleSheet from '../StyleSheets/SettingStyleSheet';
import closeIcon from '../assets/closeBlackIcon.png';
import AccountDetail from '../Accounts/AccountDetail';
import ListingDetail from '../MainListing/ListingDetail';
import SidePanView from '../Component/SidePanView';
import ReactFileReader from 'react-file-reader';
import AppConfigStyleSheet from './../StyleSheets/AppConfigStyleSheet';
import jsonIcon from '../assets/jsonIcon.png';
import pdfIcon from '../assets/pdfICon.png';

import GithubSlugger, {slug as slugChecker} from 'github-slugger';
import {withRouter} from '../wrappers/withRouter';
import {ToastContainer} from 'react-toastify';

const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let watchAnimationData = require('./../cat.json');

let accountingID = 1;
let listingID = 4;
var listingScopeTitleArray = [];
let listingKeysArray = [];

let dateFormat = 'yyyy-MM-DD';
var dt = new Date();
let currentDate = Moment(dt).format(dateFormat);

let itsMobileView = windowWidth < fixedWidth ? true : false;
const configViewRightMargin = -420;

class CreateCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existingCategoryIDs: [],
      UpdateUI: false,
      showDropDown: false,
      showCommonDropDown: false,
      showSearchDropDown: false,
      showCategoryDropDown: false,
      selectedScopeTypeId: AppConstants.appType ? 4 : 1,
      selectedConditionTypeId: 1,
      selectedCategoryType: '',
      currencyArray: [],
      webEnable: true,
      iosEnable: true,
      androidEnable: true,
      conditionType: false,
      listingsArray: [],
      selectedSearchArray: [],
      selectedCategoryItemsArray: [],
      categoriesArray: [],
      accountsArray: [],
      titleString: '',
      collectionSlug: '',
      descriptionString: '',
      statusSwitch: true,
      dataLoad: true,
      startDate: currentDate,
      endDate: currentDate,
      updateUI: false,
      automatedScopeArray: [],
      searchBtnHover: false,
      rightPosition: new Animated.Value(configViewRightMargin),
      showSideView: false,
      searchKey: '',
      showAccountDetail: false,
      showListingDetail: false,
      selectedListingAccountID: '',
      expirySwitch: false,
      selectedImage: null,
      preview_path: undefined,
    };
    this.conditonTypesViewRender = this.conditonTypesViewRender.bind(this);
    this.searchDropDownRender = this.searchDropDownRender.bind(this);
    this.renderSearchDropDown = this.renderSearchDropDown.bind(this);
    // this.didSelectSearchDropDown = this.didSelectSearchDropDown.bind(this);
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    collectionID: PropTypes.string,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    listingScopeTitleArray = AppConstants.appType
      ? ['Listing Category', 'Tags Category']
      : ['Listing Category', 'Account Category', 'Tags Category'];
    listingKeysArray = AppConstants.appType
      ? ['category_id', 'tags']
      : ['category_id', 'account_category_id', 'tags'];
    if (AppConstants.appType) {
      this.loadCategoriesApi('listings');
    } else {
      // this.loadCategoriesApi('listings')
      this.loadCategoriesApi('accounts');
    }
  }
  //MARK:-  APIS 
  loadCategoriesApi = async parameter => {
    var path = APPURL.URLPaths.categories + parameter;
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(path, 'get');
    this.setState({dataLoad: true});
    this.state.categoriesArray = [];
    if (responseJson.status == true) {
      // var categoryD = responseJson['data']['categories'];
      var listD = responseJson.data.categories;
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i].name);
        let hierarchyD = listD[i].sub_category;
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(
              hierarchyD[j],
              `${listD[i].name}-> ${hierarchyD[j].name}`,
            );
            let hierarchy2 = hierarchyD[j].sub_category;
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(
                  hierarchy2[k],
                  `${hierarchyD[j].name}--> ${hierarchy2[k].name}`,
                );
                let hierarchy3 = hierarchy2[k].sub_category;
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(
                      hierarchy3[l],
                      `${hierarchy2[k].name}---> ${hierarchy3[l].name}`,
                    );
                  }
                }
              }
            }
          }
        }
      }
      if (this.props.isEdit) {
        var SArray = [];
        for (let b = 0; b < this.state.existingCategoryIDs.length; b++) {
          let cObj = this.state.existingCategoryIDs[b];
          var result = this.state.categoriesArray.find(obj => {
            return obj.value === cObj;
          });
          SArray.push(result);
        }
        this.state.selectedCategoryItemsArray = SArray;
      }
      if (this.props.isEdit) {
        this.getCollectionDetailAPI();
        if (
          this.state.categoriesArray.length != 0 &&
          this.state.accountsArray.length != 0
        ) {
          this.setState({dataLoad: false});
          this.getCollectionDetailAPI();
        } else {
          if (AppConstants.appType) {
            if (this.state.categoriesArray.length != 0) {
              this.setState({dataLoad: false});
              this.getCollectionDetailAPI();
            }
          }
        }
      }
    }
  };
  manageData(data) {
    var deleteEnable = true;
    if (data.sub_category) {
      deleteEnable = data.sub_category.length == 0 ? true : false;
      if (data.sub_category.length !== 0) {
        deleteEnable = data.parent == 0 ? false : true;
      }
    }
    let hierarchyAry = data.hierarchy;
    var hierarchyName = '';
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a].name}`;
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> ';
        }
      }
    }
    let catData = {
      id: data.id,
      parent: data.parent,
      name: data.name,
      active: data.active,
      image_path: data.image_path,
      delete: deleteEnable,
      subCategory: data.name,
      order_by: data.order_by,
      hierarchyName: hierarchyName,
      label: hierarchyName,
      value: data.id,
    };
    this.state.categoriesArray.push(catData);
  }
  getCollectionDetailAPI = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.collections + `/${this.props.params.collectionID}`,
      'get',
      '',
      '',
    );
    if (responseJson.status == true) {
      var collectionD = responseJson.data.collection;
      console.log('collectionD', collectionD);
      this.setState({
        conditionType: collectionD.condition_type == 2 ? false : true,
        selectedScopeTypeId: collectionD.scope_type,
        titleString: collectionD.title || '',
        collectionSlug: collectionD.slug ?? '',
        descriptionString: collectionD.description || '',
        selectedImage:
          collectionD.images?.length > 0 ? collectionD.images[0] : null,
        preview_path: `${AppConstants.domainKey}/${
          collectionD.scope_type === 1 ? 'a' : 'l'
        }/collection/tdc-${collectionD.id}`,
      });
      this.state.expirySwitch = collectionD.expirable;
      if (collectionD.start_at) {
        let sdtm = Moment(collectionD.start_at).format(dateFormat);
        let edtm = Moment(collectionD.end_at).format(dateFormat);
        this.setState({endDate: new Date(edtm)});
        this.setState({startDate: new Date(sdtm)});
      }

      for (let itm of collectionD.medium) {
        if (itm == 1) {
          this.setState({iosEnable: true});
        }
        if (itm == 2) {
          this.setState({androidEnable: true});
        }
        if (itm == 3) {
          this.setState({webEnable: true});
        }
      }

      if (!this.state.conditionType) {
        this.getManualDataAPI();
      } else {
        this.setState({dataLoad: true});
        // console.log('this.state.selectedScopeTypeId', this.state.selectedScopeTypeId)
        if (this.state.selectedScopeTypeId == listingID) {
          let conditions = collectionD.conditions;
          var keys = Object.keys(conditions);
          var values = Object.values(conditions);
          for (let c = 0; c <= keys.length - 1; c++) {
            var conditionTitle = keys[c];
            var keyCat = Object.keys(values[c]);
            var valueCat = Object.values(values[c]);
            // console.log('valueCat', valueCat)
            for (let a = 0; a <= keyCat.length - 1; a++) {
              var catAry = [];
              var category = '';
              // console.log('keyCat[a] == listingKeysArray[0]', keyCat[a],a,listingKeysArray[0])
              if (keyCat[a] == listingKeysArray[0]) {
                category = listingScopeTitleArray[0];
                let SArray = valueCat[a];
                // console.log('SArray', SArray)
                for (let b = 0; b < SArray.length; b++) {
                  let cObj = SArray[b];
                  var result = this.state.categoriesArray.find(obj => {
                    return obj.value === cObj;
                  });
                  catAry.push(result);
                  // console.log('result ==>', result)
                }
              }
              // console.log('keyCat[a] == listingKeysArray[0]', keyCat[a],a,listingKeysArray[1])
              if (keyCat[a] == listingKeysArray[1]) {
                category = listingScopeTitleArray[1];
                let SArray = valueCat[a];
                // console.log('accountsArray SArray', SArray)
                for (let b = 0; b < SArray.length; b++) {
                  let cObj = SArray[b];
                  var result = this.state.accountsArray.find(obj => {
                    return obj.value === cObj;
                  });
                  if (result == undefined) {
                    var result2 = this.state.categoriesArray.find(obj => {
                      return obj.value === cObj;
                    });
                    catAry.push(result2);
                  } else {
                    catAry.push(result);
                  }
                }
              }
              if (a < keyCat.length) {
                let dict = {
                  showCondition: false,
                  showCategory: false,
                  category: category,
                  item: catAry,
                  id: this.state.automatedScopeArray.length,
                  condition: conditionTitle,
                };
                this.state.automatedScopeArray.push(dict);
              }
            }
            // console.log('automatedScopeArray', this.state.automatedScopeArray)
          }
          this.setState({updateUI: !this.state.updateUI});
        } else {
          let conditions = collectionD.conditions;
          var keys = Object.keys(conditions);
          this.state.selectedConditionTypeId = keys[0] == 'OR' ? 2 : 1;
          let catIDsAry = conditions[keys[0]].category_id;
          // console.log('catIDsAry', catIDsAry);
          this.state.existingCategoryIDs = catIDsAry;
          var SArray = [];
          for (let b = 0; b < catIDsAry.length; b++) {
            let cObj = catIDsAry[b];
            var result = this.state.categoriesArray.find(obj => {
              return obj.value === cObj;
            });
            SArray.push(result);
          }
          this.state.selectedCategoryItemsArray = SArray;
          let dict = {
            showCondition: false,
            showCategory: false,
            category: 'Category',
            item: SArray,
            id: this.state.automatedScopeArray.length,
            condition: keys[0],
          };
          this.state.automatedScopeArray.push(dict);
          this.setState({updateUI: !this.state.updateUI});
        }
        this.setState({dataLoad: true});
      }
    }
    console.log('titleString', this.state.titleString);
    this.setState({dataLoad: true});
  };
  getManualDataAPI = async () => {
    // this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accountListings +
        `1&type=accounts&collection_id=tdc-${this.props.params.collectionID}`,
      'get',
      '',
      '',
    );
    if (responseJson.status == true) {
      var colletionD = responseJson.data.accounts;
      for (let Objc of colletionD) {
        this.state.selectedSearchArray.push(Objc);
      }
    }
    this.setState({dataLoad: true});
  };

  loadListingsApi = async key => {
    this.setState({dataLoad: false});
    var path =
      this.state.selectedScopeTypeId == listingID
        ? APPURL.URLPaths.listings + `1&search_key=${key}`
        : this.state.selectedScopeTypeId == 5
        ? APPURL.URLPaths.listings + `1&search_key=${key}`
        : APPURL.URLPaths.accountListings + `1&search_key=${key}&type=accounts`;
    const responseJson = await networkService.networkCall(path, 'get');
    if (responseJson.status == true) {
      this.setState({listingsArray: []});
      var listD =
        this.state.selectedScopeTypeId == listingID
          ? responseJson.data.listings
          : this.state.selectedScopeTypeId == 5
          ? responseJson.data.listings
          : responseJson.data.accounts;
      if (listD.length != 0) {
        this.setState({stopPage: false});
        for (let i = 0; i < listD.length; i++) {
          this.state.listingsArray.push(listD[i]);
        }
        this.setState({
          showSearchDropDown: this.state.searchKey.length != 0 ? true : false,
        });
      } else {
        this.setState({showSearchDropDown: false});
      }
    }
    this.setState({dataLoad: true});
  };
  addCollectionApi = async image => {
    //
    var dictParm = {
      title: this.state.titleString || '',
      scope_type: this.state.selectedScopeTypeId,
      active: this.state.statusSwitch,
      condition_type: this.state.conditionType == false ? 2 : 1,
      slug: slugChecker(this.state.collectionSlug || this.state.titleString),
    };
    if (image?.length > 0) {
      dictParm.images = [image];
    }
    var manualidsAry = [];
    if (this.state.descriptionString.length != 0) {
      dictParm.description = this.state.descriptionString;
    }
    if (!this.state.conditionType) {
      for (let Objc of this.state.selectedSearchArray) {
        manualidsAry.push(Objc.id);
      }
      dictParm.manual_ids = manualidsAry;
    } else {
      if (this.state.selectedScopeTypeId == listingID) {
        var listsANDArray = [];
        var listsORArray = [];
        var condit = '';
        for (let accData of this.state.automatedScopeArray) {
          condit = accData.condition;
          let itemAray = accData.item.includes(undefined) ? [] : accData.item;
          var catId = [];

          if (accData.item ?? []) {
            for (let objc of itemAray) {
              if (objc.value) {
                catId.push(objc.value);
              }
            }
          }
          var keyStr = '';
          if (accData.category == listingScopeTitleArray[0]) {
            keyStr = listingKeysArray[0];
          } else if (accData.category == listingScopeTitleArray[1]) {
            keyStr = listingKeysArray[1];
          } else {
            keyStr = listingKeysArray[2];
          }
          let dictKey = {[keyStr]: catId};
          if (condit == 'AND') {
            listsANDArray.push(dictKey);
          } else {
            listsORArray.push(dictKey);
          }
        }
        var finalDic = {};
        if (listsANDArray.length != 0) {
          var ANDDict = {};
          for (let a = 0; a < listsANDArray.length; a++) {
            var keys = Object.keys(listsANDArray[a]);
            var values = Object.values(listsANDArray[a]);
            ANDDict[keys[0]] = values[0];
          }
          finalDic.AND = ANDDict;
        }
        if (listsORArray.length != 0) {
          var ORDict = {};
          for (let a = 0; a < listsORArray.length; a++) {
            var keys = Object.keys(listsORArray[a]);
            var values = Object.values(listsORArray[a]);
            ORDict[keys[0]] = values[0];
          }
          finalDic.OR = ORDict;
        }
        // console.log('finalDic', finalDic);
        dictParm.conditions = finalDic;
      } else {
        if (this.state.automatedScopeArray.length != 0) {
          let accData = this.state.automatedScopeArray[0];
          let itemAray = accData.item;
          if (itemAray.lenght != 0) {
            var catId = [];
            for (let objc of itemAray) {
              if (objc != undefined) {
                if (objc.value != undefined) {
                  if (objc.value) {
                    catId.push(objc.value);
                  }
                }
              }
            }
            // console.log('car =', catId);
          }
          var condit = accData.condition;
          let catDIc = {category_id: catId};
          dictParm.conditions = {[condit]: catDIc};
        }
      }
    }

    var mediumAry = [];
    if (this.state.iosEnable) {
      mediumAry.push(1);
    }
    if (this.state.androidEnable) {
      mediumAry.push(2);
    }
    if (this.state.webEnable) {
      mediumAry.push(3);
    }
    dictParm.medium = mediumAry;
    dictParm.expirable = this.state.expirySwitch;
    if (this.state.expirySwitch) {
      let frmt = 'yyyy-MM-DDTHH:MM:SS';
      dictParm.start_at = `${Moment(this.state.startDate).format(frmt)}Z`;
      dictParm.end_at = `${Moment(this.state.endDate).format(frmt)}Z`;
    }
    this.setState({dataLoad: false});
    var path = APPURL.URLPaths.collections;
    if (this.props.isEdit) {
      path = APPURL.URLPaths.collections + '/' + this.props.params.collectionID;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.props.isEdit ? 'put' : 'post',
      JSON.stringify({collection: dictParm}),
    );
    this.setState({dataLoad: true});
    if (responseJson.status == true) {
      this.mixpanelInfo(true);
      window?.FM?.trackCustomEvent('Payment', {
        email: AppConstants.userEmail,
        collection: dictParm,
      });

      AppToast();
      this.props.navigate(-1);
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event = this.props.isEdit
      ? MixpanelEvent.editCollection
      : `${MixpanelEvent.addCollection}`;
    addMixpanelEvent(event, properties);
  }

  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage.fileList[0].name.replace(/ /g, ''),
          type: this.state.selectedImage.fileList[0].type,
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      this.setState({dataLoad: false});
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
      );
      if (responseJson.status == true) {
        var result = responseJson.data.result;
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0].signedUrl,
            imgParm[0].type,
            await res.blob(),
          );
          this.addCollectionApi(result[0].fileUri);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.addCollectionApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };

  //MARK:-  Buttons 
  saveBtnAction() {
    if (this.state.titleString.length == 0) {
      AppToast('Title should not be empty');
    } else if (
      this.state.iosEnable == false &&
      this.state.androidEnable == false &&
      this.state.webEnable == false
    ) {
      AppToast('Select atleast one platfrom');
    } else if (this.state.startDate > this.state.endDate) {
      AppToast('End Date must be greater then start date');
    } else if (
      !this.state.conditionType &&
      this.state.selectedSearchArray?.length == 0 &&
      (this.state.selectedScopeTypeId === 1 ||
        this.state.selectedScopeTypeId === 4)
    ) {
      AppToast(
        `Select minimum one ${
          this.state.selectedScopeTypeId === 1 ? 'account' : 'listing'
        }`,
      );
    } else {
      this.uploadImageAPI();
    }
  }
  showScopeDropDown() {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectDropDown = index => {
    this.state.selectedSearchArray = [];
    if (AppConstants.appType) {
      this.setState({
        showDropDown: false,
        selectedScopeTypeId: index == 0 ? 4 : 5,
      });
    } else {
      this.setState({
        showDropDown: false,
        selectedScopeTypeId: index == 0 ? 1 : index == 1 ? 4 : 5,
      });
    } //AppConstants.appType ? 4 : 1,

    this.setState({conditionType: false});
    this.state.selectedCategoryType = 'Category';
    let type = index == 0 ? 'accounts' : 'listings';
    this.loadCategoriesApi(type);
  };
  didSelectSearchDropDown = index => {
    let objct = this.state.listingsArray[index];
    if (this.state.selectedSearchArray.includes(objct)) {
    } else {
      this.state.selectedSearchArray.push(objct);
    }
    // console.log('selectedSearchArray', this.state.selectedSearchArray);
    this.setState({UpdateUI: !this.state.UpdateUI});
  };
  deleteSelectItem = index => {
    this.state.selectedSearchArray.splice(index, 1);
    this.setState({UpdateUI: !this.state.UpdateUI});
  };
  handleChange(date, id) {
    let dtm = Moment(date).format(dateFormat);
    if (id == 1) {
      this.setState({startDate: dtm});
    } else {
      this.setState({endDate: dtm});
    }
  }
  switchStatusBtnAction(id) {
    if (id == 1) {
      this.setState({statusSwitch: !this.state.statusSwitch});
    } else {
      this.setState({expirySwitch: !this.state.expirySwitch});
    }
  }
  showConditionDropDownView(id) {
    var dict = this.state.automatedScopeArray[id];
    // console.log('id', id, dict);
    dict.showCondition = !dict.showCondition;
    this.state.automatedScopeArray[id] = dict;
    this.setState({updateUI: !this.state.updateUI});
  }
  showCategoryDropDownView(id) {
    var dict = this.state.automatedScopeArray[id];
    // console.log('id', id, dict);
    dict.showCategory = !dict.showCategory;
    this.state.automatedScopeArray[id] = dict;
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectConditionDropDown = item => {
    let id = item.id;
    var dict = this.state.automatedScopeArray[id];
    dict.showCondition = !dict.showCondition;
    dict.condition = item.name;
    this.state.automatedScopeArray[id] = dict;
    this.setState({updateUI: !this.state.updateUI});
  };
  didSelectCategoryDropDown = item => {
    let id = item.id;
    var dict = this.state.automatedScopeArray[id];
    var result = this.state.automatedScopeArray.find(obj => {
      return obj.category === item.name;
    });
    // console.log('result', result);
    dict.showCategory = !dict.showCategory;
    dict.category = item.name;
    this.state.automatedScopeArray[id] = dict;
    this.state.selectedCategoryType = item.name;
    this.setState({selectedCategoryType: item.name});
    this.setState({updateUI: !this.state.updateUI});
    // console.log('po', item['name']);
    // console.log('rd', this.state.selectedCategoryType);
    // this.loadCategoriesApi();
  };
  handleSelectChange = (selectedOption, index) => {
    // console.log('selectedOption =>', selectedOption, index);
    let dict = this.state.automatedScopeArray[index];
    // console.log('dict =>', dict);
    dict.item = selectedOption;
    this.state.automatedScopeArray[index] = dict;
    // this.state.selectedCategoryItemsArray = selectedOption;
    this.setState({updateUI: !this.state.updateUI});
    // console.log('cc =>', this.state.selectedCategoryItemsArray);
  };
  conditionTypeBtnAction() {
    this.setState({conditionType: !this.state.conditionType});
    this.state.selectedCategoryType =
      this.state.selectedScopeTypeId == listingID
        ? 'Listing Category'
        : 'Category';
    if (!this.state.conditionType) {
      this.state.automatedScopeArray = [];
      this.addAnotherConditionBtnAction();
    }
    // this.loadCategoriesApi();
  }
  addAnotherConditionBtnAction() {
    if (this.state.automatedScopeArray.length != 0) {
      let count = this.state.automatedScopeArray.length;
      let dict = this.state.automatedScopeArray[count - 1];
      let cat =
        dict.category == 'Category' ? 'Listing Category' : 'Accounts Category';
    }
    let dict = {
      showCondition: false,
      showCategory: false,
      category: this.state.selectedCategoryType,
      item: [],
      id: this.state.automatedScopeArray.length,
      condition: 'AND',
    };
    this.state.automatedScopeArray.push(dict);
    this.setState({updateUI: !this.state.updateUI});
  }
  deleteAutomatedListBtnAction(id) {
    this.state.automatedScopeArray.splice(id, 1);
    this.setState({UpdateUI: !this.state.UpdateUI});
  }
  stopAnimation = () => {
    this.setState({showLoader: true, showSideView: false, searchKey: ''});
    Animated.timing(this.state.rightPosition, {
      toValue: configViewRightMargin,
      duration: 300,
      easing: Easing.linear,
    }).start();
  };
  mooveRL() {
    Animated.timing(this.state.rightPosition, {
      toValue: 0,
      duration: 300,
      easing: Easing.linear,
    }).start();
  }
  didSelectAccountListing(id) {
    this.state.selectedListingAccountID = id;
    if (this.state.selectedScopeTypeId == listingID) {
      this.props.navigate(`/listing/${this.state.selectedListingAccountID}`);
    } else {
      this.props.navigate(`/account/${this.state.selectedListingAccountID}`);
    }
  }
  closeSearchList() {
    this.state.searchKey = '';
    this.setState({searchKey: '', showSearchDropDown: false});
  }

  onPick = image => {
    this.setState({selectedImage: image});
  };
  //MARK:-  UIs 
  searchKeyText(key) {
    this.state.searchKey = key;
    if (key.length != 0) {
      this.loadListingsApi(key);
    } else {
      this.setState({showSearchDropDown: false});
    }
  }
  txtFldEndEditing() {
    this.setState({showSearchDropDown: false});
  }
  renderSidePanView = () => {
    // var views = []
    // var showSideView = false
    // var title = ''
    // if (this.state.showSideView == true) {
    //   showSideView = true
    //   title = 'Search '
    //   this.mooveRL()
    //   views.push(<View>
    //     {this.renderSearchView()}
    //   </View>)
    // }
    // return <View style={[SettingStyleSheet.blackContainerViewStyle, { display: showSideView ? 'flex' : 'none' }]}>
    //   <TouchableOpacity style={SettingStyleSheet.transparentBtnViewStyle} onPress={() => this.stopAnimation()} />
    //   <View>
    //     <Animated.View style={[CommonStyleSheet.animationViewStyle, { right: this.state.rightPosition }]}>
    //       <Appload enable={this.state.showLoader} />
    //       <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
    //         <Text style={CommonStyleSheet.plainTextStyle}>{title}</Text>
    //         <TouchableOpacity onPress={() => this.stopAnimation()}>
    //           <Image source={closeIcon} style={SettingStyleSheet.closeBtnStyle} />
    //         </TouchableOpacity>
    //       </View>
    //       {views}
    //     </Animated.View>
    //   </View>
    // </View>

    if (this.state.showSideView) {
      return (
        <SidePanView
          dissmissView={() =>
            this.setState({showSideView: !this.state.showSideView})
          }
          showSidepanView={this.state.showSideView}
          customView={<View>{this.renderSearchView()}</View>}
          title={'Search'}
        />
      );
    } else {
      return <View />;
    }
  };
  renderSearchView = () => {
    return (
      <View style={{marginTop: 20, width: '90%'}}>
        <View style={{zIndex: 10}}>
          <this.searchViewRender />
        </View>
        <View style={{zIndex: 1, height: windowHeight / 1.3}}>
          <this.renderSelectedItem />
        </View>
      </View>
    );
  };
  scopeDropDownRender = props => {
    if (this.props.isEdit) {
      return <View />;
    } else {
      let ary = AppConstants.appType ? ['Listings'] : ['Accounts', 'Listings'];
      if (this.state.showDropDown == true) {
        return (
          <View style={styles.dropDownViewStyle}>
            <FlatList
              data={ary}
              horizontal={false}
              renderItem={this.renderDropDown}
              extraData={this.state}
              showsVerticalScrollIndicator={false}
              scrollEnabled={true}
            />
          </View>
        );
      }
      return <View></View>;
    }
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(index)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[CommonStyleSheet.plainSubTextStyle, {textAlign: 'left'}]}>
            {' '}
            {item}{' '}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  searchDropDownRender = props => {
    if (this.state.showSearchDropDown == true) {
      return (
        <View style={styles.searchDropDownViewStyle}>
          <FlatList
            data={this.state.listingsArray}
            horizontal={false}
            renderItem={this.renderSearchDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View />;
  };
  renderSearchDropDown = ({item, index}) => {
    let idn = this.state.selectedSearchArray.findIndex(x => x.id == item.id);
    let textcolor = idn == -1 ? colors.AppTitleBlack : colors.AppRed;
    return (
      <TouchableOpacity
        onPress={() => this.didSelectSearchDropDown(index)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[CommonStyleSheet.plainSubTextStyle, {color: textcolor}]}>
            {item.title || item.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  conditionsDropDownRender = id => {
    let dict = this.state.automatedScopeArray[id.id];
    let showDropdwn = dict.showCondition;
    let titleArray = [
      {name: 'AND', id: id.id},
      {name: 'OR', id: id.id},
    ];
    if (showDropdwn == true) {
      return (
        <View style={styles.commondropDownViewStyle}>
          <FlatList
            data={titleArray}
            horizontal={false}
            renderItem={this.renderConditionsDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View />;
  };
  renderConditionsDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectConditionDropDown(item)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.plainSubTextStyle}>{item.name}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  categoryDropDownRender = id => {
    let dict = this.state.automatedScopeArray[id.id];
    // console.log('dict, categoryDropDownRender', dict);
    let showDropdwn = dict.showCategory;
    var titleArray = [];
    for (let obj of listingScopeTitleArray) {
      let dict = {name: obj, id: id.id};
      titleArray.push(dict);
    }
    var cArray =
      this.state.selectedScopeTypeId == listingID
        ? titleArray
        : [{name: 'Category', id: id.id}];

    if (showDropdwn == true) {
      return (
        <View style={styles.commondropDownViewStyle}>
          <FlatList
            data={cArray}
            horizontal={false}
            renderItem={this.rendercategoryDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View />;
  };
  rendercategoryDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectCategoryDropDown(item)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.plainSubTextStyle}>{item.name}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  applicationPlatformRender = () => {
    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity
          style={{flexDirection: 'row', alignItems: 'center'}}
          onPress={() => this.setState({webEnable: !this.state.webEnable})}>
          <Image
            style={styles.checkBoxViewStyle}
            resizeMode="contain"
            source={
              this.state.webEnable !== false ? checked_Icon : unchecked_Icon
            }
          />
          <Text>Web</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{flexDirection: 'row', alignItems: 'center'}}
          onPress={() => this.setState({iosEnable: !this.state.iosEnable})}>
          <Image
            style={styles.checkBoxViewStyle}
            resizeMode="contain"
            source={
              this.state.iosEnable !== false ? checked_Icon : unchecked_Icon
            }
          />
          <Text>iOS</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{flexDirection: 'row', alignItems: 'center'}}
          onPress={() =>
            this.setState({androidEnable: !this.state.androidEnable})
          }>
          <Image
            style={styles.checkBoxViewStyle}
            resizeMode="contain"
            source={
              this.state.androidEnable !== false ? checked_Icon : unchecked_Icon
            }
          />
          <Text>Android</Text>
        </TouchableOpacity>
      </View>
    );
  };

  conditionTypeRender = () => {
    return (
      <View style={{margin: 20}}>
        <Text style={appConfigStyle.titleTxtStyle}>Condition type</Text>
        <View style={{flexDirection: 'row', marginTop: 16, marginLeft: -10}}>
          <TouchableOpacity
            style={{flexDirection: 'row', alignItems: 'center'}}
            onPress={() => this.conditionTypeBtnAction()}>
            <Image
              style={styles.checkBoxViewStyle}
              resizeMode="contain"
              source={
                this.state.conditionType !== true
                  ? radioCheckIcon
                  : radioUncheckIcon
              }
            />
            <Text>Manual</Text>
          </TouchableOpacity>
          {this.state.selectedScopeTypeId !== 5 && (
            <TouchableOpacity
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={() => this.conditionTypeBtnAction()}>
              <Image
                style={styles.checkBoxViewStyle}
                resizeMode="contain"
                source={
                  this.state.conditionType !== false
                    ? radioCheckIcon
                    : radioUncheckIcon
                }
              />
              <Text>Automated</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };
  renderSwitch = id => {
    let value = id.id == 1 ? this.state.statusSwitch : this.state.expirySwitch;
    return (
      <View style={{flexDirection: 'row', marginLeft: 20}}>
        <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.switchStatusBtnAction(id.id)}
          checked={value}
          height={22}
          width={50}
        />
      </View>
    );
  };
  renderSelectedItem = () => {
    if (this.state.selectedSearchArray.length != 0) {
      return (
        <View style={{flex: 1}}>
          <FlatList
            data={this.state.selectedSearchArray}
            horizontal={false}
            renderItem={this.cellItem}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View />;
  };
  searchViewRender = () => {
    return (
      <View style={styles.searchBarViewStyle}>
        <Image
          style={{width: 16, height: 16, marginLeft: 16}}
          source={searchIcon}
        />
        <TextInput
          style={{width: '100%', margin: 10, height: 30, outline: 0}}
          placeholder="Search Key"
          value={this.state.searchKey}
          placeholderTextColor={colors.placeholderColor}
          onChangeText={text => this.searchKeyText(text)}
        />
        <TouchableOpacity
          style={{display: this.state.searchKey.length != 0 ? 'flex' : 'none'}}
          onPress={() => this.closeSearchList()}>
          <Image
            style={{width: 16, height: 16, marginRight: 16}}
            source={close_Icon}
          />
        </TouchableOpacity>
        <this.searchDropDownRender />
      </View>
    );
  };
  cellItem = (item, index) => {
    let dict = item.item;
    let descp =
      this.state.selectedScopeTypeId == listingID
        ? dict.list_price.formatted
        : dict.description;
    return (
      <View style={styles.cellItemStyle}>
        <TouchableOpacity
          style={{flexDirection: 'row', flex: 1}}
          onPress={() => this.didSelectAccountListing(dict.id)}>
          <Image
            style={tableStyle.itemImageViewStyle}
            source={dict.images[0] || ''}
          />
          <View style={{justifyContent: 'center', marginLeft: 10, flex: 1}}>
            <Text
              style={[
                CommonStyleSheet.plainTextStyle,
                {color: colors.AppNewGreen},
              ]}>
              {dict.title || dict.name}
            </Text>
            <View style={{height: 5}} />
            <Text style={tableStyle.gridPriceViewStyle} numberOfLines={1}>
              {descp}
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{alignItems: 'center', marginTop: 5}}>
          <TouchableOpacity
            style={tableStyle.deleteViewStyle}
            onPress={() => this.deleteSelectItem(index)}>
            <Image style={tableStyle.iconStyle} source={deleteIcon} />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderConditionDropDown = id => {
    let dict = this.state.automatedScopeArray[id.id];
    var lableValue = dict.condition;
    let downIcon = dict.showCondition;
    return (
      <View>
        <TouchableOpacity
          onPress={() => this.showConditionDropDownView(id.id)}
          style={styles.dropDownView}>
          <Text style={{margin: 10}}>{lableValue}</Text>
          <Image
            style={
              downIcon == true
                ? appConfigStyle.upArraowIconStyle
                : appConfigStyle.downArraowIconStyle
            }
            resizeMode="contain"
            source={downArrow_Icon}
          />
        </TouchableOpacity>
        <this.conditionsDropDownRender id={id.id} />
      </View>
    );
  };
  categoryCustomDropDown = id => {
    let dict = this.state.automatedScopeArray[id.id];
    var lableValue = dict.category;
    let downIcon = dict.showCategory;
    return (
      <View>
        <TouchableOpacity
          onPress={() => this.showCategoryDropDownView(id.id)}
          style={styles.dropDownView}>
          <Text style={{margin: 10}}>{lableValue}</Text>
          <Image
            style={
              downIcon == true
                ? appConfigStyle.upArraowIconStyle
                : appConfigStyle.downArraowIconStyle
            }
            resizeMode="contain"
            source={downArrow_Icon}
          />
        </TouchableOpacity>
        <this.categoryDropDownRender id={id.id} />
      </View>
    );
  };

  renderDeleteListingView = id => {
    if (this.state.selectedScopeTypeId == listingID) {
      return (
        <TouchableOpacity
          style={styles.deleteViewStyle}
          onPress={() => this.deleteAutomatedListBtnAction(id.id)}>
          <Image
            style={styles.checkBoxViewStyle}
            resizeMode="contain"
            source={deleteIcon}
          />
        </TouchableOpacity>
      );
    } else {
      return <View />;
    }
  };
  scopeAutomaticRender = () => {
    let listingScopeViews = [];
    for (let a = 0; a < this.state.automatedScopeArray.length; a++) {
      let selectedItem = this.state.automatedScopeArray[a].item;
      let categoryTy = this.state.automatedScopeArray[a].category;
      let catAry = this.state.categoriesArray;
      // let catAry = categoryTy == 'Category' ? this.state.accountsArray : this.state.categoriesArray;
      var selectionView = [];
      if (catAry.length != 0) {
        selectionView.push(
          <View style={{marginTop: 10, flex: 1}}>
            <SelectSearchView
              value={selectedItem}
              onChange={itm => this.handleSelectChange(itm, a)}
              options={catAry}
              isMulti={true}
              placeholder={`Choose ${categoryTy}`}
              styles={colourStyles}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: colors.AppGreenBorder,
                  primary: colors.AppGreenBorder,
                },
              })}
            />
          </View>,
        );
      } else {
        selectionView.push(
          <View style={{marginTop: 10, flex: 1}}>
            <View
              style={{
                height: 40,
                justifyContent: 'center',
                borderRadius: 5,
                borderColor: colors.AppGreenBorder,
                borderWidth: 1,
              }}>
              <Text style={{paddingLeft: 15, color: colors.Appgray}}>
                {`Create ${categoryTy} category first`}
              </Text>
            </View>
          </View>,
        );
      }
      listingScopeViews.push(
        <View
          style={{
            flexDirection: itsMobileView ? 'column' : 'row',
            alignItems: 'flex-start',
            zIndex: 10000 - a,
          }}>
          <View style={{zIndex: 1000009}}>
            <this.renderConditionDropDown id={a} />
          </View>
          <View style={{width: 20}} />
          <View style={{zIndex: 1000007}}>
            <this.categoryCustomDropDown id={a} />
          </View>
          <View style={{width: 20}} />
          <View
            style={{
              zIndex: 1000005,
              flexDirection: 'row',
              width: itsMobileView ? '100%' : '50%',
            }}>
            {selectionView}
            <this.renderDeleteListingView id={a} />
          </View>
        </View>,
      );
    }
    return listingScopeViews;
  };
  automaticType = () => {
    if (this.state.selectedScopeTypeId == listingID) {
      return (
        <View>
          <this.scopeAutomaticRender />
          <View style={{margin: 20, alignItems: 'center', zIndex: 10}}>
            <TouchableOpacity
              style={styles.addAnotherNowView}
              onPress={() => this.addAnotherConditionBtnAction()}>
              <Text
                style={[
                  tableStyle.saveTxtStyle,
                  {color: colors.AppGreenBorder},
                ]}>
                + Add another condition
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return <this.scopeAutomaticRender />;
    }
  };
  conditonTypesViewRender = () => {
    if (this.state.conditionType == false) {
      let hover = this.state.searchBtnHover;
      return (
        <View style={{margin: 20, marginTop: -6}}>
          <View style={[tableStyle.secondButtonViewStyle, {width: 160}]}>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => this.setState({showSideView: true})}>
              <Image style={{width: 16, height: 16}} source={searchIcon} />
              <Text
                style={[
                  tableStyle.secondBtnTextStyle,
                  {color: hover ? colors.AppNewGreen : colors.placeholderColor},
                ]}>
                {'Search'}
              </Text>
            </TouchableOpacity>
          </View>
          {/* <View style={{justifyContent:'center', alignItems:'center', flexDirection:'row',padding:10,width:160}}>
            <Image style={{ width: 16, height: 16, marginLeft: 16 }} source={searchIcon} />
            <Text style={appConfigStyle.titleTxtStyle}>Search</Text>
          </View> */}
          {/* <Text style={appConfigStyle.titleTxtStyle}>Search</Text>
          <View style={{flexDirection: 'row'}}>
            <this.searchViewRender />
          </View> */}
        </View>
      );
    } else {
      return (
        <View style={{margin: 20, marginTop: -10}}>
          <this.automaticType />
        </View>
      );
    }
  };

  renderdownArrow = () => {
    if (this.props.isEdit) {
      return <View />;
    } else {
      return (
        <Image
          style={
            this.state.showDropDown == true
              ? appConfigStyle.upArraowIconStyle
              : appConfigStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      );
    }
  };

  //
  imageView = props => {
    var view = [];
    view.push(
      <View style={{flexDirection: 'row'}}>
        <View style={CommonStyleSheet.addImageViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg', '.gif']}
            base64={true}
            handleFiles={this.onPick}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Add Photo
            </Text>
          </ReactFileReader>
        </View>
        <View style={{width: 20, height: 20}} />
      </View>,
    );
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      var showImage = true;
      var icon = jsonIcon;
      var fileExt = '';
      if (fileName == undefined) {
        let name = this.state.selectedImage.substring(
          this.state.selectedImage.lastIndexOf('/') + 1,
        );
        var fileExt = name.split('.').pop();
      }
      if (fileExt == 'json') {
        icon = jsonIcon;
        showImage = false;
      } else if (fileExt == 'pdf') {
        icon = pdfIcon;
        showImage = false;
      }
      view.push(
        <View>
          <View
            style={{
              width: itsMobileView ? 20 : 0,
              height: itsMobileView ? 20 : 0,
            }}
          />
          <Image
            // source={{ uri: fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage }}
            source={
              showImage
                ? {
                    uri:
                      fileName != null
                        ? this.state.selectedImage.base64
                        : this.state.selectedImage,
                  }
                : icon
            }
            style={CommonStyleSheet.ImageViewStyle}
          />
        </View>,
      );
    }
    return (
      <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
        {view}
      </View>
    );
  };

  //

  render() {
    const isEdit = this.props.isEdit;

    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <View style={{zIndex: 10}}>{this.renderSidePanView()}</View>
        <View>
          <Header_View
            title={isEdit ? 'Edit Collection' : 'Create Collection'}
            isEdit={isEdit}
            preview_path={this.state.preview_path}
            backBtn={() => this.props.navigate(-1)}
            saveBtnTitle={isEdit ? 'Update' : 'Create'}
            saveBtn={() => this.saveBtnAction()}
          />
          <View style={tableStyle.subContainerView}>
            <ScrollView>
              <View
                style={{
                  flexDirection: itsMobileView ? 'column' : 'row',
                  flex: 1,
                }}>
                <View style={styles.leftMainView}>
                  <Text style={CommonStyleSheet.plainTextStyle}>
                    Collection details
                  </Text>
                  <View>
                    <View style={{margin: 16}}>
                      <Text style={appConfigStyle.titleTxtStyle}>Image</Text>
                      <View style={{width: '80%', marginTop: '16px'}}>
                        <this.imageView />
                      </View>
                    </View>
                  </View>
                  <View style={styles.firstViewStyle}>
                    <View style={{margin: 16}}>
                      <Text style={appConfigStyle.titleTxtStyle}>Title</Text>
                      <TextInput
                        style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                        placeholder="Enter title name"
                        placeholderTextColor={colors.placeholderColor}
                        onChangeText={txt => this.setState({titleString: txt})}
                        value={this.state.titleString}
                      />
                    </View>
                    <View style={{margin: 16}}>
                      <Text style={appConfigStyle.titleTxtStyle}>Slug</Text>
                      <TextInput
                        style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                        placeholder="Enter slug"
                        placeholderTextColor={colors.placeholderColor}
                        onChangeText={txt =>
                          this.setState({collectionSlug: txt})
                        }
                        value={this.state.collectionSlug}
                      />
                    </View>
                    <View style={{margin: 16}}>
                      <Text style={appConfigStyle.titleTxtStyle}>
                        Description{' '}
                      </Text>
                      <DescriptionView
                        value={this.state.descriptionString}
                        onChange={txt =>
                          this.setState({descriptionString: txt})
                        }
                      />
                    </View>
                    <View style={{margin: 16}}>
                      <Text style={appConfigStyle.titleTxtStyle}>Scope </Text>
                      <TouchableOpacity
                        onPress={() => this.showScopeDropDown()}
                        style={styles.dropDownView}>
                        <Text style={{margin: 10}}>
                          {this.state.selectedScopeTypeId == 1
                            ? 'Accounts'
                            : this.state.selectedScopeTypeId == 5
                            ? 'Featured Listings'
                            : 'Listings'}
                        </Text>
                        <this.renderdownArrow />
                      </TouchableOpacity>
                      <this.scopeDropDownRender />
                    </View>
                  </View>
                  <View style={{height: 20}} />
                  <Text style={CommonStyleSheet.plainTextStyle}>
                    Conditions
                  </Text>
                  <View style={styles.firstViewStyle}>
                    <this.conditionTypeRender />
                    <this.conditonTypesViewRender />
                  </View>
                  <View
                    style={
                      this.state.selectedSearchArray.length == 0
                        ? {flex: 1}
                        : styles.selectedViewStyle
                    }>
                    <this.renderSelectedItem />
                  </View>
                  <View style={{height: 100}} />
                </View>
                <View style={styles.rightMainView}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '95%',
                    }}>
                    <Text style={CommonStyleSheet.plainTextStyle}>
                      Status(Not Active / Active)
                    </Text>
                    <this.renderSwitch id={1} />
                  </View>
                  <View style={{height: 30}} />
                  <Text style={CommonStyleSheet.plainTextStyle}>
                    Collections availability
                  </Text>
                  <View style={styles.firstViewStyle}>
                    <View style={{margin: 20, zIndex: 101}}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <Text style={appConfigStyle.titleTxtStyle}>
                          Schedule
                        </Text>
                        <this.renderSwitch id={2} />
                      </View>
                    </View>
                    <View
                      style={{
                        display: this.state.expirySwitch ? 'flex' : 'none',
                      }}>
                      <View style={{margin: 20, zIndex: 101, marginTop: 10}}>
                        <Text style={appConfigStyle.titleTxtStyle}>
                          Start Date
                        </Text>
                        <View style={styles.dateViewStyle}>
                          <DatePicker
                            style={[
                              appConfigStyle.txtFieldStyle,
                              {outline: 0, height: 35},
                            ]}
                            date={Moment(this.state.startDate).format(
                              dateFormat,
                            )}
                            color={colors.AppLightGreen}
                            placeholder={'Select start date'}
                            handleDateChange={date =>
                              this.handleChange(date, 1)
                            }
                            width={300}
                            inputStyle={{
                              borderRadius: 0,
                              borderColor: colors.TransparentColor,
                              marginTop: -30,
                              height: 20,
                              ...p2,
                              backgroundColor: colors.TransparentColor,
                            }}
                          />
                        </View>
                      </View>
                      <View style={{margin: 20, zIndex: 100}}>
                        <Text style={appConfigStyle.titleTxtStyle}>
                          End Date
                        </Text>
                        <View style={styles.dateViewStyle}>
                          <DatePicker
                            date={Moment(this.state.endDate).format(dateFormat)}
                            color={colors.AppLightGreen}
                            handleDateChange={date =>
                              this.handleChange(date, 2)
                            }
                            placeholder={'Select End Date'}
                            width={300}
                            inputStyle={{
                              borderRadius: 0,
                              borderColor: colors.TransparentColor,
                              marginTop: -30,
                              height: 20,
                              ...p2,
                              backgroundColor: colors.TransparentColor,
                            }}
                          />
                        </View>
                      </View>
                    </View>
                    {/* <View style={{ margin: 20,zIndex: 1 }}>
                      <TouchableOpacity style={styles.endNowView}>
                        <Text style={[tableStyle.saveBtnTxtStyle, { color: colors.AppRed }]}>
                          End Now
                        </Text>
                      </TouchableOpacity>
                    </View> */}
                  </View>
                  <View style={{height: 20, zIndex: 2}} />
                  <Text style={CommonStyleSheet.plainTextStyle}>
                    Select applicable channel
                  </Text>
                  <View style={styles.platformViewStyle}>
                    <View style={{margin: 20}}>
                      <this.applicationPlatformRender />
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
          <Appload enable={this.state.dataLoad} />
        </View>
      </SafeAreaView>
    );
  }
}
export default withRouter(CreateCollection);

const rendererSettings = {
  preserveAspectRatio: 'xMinYMin slice',
};
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
    height: windowHeight,
  },
  leftMainView: {
    margin: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 20,
    flex: 1.9,
  },
  rightMainView: {
    margin: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: 20,
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
  },
  dropDownView: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    width: 150,
    marginTop: 10,
    marginLeft: 0,
    height: 40,
    alignItems: 'center',
    zIndex: 900,
    paddingLeft: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 150,
    marginTop: 65,
    marginLeft: 0,
    zIndex: 9000,
  },
  commondropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 150,
    marginTop: 50,
    marginLeft: 0,
    zIndex: 9000,
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
  firstViewStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 111,
    padding: 2,
  },
  selectedViewStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 110,
    padding: 2,
  },
  platformViewStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 6,
  },
  endNowView: {
    width: itsMobileView ? 140 : '40%',
    height: 40,
    borderColor: colors.AppRed,
    borderWidth: 1,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteViewStyle: {
    width: 40,
    height: 40,
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
  },
  addAnotherNowView: {
    width: '90%',
    height: 40,
    borderColor: colors.AppGreenBorder,
    borderWidth: 1,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateViewStyle: {
    padding: 10,
    paddingTop: 6,
    paddingLeft: 0,
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    outlineWidth: 0,
    zIndex: 90,
  },
  searchBarViewStyle: {
    marginTop: 10,
    borderColor: colors.AppGreenBorder,
    borderRadius: 10,
    borderWidth: 1,
    width: '100%',
    height: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  searchDropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '85%',
    height: 200,
    marginTop: 290,
    marginLeft: 40,
    zIndex: 9001,
    marginBottom: 50,
    borderTopWidth: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  cellItemStyle: {
    flexDirection: 'row',
    backgroundColor: colors.AppWhite,
    margin: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 10,
    justifyContent: 'space-between',
  },
});

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
