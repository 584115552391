import React, {useEffect, useState} from 'react';
import PageHeader from '../../Component/Layout/PageHeader';
import {useNavigate, useLocation} from 'react-router-dom';
import LanguageHeader from '../../Component/Layout/LanguageHeader';
import {getTranslations} from '../Actions/getTranslations';
import {getTenantLanguages} from '../Actions/getLanguages';
import {saveTranslateData} from '../Actions/saveTranslation';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';

const CategoryDescriptionTranslate = ({
  translationTitle,
  titleString,
  translationRef,
  backBtnAction,
}) => {
  const navigate = useNavigate();
  const {search} = useLocation();

  const [category_form, setCategoryForm] = useState({
    description: {},
    name: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  // language header
  const [selected_language, setSelectedLanguage] = useState([]);
  const [default_language, setDefaultLanguage] = useState(null);
  const [tenant_languages, setTenantLanguages] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getTenantLanguages()
      .then(res => {
        setIsLoading(false);

        setTenantLanguages(res);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setSelectedLanguage(tenant_languages?.filter(lan => lan?.default)[0].code);
    setDefaultLanguage(tenant_languages?.filter(lan => lan?.default)[0].code);
  }, [tenant_languages]);

  //
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const categoryID = urlParams.get('ct_id');
    setIsLoading(true);
    if (categoryID) {
      // name
      getTranslations({
        reference_id: categoryID,
        reference_type: 'category',
        translation_type: 'name',
      }).then(res => {
        if (res?.values?.length > 0) {
          setIsLoading(false);
          setCategoryForm(prev => ({
            ...prev,
            name: res?.values?.reduce((prev, curr) => {
              return {...prev, [curr?.locale]: curr?.value};
            }, {}),
          }));
        }
      });

      // desc
      getTranslations({
        reference_id: categoryID,
        reference_type: 'category',
        translation_type: 'description',
      }).then(res => {
        if (res?.values?.length > 0) {
          setIsLoading(false);
          setCategoryForm(prev => ({
            ...prev,
            description: res?.values?.reduce((prev, curr) => {
              return {...prev, [curr?.locale]: curr?.value};
            }, {}),
          }));
        }
      });
    }
  }, [search]);

  // on Update value
  const onUpdateValue = ({key, value}) => {
    if (key === 'description' && selected_language) {
      setCategoryForm(prev => ({
        ...prev,
        description: {
          ...category_form?.description,
          [selected_language]: value,
        },
      }));
    }
    if (key === 'name' && selected_language) {
      setCategoryForm(prev => ({
        ...prev,
        name: {
          ...category_form?.name,
          [selected_language]: value,
        },
      }));
    }
  };

  //
  const onSave = () => {
    const urlParams = new URLSearchParams(search);
    const categoryID = urlParams.get('ct_id');
    setIsLoading(true);
    saveTranslateData({
      reference_id: categoryID,
      reference_type: 'category',
      translation_type: 'name',
      translations_data: category_form?.name,
      onErrorHappen: () => {
        setIsLoading(false);
      },
      closeFunc: () => {
        saveTranslateData({
          reference_id: categoryID,
          reference_type: 'category',
          translation_type: 'description',
          translations_data: category_form?.description,
          closeFunc: () => {
            setIsLoading(false);
            navigate('/listing-category');
          },
          onErrorHappen: () => {
            setIsLoading(false);
            navigate('/listing-category');
          },
        });
      },
    });
  };

  return (
    <div className="">
      <PageHeader
        title={translationTitle}
        close_action={() => navigate('/listing-category')}
        save_action={() => onSave()}
      />
      <div>
        {/* Language Header */}
        <LanguageHeader
          selected_language={selected_language}
          setSelectedLanguage={setSelectedLanguage}
          setDefaultLanguage={setDefaultLanguage}
          languages={tenant_languages}
        />
        <div className="space-y-4">
          <div className=" flex flex-col w-full gap-2 mt-4 ">
            <label htmlFor="ct-name" className=" text-lg text-black">
              Category Name
            </label>
            <input
              type="text"
              value={category_form?.name?.[selected_language] ?? ''}
              name="ct-name"
              className=" border rounded-md border-[#e6e7e7]"
              onChange={e => {
                onUpdateValue({key: 'name', value: e.target.value});
              }}
            />
          </div>
          {/* Desc */}
          <div className=" flex flex-col w-full gap-2 mt-4 ">
            <label htmlFor="ct-desc" className=" text-lg text-black">
              Description
            </label>
            <textarea
              value={category_form?.description?.[selected_language] ?? ''}
              name="ct-desc"
              id="ct-desc"
              cols="30"
              rows="10"
              className=" border rounded-md border-[#e6e7e7]"
              onChange={e => {
                onUpdateValue({key: 'description', value: e.target.value});
              }}
            />
          </div>
        </div>
      </div>

      <AppLoader enable={!isLoading} />
    </div>
  );
};

export default CategoryDescriptionTranslate;
