
import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  TouchableOpacity,
  Image,
  Dimensions
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, seoMixPanelEvent } from '../../Models/MixPannelEnum'
import ReactFileReader from 'react-file-reader';
import closeIcon from '../../assets/closeBlackIcon.png';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'seo';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class SEOGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      metaTitle: '',
      metaDescription: '',
      metaImage:'',
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,'get','', '', );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        metaTitle: configs['meta_title'] || '',
        metaDescription: configs['meta_description'] || '',
        metaImage: configs['meta_image'] || '',

      });
    }
    this.setState({dataLoad: true});
  }
  uploadMetaImage = async () => {
    var imgParm = [];
    var uploadBase64 = [];

    if (this.state.metaImage != null) {
      let fileName = this.state.metaImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.metaImage['fileList'][0]['name'],
          type: this.state.metaImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.metaImage.base64,
          key: 'sell_icon',
        });
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL, 'POST', JSON.stringify({ files: imgParm }))
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'], imgParm[i]['type'], await res.blob())
            let selectedImagePath = result[0]['fileUri']
            this.addOrUpdateAppDetailApi(selectedImagePath)
          })
        }
      } else {
        this.alertView(responseJson);
      }
    } else {
      this.addOrUpdateAppDetailApi(this.state.metaImage != null ? this.state.metaImage : '')
    }
  }

  addOrUpdateAppDetailApi = async (imagePath) => {
    var configs = [];
    let valueArray = [
      this.state.metaTitle,
      this.state.metaDescription,
      imagePath || ''
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    if (configs.length == 0){
      this.deleteKeysAPI(configs);
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}))
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      seoMixPanelEvent(MixpanelEvent.seoGeneral)
      this.deleteKeysAPI(configs);
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  }

  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall( urlParm, 'delete')
      this.setState({dataLoad: true});
      this.alertView();
    } else {
      this.setState({dataLoad: true});
      this.alertView();
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // console.log('calling api');
    this.setState({dataLoad: false})
    this.uploadMetaImage()
  }
  onPickSplash = image => {
    this.setState({metaImage: image});
  };
  //MARK:-  UIs 
  imageViewPicker = id => {
    var msgTxt = 'Choose photo'
    return (
      <View style={appConfigStyle.dottedViewStyle}>
        <ReactFileReader
          fileTypes={['.png', '.jpg', 'image/*']}
          base64={true}
          style={appConfigStyle.dottedViewStyle}
          handleFiles={file => this.onPickSplash(file)}>
          <View style={{ height: 5 }} />
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
          <View style={{ height: 5 }} />
        </ReactFileReader>
      </View>
    )
  }
  viewSelectedImages = id => {
    if (this.state.metaImage != null) {
      let fileName = this.state.metaImage.base64
      let showCloseBtn = false
      if (this.state.metaImage.length != 0) {
        showCloseBtn = true
      } else {
        showCloseBtn = fileName != undefined ? true : false
      }
      return (
        <View style={[appConfigStyle.selectImagesViewStyle,{flexDirection:'row'}]}>
          <Image source={{uri: fileName != null ? this.state.metaImage.base64 : this.state.metaImage}}
            style={appConfigStyle.SelectedImageStyle} />
               <TouchableOpacity  style={{display : showCloseBtn ? 'flex' : 'none'}} onPress={() => this.setState({metaImage:''})}>
              <Image source={closeIcon} style={SettingStyleSheet.closeBtnStyle} />
            </TouchableOpacity>
        </View>
      )
    } else {
      return <View />
    }
  }
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>General</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Title</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaTitle: name})}
                value={this.state.metaTitle}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Description</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaDescription: name})}
                value={this.state.metaDescription}
              />
            </View>
            <View style={{ marginTop: 20 }}>
              <View style={{ width: '100%' }}>
                <Text style={appConfigStyle.titleTxtStyle}>Meta Image</Text>
                <this.imageViewPicker id={1} />
                <View style={{ marginTop: 16, flexDirection: 'row' }}>
                  <this.viewSelectedImages id={1} />
                </View>
              </View>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'meta_title',
  'meta_description',
  'meta_image'
];
