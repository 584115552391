var slugify = require('slugify');

export const orderStatus = id => {
  if (id === 1) {
    return 'Incomplete';
  }
  if (id === 2) {
    return 'Confirmed';
  }
  if (id === 3) {
    return 'In progress';
  }
  if (id === 4) {
    return 'Shipped';
  }
  if (id === 5) {
    return 'Customer unreachable';
  }
  if (id === 6) {
    return 'Out for delivery';
  }
  if (id === 7) {
    return 'Returned';
  }
  if (id === 8) {
    return ' Return confirmed';
  }
  if (id === 9) {
    return 'Delivered';
  }
  if (id === 10) {
    return 'Delivery confirmed';
  }
  if (id === 11) {
    return 'Return initiated';
  }
  if (id === 12) {
    return 'Return picked';
  }
  if (id === 13) {
    return 'Return confirmed';
  }
  if (id === 14) {
    return 'Return disputed';
  }
  if (id === 15) {
    return 'Canceled by seller ';
  }
  if (id === 16) {
    return 'Canceled by customer';
  }
  if (id === 17) {
    return 'Ready for pickup';
  }
  if (id === 18) {
    return 'Order Completed';
  }
  if (id === 19) {
    return 'Order Confirmed';
  }
  if (id === 20) {
    return 'Arrived at customer location';
  }
  if (id === 21) {
    return 'Canceled By Admin';
  }
};

//
export const changeStatus = id => {
  switch (id) {
    case 1:
      return 'Incomplete';

    case 2:
      return 'Order Place';

    case 3:
      return 'Order In progress';

    case 4:
      return 'Ship';

    case 5:
      return 'Deliver';

    case 6:
      return 'Cancel by customer';

    case 7:
      return 'Cancel by admin';

    case 8:
      return 'Order complete';

    default:
      return 'N/A';
  }
};

//
export const currentOrderStatus = id => {
  switch (id) {
    case 1:
      return 'Incomplete';

    case 2:
      return 'Order placed';

    case 3:
      return 'Order in progress';

    case 4:
      return 'Shipped';

    case 5:
      return 'Deliverd';

    case 6:
      return 'Cancelled by customer';

    case 7:
      return 'Cancelled by admin';

    case 8:
      return 'Order completed';

    default:
      return 'N/A';
  }
};

//

export const ShipmentStatus = id => {
  switch (id) {
    case 1:
      return 'Shipment created';
    case 2:
      return 'Shipment in progress';
    case 3:
      return 'Ship';
    case 4:
      return 'Ready for pickup';
    case 5:
      return 'Out for delivery';
    case 6:
      return 'Undelivered return';
    case 7:
      return 'Shipment deliver';
    case 8:
      return 'Shipment delivery confirm';
    case 9:
      return 'Return initiate';
    case 10:
      return 'Return for pickup';
    case 11:
      return 'Shipment return confirm';
    case 12:
      return 'Cancel by seller';
    case 13:
      return 'Cancel by customer';
    case 14:
      return 'Arrive at customer location';
    case 15:
      return 'Cancel by admin';
    default:
      return 'N/A';
  }
};

export const currentShipmentStatus = id => {
  switch (id) {
    case 1:
      return 'Shipment created';
    case 2:
      return 'Shipment in progress';
    case 3:
      return 'Shipped';
    case 4:
      return 'Ready for pickup';
    case 5:
      return 'Out for delivery';
    case 6:
      return 'Undelivered return';
    case 7:
      return 'Shipment delivered';
    case 8:
      return 'Shipment delivery confirmed';
    case 9:
      return 'Return initiated';
    case 10:
      return 'Returned for pickup';
    case 11:
      return 'Shipment return confirmed';
    case 12:
      return 'Cancelled by seller';
    case 13:
      return 'Cancelled by customer';
    case 14:
      return 'Arrived at customer location';
    case 15:
      return 'Cancelled by admin';
    default:
      return 'N/A';
  }
};
