import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import grayTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import { ToastContainer } from 'react-toastify';
import AppConstants from '../../Constants/AppConstants';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import colors from '../../HelperClasses/AppColor';
import AppColor from '../../HelperClasses/AppColor';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import { ReactComponent as LockIcon } from '../../assets/lockIcon.svg';
import networkService from '../../Network/NetworkManager';
import { AppToast } from '../../HelperClasses/AppToast';
import TwiloIntegration from '../NativeIntegrations/TwiloIntegration';
import FacebookIntegration from '../NativeIntegrations/FacebookIntegration';
import GoogleIntegration from '../NativeIntegrations/GoogleIntegration';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import HeaderView from '../HeaderView';
import { RestrictedFeatureEnum } from '../../Models/RestrictedFeatureEnum';
import SubscribePlan from '../../Component/SubscribePlan';

const APPURL = require('../../Constants/URLConstants');

var subMenuTextArray = [
  'Facebook Auth',
  'Google Auth',
  'Twilio',
];

export default class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subMenuSelectedIndex: 0,
      switchArray: [false, false, false, false, false],
      saveBtnCallBack: false,
      dataLoad: true,
      writeP: AppConstants.defaultRead,
      showUpgrade:false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi()
    this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.NATIVEINTEGRATIONS, res => {
      this.setState({ writeP: res[1] })
    })
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general', 'get', '', '',
    );
    // console.log('configs', responseJson);
    this.setState({ currencyArray: [] });
    if (responseJson["status"] == true) {
      var configs = responseJson['data']['configs'];
      let enable_admob = configs['tenant_enable_admob'] || false;
      if (enable_admob) {
        subMenuTextArray.push('Admob');
      }
    }
    this.setState({ dataLoad: true });
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
    this.setState({ dataLoad: true });
  };

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    // console.log('main calling');
    if (this.state.subMenuSelectedIndex == 1) {
      this.child.saveButtonAction()
    } else if (this.state.subMenuSelectedIndex == 2) {
      this.child.saveButtonAction()
    } else if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveButtonAction()
    }
  }
  didSelectMenuItem = index => {
    this.setState({ subMenuSelectedIndex: index });
  };
  switchSattusBtnAction(id) {
    this.state.switchArray[id] = !this.state.switchArray[id];
    this.setState({ updateUI: !this.state.updateUI });
  }
  dissmissSaveBtn() {
    this.setState({ saveBtnCallBack: false });
  }
  /*  UI   */
  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= subMenuTextArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={this.state.subMenuSelectedIndex == a ? SettingsStyleSheet.selectedSubMenuViewStyle : SettingsStyleSheet.subMenuViewStyle}
          onPress={() => this.didSelectMenuItem(a)}>
          <Image style={SettingsStyleSheet.subMenuIconStyle} source={this.state.subMenuSelectedIndex == a ? greenTickIcon : grayTick_Icon} />
          <Text style={this.state.subMenuSelectedIndex == a ? SettingsStyleSheet.subMenuSelectedtextStyle : SettingsStyleSheet.subMenutextStyle}>
            {subMenuTextArray[a]}
          </Text>
          <View style={{ display: a == 0 || a == 1 || a == 6 ? 'flex' : 'none' }}>
            <LockIcon style={{ height: 16, width: 16, marginLeft: 5 }} />
          </View>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };

  mainViewRender = () => {
    let title = subMenuTextArray[this.state.subMenuSelectedIndex];
    let pp = 'Enter ' + title + ' ID';
    if (this.state.subMenuSelectedIndex == 2) {
      return (<TwiloIntegration ref={child => { this.child = child }} />);
    } else if (this.state.subMenuSelectedIndex == 0) {
      return (<FacebookIntegration ref={child => { this.child = child }} />);
    } else if (this.state.subMenuSelectedIndex == 1) {
      return (<GoogleIntegration ref={child => { this.child = child }} />);
    } else {
      return (
        <View style={{ width: '70%', marginLeft: 10 }}>
          <Text style={tableStyle.listTitleText}>{title}</Text>
          <Text style={appConfigStyle.subTitleStyle}>
            {AppConstants.plainTextString}
          </Text>
          <View style={appConfigStyle.horizontalLineViewStyle} />
          <View style={appConfigStyle.subViewStyle}>
            <View style={{ flexDirection: 'column', flex: 1.5 }}>
            </View>
          </View>
        </View>
      );
    }
  }
  checkRestriction() {
    if (this.state.subMenuSelectedIndex == 0 || this.state.subMenuSelectedIndex == 1) {
      let check = AppConstants.restrictedFeatureArray.includes(RestrictedFeatureEnum.socialAuth)
      return check
    }
    else {
      return false
    }
  }
  renderUpgradePlanView() {
    return <TouchableOpacity style={[tableStyle.passwordViewStyle, { display: this.state.showUpgrade ? 'flex' : 'none' }]}
      onPress={() => this.setState({ showUpgrade: false })}>
      <SubscribePlan upgrade={true} />
    </TouchableOpacity>
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        {this.renderUpgradePlanView()}
        <HeaderView
          title={'Auth'}
          backBtn={this.props.backBtn}
          saveBtn={() => this.saveBtnAction()}
          showBackBtn={this.props.showBackBtn}
          showSaveBtn={this.state.writeP}
          deActiveBtnAction={() => this.setState({ showUpgrade: true })}
          btnDeActive={this.checkRestriction()}
        />
        <View style={{ flexDirection: 'column', marginBottom: 0 }}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{ flex: 2, backgroundColor: 'white' }}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
  },
});
