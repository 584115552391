import React from 'react';
import {Dimensions, Image, Text, View} from 'react-native';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import AppConfigStyleSheet from '../StyleSheets/AppConfigStyleSheet';
import ReactFileReader from 'react-file-reader';
import jsonIcon from '../assets/jsonIcon.png';
import pdfIcon from '../assets/pdfICon.png';
import AppConstants from '../Constants/AppConstants';

//
let fixedWidth = AppConstants.mobileMaxWidth;
const windowWidth = Dimensions.get('window').width;

let itsMobileView = windowWidth < fixedWidth ? true : false;

const ImageSelection = ({selectedImage = null, setSelectedImage}) => {
  var view = [];
  view.push(
    <View style={{flexDirection: 'row'}}>
      <View style={CommonStyleSheet.addImageViewStyle}>
        <ReactFileReader
          fileTypes={['.png', '.jpg', '.gif']}
          base64={true}
          handleFiles={image => setSelectedImage(image)}>
          <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>Add Photo</Text>
        </ReactFileReader>
      </View>
      <View style={{width: 20, height: 20}} />
    </View>,
  );
  if (selectedImage != null) {
    let ImageURI = selectedImage?.base64 ?? undefined;
    var showImage = true;
    var icon = jsonIcon;
    var fileExt = '';
    if (ImageURI == undefined) {
      let name = selectedImage.substring(selectedImage.lastIndexOf('/') + 1);
      fileExt = name.split('.').pop();
    }
    if (fileExt == 'json') {
      icon = jsonIcon;
      showImage = false;
    } else if (fileExt == 'pdf') {
      icon = pdfIcon;
      showImage = false;
    }
    view.push(
      <View>
        <View
          style={{
            width: itsMobileView ? 20 : 0,
            height: itsMobileView ? 20 : 0,
          }}
        />
        <Image
          source={
            showImage
              ? {
                  uri:
                    ImageURI != undefined
                      ? selectedImage.base64
                      : selectedImage,
                }
              : icon
          }
          style={CommonStyleSheet.ImageViewStyle}
        />
      </View>,
    );
  }
  return (
    <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
      {view}
    </View>
  );
};

export default ImageSelection;
