import React from 'react';
import {Image, Text, TouchableOpacity, View, StyleSheet} from 'react-native';
import TableCommonStyleSheet from '../../../StyleSheets/TableCommonStyleSheet';
import addIcon from '../../../assets/add_icon.png';

const AddRow = ({onPress}) => {
  return (
    <View style={TableCommonStyleSheet.secondButtonViewStyle}>
      <TouchableOpacity style={styles.addRowViewStyle} onPress={onPress}>
        <Image
          style={TableCommonStyleSheet.iconStyle}
          resizeMode="center"
          source={addIcon}
        />
        <Text style={TableCommonStyleSheet.secondBtnTextStyle}>
          {'Add Row'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default AddRow;

const styles = StyleSheet.create({
  addRowViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
});
