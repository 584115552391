import React from 'react';

const FeatureCard = ({
  title,
  description,
  onClickHandler,
  component_styles,
}) => {
  return (
    <div
      className={`border  border-gray-200 rounded w-full px-6 py-4 flex items-center gap-4 bg-[#FAFBFB] ${component_styles}`}
      onClick={onClickHandler}>
      <div className="flex-grow space-y-1">
        <h1 className="font-semibold  text-sm md:text-lg text-black">
          {title}
        </h1>
        <p className="   text-xs md:text-base font-normal text-gray-700">
          {description}
        </p>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        data-slot="icon"
        class="w-6 h-6 text-[#222]">
        <path
          fill-rule="evenodd"
          d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  );
};

export default FeatureCard;
