import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastPopup = ({type, message}) => {
  if (type === 'success') {
    toast.success(`✅️ ${message}`, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
  if (type === 'error') {
    toast.error(`❌️ ${message}`, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
  if (type === 'info') {
    toast.info(`ℹ️ ${message}`, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
  if (type === 'warning') {
    toast.warn(`⚠️ ${message}`, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
};

export default ToastPopup;
