import React from 'react';

const Paragraph = ({children, className}) => {
  return (
    <p className={` text-xs md:text-base text-gray-700 ${className}`}>
      {children}
    </p>
  );
};

export default Paragraph;
