import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Platform,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import ImagePicker from 'react-native-image-crop-picker';
import ReactFileReader from 'react-file-reader';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import Translations from './Translation';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import AddAttribute from './AddAttributes';
import MediaManagerView from '../../Component/MediaManagerView';
import {onHandleNumberFieldChange} from '../../HelperClasses/SingletonClass';
import DescriptionView from '../../Component/DescriptionView';
import Switch from 'react-switch';
import jsonIcon from '../../assets/jsonIcon.png';
import pdfIcon from '../../assets/pdfICon.png';
import {Gradient} from 'react-gradient';
import AppColor from '../../HelperClasses/AppColor';
import {addMixpanelEvent} from '../../Models/MixPannelEnum';

import CustomAlertView from '../../Component/CustomAlertView';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import AppConstants from '../../Constants/AppConstants';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import {ICONS} from '../../Constants/Icons';
import GithubSlugger, {slug as slugChecker} from 'github-slugger';
import SidePan from '../../Shared/SidePan';
import SecondryButton from '../../Component/SecondryButton';
import DescriptionTranslation from './DescriptionTranslation';
import {TranslateEnum} from '../../Models/TranslateEnum';
import CategoryDescriptionTranslate from './CategoryDescriptionTranslate';
import {withRouter} from '../../wrappers/withRouter';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
var pageNo = 1;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      selectedImage: null,
      status: true,
      name: '',
      dataLoad: false,
      id: '',
      selectedCategoryLevel: '',
      selectedParentId: 0,
      localCat: [],
      categoriesArray: [],
      backendParentID: 0,
      updatingBool: false,
      rank: '',
      translationBool: false,
      categoryTypeField: '',
      parentId: '',
      attributeArray: [],
      updateUI: false,
      selectedData: {},
      addAttributeBool: false,
      showMediaManager: false,
      mediaArray: [],
      descriptionString: '',
      slugValue: '',
      editAttribute: false,
      showNextViewBool: false,
      showMetaDescription: false,
      showMetaTitle: false,
      metaDescriptionString: '',
      metaTitle: '',
      translationDescBool: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    categoryData: PropTypes.any,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
    closeBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.loadMediaAPi();
    const catData = this.props.categoryData;

    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (!this.state.updatingBool) {
        this.setState({
          name: catData['subCategory']
            ? catData['subCategory']
            : catData['name'],
          status: catData['active'],
          id: catData['id'],
          selectedImage: catData['image_path'],
          backendParentID: catData['parent'],
          updatingBool: true,
          rank: catData['order_by'],
          slugValue: catData['slug'] || '',
          descriptionString: catData['description'] || '',
          categoryTypeField: catData['type'] || '',
          metaDescriptionString: catData['meta_description'] || '',
          metaTitle: catData['meta_title'] || '',
        });
        if (this.state.categoriesArray[0]) {
          this.didSelectDropDown(this.state.categoriesArray[0]);
        }
        this.loadAttributeApi(catData['id']);
      }
    }
    if (catData != undefined) {
      this.state.parentId = catData['id'];
      this.setState({parentId: catData['id']});
    }
    this.loadCategoryApi();
    if (this.props.type == 'Listings') {
      this.loadConfigApi();
    }
  }
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=listings',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        showMetaDescription: configs['meta_description'] || false,
        showMetaTitle: configs['meta_title'] || false,
      });
    }
    this.setState({dataLoad: true});
  };
  loadMediaAPi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,
      'get',
      '',
      '',
    );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['media'];
      if (collectionD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of collectionD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
    }
    this.setState({dataLoad: true});
  };
  loadAttributeApi = async id => {
    let path = this.props.type == 'Listings' ? 'listings' : 'accounts';
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes + `?type=${path}&category_id=${id}`,
      'get',
    );
    console.log('responseJson', responseJson);
    this.setState({attributeArray: []});
    if (responseJson['status'] == true) {
      var attriD = responseJson['data']['attributes'];
      for (let obj of attriD) {
        this.state.attributeArray.push(obj);
      }
      this.setState({updateUI: !this.state.updateUI});
    }
  };
  loadCategoryApi = async () => {
    let aType = this.props.type == 'Listings' ? 'listings' : 'accounts';
    let catData = {
      value: 0,
      parent: 0,
      label: 'Parent Category',
    };
    this.state.categoriesArray.push(catData);
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.categories + `${aType}&parent=0`,
      'get',
      '',
      '',
    );
    // console.log('categories', responseJson);
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(
              hierarchyD[j],
              `${listD[i]['name']}--> ${hierarchyD[j]['name']}`,
            );
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(
                  hierarchy2[k],
                  `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`,
                );
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(
                      hierarchy3[l],
                      `${hierarchy2[k]['name']}--> ${hierarchy3[l]['name']}`,
                    );
                  }
                }
              }
            }
          }
        }
      }
      // console.log('this.state.parentId', this.state.parentId)
      if (this.state.id) {
        if (this.state.categoriesArray[0]) {
          let index = this.state.categoriesArray.findIndex(
            x => x.value == this.state.backendParentID,
          );
          // console.log('index', index);
          this.didSelectDropDown(this.state.categoriesArray[index]);
        }
      } else if (this.state.parentId) {
        if (this.state.categoriesArray[0]) {
          let index = this.state.categoriesArray.findIndex(
            x => x.value == this.state.parentId,
          );
          if (index != -1) {
            this.didSelectDropDown(this.state.categoriesArray[index]);
          }
        }
      } else {
        if (this.state.categoriesArray.length != 0) {
          this.didSelectDropDown(this.state.categoriesArray[0]);
        }
      }
    }
    this.setState({dataLoad: true});
  };
  manageData(data, name) {
    // console.log('data => ', data);
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = '';
    var level = 1;
    var id = 0;
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`;
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> ';
          level = hierarchyAry[a]['level'];
          id = hierarchyAry[a]['id'];
        }
      }
    }
    hierarchyName = hierarchyName.length == 0 ? name : hierarchyName;
    // console.log('hierarchyName', hierarchyName);
    // console.log('level => ', level, id);
    let catData = {
      value: data['id'],
      parent: data['parent'],
      label: hierarchyName,
      level: level,
    };
    const catDt = this.props.categoryData;
    const isEdit = this.props.isEdit;

    this.state.localCat.push(catData);
    var neAray = [];
    let initData = {value: 0, parent: 0, label: 'Parent Category'};
    neAray.push(initData);
    // console.log('this.state.localCat', this.state.localCat);
    var existValue = false;
    if (isEdit) {
      for (let a = 0; a < this.state.localCat.length; a++) {
        let objc = this.state.localCat[a];
        if (catDt['id'] == objc['value']) {
          existValue = true;
        }
        if (existValue) {
          if (objc['parent'] == 0) {
            if (catDt['id'] == objc['value']) {
              existValue = true;
            } else {
              existValue = false;
              if (objc['level'] != 3) {
                neAray.push(objc);
              }
            }
          }
        } else {
          if (objc['level'] != 3) {
            neAray.push(objc);
          }
        }
      }
    } else {
      for (let a = 0; a < this.state.localCat.length; a++) {
        let objc = this.state.localCat[a];
        if (objc['level'] != 3) {
          neAray.push(objc);
        }
      }
    }
    // console.log('neAray', neAray);
    this.state.categoriesArray = neAray;
    // console.log('this.state.categoriesArray', this.state.categoriesArray);
  }
  addCategoryApi = async image => {
    let aType = this.props.type == 'Listings' ? 'listings' : 'accounts';
    var dict = {
      name: this.state.name,
      active: this.state.status,
      parent: this.state.selectedParentId,
      type: aType,
    };
    if (image.length != 0) {
      dict['image_path'] = image;
    }
    if (this.state.rank != 0) {
      dict['order_by'] = this.state.rank;
    }
    // if (this.state.slugValue.length != 0) {
    let slv =
      this.state.slugValue.length == 0 ? this.state.name : this.state.slugValue;
    let vslug = slugChecker(slv);
    dict['slug'] = vslug;
    // }
    if (this.state.categoryTypeField != 0) {
      dict['type'] = this.state.categoryTypeField;
    }
    if (this.state.categoryTypeField != 0) {
      dict['type'] = this.state.categoryTypeField;
    }
    if (this.state.descriptionString != 0) {
      dict['description'] = this.state.descriptionString;
    }
    if (this.state.metaTitle != 0) {
      dict['meta_title'] = this.state.metaTitle;
    }
    if (this.state.metaDescriptionString != 0) {
      dict['meta_description'] = this.state.metaDescriptionString;
    }
    if (this.state.categoryTypeField != 0) {
      dict['type'] = this.state.categoryTypeField;
    }
    if (this.state.slugValue.length != 0) {
      let vslug = slugChecker(this.state.slugValue);
      dict['slug'] = vslug;
    }
    var path = APPURL.URLPaths.addCategories;

    if (this.state.id !== '') {
      path = APPURL.URLPaths.addCategories + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({category: dict}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.customAlert();
      // if (this.state.id !== '') {
      this.props.backBtnAction(true);
      // } else {
      //   this.setState({ showNextViewBool: true })
      // }
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(
      `${this.state.id !== '' ? 'Edit' : 'Add'} ${this.props.type} Category`,
      properties,
    );
  }
  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'].replace(
            / /g,
            '',
          ),
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          this.addCategoryApi(result[0]['fileUri']);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.addCategoryApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };
  customAlert() {
    AppToast();
  }
  deleteAtrrApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes + '/' + id,
      'delete',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadAttributeApi(this.state.id);
    }
  };
  //MARK:-  Button Actions
  backBtnAction() {
    this.loadAttributeApi(this.state.id);
    this.setState({
      addAttributeBool: !this.state.addAttributeBool,
      editAttribute: false,
    });
  }
  closeBtnAction() {
    this.props.closeBtnAction(true);
    this.setState({
      addAttributeBool: !this.state.addAttributeBool,
      editAttribute: false,
    });
  }
  submitBtnAction = () => {
    this.setState({dataLoad: false});
    this.uploadImageAPI();
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
  };
  defaultToggle = () => {
    this.setState({default: !this.state.default});
  };
  onPick = image => {
    this.setState({selectedImage: image});
  };
  openDropDown = id => {
    this.setState({showDropDown: !this.state.showDropDown});
  };
  didSelectDropDown = item => {
    if (item['label']) {
      this.setState({
        showDropDown: false,
        selectedCategoryLevel: item['label'],
        selectedParentId: item['value'],
      });
    }
  };
  editButtonAction = index => {
    let id_Index = this.state.attributeArray.findIndex(x => x.id === index);
    this.state.selectedData = this.state.attributeArray[id_Index];
    this.setState({addAttributeBool: true, editAttribute: true});
    // this.props.editBtn(this.state.attributeArray[id_Index]);
  };
  deleteAttributeAction = index => {
    let msg = 'Do you want to go delete this Attribute?';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteAtrrApi(index);
      }
    }
  };
  showMediaBtnAction() {
    if (this.state.mediaArray != 0) {
      this.setState({showMediaManager: true});
    } else {
      AppToast('You dont have media file.');
    }
  }
  addAttributeBtnAction() {
    this.state.editAttribute = false;
    this.setState({addAttributeBool: true, showNextViewBool: false});
  }
  // UI Renders
  imagePicker = props => {
    ImagePicker.openPicker({
      width: 300,
      height: 300,
      cropping: true,
      includeBase64: true,
    }).then(image => {
      // console.log('image -=== ', image);
      this.setState({selectedImage: 'data:image/png;base64,' + image.data});
    });
  };
  imageView = props => {
    var view = [];
    view.push(
      <View style={{flexDirection: 'row'}}>
        <View style={commonStyle.addImageViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg', '.gif', '.svg']}
            base64={true}
            handleFiles={this.onPick}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Add Photo
            </Text>
          </ReactFileReader>
        </View>
        <View style={{width: 20, height: 20}} />
        <View style={commonStyle.addImageViewStyle}>
          <TouchableOpacity
            style={{width: 100}}
            onPress={() => this.showMediaBtnAction()}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Select from media
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{width: 20, height: 20}} />
      </View>,
    );
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      var showImage = true;
      var icon = jsonIcon;
      var fileExt = '';
      if (fileName == undefined) {
        let name = this.state.selectedImage.substring(
          this.state.selectedImage.lastIndexOf('/') + 1,
        );
        var fileExt = name.split('.').pop();
      }
      if (fileExt == 'json') {
        icon = jsonIcon;
        showImage = false;
      } else if (fileExt == 'pdf') {
        icon = pdfIcon;
        showImage = false;
      }
      view.push(
        <View>
          <View
            style={{
              width: itsMobileView ? 20 : 0,
              height: itsMobileView ? 20 : 0,
            }}
          />
          <Image
            // source={{ uri: fileName != null ? this.state.selectedImage.base64 : this.state.selectedImage }}
            source={
              showImage
                ? {
                    uri:
                      fileName != null
                        ? this.state.selectedImage.base64
                        : this.state.selectedImage,
                  }
                : icon
            }
            style={commonStyle.ImageViewStyle}
          />
          <TouchableOpacity
            style={CommonStyleSheet.cancelViewStyle}
            onPress={() => {
              this.setState({
                selectedImage: null,
              });
            }}>
            <Image
              source={ICONS.closeIcon}
              style={CommonStyleSheet.closeBtnStyle}
            />
          </TouchableOpacity>
        </View>,
      );
    }
    return (
      <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
        {view}
      </View>
    );
  };
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={this.state.categoriesArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>
            {item['label']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Text
          style={
            this.state.selectedCategoryLevel == ''
              ? commonStyle.placeholderTxtStyle
              : commonStyle.dropDowntxtFieldStyle
          }>
          {this.state.selectedCategoryLevel == ''
            ? 'Select'
            : this.state.selectedCategoryLevel}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? commonStyle.upArraowIconStyle
              : commonStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };

  renderAttributeHeaderView = () => {
    return (
      <View
        style={{
          paddingLeft: 10,
          height: 40,
          backgroundColor: colors.AppLightGreen,
          borderTopStartRadius: 5,
          borderTopEndRadius: 5,
          flexDirection: 'row',
        }}>
        <View style={{width: windowWidth / 3, alignItems: 'flex-start'}}>
          <Text style={tableStyle.tableViewTitleStyle}>{'Name'}</Text>
        </View>
        <View style={{width: windowWidth / 3, alignItems: 'flex-start'}}>
          <Text style={tableStyle.tableViewTitleStyle}>{'Status'}</Text>
        </View>
        <View style={{width: windowWidth / 3, alignItems: 'flex-start'}}>
          <Text style={tableStyle.tableViewTitleStyle}>{'Action'}</Text>
        </View>
      </View>
    );
  };
  renderAddAttributeBtn = () => {
    return (
      <View style={tableStyle.secondButtonViewStyle}>
        <TouchableOpacity
          style={tableStyle.nextPreviousViewStyle}
          onPress={() => this.addAttributeBtnAction()}>
          <Text style={tableStyle.secondBtnTextStyle}>{'Add Attribute'}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  renderAttributeView = () => {
    if (this.state.attributeArray.length != 0) {
      return (
        <View>
          <View
            style={{
              height: 1,
              backgroundColor: colors.SimonGray,
              marginTop: 16,
            }}
          />
          <View
            style={{
              justifyContent: 'space-between',
              marginTop: 16,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Text style={commonStyle.plainTextStyle}>Attributes List</Text>
            {this.renderAddAttributeBtn()}
          </View>
          <View
            style={{
              height: 1,
              backgroundColor: colors.SimonGray,
              marginTop: 16,
            }}
          />
          {this.renderAttributeHeaderView()}
          <View>
            <FlatList
              data={this.state.attributeArray}
              horizontal={false}
              renderItem={this.renderAttributeCell}
              extraData={this.state}
              showsVerticalScrollIndicator={false}
              scrollEnabled={true}
            />
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderAttributeCell = ({item}) => {
    var val = item['active'] ? 'Active' : 'Inactive';
    return (
      <View
        style={{
          flexDirection: 'row',
          padding: 10,
          borderBottomWidth: 1,
          borderBottomColor: colors.Lightgray,
        }}>
        <View style={{width: windowWidth / 3, alignItems: 'flex-start'}}>
          <Text style={tableStyle.tableViewTitleStyle}>{item['name']}</Text>
        </View>
        <View style={{width: windowWidth / 3, alignItems: 'flex-start'}}>
          <View>
            <Text
              style={
                item['active'] == true
                  ? tableStyle.columnActiveBtnStyle
                  : tableStyle.columnDeActiveBtnStyle
              }>
              {val}
            </Text>
          </View>
        </View>
        <View style={{width: windowWidth / 3, alignItems: 'flex-start'}}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.editButtonAction(item['id'])}>
              <Image
                style={tableStyle.iconStyle}
                resizeMode="center"
                source={edit_Icon}
              />
            </TouchableOpacity>
            <View style={{width: 10}} />
            <TouchableOpacity
              onPress={() => this.deleteAttributeAction(item['id'])}>
              <Image
                style={tableStyle.iconStyle}
                resizeMode="center"
                source={deleteIcon}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9920,
          }}>
          <MediaManagerView
            mediaData={this.state.mediaArray}
            closeBtn={() => this.setState({showMediaManager: false})}
            onSelectBtn={path =>
              this.setState({showMediaManager: false, selectedImage: path})
            }
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSlugField = () => {
    return (
      <View>
        <TextInput
          style={[commonStyle.fulltxtFieldStyle, {outline: 0}]}
          placeholder="Enter slug "
          placeholderTextColor={colors.placeholderColor}
          onChangeText={txt => this.setState({slugValue: txt})}
          value={this.state.slugValue}
        />
      </View>
    );
  };
  renderMetaDataView = () => {
    return (
      <View>
        <View
          style={{display: this.state.showMetaDescription ? 'flex' : 'none'}}>
          <View style={commonStyle.subViewStyle}>
            <Text style={commonStyle.titleNameStyle}>Meta Title</Text>
            <View style={{width: itsMobileView ? '100%' : '80%'}}>
              <TextInput
                style={[commonStyle.fulltxtFieldStyle, {outline: 0}]}
                placeholder="Enter meta title "
                placeholderTextColor={colors.placeholderColor}
                onChangeText={txt => this.setState({metaTitle: txt})}
                value={this.state.metaTitle}
              />
            </View>
          </View>
        </View>
        <View
          style={{display: this.state.showMetaDescription ? 'flex' : 'none'}}>
          <View style={commonStyle.subViewStyle}>
            <Text style={commonStyle.titleNameStyle}>Meta Description</Text>
            <View style={{width: itsMobileView ? '100%' : '80%'}}>
              <DescriptionView
                value={this.state.metaDescriptionString}
                onChange={txt => this.setState({metaDescriptionString: txt})}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };
  renderAlertView = () => {
    return (
      <CustomAlertView
        showNextViewBool={this.state.showNextViewBool}
        titleValue={'Nice 🎉, Successfully added category'}
        nextBtnTitle={'Add Attribute'}
        closeBtnAction={() => this.closeBtnAction()}
        nextBtnAction={() => this.addAttributeBtnAction()}
      />
    );
  };
  render() {
    const isEdit = this.props.isEdit;
    if (this.state.translationBool) {
      return (
        <Translations
          title={'Category'}
          backBtnAction={() => this.setState({translationBool: false})}
          data={this.props.categoryData}
        />
      );
    } else if (this.state.addAttributeBool) {
      return (
        <ScrollView style={{flexDirection: 'column'}}>
          <AddAttribute
            type={this.props.type}
            isEdit={this.state.editAttribute}
            attributeData={this.state.selectedData}
            categoryID={this.state.id}
            closeBtnAction={() => this.closeBtnAction()}
            backBtnAction={() => this.backBtnAction()}
          />
        </ScrollView>
      );
    } else {
      return (
        <>
          <SafeAreaView style={styles.Container}>
            {this.renderAlertView()}
            <Header_View
              title={isEdit ? 'Edit Category' : 'Add Category'}
              backBtn={this.props.backBtnAction}
              isClose={true}
              saveBtnTitle={'Save'}
              saveBtn={() => this.submitBtnAction()}
              secondary_btnAction={() =>
                this.props.navigate(`translation?ct_id=${this.state.id}`)
              }
              secondary_btnTitle={'Translate'}
              secondary_showBtn={isEdit}
            />
            <View style={{height: windowHeight / 1.16}}>
              <ScrollView>
                <View style={commonStyle.mainView}>
                  {this.mediaManagerView()}
                  <View style={{height: 20, width: '100%'}} />
                  <View style={styles.subViewStyle}>
                    <this.titleLblRender title={'Select Category Level'} />
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      <TouchableOpacity
                        style={styles.dropDownViewStyle}
                        onPress={() => this.openDropDown(2)}>
                        <this.renderValueLabel id={2} />
                        <this.customDropDown id={2} />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <View style={commonStyle.subViewStyle}>
                    <this.titleLblRender title={'Category Name'} />
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      <TextInput
                        style={[commonStyle.fulltxtFieldStyle, {outline: 0}]}
                        value={this.state.name}
                        placeholder="Enter category name"
                        placeholderTextColor={colors.placeholderColor}
                        onChangeText={name => this.setState({name: name})}
                      />
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>Slug</Text>
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      {this.renderSlugField()}
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>
                      Category Description
                    </Text>
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      <DescriptionView
                        value={this.state.descriptionString}
                        onChange={txt =>
                          this.setState({descriptionString: txt})
                        }
                      />
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>Order</Text>
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      <TextInput
                        style={[commonStyle.fulltxtFieldStyle, {outline: 0}]}
                        value={this.state.rank}
                        placeholder="Enter order"
                        placeholderTextColor={colors.placeholderColor}
                        onChangeText={txt =>
                          this.setState({rank: onHandleNumberFieldChange(txt)})
                        }
                      />
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>
                      Status(Not Active / Active){' '}
                    </Text>
                    <View style={{width: itsMobileView ? '12%' : '80%'}}>
                      <Switch
                        onColor={colors.AppNewGreen}
                        onChange={() =>
                          this.setState({status: !this.state.status})
                        }
                        checked={this.state.status}
                        height={22}
                        width={50}
                      />
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>Upload Media</Text>
                    <View style={{width: '80%'}}>
                      <this.imageView />
                    </View>
                  </View>
                  <View style={{height: 16}} />
                  <this.renderMetaDataView />
                  <View style={{height: 16}} />
                  <View style={{padding: 20, width: '100%'}}>
                    {this.renderAttributeView()}
                  </View>
                  <View style={{height: 0, width: '100%'}} />
                </View>
              </ScrollView>
            </View>

            <Appload enable={this.state.dataLoad} />
          </SafeAreaView>
          <SidePan
            openSidepan={this.state.translationDescBool}
            closeSipan={() =>
              this.setState({
                translationDescBool: false,
                selectedData: '',
              })
            }
            sidepan_width={windowWidth - 270}>
            <CategoryDescriptionTranslate
              translationTitle={'Category Description'}
              titleString={this.props.type}
              translationRef={TranslateEnum.category}
              backBtnAction={() => this.setState({translationDescBool: false})}
            />
          </SidePan>
        </>
      );
    }
  }
}

export default withRouter(AddCategory);

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  addAttributeGradientStyle: {
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    height: 40,
    width: 120,
    borderRadius: 10,
    justifyContent: 'center',
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 10292,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: itsMobileView ? '100%' : '100%',
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 10292,
  },
});
