import ToastPopup from '../../../HelperClasses/AppLoaderClasses/ToastPopup';

export const checkGroupForm = ({form_data}) => {
  if (
    form_data?.name === '' ||
    form_data?.name?.replace(/\s/g, '').length <= 0
  ) {
    ToastPopup({type: 'error', message: 'Name is required'});
    return false;
  }

  return true;
};
