import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Dimensions,
  TextInput,
  ScrollView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import {AppToast} from '../../HelperClasses/AppToast';
import networkService from '../../Network/NetworkManager';
import {addMixpanelEvent, MixpanelEvent} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SidePanView from '../../Component/SidePanView';
import SaveButton from '../../Component/SaveButton';
import SecondryButton from '../../Component/SecondryButton';
import ToolTipViewBtn from '../../Component/ToolTipViewBtn';
import AppConstantsMessage from '../../Constants/AppConstantsMessage';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');
//'Subscription', 'Subscription Receipt'
const titleArray = [
  'Welcome Message Template',
  'OTP Message Template',
  'Subscription Link Template',
  'Subscription Success Template',
];
const mergeTags = [
  '{first_name} {last_name}',
  '{first_name} {last_name} {code}',
  '{first_name} {last_name} {link}',
  '{first_name} {last_name} {product_name} {amount} {manage_link}',
];

const KeyGroup = 'email';
const keysTitleArray = [
  'welcome_email_title',
  'verification_email_title',
  'subscription_email_subject_template',
  'subscription_success_email_subject_template',
];
const keysBodyArray = [
  'welcome_email_body_template',
  'verification_email_body_template',
  'subscription_email_body_template',
  'subscription_success_email_body_template',
];

const windowHeight = Dimensions.get('window').height;

export default class EditEmailNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      statusArray: [false, false, false, false],
      updateUI: false,
      bodyValue: '',
      showEditor: this.props.showEditor,
      editorIndex: 0,
      subjectTitle: '',
      dataLoad: false,
      configData: {},
      emailTest: '',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    success: PropTypes.func,
    editEmail: PropTypes.func,
    showEditor: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + KeyGroup,
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      this.state.configData = responseJson['data']['configs'];
      this.state.subjectTitle =
        this.state.configData[keysTitleArray[this.props.editorIndex]] ?? '';
      this.state.bodyValue =
        this.state.configData[keysBodyArray[this.props.editorIndex]] ?? '';
      console.log('this.state.configs =>', this.state.configData);
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateApi = async () => {
    this.setState({dataLoad: false});
    var configs = [];
    if (this.state.bodyValue.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: keysBodyArray[this.props.editorIndex],
        value: this.state.bodyValue,
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.subjectTitle.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: keysTitleArray[this.props.editorIndex],
        value: this.state.subjectTitle,
        secured: false,
      };
      configs.push(fDict);
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );
    console.log('emailNotification', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.emailNotification, {success: true});
      AppToast();
      this.props.success(true);
    } else {
      addMixpanelEvent(MixpanelEvent.emailNotification, {success: false});
      this.setState({dataLoad: true});
      this.props.success(false);
    }
  };
  resetToDefaultApi = async () => {
    this.setState({dataLoad: false});
    let urlParm =
      APPURL.URLPaths.config +
      '?key=' +
      `${keysBodyArray[this.props.editorIndex]},${
        keysTitleArray[this.props.editorIndex]
      }`;
    const responseDeleteJson = await networkService.networkCall(
      urlParm,
      'delete',
      '',
      '',
    );

    if (responseDeleteJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.emailNotification, {success: true});
      AppToast();
      this.props.success(true);
    } else {
      addMixpanelEvent(MixpanelEvent.emailNotification, {success: false});
      this.setState({dataLoad: true});
      this.props.success(false);
    }
  };
  testEmailApi = async () => {
    this.setState({dataLoad: false});
    let emailAry = this.state.emailTest.split(',');
    let fDict = {
      subject: this.state.subjectTitle,
      body: this.state.bodyValue,
      email: emailAry,
    };
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.testEmail,
      'post',
      JSON.stringify(fDict),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.emailNotificationTest, {success: true});
      AppToast(undefined, 'Sent');
    } else {
      addMixpanelEvent(MixpanelEvent.emailNotificationTest, {success: false});
      this.setState({dataLoad: true});
      AppToast(responseJson);
    }
  };
  //MARK:-  Buttons Action Method 
  submitButtonAction() {
    let bodyValue = this.state.bodyValue;
    if (this.state.subjectTitle.length == 0) {
      AppToast('Subject should not be empty');
    } else if (bodyValue.length == 11) {
      AppToast('body should not be empty');
    } else {
      if (this.props.editorIndex == 1) {
        if (bodyValue.includes('{code}')) {
          this.addOrUpdateApi();
        } else {
          AppToast('{code} merge tag is mandatory');
        }
      } else {
        this.addOrUpdateApi();
      }
    }
  }
  sendTestBtnAction() {
    if (this.state.emailTest.length != 0) {
      this.testEmailApi();
    }
  }
  renderSaveConfigBtn = () => {
    return (
      <View style={{flexDirection: 'row'}}>
        <SaveButton
          saveBtn={() => this.submitButtonAction()}
          saveBtnTitle={'Update'}
        />
      </View>
    );
  };
  renderResetConfigBtn = () => {
    return (
      <View style={{flexDirection: 'row'}}>
        <SecondryButton
          btnAction={() => this.resetToDefaultApi()}
          btnTitle={'Reset to default'}
        />
      </View>
    );
  };
  renderModelView = () => {
    return (
      <View>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
          }}>
          <View style={CommonStyleSheet.sidepanSaveBtnViewStyle}>
            {this.renderResetConfigBtn()}
          </View>
          <View style={CommonStyleSheet.sidepanSaveBtnViewStyle}>
            {this.renderSaveConfigBtn()}
          </View>
        </div>
        <View style={styles.modalViewStyle}>
          <View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Subject Line</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                value={this.state.subjectTitle}
                placeholder="Enter the Subject"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={subject => this.setState({subjectTitle: subject})}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Available merge tags:
                <Text style={CommonStyleSheet.plainSubTextStyle}>
                  {`  `}
                  {mergeTags[this.props.editorIndex]}
                </Text>
              </Text>
            </View>
            <View style={{marginTop: 20}}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={appConfigStyle.titleTxtStyle}>Body</Text>
                <ToolTipViewBtn
                  title={AppConstantsMessage.email_body}
                  description={AppConstantsMessage.email_body_info}
                />
              </View>
              <TextInput
                style={[
                  appConfigStyle.descriptionTxtFieldStyle,
                  {outline: 0, height: windowHeight / 3},
                ]}
                placeholder="Write here..."
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({bodyValue: name})}
                value={this.state.bodyValue}
                multiline={true}
              />
            </View>
            <View style={{marginTop: 20, flex: 1}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Enter test emails
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <TextInput
                  style={[
                    appConfigStyle.txtFieldStyle,
                    {outline: 0, width: '100%'},
                  ]}
                  value={this.state.emailTest}
                  placeholder="Enter multiple emails with comma"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={subject => this.setState({emailTest: subject})}
                />
                <View
                  style={{
                    marginLeft: 20,
                    height: 40,
                    marginTop: 20,
                    marginRight: -10,
                  }}>
                  <SecondryButton
                    btnTitle={'Send test'}
                    btnAction={() => this.sendTestBtnAction()}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  };

  render() {
    return (
      <View>
        <this.renderModelView />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  modalViewStyle: {
    // backgroundColor: colors.AppWhite,
    // position: 'absolute',
    // borderColor: colors.SimonGray,
    // borderWidth: 1,
    margin: 10,
    // borderRadius: 10,
    // padding: 10,
    // width: '100%',
  },
});
