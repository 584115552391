import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import DeliveryStatusEnum from '../../Models/DeliveryStatusEnum';
import GoogleMapReact from 'google-map-react';
import AppConstants from '../../Constants/AppConstants';
import { dateConversionFromString, dateConversionFromTimeStamp } from '../../HelperClasses/SingletonClass';
import closeIcon from '../../assets/closeBlackIcon.png';
import { Gradient } from 'react-gradient';
import { AppToast } from '../../HelperClasses/AppToast';
import { ToastContainer } from 'react-toastify';
import radioCheckIcon from '../../assets/radioCheck.png';
import radioUncheckIcon from '../../assets/radioUncheck.png';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { addMixpanelEvent, MixpanelEvent } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
var converter = require('number-to-words');

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height - 120;
let fixedWidth = AppConstants.mobileMaxWidth
let dateFormat = 'yyyy-MM-DD HH:mm:ss'


export default class DeliveryOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      orderDetailData: {},
      orderDetailArray: [],
      createdDate: '',
      customerName:'',
      customerMobile:'',
      deliveryAddress: '',
      deliveryCoordinates: '',
      branchName: '',
      totalAmount: '',
      wordsAmount: '',
      statusArray: [],
      viewListingDetailBool:false,
      selectedID:'',
      deliveryType: '',
      paymentType: '',
      showChangeOrderStatusView:false,
      orderStatus:1,
      slotFromDate:'',
      slotToDate:'',
      slotDatePicker:false,
      driverName:'',
      driverMobile:'',
      branchId:'',
      driversArray:[],
      showAssignDriverBool:false,
      driverID: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    orderID: PropTypes.string,
  };
  componentDidMount() {
    addMixpanelEvent(`${MixpanelEvent.deliveryOrderDetail}`)
    this.loadOrderDetailApi()
  }
  loadOrderDetailApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryOrder + `${this.props.orderID}`, 'get');
    if (responseJson['status'] == true) {
      this.setState({orderDetailData: responseJson['data']['order']})
      let dt = this.state.orderDetailData['created_at'];
      // console.log('orders order Detail', this.state.orderDetailData)
      if (this.state.orderDetailData['driver']){
        if (this.state.orderDetailData['driver'] != null){
          this.state.driverName = this.state.orderDetailData['driver']['name']
          this.state.driverMobile = this.state.orderDetailData['driver']['mobile']
        }
      }
      this.state.deliveryType = DeliveryStatusEnum.deliveryType(this.state.orderDetailData['delivery_type'])
      this.state.paymentType = DeliveryStatusEnum.paymentType(this.state.orderDetailData['payment_type'])

      this.state.statusArray = this.state.orderDetailData['status_history']
      this.setState({
        orderStatus:this.state.orderDetailData['status'],
        createdDate: dateConversionFromTimeStamp(dt),
        totalAmount:`${this.state.orderDetailData['currency']} ${this.state.orderDetailData['amount']}` ,
        wordsAmount: converter.toWords(this.state.orderDetailData['amount']),
        customerName:this.state.orderDetailData['customer']['name'],
        customerMobile:this.state.orderDetailData['customer']['mobile'],
        branchName:this.state.orderDetailData['branch']['name'],
        branchId:this.state.orderDetailData['branch']['branch_id'],
        deliveryAddress:this.state.orderDetailData['address']['formatted_address'],
        deliveryCoordinates:this.state.orderDetailData['address']['coordinates'],
      })
        let cpWrd = this.state.wordsAmount.replace(/\b\w/g, (c) => c.toUpperCase())
        this.state.wordsAmount = `${this.state.orderDetailData['currency']} ${cpWrd}`
        this.loadDriverApi()
    
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  }
  updateStatusApi = async (statusValue) => {
    this.setState({dataLoad: false})
    let path = `${APPURL.URLPaths.deliveryOrder}${this.props.orderID}/status`
    var dict = {'status':statusValue}
    if (this.state.slotFromDate.length != 0 && this.state.slotToDate.length != 0){
      let from = Moment(this.state.slotFromDate).utc().format('yyyy-MM-DDTHH:mm:ss')
      let to = Moment(this.state.slotToDate).utc().format('yyyy-MM-DDTHH:mm:ss')
      dict['delivery_slot_from'] = from+'Z'
      dict['delivery_slot_to'] = to+'Z'
    }
    let statusDic = JSON.stringify({order:dict})
    const responseJson = await networkService.networkCall(path, 'PATCH', statusDic, '')
    // console.log('responseJson', responseJson)
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      this.mixpanelUpdateStatus(true)
      this.loadOrderDetailApi()
    }  else {
      this.mixpanelUpdateStatus(false)
      AppToast(responseJson)
    }
  }
  mixpanelUpdateStatus(resp){
    addMixpanelEvent(MixpanelEvent.deliveryOrderUpdateStatus,{'success':resp})
  }
  loadDriverApi = async () => {
    this.setState({ dataLoad: false });
    var path = `?page=${1}&per_page=${100}&type=driver&branch_id=${this.state.branchId}`
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriver + path, 'get')
    this.setState({ driversArray: [] });
    if (responseJson['status'] == true) {
      var driverD = responseJson['data']['users']
      this.setState({ stopPage: false });
      for (let objc of driverD) {
        this.state.driversArray.push(objc);
      }
    }
    this.setState({ dataLoad: true });
  }
  assignDriverAPI = async () => {
    this.setState({ dataLoad: false })
    let dict = {'driver_id': this.state.driverID}
    let statusDic = JSON.stringify({order:dict})
    let path =  APPURL.URLPaths.deliveryOrder + `${this.props.orderID}/assign_driver`
    const responseJson = await networkService.networkCall(path, 'PATCH',statusDic)
    this.setState({ dataLoad: true })
    if (responseJson['status'] == true) {
      this.mixpannelAssignDriver(true)
      this.loadOrderDetailApi()
      AppToast()
    }else {
      this.mixpannelAssignDriver(false)
      AppToast(responseJson)
    }
    this.setState({dataLoad: true})
  }
  mixpannelAssignDriver(resp){
    let properties = { success:resp }
    addMixpanelEvent(MixpanelEvent.assignDriver,properties)
  }
  // Action Buttons

  updateOrderStatusBtnAction() {
    this.updateStatusApi(this.state.orderStatus)
    this.setState({showChangeOrderStatusView: false});
  }
  assignDriverBtnAction() {
    this.assignDriverAPI()
    this.setState({showAssignDriverBool: false})
  }
  didSelectOrderStatus(id) {
    if (this.state.orderStatus == id) {
      this.setState({orderStatus: this.state.orderDetailData['status']})
    }else {
      this.setState({orderStatus:id})
    }
  }
  didSelectAssignDriver(id) {
    if (this.state.driverID == id) {
      this.setState({driverID: ''})
    }else {
      this.setState({driverID:id})
    }
  }
  closeOrderStatusViewBtnAction() {
    this.setState({orderStatus: this.state.orderDetailData['status'], driverID: ''})
    this.setState({showChangeOrderStatusView:false,showAssignDriverBool:false})
  }
  // UI Renders
  titleLblRender = ({first, second}) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text style={appConfigStyle.titleTxtStyle}>{`${first} `}</Text>
        <Text style={{marginTop: 2}}>{second}</Text>
      </View>
    );
  };
  renderDetails = ({title, name, phoneNo}) => {
    return (
      <View>
        <Text style={appConfigStyle.titleTxtStyle}>{title}</Text>
        <View style={{height: 10}}/>
        <Text >{name}</Text>
        <View style={{height: 10}}/>
        <Text>{phoneNo}</Text>
      </View>
    )
  }
  renderStatusOrderValue = () => {
    var views = [];
    for (let a = 0; a < this.state.statusArray.length; a++) {
      let date = dateConversionFromTimeStamp(this.state.statusArray[a]['created_at']);
      let status = DeliveryStatusEnum.status(this.state.statusArray[a]['status']);
      views.push( 
      <View style={{borderRadius: 10}}>
        <View style={{ flexDirection:'row', justifyContent: 'space-between',width: '40%', marginLeft: 20}}>
          <Text style={{marginTop: 10}}>{status}</Text>
          <Text style={{marginTop: 10, textAlign: 'left'}}>{date}</Text>
        </View>
        <View style={{backgroundColor: colors.SimonGray, height: 1, marginTop: 10}}/>
      </View>
      );
    }
    return views;
  }
  renderGoogleMap = () => {
    var marker = []
    if (this.state.deliveryCoordinates['latitude']) {
      let lat = Number(this.state.deliveryCoordinates['latitude'].toFixed(3))
      let long = Number(this.state.deliveryCoordinates['longitude'].toFixed(3))
      marker.push(<View lat={lat} lng={long} 
        style={{ backgroundColor: colors.LightGreen }}>
        <View style={{ height: 40, width: 40, borderRadius: 20, backgroundColor: colors.AppNewGreen,alignItems:'center', justifyContent:'center' }}>
          <View style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: colors.BGBlueColor }} />
        </View>
      </View>)
    return (<View style={{ height: 300, width: 300 }}>
      <GoogleMapReact
        style={{ borderRadius: 10 }}
        bootstrapURLKeys={{ key: AppConstants.googleMapAPIKey }}
        defaultCenter={{lat: lat ?? '30.76', lng:long ?? '76.68'}}
        defaultZoom={7}
        yesIWantToUseGoogleMapApiInternals>
        {marker}
      </GoogleMapReact>
    </View>)
    } else {
      return <View />
    }
  }
  renderUpdateBtn = () => {
    var title = this.state.driverName.length == 0 ? 'Assign Driver' : 'Change Driver'
     title = this.state.showAssignDriverBool ? title : 'Change Order Status'
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity 
          onPress={() => this.state.showAssignDriverBool ? this.assignDriverBtnAction() : this.updateOrderStatusBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{title}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderRescheduleView = () => {
    let strtD = this.state.slotFromDate.length == 0 ? 'Select Date' : Moment(this.state.slotFromDate).format(dateFormat)
    let endD = this.state.slotToDate.length == 0 ? 'Select Date' : Moment(this.state.slotToDate).format(dateFormat)
    return <View style={{ width: 200, display: this.state.orderStatus == 11 ? 'flex' : 'none', marginTop:20}}>
      <View style={{ zIndex: 126 }}>
        <View style={{height:10}}/>
        <Text style={CommonStyleSheet.subTitleStyle}>Slot From</Text>
        <View style={[tableStyle.dateTextField,{marginLeft:0}]}>
          <DatePicker
            selected={this.state.slotFromDate}
            onChange={(date) => this.setState({ slotFromDate: date})}
            dateFormat="yyyy-mm-ddThHH:mm:ss"
            showTimeInput={true}
            onCalendarOpen={() => this.setState({slotDatePicker:false})}
            customInput={<View style={{ height: 20, width: 160 }}>
              <Text style={CommonStyleSheet.subTitleStyle}>{endD}</Text>
            </View>}
          />
        </View>
      </View>
      <View style={{zIndex: this.state.slotDatePicker ? 130 : 120}}>
      <View style={{height:10}}/>
      <Text style={CommonStyleSheet.subTitleStyle}>Slot To</Text>
        <View style={[tableStyle.dateTextField,{marginLeft:0}]}>
          <DatePicker
            selected={this.state.slotToDate}
            onChange={(date) => this.setState({ slotToDate: date})}
            onCalendarOpen={() => this.setState({slotDatePicker:true})}
            dateFormat="yyyy-mm-ddThHH:mm:ss"
            showTimeInput={true}
            customInput={<View style={{ height: 20, width: 160 }}> 
              <Text style={CommonStyleSheet.subTitleStyle}>{endD}</Text>
            </View>}
          />
        </View>
      </View>
    </View>
  }
  renderChangeOrderSatatusView = () => {
    var views = []
    if (this.state.showAssignDriverBool) {
      for (let a = 0; a < this.state.driversArray.length; a++) {
        let item = this.state.driversArray[a]
        let name = item['name']
        let check = this.state.driverID == item['id'] ? radioCheckIcon : radioUncheckIcon
        views.push(<View>
          <TouchableOpacity style={styles.statusItemViewStyle} onPress={() => this.didSelectAssignDriver(item['id'])}>
            <Text style={commonStyle.plainSubTextStyle}>{name}</Text>
            <Image style={{ height: 16, width: 16 }} source={check} />
          </TouchableOpacity>
        </View>)
      }
    } else {
      if (this.state.orderDetailData['next_status']) {
        let nextStatus = this.state.orderDetailData['next_status']
        for (let a = 0; a < nextStatus.length; a++) {
          let name = DeliveryStatusEnum.status(nextStatus[a])
          let check = this.state.orderStatus == nextStatus[a] ? radioCheckIcon : radioUncheckIcon
          views.push(<View>
            <TouchableOpacity style={styles.statusItemViewStyle} onPress={() => this.didSelectOrderStatus(nextStatus[a])}>
            <Text style={commonStyle.plainSubTextStyle}>{name}</Text>
              <Image style={{ height: 16, width: 16 }} source={check} />
            </TouchableOpacity>
          </View>)
        }
      }
    }
    let title = this.state.showAssignDriverBool ? 'Assign Driver' : 'Change Order Status'
    var check = false
    if (this.state.showAssignDriverBool) {
       check = this.state.showAssignDriverBool
    } else {
       check = this.state.showChangeOrderStatusView 
    }
    return <View style={[tableStyle.passwordViewStyle, { display:check ? 'flex' : 'none' }]}>
        <View style={{ backgroundColor: colors.AppWhite, paddingBottom:10, width: 300, borderRadius: 10,alignSelf:'center'}}>
          <View style={{ margin: 16 }}>
            <View style={{flexDirection:'row', justifyContent: 'space-between'}}>
              <Text style={commonStyle.plainTextStyle}>{title}</Text>
              <TouchableOpacity onPress={() => this.closeOrderStatusViewBtnAction()}>
                <Image style={{height:20,width:20, marginTop:2}} source={closeIcon}/> 
              </TouchableOpacity>
            </View>
            <View style={{ marginTop: 30 }}>
              {views}
            </View>
            <View style={{zIndex:1022,display: this.state.showAssignDriverBool ? 'none' : 'flex'}}>
              {this.renderRescheduleView()}
            </View>
            <View style={{ alignSelf: 'center', margin: 16, marginTop: 50, paddingLeft: 10,zIndex:10 }}>
              {this.renderUpdateBtn()}
            </View>
          </View>
        </View>
    </View>
  }
  renderChangeDriverBtn = () => {
    let showUpdateBtn = this.state.orderDetailData['next_status'] ? this.state.orderDetailData['next_status'].length != 0 ? true :false : false
    let title = this.state.driverName.length == 0 ? 'Assign Driver' : 'Change Driver'
    return <View style={[tableStyle.gradientViewBGStyle,{width:140,marginTop:20, display: showUpdateBtn ? 'flex': 'none'}]}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.setState({showAssignDriverBool:true})}>
          <Text style={tableStyle.saveTxtStyle}>{title}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  render() {
      let showUpdateBtn = this.state.orderDetailData['next_status'] ? this.state.orderDetailData['next_status'].length != 0 ? true :false : false
      return (
        <SafeAreaView style={styles.Container}>
          {this.renderChangeOrderSatatusView()}
          <ToastContainer />
          <Header_View
            title={'Order Detail'}
            backBtn={this.props.backBtnAction}
            showSaveBtn={showUpdateBtn}
            saveBtnTitle={'Update Order Status'}
            saveBtn={() => this.setState({showChangeOrderStatusView: true})}
          />
          <ScrollView style={{ height: windowHeight }}>
            <View style={commonStyle.mainView}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', margin: 20 }}>
                <View style={{ margin: 5 }}>
                  <this.titleLblRender first={'Order Number:'} second={this.state.orderDetailData['order_reference']} />
                  <View style={{ height: 16 }} />
                  <this.titleLblRender first={'Order Date:'} second={this.state.createdDate} />
                  <View style={{ height: 16 }} />
                  <this.titleLblRender first={'Order Status:'} second={DeliveryStatusEnum.status(this.state.orderDetailData['status'])} />
                  <View style={{ height: 16 }} />
                  <this.titleLblRender first={'Payment Type:'} second={this.state.paymentType} />
                  <View style={{ height: 16 }} />
                  <this.titleLblRender first={'Delivery Type:'} second={this.state.deliveryType} />
                </View>
                <View>
                  {this.renderGoogleMap()}
                </View>
              </View>
              <View style={{ height: 1, backgroundColor: colors.Lightgray, margin: 20 }} />
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 20, marginLeft: 20 }}>
                <View style={{ margin: 5, width: '25%' }}>
                  <this.renderDetails
                    title={'Customer Details:'}
                    name={this.state.customerName}
                    phoneNo={this.state.customerMobile} />
                </View>
                <View style={styles.subViewStyle}>
                  <this.renderDetails
                    title={'Branch Details:'}
                    name={this.state.branchName}
                  />
                </View>
                <View style={styles.subViewStyle}>
                  <this.renderDetails
                    title={'Driver Detail:'}
                    name={this.state.driverName}
                    phoneNo={this.state.driverMobile}
                  />
                  {this.renderChangeDriverBtn()}
                </View>
                <View style={styles.subViewStyle}>
                  <this.renderDetails
                    title={'Delivery Address:'}
                    name={this.state.deliveryAddress}
                    phoneNo={``}
                  />
                </View>
              </View>
              <View style={styles.subConatinerView}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <View style={styles.columnTitleViewStyle}>
                    <View style={{ height: 10 }} />
                    <Text style={appConfigStyle.titleTxtStyle}>Total Amount:</Text>
                    <View style={{ height: 10 }} />
                  </View>
                  <View style={styles.columnTitleViewStyle}>
                    <Text style={CommonStyleSheet.amountTxtStyle}>{this.state.totalAmount}</Text>
                  </View>
                </View>
                <View style={{ height: 1, backgroundColor: colors.Lightgray }} />
                <View style={{ flexDirection: 'row',justifyContent: 'space-between' }}>
                  <View style={styles.columnTitleViewStyle}>
                    <View style={{ height: 10 }} />
                    <Text style={appConfigStyle.titleTxtStyle}>Amount in Words:</Text>
                    <View style={{ height: 10 }} />
                  </View>
                  <View style={styles.columnTitleViewStyle}>
                    <Text style={CommonStyleSheet.amountTxtStyle}>{this.state.wordsAmount}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.subConatinerView}>
                <View style={{ height: 40, backgroundColor: colors.AppLightGreen, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '40%', marginLeft: 20 }}>
                    <Text style={[CommonStyleSheet.amountTxtStyle,{marginTop: 10,color:colors.AppTitleBlack }]}>{'Status'}</Text>
                    <Text style={[CommonStyleSheet.amountTxtStyle,{marginTop: 10, marginRight: 55,color:colors.AppTitleBlack}]}>{'Date'}</Text>
                  </View>
                </View>
                <this.renderStatusOrderValue />
              </View>
            </View>
          </ScrollView>
          <View style={{ height: 50 }} />
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      )
    }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  subViewStyle: {
    margin: 5,
    borderLeftWidth: 1,
    borderLeftColor: colors.Lightgray,
    paddingLeft: 10,
    paddingBottom: 10,
    width: '25%',
  },
  subConatinerView: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    marginTop: 40,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / 6.8,
    height: 40,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / 6.8,
    paddingLeft: 16,
  },
  lastViewStyle: {
    padding: 16,
    flexDirection: 'column',
    width: 300,
  },

  statusItemViewStyle:{
    margin:5, 
    justifyContent:'space-between',
     flexDirection:'row'
  },
});
const tableHeaderString = [
  'Description',
  'Unit Price',
  'Discount',
  'Qty',
  'Tax Rate',
  'Total Amount',
];