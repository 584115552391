import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';
const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'sms';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false



export default class TwiloIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      accountSID: '',
      twilioAuthToken: '',
      sourcePhoneNumber: '',
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=sms',
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.setState({
        accountSID: configs['twilio_account_sid'] || '',
        twilioAuthToken: configs['twilio_auth_token'] || '',
        sourcePhoneNumber: configs['twilio_phone_number'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    let valueArray = [
      this.state.accountSID,
      this.state.twilioAuthToken,
      this.state.sourcePhoneNumber,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: true,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      mobileIntegrationMixPanelEvent(MixpanelEvent.twilioIntegration)
      this.deleteKeysAPI(configs);
      //this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = []
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q])
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString()
      let urlParm = APPURL.URLPaths.config + '?key=' + parms
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    } else {
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    }
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // console.log('calling api');
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Twilio Integration</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Account SID</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter account SID"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({accountSID: name})}
                value={this.state.accountSID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Auth token</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter auth token"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({twilioAuthToken: name})}
                value={this.state.twilioAuthToken}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Source phone number
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter source phone number"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({sourcePhoneNumber: name})}
                value={this.state.sourcePhoneNumber}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'twilio_account_sid',
  'twilio_auth_token',
  'twilio_phone_number',
];
