export const instructions = {
  listing_category: {
    system:
      'You will be provided with a what they want to build and launch and your task is to generate category names.Give the instructions in Number List or Bullet points. ',
    user: `“What do you want to build and launch” the answer is <expectations>`,
  },
  listing_attribute: {
    system:
      'You will be provided with what they want to build and launch, and your task is to generate ATTRIBUTE(custom field) suggestion. Custom field can be used for good listing details page, also used for filtering, etc',
    user: '“What do you want to build and launch” the answer is <expectations>',
  },
  listing_variant: {
    system:
      'The user will provide the necessary information for creating listing variants. Based on this input, give them to some examples following fields correctly.',
    user: 'Please provide the details for  listing variant. Start by entering a unique variant type (e.g., Color, Size, Material). Next, specify the values under this type (e.g., for Color: Red, Blue, Green). You can add multiple values by separating them with commas. Ensure that the variant type and at least one value are provided, as these are required fields.',
  },
  account_category: {
    system:
      'You will be provided with a what they want to build and launch and your task is to generate category names for listings.Give the instructions in Number List or Bullet points. Account Categories are like like store types (Individual, Private Limited, Personal, Team, Company, etc)',
    user: `“What do you want to build and launch” the answer is <expectations>`,
  },
  account_attribute: {
    system:
      'You will be provided with what they want to build and launch, and your task is to generate ATTRIBUTE(custom field) suggestion. Custom field can be used for good account details page, also used for filtering, etc',
    user: '“What do you want to build and launch” the answer is <expectations>',
  },
};
