import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Dimensions,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import error_Icon from './../../assets/errorIcon.png';
import grayTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import EmailNotification from './EmailNotifications';
import FeedbackIntegration from './FeedbackIntegrations';
import EmailConfigurations from './EmailConfiguration';
import SystemEmail from './SystemEmails';
import {ToastContainer} from 'react-toastify';
import PersistentNotification from './PersistentNotification';
import OrderNotification from './OrderNotification';
import {Gradient} from 'react-gradient';
import colors from '../../HelperClasses/AppColor';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import NotificationTransaltion from './NotificationTransaltion';
import {checkUserRoleModule} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import AppColor from '../../HelperClasses/AppColor';
import {ReactComponent as LockIcon} from '../../assets/lockIcon.svg';
import BackBtnView from '../../Component/BackBtnView';
import HeaderView from '../HeaderView';
import {RestrictedFeatureEnum} from '../../Models/RestrictedFeatureEnum';
import SubscribePlan from '../../Component/SubscribePlan';
import SecondryButton from '../../Component/SecondryButton';
import SaveButton from '../../Component/SaveButton';
import EditEmailNotifications from './EditEmailNotifications';
import SidePanView from '../../Component/SidePanView';
import {withRouter} from '../../wrappers/withRouter';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

let subMenuTextArray = [
  'Feedback Form',
  'Email Notification',
  'Email Configuration',
  'System Emails',
  'Push notification',
  'Order Notification',
];

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      showTranslateView: false,
      writeP: AppConstants.defaultRead,
      showUpgrade: false,
      editEmailNotification: false,
      emailEditIndex: -1,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
  };
  componentDidMount() {
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.NOTIFICATIONS, res => {
      this.setState({writeP: res[1]});
    });
  };
  //MARK:-  APIs Method 
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 1) {
      this.child.submitButtonAction();
    } else if (this.state.subMenuSelectedIndex == 2) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 3) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 4) {
      this.child.saveButtonAction();
    } else if (this.state.subMenuSelectedIndex == 5) {
      this.child.saveButtonAction();
    }
  }
  didSelectMenuItem = index => {
    this.setState({subMenuSelectedIndex: index});
  };
  /*  UI   */

  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a < subMenuTextArray.length; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex == a
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image
            style={SettingsStyleSheet.subMenuIconStyle}
            source={
              this.state.subMenuSelectedIndex == a
                ? greenTickIcon
                : grayTick_Icon
            }
          />
          <Text
            style={
              this.state.subMenuSelectedIndex == a
                ? SettingsStyleSheet.subMenuSelectedtextStyle
                : SettingsStyleSheet.subMenutextStyle
            }>
            {subMenuTextArray[a]}
          </Text>
          <View style={{display: a == 2 ? 'flex' : 'none'}}>
            <LockIcon style={{height: 16, width: 16, marginLeft: 5}} />
          </View>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };
  editEmailValue = value => {
    this.state.emailEditIndex = value['id'];
    this.setState({editEmailNotification: true});
  };

  mainViewRender = () => {
    if (this.state.subMenuSelectedIndex == 0) {
      return (
        <FeedbackIntegration
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 1) {
      return (
        <EmailNotification
          ref={child => {
            this.child = child;
          }}
          editEmailNotification={data => this.editEmailValue(data)}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 3) {
      return (
        <SystemEmail
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 4) {
      return (
        <PersistentNotification
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else if (this.state.subMenuSelectedIndex == 5) {
      return (
        <OrderNotification
          ref={child => {
            this.child = child;
          }}
        />
      );
    } else {
      return (
        <EmailConfigurations
          ref={child => {
            this.child = child;
          }}
        />
      );
    }
  };
  renderTranslationBtnView = () => {
    var transaltionTitle = 'Translate';
    var translateView = [];
    translateView.push(
      <SecondryButton
        btnDeActive={this.checkRestriction()}
        showBtn={true}
        btnTitle={transaltionTitle}
        deActiveBtnAction={() => this.setState({showUpgrade: true})}
        btnAction={() => this.setState({showTranslateView: true})}
      />,
    );
    if (
      this.state.subMenuSelectedIndex == 4 ||
      this.state.subMenuSelectedIndex == 5
    ) {
      return <View>{translateView}</View>;
    }
    return <View />;
  };
  renderUpgradePlanView() {
    return (
      <TouchableOpacity
        style={[
          tableStyle.passwordViewStyle,
          {display: this.state.showUpgrade ? 'flex' : 'none'},
        ]}
        onPress={() => this.setState({showUpgrade: false})}>
        <SubscribePlan upgrade={true} />
      </TouchableOpacity>
    );
  }
  checkRestriction() {
    return this.state.subMenuSelectedIndex == 2
      ? AppConstants.restrictedFeatureArray.includes(
          RestrictedFeatureEnum.email,
        )
      : false;
  }
  renderSaveBtn = () => {
    return (
      <View
        style={{
          display: this.state.subMenuSelectedIndex == 1 ? 'none' : 'flex',
        }}>
        <SaveButton
          saveBtn={() => this.saveBtnAction()}
          deActiveBtnAction={() => this.setState({showUpgrade: true})}
          btnDeActive={this.checkRestriction()}
          showSaveBtn={this.props.showSaveBtn}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };
  renderEditEmailNotificationView = () => {
    if (this.state.editEmailNotification) {
      return (
        <SidePanView
          customWidth={true}
          dissmissView={() =>
            this.setState({
              editEmailNotification: !this.state.editEmailNotification,
            })
          }
          showSidepanView={this.state.editEmailNotification}
          customView={
            <View>
              <ToastContainer />
              <EditEmailNotifications
                editorIndex={this.state.emailEditIndex}
                success={() => this.setState({editEmailNotification: false})}
              />
            </View>
          }
          title={'Edit email template'}
        />
      );
    } else {
      return <View />;
    }
  };
  render() {
    if (this.state.showTranslateView) {
      if (this.state.subMenuSelectedIndex == 4) {
        return (
          <NotificationTransaltion
            translationTitle={subMenuTextArray[4]}
            backBtnAction={() => this.setState({showTranslateView: false})}
          />
        );
      } else if (this.state.subMenuSelectedIndex == 5) {
        return (
          <NotificationTransaltion
            translationTitle={subMenuTextArray[5]}
            type={'order'}
            backBtnAction={() => this.setState({showTranslateView: false})}
          />
        );
      }
    }
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{zIndex: 101}}>
          {this.renderUpgradePlanView()}
          {this.renderEditEmailNotificationView()}
        </View>
        <View style={tableStyle.navigationViewStyle}>
          <View style={tableStyle.headerContainer}>
            <View
              style={[
                tableStyle.headerListViewcontainer,
                {
                  width: itsMobileView
                    ? '100%'
                    : this.state.selectedTabIndex == 0
                    ? '65%'
                    : '72%',
                },
              ]}>
              <TouchableOpacity
                style={tableStyle.backBtnBackViewStyle}
                onPress={() => {
                  this.props.navigate(-1);
                }}>
                <BackBtnView />
              </TouchableOpacity>
              <Text style={tableStyle.titleTextStyle}>{`Communications`}</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                marginLeft: itsMobileView ? 80 : 0,
              }}>
              <this.renderTranslationBtnView />
              <this.renderSaveBtn />
            </View>
          </View>
        </View>
        <View style={{flexDirection: 'column', marginBottom: 40}}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{flex: 2, backgroundColor: 'white'}}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}

export default withRouter(Notifications);

const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
  },
  navigationViewStyle: {
    margin: 10,
    flexDirection: 'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    padding: itsMobileView ? 0 : 0,
    alignItems: 'center',
    height: 60,
    marginTop: 2,
    justifyContent: 'space-between',
    backgroundColor: colors.AppWhite,
    width: itsMobileView ? '84%' : '98%',
  },
  headerViewStyle: {
    padding: 10,
    height: 40,
    margin: 10,
    borderBottomColor: colors.AppNewGreen,
    borderBottomWidth: 5,
  },
  saveBtnStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: 140,
    borderRadius: 5,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
