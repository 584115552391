import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Platform,
  Dimensions,
  Image,
  FlatList,
  SafeAreaView,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import detailsStyle from '../StyleSheets/DetailStyleSheet';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import AddVariant from './AddVariants';
import AddListing from './AddListings';
import edit_Icon from '../assets/editIcon.png';
import deleteIcon from '../assets/delete_icon.png';
import placeholder from '../assets/pPlaceholder.png';
import {
  checkUserRoleModule,
  getThumbnailImage,
  convertTimeinto12Hrs,
} from '../HelperClasses/SingletonClass';
import AppConstants from '../Constants/AppConstants';
import AddCategory from '../Settings/Listings/AddCategory';
import AccountDetail from '../Accounts/AccountDetail';
import {ToastContainer} from 'react-toastify';
import closeIcon from '../assets/closeWhiteIcon.png'; //'../../assets/menuIcon.png';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {MODULEKEYS, ROLEMODULE} from '../Models/Enum';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import calendarIcon from '../assets/calendarIcon.png';
import Moment from 'moment';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import ConstantArray from '../Constants/ConstantArray';
import TextTitleStyleSheet from '../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import BackBtnView from '../Component/BackBtnView';
import styles from '../StyleSheets/NewStyleSheet';
import shareIcon from '../assets/shareIcon.png'; //'../../assets/menuIcon.png';
import {RWebShare} from 'react-web-share';

import GithubSlugger, {slug as slugChecker} from 'github-slugger';
import {withRouter} from '../wrappers/withRouter';
import DownloadAssets from './components/DownloadAssets';
import {CONFIG_CONSTANT} from '../Constants/ConfigsConstants';
const converter = require('number-to-words');
const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height - 120;

let fixedWidth = AppConstants.mobileMaxWidth;

class ListingDetail2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      titleDataArray: [],
      listingDetailData: {},
      imagesArray: [],
      accountData: {},
      attributeArray: [],
      addVariantBool: false,
      editListingBool: false,
      variantsArray: [],
      isEditVariant: false,
      selectedVaraintData: {},
      status: '',
      activeStatus: false,
      fulfilled_by_platform: false,
      categoryData: {},
      editCategoryBool: false,
      showAccountDetail: false,
      accountID: '',
      selectedImage: '',
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      moderationWrite: AppConstants.defaultWrite,
      scheduleArray: [],
      listingWebPath: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    listingID: PropTypes.string,
  };
  componentDidMount() {
    this.loadOrderDetailApi();
    // this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.LISTIINGS, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
    checkUserRoleModule(MODULEKEYS.LISTIINGSMODERATION, res => {
      console.log('res ==>>> ', res);
      this.setState({moderationWrite: res[1]});
    });
  };
  loadOrderDetailApi = async () => {
    this.loadVariantsAPI();
    this.state.titleDataArray = [];
    this.state.imagesArray = [];
    this.state.attributeArray = [];
    this.state.accountData = {};
    this.state.listingDetailData = {};

    this.setState({dataLoad: false});

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listingsModerate + `/${this.props.params.listingID}`,
      'get',
    );
    if (responseJson['status'] == true) {
      let dataObjc = responseJson['data']['listing'];
      var categories = dataObjc['categories'];
      var catData = [];
      for (let a = 0; a < categories.length; a++) {
        // let obj = categories[a]['name'];
        let obj = categories[a];
        this.state.categoryData = obj;
        var hame = '';
        for (let hierarchy of obj['hierarchy']) {
          if (hame.length != 0) {
            hame = `${hame} -> ${hierarchy['name']}`;
          } else {
            hame = hierarchy['name'];
          }
        }
        catData.push(hame);
      }
      if (dataObjc['account'] != null) {
        this.state.accountData = dataObjc['account'];
      }
      console.log('catData ', catData);

      this.state.imagesArray = dataObjc['images'];
      this.state.attributeArray = dataObjc['attributes'];
      this.setState({listingDetailData: dataObjc});
      this.state.titleDataArray.push(dataObjc['title']);
      this.state.titleDataArray.push(dataObjc['slug'] || '');
      this.state.titleDataArray.push(dataObjc['description'] || '   ');
      this.state.titleDataArray.push(catData);
      this.state.titleDataArray.push(dataObjc['list_price']['formatted']);
      this.state.titleDataArray.push(dataObjc['offer_percent']);
      this.state.titleDataArray.push(dataObjc['offer_price']['formatted']);
      this.state.titleDataArray.push(dataObjc['list_price']['currency']);
      this.state.titleDataArray.push(dataObjc['status']);
      this.state.status = dataObjc['status'];
      this.state.activeStatus = dataObjc['active'];
      this.state.fulfilled_by_platform = dataObjc['fulfilled_by_platform'];
      this.state.titleDataArray.push(dataObjc['active']);
      this.state.titleDataArray.push(dataObjc['sold']);
      this.state.titleDataArray.push(dataObjc['meta_title'] || '');
      this.state.titleDataArray.push(dataObjc['meta_description'] || '');
      let schedules = dataObjc['schedules'];
      console.log('schedules', schedules);
      for (let scObj of schedules) {
        let timeArray = [];
        let repeatClass = scObj['repeat_days'];
        var repeatIDs = '1,7';
        var repeatName = '';
        var repeatIndex = 0;
        repeatIDs = repeatClass.toString();
        let index = ConstantArray.repeatArray.findIndex(x => x.id == repeatIDs);
        if (index == -1) {
          repeatIndex = 3;
          var nameary = [];
          for (let objc of repeatClass) {
            let ind = ConstantArray.weekDays.findIndex(x => x.id == objc);
            nameary.push(ConstantArray.weekDays[ind].name);
          }
          if (nameary.length != 0) {
            repeatName = nameary.toString();
          }
        } else {
          repeatIndex = index;
          repeatName = ConstantArray.repeatArray[index].name;
        }
        let reactDic = {
          name: repeatName,
          ids: repeatIDs,
        };
        let timeDic = {
          start: convertTimeinto12Hrs(scObj['start_time']),
          end: convertTimeinto12Hrs(scObj['end_time']),
        };
        let dict = {
          startDate: scObj['start_date'],
          startTime: scObj['start_time'],
          endTime: scObj['end_time'],
          slot: '60',
          repeatClass: reactDic,
        };
        let dIndex = this.state.scheduleArray.findIndex(
          x => x['startDate'] == scObj['start_date'],
        );
        if (dIndex == -1) {
          timeArray.push(timeDic);
          dict['timeSlots'] = timeArray;
          this.state.scheduleArray.push(dict);
        } else {
          let getTimeAry = this.state.scheduleArray[dIndex];
          timeArray = [...getTimeAry['timeSlots']];
          timeArray.push(timeDic);
          this.state.scheduleArray[dIndex]['timeSlots'] = timeArray;
        }
      }
      if (this.state.titleDataArray.length != 0) {
        let slug = this.state.titleDataArray[1];
        var pathURL = AppConstants.domainKey;
        if (slug.length == 0) {
          let title = this.state.titleDataArray[0];
          let vslug = slugChecker(title);
          pathURL =
            pathURL + `/l/${this.props.params.listingID}-${vslug}?id=true`;
        } else {
          pathURL = pathURL + `/l/${slug}`;
        }
        this.state.listingWebPath = pathURL;
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  deleteListingApi = async id => {
    this.setState({dataLoad: false});
    var path =
      APPURL.URLPaths.listingsModerate + '/' + this.props.params.listingID;
    const responseJson = await networkService.networkCall(path, 'delete');
    if (responseJson['status'] == true) {
      let key = `${MixPannelEnum.delete} ${MixPannelEnum.listings}`;
      addMixpanelEvent(MixpanelEvent.viewListingsDetail, {[key]: true});
      this.setState({dataLoad: true});
      this.props.navigate(-1);
    } else {
      this.setState({dataLoad: true});
    }
  };
  loadVariantsAPI = async () => {
    this.setState({dataLoad: false});
    var path =
      APPURL.URLPaths.listingsModerate +
      '/' +
      this.props.params.listingID +
      '/variants';
    const responseJson = await networkService.networkCall(path, 'get', '', '');
    if (responseJson['status'] == true) {
      let vData = responseJson['data']['variants'];
      this.state.variantsArray = vData;
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  deleteVariantApi = async id => {
    this.setState({dataLoad: false});
    var path =
      APPURL.URLPaths.listingsModerate +
      '/' +
      this.props.params.listingID +
      '/variants/' +
      id;
    const responseJson = await networkService.networkCall(path, 'delete');
    if (responseJson['status'] == true) {
      this.loadVariantsAPI();
    } else {
      this.setState({dataLoad: true});
    }
  };
  aproveRejectListingsApi = async () => {
    this.setState({dataLoad: false});
    let fDict = {status: this.state.status == 2 ? 3 : 2};
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listingsModerate +
        `/${this.props.params.listingID}/moderate`,
      'post',
      JSON.stringify({listing: fDict}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      let text =
        this.state.status == 3 ? MixPannelEnum.approve : MixPannelEnum.reject;
      let key = `${text} ${MixPannelEnum.listings}`;
      addMixpanelEvent(MixpanelEvent.viewListingsDetail, {[key]: true});
      this.loadOrderDetailApi();
    }
  };
  fullFilledByPlatformAction = async () => {
    this.setState({dataLoad: false});
    let fDict = {fulfilled_by_platform: !this.state.fulfilled_by_platform};
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listingsModerate + `/${this.props.params.listingID}`,
      'PATCH',
      JSON.stringify({listing: fDict}),
      '',
    );
    if (responseJson['status'] == true) {
      this.setState({
        fulfilled_by_platform: !this.state.fulfilled_by_platform,
      });
      this.loadOrderDetailApi();
    }
  };
  updateActiveStatusAPi = async () => {
    this.setState({dataLoad: false});
    let fDict = {active: this.state.activeStatus ? false : true};
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listingsModerate + `/${this.props.params.listingID}`,
      'PATCH',
      JSON.stringify({listing: fDict}),
      '',
    );
    if (responseJson['status'] == true) {
      this.setState({activeStatus: !this.state.activeStatus, dataLoad: true});
      this.loadOrderDetailApi();
    }
  };
  //***   Buttons Action
  updateActiveStatusBtnAction() {
    this.updateActiveStatusAPi();
  }
  approveRejectBtnAction = () => {
    let msg = `Do you want to ${
      this.state.status == 2 ? 'reject' : 'approve'
    } this Listing ?`;
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.aproveRejectListingsApi();
      }
    }
  };
  backBtnAction() {
    this.setState({
      editListingBool: false,
      addVariantBool: false,
      isEditVariant: false,
    });
    this.loadOrderDetailApi();
  }
  deleteVariantBtnAction = index => {
    if (Platform.OS === 'web') {
      const r = window.confirm('Are you sure you want to this variant?');
      if (r == true) {
        this.deleteVariantApi(index);
      }
    }
  };
  deleteListingBtnAction() {
    if (Platform.OS === 'web') {
      const r = window.confirm('Are you sure you want to this listing?');
      if (r == true) {
        this.deleteListingApi();
      }
    }
  }
  editButtonAction = id => {
    let index = this.state.variantsArray.findIndex(x => x.id === id);
    this.state.selectedVaraintData = this.state.variantsArray[index];
    this.setState({isEditVariant: true, addVariantBool: true});
  };
  previewBtnAction() {
    if (this.state.titleDataArray.length != 0) {
      window.open(this.state.listingWebPath, '_blank');
    }
  }
  viewAccountBtnAction(id) {
    this.state.accountID = id;
    this.props.navigate(`/account/${id}`);
  }
  // UI Renders
  renderImagesView = () => {
    if (this.state.imagesArray.length != 0) {
      var view = [];
      for (let a = 0; a < this.state.imagesArray.length; a++) {
        this.state.selectedImage = this.state.imagesArray[a];
        view.push(
          <View style={detailsStyle.imageViewContainerStyle}>
            <Zoom>
              <Image
                style={detailsStyle.imageViewsStyle}
                resizeMode="contain"
                source={this.state.imagesArray[a]}
              />
            </Zoom>
          </View>,
        );
      }
      return (
        <View style={commonStyle.mainView}>
          <Text style={tableStyle.mainTitile}>Images</Text>
          <ScrollView>
            <View style={detailsStyle.containerView}>{view}</View>
          </ScrollView>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderAccountDetail = () => {
    if (Object.keys(this.state.accountData).length != 0) {
      return (
        <View style={commonStyle.mainView}>
          <Text style={tableStyle.mainTitile}>Posted by</Text>
          <TouchableOpacity
            style={{flexDirection: 'row', marginTop: -20}}
            onPress={() =>
              this.viewAccountBtnAction(this.state.accountData['id'])
            }>
            <View
              style={{
                borderWidth: 1,
                borderColor: colors.Lightgray,
                margin: 20,
              }}>
              <Text style={detailsStyle.tableViewTitleStyle}>{'Name'}</Text>
              <View style={{height: 2}} />
              <View
                style={{
                  height: 1,
                  backgroundColor: colors.Lightgray,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              <Text style={detailsStyle.tableViewTitleStyle}>
                {'Unique Name	'}
              </Text>
              <View style={{height: 2}} />
              <View
                style={{
                  height: 1,
                  backgroundColor: colors.Lightgray,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              <Text style={detailsStyle.tableViewTitleStyle}>
                {'Descriptions'}
              </Text>
              <View style={{height: 2}} />
            </View>
            <View
              style={{
                borderWidth: 1,
                borderColor: colors.Lightgray,
                margin: 20,
                marginLeft: -20,
                flex: 1,
              }}>
              <Text
                style={[
                  detailsStyle.valueTitleStyle,
                  {color: colors.AppNewGreen},
                ]}>
                {this.state.accountData['name'] || ' '}
              </Text>
              <View style={{height: 2}} />
              <View
                style={{
                  height: 1,
                  backgroundColor: colors.Lightgray,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              <Text style={detailsStyle.valueTitleStyle}>
                {this.state.accountData['unique_name'] || 'N/A'}
              </Text>
              <View style={{height: 2}} />
              <View
                style={{
                  height: 1,
                  backgroundColor: colors.Lightgray,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              <Text style={detailsStyle.valueTitleStyle}>
                {this.state.accountData['description'] || ' '}
              </Text>
              <View style={{height: 2}} />
            </View>
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderAttributes = id => {
    var titleArray = ['Title'];
    var valueArray = ['Value'];
    for (let a = 0; a < this.state.attributeArray.length; a++) {
      titleArray.push(this.state.attributeArray[a]['name']);
      let valueObj = this.state.attributeArray[a]['values'];
      // console.log('this.state.attributeArray[a]',this.state.attributeArray[a]);
      var valueD = [];
      for (let b = 0; b < valueObj.length; b++) {
        if (valueObj[b]['name']) {
          valueD.push(valueObj[b]['name']);
        } else if (valueObj[b]['latitude']) {
          let loc = `Latitude: ${valueObj[b]['latitude']} Longitude: ${valueObj[b]['longitude']}`;
          valueD.push(loc);
        } else if (this.state.attributeArray[a]['field_type'] == 11) {
          let va = valueObj[b];
          let jsd = `${JSON.stringify(va)}`;
          valueD.push(jsd);
        } else {
          valueD.push(valueObj[b]);
        }
      }
      valueArray.push(valueD.join(','));
    }
    var view = [];
    var tableHeaderString = [];
    tableHeaderString = id['id'] == 1 ? titleArray : valueArray;
    for (let a = 0; a < tableHeaderString.length; a++) {
      let stylesH =
        a == 0
          ? detailsStyle.tableViewTitleStyle
          : detailsStyle.valueTitleStyle;
      view.push(
        <View style={styles.attributeListViewStyle}>
          <View style={{width: '30%'}}>
            <Text style={stylesH}>{titleArray[a]}</Text>
          </View>
          <View
            style={{
              width: 1,
              height: '100%',
              backgroundColor: colors.Lightgray,
            }}
          />
          <View style={{width: '70%'}}>
            <Text style={stylesH}>{valueArray[a]}</Text>
          </View>
          {/* <Text style={stylesH}>{tableHeaderString[a]}</Text>
          <View style={{ height: a == length ? 0 : 1, backgroundColor: colors.Lightgray, marginTop: 0 }} /> */}
        </View>,
      );
    }
    return view;
  };
  renderDetailStatusBtn = (value, index) => {
    let values = value == true ? 'Active' : 'Inactive';
    return (
      <View style={detailsStyle.listing_detail_tableViewTitleViewStyle}>
        <View style={{height: 4}} />
        <Text
          style={
            value == true
              ? detailsStyle.columnActiveBtnStyle
              : detailsStyle.columnDeActiveBtnStyle
          }>
          {values}
        </Text>
        <View style={{height: 4}} />
      </View>
    );
  };
  renderDetailStatusActionBtn = () => {
    if (this.state.moderationWrite) {
      return (
        <View>
          <View
            style={{
              shadowColor: colors.Appgray,
              shadowOpacity: 0.5,
              shadowOffset: {width: 0, height: 0},
              shadowRadius: 5,
              width: 75,
              marginRight: 10,
              height: 33,
              borderRadius: 5,
              backgroundColor: this.state.activeStatus
                ? colors.darkRed
                : colors.AppNewGreen,
            }}>
            <TouchableOpacity
              style={{
                alignItems: 'center',
                height: 33,
                justifyContent: 'center',
              }}
              onPress={() => this.updateActiveStatusBtnAction()}>
              <Text style={{color: 'white'}}>
                {this.state.activeStatus ? 'InActive' : 'Active'}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
  };
  renderDetail = () => {
    let tableHeaderString = this.state.titleDataArray;
    var detailView = [];
    let length = tableHeaderString.length - 1;
    for (let a = 0; a < tableHeaderString.length; a++) {
      if (a == 8) {
        var value = '';
        if (tableHeaderString[a] == 1) {
          value = 'Pending';
        } else if (tableHeaderString[a] == 2) {
          value = 'Approved';
        } else if (tableHeaderString[a] == 3) {
          value = 'Rejected';
        }
        detailView.push(
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <View
              style={{
                width: '30%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
                borderRightWidth: 0,
              }}>
              <Text style={detailsStyle.tableViewTitleStyle}>
                {tableStaticArrayString[a]}
              </Text>
            </View>
            <View
              style={{
                width: '70%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: 0,
                borderTopWidth: 0,
              }}>
              <View style={detailsStyle.listing_detail_tableViewTitleViewStyle}>
                <View style={{height: 1, backgroundColor: colors.Lightgray}} />
                <View style={{height: 4}} />
                <Text
                  style={
                    tableHeaderString[a] == 2
                      ? detailsStyle.columnActiveBtnStyle
                      : detailsStyle.columnDeActiveBtnStyle
                  }>
                  {value}
                </Text>
                <View style={{height: 4}} />
              </View>
            </View>
          </View>,
        );
      } else if (a == 9 || a == 10) {
        var value = tableHeaderString[a] == true ? 'Yes' : 'No';
        var vViews = [];
        if (a == 9) {
          value = tableHeaderString[a] == true ? 'Active' : 'Inactive';
          vViews.push(this.renderDetailStatusBtn(tableHeaderString[a], a));
        } else {
          vViews.push(
            <View style={detailsStyle.listing_detail_tableViewTitleViewStyle}>
              <View style={{height: 4}} />
              <Text
                style={
                  tableHeaderString[a] == true
                    ? detailsStyle.columnActiveBtnStyle
                    : detailsStyle.columnDeActiveBtnStyle
                }>
                {value}
              </Text>
              <View style={{height: 4}} />
            </View>,
          );
        }
        detailView.push(
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <View
              style={{
                width: '30%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
                borderRightWidth: 0,
              }}>
              <Text style={detailsStyle.tableViewTitleStyle}>
                {tableStaticArrayString[a]}
              </Text>
            </View>
            <View
              style={{
                width: '70%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
              }}>
              {vViews}
            </View>
          </View>,
        );
      } else if (a == 3) {
        let name = tableHeaderString[a] ? tableHeaderString[a] : '';
        detailView.push(
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <View
              style={{
                width: '30%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
                borderRightWidth: 0,
              }}>
              <Text style={detailsStyle.tableViewTitleStyle}>
                {tableStaticArrayString[a]}
              </Text>
            </View>
            <View
              style={{
                width: '70%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
              }}>
              <TouchableOpacity
                onPress={() => this.setState({editCategoryBool: true})}>
                <Text
                  style={[
                    detailsStyle.valueTitleStyle,
                    {color: colors.AppNewGreen},
                  ]}>
                  {name}
                </Text>
              </TouchableOpacity>
            </View>
          </View>,
        );
      } else {
        detailView.push(
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 0,
            }}>
            <View
              style={{
                width: '30%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
                borderRightWidth: 0,
              }}>
              <Text style={detailsStyle.tableViewTitleStyle}>
                {tableStaticArrayString[a]}
              </Text>
            </View>
            <View
              style={{
                width: '70%',
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
              }}>
              <Text style={detailsStyle.valueTitleStyle}>
                {tableHeaderString[a]}
              </Text>
            </View>
          </View>,
        );
      }
    }
    return detailView;
  };

  renderDeleteBtn = () => {
    return (
      <View style={{display: this.state.deleteP ? 'flex' : 'none'}}>
        <View
          style={[
            tableStyle.secondButtonViewStyle,
            {borderColor: colors.AppRed},
          ]}>
          <TouchableOpacity onPress={() => this.deleteListingBtnAction()}>
            <Text
              style={[tableStyle.secondBtnTextStyle, {color: colors.AppRed}]}>
              Delete
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderAddVariantBtn = () => {
    return (
      <View
        style={{
          display: AppConstants.appType
            ? this.state.writeP
              ? 'flex'
              : 'none'
            : 'none',
        }}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity
            onPress={() =>
              this.setState({addVariantBool: !this.state.addVariantBool})
            }>
            <Text style={tableStyle.secondBtnTextStyle}>Add Variant</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderApproveRejectBtn = () => {
    return (
      <View style={{display: this.state.moderationWrite ? 'flex' : 'none'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity
            disabled={!this.state.moderationWrite}
            onPress={() => this.approveRejectBtnAction()}>
            <Text style={tableStyle.secondBtnTextStyle}>
              {this.state.status == 2 ? 'Reject' : 'Approve'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderFullFIlledBtn = () => {
    return (
      <View style={{display: this.state.moderationWrite ? 'flex' : 'none'}}>
        {this.state.fulfilled_by_platform ? (
          <View style={tableStyle.gradientViewBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              angle="0deg">
              <TouchableOpacity
                onPress={() => this.fullFilledByPlatformAction()}>
                <Text style={tableStyle.saveBtnTxtStyle}>
                  Fullfilled by Platform
                </Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        ) : (
          <View style={tableStyle.secondButtonViewStyle}>
            <TouchableOpacity
              disabled={!this.state.moderationWrite}
              onPress={() => this.fullFilledByPlatformAction()}>
              <Text style={tableStyle.secondBtnTextStyle}>
                Fullfilled by Platform
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  renderEditBtn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity
              onPress={() =>
                this.props.navigate(
                  `/edit-listing/${this.props.params.listingID}`,
                )
              }>
              <Text style={tableStyle.saveBtnTxtStyle}>Edit</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  renderPreviewBtnView = () => {
    return (
      <View style={{display: this.state.status == 2 ? 'flex' : 'none'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <TouchableOpacity onPress={() => this.previewBtnAction()}>
            <Text style={tableStyle.secondBtnTextStyle}>Preview</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderShareBtnView = () => {
    return (
      <View style={{display: this.state.status == 2 ? 'flex' : 'none'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <RWebShare
            data={{url: this.state.listingWebPath}}
            onClick={() => console.log('shared successfully!')}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
              {/* <Image source={shareIcon} style={{ height: 20, width: 20 }} /> */}
              <Text style={tableStyle.secondBtnTextStyle}>Share</Text>
            </View>
          </RWebShare>
        </View>
      </View>
    );
  };
  renderHeader = () => {
    var addVariantView = [];
    if (AppConstants.appType) {
      addVariantView.push(this.renderAddVariantBtn());
    }
    return (
      <View style={styles.headerView}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <TouchableOpacity
            style={tableStyle.backBtnBackViewStyle}
            onPress={() => this.props.navigate('/listings')}>
            <BackBtnView />
          </TouchableOpacity>
          <Text style={tableStyle.listTitleText}>View Listing</Text>
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {AppConstants.appType === false &&
            CONFIG_CONSTANT?.SHIPPING_CONFIGS
              ?.fulfillment_by_platform_enabled &&
            this.renderFullFIlledBtn()}
          {this.renderDetailStatusActionBtn()}
          {this.renderShareBtnView()}
          {this.renderPreviewBtnView()}
          {this.renderApproveRejectBtn()}
          {this.renderDeleteBtn()}
          {this.renderEditBtn()}
          {addVariantView}
        </View>
      </View>
    );
  };
  tableViewHeader = props => {
    const tableHeaderString = [
      'Title',
      'Description',
      'Image',
      'Price',
      'Offer Price',
      'Stock',
      'Action',
      'Status',
    ];
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 89}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.listing_detail_tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.variantsArray.length == 0) {
      return <View />;
    } else {
      var views = [];
      for (let i = 0; i < this.state.variantsArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.variantsArray[i]['title'],
              this.state.variantsArray[i]['description'],
              this.state.variantsArray[i]['images'][0] || '',
              this.state.variantsArray[i]['list_price']['formatted'] || '',
              this.state.variantsArray[i]['offer_price']['formatted'] || '',
              this.state.variantsArray[i]['stock'],
              this.state.variantsArray[i]['id'],
              this.state.variantsArray[i]['active'] == true
                ? 'Active'
                : 'Inactive',
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
            style={{
              borderBottomWidth: 1,
              borderBottomColor: colors.SimonGray,
              backgroundColor: 'white',
            }}
            keyExtractor={(item, index) => index + 89}
            key={'X'}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 7) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item == 'Active'
                ? tableStyle.columnActiveBtnStyle
                : tableStyle.columnDeActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 6) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.editButtonAction(item)}>
              <Image
                style={tableStyle.iconStyle}
                resizeMode="center"
                source={edit_Icon}
              />
            </TouchableOpacity>
            <View style={{width: 10}} />
            <TouchableOpacity onPress={() => this.deleteVariantBtnAction(item)}>
              <Image
                style={tableStyle.iconStyle}
                resizeMode="center"
                source={deleteIcon}
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    } else if (index == 2) {
      let fileURl = getThumbnailImage(item);
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{margin: 5}}>
            <Image
              style={tableStyle.itemImageViewStyle}
              source={item.length == 0 ? placeholder : fileURl}
            />
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderVariantsView = () => {
    if (this.state.variantsArray.length == 0) {
      return <View />;
    } else {
      return (
        <View style={commonStyle.mainView}>
          <Text style={tableStyle.mainTitile}>Variants</Text>
          <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
            <View style={{flexDirection: 'column'}}>
              <View style={tableStyle.headerListContainer}>
                <ScrollView horizontal={true}>
                  <this.tableViewHeader />
                </ScrollView>
              </View>
              <View style={{flex: 1}}>
                <View style={{height: '100%'}}>
                  <ScrollView>
                    <View style={{flex: 1, width: '100%', paddingLeft: 10}}>
                      <this.columnDataView />
                    </View>
                  </ScrollView>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      );
    }
  };

  renderAttributeView = () => {
    var attViews = [];
    let name = this.state.listingDetailData['title'] ?? '';
    if (this.state.attributeArray.length != 0) {
      attViews.push(
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          }}>
          <View
            style={{
              flex: 1,
              borderColor: colors.Lightgray,
              borderWidth: 1,
              borderRightWidth: 1,
            }}>
            <this.renderAttributes id={1} />
          </View>
        </View>,
      );
    } else {
      attViews.push(
        <View style={{justifyContent: 'center', margin: 20}}>
          <Text style={CommonStyleSheet.plainSubTextStyle}>
            No Attributes Value Present in this {name}
          </Text>
        </View>,
      );
    }
    return (
      <View
        style={[
          commonStyle.mainView,
          {display: name.length == 0 ? 'none' : 'flex'},
        ]}>
        <Text style={tableStyle.mainTitile}>Attributes</Text>
        {attViews}
      </View>
    );
  };
  renderFullImageView = () => {
    // if (this.state.showMediaManager) {
    return (
      <View style={styles.fullImageContainStyle}>
        <View style={{alignItems: 'flex-end'}}>
          <TouchableOpacity>
            <Image
              source={closeIcon}
              style={{
                width: 30,
                height: 30,
                marginTop: 20,
                marginRight: 20,
                backgroundColor: colors.blackColor,
              }}
            />
          </TouchableOpacity>
        </View>
        <View>
          <Zoom>
            <Image
              source={this.state.selectedImage}
              style={{width: windowWidth / 2, height: windowHeight / 2}}
            />
          </Zoom>
        </View>
      </View>
    );
    // } else {
    //   return <View />
    // }
  };
  renderScheduleView = () => {
    var scheduleView = [];
    var display = 'none';
    for (let a = 0; a < this.state.scheduleArray.length; a++) {
      display = 'flex';
      let item = this.state.scheduleArray[a];
      let date = Moment(item['startDate']).format('ddd MMM Do YYYY');
      let repeat = item['repeatClass']['name'];
      let timeSlots = item['timeSlots']; //getTimeDifferenceArray(item['startTime'],item['endTime'],item['slot'])
      var slotsView = [];
      for (let objc of timeSlots) {
        slotsView.push(
          <Text
            style={[
              CommonStyleSheet.subTitleStyle,
              {marginBottom: 5},
            ]}>{`${objc['start']} ${objc['end']}`}</Text>,
        );
      }
      scheduleView.push(
        <View
          style={{
            flexDirection: 'row',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: colors.SimonGray,
            margin: 16,
            padding: 5,
            width: 300,
          }}>
          <View style={{width: 30, alignItems: 'center'}}>
            <Image style={{width: 30, height: 30}} source={calendarIcon} />
          </View>
          <View style={{flex: 1, marginLeft: 10}}>
            <Text style={commonStyle.plainSubTextStyle}>
              Starts From:{' '}
              <Text style={commonStyle.plainSubTextStyle}>{date}</Text>
            </Text>
            <View style={{height: 10}} />
            {slotsView}
            <Text style={commonStyle.plainSubTextStyle}>{repeat}</Text>
          </View>
        </View>,
      );
    }
    return (
      <View style={[commonStyle.mainView, {display: display}]}>
        <Text style={tableStyle.mainTitile}>Schedule</Text>
        <View>{scheduleView}</View>
      </View>
    );
  };
  render() {
    if (this.state.addVariantBool) {
      return (
        <AddVariant
          vairantData={this.state.selectedVaraintData}
          isEdit={this.state.isEditVariant}
          listingId={this.props.params.listingID}
          backBtnAction={() => this.backBtnAction()}
        />
      );
    } else if (this.state.editCategoryBool) {
      return (
        <ScrollView style={{flexDirection: 'column'}}>
          <ToastContainer />
          <AddCategory
            type={'Listings'}
            isEdit={true}
            categoryData={this.state.categoryData}
            backBtnAction={() =>
              this.setState({editCategoryBool: !this.state.editCategoryBool})
            }
          />
        </ScrollView>
      );
    } else if (this.state.editListingBool) {
      return (
        <AddListing
          isEdit={true}
          listingDetailData={this.state.listingDetailData}
          backBtnAction={() => this.backBtnAction()}
        />
      );
    } else {
      return (
        <SafeAreaView style={commonStyle.Container}>
          <this.renderHeader />
          {/* <this.renderFullImageView /> */}
          <View style={{height: windowHeight - 10}}>
            <ScrollView style={{zIndex: 928, flex: 1}}>
              <View>{this.renderImagesView()}</View>
              <View style={commonStyle.mainView}>
                <Text style={tableStyle.mainTitile}>Detail</Text>
                <View style={{margin: 16}}>
                  <this.renderDetail />
                </View>
              </View>
              <View>
                <this.renderScheduleView />
              </View>
              <View>{this.renderAttributeView()}</View>
              {CONFIG_CONSTANT.LISTINGS_CONFIGS?.digital_content_enables ===
                true && (
                <View>
                  <DownloadAssets listing_id={this.props.params.listingID} />
                </View>
              )}
              <View>
                <this.renderAccountDetail />
              </View>
              <this.renderVariantsView />
              <Appload enable={this.state.dataLoad} />
            </ScrollView>
          </View>
        </SafeAreaView>
      );
    }
  }
}

export default withRouter(ListingDetail2);

const tableStaticArrayString = [
  'Title',
  'Slug',
  'Descriptions',
  'Categories',
  'List Price',
  'Offer Percent',
  'Offer Price',
  'Currency',
  'Listing Status',
  'Status',
  'Sold',
  'Meta Title',
  'Meta Descritpion',
];
