import React from 'react';
import SideMenubar from './DynamicSideMenubar';
import DashboardScreen2 from '../Dashboard/DashboardScreen2';
import AppConstants from '../Constants/AppConstants';
import {useNavigate, useLocation, Outlet} from 'react-router-dom';
import Header from './Header/Header';

const MainRootView = () => {
  const location = useLocation();
  return (
    <div className="flex  w-full min-w-screen ">
      <div className="  hidden md:block">
        <SideMenubar />
      </div>
      <div className=" flex-grow  overflow-x-auto ">
        <Header />
        {location.pathname === '/' ? (
          AppConstants.appType ? (
            <DashboardScreen2 />
          ) : (
            <DashboardScreen2 />
          )
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default MainRootView;
