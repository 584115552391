import React from 'react';
import {StyleSheet, TouchableOpacity, View, Text} from 'react-native';
import edit_Icon from '../../assets/editIcon.png';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import colors from '../../HelperClasses/AppColor';

const ApproveButton = ({onClickButton, isDisabled, is_approve = true}) => {
  return (
    <View
      style={[
        tableStyle.secondButtonViewStyle,
        {
          borderColor: is_approve ? colors.AppNewGreen : colors.AppRed,
          marginRight: 0,
        },
      ]}>
      <TouchableOpacity disabled={isDisabled} onPress={() => onClickButton()}>
        <Text
          style={[
            tableStyle.acceptBtnTextStyle,
            {color: is_approve ? colors.AppNewGreen : colors.AppRed},
          ]}>
          {is_approve ? 'Approve' : 'Reject'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default ApproveButton;
