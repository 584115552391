import React from 'react';
import {getFormatBySelectedCurrency} from '../../utils/CurrencyFunctions';
import {ICONS} from '../../Constants/Icons';
import deleteSubscriptionListing from './actions/deleteSubscriptionListing';
import ToastPopup from '../../HelperClasses/AppLoaderClasses/ToastPopup';

const SubscriptionCard = ({
  subscription,
  setIsLoading,
  load_subscription_list,
  onEditSubscription,
}) => {
  return (
    <div className=" w-full mx-auto relative  bg-white  shadow  overflow-hidden rounded-md">
      <img
        src={subscription?.image_path}
        alt=""
        className="w-full h-40   object-cover"
      />

      <div className="px-6 py-6 space-y-4">
        <div className=" space-y-2">
          <h4 className="  text-base font-semibold ">{subscription?.title}</h4>
          <p className=" text-lg font-bold">
            {getFormatBySelectedCurrency({
              value: subscription?.amount,
              selected_format: subscription?.currency?.format,
            })}
          </p>
        </div>
        <p className=" text-base text-gray-500    ">
          {/* truncate description text base on length */}
          {subscription?.description?.length > 100
            ? subscription?.description.slice(0, 150) + '...'
            : subscription?.description}
        </p>
      </div>

      <div className=" absolute flex  items-center  top-0 right-0 gap-3 bg-white p-1 rounded-bl-md">
        {/* delete button */}
        <button
          className="    cursor-pointer   text-primary hover:text-red-600  "
          onClick={() => {
            deleteSubscriptionListing({
              id: subscription?.id,
              setIsLoading: setIsLoading,
              onSuccess: () => {
                load_subscription_list();
                ToastPopup({
                  type: 'success',
                  message: 'Subscription Listing Deleted Successfully',
                });
              },
            });
          }}>
          {ICONS.delete_icon}
        </button>

        {/* edit */}
        <button
          className="   cursor-pointer   text-primary hover:text-green-600  "
          onClick={() => onEditSubscription(subscription)}>
          {ICONS.edit_icon}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
