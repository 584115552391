import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Image,
  FlatList,
  SafeAreaView,
  Platform,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../HeaderView';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import HelperLinkViews from '../../Component/HelperLinkView';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer} from 'react-toastify';
import edit_Icon from '../../assets/editIcon.png';
import deleteIcon from '../../assets/delete_icon.png';
import StorageHubs from './StorageHub';
import AppColor from '../../HelperClasses/AppColor';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import SidePanView from '../../Component/SidePanView';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false
const configViewRightMargin = -420


export default class StorageHubList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: true,
      dataLoad: false,
      storageListArray: [],
      haveData: false,
      addStorageHubBool: false,
      selectedStorageHubIndex:0,
      isEdit:false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.loadStorageHubApi()
  }
  loadStorageHubApi = async () => {
    this.setState({dataLoad:false})
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addresses + '/?type=storage_hub', 'get')
    if (responseJson['status'] == true) {
      this.state.storageListArray = responseJson['data']['addresses']
      this.state.haveData = true
    }
    this.setState({dataLoad: true})
  };
  deleteStorageHubApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.addresses + '/' + id, 'delete', '', '');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.loadStorageHubApi();
    }
  };

  //MARK:-  Button Actions
  editButtonAction(index) {
    let id_Index = this.state.storageListArray.findIndex(x => x.id === index);
    this.setState({isEdit: true, selectedStorageHubIndex: id_Index, addStorageHubBool: true});
  }
  dltStorageBtnAction = index => {
    let msg = 'Do you want to go delete this Storage Hub Point';
    if (Platform.OS === 'web') {
      const r = window.confirm(msg);
      if (r == true) {
        this.deleteStorageHubApi(index);
      }
    }
  }
  backButtonAction() {
    this.setState({isEdit: false,addStorageHubBool: false});
    this.loadStorageHubApi()
  }
  reloadAPI() {
    this.loadStorageHubApi()
  }
  // UI Renders
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    if (this.state.storageListArray.length == 0) {
      if (this.state.haveData) {
        return <EmptyListUI />;
      }else {
        return <View />;
      }
    } else {
      var views = [];
      for (let i = 0; i < this.state.storageListArray.length; i++) {
        views.push(
          <FlatList
            data={[
              this.state.storageListArray[i]['name'],
              this.state.storageListArray[i]['phone_number'],
              this.state.storageListArray[i]['formatted_address'],
              this.state.storageListArray[i]['landmark'],
              this.state.storageListArray[i]['state'],
              this.state.storageListArray[i]['post_code'],
              this.state.storageListArray[i]['country'],
              this.state.storageListArray[i]['id'],
            ]}
            horizontal={true}
            renderItem={this.columnCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            style={{borderBottomWidth: 1, borderBottomColor: colors.SimonGray,backgroundColor: 'white'}}
          />,
        );
      }
      return views;
    }
  };
  columnCell = ({item, index}) => {
    if (index == 7) {     
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.editButtonAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon}/>
            </TouchableOpacity>
            <View style={{width: 10}} />
            <TouchableOpacity onPress={() => this.dltStorageBtnAction(item)}>
              <Image style={tableStyle.iconStyle} resizeMode="center" source={deleteIcon} />
            </TouchableOpacity>
            <View style={{width: 10}} />
          </View>
        </View>
      );
    } else if (index == 0) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </View>
      );
    } else if (index == 2) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={[tableStyle.columnViewTitleStyle,{textAlign:'left'}]}>{item}</Text>
        </View>
      )
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      )
    }
  }

  renderMainView = () => {
    return (<View style={tableStyle.listContainerView}>
      <ToastContainer />
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
          <View>
            <View style={tableStyle.headerListContainer}>
              <this.tableViewHeader />
            </View>
            <ScrollView style={styles.tableViewHeaderStyle}>
              <this.columnDataView />
            </ScrollView>
          </View>
        </ScrollView>
    </View>);
  }
  renderConfigsView = () => {
    if (this.state.addStorageHubBool) {
      return <SidePanView
        dissmissView={() => this.setState({ addStorageHubBool: !this.state.addStorageHubBool })}
        showSidepanView={this.state.addStorageHubBool}
        customView={<View>
          <StorageHubs
          storageData={this.state.storageListArray[this.state.selectedStorageHubIndex]}
          ref={child => { this.child = child }}
          backBtnAction={() => this.reloadAPI()}
          isEdit={this.state.isEdit} />    
              </View>}
        title={this.state.isEdit ? 'Edit Storage Hub' : 'Add Storage Hub'}
      />
    } else { return <View /> }
  }
  render() {
      return (
        <SafeAreaView style={styles.Container}>
          <View style={{ zIndex: 101 }}>
            {this.renderConfigsView()}
          </View>
          <View style={{ zIndex: 1 }}>
            <Header_View
              title={'Storage Hub Points'}
              backBtn={this.props.backBtnAction}
              saveBtn={() => this.setState({ addStorageHubBool: true, isEdit: false })}
              saveBtnTitle={'Add Storage Hub'}
            />
            <View style={styles.MainContainerViewStyle}>
              <this.renderMainView />
            </View>
            <Appload enable={this.state.dataLoad} />
          </View>
        </SafeAreaView>
      )
  }
}
const customWidth = 8.5

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : windowWidth / customWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / customWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  MainContainerViewStyle: {
    flex: 1,
    marginTop: 0,
  },
});


const tableHeaderString = [
  'Name',
  'Phone No',
  'Address',
  'Landmark',
  'State',
  'Post Code',
  'Country',
  'Action'
];