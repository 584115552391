import React from 'react';
import {StyleSheet, TouchableOpacity, Image} from 'react-native';
import edit_Icon from '../../assets/editIcon.png';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';

const EditButton = ({onClickEdit}) => {
  return (
    <TouchableOpacity onPress={onClickEdit}>
      <Image
        style={TableCommonStyleSheet.iconStyle}
        resizeMode="center"
        source={edit_Icon}
      />
    </TouchableOpacity>
  );
};

export default EditButton;
