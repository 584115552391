import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  FlatList,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import SlabView from './SlabView';
import Select from 'react-select';
import deleteIcon from './../assets/delete_icon.png';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from './../HelperClasses/AppToast';
import {Gradient} from 'react-gradient';
import commonStyle from './../StyleSheets/CommonStyleSheet';
import Switch from 'react-switch';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import {MixPannelEnum, revenuModelMixPanelEvent} from '../Models/MixPannelEnum';
import CommonStyleSheet from './../StyleSheets/CommonStyleSheet';
import downArrow_Icon from './../assets/downArrowIcon.png';
import appConfigStyle from './../StyleSheets/AppConfigStyleSheet';
import {CommissionTypeEnum} from '../Models/CommonEnum';
import {capitalizeFirstLetter} from '../HelperClasses/SingletonClass';
import addIcon from './../assets/add_icon.png';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstants from '../Constants/AppConstants';

const APPURL = require('./../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;
let TypeArray = ['Supply', 'Demand', 'Cart'];
let demandTypeArray = ['Fixed', 'Percentage'];

export default class AddCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      status: true,
      dataLoad: false,
      updateUI: false,
      slab: 0,
      addCommissionCategoryCount: 0,
      selectedCalegoryArray: [],
      chips: [],
      selectedItems: [],
      regexp: /^[0-9\.]+$/,
      commissionTitle: '',
      defaultValue: '',
      categoriesArray: [],
      id: '',
      showTypeDropDown: false,
      selectedType: TypeArray[0],
      selectedDemandArray: [],
      shortCode: '',
      optional: false,
      minimumAmount: '',
      maximumAmount: '',
      description: '',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    commissionID: PropTypes.string,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    this.loadCategoriesApi();
  }
  //MARK:-  APIS Method 
  loadCategoriesApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.categories + 'listings',
      'get',
      '',
      '',
    );
    // console.log('commission', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(
              hierarchyD[j],
              `${listD[i]['name']}-> ${hierarchyD[j]['name']}`,
            );
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(
                  hierarchy2[k],
                  `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`,
                );
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(
                      hierarchy3[l],
                      `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`,
                    );
                  }
                }
              }
            }
          }
        }
      }
      this.setState({updateUI: !this.state.updateUI});
      if (this.props.isEdit) {
        this.setState({dataLoad: false});
        this.loadCommissionDataApi();
      }
    }
  };
  manageData(data, name) {
    var deleteEnable = true;
    if (data['sub_category']) {
      deleteEnable = data['sub_category'].length == 0 ? true : false;
      if (data['sub_category'].length !== 0) {
        deleteEnable = data['parent'] == 0 ? false : true;
      }
    }
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = '';
    var level = 1;
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`;
        level = hierarchyAry[a]['level'];
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> ';
        }
      }
    }
    let catData = {
      value: data['id'],
      parent: data['parent'],
      label: hierarchyName,
    };
    const atriData = this.props.attributeData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (atriData != undefined) {
        let selectedData = atriData['categories'];
        for (let j = 0; j < selectedData.length; j++) {
          if (data['id'] == selectedData[j]['id']) {
            this.state.selectedCalegoryArray.push(catData);
          }
        }
      }
    }
    this.state.categoriesArray.push(catData);
  }
  loadCommissionDataApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.addCommissions + '/' + this.props.commissionID,
      'get',
    );
    if (responseJson['status'] == true) {
      var comisnD = responseJson['data']['commission'];
      console.log('comisnD', comisnD);
      this.setState({
        commissionTitle: comisnD['title'],
        defaultValue: comisnD['default_value'],
        status: comisnD['active'],
        selectedType: capitalizeFirstLetter(comisnD['type']),
        shortCode: comisnD['short_code'],
        description: comisnD?.['description'],
        optional: comisnD?.optional,
      });
      if (comisnD['type'] == TypeArray[2].toLowerCase()) {
        if (comisnD['commission_data']) {
          let comData = comisnD['commission_data'];
          this.state.minimumAmount = comData['min_amount'] ?? '';
          this.state.maximumAmount = comData['max_amount'] ?? '';
          this.state.optional = comData['optional'] ?? false;
        }
      } else {
        for (let a = 0; a < comisnD['commission_data'].length; a++) {
          let dict = comisnD['commission_data'][a];
          if (comisnD['type'] == 'supply') {
            let saveDict = {};
            saveDict['category'] = a + 1;
            if (dict['type'] == 1) {
              saveDict['type'] = 'fixed';
              saveDict['value'] = dict['fixed_value'];
              saveDict['slab'] = [];
            } else {
              var slabAry = [];
              for (let b = 0; b < dict['slab_value'].length; b++) {
                let sObj = dict['slab_value'][b];
                let sDict = {
                  fromValue: sObj['from'],
                  id: b + 1,
                  toValue: sObj['to'],
                  valuePercentage: sObj['value'],
                };
                slabAry.push(sDict);
              }
              saveDict['type'] = 'slab';
              saveDict['slab'] = slabAry;
            }
            var categoryAry = [];
            for (let b = 0; b < dict['category'].length; b++) {
              let cObj = dict['category'][b];
              var result = this.state.categoriesArray.find(obj => {
                return obj.value === cObj;
              });
              categoryAry.push(result);
            }
            saveDict['item'] = categoryAry;
            this.state.selectedCalegoryArray.push(saveDict);
          } else {
            let saveObj = {
              from: dict['from'],
              to: dict['to'],
              expand: false,
              value: dict['value'],
              type:
                dict['type'] == '1' ? demandTypeArray[0] : demandTypeArray[1],
            };
            this.state.selectedDemandArray.push(saveObj);
          }
          this.setState({
            addCommissionCategoryCount: a + 1,
          });
        }
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  addCommissionsApi = async () => {
    var dictParm = {
      title: this.state.commissionTitle || '',
      type: this.state.selectedType.toLowerCase(),
      default_value: Number(this.state.defaultValue) || 0,
      short_code: this.state.shortCode || '',
      active: this.state.status,
      optional: this.state.optional,
    };
    if (this.state.description.length != 0) {
      dictParm['description'] = this.state.description;
    }
    if (this.state.selectedType == CommissionTypeEnum.cart) {
      var dict = {
        min_amount: Number(this.state.minimumAmount),
        max_amount: Number(this.state.maximumAmount),
        optional: this.state.optional,
      };

      dictParm['commission_data'] = dict;
    } else {
      var commissionParm = [];
      for (let a = 0; a < this.state.selectedCalegoryArray.length; a++) {
        let dict = this.state.selectedCalegoryArray[a];
        var categoryParm = [];
        if (dict['item']) {
          for (let b = 0; b < dict['item'].length; b++) {
            if (dict['item'][b]['value']) {
              categoryParm.push(dict['item'][b]['value']);
            }
          }
        }
        if (this.state.selectedCalegoryArray[a]['type'] == 'fixed') {
          if (dict['value']) {
            let pramDict = {
              category: categoryParm,
              type: 1,
              fixed_value: Number(dict['value']),
            };
            commissionParm.push(pramDict);
          }
        } else {
          var slabParm = [];
          for (let b = 0; b < dict['slab'].length; b++) {
            let slabDict = {
              from: Number(dict['slab'][b]['fromValue']),
              to: Number(dict['slab'][b]['toValue']),
              value: Number(dict['slab'][b]['valuePercentage']),
            };
            slabParm.push(slabDict);
          }
          let pramDict = {
            category: categoryParm,
            type: 2,
            slab_value: slabParm,
          };
          commissionParm.push(pramDict);
        }
      }
      for (let a = 0; a < this.state.selectedDemandArray.length; a++) {
        let demandV = this.state.selectedDemandArray[a];
        let pramDict = {
          type: demandV['type'] == demandTypeArray[0] ? 1 : 2,
          from: Number(demandV['from']),
          to: Number(demandV['to']),
          value: Number(demandV['value']),
        };
        commissionParm.push(pramDict);
      }
      if (commissionParm.length != 0) {
        dictParm['commission_data'] = commissionParm;
      }
    }
    // console.log('commission_data', dictParm);
    this.setState({dataLoad: false});
    var path = APPURL.URLPaths.addCommissions;
    if (this.props.isEdit) {
      path = APPURL.URLPaths.addCommissions + '/' + this.props.commissionID;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.props.isEdit ? 'put' : 'post',
      JSON.stringify({commission: dictParm}),
      '',
    );
    // console.log('currency', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      revenuModelMixPanelEvent(MixPannelEnum.commission);
      this.props.backBtnAction(false);
      AppToast();
    } else {
      this.alertView(responseJson);
    }
  };
  //MARK:-  Buttons 
  successMessageAction() {
    if (Platform.OS === 'web') {
      const r = window.confirm('Successfully added');
      if (r == true) {
      }
    }
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  onHandleTextChange = text => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.setState({defaultValue: text});
    }
  };
  onChangeShortCode = text => {
    let value = text;
    let reg = /^[a-zA-Z\s]+$/;
    if (text == '' || reg.test(value)) {
      if (value.length < 7) {
        this.setState({shortCode: text.toUpperCase()});
      }
    }
  };
  //MARK:-  Button Actions
  submitButtonAction = () => {
    this.addCommissionsApi();
  };
  deleteCategoryButtonAction = index => {
    this.setState({
      addCommissionCategoryCount: this.state.addCommissionCategoryCount - 1,
    });
    if (this.state.selectedType == CommissionTypeEnum.supply) {
      this.state.selectedCalegoryArray.splice(index, 1);
    } else {
      this.state.selectedDemandArray.splice(index, 1);
    }
  };
  slabButtonAction = index => {
    let selectIndex = index;
    var slab = this.state.selectedCalegoryArray[selectIndex]['slab'];
    if (slab.length > 0) {
      let lastObj = slab[slab.length - 1];
      if (
        lastObj['fromValue'] &&
        lastObj['toValue'] &&
        lastObj['valuePercentage']
      ) {
        let a = slab.length;
        let category = this.state.selectedCalegoryArray[selectIndex];
        category['slab'].push({id: a + 1});
        this.state.selectedCalegoryArray[selectIndex] = category;
      }
    } else {
      let a = slab.length;
      let category = this.state.selectedCalegoryArray[selectIndex];
      category['slab'].push({id: a + 1});
      this.state.selectedCalegoryArray[selectIndex] = category;
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  deleteSlabButtonAction = (id, index) => {
    var slab = this.state.selectedCalegoryArray[id]['slab'];
    slab.splice(index, 1);
    let category = this.state.selectedCalegoryArray[id];
    category['slab'] = slab;
    this.state.selectedCalegoryArray[id] = category;
    this.setState({updateUI: !this.state.updateUI});
  };
  addCommissionCategoryButtonAction = index => {
    if (this.state.selectedType == CommissionTypeEnum.supply) {
      let category = {
        category: this.state.addCommissionCategoryCount + 1,
        slab: [],
        type: 'fixed',
      };
      this.state.selectedCalegoryArray.push(category);
    } else {
      let demandV = {
        expand: false,
        type: demandTypeArray[0],
      };
      this.state.selectedDemandArray.push(demandV);
    }
    this.setState({
      addCommissionCategoryCount: this.state.addCommissionCategoryCount + 1,
    });
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
    // console.log('statusToggel', !this.state.status);
  };
  handleChange = (selectedOption, index) => {
    // console.log('selectedOption =>', selectedOption);
    let category = this.state.selectedCalegoryArray[index];
    category['item'] = selectedOption;
    this.state.selectedCalegoryArray[index] = category;
    this.setState({updateUI: !this.state.updateUI});
  };
  showTypeDropDown(index) {
    this.setState({showTypeDropDown: !this.state.showTypeDropDown});
    let category = this.state.selectedCalegoryArray[index];
    category['expand'] =
      category['expand'] == undefined ? true : !category['expand'];
    this.state.selectedCalegoryArray[index] = category;
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectDropDown = item => {
    let index = item['id'];
    let category = this.state.selectedCalegoryArray[index];
    category['type'] = item['name'];
    this.state.selectedCalegoryArray[index] = category;
    if (item['name'] == 'slab') {
      let slab = category['slab'];
      if (slab.length == 0) {
        this.slabButtonAction(index);
      }
    } else {
      category['slab'] = [];
      this.state.selectedCalegoryArray[index] = category;
    }
    this.showTypeDropDown(index);
  };
  didSelectTypeCellItem = item => {
    this.state.selectedCalegoryArray = [];
    this.state.addCommissionCategoryCount = 0;
    this.setState({selectedType: item, showDropDown: false});
  };
  didSelectDemandTypeCellItem = item => {
    let index = item['id'];
    let demandV = this.state.selectedDemandArray[index];
    demandV['type'] = item['name'];
    this.state.selectedDemandArray[index] = demandV;
    this.showDemandTypeDropDown(index);
  };
  showDemandTypeDropDown(index) {
    this.setState({showTypeDropDown: !this.state.showTypeDropDown});
    let category = this.state.selectedDemandArray[index];
    category['expand'] =
      category['expand'] == undefined ? true : !category['expand'];
    this.state.selectedDemandArray[index] = category;
    this.setState({updateUI: !this.state.updateUI});
  }
  amountFieldsOnChange(value, id) {
    let reg = /^[0-9-.\b]+$/;
    var txtValue = '';
    if (value == '' || reg.test(value)) {
      txtValue = value;
    }
    if (id == 1) {
      this.state.minimumAmount = txtValue;
    } else if (id == 2) {
      this.state.maximumAmount = txtValue;
    }
    this.setState({updateUI: !this.state.updateUI});
  }

  //MARK:-  UIs
  typeDropDown = show => {
    if (show['show'] == true) {
      return (
        <View style={styles.dropDownBottomView}>
          <FlatList
            data={[
              {name: 'fixed', id: show['pIndex']},
              {name: 'slab', id: show['pIndex']},
            ]}
            horizontal={false}
            renderItem={this.renderTypeDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderTypeDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View
          style={{
            justifyContent: 'center',
            width: '100%',
            borderTopWidth: 1,
            borderTopColor: colors.Lightgray,
          }}>
          <Text
            style={[
              CommonStyleSheet.dropdownCellItemTextStyle,
              {paddingTop: 5, marginLeft: 0},
            ]}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  valueViewRender = show => {
    var caltegory = this.state.selectedCalegoryArray[show['pIndex']];
    if (show['show'] == 'fixed') {
      return (
        <View style={{margin: 16, flex: 2}}>
          <this.titleLblRender title={'Value'} />
          <TextInput
            style={[commonStyle.commissionValueTxtFieldStyle, {outline: 0}]}
            placeholder="Value"
            value={caltegory['value']}
            placeholderTextColor={colors.placeholderColor}
            onChangeText={value =>
              this.saveFixedValueField(value, show['pIndex'])
            }
          />
        </View>
      );
    }
    return <View></View>;
  };

  saveFixedValueField(value, pIndex) {
    // console.log('value ==> ', value, pIndex);
    if (value == '' || this.state.regexp.test(value)) {
      var category = this.state.selectedCalegoryArray[pIndex];
      category['value'] = value;
      this.state.selectedCalegoryArray[pIndex] = category;
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  fromValue(value, id) {
    var txtValue = '';
    if (value == '' || this.state.regexp.test(value)) {
      txtValue = value;
    }
    let slab = this.state.selectedDemandArray[id];
    slab['from'] = txtValue;
    this.state.selectedDemandArray[id] = slab;
    this.setState({updateUI: !this.state.updateUI});
  }
  toValue(value, id) {
    let reg = /^[0-9-\b]+$/;
    var txtValue = '';
    if (value == '' || reg.test(value)) {
      txtValue = value;
    }
    let slab = this.state.selectedDemandArray[id];
    slab['to'] = txtValue;
    this.state.selectedDemandArray[id] = slab;
    this.setState({updateUI: !this.state.updateUI});
  }
  valuePercentage(value, id) {
    let slab = this.state.selectedDemandArray[id];
    var txtValue = '';
    if (slab['type'] == demandTypeArray[0]) {
      let reg = /^[0-9\.]+$/;
      if (value == '' || reg.test(value)) {
        txtValue = value;
        slab['value'] = txtValue;
      }
    } else {
      if (value < 101 && (value == '' || this.state.regexp.test(value))) {
        slab['value'] = value;
      }
    }
    this.state.selectedDemandArray[id] = slab;
    this.setState({updateUI: !this.state.updateUI});
  }
  addCommissionByCategoryRender = () => {
    const {selectedOption} = this.state;
    if (this.state.addCommissionCategoryCount != 0) {
      var views = [];
      for (let i = 0; i < this.state.addCommissionCategoryCount; i++) {
        var SelectedItem = this.state.selectedCalegoryArray[i]['item'];
        var showType = this.state.selectedCalegoryArray[i]['expand'];
        var type = this.state.selectedCalegoryArray[i]['type'];
        var selectionView = [];
        if (this.state.categoriesArray.length != 0) {
          selectionView.push(
            <View style={{marginTop: 10, zIndex: 60049}}>
              <Select
                value={SelectedItem}
                onChange={itm => this.handleChange(itm, i)}
                options={this.state.categoriesArray}
                isMulti={true}
                placeholder={'Choose category'}
                styles={colourStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: colors.SimonGray,
                    primary: colors.Lightgray,
                  },
                })}
              />
            </View>,
          );
        } else {
          selectionView.push(
            <View
              style={{flexDirection: 'column', zIndex: 6030, marginTop: 10}}>
              <View style={styles.dropDownViewStyle}>
                <Text style={{paddingLeft: 15, color: colors.Appgray}}>
                  Create categories in listing configuration first
                </Text>
              </View>
            </View>,
          );
        }
        views.push(
          <View style={[styles.subViewContainerStyle, {zIndex: 9980 - i}]}>
            <View style={styles.addCommissionViewStyle}>
              <View style={styles.selecyCategoryViewStye}>
                <this.titleLblRender title={'Select Category'} />
                {selectionView}
              </View>
              <View style={[styles.selecyTypeViewStye]}>
                <this.titleLblRender title={'Select Type'} />
                <View
                  style={{
                    flexDirection: 'column',
                    zIndex: 6030,
                    marginTop: 10,
                  }}>
                  <TouchableOpacity
                    onPress={() => this.showTypeDropDown(i)}
                    style={styles.dropDownViewStyle}>
                    <Text style={{paddingLeft: 15}}>{type}</Text>
                    <Image
                      style={
                        showType == true
                          ? appConfigStyle.upArraowIconStyle
                          : appConfigStyle.downArraowIconStyle
                      }
                      resizeMode="contain"
                      source={downArrow_Icon}
                    />
                  </TouchableOpacity>
                  <this.typeDropDown show={showType} pIndex={i} />
                </View>
              </View>
              <View style={{flex: 4, flexDirection: 'row', zIndex: 6010}}>
                <this.valueViewRender show={type} pIndex={i} />
                <TouchableOpacity
                  style={styles.deleteBtnViewStye}
                  onPress={() => this.deleteCategoryButtonAction(i)}>
                  <Image
                    style={styles.iconImage}
                    source={deleteIcon}
                    resizeMode={'contain'}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{zIndex: 5}}>
              <SlabView
                valueArray={this.state.selectedCalegoryArray[i]}
                count={this.state.selectedCalegoryArray[i]['slab'].length}
                id={i}
                addBtn={id => this.slabButtonAction(id)}
                deleteBtn={(id, i) => this.deleteSlabButtonAction(id, i)}
              />
            </View>
          </View>,
        );
      }
      return views;
    } else {
      return <View />;
    }
  };

  loaderView = () => {
    return <Appload enable={this.state.dataLoad} />;
  };
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={appConfigStyle.showDropDownViewStyle}>
          <FlatList
            data={TypeArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectTypeCellItem(item)}
        style={{margin: 5, height: 30}}>
        <View style={{width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Text
          style={
            this.state.selectedType == ''
              ? appConfigStyle.placeholderTxtStyle
              : appConfigStyle.txtFieldStyleDropDown
          }>
          {this.state.selectedType == ''
            ? 'Select Category'
            : this.state.selectedType}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? appConfigStyle.upArraowIconStyle
              : appConfigStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  saveFieldValues(value, pIndex) {
    if (value == '' || this.state.regexp.test(value)) {
      var category = this.state.selectedDemandArray[pIndex];
      category['value'] = value;
      this.state.selectedDemandArray[pIndex] = category;
    } else {
      var category = this.state.selectedDemandArray[pIndex];
      category['value'] = '';
      this.state.selectedDemandArray[pIndex] = category;
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  demandTypeDropDown = show => {
    if (show['show'] == true) {
      return (
        <View style={styles.dropDownBottomView}>
          <FlatList
            data={[
              {name: demandTypeArray[0], id: show['dIndex']},
              {name: demandTypeArray[1], id: show['dIndex']},
            ]}
            horizontal={false}
            renderItem={this.renderDemandTypeDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDemandTypeDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDemandTypeCellItem(item)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View
          style={{
            justifyContent: 'center',
            width: '100%',
            borderTopWidth: 1,
            borderTopColor: colors.Lightgray,
          }}>
          <Text
            style={[
              CommonStyleSheet.dropdownCellItemTextStyle,
              {paddingTop: 5, marginLeft: 0},
            ]}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderAdvancedView = () => {
    if (this.state.addCommissionCategoryCount != 0) {
      var views = [];
      for (let i = 0; i < this.state.addCommissionCategoryCount; i++) {
        let demandV = this.state.selectedDemandArray[i];
        var showType = demandV['expand'] || false;
        var type = demandV['type'] || '';
        var fromV = demandV['from'] ?? '';
        var toV = demandV['to'] ?? '';
        var valueV = demandV['value'] ?? '';
        let valueTitle = type == demandTypeArray[0] ? '' : '(%)';

        views.push(
          <View style={[styles.subViewContainerStyle, {zIndex: 9980 - i}]}>
            <View style={{marginTop: 0}}>
              <Text style={CommonStyleSheet.hintTitleStyle}>
                * To value put -1 for Unlimited
              </Text>
            </View>
            <View style={styles.addCommissionViewStyle}>
              <View style={{margin: 16, flex: 3}}>
                <this.titleLblRender title={'From'} />
                <TextInput
                  style={[
                    commonStyle.commissionValueTxtFieldStyle,
                    {outline: 0},
                  ]}
                  placeholder="Enter from"
                  value={fromV}
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={value => this.fromValue(value, i)}
                />
              </View>
              <View style={{margin: 16, flex: 3}}>
                <this.titleLblRender title={'To'} />
                <TextInput
                  style={[
                    commonStyle.commissionValueTxtFieldStyle,
                    {outline: 0},
                  ]}
                  placeholder="Enter to"
                  value={toV}
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={value => this.toValue(value, i)}
                />
              </View>
              <View style={styles.selecyTypeViewStye}>
                <this.titleLblRender title={'Select Type'} />
                <View
                  style={{
                    flexDirection: 'column',
                    zIndex: 6030,
                    marginTop: 10,
                  }}>
                  <TouchableOpacity
                    onPress={() => this.showDemandTypeDropDown(i)}
                    style={[styles.dropDownViewStyle]}>
                    <Text style={{paddingLeft: 15}}>{type}</Text>
                    <Image
                      style={
                        showType == true
                          ? appConfigStyle.upArraowIconStyle
                          : appConfigStyle.downArraowIconStyle
                      }
                      resizeMode="contain"
                      source={downArrow_Icon}
                    />
                  </TouchableOpacity>
                  <this.demandTypeDropDown show={showType} dIndex={i} />
                </View>
              </View>
              <View style={{margin: 16, flex: 3}}>
                <this.titleLblRender title={`Value ${valueTitle}`} />
                <TextInput
                  style={[
                    commonStyle.commissionValueTxtFieldStyle,
                    {outline: 0},
                  ]}
                  placeholder="Enter Value"
                  value={valueV}
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={value => this.valuePercentage(value, i)}
                />
              </View>
              <View style={{flex: 1, flexDirection: 'row', zIndex: 6010}}>
                <TouchableOpacity
                  style={styles.deleteBtnViewStye}
                  onPress={() => this.deleteCategoryButtonAction(i)}>
                  <Image
                    style={styles.iconImage}
                    source={deleteIcon}
                    resizeMode={'center'}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>,
        );
      }
      return views;
    } else {
      return <View />;
    }
  };

  renderBottomView = () => {
    if (this.state.selectedType == CommissionTypeEnum.supply) {
      return <this.addCommissionByCategoryRender />;
    } else {
      return <this.renderAdvancedView />;
    }
  };
  renderAddRowBtnView = () => {
    return (
      <View
        style={{
          display: this.state.addCommissionCategoryCount > 0 ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}>
        <View style={TableCommonStyleSheet.secondButtonViewStyle}>
          <TouchableOpacity
            style={styles.addRowViewStyle}
            onPress={() => this.addCommissionCategoryButtonAction()}>
            <Image
              style={TableCommonStyleSheet.iconStyle}
              resizeMode="center"
              source={addIcon}
            />
            <Text style={TableCommonStyleSheet.secondBtnTextStyle}>
              {'Add Row'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderCartView = () => {
    return (
      <View
        style={{
          display:
            this.state.selectedType == CommissionTypeEnum.cart
              ? 'flex'
              : 'none',
        }}>
        <View style={styles.subViewStyle}>
          <this.titleLblRender title={'Minimum amount'} />
          <TextInput
            style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
            value={this.state.minimumAmount}
            placeholder="Enter minimum amount"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={name => this.amountFieldsOnChange(name, 1)}
          />
        </View>
        <View style={styles.subViewStyle}>
          <this.titleLblRender title={'Maximum amount'} />
          <TextInput
            style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
            value={this.state.maximumAmount}
            placeholder="Enter maximum amount"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={name => this.amountFieldsOnChange(name, 2)}
          />
        </View>
        <View style={styles.subViewStyle}>
          <Text style={commonStyle.titleNameStyle}>{'Notes'} </Text>
          <TextInput
            style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
            value={this.state.description}
            placeholder="Enter note"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={name => this.setState({description: name})}
          />
        </View>
        <View style={[commonStyle.switchViewStyle, {zIndex: 10}]}>
          <Text style={commonStyle.titleNameStyle}>Optional</Text>
          <View
            style={{
              width: itsMobileView ? '12%' : '80%',
              flexDirection: 'row',
            }}>
            <Switch
              onColor={colors.AppNewGreen}
              onChange={() => this.setState({optional: !this.state.optional})}
              checked={this.state.optional}
              height={22}
              width={50}
            />
          </View>
        </View>
      </View>
    );
  };

  render() {
    let isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={isEdit ? 'Edit Commission' : 'Add Commission'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}
        />
        <ScrollView>
          <View style={styles.mainView}>
            <View style={styles.listItemView}>
              <View style={{height: 15, width: '100%'}} />
              <View style={[styles.subViewStyle, {zIndex: 1000}]}>
                <this.titleLblRender title={'Type'} />
                <TouchableOpacity
                  disabled={isEdit ? true : false}
                  style={[appConfigStyle.dropDownViewStyle, {marginTop: 0}]}
                  onPress={() =>
                    this.setState({showDropDown: !this.state.showDropDown})
                  }>
                  <this.renderValueLabel id={0} />
                  <this.customDropDown id={0} />
                </TouchableOpacity>
              </View>
              <View style={styles.subViewStyle}>
                <this.titleLblRender title={'Title'} />
                <TextInput
                  style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                  value={this.state.commissionTitle}
                  placeholder="Enter title"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({commissionTitle: name})}
                />
              </View>
              {this.state.selectedType !== CommissionTypeEnum.cart && (
                <View style={styles.subViewStyle}>
                  <Text style={commonStyle.titleNameStyle}>
                    {'Description'}{' '}
                  </Text>
                  <TextInput
                    style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                    value={this.state.description}
                    placeholder="Enter description"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({description: name})}
                  />
                </View>
              )}
              <View
                style={[
                  styles.subViewStyle,
                  {
                    display:
                      this.state.selectedType == CommissionTypeEnum.cart
                        ? 'none'
                        : 'flex',
                  },
                ]}>
                <this.titleLblRender title={'Default value (%)'} />
                <TextInput
                  style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                  placeholder="Default value (%)"
                  placeholderTextColor={colors.placeholderColor}
                  keyboardType={'decimal-pad'}
                  value={this.state.defaultValue}
                  onChangeText={text => {
                    this.onHandleTextChange(text);
                  }}
                />
              </View>
              <View style={[styles.subViewStyle, {zIndex: 101}]}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <this.titleLblRender title={'Short Code'} />
                  <ToolTipViewBtn
                    title={AppConstantsMessage.shortCodeTitleToopTipMsg}
                    description={
                      AppConstantsMessage.shortCodeSubTitleToolTipMsg
                    }
                  />
                </View>
                <TextInput
                  editable={isEdit ? false : true}
                  style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                  placeholder="Enter short code"
                  placeholderTextColor={colors.placeholderColor}
                  keyboardType={'decimal-pad'}
                  value={this.state.shortCode}
                  onChangeText={text => this.onChangeShortCode(text)}
                />
              </View>
              {this.renderCartView()}
              <View style={[commonStyle.switchViewStyle, {zIndex: 10}]}>
                <Text style={commonStyle.titleNameStyle}>
                  Status(Not Active / Active){' '}
                </Text>
                <View
                  style={{
                    width: itsMobileView ? '12%' : '80%',
                    flexDirection: 'row',
                  }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={this.statusToggle}
                    checked={this.state.status}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
              {this.state.selectedType === TypeArray[1] && (
                <View style={[commonStyle.switchViewStyle, {zIndex: 10}]}>
                  <Text style={commonStyle.titleNameStyle}>Optional</Text>
                  <View
                    style={{
                      width: itsMobileView ? '12%' : '80%',
                      flexDirection: 'row',
                    }}>
                    <Switch
                      onColor={colors.AppNewGreen}
                      onChange={() =>
                        this.setState({optional: !this.state.optional})
                      }
                      checked={this.state.optional}
                      height={22}
                      width={50}
                    />
                  </View>
                </View>
              )}

              <View
                style={{
                  alignItems: 'flex-end',
                  overflow: 'hidden',
                  paddingRight: 10,
                  display:
                    this.state.selectedType == CommissionTypeEnum.cart
                      ? 'none'
                      : 'flex',
                }}>
                <View style={TableCommonStyleSheet.secondButtonViewStyle}>
                  <TouchableOpacity
                    disabled={
                      this.state.addCommissionCategoryCount == 0 ? false : true
                    }
                    onPress={() => this.addCommissionCategoryButtonAction()}>
                    <Text style={TableCommonStyleSheet.secondBtnTextStyle}>
                      {this.state.selectedType == CommissionTypeEnum.demand
                        ? 'Advanced'
                        : 'Add commission by category'}
                    </Text>
                  </TouchableOpacity>
                </View>
                {/* <TouchableOpacity
                  style={tableStyle.addCurrencyViewStyle}
                  onPress={() => this.addCommissionCategoryButtonAction()}>
                  <Text style={tableStyle.addCurrencyTextStyle}>
                    Add Commission By Category
                  </Text>
                </TouchableOpacity> */}
              </View>
              <View style={{zIndex: 500}}>{this.renderBottomView()}</View>
              <View style={{zIndex: 200}}>{this.renderAddRowBtnView()}</View>
              <View style={{height: 30, width: '100%'}} />
            </View>
          </View>
        </ScrollView>
        <View style={{height: 50, width: '100%'}} />
        <this.loaderView />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: 5,
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
  },
  listItemView: {
    backgroundColor: 'white',
    width: '100%',
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 20,
    zIndex: 1,
  },
  submitViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    backgroundColor: colors.Appgreen,
    alignSelf: 'center',
    width: 80,
  },
  resetViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignSelf: 'center',
    width: 80,
  },
  statusViewStyle: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    alignItems: 'center',
    zIndex: 5,
  },
  switchStyle: {
    marginLeft: 190,
  },
  addCommissionViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    zIndex: 6001,
  },
  selecyCategoryViewStye: {
    margin: 16,
    backgroundColor: 'white',
    flex: 6,
    // height: 100,
    zIndex: 60090,
  },
  selecyTypeViewStye: {
    margin: 16,
    flex: 3,
    zIndex: 60080,
  },
  selecyValueViewStye: {
    margin: 16,
    backgroundColor: 'white',
    flex: 2,
    height: 100,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.4,
    shadowOffset: {width: 2, height: 2},
    shadowRadius: 5,
    backgroundColor: 'red',
  },
  deleteBtnViewStye: {
    margin: 16,
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ValueViewStyle: {
    margin: 16,
    backgroundColor: 'white',
    flex: 2,
    height: 100,
    zIndex: 43,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.4,
    shadowOffset: {width: 2, height: 2},
    shadowRadius: 5,
  },
  dropDownViewStyle: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.Lightgray,
    width: '80%',
    marginLeft: 0,
    height: 40,
    alignItems: 'center',
    zIndex: 102,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownBottomView: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderTopWidth: 0,
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    borderColor: colors.Lightgray,
    width: '80%',
    marginTop: 30,
    zIndex: 105,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 1,
    height: 70,
  },
  iconImage: {
    height: 26,
    width: 26,
    alignSelf: 'center',
  },
  subViewContainerStyle: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: colors.Lightgray,
    margin: 20,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 2,
    borderRadius: 10,
  },
  addRowViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
