import networkService from '../../Network/NetworkManager';
const APPURL = require('./../../Constants/URLConstants');

export const getConfigsByKey = async keyGroup => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${keyGroup}`,
      'get',
    );
    return responseJson?.data?.configs;
  } catch (error) {
    return error;
  }
};
