import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import {Gradient} from 'react-gradient';

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';

export default class OPPSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      apiKey: '',
      secretKey: '',
      hideApiKey: false,
      hideSecretKey: false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + KeyGroup,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        apiKey: configs[keysArray[0]] || '',
        secretKey: configs[keysArray[1]] || '',
      });
    }
    this.setState({dataLoad: true});
  };

  addOrUpdateAppDetailApi = async filesData => {
    var configs = [];
    let valueArray = [this.state.apiKey, this.state.secretKey];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: true,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.deleteKeysAPI(configs);
      this.setState({dataLoad: true});
      // this.alertView(responseJson)
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete');
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  renderSaveConfigBtn = () => {
    return (
      <View>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.submitBtnAction()}>
              <Text style={tableStyle.saveBtnTxtStyle}>{'Save'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={{flex: 1, padding: 10}}>
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={[commonStyle.titleNameStyle]}>API Key</Text>
                <View
                  style={[
                    commonStyle.customTxtFieldViewStyle,
                    {width: '100%', marginTop: 0},
                  ]}>
                  <TextInput
                    style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                    placeholder="Enter api key "
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({apiKey: name})}
                    value={this.state.apiKey}
                    secureTextEntry={!this.state.hideApiKey}
                  />
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({hideApiKey: !this.state.hideApiKey})
                    }>
                    <Image
                      style={{width: 15, height: 15}}
                      resizeMode="contain"
                      source={this.state.hideApiKey ? showIcon : hideIcon}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={commonStyle.configContainerViewStyle}>
                <Text style={commonStyle.titleNameStyle}>
                  Notification Secret
                </Text>
                <View
                  style={[
                    commonStyle.customTxtFieldViewStyle,
                    {width: '100%', marginTop: 0},
                  ]}>
                  <TextInput
                    style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                    placeholder="Enter notification secret"
                    placeholderTextColor={colors.placeholderColor}
                    onChangeText={name => this.setState({secretKey: name})}
                    value={this.state.secretKey}
                    secureTextEntry={!this.state.hideSecretKey}
                  />
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({hideSecretKey: !this.state.hideSecretKey})
                    }>
                    <Image
                      style={{width: 15, height: 15}}
                      resizeMode="contain"
                      source={this.state.hideSecretKey ? showIcon : hideIcon}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
});

let keysArray = ['opp_api_key', 'opp_webhook_secret'];
