import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
  Image,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {
  dateConversionFromTimeStamp,
  getThumbnailImage,
} from '../HelperClasses/SingletonClass';
import statusModel from '../Models/StatusModel';
import placeholder from '../assets/pPlaceholder.png';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import OrderDetails from '../Orders/OrderDetails';
import AppConstants from '../Constants/AppConstants';
import {withRouter} from '../wrappers/withRouter';
import {currentOrderStatus} from '../Constants/Status';
import UserAttributes from './UserAttributes';
import UserDetails from './UserDetails';
import UserOrderList from './UserOrderList';

const APPURL = require('../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height - 120;

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      ordersArray: [],
      showOrderDetail: false,
      orderID: '',
      userData: '',
    };
  }

  componentDidMount() {
    this.loadUserDetailApi();
    this.loadOrderDetailApi();
  }
  loadUserDetailApi = async () => {
    const responseJson = await networkService.networkCall(
      `${APPURL.URLPaths.user}/${this.props.params.userID}`,
      'get',
    );
    if (responseJson['status'] == true) {
      var serD = responseJson['data']['user'];
      this.state.userData = serD;
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  loadOrderDetailApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.ordersList +
        `1&per_page=50&user_id=${this.props.params.userID}`,
      'get',
    );
    if (responseJson['status'] == true) {
      var ordrD = responseJson['data']['orders'];
      for (let obj of ordrD) {
        this.state.ordersArray.push(obj);
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };

  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={'User Detail'}
          backBtn={() => this.props.navigate(-1)}
          showSaveBtn={false}
        />
        <View style={styles.mainView}>
          <UserDetails user_details={this.state.userData} />

          {this.state.userData?.attributes?.length > 0 && (
            <>
              <View
                style={{
                  height: 1,
                  width: '100%',
                  backgroundColor: colors.Lightgray,
                }}
              />
              <UserAttributes attributes={this.state.userData?.attributes} />
            </>
          )}

          <View
            style={{
              height: 1,
              width: '100%',
              backgroundColor: colors.Lightgray,
            }}
          />
          <UserOrderList orders_list={this.state.ordersArray} />
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

export default withRouter(UserDetail);

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    height: windowHeight - 20,
  },
  horizontalCellItemStyle: {
    width: '70%',
    margin: 5,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    padding: 10,
    flexDirection: 'row',
    // justifyContent: 'space-between'
  },
});
const tableHeaderString = [
  'Description',
  'Unit Price',
  'Discount',
  'Qty',
  'Tax Rate',
  'Total Amount',
];
