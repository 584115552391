import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Image,
  SafeAreaView,
  I18nManager,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import {AppToast} from '../../HelperClasses/AppToast';
import branchesIcon from '../../assets/branchesIcon.png';
import orderIcon from '../../assets/orderIcon.png';
import driver from '../../assets/driver.png';
import fleetsIcon from '../../assets/fleetsIcon.png';
import tickIcon from '../../assets/tickIcon.png';
import partyIcon from '../../assets/partyIcon.png';
import {ToastContainer} from 'react-toastify';
import AppConstants from '../../Constants/AppConstants';
import APPURL from '../../Constants/URLConstants';
import AddFleet from '../Fleets/AddFleet';
import AddBranch from '../Branches/AddBranch';
import AddDriver from '../Drivers/AddDriver';
import appStore_Icon from '../../assets/appleLogo.png';
import playStore_Icon from '../../assets/playStore.png';

import {LocalStrings} from '../../LocalizedStrings/LocalStrings';
import LocalizedStrings from 'react-localization';
import {addMixpanelEvent, MixpanelEvent} from '../../Models/MixPannelEnum';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
let strings = new LocalizedStrings(LocalStrings);

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let regexp = /^[0-9\.]+$/;
const alignment = I18nManager.isRTL ? 'right' : 'left';
const direction = I18nManager.isRTL ? 'rtl' : 'ltr';

export default class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoad: true,
      haveBranchBool: false,
      haveFleetBool: false,
      haveDriverBool: false,
      addBranchBool: false,
      addFleetBool: false,
      addDriverBool: false,
      haveOrdersBool: false,
      dataArray: [],
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    branchData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.deliveryGetStarted);
    this.getData();
    strings.setLanguage(AppConstants.appLanguage);
    this.setState({updateUI: this.state.updateUI});
    this.setState({updateUI: this.state.updateUI});
  }
  getData() {
    this.loadDashboardApi();
  }
  loadDashboardApi = async type => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.deliveryDriverDashboard,
      'get',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      let data = responseJson['data'];
      if (data['branches'] != 0) {
        this.setState({haveBranchBool: true});
      }
      if (data['fleets'] != 0) {
        this.setState({haveFleetBool: true});
      }
      if (data['drivers'] != 0) {
        this.setState({haveDriverBool: true});
      }
      if (data['orders'] != 0) {
        this.setState({haveOrdersBool: true});
      }
    }
    this.setState({dataLoad: true});
  };
  customAlert() {
    AppToast();
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:-  Button Actions
  addBrnachBtnAction = () => {
    this.setState({addBranchBool: !this.state.addBranchBool});
    if (!this.state.addBranchBool) {
      this.loadBranchesApi();
    }
  };
  addBrnachBtnAction = () => {
    this.setState({addBranchBool: !this.state.addBranchBool});
    if (!this.state.addBranchBool) {
      this.loadBranchesApi();
    }
  };
  addFleetBtnAction = () => {
    this.setState({addFleetBool: !this.state.addFleetBool});
    if (!this.state.addFleetBool) {
      this.loadFleetsApi();
    }
  };
  addDriverBtnAction = () => {
    this.setState({addDriverBool: !this.state.addDriverBool});
    if (!this.state.addDriverBool) {
      this.loadDriverApi();
    }
  };
  closeBtnAction() {
    this.setState({addBranchBool: false});
    this.setState({addFleetBool: false});
    this.setState({addDriverBool: false});
    this.getData();
  }
  handleSelectChange = selectedOption => {
    this.state.selectedTimeZone = selectedOption;
    this.setState({updateUI: !this.state.updateUI});
  };
  onHandleTextChange(text, id) {
    let value = text;
    if (id == 1) {
      if (text == '' || regexp.test(value)) {
        this.setState({lat: text});
      }
    } else {
      if (text == '' || regexp.test(value)) {
        this.setState({long: text});
      }
    }
  }
  addBtnAction(id) {
    if (id == 0) {
      this.mixPanelInfo(strings.addbranch);
      this.addBrnachBtnAction();
    } else if (id == 1) {
      this.mixPanelInfo(strings.addFleet);
      this.addFleetBtnAction();
    } else if (id == 3) {
      this.mixPanelInfo(strings.apiDocumentation);
      window.open(AppConstants.docsURL, '_blank');
    } else {
      this.mixPanelInfo(strings.addDriver);
      this.addDriverBtnAction();
    }
  }
  linkBtnAction = async id => {
    var pathURL = '';
    if (id == 1) {
      pathURL = AppConstants.deliveryAppStoreLink;
    } else {
      pathURL = AppConstants.deliveryPlayStoreLink;
    }
    this.mixPanelInfo(pathURL);
    window.open(pathURL, '_blank');
  };
  mixPanelInfo(value) {
    addMixpanelEvent(MixpanelEvent.deliveryGetStarted, {Action: value});
  }
  // UI Renders
  renderElementView = () => {
    var view = [];
    const staticData = [
      {
        title: strings.addyourbranch,
        subTitle: strings.aBranchcanhavemanyfleetsanddrivers,
        image: branchesIcon,
        button: strings.addbranch,
      },
      {
        title: strings.addyourfleet,
        subTitle: strings.afleetcanbeassigneddriver,
        image: fleetsIcon,
        button: strings.addFleet,
      },
      {
        title: strings.addYourDriver,
        subTitle: strings.driverwilldeliveryour,
        image: driver,
        button: strings.addDriver,
      },
      {
        title: strings.createFirstOrder,
        subTitle: strings.ordercanbecreatedbyAPI,
        image: orderIcon,
        button: strings.apiDocumentation,
      },
    ];
    for (let a = 0; a < staticData.length; a++) {
      let item = staticData[a];
      view.push(
        <View style={styles.elementViewsStyle}>
          <Image
            style={styles.iconImageViewStyle}
            resizeMode="contain"
            source={item['image']}
          />
          <View style={{height: 16}} />
          <Text style={CommonStyleSheet.plainTextStyle}>{item['title']}</Text>
          <View style={{height: 10}} />
          <Text style={CommonStyleSheet.subTitleStyle}>{item['subTitle']}</Text>
          <TouchableOpacity onPress={() => this.addBtnAction(a)}>
            <Text style={[CommonStyleSheet.addButtonOnGetStartTextStyle]}>
              {item['button']}
            </Text>
          </TouchableOpacity>
        </View>,
      );
    }
    return <View style={styles.containerView}>{view}</View>;
  };

  renderProgressView = () => {
    var views = [];
    let titleArray = [
      strings.branches,
      strings.fleets,
      strings.drivers,
      strings.deliveryOrders,
    ];
    var completeUpto = 0;
    if (this.state.haveBranchBool) {
      completeUpto = 1;
    }
    if (this.state.haveFleetBool) {
      completeUpto = 2;
    }
    if (this.state.haveDriverBool) {
      completeUpto = 3;
    }
    if (this.state.haveOrdersBool) {
      completeUpto = 4;
    }
    for (let a = 0; a < 4; a++) {
      views.push(
        <View
          style={{width: 70, alignItems: 'center', justifyContent: 'center'}}>
          <View
            style={
              a >= completeUpto
                ? styles.unCompleteStatusViewStyle
                : styles.statusViewStyle
            }>
            <View>
              <Text
                style={[
                  CommonStyleSheet.plainTextStyle,
                  {
                    textAlign: alignment,
                    color: colors.Appgray,
                    display: a >= completeUpto ? 'flex' : 'none',
                  },
                ]}>
                {a + 1}
              </Text>
              <Image
                source={a == 3 ? partyIcon : tickIcon}
                style={{
                  height: 24,
                  width: 24,
                  display: a >= completeUpto ? 'none' : 'flex',
                }}
              />
            </View>
          </View>
          <Text
            style={[
              CommonStyleSheet.subTitleStyle,
              {marginTop: 10, textAlign: 'center'},
            ]}>
            {titleArray[a]}
          </Text>
        </View>,
      );
      views.push(
        <View
          style={[
            styles.statusViewStyle,
            {
              display: a == 3 ? 'none' : 'flex',
              backgroundColor: colors.TransparentColor,
            },
          ]}>
          <View
            style={{
              height: 6,
              width: 120,
              borderRadius: 25,
              backgroundColor:
                a >= completeUpto ? colors.Lightgray : colors.Appgreen,
            }}></View>
        </View>,
      );
    }
    return views;
  };
  rendeDownloadAppView = () => {
    return (
      <View>
        <Text style={CommonStyleSheet.plainTextStyle}>Download apps</Text>
        <View style={{marginTop: 5}} />
        <Text style={CommonStyleSheet.plainSubTextStyle}>
          Apps for your drivers to manage tasks
        </Text>
        <View style={{width: 10, height: 10}} />
        <View style={styles.downloadBtnViewStyle}>
          <TouchableOpacity onPress={() => this.linkBtnAction(1)}>
            <View style={styles.githubBackViewStyle}>
              <Image style={styles.githubIconStyle} source={appStore_Icon} />
              <Text style={styles.textStyle}> App Store</Text>
            </View>
          </TouchableOpacity>
          <View style={{width: 20, height: 20}} />
          <TouchableOpacity onPress={() => this.linkBtnAction(2)}>
            <View style={styles.githubBackViewStyle}>
              <Image
                style={{width: 40, height: 30, marginLeft: -5}}
                resizeMode={'center'}
                source={playStore_Icon}
              />
              <Text style={[styles.textStyle, {color: 'black'}]}>
                Play Store
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  renderHeaderView = () => {
    return (
      <View style={styles.headerViewstyle}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '60%',
            alignSelf: 'center',
          }}>
          {this.renderProgressView()}
        </View>
      </View>
    );
  };
  render() {
    if (this.state.addBranchBool) {
      return (
        <View>
          <ToastContainer />
          <AddBranch
            backBtnAction={() => this.addBrnachBtnAction()}
            closeBtnAction={() => this.closeBtnAction()}
          />
        </View>
      );
    }
    if (this.state.addFleetBool) {
      return (
        <View>
          <ToastContainer />
          <AddFleet
            backBtnAction={() => this.addFleetBtnAction()}
            closeBtnAction={() => this.closeBtnAction()}
          />
        </View>
      );
    }
    if (this.state.addDriverBool) {
      return (
        <View>
          <ToastContainer />
          <AddDriver
            backBtnAction={() => this.addDriverBtnAction()}
            closeBtnAction={() => this.closeBtnAction()}
          />
        </View>
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ScrollView>
            <View style={styles.mainView}>
              <Text style={CommonStyleSheet.welcomeTitleStyle}>
                {strings.welcome} Tradly, {AppConstants.userFullName}!
              </Text>
              <Text style={CommonStyleSheet.welcomeSubTitleTextStyle}>
                {strings.whatwouldyoulike}
              </Text>
              <View style={{height: 30}} />
              <View style={{alignSelf: 'center'}}>
                {this.renderElementView()}
              </View>
              <View style={{height: 30}} />
              <this.renderHeaderView />
              <View style={{alignItems: 'flex-end', marginTop: 40}}>
                <View style={styles.boxContainerStyle}>
                  {this.rendeDownloadAppView()}
                </View>
              </View>
            </View>
          </ScrollView>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}

const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    height: windowHeight,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 20,
    // backgroundColor:colors.AppWhite,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    paddingTop: 0,
  },
  containerView: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 10,
    width: itsMobileView ? windowWidth : windowWidth / 1.32,
  },
  elementViewsStyle: {
    margin: 10,
    backgroundColor: colors.AppWhite,
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 220,
    width: 250,
    padding: 20,
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 120,
  },
  iconImageViewStyle: {
    height: 40,
    width: 40,
  },
  buttonTextStyle: {
    fontWeight: '600',
    textAlign: 'left',
    marginTop: 10,
    textDecorationLine: 'underline',
  },
  headerViewstyle: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 120,
    height: 100,
  },
  statusViewStyle: {
    height: 60,
    width: 60,
    borderRadius: 30,
    backgroundColor: colors.Appgreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  unCompleteStatusViewStyle: {
    height: 60,
    width: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.SimonGray,
    borderWidth: 2,
  },
  downloadBtnViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  githubBackViewStyle: {
    height: 40,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 1,
  },
  githubIconStyle: {
    width: 25,
    height: 25,
  },
  boxContainerStyle: {
    marginTop: 20,
    backgroundColor: 'white',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    borderRadius: 5,
    padding: 16,
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 300,
    borderWidth: 1,
    borderColor: colors.Lightgray,
  },
});
