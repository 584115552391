import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TextInput,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from "react-switch";
import { addMixpanelEvent, MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');

const KeyGroup = 'general'
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class GoogleIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusArray: [false, false, false, false],
      negotiateValue: false,
      dataLoad: false,
      clientID: ''
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`, 'get', '', '');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.state.clientID = configs['google_client_id'] || '';
      this.state.statusArray[0] = configs['google_sign_in'] || false;
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateChatApi = async () => {
    var configs = [];
    if (this.state.clientID.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: 'google_client_id',
        value: this.state.clientID,
        secured: false,
      };
      configs.push(fDict)
    }
    for (let i = 0; i < keyParameterArray.length; i++) {
      let fDict = {
        key_group: KeyGroup,
        key: keyParameterArray[i],
        value: this.state.statusArray[i],
        secured: false,
      };
      configs.push(fDict);
    }

    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post',  JSON.stringify({configs: configs}))
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      mobileIntegrationMixPanelEvent(MixpanelEvent.googleIntegration)
      this.deleteKeysAPI(configs)
    } else {
      AppToast(responseJson)
    }
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = []
    for (let q = 0; q < DeletekeyParameterArray.length; q++) {
      deleteParms.push(DeletekeyParameterArray[q])
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString()
      let urlParm = APPURL.URLPaths.config + '?key=' + parms
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      this.setState({dataLoad: true})
      AppToast()
    } else {
      this.setState({dataLoad: true})
      AppToast()
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {   
      this.setState({ dataLoad: false });
      this.addOrUpdateChatApi()
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  //MARK:-  UI 
  renderAllSwitches = (id) => {
    var views = [];
    let i = id['id'];
    views.push(
      <View style={{ flexDirection: 'row', marginTop: 0, alignItems: 'center' }}>
        <Switch
          onColor={colors.AppNewGreen}
          onChange={() => this.switchSattusBtnAction(i)}
          checked={this.state.statusArray[i]}
          height={22}
          width={50}
        />
        <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[i]}</Text>
      </View>,
    );
    
    return views;
  };
  render() {
    return (
      <View style={{width:itsMobileView ? '95%' : '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Google Auth</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plain2TextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{ flexDirection: 'column', flex: 1.5 }}>
            <View style={{ marginTop: -20 }}>
              <this.renderAllSwitches id={0} />
            </View>
            <View style={{ marginTop: 20,display:this.state.statusArray[0] == true ? 'flex' : 'none'}}>
              <Text style={appConfigStyle.titleTxtStyle}>Client id</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter client id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({ clientID: text })}
                value={this.state.clientID}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const switchTitleStrings = [
  'Enable Google SignIn',
];
let keyParameterArray = [
  'google_sign_in',
];

let DeletekeyParameterArray = [
  'google_sign_in',
  'google_client_id',
]