
import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, seoMixPanelEvent } from '../../Models/MixPannelEnum';
const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'seo';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

let accountTitle = '{account_title}'
let categoryTitle = '{category}'

export default class SEOAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      metaAccTitle: accountTitle,
      metaAccDescription: accountTitle,
      metaAccCatTitle: categoryTitle,
      metaAccCatDescription: categoryTitle,
    }
    this.saveButtonAction = this.saveButtonAction.bind(this)
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,'get','', '', );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        metaAccTitle: configs['meta_account_title'] || accountTitle,
        metaAccDescription: configs['meta_account_description'] || accountTitle,
        metaAccCatTitle: configs['meta_account_category_title'] || categoryTitle,
        metaAccCatDescription  : configs['meta_account_category_description'] || categoryTitle,
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    let valueArray = [
      this.state.metaAccTitle,
      this.state.metaAccDescription,
      this.state.metaAccCatTitle,
      this.state.metaAccCatDescription,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    if (configs.length == 0){
      this.deleteKeysAPI(configs);
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      seoMixPanelEvent(MixpanelEvent.seoAccount)
      this.deleteKeysAPI(configs);
    } else {
      this.setState({dataLoad: true})
      this.alertView(responseJson);
    }
  }

  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall( urlParm, 'delete','','');
      this.setState({dataLoad: true});
      this.alertView();
    } else {
      this.setState({dataLoad: true});
      this.alertView();
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // console.log('calling api');
    if (!this.state.metaAccTitle.includes(accountTitle)) {
      AppToast('Meta account title should contain ' + accountTitle)
    } else if (!this.state.metaAccDescription.includes(accountTitle)) {
      AppToast('Meta account description should contain ' + accountTitle)
    } else if (!this.state.metaAccCatTitle.includes(categoryTitle)) {
      AppToast('Meta account category title should contain ' + categoryTitle)
    } else if (!this.state.metaAccCatDescription.includes(categoryTitle)) {
      AppToast('Meta account category description should contain ' + categoryTitle)
    }else {
      this.setState({dataLoad: false})
      this.addOrUpdateAppDetailApi()
    }

  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Account</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {'You can add suffix or prefix with default braces for scripting. Example: Purchase from {account_title}'}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Account Title</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Account Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaAccTitle: name})}
                value={this.state.metaAccTitle}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Account Description</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Account Description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaAccDescription: name})}
                value={this.state.metaAccDescription}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Account Category Title</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Account Category Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaAccCatTitle: name})}
                value={this.state.metaAccCatTitle}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Account Category Description</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Account Category Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaAccCatDescription: name})}
                value={this.state.metaAccCatDescription}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'meta_account_title',
  'meta_account_description',
  'meta_account_category_title',
  'meta_account_category_description',
];



