import React from 'react';
import {StyleSheet, TouchableOpacity, Image} from 'react-native';
import ToastPopup from '../../HelperClasses/AppLoaderClasses/ToastPopup';
import copyPasteIcon from '../../assets/copyPaste.png';

const CopyButton = ({copy_item}) => {
  function copyBtnAction() {
    navigator.clipboard.writeText(copy_item);
    ToastPopup({type: 'success', message: 'Copied'});
  }
  return (
    <TouchableOpacity onPress={() => copyBtnAction()}>
      <Image source={copyPasteIcon} style={{width: 15, height: 15}} />
    </TouchableOpacity>
  );
};

export default CopyButton;
