import React, {useState} from 'react';
import Table from '../../../UI/Table/Table';
import TextInput from '../../../UI/Form/TextInput';
import deleteIcon from '../../../assets/delete_icon.png';
import AddRow from './AddRow';

const AttributeValueMetadata = ({metadata, onUpdate}) => {
  console.log(metadata);
  return (
    <div className="  flex flex-col  ">
      {metadata?.map(meta_item => {
        return (
          <div className="  mb-5      gap-8    rounded-md  flex items-center justify-start ">
            <TextInput
              component_style={'flex-grow flex  items-center gap-2'}
              isRequired={true}
              title={'Key'}
              current_data={meta_item?.key}
              update_data={v => {
                onUpdate(
                  metadata?.map(m => {
                    if (m.id !== meta_item?.id) {
                      return m;
                    }
                    return {
                      ...m,
                      key: v,
                    };
                  }),
                );
              }}
            />
            <TextInput
              component_style={'flex-grow  flex  items-center gap-2'}
              title={'Value'}
              isRequired={true}
              current_data={meta_item?.value}
              update_data={v => {
                onUpdate(
                  metadata?.map(m => {
                    if (m.id !== meta_item?.id) {
                      return m;
                    }
                    return {
                      ...m,
                      value: v,
                    };
                  }),
                );
              }}
            />
            <button
              className=" flex-none"
              onClick={() =>
                onUpdate(metadata.filter(m => m.id !== meta_item?.id))
              }>
              {
                <img
                  className=" w-6 h-6 object-contain"
                  src={deleteIcon}
                  alt=""
                />
              }
            </button>
          </div>
        );
      })}
      <div className=" flex items-center justify-center mt-4">
        <AddRow
          onPress={() => onUpdate([...metadata, {id: metadata?.length + 1}])}
        />
      </div>
    </div>
  );
};

export default AttributeValueMetadata;
