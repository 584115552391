export const b2c_dashboard_data = {
  main_card: {
    title: 'Add a New Product',
    description: 'Add a product(listing) and test the product creation flow.',
    link: '/add-listing',
    is_completed: false,
  },
  main_features: [
    {
      title: 'Setup shipping / Service Method',
      link: '/delivery',
      is_completed: false,
      button_title: 'Configure Now',
    },

    {
      title: 'Setup payments',
      link: '/payments',
      is_completed: false,
      button_title: 'Configure Now',
    },

    {
      title: 'Add Custom Domain',
      link: 'https://tradly.app/workspace/domain',
      is_completed: false,
      is_external_link: true,
      description: 'Add your domain with no additional cost.',
      button_title: 'Configure Now',
    },
    {
      title: 'Customize Website',
      link: 'https://tradly.app/editor',
      is_completed: false,
      is_external_link: true,
      description:
        'For Advanced Users, Use Editor to customize content, customize CSS, manage blocks, etc.',
      button_title: 'Open editor',
    },
  ],
  features_list: [
    {
      section_title: 'Store Merchandising',
      items_list: [
        {
          title: 'Create Collection of Listings',
          description:
            'Helps increasing browsing experience and SEO for your website',
          link: '/collections',
          is_completed: false,
        },
        {
          title: 'Create custom fields for your listings',
          description: 'to allow your users filter what they want.',
          link: '/listing-attributes',
          is_completed: false,
        },
        {
          title: 'Add your first blog post',
          description: 'to share about your product',
          link: 'https://tradly.app/workspace/blog',
          is_completed: false,
          is_external_link: true,
        },
        {
          title: 'Add your business pages',
          description: 'like terms, about us, privacy policy.',
          link: 'https://tradly.app/workspace/p',
          is_completed: false,
          is_external_link: true,
        },
      ],
    },
    {
      section_title: 'Experience your new website   ',
      items_list: [
        {
          title: 'Register as a User',
          description: 'Act like a customer and test',
          link: `/sign-up`,
          is_completed: false,
          is_external_link: true,
          external_link_by_domain: true,
        },
        {
          title: 'Make Order',
          description: 'to see how order and checkout flow works',
          link: `/l`,
          is_completed: false,
          is_external_link: true,
          external_link_by_domain: true,
        },
        {
          title: 'Add item to wish list',
          description: '',
          link: `/l`,
          is_completed: false,
          is_external_link: true,
          external_link_by_domain: true,
        },
        {
          title: 'View Orders',
          description: 'to know the status of your customers orders',
          link: '/orders',
          is_completed: false,
        },
      ],
    },
    {
      section_title: 'Growing your Store traffic ',
      items_list: [
        {
          title: 'Connect your website with Google Search',
          description: 'to let google know about your website',
          link: 'https://tradly.app/workspace/integrations/google-search-console',
          is_completed: false,
          is_external_link: true,
        },
        {
          title: 'Integrate Google Analytics',
          description: 'to analyze user behaviour',
          link: 'https://tradly.app/workspace/integrations/google-analytics',
          is_completed: false,
          is_external_link: true,
        },
      ],
    },
    {
      section_title: 'Support your Customers ',
      items_list: [
        {
          title: 'Add WhatsApp Support in your website',
          description: '',
          link: 'https://tradly.app/workspace/integrations/whatsapp-button',
          is_completed: false,
          is_external_link: true,
        },
        {
          title: 'Add any advanced LiveChat',
          description: '',
          link: 'https://tradly.app/workspace/integrations?category_id=5137',
          is_completed: false,
          is_external_link: true,
        },
      ],
    },
    {
      section_title: 'Store Promotion to convert customers ',
      items_list: [
        {
          title: 'Create your first coupon',
          description: '',
          link: '/coupon',
          is_completed: false,
        },
        {
          title: 'Add a Promotional Images',
          description: 'used for slider in homepage',
          link: '/promotions',
          is_completed: false,
        },
      ],
    },

    {
      section_title: 'Advanced',
      items_list: [
        {
          title: 'Connect with Zapier ',
          description: 'if you want send data to other tools',
          link: 'https://tradly.app/workspace/webhooks?page=1',
          is_completed: false,
          is_external_link: true,
        },
        {
          title: 'Customize your Emails',
          description:
            'if you want to change welcome email, OTP, order emails. HTML supported',
          link: '/communications',
          is_completed: false,
        },
      ],
    },
  ],
  tutorial_data: {
    title: 'Resource to get started',
    description: '',
    all_link: '/',
    video_list: [
      {
        title: 'Setting up Listings  ',
        link: 'https://www.youtube.com/embed/HzTNKhxQD4g?si=27OXhI9eWYiGC6S7',
        is_external_link: true,
      },
      {
        title: 'Listing Types',
        link: 'https://www.youtube.com/embed/KkWGazYGqGo?si=jPHfLQu0a7f75mOh',
        is_external_link: true,
      },
      {
        title: 'String Customisation',
        link: 'https://www.youtube.com/embed/V-CYBTXo7Fg?si=qjYOnTvnS32vcDqp',
        is_external_link: true,
      },
      {
        title: 'Configuring Stripe ',
        link: 'https://www.youtube.com/embed/s5miuwJRR-I?si=dfxYQPZRpolAX-MS',
        is_external_link: true,
      },
      {
        title: 'Shipping Charges',
        link: 'https://www.youtube.com/embed/UtQw-hib018?si=qwAG8VQ0kaYzDGuq',
        is_external_link: true,
      },
      {
        title: 'Configuring Tax',
        link: 'https://www.youtube.com/embed/qwOOsGvDUN4?si=iK7bkhV5AMkcL3Qf',
        is_external_link: true,
      },
    ],
  },
};
