import React, {useState} from 'react';
import SidePan from '../Shared/SidePan';
import {useNavigate} from 'react-router-dom';
import {
  Text,
  View,
  Animated,
  Easing,
  TouchableOpacity,
  Image,
  Dimensions,
} from 'react-native';
import {useEffect} from 'react';

const Test = () => {
  // States
  const [test_states, setTestStates] = useState({
    maxQuantity: '',
    maxListingPrice: '',
    pictureCount: '',
    addressLabel: '',
    show_stock_out_listings: false,
    show_variants: false,
    meta_title: false,
    meta_description: false,
  });

  //Object destructure (for using in UI)
  const {
    maxQuantity,
    maxListingPrice,
    pictureCount,
    addressLabel,
    show_stock_out_listings,
    show_variants,
    meta_title,
    meta_description,
  } = test_states;

  //Use Effect function (this effect will cal at first time of page loading and fecth data by api )
  useEffect(() => {
    //Some api function will be here (Its not predictable before work )
    // after fetch api in data i will get arrays or object base on return array next will be differant

    // base on object
    const resp = {meta_title: false}; //imagine this object come from backend

    //Now will update main state
    setTestStates(prevState => ({
      ...prevState,
      meta_title: resp.meta_title,
    }));
  }, []);

  return (
    <View>
      {/* Now base on state or function logic UI will be here but this ui will not connect with any array or object this ui will connect base on state value like meta title etc and in ui will be some logic base any requirements  */}
    </View>
  );
};

export default Test;
