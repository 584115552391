import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
  TextInput,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { Gradient } from 'react-gradient';
import { AppToast } from '../../HelperClasses/AppToast';
import selectedListIcon from '../../assets/selectedListIcon.png';
import AppConstants from '../../Constants/AppConstants';
import { checkUserRoleModule, dateConversionFromTimeStamp } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import DeliveryStatusEnum from '../../Models/DeliveryStatusEnum';
import checked_Icon from '../../assets/checkedIcon.png';
import unchecked_Icon from '../../assets/uncheckedIcon.png';
import RosterDetail from './RosterDetail';
import closeIcon from '../../assets/closeIcon.png';
import { addMixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false
let perPage = 50
const Unassigned = 'Unassigned'

export default class Roster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rosterArray: [],
      dataLoad: false,
      stopPage: false,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      totalRecords: 0,
      searchKey: '',
      typingTimeout: 0,
      showSearchView:false,
      showFilterView: false,
      selectedFilterID: -1,
      perPage:perPage,
      totalRecords: 0,
      branchesArray:[],
      selectedBranchAarray:[],
      appliedBranch:[],
      driverArray:[],
      selectedDriverAarray:[],
      appliedDriver:[],
      showRosterDetail:false,
      rosterDetailData:{},
    }
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo()
    this.getApi()
    this.loadBranchsApi()
    this.loadDriverApi()
  }
  mixPannelInfo(){
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.roster}`,{'page':pageNo})
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  getApi() {
    pageNo = 1;
    this.loadRosterApi()
  }
  //MARK:-  APIS Method 
  loadRosterApi = async (type) => {
    this.setState({ dataLoad: false });
    var param = ''
    if (this.state.appliedBranch.length != 0) {
      param = `&branch_id=${this.state.appliedBranch.toString()}`
    }
    if (this.state.appliedDriver.length != 0) {
      param = `&driver_id=${this.state.appliedDriver.toString()}`
    }
    var path = `?page=${pageNo}&per_page=${perPage}${param}`

    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryRoster + path, 'get')
    this.setState({ rosterArray: [] });
    console.log('responseJson', responseJson)
    if (responseJson['status'] == true) {
      var rosterD = responseJson['data']['rosters']
      this.state.totalRecords = responseJson['data']['total_records'] ?? 0
      if (rosterD.length != 0) {
        this.setState({ stopPage: false });
        for (let objc of rosterD) {
          this.state.rosterArray.push(objc);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({ stopPage: true });
      }
      this.state.haveData = this.state.rosterArray.length == 0 ? true : false
    } else {
      AppToast(responseJson)
    }
    this.setState({ dataLoad: true });
  }
  loadBranchsApi = async () => {
    let path = `${APPURL.URLPaths.deliveryBranches}?page=${pageNo}&per_page=${100}`
    const responseJson = await networkService.networkCall(path, 'get', '', '');
    // console.log('branches', responseJson)
    this.setState({ branchesArray: [] });
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches']
      for (let objc of branchD){
        this.state.branchesArray.push(objc)
      }
    }
  }
  loadDriverApi = async () => {
    let path = `${APPURL.URLPaths.deliveryDriver}?page=${pageNo}&per_page=${100}&type=driver`
    const responseJson = await networkService.networkCall(path, 'get', '', '');
    // console.log('drivers', responseJson)
    this.setState({ driverArray: [] });
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['users']
      for (let objc of branchD){
        this.state.driverArray.push(objc)
      }
    }
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({ addDriverBool: false })
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadRosterApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadRosterApi();
      }
    }
    this.mixPannelInfo()
    this.setState({ updateUI: !this.state.updateUI });
  }
  onSearchTextChange = text => {
    this.setState({ searchKey: text })
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(function () {
        this.getApi()
      }.bind(this), 1000)
    })
  }
  onCancelText() {
    if (this.state.searchKey.length != 0) {
      this.state.searchKey = ''
      this.getApi()
    }
    this.setState({showSearchView: false})
  }
  didSelectBranch= (item) => {
    let ind = this.state.selectedBranchAarray.findIndex( x => x == item['id']) 
    if (ind != -1) {
      this.state.selectedBranchAarray.splice(ind,1)
    } else {
      this.state.selectedBranchAarray.push(item['id'])
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  didSelectDriver= (item) => {
    let ind = this.state.selectedDriverAarray.findIndex( x => x == item['id']) 
    if (ind != -1) {
      this.state.selectedDriverAarray.splice(ind,1)
    } else {
      this.state.selectedDriverAarray.push(item['id'])
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  applyBtnAction(id) {
    // if (id == 1){
      this.state.appliedBranch = this.state.selectedBranchAarray
    // } else {
      this.state.appliedDriver = this.state.selectedDriverAarray
    // }
    this.setState({showFilterView: false, selectedFilterID: -1 })

    this.getApi()
  }
  clearBtnAction(id){
    if (id == 1){
      this.state.appliedBranch = []
    } else  if (id == 2) {
      this.state.appliedDriver = []
    } else{
      this.state.appliedBranch = []
      this.state.appliedDriver = []
    }
    this.setState({showFilterView: false,selectedFilterID:-1})
    this.getApi()
  }
  fiterBtnAction() {
    console.log('fiterBtnAction', this.state.appliedBranch)
    let brnach = [... this.state.appliedBranch]
    let driver = [... this.state.appliedDriver]
    this.state.selectedBranchAarray = brnach
    this.state.selectedDriverAarray = driver
    this.setState({ showFilterView: !this.state.showFilterView, selectedFilterID: -1 })
  }
  didSelectFilter(id) {
    this.setState({ selectedFilterID: this.state.selectedFilterID == id ? -1 : id })
    if (id == 2){
      this.clearBtnAction(5)
    } 
  }
  viewRosterDetailBtnAction(id){
    let ind = this.state.rosterArray.findIndex(x => x['id'] == id)
    this.state.rosterDetailData = this.state.rosterArray[ind]
    this.setState({showRosterDetail:true})
  }
  removeFilterValueBtnAcion(item){
    if (item['type'] == 1){
      let indx = this.state.appliedBranch.findIndex(x => x == item['id']) 
      this.state.appliedBranch.splice(indx, 1)
    } else if (item['type'] == 2){
      let indx = this.state.appliedDriver.findIndex(x => x == item['id']) 
      this.state.appliedDriver.splice(indx, 1)
    } 
    this.setState({updateUI: !this.state.updateUI})
    this.getApi()
  }
  /*  UI   */

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.rosterArray.length; i++) {
      let orderCount = this.state.rosterArray[i]['orders'] ? this.state.rosterArray[i]['orders'].length : 0
      let driverName = this.state.rosterArray[i]['driver'] ? this.state.rosterArray[i]['driver']['name'] : '-' 
      let driverMobile = this.state.rosterArray[i]['driver'] ? this.state.rosterArray[i]['driver']['mobile'] : '-' 

      views.push(
        <FlatList
          data={[
            this.state.rosterArray[i]['id'],
            driverName,
            driverMobile,
            orderCount,
            DeliveryStatusEnum.rosterType(this.state.rosterArray[i]['status']),
            dateConversionFromTimeStamp(this.state.rosterArray[i]['delivery_slot_from']),
            dateConversionFromTimeStamp(this.state.rosterArray[i]['delivery_slot_to']),
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white'}}
          keyExtractor={(index) => index + 9989}
          key={'H'}
        />,
      )
    }
    return views
  }
  columnCell = ({ item, index }) => {
   if (index == 0) {
    let ind = this.state.rosterArray.findIndex(x => x['id'] == item)
    let branchName = this.state.rosterArray[ind]['branch']['name']
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() =>this.viewRosterDetailBtnAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{branchName}</Text>
        </TouchableOpacity>
      )
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={[tableStyle.columnViewTitleStyle, { color: item == Unassigned ? colors.AppRed : colors.blackColor }]}>
            {item}</Text>
        </View>
      )
    }
  }
  gradientButtons = name => {
    if (this.state.rosterArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />
      } else if (name['id'] == 1 && this.state.rosterArray.length == 0 && pageNo > 1) {
        return <View />
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{ height: 30, width: 100, borderRadius: 10, marginRight: 30 }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{ color: 'white' }}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  }
  renderFilterView = () => {
    return <View style={tableStyle.filterContainerViewStyle}>
      <View style={{ display: this.state.showFilterView ? 'flex' : 'none',}}>
        <View style={[tableStyle.filterViewStyle,{marginLeft:20, flexDirection: 'row-reverse'}]}>
          <FlatList
            data={FilterStaticArray}
            renderItem={this.renderFilterCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index + 9989}
            key={'FL'}
            style={{marginBottom:10}}
          />
          <View style={{height:'100%', width:1, backgroundColor: colors.SimonGray}}/>
          {this.renderSubListView(1)}
          {this.renderSubListView(2)}
        </View>
      </View>
    </View>
  }
  renderFilterCell = ({ item, index }) => {
    return (
      <TouchableOpacity onPress={() => this.didSelectFilter(index)}
        style={{ marginLeft: 8, marginTop: 10, marginRight: 8, backgroundColor: this.state.selectedFilterID == index ? colors.SimonGray : colors.TransparentColor, padding: 5, borderRadius: 2 }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    );
  }

  renderClearApplyButton = (id) => {
    return <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <TouchableOpacity onPress={() => this.clearBtnAction(id)}>
        <Text style={tableStyle.clearTxtStyle}>Clear</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => this.applyBtnAction(id)}>
        <Text style={tableStyle.applyTxtStyle}>Apply</Text>
      </TouchableOpacity>
    </View>
  }
  
  renderSubListView = (id) => {
    var views = []
    let dynamicAry = id == 1 ? this.state.selectedBranchAarray : this.state.selectedDriverAarray
    let valueArray = id == 1 ? this.state.branchesArray : this.state.driverArray
    var check = this.state.selectedFilterID == 0 ? true : false
    if (id == 2) {
      check = this.state.selectedFilterID == 1 ? true : false
    }
    for (let a = 0; a < valueArray.length; a++) {
      let item = valueArray[a]
      let ind = dynamicAry.findIndex(x => x == item['id'])
      views.push(<View>
        <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          onPress={() => id == 2 ? this.didSelectDriver(item) : this.didSelectBranch(item)}>
          <Text>{item['name']}</Text>
          <Image style={{ width: 12, height: 12, marginRight:10 }} source={ind != -1 ? checked_Icon : unchecked_Icon} />
        </TouchableOpacity>
        <View style={{ height: 5, width: 5 }} />
      </View>)
    }
    return <View style={{ width: 170,display: check ? 'flex' : 'none' }}>
      <View style={{ margin: 10 }}>
        <ScrollView style={{height:150, marginTop:0}}>
          <View>
            {views}
          </View>
        </ScrollView>
        <View style={{ zIndex: 12, marginTop: 10}}>
          {this.renderClearApplyButton(id)}
        </View>
      </View>
    </View>
  }
  renderHeaderRightBtn = () => {
    return (<View style={{ flexDirection: 'row', zIndex: 100,marginLeft:5, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View />
        <View style={{ position: 'relative', flexDirection: 'row-reverse'}}>
          <View>
            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              onPress={() => this.fiterBtnAction()}>
              <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: this.state.showFilterView ? colors.AppNewGreen : colors.Appgray }]}>Filter</Text>
            </TouchableOpacity>
            <View style={{ backgroundColor: this.state.showFilterView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
          </View>
          {this.renderFilterView()}
        </View>
      </View>
    </View>)
  }
  renderHeaderBtn = () => {
    return (<View style={{ flexDirection: 'row', zIndex: 100, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View style={{ width: 10 }} />
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image style={tableStyle.viewIconsStyle} source={selectedListIcon} />
            <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: colors.AppNewGreen }]}>Roster</Text>
          </View>
          <View style={{ backgroundColor: colors.AppNewGreen, height: 2, width: '100%', marginTop: 5 }} />
        </View>
      </View>
    </View>)
  }
  renderHeaderView = () => {
    return (<View style={tableStyle.headerViewstyle}>
      <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
        {/* <this.renderHeaderBtn /> */}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <this.renderHeaderRightBtn />
      </View>
    </View>)
  }
  EmptyListRender = () => {
    if (this.state.rosterArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{ height: '100%', justifyContent: 'center' }}>
          <EmptyListUI showImage={true} titleString={'No Roster Found'} subtitleString={''} />
        </View>);
      } else {
        return <View />
      }
    } else {
      return <View />
    }
  }

  renderListView = () => {
    return <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>
  }
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
        <this.renderPerView />
      </View>)
    } else {
      return <View />
    }
  }
  renderSelectFilterView = () => {
    var views = []
    var ssAry = []
    if (this.state.appliedBranch.length != 0) {
      for (let obj of this.state.appliedBranch){
      let indx = this.state.branchesArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.branchesArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:1})
      }
    }
    if (this.state.appliedDriver.length != 0) {
      for (let obj of this.state.appliedDriver){
      let indx = this.state.driverArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.driverArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.AppLightOrange, type:2})
      }
    }
   
    for (let a = 0; a < ssAry.length; a++) {
      let obj = ssAry[a]
      views.push(<View style={{ backgroundColor: obj['color'], borderRadius: 8, padding: 5, flexDirection: 'row', marginRight: 5 }}>
        <Text style={[CommonStyleSheet.helperTextStyle,{paddingLeft: 2 }]}>{obj['title']}</Text>
        <TouchableOpacity onPress={() => this.removeFilterValueBtnAcion(obj)}>
          <Image style={{ width: 14, height: 14, marginLeft: 10 }} source={closeIcon} />
        </TouchableOpacity>
      </View>)
    }
    return <View style={{ width: '97%', marginLeft: 10, flexDirection:'row'}}>
      {views}
    </View>
  }
  render() {
    if (this.state.showRosterDetail) {
      return (<RosterDetail  rosterDetailData={this.state.rosterDetailData} backBtnAction={() => this.setState({showRosterDetail:false})}/>)
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <this.renderHeaderView />
          <this.renderSelectFilterView />
          <View style={tableStyle.containerMainView}>
            <this.renderListView />
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      )
    }
  }
}
const commonWidth = windowWidth/6
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
    padding:10,
  },
});

const tableHeaderString = [
  'Branch',
  'Driver Name',
  'Driver Mobile',
  'Order Count',
  'Status',
  'Slot From',
  'Slot To',
];
const FilterStaticArray = [
  'Branch',
  'Driver',
  'Clear Filter',
]
