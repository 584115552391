import React, {useEffect, useState} from 'react';
import {getConfigsByKey} from '../../Actions/getConfigsByKey';
import SwitchButton from '../../../Shared/Switch';
import ConfigurationBox from './ConfigurationBox';
import PageHeader from '../../../Component/Layout/PageHeader';
import {saveConfigsData} from '../../Actions/SaveConfigsData';
import AppLoader from '../../../HelperClasses/AppLoaderClasses/AppLoader';
import {AllMenus} from '../../../Constants/AllMenus';
import {SaveConfigsDataByKey} from '../../Actions/SaveConfigsDataByKey';
import {CONFIG_CONSTANT} from '../../../Constants/ConfigsConstants';
import ColorConfigurationSetup from './ColorConfigurationSetup';

const ManageLeftNavBarConfiguration = () => {
  const [configuration_states, setConfigurationStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const general_list = AllMenus.filter(
    it => it.sub_menus?.length === 0 && it.href !== undefined,
  );
  const with_sub_groups_list = AllMenus.filter(
    it => it.sub_menus?.length !== 0,
  );
  const color_groups_list = AllMenus.filter(it => it.type === 'color');

  useEffect(() => {
    loadConfigs();
  }, []);

  //loadConfigs();
  const loadConfigs = async () => {
    setIsLoading(true);

    getConfigsByKey('superadmin').then(config => {
      setConfigurationStates(
        config?.sidebar_setup?.length > 0
          ? config?.sidebar_setup
          : [
              ...general_list?.map(it => ({
                plan: '',
                device: '',
                country: '',
                key_name: it?.key_name,
                parent_key: null,
                feature_activated: true,
              })),
              ...with_sub_groups_list
                ?.map(gr =>
                  gr.sub_menus.map(mn => ({
                    plan: '',
                    device: '',
                    country: '',
                    key_name: mn?.key_name,
                    parent_key: gr.key_name,
                    feature_activated: true,
                  })),
                )
                .flat(),
              {
                plan: '',
                device: '',
                country: '',
                key_name: 'text_color',
                parent_key: null,
                value: '#ffffff',
              },
              {
                plan: '',
                device: '',
                country: '',
                key_name: 'background_color',
                parent_key: null,
                value: '#01544E',
              },
            ],
      );

      setIsLoading(false);
    });
  };

  const onSave = async () => {
    setIsLoading(true);
    const simplifiedObject = {};

    for (const key in configuration_states) {
      simplifiedObject[key] = configuration_states[key].value;
    }

    await SaveConfigsDataByKey({
      form_data: simplifiedObject,
      config_key_group: 'superadmin',
      config_key_name: 'sidebar_setup',
      value: configuration_states,
    });

    loadConfigs();
    window.location.reload();
  };

  return (
    <div className=" w-full p-3 md:p-6  relative">
      <PageHeader
        isHideBackButton={true}
        title={'Manage Features'}
        save_action={onSave}
      />
      <div className=" mt-2 border border-[#E6E7E7]  h-[calc(100vh-180px)] rounded-md  overflow-y-auto  p-4   flex flex-col gap-8">
        {/*  */}

        <div className=" flex flex-col gap-5 bg-white  p-5 md:px-10 md:py-5  shadow-custom border border-[rgba(220, 220, 220, 1)] rounded-[10px] ">
          <p className=" text-2xl  font-medium  text-black   w-full font-sans">
            General
          </p>
          {general_list.map(menu => {
            return (
              <div className="flex items-center   justify-between md:justify-start ">
                <div className="max-w-[400px] mr-4  w-full flex ">
                  <p className=" text-base   font-normal  text-black font-sans items-center">
                    {menu.title}{' '}
                  </p>
                </div>
                <ConfigurationBox
                  key={menu?.key_name}
                  current_key={menu?.key_name}
                  selected_config={configuration_states?.find(
                    con => con?.key_name === menu?.key_name,
                  )}
                  setListingStates={setConfigurationStates}
                  configuration_states={configuration_states}
                />
              </div>
            );
          })}
        </div>

        {/*  */}
        {with_sub_groups_list?.map(item => {
          return (
            <div className=" flex flex-col gap-5 bg-white   p-5 md:px-10 md:py-5 shadow-custom border border-[rgba(220, 220, 220, 1)] rounded-[10px] ">
              <p className="  text-2xl  font-medium  text-black   w-full font-sans">
                {item.title}
              </p>
              {item?.sub_menus.map(menu => {
                return (
                  <div className="flex items-center  justify-between md:justify-start ">
                    <div className="max-w-[400px] mr-4  w-full flex ">
                      <p className=" text-base  font-normal  text-black font-sans items-center">
                        {menu.title}{' '}
                      </p>
                    </div>
                    <ConfigurationBox
                      key={menu?.key_name}
                      current_key={menu?.key_name}
                      selected_config={configuration_states?.find(
                        con => con?.key_name === menu?.key_name,
                      )}
                      setListingStates={setConfigurationStates}
                      configuration_states={configuration_states}
                      parent_key={item?.key_name}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}

        {/*  */}
        <div className=" flex flex-col gap-5 bg-white  p-5 md:p-10 shadow-custom border border-[rgba(220, 220, 220, 1)] rounded-[10px] ">
          <p className=" text-2xl  font-medium  text-black   w-full font-sans">
            Sidebar Background
          </p>
          {color_groups_list.map(menu => {
            return (
              <div className="flex items-center   justify-between md:justify-start ">
                <div className="max-w-[400px] mr-4  w-full flex ">
                  <p className=" text-base   font-normal  text-black font-sans items-center">
                    {menu.title}
                  </p>
                </div>
                <ColorConfigurationSetup
                  key={menu?.key_name}
                  current_key={menu?.key_name}
                  selected_config={configuration_states?.find(
                    con => con?.key_name === menu?.key_name,
                  )}
                  setListingStates={setConfigurationStates}
                  configuration_states={configuration_states}
                />
              </div>
            );
          })}
        </div>
      </div>

      {isLoading && (
        <div className="h-full w-full bg-black/5 absolute inset-0   z-0">
          <AppLoader enable={!isLoading} />
        </div>
      )}
    </div>
  );
};

export default ManageLeftNavBarConfiguration;
