import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom';

export const withRouter = Component => {
  const Wrapper = props => {
    const navigate = useNavigate();
    const navigation = useNavigation();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        navigate={navigate}
        navigation={navigation}
        location={location}
        params={params}
        {...props}
      />
    );
  };

  return Wrapper;
};
