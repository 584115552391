import React from 'react';
import {generateNextID} from '../../../utils/genarateNextID';
import SelectFromList from '../../../Component/Form/SelectFromList';
import TextInput from '../../../Component/Form/TextInput';
import {ICONS} from '../../../Constants/Icons';

const SendcloudCountries = ({
  countries_list,
  sendcloudCountries,
  setSendCloudCountries,
}) => {
  //
  const addNewCountry = () => {
    setSendCloudCountries([
      ...sendcloudCountries,
      {
        id: generateNextID({list: sendcloudCountries}),
        country: '',
        country_id: '',
      },
    ]);
  };
  //
  const deleteCountry = id => {
    setSendCloudCountries(
      sendcloudCountries.filter(country => country.id !== id),
    );
  };

  //
  const updateCountry = ({data, id}) => {
    setSendCloudCountries(
      sendcloudCountries.map(country => {
        if (country.id !== id) {
          return country;
        } else {
          return data;
        }
      }),
    );
  };

  return (
    <div className="  font-sans border rounded-lg mt-4 max-h-[60vh] overflow-auto">
      <div className="flex  justify-between gap-2 sticky top-0 bg-white p-4 ">
        <h2 className=" text-sm  font-semibold font-sans">
          Default Shipping Address
        </h2>{' '}
        <button
          className=" px-2 py-1 border border-primary rounded-md text-sm text-primary hover:bg-primary hover:text-white transition duration-300"
          onClick={() => addNewCountry()}>
          + Add New
        </button>
      </div>

      <div className="   space-y-2 px-4 pb-4">
        {sendcloudCountries?.map(cn => {
          return (
            <div
              className="flex  items-center justify-start gap-2"
              key={cn?.id}>
              <SelectFromList
                list={countries_list?.map(country => ({
                  label: country.name,
                  value: country.code2,
                }))}
                default_text={'Country'}
                selected_value={cn?.country}
                setValue={val => {
                  updateCountry({id: cn.id, data: {...cn, country: val}});
                }}
              />
              <TextInput
                value={cn?.country_id}
                placeholder={'ID'}
                type={'number'}
                onChangeText={val => {
                  updateCountry({id: cn.id, data: {...cn, country_id: val}});
                }}
              />
              <button onClick={() => deleteCountry(cn?.id)}>
                {ICONS.trash_icon}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SendcloudCountries;
