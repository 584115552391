import React from 'react';

const UserAttributes = ({attributes}) => {
  return (
    <div className=" p-[25px] ">
      <p>Attributes</p>

      <div className="  mt-3 flex flex-col ">
        <table className=" table-auto	">
          <tr className="grid grid-cols-2 items-center  border-b border-t border-l border-r border-SimonGray ">
            <td className="p-[10px] border-r  border-SimonGray">Title</td>
            <td className="p-[10px] ">Value</td>
          </tr>
          {attributes?.map(attr => {
            return (
              attr.visible &&
              attr.field_type !== 11 && (
                <tr
                  className="grid grid-cols-2 items-center  border-b border-l border-r border-SimonGray  text-Appgray  text-base leading-none"
                  key={attr.id}>
                  <td className="flex items-center justify-start gap-2 p-[10px] border-r  border-SimonGray">
                    {attr?.icon_path && (
                      <img className=" w-8 h-8 " src={attr?.icon_path} alt="" />
                    )}
                    <p className="description-paragraph">{attr.name}</p>
                  </td>

                  {/* 1|2 */}
                  {(attr.field_type === 1 || attr.field_type === 2) && (
                    <td className=" flex items-center justify-start gap-2 flex-wrap p-[10px]">
                      {attr.values.map(item => {
                        if (attr.field_type === 1 || attr.field_type === 2) {
                          return (
                            <p className="overflow-auto  scrollbar-none   description-paragraph ">
                              {item.name}
                            </p>
                          );
                        }
                      })}
                    </td>
                  )}

                  {attr.field_type !== 1 &&
                    attr.field_type !== 2 &&
                    attr.field_type !== 6 && (
                      <td className="  whitespace-pre-line  description-paragraph  overflow-auto  scrollbar-none p-[10px]">
                        {attr.values
                          .map(item => {
                            if (
                              attr.field_type === 3 ||
                              attr.field_type === 4
                            ) {
                              return item;
                            } else if (attr.field_type === 12) {
                              return `latitude:${item?.latitude}, longitude:${item?.longitude}`;
                            } else {
                              return item;
                            }
                          })
                          .join(', ')}
                      </td>
                    )}
                </tr>
              )
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default UserAttributes;
