import networkService from '../../Network/NetworkManager';
const APPURL = require('./../../Constants/URLConstants');

export const getTenantLanguages = async () => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tanantslanguage,
      'get',
      '',
      '',
    );
    return responseJson?.data?.languages;
  } catch (error) {
    return error;
  }
};
