import {View} from 'react-native';
import {ReactComponent as CloseIcon} from '../assets/close.svg';

export default function CloseBtnView() {
  return (
    <View>
      <CloseIcon style={{height: 15, width: 15}} />
    </View>
  );
}
