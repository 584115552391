import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';

import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent } from '../../Models/MixPannelEnum';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'orders';

const feedbackTypeArray = ['Email', 'Phone', 'Email or Phone']
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class OrderNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      valueArray : ['','','','','','','','','','','','','','','','','']
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`, 'get', '', '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      console.log('this.state.configs =>', configs);
      this.state.valueArray[0] = configs[keysArray[0]] || defaultValue[0];
      this.state.valueArray[1] = configs[keysArray[1]] || defaultValue[1];
      this.state.valueArray[2] = configs[keysArray[2]] || defaultValue[2];
      this.state.valueArray[3] = configs[keysArray[3]] || defaultValue[3];
      this.state.valueArray[4] = configs[keysArray[4]] || defaultValue[4];
      this.state.valueArray[5] = configs[keysArray[5]] || defaultValue[5];
      this.state.valueArray[6] = configs[keysArray[6]] || defaultValue[6];
      this.state.valueArray[7] = configs[keysArray[7]] || defaultValue[7];
      this.state.valueArray[8] = configs[keysArray[8]] || defaultValue[8];
      this.state.valueArray[9] = configs[keysArray[9]] || defaultValue[9];
      this.state.valueArray[10] = configs[keysArray[10]] || defaultValue[10];
      this.state.valueArray[11] = configs[keysArray[11]] || defaultValue[11];
      this.state.valueArray[12] = configs[keysArray[12]] || defaultValue[12];
      this.state.valueArray[13] = configs[keysArray[13]] || defaultValue[13];
      this.state.valueArray[14] = configs[keysArray[14]] || defaultValue[14];
      this.state.valueArray[15] = configs[keysArray[15]] || defaultValue[15];
      this.state.valueArray[16] = configs[keysArray[16]] || defaultValue[16];
    }
    this.setState({dataLoad: true});
  };
 
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    let values = this.state.valueArray;
    let keys = keysArray
    for (let a = 0; a < keys.length; a++) {
      if (values[a].length != 0){
        let fDict = {
          key_group: KeyGroup,
          key: keys[a],
          value: values[a],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,  'post',  JSON.stringify({configs: configs}));
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.orderStatusNotification,{'success':true})
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    } else {
      addMixpanelEvent(MixpanelEvent.orderStatusNotification,{'success':false})
      this.alertView(responseJson)
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  onChangeTextValue(id,text) {
    this.state.valueArray[id] = text
    this.setState({updateUI: this.state.updateUI});
  };
  //MARK:-  UIs 
  renderOrderStatusBackView = () => {
    var view = [];
    for (let a = 0; a < titleArray.length; a++) {
      let obj = titleArray[a];
      let placeholder =  `Enter ${obj}`;
      view.push(<View style={{ marginTop: 30, zIndex: a+1 }}>
        <Text style={appConfigStyle.titleTxtStyle}>{obj}</Text>
        <TextInput
          style={[appConfigStyle.txtFieldStyle,{outline:0}]}
          placeholder={placeholder}
          placeholderTextColor={colors.placeholderColor}
          onChangeText={txt => this.onChangeTextValue(a,txt)}
          value={this.state.valueArray[a]}
        />
      </View>)
    }
    return (<View>
      {view}
    </View>
    )
  }
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' :  '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Order Status Notification</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -40}}>
              <this.renderOrderStatusBackView />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const titleArray = [
  'Order status confirmed',
  'Order status in progress',
  'Order status shipped',
  'Order status customer unreachable',
  'Order status out for delivery',
  'Order status undelivered returned',
  'Order status undelivered return confirmed',
  'Order status delivered',
  'Order status delivered confirmed',
  'Order status customer return initiated',
  'Order status customer return picked',
  'Order status customer return confirmed',
  'Order status customer return disputed',
  'Order status cancelled by account',
  'Order status cancelled by customer',
  'Order status ready for pickup',
  'Order status completed',
];
const keysArray = [
  'order_status_confirmed',
  'order_status_in_progress',
  'order_status_shipped',
  'order_status_customer_unreachable',
  'order_status_out_for_delivery',
  'order_status_undelivered_returned',
  'order_status_undelivered_return_confirmed',
  'order_status_delivered',
  'order_status_delivered_confirmed',
  'order_status_customer_return_initiated',
  'order_status_customer_return_picked',
  'order_status_customer_return_confirmed',
  'order_status_customer_return_disputed',
  'order_status_cancelled_by_account',
  'order_status_cancelled_by_customer',
  'order_status_ready_for_pickup',
  'order_status_completed',
] 

const defaultValue = [
  'Confirmed',
  'In Progress',
  'Shipped',
  'Customer Unreachable',
  'Out For Delivery',
  'Undelivered Returned',
  'Undelivered Return Confirmed',
  'Delivered',
  'Delivery Confirmed',
  'Return Initiated',
  'Return Picked',
  'Return Confirmed',
  'Return Disputed',
  'Cancelled by seller',
  'Cancelled by customer',
  'Ready for pickup',
  'Completed',
]