import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  FlatList,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import ImagePicker from 'react-native-image-crop-picker';
import ReactFileReader from 'react-file-reader';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from '../HelperClasses/AppToast';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import commonStyleSheet from '../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../assets/downArrowIcon.png';
import DatePicker from 'react-custom-date-picker';
import Moment from 'moment';
import AppConstants from '../Constants/AppConstants';
import Switch from 'react-switch';
import MediaManagerView from '../Component/MediaManagerView';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import previewStyleSheet from '../StyleSheets/PreviewStyleSheet';
import appHeaderView from '../assets/appHeaderView.png';
import appBottomView from '../assets/appBottomView.png';
import {addMixpanelEvent, MixPannelEnum} from '../Models/MixPannelEnum';
import SelectSearchView from 'react-select';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import {capitalizeFirstLetter} from '../HelperClasses/SingletonClass';
import {t1} from '../StyleSheets/TextTitleStyleSheet';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import AppConfigStyleSheet from '../StyleSheets/AppConfigStyleSheet';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let dateFormat = 'yyyy-MM-DD';
var dt = new Date();
let currentDate = Moment(dt).format(dateFormat);

let MediumTypesArray = ['App', 'Web'];
var TypesArray = [];
var pageNo = 1;
let externalLink = 'External_link';
let placementTypesArray = ['Home', 'Footer'];

class AddPromoBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropDown: false,
      showTypeDropDown: false,
      selectedImage: null,
      status: true,
      dataLoad: true,
      name: '',
      selectedMedium: MediumTypesArray[(0, 1)],
      selectedType: 'General',
      startDate: currentDate,
      endDate: currentDate,
      valueID: '',
      id: '',
      placement: '',
      mediaArray: [],
      showMediaManager: false,
      mediumArray: ['App', 'Web'],
      listingsArray: [],
      accountsArray: [],
      updateUI: false,
      showPlacementDropDown: false,
      selectedPlacementType: placementTypesArray[0],
      expirySwitch: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    promoBannerData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    this.loadMediaAPi();
    TypesArray = AppConstants.appType
      ? ['General', 'Listing', externalLink]
      : ['General', 'Listing', 'Account', externalLink];
    const isEdit = this.props.isEdit;
    // console.log('isEdit =>', isEdit);
    if (isEdit) {
      this.loadPromoBannerApi();
    }
    this.getListingAPI();
    this.getAccountsAPI();
  }

  loadPromoBannerApi = async type => {
    this.setState({dataLoad: false});

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.promo,
      'get',
    );
    if (responseJson['status'] == true) {
      var promoB = responseJson['data']['promo_banners'];
      // console.log('promoB', promoB);

      const promoBData = promoB.filter(
        it => it.id == this.props.params.promoID,
      )[0];

      if (this.state.name.length == 0) {
        var mm = promoBData['medium'];
        let ap = mm.includes('app');
        let we = mm.includes('web');
        if (ap) {
          this.state.mediumArray[0] = MediumTypesArray[0];
        } else {
          this.state.mediumArray[0] = '';
        }
        if (we) {
          this.state.mediumArray[1] = MediumTypesArray[1];
        } else {
          this.state.mediumArray[1] = '';
        }
        this.state.expirySwitch = promoBData['expirable'] ?? false;
        this.setState({
          name: promoBData['reference'],
          selectedType: capitalizeFirstLetter(promoBData['type']),
          // selectedMedium: this.capitalizeFirstLetter(promoBData['medium']),
          status: promoBData['active'],
          id: promoBData['id'],
          order_by: promoBData['order_by'],
          valueID: promoBData['value'],
          selectedImage: promoBData['image_path'],
          endDate: promoBData['end_at'],
          startDate: promoBData['start_at'],
          placement: promoBData['placement'] || '',
          selectedPlacementType: capitalizeFirstLetter(
            promoBData['placement'] || placementTypesArray[0],
          ),
        });
      }
    }
    this.setState({dataLoad: true});
  };
  loadMediaAPi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,
      'get',
      '',
      '',
    );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['media'];
      if (collectionD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of collectionD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.mediaArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  promotType() {
    return this.state.selectedType.toLowerCase();
  }
  addPromoBannerApi = async image => {
    var mediumString = this.state.mediumArray.toString();
    var value = '';
    if (mediumString.length == 4) {
      value = mediumString.replace(',', '');
    } else {
      value = mediumString;
    }
    var dict = {
      reference: this.state.name,
      active: this.state.status,
      medium: value.toLowerCase(),
      type: this.promotType(),
    };
    dict['expirable'] = this.state.expirySwitch;
    if (this.state.expirySwitch) {
      dict['start_at'] = this.state.startDate;
      dict['end_at'] = this.state.endDate;
    }
    if (this.state.selectedType != 'General') {
      if (this.state.selectedType == externalLink) {
        dict['value'] = this.state.valueID;
      } else {
        if (this.state.valueID['value']) {
          dict['value'] = this.state.valueID['value'];
        }
      }
    }
    // if (this.state.placement != 0) {
    dict['placement'] = this.state.selectedPlacementType.toLowerCase();
    // }
    if (image.length != 0) {
      dict['image_path'] = image;
    }

    var path = APPURL.URLPaths.promo;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.promo + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({promo_banner: dict}),
    );
    // console.log('promo_banner', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      window?.FM?.trackCustomEvent('Promo Banner', {
        email: AppConstants.userEmail,
        promo_banner: dict,
      });
      this.props.navigate(-1);
      this.customAlert();
    } else {
      this.mixpanelInfo(false);
      this.alertView(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event =
      this.state.id !== ''
        ? MixPannelEnum.editPromotions
        : MixPannelEnum.addPromotions;
    addMixpanelEvent(event, properties);
  }
  uploadImageAPI = async () => {
    this.setState({dataLoad: false});
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'].replace(
            / /g,
            '',
          ),
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          // console.log('file_upload_res', file_upload_res);
          this.addPromoBannerApi(result[0]['fileUri']);
        });
      } else {
        this.setState({dataLoad: true});
        this.alertView(responseJson);
      }
    } else {
      this.addPromoBannerApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };
  getListingAPI = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.listings + `1&per_page=100`,
      'get',
    );
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['listings'];
      for (let obj of listD) {
        let catData = {
          value: obj['id'],
          parent: obj['title'],
          label: obj['title'],
        };
        if (this.state.selectedType == 'Listing') {
          if (this.state.valueID == obj['id']) {
            this.state.valueID = catData;
            this.setState({updateUI: !this.state.updateUI});
          }
        }
        this.state.listingsArray.push(catData);
      }
    } else {
      this.alertView(responseJson);
    }
    this.setState({dataLoad: true});
  };
  getAccountsAPI = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.accountListings + `1&per_page=100&type=accounts`,
      'get',
    );
    if (responseJson['status'] == true) {
      var accountD = responseJson['data']['accounts'];
      for (let objd of accountD) {
        let catData = {
          value: objd['id'],
          parent: objd['name'],
          label: objd['name'],
        };
        if (this.state.selectedType == 'Account') {
          if (this.state.valueID == objd['id']) {
            this.state.valueID = catData;
            this.setState({updateUI: !this.state.updateUI});
          }
        }
        this.state.accountsArray.push(catData);
      }
    } else {
      AppToast(responseJson);
    }
    this.setState({dataLoad: true});
  };
  customAlert() {
    AppToast();
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Button Actions
  submitButtonAction = () => {
    if (this.state.selectedImage == null) {
      AppToast('Please add image');
    } else {
      this.uploadImageAPI();
    }
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
  };
  defaultToggle = () => {
    this.setState({default: !this.state.default});
  };
  switchStatusBtnAction(id) {
    this.setState({expirySwitch: !this.state.expirySwitch});
  }
  onPick = image => {
    // console.log('web image -=== ', image);
    this.setState({selectedImage: image});
  };
  didSelectDropDown = item => {
    if (this.state.showTypeDropDown) {
      this.state.valueID = '';
      this.setState({showTypeDropDown: false, selectedType: item});
    } else if (this.state.showPlacementDropDown) {
      this.setState({
        showPlacementDropDown: false,
        selectedPlacementType: item,
      });
    } else {
      let ind = this.state.mediumArray.findIndex(x => x == item);
      if (ind == -1) {
        if (item == MediumTypesArray[0]) {
          this.state.mediumArray[0] = item;
        } else {
          this.state.mediumArray[1] = item;
        }
      } else {
        if (item == MediumTypesArray[0]) {
          this.state.mediumArray[0] = '';
        } else {
          this.state.mediumArray[1] = '';
        }
      }
      this.setState({showDropDown: false});
    }
  };
  handleChange(date, id) {
    let dtm = Moment(date).format(dateFormat);
    if (id == 1) {
      this.setState({startDate: dtm});
    } else {
      this.setState({endDate: dtm});
    }
  }
  // UI Renders

  customTypeDropDown = id => {
    if (this.state.showTypeDropDown == true) {
      return (
        <View style={styles.dropDownViewStyle}>
          <FlatList
            data={TypesArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {' '}
            {item}{' '}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  placementTypeDropDown = id => {
    if (this.state.showPlacementDropDown == true) {
      return (
        <View style={styles.dropDownViewStyle}>
          <FlatList
            data={placementTypesArray}
            horizontal={false}
            renderItem={this.renderPlacementDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderPlacementDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item} {item == placementTypesArray[1] ? '(Web Only)' : ''}{' '}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  imagePicker = props => {
    ImagePicker.openPicker({
      width: 300,
      height: 300,
      cropping: true,
      includeBase64: true,
    }).then(image => {
      // console.log('image -=== ', image);
      this.setState({selectedImage: 'data:image/png;base64,' + image.data});
    });
  };
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 99920,
          }}>
          <MediaManagerView
            mediaData={this.state.mediaArray}
            closeBtn={() => this.setState({showMediaManager: false})}
            onSelectBtn={path =>
              this.setState({showMediaManager: false, selectedImage: path})
            }
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  imageView = props => {
    var view = [];
    view.push(
      <View style={{flexDirection: 'row'}}>
        <View style={commonStyleSheet.addImageViewStyle}>
          <ReactFileReader
            fileTypes={['video/*', '.gif', 'image/*']}
            base64={true}
            handleFiles={this.onPick}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Add Photo
            </Text>
          </ReactFileReader>
        </View>
        <View style={{width: 20, height: 20}} />
        <View style={commonStyleSheet.addImageViewStyle}>
          <TouchableOpacity
            style={{width: 100}}
            onPress={() => this.setState({showMediaManager: true})}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Select from media
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{width: 20, height: 20}} />
      </View>,
    );
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      view.push(
        <View>
          <View
            style={{
              width: itsMobileView ? 20 : 0,
              height: itsMobileView ? 20 : 0,
            }}
          />
          <Image
            source={{
              uri:
                fileName != null
                  ? this.state.selectedImage.base64
                  : this.state.selectedImage,
            }}
            style={commonStyleSheet.ImageViewStyle}
          />
        </View>,
      );
    }
    return (
      <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
        {view}
      </View>
    );
  };

  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  storeIDRender = () => {
    if (this.state.selectedType != 'General') {
      let title =
        this.state.selectedType == externalLink
          ? externalLink
          : `${this.state.selectedType} ID`;
      let placeholder =
        this.state.selectedType == externalLink
          ? externalLink
          : `${this.state.selectedType} ID`;
      // let title = AppConstants.appType ? 'Listing ID /\nExternal Link' : 'Listing ID / Account ID /\nExternal Link'
      // let placeholder = AppConstants.appType ? 'Listing ID / External Link' : 'Listing ID / Account ID / External Link'
      var views = [];
      if (this.state.selectedType == externalLink) {
        views.push(
          <TextInput
            style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
            value={this.state.valueID}
            placeholder={placeholder}
            placeholderTextColor={colors.placeholderColor}
            onChangeText={name => this.setState({valueID: name})}
          />,
        );
      } else {
        views.push(
          <View style={{width: '80%'}}>
            {this.renderSelectionView()}
            <View style={{height: 10}} />
          </View>,
        );
      }
      return (
        <View style={{zIndex: 1007}}>
          <View style={{height: 10}} />
          <View style={[commonStyleSheet.subViewStyle]}>
            <this.titleLblRender title={title} />
            {views}
          </View>
        </View>
      );
    }
    return <View></View>;
  };
  renderSelectionView = () => {
    let array =
      this.state.selectedType == 'Listing'
        ? this.state.listingsArray
        : this.state.accountsArray;
    let placeholder =
      this.state.selectedType == 'Listing'
        ? 'Select Listing'
        : 'Select Account';
    return (
      <View style={[styles.dropDownViewStyle, {marginTop: 0, borderWidth: 0}]}>
        <View style={{width: '100%', marginLeft: 10}}>
          <SelectSearchView
            value={this.state.valueID}
            onMenuOpen={() => console.log('open drop')}
            onChange={itm => this.setState({valueID: itm})}
            options={array}
            isMulti={false}
            placeholder={placeholder}
            styles={colourStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: colors.AppGreenBorder,
                primary: colors.AppGreenBorder,
              },
            })}
          />
        </View>
      </View>
    );
  };
  renderTypeView = () => {
    const typesArray = MediumTypesArray;
    var view = [];
    for (let a = 0; a < 2; a++) {
      let item = typesArray[a];
      let check = this.state.mediumArray[a] == item ? true : false;
      view.push(
        <TouchableOpacity
          style={check ? styles.selecedTypesViewStyle : styles.typesViewStyle}
          onPress={id => this.didSelectDropDown(item)}>
          <Text style={styles.titleStyle}>{item}</Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.typesViewContainerStyle}>{view}</View>;
  };
  renderPreviewView = () => {
    var imageViews = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      imageViews.push(
        <View>
          <Image
            source={{
              uri:
                fileName != null
                  ? this.state.selectedImage.base64
                  : this.state.selectedImage,
            }}
            style={previewStyleSheet.promoImageViewStyle}
          />
        </View>,
      );
    }
    return (
      <View style={{width: 320, height: '100%'}}>
        <View style={{flexDirection: 'column'}}>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <Text style={tableStyle.listTitleText}>Preview</Text>
          </View>
          <View style={previewStyleSheet.previewFrameStyle}>
            <View style={{justifyContent: 'center'}}>
              <View>
                <Image
                  style={{width: 285, height: 110}}
                  source={appHeaderView}
                />
                <View
                  style={{width: '100%', height: 5, backgroundColor: 'white'}}
                />
              </View>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}>
                <View
                  style={{flexDirection: 'row', width: '100%', height: 140}}>
                  {imageViews}
                </View>
              </ScrollView>
              <View>
                <Image
                  style={{width: 285, height: 345}}
                  resizeMode={'center'}
                  source={appBottomView}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  render() {
    let isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={isEdit ? 'Edit Promotions' : 'Add Promotions'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}
        />
        <ScrollView>
          <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
            <View style={commonStyleSheet.promoMainView}>
              {this.mediaManagerView()}
              <View style={{height: 15, width: '100%'}} />
              <View style={commonStyleSheet.subViewStyle}>
                <this.titleLblRender title={'Promotion Name'} />
                <TextInput
                  style={[
                    appConfigStyle.textFieldAddCurrencyStyle,
                    {outline: 0},
                  ]}
                  value={this.state.name}
                  placeholder="Promotion Name"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({name: name})}
                />
              </View>
              <View style={styles.dropDownView}>
                <this.titleLblRender title={'Select Medium'} />
                <View style={{width: '80%'}}>
                  <this.renderTypeView />
                </View>
              </View>
              <View style={[commonStyleSheet.subViewStyle, {zIndex: 9002}]}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    zIndex: 9829,
                  }}>
                  <Text style={commonStyleSheet.titleNameStyle}>
                    {'Upload Media'}
                    <Text style={{color: 'red', paddingTop: 5}}> *</Text>
                  </Text>
                  <ToolTipViewBtn
                    title={''}
                    description={AppConstantsMessage.uploadMediaTextHintMsg}
                  />
                </View>
                <View style={{width: '80%', zIndex: 29}}>
                  <View>
                    <this.imageView />
                  </View>
                </View>
              </View>
              <View style={{height: 10}} />
              <View style={commonStyleSheet.switchViewStyle}>
                <this.titleLblRender title={'Schedule'} />
                <View
                  style={{
                    width: itsMobileView ? '12%' : '80%',
                    flexDirection: 'row',
                  }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={() =>
                      this.setState({expirySwitch: !this.state.expirySwitch})
                    }
                    checked={this.state.expirySwitch}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
              <View style={{height: 10}} />
              <View
                style={{
                  zIndex: 89898958,
                  display: this.state.expirySwitch ? 'flex' : 'none',
                }}>
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 90999}]}>
                  <this.titleLblRender title={'Start Date'} />
                  <View style={appConfigStyle.textFieldAddCurrencyStyle}>
                    <DatePicker
                      date={Moment(this.state.startDate).format(dateFormat)}
                      color={colors.AppLightGreen}
                      placeholder={'Select Start Date'}
                      handleDateChange={date => this.handleChange(date, 1)}
                      inputStyle={{
                        borderRadius: 0,
                        borderColor: colors.TransparentColor,
                        marginTop: 5,
                        height: 20,
                        ...t1,
                      }}
                    />
                  </View>
                </View>
                <View style={{height: 10}} />
                <View style={[commonStyleSheet.subViewStyle, {zIndex: 9099}]}>
                  <this.titleLblRender title={'End Date'} />
                  <View style={appConfigStyle.textFieldAddCurrencyStyle}>
                    <DatePicker
                      date={Moment(this.state.endDate).format(dateFormat)}
                      color={colors.AppLightGreen}
                      placeholder={'Select End Date'}
                      handleDateChange={date => this.handleChange(date, 2)}
                      inputStyle={{
                        borderRadius: 0,
                        borderColor: colors.TransparentColor,
                        marginTop: 5,
                        height: 20,
                        ...t1,
                      }}
                    />
                  </View>
                </View>
                <View style={{height: 10}} />
              </View>
              <View style={[commonStyleSheet.subViewStyle, {zIndex: 1020}]}>
                <this.titleLblRender title={'Destination Type'} />
                <View style={styles.dropDownContainerStyle}>
                  <TouchableOpacity
                    style={styles.dropDownFieldStyle}
                    onPress={() =>
                      this.setState({
                        showTypeDropDown: !this.state.showTypeDropDown,
                      })
                    }>
                    <Text style={CommonStyleSheet.plainTextStyle}>
                      {this.state.selectedType}
                    </Text>
                  </TouchableOpacity>
                  <Image
                    style={
                      this.state.showDropDown == true
                        ? appConfigStyle.upArraowIconStyle
                        : appConfigStyle.downArraowIconStyle
                    }
                    resizeMode="contain"
                    source={downArrow_Icon}
                  />
                  <this.customTypeDropDown id={2} />
                </View>
              </View>
              <this.storeIDRender />
              <View style={{height: 10}} />
              <View style={[commonStyleSheet.subViewStyle, {zIndex: 1005}]}>
                <Text style={commonStyleSheet.titleNameStyle}>Placement</Text>
                <View style={styles.dropDownContainerStyle}>
                  <TouchableOpacity
                    style={styles.dropDownFieldStyle}
                    onPress={() =>
                      this.setState({
                        showPlacementDropDown:
                          !this.state.showPlacementDropDown,
                      })
                    }>
                    <Text style={CommonStyleSheet.plainTextStyle}>
                      {this.state.selectedPlacementType}{' '}
                      {this.state.selectedPlacementType ==
                      placementTypesArray[1]
                        ? '(Web Only)'
                        : ''}{' '}
                    </Text>
                  </TouchableOpacity>
                  <Image
                    style={
                      this.state.showPlacementDropDown == true
                        ? appConfigStyle.upArraowIconStyle
                        : appConfigStyle.downArraowIconStyle
                    }
                    resizeMode="contain"
                    source={downArrow_Icon}
                  />
                  <this.placementTypeDropDown />
                </View>
              </View>
              <View style={{height: 10}} />
              <View style={commonStyleSheet.switchViewStyle}>
                <Text style={commonStyleSheet.titleNameStyle}>
                  Status(Not Active / Active)
                </Text>
                <View
                  style={{
                    width: itsMobileView ? '12%' : '80%',
                    flexDirection: 'row',
                  }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={this.statusToggle}
                    checked={this.state.status}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
            </View>
            <View>
              <this.renderPreviewView />
            </View>
          </View>
          <View style={{height: 100}} />
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

export default withRouter(AddPromoBanner);

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
export const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to 
  resize from the original image resolution */
  canvas.width = 250;
  canvas.height = 250;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg');
  });
};
const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    height: windowHeight,
    backgroundColor: colors.AppLightGreen,
  },

  dropDownView: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 11,
  },
  dropDownFieldStyle: {
    marginLeft: 5,
    width: itsMobileView ? '85%' : '96%',
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropDownContainerStyle: {
    paddingLeft: 10,
    marginLeft: itsMobileView ? 0 : 10,
    width: itsMobileView ? '100%' : '80%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    flexDirection: 'row',
    zIndex: 12,
    marginTop: itsMobileView ? 10 : 0,
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    marginTop: 38,
    zIndex: 9000,
    marginLeft: -10,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 40,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    height: 40,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginLeft: 0,
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    backgroundColor: 'white',
    width: itsMobileView ? windowWidth : windowWidth / 2,
  },
});

const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
