import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {ReactComponent as LockIcon} from '../assets/lockIcon.svg';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import SubscribePlan from './SubscribePlan';
import SaveButton from './SaveButton';

const windowWidth = Dimensions.get('window').width;
export default class SecondryButton extends Component {
  static propTypes = {
    btnAction: PropTypes.func,
    btnTitle: PropTypes.string,
    showBtn: PropTypes.bool,
    btnDeActive: PropTypes.bool,
    deActiveBtnAction: PropTypes.func,
    showLockIcon: PropTypes.bool,
  };
  renderActiveBtn = () => {
    let saveTitle =
      this.props.btnTitle == undefined ? 'Save' : this.props.btnTitle;
    if (this.props.showBtn == undefined || this.props.showBtn == true) {
      return (
        <View
          style={[
            tableStyle.secondButtonViewStyle,
            {borderColor: colors.AppNewGreen},
          ]}>
          <TouchableOpacity onPress={this.props.btnAction}>
            <Text
              style={[
                tableStyle.secondBtnTextStyle,
                {color: colors.AppNewGreen},
              ]}>
              {saveTitle}
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View />;
    }
  };

  renderDeActiveBtn = () => {
    let saveTitle =
      this.props.btnTitle == undefined ? 'Save' : this.props.btnTitle;
    if (this.props.showBtn == undefined || this.props.showBtn == true) {
      // return (<View style={[tableStyle.secondButtonViewStyle, { borderColor: colors.darkGray }]}>
      //     <TouchableOpacity onPress={this.props.deActiveBtnAction} >
      //       <Text style={[tableStyle.secondBtnTextStyle, { color: colors.darkGray }]}>{saveTitle}</Text>
      //     </TouchableOpacity>
      // </View>)
      return (
        <SaveButton
          saveBtn={this.props.saveBtn}
          showSaveBtn={this.props.showSaveBtn}
          deActiveBtnAction={this.props.deActiveBtnAction}
          btnDeActive={true}
          showLock={this.props.showLockIcon ?? true}
          saveBtnTitle={this.props.btnTitle}
        />
      );
    } else {
      return <View />;
    }
  };
  render() {
    if (this.props.btnDeActive == true) {
      return <this.renderDeActiveBtn />;
    } else {
      return <this.renderActiveBtn />;
    }
  }
}
