import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
  TextInput,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
// import TranslationFields from './TranslationFields'

import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import gTick_Icon from './../../assets/grayTickIcon.png';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import { TranslateEnum } from '../../Models/TranslateEnum';
import SelectSearchView from 'react-select';
import { attributeType } from '../../HelperClasses/SingletonClass';
import { TypeEnum } from '../../Models/CommonEnum';

const APPURL = require('../../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height;


export default class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      name: '',
      dataLoad: false,
      id: '',
      selectedTabIndex: 0,
      selectedLanguage: 0,
      languageArray: [],
      selectedValue: {},
      valueIndex: 0,
      attributesArray: [],
      updateUI: false,
      editRankValue: [],
      categoriesArray: [],
      selectedCategoryArray:[],
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    data: PropTypes.any,
    isEdit: PropTypes.bool,
    titleString: PropTypes.string,
    bulkTitle: PropTypes.string,
    bulkRef: PropTypes.string,
  };
  componentDidMount() {
    // this.loadLanguageApi();
    this.loadApis();
  }
  loadApis() {
    this.setState({ dataLoad: false })
    if (this.props.bulkRef == TranslateEnum.category) {
      this.loadCatgoryApi()
    } else if (this.props.bulkRef == TranslateEnum.listingVariant) {
      this.loadListingVariantApi()
    } else if (this.props.bulkRef == TranslateEnum.paymentMethod) {
      this.loadPaymentMethodsApi()
    } else if (this.props.bulkRef == TranslateEnum.shippingMethod) {
      this.loadShippingMethodsApi()
    } else {
      this.loadCatgoryApi()
      this.loadAttributeApi()
      
    }
  }
  //MARK:-  APIs Method 
  loadCatgoryApi = async () => {
    let path = this.props.titleString == TypeEnum.listings ? 'listings' : 'accounts';
    const responseJson = await networkService.networkCall(APPURL.URLPaths.categories + path, 'get', '', '');
    this.setState({ categoriesArray: [] });
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(hierarchyD[j], `${listD[i]['name']}-> ${hierarchyD[j]['name']}`);
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(hierarchy2[k], `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`);
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(hierarchy3[l], `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`);
                  }
                }
              }
            }
          }
        }
      }
      // if (this.state.categoriesArray.length != 0){
      //   this.state.selectedValue = this.state.categoriesArray[this.state.valueIndex];
      // }
      this.setState({ dataLoad: true })
    }
    this.setState({ dataLoad: true })
  }
  manageData(data, name) {
    var deleteEnable = true;
    if (data['sub_category']) {
      deleteEnable = data['sub_category'].length == 0 ? true : false;
      if (data['sub_category'].length !== 0) {
        deleteEnable = data['parent'] == 0 ? false : true;
      }
    }
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = "";
    var level = 1;
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`
        level = hierarchyAry[a]['level']
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> '
        }
      }
    }
    let catData = {
      id: data['id'],
      parent: data['parent'],
      name: data['name'],
      active: data['active'],
      image_path: data['image_path'],
      delete: deleteEnable,
      subCategory: data['name'],
      order_by: data['order_by'],
      hierarchyName: hierarchyName,
      level: level,
      value: data['id'],
      label: hierarchyName,
    }
    this.state.categoriesArray.push(catData)
    this.setState({ dataLoad: true })
  }
  loadAttributeApi = async () => {
    this.setState({ dataLoad: false });
    let path = attributeType(this.props.titleString)
    const responseJson = await networkService.networkCall(APPURL.URLPaths.attributes + `?type=${path}`, 'get')
    this.setState({ attributesArray: [] })
    if (responseJson['status'] == true) {
      var attriD = responseJson['data']['attributes']
      console.log('attri', JSON.stringify(attriD))
      for (let objc of attriD) {
        this.state.attributesArray.push(objc)
      }
      if (this.state.attributesArray.length != 0) {
        this.state.selectedValue = this.state.attributesArray[this.state.valueIndex]
      }
      this.setState({ dataLoad: true })
    }
    this.setState({ dataLoad: true })
  };
  loadListingVariantApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.addVariant, 'get', '', '');
    this.setState({ attributesArray: [] });
    if (responseJson['status'] == true) {
      var vTypeD = responseJson['data']['variant_types'];
      for (let objc of vTypeD) {
        this.state.attributesArray.push(objc);
      }
      if (this.state.attributesArray.length != 0) {
        this.state.selectedValue = this.state.attributesArray[this.state.valueIndex];
      }
      this.setState({ dataLoad: true });
    }
    this.setState({ dataLoad: true });
  };
  loadPaymentMethodsApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tenantPaymentMethod, 'get', '', '');
    this.setState({ attributesArray: [] });
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['payment_methods'];
      for (let objc of objectD) {
        this.state.attributesArray.push(objc);
      }
      if (this.state.attributesArray.length != 0) {
        this.state.selectedValue = this.state.attributesArray[this.state.valueIndex];
      }
      this.setState({ dataLoad: true });
    }
    this.setState({ dataLoad: true });
  };
  loadShippingMethodsApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.tenantShippingMethod, 'get', '', '');
    this.setState({ attributesArray: [] });
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['shipping_methods'];
      for (let dObjc of objectD) {
        this.state.attributesArray.push(dObjc);
      }
      if (this.state.attributesArray.length != 0) {
        this.state.selectedValue = this.state.attributesArray[this.state.valueIndex];
      }
      this.setState({ dataLoad: true });
    }
    this.setState({ dateLoad: true });
  }
  translateApi = async () => {
    this.setState({ dataLoad: false });
    var values = [];
    for (let obj of this.state.translationValues) {
      let fDict = {
        value: obj['value'],
        locale: obj['locale'],
      };
      values.push(fDict)
    }
    let pDict = {
      reference_type: this.props.bulkRef,
      translation_type: 'name',
      reference_id: this.state.selectedValue['id'],
      values: values,
    }
    var path = APPURL.URLPaths.translations;
    var method = 'PATCH';
    const responseJson = await networkService.networkCall(path, method, JSON.stringify({ translation: pDict }), '');
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.loadApis();
      this.customAlert();
    }
  }
  updateBulkAtrribute = async () => {
    this.setState({ dataLoad: false })

    var dicAry = []
    for (let objc of this.state.attributesArray) {
      let categories = objc['categories']
      var catAry = []
      for (let cat of categories) {
        catAry.push(cat['id'])
      }
      var dict = {
        'order_by': objc['order_by'],
        'id': objc['id'],
        category_id: catAry,
      }
      dicAry.push(dict)
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.attributes + APPURL.URLPaths.bulk,
      'PATCH', JSON.stringify({ attributes: dicAry }))
    console.log('responseJson', responseJson)
    this.setState({ dataLoad: true })
    if (responseJson['status'] == true) {
      AppToast()
    }
  }
  customAlert() {
    AppToast()
  }
  //MARK:-  Button Actions
  saveButtonAction = () => {
    // this.translateApi()
    this.updateBulkAtrribute()
  };
  didSelectMenuItem = index => {
    this.state.selectedLanguage = 0;
    this.state.selectedValue = this.state.attributesArray[index];
    this.state.valueIndex = index
    this.setState({ updateUI: !this.state.updateUI });
  };
  didChangeTextField(text, id) {
    this.state.attributesArray[id]['order_by'] = text;
    this.setState({ updateUI: !this.state.updateUI });
  }
  handleSelectChange = (selectedOption,id) => {
    this.state.attributesArray[id]['categories'] = selectedOption
    this.setState({ updateUI: !this.state.updateUI })
  }
  selectAllBtnAction = (id) => {
    this.state.attributesArray[id]['categories'] = this.state.categoriesArray
    this.setState({ updateUI: !this.state.updateUI })
  }
  unSelectAllBtnAction = (id) => {
    this.state.attributesArray[id]['categories'] = []
    this.setState({ updateUI: !this.state.updateUI });
  }
  // UI Renders

  renderSelectAllView = ({id}) => {
    var attriCat = this.state.attributesArray[id]['categories']
    var selectedCat = []
    for (let obc of attriCat){
      let findex = this.state.categoriesArray.findIndex(x => x.id == obc.id)
      if (findex != -1){
        selectedCat.push(this.state.categoriesArray[findex])
      }
    }
    if (this.state.categoriesArray != 0) {
      return (
        <View style={{marginTop:16}}>
          <View style={{zIndex:20}} >
            <View style={{ width: '100%' }}>
              <SelectSearchView
                value={selectedCat}
                onChange={itm => this.handleSelectChange(itm,id)}
                options={this.state.categoriesArray}
                isMulti={true}
                disabled={true}
                placeholder={`Select category`}
                styles={colourStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: colors.AppGreenBorder,
                    primary: colors.AppGreenBorder,
                  },
                })}
              />
            </View>
          </View>
          <View style={{ flexDirection: 'row',marginTop:5,zIndex:10}}>
            <TouchableOpacity onPress={() => this.selectAllBtnAction(id)}>
              <Text style={styles.selectAllTextStyle}>Select All</Text>
            </TouchableOpacity>
            <Text>{`   ||   `}</Text>
            <TouchableOpacity onPress={() => this.unSelectAllBtnAction(id)}>
              <Text style={styles.selectAllTextStyle}>UnSelect All</Text>
            </TouchableOpacity>
          </View>
        </View>
      )
    } else {
      return <View />
    }
  }
  renderFields = ({id,ind}) => {
    var fieldsViews = []
    let dictData = this.state.attributesArray[id]
    if (ind == 1) {
      fieldsViews.push(
        <View>
          <this.renderSelectAllView id={id}/>
        </View>)
    } 
    else {
      fieldsViews.push(
        <View>
          <TextInput
            style={[AppConfigStyleSheet.txtFieldStyle, { outline: 0, height:43 }]}
            value={dictData['name']}
            placeholderTextColor={colors.placeholderColor}
            editable={false}
          />
        </View>
      )
    }

    return fieldsViews
  }
  renderItemTypeView = () => {
    var titleViews = []
    for (let a = 0; a < this.state.attributesArray.length; a++) {
      let fieldsArray = ['Title', 'Categories']
      var minView = []
      for (let b = 0; b < fieldsArray.length; b++) {
        let objc = fieldsArray[b]
        minView.push(
          <View style={{ width: b == 0 ? 300 :'100%', margin: 20,}}>
            <View style={{ height: 24 }}>
              <Text style={AppConfigStyleSheet.titleTxtStyle} isEdit={false}>
                {objc}
              </Text>
            </View>
            <this.renderFields id={a} ind={b} />
          </View>
        )
      }
      titleViews.push(<View style={{flexDirection:'row',zIndex: 99990 - a}}>
        {minView}
      </View>)
    }
    return <View >
      {titleViews}
    </View>
  }
  mainViewRender = () => {
    return (<View style={{ width: '75%' }}>
      <View style={{ flexDirection: 'row' }}>
        <this.renderItemTypeView />
      </View>
    </View>)
  }
  subMenuViewRender = () => {
    let subViews = []
    for (let a = 0; a < this.state.attributesArray.length; a++) {
      subViews.push(
        <TouchableOpacity
          style={this.state.selectedValue['id'] == this.state.attributesArray[a]['id'] ? SettingsStyleSheet.selectedSubMenuViewStyle
            : SettingsStyleSheet.subMenuViewStyle}
          onPress={() => this.didSelectMenuItem(a)}>
          <Image style={SettingsStyleSheet.subMenuIconStyle} resizeMode="contain" source={gTick_Icon} />
          <Text style={SettingsStyleSheet.subMenutextStyle}>
            {this.state.attributesArray[a]['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={`${this.props.bulkTitle} Edit Bulk`}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.saveButtonAction()} />
        <View style={commonStyle.mainView}>
          <ScrollView style={{ zIndex: 12, height: windowHeight / 1.23 }}>
            <View style={{ flexDirection: 'row', paddingLeft: 16 }}>
              <this.mainViewRender />
            </View>
            <Appload enable={this.state.dataLoad} />
          </ScrollView>
        </View>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
})

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', borderRadius: 5 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? colors.SimonGray
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? colors.SimonGray
            ? colors.AppRed
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
}