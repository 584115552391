import React, {Component} from 'react';
import {StyleSheet, View, Text, Platform, Alert, TextInput} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from 'react-switch';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
const KeyGroup = 'orders';
export default class OrdersSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      geoCodeKey: '',
      updateUI: false,
      dataLoad: false,
      statusArray: [false],
      returnPolicyDays: '',
      regexp: /^[0-9\.]+$/,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + KeyGroup,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        statusArray: [configs[keyParameterArray[0]] ?? false],
        returnPolicyDays: configs['return_policy_days'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    for (let i = 0; i < keyParameterArray.length; i++) {
      let fDict = {
        key_group: KeyGroup,
        key: keyParameterArray[i],
        value: i == 0 ? this.state.statusArray[i] : this.state.returnPolicyDays,
        secured: false,
      };
      configs.push(fDict);
    }
    if (configs.length != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.config,
        'post',
        JSON.stringify({configs: configs}),
      );
      if (responseJson['status'] == true) {
        this.deleteKeysAPI(configs);
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.deleteKeysAPI(configs);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keyParameterArray.length; q++) {
      deleteParms.push(keyParameterArray[q]);
    }

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm, 'delete');
      console.log('responseJson', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    AppToast();
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  switchSattusBtnAction(id) {
    this.state.statusArray[id] = !this.state.statusArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  renderAllSwitches = props => {
    var views = [];
    for (let i = 0; i < switchTitleStrings.length; i++) {
      views.push(
        <View style={{flexDirection: 'row', marginTop: 20}}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.switchSattusBtnAction(i)}
            checked={this.state.statusArray[i]}
            height={22}
            width={50}
          />
          <Text style={appConfigStyle.statusTxtStyle}>
            {switchTitleStrings[i]}
          </Text>
        </View>,
      );
    }
    return views;
  };
  onHandleTextChange = text => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.setState({returnPolicyDays: text});
    }
  };
  //MARK:-  UI 
  render() {
    return (
      <View style={{width: '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Orders</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            {/* <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Reverse Geocode Api
              </Text>
              <TextInput
                style={appConfigStyle.txtFieldStyle}
                placeholder="Enter Reverse Geocode Api"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({geoCodeKey: text})}
                value={this.state.geoCodeKey}
              />
            </View> */}
            <View style={{marginTop: -50}}>
              <this.renderAllSwitches />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Return policy days
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter return policy days"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(text)}
                value={this.state.returnPolicyDays}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  verifyEmailStyle: {
    height: 30,
    width: 150,
    backgroundColor: colors.AppNewGreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
});

const switchTitleStrings = ['Orders Calender View'];
let keyParameterArray = ['show_orders_view_type', 'return_policy_days'];
