import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  SafeAreaView,
  Dimensions,
  Image,
  TouchableOpacity,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import networkService from '../Network/NetworkManager';
import Chart from 'react-apexcharts';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import iconGlobal from './../assets/global.png';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import styles from '../StyleSheets/NewStyleSheet';
import Users from '../Users/Users';
import AccountList from '../Accounts/AccountList';
import MainListing from '../MainListing/MainListing';
import Orders from '../Orders/Orders';
import detailsStyle from '../StyleSheets/DetailStyleSheet';
import ListingDetail from '../MainListing/ListingDetail';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('../Constants/URLConstants');
const APPConstants = require('../Constants/AppConstants');
const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoad: false,
      selectedIndex: '',
      subTitleAry: [],
      seriesYearly: [{data: []}],
      optionsYearly: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '10px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        colors: [colors.Appgreen],
      },
      seriesDaily: [{data: []}],
      optionsDaily: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '10px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        colors: [colors.Appgreen],
      },
      userMonth: [{data: []}],
      optionsUserMonth: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '10px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        colors: [colors.Appgreen],
      },
      halfYearly: [{data: []}],
      optionsHalfYearly: {
        chart: {
          type: 'bar',
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '10px',
            colors: ['#fff'],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        colors: [colors.Appgreen],
      },
      hoverV: '',
      topSoldListings: [],
      showListingDetail: false,
      listingID: '',
    };
  }
  componentDidMount() {
    addMixpanelEvent(MixpanelEvent.viewReport, {type: MixPannelEnum.chartView});
    this.loadChartApi();
  }
  //MARK:-  APIs Method 
  loadChartApi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.chart,
      'get',
    );
    if (responseJson['status'] == true) {
      let data = responseJson['data'];
      console.log('data ==>>> ', data);
      this.state.subTitleAry.push({data: data['users'] || 0, href: '/users'});
      this.state.subTitleAry.push({
        data: data['accounts'] || 0,
        href: '/accounts',
      });
      this.state.subTitleAry.push({
        data: data['listings'] || 0,
        href: '/listings',
      });
      this.state.subTitleAry.push({data: data['listings_sold'] || 0});
      this.state.subTitleAry.push({data: data['total_sales'].toFixed(2) || 0});
      this.state.subTitleAry.push({
        data: data['total_orders'] || 0,
        href: '/orders',
      });
      this.state.subTitleAry.push({
        data: data['total_earnings'].toFixed(2) || 0,
      });
      this.state.topSoldListings = data['top_sold_listings'];
      var xAxisYearly = [];
      var chartValueYearly = [];
      for (let obj of data['listings_half_yearly']) {
        xAxisYearly.push(obj['month']);
        chartValueYearly.push(obj['total']);
      }
      this.state.seriesYearly = [{data: chartValueYearly}];
      this.state.optionsYearly['xaxis'] = {categories: xAxisYearly};
      var xAxisDaily = [];
      var chartValueDaily = [];
      for (let obj of data['listings_month']) {
        xAxisDaily.push(obj['date_field']);
        chartValueDaily.push(obj['total_count']);
      }
      this.state.optionsDaily['xaxis'] = {categories: xAxisDaily};
      this.state.seriesDaily = [{data: chartValueDaily}];

      var xAxisUserMonth = [];
      var chartValueUserMonth = [];
      for (let obj of data['users_month']) {
        xAxisUserMonth.push(obj['date_field']);
        chartValueUserMonth.push(obj['total_count']);
      }
      this.state.optionsUserMonth['xaxis'] = {categories: xAxisUserMonth};
      this.state.userMonth = [{data: chartValueUserMonth}];

      var xAxisHalfYearly = [];
      var chartValueHalfYearly = [];
      for (let obj of data['users_half_yearly']) {
        xAxisHalfYearly.push(obj['month']);
        chartValueHalfYearly.push(obj['total']);
      }
      this.state.halfYearly = [{data: chartValueHalfYearly}];
      this.state.optionsHalfYearly['xaxis'] = {categories: xAxisHalfYearly};

      this.setState({dataLoad: true});
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  backBtnHandler() {
    this.setState({selectedIndex: '', showListingDetail: false});
  }
  mixpanelInfo(resp) {
    let properties = {'Report Widget': `${resp} ${MixPannelEnum.card}`};
    addMixpanelEvent(MixPannelEnum.reportClick, properties);
  }
  onMouseLeave = () => {
    this.setState({hoverV: ''});
  };
  onMouseEnter = item => {
    this.setState({hoverV: item});
  };
  didSelect(item) {
    this.mixpanelInfo();
    item.href && this.props.navigate(item.href);
  }
  didSelectTopListing(item) {
    this.state.listingID = item['listing_id'];
    this.props.navigate(`/listing/${item['listing_id']}`);
  }

  //MARK:-   * UI  */
  renderYearlyChartView = () => {
    if (this.state.subTitleAry.length != 0) {
      return (
        <View style={styles.mainViewStyle}>
          <Text style={tableStyle.listTitleText}>Listings Monthly</Text>
          <View style={{flexDirection: 'row'}}>
            <View style={{marginTop: 10, flex: 1, padding: 10}}>
              <View style={styles.charViewStyle}>
                <Chart
                  options={this.state.optionsYearly}
                  series={this.state.seriesYearly}
                  type="bar"
                  height={300}
                />
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderDailyChartView = () => {
    if (this.state.subTitleAry.length != 0) {
      return (
        <View style={styles.mainViewStyle}>
          <Text style={tableStyle.listTitleText}> Listings Daily</Text>
          <View style={{flexDirection: 'row'}}>
            <View style={{marginTop: 10, flex: 1, padding: 10}}>
              <View style={styles.charViewStyle}>
                <Chart
                  options={this.state.optionsDaily}
                  series={this.state.seriesDaily}
                  type="bar"
                  height={300}
                />
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderUserMonthChartView = () => {
    if (this.state.subTitleAry.length != 0) {
      return (
        <View style={styles.mainViewStyle}>
          <Text style={tableStyle.listTitleText}> Users Daily</Text>
          <View style={{flexDirection: 'row'}}>
            <View style={{marginTop: 10, flex: 1, padding: 10}}>
              <View style={styles.charViewStyle}>
                <Chart
                  options={this.state.optionsUserMonth}
                  series={this.state.userMonth}
                  type="bar"
                  height={300}
                />
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderUserHalfYearlyChartView = () => {
    if (this.state.subTitleAry.length != 0) {
      return (
        <View style={styles.mainViewStyle}>
          <Text style={tableStyle.listTitleText}> Users Monthly</Text>
          <View style={{flexDirection: 'row'}}>
            <View style={{marginTop: 10, flex: 1, padding: 10}}>
              <View style={styles.charViewStyle}>
                <Chart
                  options={this.state.optionsHalfYearly}
                  series={this.state.halfYearly}
                  type="bar"
                  height={300}
                />
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };

  renderReportView = () => {
    var view = [];
    for (let a = 0; a <= this.state.subTitleAry.length - 1; a++) {
      let item = titleArray[a];
      let check = this.state.hoverV == item;

      view.push(
        <View
          onMouseLeave={() => this.onMouseLeave(item)}
          onMouseEnter={() => this.onMouseEnter(item)}>
          <TouchableOpacity
            disabled={this.state.subTitleAry[a].href ? false : true}
            style={[
              styles.settingViewsStyle,
              {shadowOpacity: check ? 0.5 : 0.2},
            ]}
            onPress={() => this.didSelect(this.state.subTitleAry[a])}>
            <Image style={styles.iconImageViewStyle} source={iconGlobal} />
            <View style={styles.report_subViewStyle}>
              <Text style={styles.titleStyle}>{item}</Text>
              <Text style={styles.subTitleStyle}>
                {this.state.subTitleAry[a].data}
              </Text>
            </View>
          </TouchableOpacity>
        </View>,
      );
    }
    return view;
  };
  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View style={tableStyle.headerSubContainer}>
          <View style={{flexDirection: 'row', alignItems: 'center', zIndex: 4}}>
            <Text style={tableStyle.titleTextStyle}>{'Report '}</Text>
          </View>
        </View>
      </View>
    );
  };
  renderTopListingView = () => {
    if (this.state.topSoldListings.length != 0) {
      let tableHeaderString = this.state.topSoldListings;
      var detailView = [];
      let length = tableHeaderString.length - 1;
      for (let a = 0; a < tableHeaderString.length; a++) {
        let item = tableHeaderString[a];
        detailView.push(
          <TouchableOpacity
            onPress={() => this.didSelectTopListing(item)}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 0,
            }}>
            <View
              style={{
                flex: 1,
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
                borderRightWidth: 0,
              }}>
              <Text style={detailsStyle.tableViewTitleStyle}>
                {item['title']}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                borderColor: colors.Lightgray,
                borderWidth: 1,
                borderBottomWidth: length == a ? 1 : 0,
                alignItems: 'flex-end',
              }}>
              <Text style={detailsStyle.valueTitleStyle}>{item['counts']}</Text>
            </View>
          </TouchableOpacity>,
        );
      }
      return (
        <View style={styles.topListingViewStyle}>
          <Text style={tableStyle.listTitleText}> Top Sold Listings</Text>
          <View style={{marginLeft: 16, padding: 10, paddingRight: 0}}>
            {detailView}
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderMainView = () => {
    return (
      <SafeAreaView style={styles.Container}>
        <this.renderHeaderView />
        <View style={tableStyle.containerMainView}>
          <ScrollView>
            <View style={styles.containerView}>
              <this.renderReportView />
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: itsMobileView ? 'column' : 'row',
              }}>
              {this.renderDailyChartView()}
              {this.renderYearlyChartView()}
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: itsMobileView ? 'column' : 'row',
              }}>
              {this.renderUserMonthChartView()}
              {this.renderUserHalfYearlyChartView()}
            </View>
            <View style={{backgroundColor: colors.AppWhite}}>
              {this.renderTopListingView()}
            </View>
          </ScrollView>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  };

  renderAllViews = () => {
    if (this.state.selectedIndex == titleArray[0]) {
      return <Users />;
    } else if (this.state.selectedIndex == titleArray[1]) {
      return <AccountList />;
    } else if (this.state.selectedIndex == titleArray[2]) {
      return <MainListing />;
    } else if (this.state.selectedIndex == titleArray[5]) {
      return <Orders />;
    } else {
      return <this.renderMainView />;
    }
  };
  render() {
    return <this.renderAllViews />;
  }
}

export default withRouter(Report);

const titleArray = [
  'Users',
  'Accounts',
  'Listings',
  'Listings Sold',
  'Total Sales',
  'Total Orders',
  'Total Earnings',
];
