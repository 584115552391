import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from "react-switch";
import { addMixpanelEvent, MixpanelEvent } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'general';

const feedbackTypeArray = ['Email', 'Phone', 'Email or Phone']

export default class FeedbackIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      feedBackSwitch: true,
      emailsString: '',
      showDropDown: false,
      selectedFeedbackType: ''
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
    this.loadConfigEmailApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=email',
      'get', '', '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      let emailsString = configs['feedback_email_to'] || '';
      let x = emailsString.toString();
      if (configs['feedback_email_to']) {
        this.setState({emailsString: x});
      }
    }
    this.setState({dataLoad: true});
  };
  loadConfigEmailApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general','get','','')
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      if (configs['feedback_contact_field']) {
        let fetype = configs['feedback_contact_field']
        if (fetype == 1) {
          this.state.selectedFeedbackType = feedbackTypeArray[0];
        } else  if (fetype == 2) {
          this.state.selectedFeedbackType = feedbackTypeArray[1];
        } else  if (fetype == 3) {
          this.state.selectedFeedbackType = feedbackTypeArray[2];
        }
      }
      let emailFeedback = configs['enable_feedback'];
      // console.log('emailFeedback =>', emailFeedback);
      this.setState({feedBackSwitch: emailFeedback});
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    var emailsarray = this.state.emailsString.split(',');

    let fDict = {
      key_group: 'general',
      key: 'enable_feedback',
      value: this.state.feedBackSwitch,
      secured: false,
    };
    // console.log('fDict ==> ', fDict);
    configs.push(fDict);
    if (this.state.feedBackSwitch) {
      if (emailsarray.length != 0) {
        let fDict2 = {
          key_group: 'email',
          key: 'feedback_email_to',
          value: emailsarray,
          secured: false,
        };
        configs.push(fDict2);
      }
      if (this.state.selectedFeedbackType.length != 0) {
        var feedbackType = 1;
        if (this.state.selectedFeedbackType == feedbackTypeArray[1]) {
          feedbackType = 2
        } else if (this.state.selectedFeedbackType == feedbackTypeArray[2]) {
          feedbackType = 3
        }
        let fDict2 = {
          key_group: 'general',
          key: 'feedback_contact_field',
          value: feedbackType,
          secured: false,
        };
        configs.push(fDict2);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,'post',JSON.stringify({configs: configs}))
    // console.log('configs', responseJson);
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      addMixpanelEvent(MixpanelEvent.feedbackIntegration,{'success':true})
      this.setState({dataLoad: true})
      this.alertView('Uploaded successfully')
    } else {
      addMixpanelEvent(MixpanelEvent.feedbackIntegration,{'success':false})
      this.alertView(responseJson)
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  didSelectDropDown = item => {
    this.setState({ showDropDown: false,  selectedFeedbackType: item});
    
  };
  //MARK:-  UIs 
  
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={feedbackTypeArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={this.state.selectedFeedbackType == '' ? appConfigStyle.placeholderTxtStyle : appConfigStyle.txtFieldStyleDropDown}>
          {this.state.selectedFeedbackType == '' ? 'Select type' : this.state.selectedFeedbackType}
        </Text>
        <Image
          style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  renderFeedBackView = () => {
    if (this.state.feedBackSwitch) {
      return (<View>
        <View style={{ marginTop: 30, zIndex: 1233 }}>
          <Text style={appConfigStyle.titleTxtStyle}>
            Form Required Field
          </Text>
          <TouchableOpacity
            style={styles.dropDownViewStyle}
            onPress={() => this.setState({ showDropDown: !this.state.showDropDown })}>
            <this.renderValueLabel id={1} />
            <this.customDropDown id={1} />
          </TouchableOpacity>
        </View>
        <View style={{ marginTop: 30, zIndex: 1, }}>
          <Text style={appConfigStyle.titleTxtStyle}>
            Email to receive feedback (For more than one email, enter as
            comma separated)
          </Text>
          <TextInput
            style={[appConfigStyle.txtFieldStyle,{outline:0}]}
            placeholder="Enter email"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={email => this.setState({ emailsString: email })}
            value={this.state.emailsString}
          />
        </View>
      </View>
      )
    } else {
      return <View />
    }
  }
  render() {
    return (
      <View style={{width: '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Feedback Form</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Enable feedback Form
              </Text>
              <View style={{flexDirection: 'row', marginTop: 20}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({ feedBackSwitch: !this.state.feedBackSwitch })}
                  checked={this.state.feedBackSwitch}
                  height={22}
                  width={50}
                />
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={appConfigStyle.statusTxtStyle}>Feedback</Text>
                </View>
              </View>
              <this.renderFeedBackView />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: '100%',
    zIndex: 2883,
  },
});
