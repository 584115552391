import React from 'react';
import {Dimensions, Text, View} from 'react-native';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import AppConstants from '../../Constants/AppConstants';
import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import SaveButton from '../../Component/SaveButton';
import SecondryButton from '../../Component/SecondryButton';
import {useNavigate, useSearchParams} from 'react-router-dom';
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const AttributeGroupHeaderView = ({onCreateAction}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') ?? 'listings';
  const navigate = useNavigate();
  return (
    <View style={[tableStyle.headerViewstyle, {display: 'flex', zIndex: 10}]}>
      <View style={tableStyle.headerTitleContainerViewStyle}>
        <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
          Attribute Groups
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginTop: itsMobileView ? 5 : 0,
          zIndex: 0,
        }}>
        <SecondryButton
          showBtn={true}
          btnTitle={'Attributes'}
          btnAction={() =>
            navigate(
              type == 'listings'
                ? '/listing-attributes'
                : type == 'users'
                ? '/user-attributes'
                : '/account-attributes',
            )
          }
        />
        <SaveButton
          saveBtn={() => onCreateAction()}
          saveBtnTitle={'Add Attribute Group'}
        />
      </View>
    </View>
  );
};

export default AttributeGroupHeaderView;
