import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
  Platform,
  TextInput,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { Gradient } from 'react-gradient';
import OrderDetail from './DeliveryOrderDetail';
import { dateConversionFromString, dateConversionFromTimeStamp } from '../../HelperClasses/SingletonClass';
import Board from 'react-trello'
import listIcon from '../../assets/listIcon.png';
import selectedListIcon from '../../assets/selectedListIcon.png';

import DeliveryOrderStatus from '../../Models/DeliveryStatusEnum';
import checked_Icon from '../../assets/checkedIcon.png';
import unchecked_Icon from '../../assets/uncheckedIcon.png';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import closeIcon from '../../assets/closeIcon.png';
import { AppToast } from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false
let laneHeight = windowHeight / 1.55

let dateFormat = 'yyyy-MM-DD'
let PerPage = 50

export default class DeliveryOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntriesCount: 10,
      ordersArray: [],
      tempArray:[],
      dataLoad: false,
      isEdit: false,
      createCollectionBool: false,
      orderID: 0,
      stopPage: false,
      showOrderDetail: false,
      orderID: 0,
      haveData: false,
      statusArray: [],
      showKanbanView: false,
      showFilterView: false,
      selectedFilterID: -1,
      showDropDown:false,
      perPage:PerPage,
      totalRecords: 0,
      branchesArray:[],
      selectedBranchAarray:[],
      appliedBranch:[],
      driverArray:[],
      selectedDriverAarray:[],
      appliedDriver:[],
      filterStartDate: '',//new Date(),
      filterEndDate: '',// new Date(),
      appliedStartDate:'',
      appliedEndDate:'',
      fleetsArray:[],
      selectedFleetsArray:[],
      appliedFleets:[],
      deliveryTypeArray:[],
      selectedDeliveryTypeArray:[],
      appliedDeliveryType:[],
      paymentTypeArray:[],
      selectedPaymentTypeArray:[],
      appliedPaymentType:[],
      statusArray:[],
      selectedStatusArray:[],
      appliedStatusType:[],
      searchKey: '',
      typingTimeout: 0,
      showSearchView:false,
      showDownloadView:false,
      downloadReportLink:'',
      linkValidTime:'',
      loaderTitle:'Please wait',
    }
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    pageNo = 1
    this.mixPannelInfo()
    this.loadOrdersApi()
    this.loadBranchsApi()
    this.loadDriverApi()
    this.loadFleetsApi()
    this.loadLocalData()
    this.refreshAPI() 
  }
  componentWillUnmount(){
    clearInterval(this.interval);
  }
  mixPannelInfo(){
    addMixpanelEvent(`${MixPannelEnum.view} ${MixpanelEvent.deliveryOrder}`,{'page':pageNo})
  }
  initApi(){
    pageNo = 1
    this.loadOrdersApi()
  }
  loadLocalData() {
    let a = 1
    while (a < 4) {
      let dic = {
        'id': a,
        'name': DeliveryOrderStatus.deliveryType(a)
      }
      this.state.deliveryTypeArray.push(dic)
      a++
    }
    let b = 1
    while (b < 3) {
      let dic = {
        'id': b,
        'name': DeliveryOrderStatus.paymentType(b)
      }
      this.state.paymentTypeArray.push(dic)
      b++
    }
    let c = 1
    while (c < 12) {
      let dic = {
        'id': c,
        'name': DeliveryOrderStatus.status(c)
      }
      this.state.statusArray.push(dic)
      c++
    }
  }
  //MARK:-  APIS Method 
  refreshAPI() {
    this.interval = setInterval(() => {
      console.log('calling api after 5 secs')
      if (this.state.showOrderDetail) {
        clearInterval(this.interval)
      }else {this.loadOrdersApi(true)  }
      }, 5000)
  }
  loadOrdersApi = async (hideLoader) => {
    this.setState({ dataLoad: hideLoader ? true : false });
    var param = ''
    if (this.state.appliedBranch.length != 0) {
      param = `&branch_id=${this.state.appliedBranch.toString()}`
    }
    if (this.state.appliedDriver.length != 0) {
      param = param + `&driver_id=${this.state.appliedDriver.toString()}`
    }
    if (this.state.appliedEndDate.length != 0) {
      let endD = Moment(this.state.appliedEndDate).format(`${dateFormat}`)
      param = param + `&delivery_slot_to=${endD}T00:00:00Z`
    }
    if (this.state.appliedStartDate.length != 0) {
      let strtD = Moment(this.state.appliedStartDate).format(`${dateFormat}`)
      param = param + `&delivery_slot_from=${strtD}T00:00:00Z`
    }
    if (this.state.appliedFleets.length != 0) {
      param = param + `&fleet_id=${this.state.appliedFleets.toString()}`
    }
    if (this.state.appliedDeliveryType.length != 0) {
      param = param + `&delivery_type=${this.state.appliedDeliveryType.toString()}`
    }
    if (this.state.appliedPaymentType.length != 0) {
      param = param + `&payment_type=${this.state.appliedPaymentType.toString()}`
    }
    if (this.state.appliedStatusType.length != 0) {
      param = param + `&status=${this.state.appliedStatusType.toString()}`
    }
    if (this.state.searchKey.length != 0){
      param = param + `&order_id=${this.state.searchKey}`
    }
    console.log('param', param)
    let path = `${APPURL.URLPaths.deliveryOrder}?page=${pageNo}&per_page=${this.state.perPage}${param}`
    const responseJson = await networkService.networkCall(path, 'get');
    console.log('orders', responseJson);
    this.setState({ ordersArray: [] });
    if (responseJson['status'] == true) {
      var ordrD = responseJson['data']['orders'];
      this.state.totalRecords = responseJson['data']['total_records'];
      if (ordrD.length != 0) {
        this.setState({ ordersArray: [] });
        this.setState({ stopPage: false });
        // this.statusBoardData(ordrD)
        for (let i = 0; i < ordrD.length; i++) {
          this.state.ordersArray.push(ordrD[i])
          this.state.tempArray.push(ordrD[i])
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({ stopPage: true });
      }
      this.state.haveData = this.state.ordersArray.length == 0 ? true : false
    } else {
      this.state.haveData = true
      AppToast(responseJson)
    }
    this.setState({ dataLoad: true });
  }
  loadBranchsApi = async () => {
    let path = `${APPURL.URLPaths.deliveryBranches}?page=${1}&per_page=${100}`
    const responseJson = await networkService.networkCall(path, 'get');
    this.setState({ branchesArray: [] });
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches']
      for (let objc of branchD){
        this.state.branchesArray.push(objc)
      }
    }
  }
  loadDriverApi = async () => {
    let path = `${APPURL.URLPaths.deliveryDriver}?page=${1}&per_page=${100}&type=driver`
    const responseJson = await networkService.networkCall(path, 'get');
    this.setState({ driverArray: [] });
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['users']
      for (let objc of branchD){
        this.state.driverArray.push(objc)
      }
    }
  }
  loadFleetsApi = async () => {
    let path = `${APPURL.URLPaths.deliveryFleets}?page=${1}&per_page=${100}`
    const responseJson = await networkService.networkCall(path, 'get');
    this.setState({ fleetsArray: [] });
    if (responseJson['status'] == true) {
      var fleetD = responseJson['data']['fleets']
      for (let objc of fleetD){
        this.state.fleetsArray.push(objc)
      }
    }
  }
  generateReportApi = async () => {
    this.setState({loaderTitle:'Generating report'})
    let path = `${APPURL.URLPaths.deliveryReport}`
    var dict = {'type'  : 'orders'}
    var param = {}
    if (this.state.appliedBranch.length != 0) {
      param['branch_id'] = `${this.state.appliedBranch.toString()}`
    }
    if (this.state.appliedDriver.length != 0) {
      param['driver_id']  = `${this.state.appliedDriver.toString()}`
    }
    if (this.state.appliedEndDate.length != 0) {
      let endD = Moment(this.state.appliedEndDate).format(`${dateFormat}`)
      param['delivery_slot_to']=`${endD}T00:00:00Z`
    }
    if (this.state.appliedStartDate.length != 0) {
      let strtD = Moment(this.state.appliedStartDate).format(`${dateFormat}`)
      param['delivery_slot_from'] = `${strtD}T00:00:00Z`
    }
    if (this.state.appliedFleets.length != 0) {
      param['fleet_id'] = `${this.state.appliedFleets.toString()}`
    }
    if (this.state.appliedDeliveryType.length != 0) {
      param['delivery_type'] =  `${this.state.appliedDeliveryType.toString()}`
    }
    if (this.state.appliedPaymentType.length != 0) {
      param['payment_type'] = `${this.state.appliedPaymentType.toString()}`
    }
    if (this.state.appliedStatusType.length != 0) {
      param['status'] = `${this.state.appliedStatusType.toString()}`
    }
    if (this.state.searchKey.length != 0){
      param['order_id'] =  `${this.state.searchKey}`
    }
    if ( Object.keys(param).length != {}){
      dict['params'] = param
    }
    this.setState({ dataLoad: false });
    const responseJson = await networkService.networkCall(path, 'post',JSON.stringify(dict));
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.state.loaderTitle = 'Please wait'
      this.state.downloadReportLink = responseJson['data']['report_url']
      this.setState({linkValidTime: responseJson['data']['valid_until'],showDownloadView:true})
    }else {
      this.mixpanelExport(false)
    }
    this.setState({ dataLoad: true })
  }
  mixpanelExport(resp){
    addMixpanelEvent(`${MixpanelEvent.deliveryOrderExport}`,{'success':resp})

  }
  statusBoardData(data) {
    this.state.statusArray = []
    let ssArray = []
    var commonArray = []
    var a = 1;
    while (a < 11) {
      let objc = []
      commonArray.push(objc)
      a++
    }
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      let status = item['status'] - 1
      let pstatus = DeliveryOrderStatus.paymentType(item['payment_type'])
      let dic = {
        id: item['order_id'],
        title: item['order_reference'],
        description: pstatus,
        draggable: true,
      }
      let dicAry = commonArray[status]
      dicAry.push(dic)
      commonArray[status] = dicAry
    }
    var k = 1;
    while (k < 11) {
      let status = DeliveryOrderStatus.status(k);
      let randomColor = OrderBGColor[k]
      let pendingDic = {
        id: 'lane'+k,
        title: status,
        label: '',
        cards: commonArray[k - 1],
        style: { backgroundColor: randomColor, maxHeight: laneHeight }
      }
      ssArray.push(pendingDic)
      k++
    }
    this.state.statusArray.push({ lanes: ssArray })
    this.setState({ updateUI: !this.state.updateUI })
    // console.log('statusArray', this.state.statusArray)
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({ showOrderDetail: false })
    this.refreshAPI()
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadOrdersApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadOrdersApi();
      }
    }
    this.mixPannelInfo()
    this.setState({ updateUI: !this.state.updateUI });
  }
  viewOrderBtnAction = id => {
    this.setState({ showOrderDetail: true, orderID: id });
  }
  onCardClick = (cardId, metadata, laneId) => {
    this.setState({ showOrderDetail: true, orderID: cardId })
  }
  didSelectFilter(id) {
    this.setState({ selectedFilterID: this.state.selectedFilterID == id ? -1 : id })
    if (id == 7){
      this.clearBtnAction(id)
    } 
  }
  applyBtnAction(id) {
    // if (id == 1){
      this.state.appliedBranch = this.state.selectedBranchAarray
    // } else {
      this.state.appliedDriver = this.state.selectedDriverAarray
    // }
    this.state.appliedEndDate = this.state.filterEndDate
    this.state.appliedStartDate = this.state.filterStartDate
    this.state.appliedStatusType = this.state.selectedStatusArray
    this.state.appliedDeliveryType = this.state.selectedDeliveryTypeArray
    this.state.appliedPaymentType = this.state.selectedPaymentTypeArray

    this.setState({showFilterView: false, selectedFilterID: -1 })

    this.initApi()
  }
  clearBtnAction(id){
    if (id == 0){
      this.state.appliedBranch = []
    } else  if (id == 1) {
      this.state.appliedDriver = []
    } else  if (id == 2) {
      this.state.appliedEndDate = ''
      this.state.appliedStartDate = ''
      this.state.filterEndDate = ''
      this.state.filterStartDate = ''
    } else  if (id == 3) {
      this.state.appliedFleets = []
    } else  if (id == 4) {
      this.state.appliedDeliveryType = []
    } else  if (id == 5) {
      this.state.appliedPaymentType = []
    } else  if (id == 6) {
      this.state.appliedStatusType = []
    } else{
      this.state.appliedBranch = []
      this.state.appliedDriver = []
      this.state.appliedFleets = []
      this.state.appliedEndDate = ''
      this.state.appliedStartDate = ''
      this.state.filterEndDate = ''
      this.state.appliedPaymentType = []
      this.state.appliedDeliveryType = []
      this.state.appliedStatusType = []
      this.state.filterStartDate = ''
    }
    this.setState({showFilterView: false,selectedFilterID:-1})
    this.initApi()
  }
  fiterBtnAction() {
    console.log('fiterBtnAction', this.state.appliedBranch)
    let brnach = [... this.state.appliedBranch]
    let driver = [... this.state.appliedDriver]
    let fleet = [... this.state.appliedFleets]
    let payment = [... this.state.appliedPaymentType]
    let delivery = [... this.state.appliedDeliveryType]
    let status = [... this.state.appliedStatusType]
    this.state.selectedFleetsArray = fleet
    this.state.selectedBranchAarray = brnach
    this.state.selectedDriverAarray = driver
    this.state.selectedPaymentTypeArray = payment
    this.state.selectedDeliveryTypeArray = delivery
    this.state.selectedStatusArray = status

    this.setState({ showFilterView: !this.state.showFilterView, selectedFilterID: -1 })
  }
  onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
    // let statusValue = toLaneId.slice(4)
    // this.updateStatusApi(cardId,statusValue)
  }

  didSelectFilterSubmenu(data) {
    let item = data['item']
    let id = data['id']
    if (id == 0) {
      let ind = this.state.selectedBranchAarray.findIndex(x => x == item['id'])
      if (ind != -1) {
        this.state.selectedBranchAarray.splice(ind, 1)
      } else {
        this.state.selectedBranchAarray.push(item['id'])
      }
    } else if (id == 1) {
      let ind = this.state.selectedDriverAarray.findIndex(x => x == item['id'])
      if (ind != -1) {
        this.state.selectedDriverAarray.splice(ind, 1)
      } else {
        this.state.selectedDriverAarray.push(item['id'])
      }
    } else if (id == 3) {
      let ind = this.state.selectedFleetsArray.findIndex(x => x == item['id'])
      if (ind != -1) {
        this.state.selectedFleetsArray.splice(ind, 1)
      } else {
        this.state.selectedFleetsArray.push(item['id'])
      }
    } else if (id == 4) {
      let ind = this.state.selectedDeliveryTypeArray.findIndex(x => x == item['id'])
      if (ind != -1) {
        this.state.selectedDeliveryTypeArray.splice(ind, 1)
      } else {
        this.state.selectedDeliveryTypeArray.push(item['id'])
      }
    } else if (id == 5) {
      let ind = this.state.selectedPaymentTypeArray.findIndex(x => x == item['id'])
      if (ind != -1) {
        this.state.selectedPaymentTypeArray.splice(ind, 1)
      } else {
        this.state.selectedPaymentTypeArray.push(item['id'])
      }
    } else if (id == 6) {
      let ind = this.state.selectedStatusArray.findIndex(x => x == item['id'])
      if (ind != -1) {
        this.state.selectedStatusArray.splice(ind, 1)
      } else {
        this.state.selectedStatusArray.push(item['id'])
      }
    }
    this.setState({updateUI: !this.state.updateUI})
  }
  onSearchTextChange = text => {
    this.setState({ searchKey: text })
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(function () {
        this.initApi()
      }.bind(this), 1000)
    })
  }
  onCancelText() {
    if (this.state.searchKey.length != 0) {
      this.state.searchKey = ''
      this.initApi()
    }
    this.setState({showSearchView: false})
  }
  downloadBtnAction(){
    if (Platform.OS == 'web') {
      this.mixpanelExport(true)
      window.open(this.state.downloadReportLink)
      this.setState({showDownloadView:false})
    }
  }
  exportBtnAction(){
    this.state.loaderTitle = 'Generating report please wait....'
    this.generateReportApi()
  }
  removeFilterValueBtnAcion(item){
    if (item['type'] == 1){
      console.log(this.state.appliedBranch,'id branch',item['id'])
      let indx = this.state.appliedBranch.findIndex(x => x == item['id']) 
      console.log('id inddex',indx)
      this.state.appliedBranch.splice(indx, 1)
    } else if (item['type'] == 2){
      let indx = this.state.appliedDriver.findIndex(x => x == item['id']) 
      this.state.appliedDriver.splice(indx, 1)
    }  else if (item['type'] == 3){
       this.state.appliedEndDate = ''
       this.state.appliedStartDate = ''
    } else if (item['type'] == 4){
      let indx = this.state.appliedFleets.findIndex(x => x == item['id']) 
      this.state.appliedFleets.splice(indx, 1)
    } else if (item['type'] == 5){
      let indx = this.state.appliedDeliveryType.findIndex(x => x == item['id']) 
      this.state.appliedDeliveryType.splice(indx, 1)
    } else if (item['type'] == 6){
      let indx = this.state.appliedPaymentType.findIndex(x => x == item['id']) 
      this.state.appliedPaymentType.splice(indx, 1)
    } else if (item['type'] == 7){
      let indx = this.state.appliedStatusType.findIndex(x => x == item['id']) 
      this.state.appliedStatusType.splice(indx, 1)
    } 
    this.setState({updateUI: !this.state.updateUI})
    this.initApi()
  }
  /*  UI   */
  renderFilterView = () => {
    return <View style={[tableStyle.filterContainerViewStyle,{flexDirection: 'row-reverse'}]}>
      <View style={{ display: this.state.showFilterView ? 'flex' : 'none', flexDirection: 'row-reverse'}}>
        <View style={[tableStyle.filterViewStyle,{marginLeft:0,flexDirection:'row-reverse'}]}>
          <FlatList
            data={FilterStaticArray}
            renderItem={this.renderFilterCell}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item, index) => index + 9989}
            key={'FL'}
            style={{marginBottom:10}}
          />
          <View style={{ height: '100%', width: 1, backgroundColor: colors.SimonGray }} />
          <View>
            {this.renderSubListView(0)}
            {this.renderSubListView(1)}
            {this.renderDateView(2)}
            {this.renderSubListView(3)}
            {this.renderSubListView(4)}
            {this.renderSubListView(5)}
            {this.renderSubListView(6)}
          </View>
        </View>
      </View>
    </View>
  }
  renderFilterCell = ({ item, index }) => {
    return (
      <TouchableOpacity onPress={() => this.didSelectFilter(index)}
        style={{ marginLeft: 8, marginTop: 10, marginRight: 8, backgroundColor: this.state.selectedFilterID == index ? colors.SimonGray : colors.TransparentColor, padding: 5, borderRadius: 2 }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    );
  }

  renderClearApplyButton = (id) => {
    return <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <TouchableOpacity onPress={() => this.clearBtnAction(id)}>
        <Text style={tableStyle.clearTxtStyle}>Clear</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => this.applyBtnAction(id)}>
        <Text style={tableStyle.applyTxtStyle}>Apply</Text>
      </TouchableOpacity>
    </View>
  }
  renderDateView = () => {
    let strtD = this.state.filterStartDate.length == 0 ? 'Select Date' : Moment(this.state.filterStartDate).format(dateFormat)
    let endD = this.state.filterEndDate.length == 0 ? 'Select Date' : Moment(this.state.filterEndDate).format(dateFormat)
    return <View style={{ width: 160, display: this.state.selectedFilterID == 2 ? 'flex' : 'none' }}>
      <View style={{ zIndex: 126 }}>
        <Text style={CommonStyleSheet.dateTitleStyle}>Start Date</Text>
        <View style={tableStyle.dateTextField}>
          <DatePicker
            selected={this.state.filterStartDate}
            onChange={(date) => this.setState({ filterStartDate: date })}
            dateFormat="MM/dd/yyyy h:mm:ss"
            showTimeInput={false}
            customInput={<View style={{ height: 20, width: 100 }}> 
            <Text style={CommonStyleSheet.dateTitleStyle}>{strtD}</Text>
            </View>}
          />
        </View>
      </View>
      <View style={{zIndex: 123}}>
      <Text style={CommonStyleSheet.dateTitleStyle}>End Date</Text>
        <View style={tableStyle.dateTextField}>
          <DatePicker
            selected={this.state.filterEndDate}
            onChange={(date) => this.setState({ filterEndDate: date })}
            dateFormat="MM/dd/yyyy h:mm:ss"
            showTimeInput={false}
            customInput={<View style={{ height: 20, width: 100 }}>
              <Text style={CommonStyleSheet.dateTitleStyle}>{endD}</Text>
            </View>}
          />
        </View>
      </View>
      <View style={{ zIndex: 12,marginLeft:10, marginTop: 50, marginRight: 20 }}>
        {this.renderClearApplyButton(2)}
      </View>
    </View>
  }
  renderSubListView = (id) => {
    var views = []
    var dynamicAry = id == 0 ? this.state.selectedBranchAarray : this.state.selectedDriverAarray
    var valueArray = id == 0 ? this.state.branchesArray : this.state.driverArray
    var check = this.state.selectedFilterID == 0 ? true : false
    if (id == 1) {
      check = this.state.selectedFilterID == 1 ? true : false
    } else if (id == 3) {
      dynamicAry = this.state.selectedFleetsArray
      valueArray = this.state.fleetsArray
      check = this.state.selectedFilterID == 3 ? true : false
    }else if (id == 4) {
      dynamicAry = this.state.selectedDeliveryTypeArray
      valueArray = this.state.deliveryTypeArray
      check = this.state.selectedFilterID == 4 ? true : false
    } else if (id == 5) {
      dynamicAry = this.state.selectedPaymentTypeArray
      valueArray = this.state.paymentTypeArray
      check = this.state.selectedFilterID == 5 ? true : false
    } else if (id == 6) {
      dynamicAry = this.state.selectedStatusArray
      valueArray = this.state.statusArray
      check = this.state.selectedFilterID == 6 ? true : false
    }
    for (let a = 0; a < valueArray.length; a++) {
      let item = valueArray[a]
      let ind = dynamicAry.findIndex(x => x == item['id'])
      var name = item['name']
      if (id == 3){
        name = `${item['fleet_type']} (${item['plate_number']})`
      }
      views.push(<View>
        <TouchableOpacity style={styles.filterSubItemViewStyle}
          onPress={() => this.didSelectFilterSubmenu({item:item, id:id})} >
          <Text>{name}</Text>
          <Image style={{ width: 12, height: 12, marginRight: 10 }} source={ind != -1 ? checked_Icon : unchecked_Icon} />
        </TouchableOpacity>
        <View style={{ height: 5, width: 5 }} />
      </View>)
    }
    return <View style={{ width: 200,display: check ? 'flex' : 'none' }}>
      <View style={{ margin: 10 }}>
        <ScrollView style={{height:250, marginTop:0}}>
          <View>
            {views}
          </View>
        </ScrollView>
        <View style={{ zIndex: 12, marginTop: 10}}>
          {this.renderClearApplyButton(id)}
        </View>
      </View>
    </View>
  }
  renderFleetView = (id) => {
    var views = []
    let dynamicAry = this.state.selectedFleetsArray
    let valueArray = this.state.fleetsArray
    var check = this.state.selectedFilterID == 3 ? true : false
    for (let a = 0; a < valueArray.length; a++) {
      let item = valueArray[a]
      let ind = dynamicAry.findIndex(x => x == item['id'])
      views.push(<View>
        <TouchableOpacity style={styles.filterSubItemViewStyle}
          onPress={() => this.didSelectFleets(item)} >
          <Text>{'dd'}</Text>
          <Image style={{ width: 12, height: 12, marginRight:10 }} source={ind != -1 ? checked_Icon : unchecked_Icon} />
        </TouchableOpacity>
        <View style={{ height: 5, width: 5 }} />
      </View>)
    }
    return <View style={{ width: 200,display: check ? 'flex' : 'none' }}>
      <View style={{ margin: 10}}>
        <ScrollView style={{height:250, marginTop:0}}>
          <View>
            {views}
          </View>
        </ScrollView>
        <View style={{ zIndex: 12, marginTop: 10}}>
          {this.renderClearApplyButton(id)}
        </View>
      </View>
    </View>
  }
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 9989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  }
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.ordersArray.length; i++) {
      let status = DeliveryOrderStatus.status(this.state.ordersArray[i]['status'])
      let amount = `${this.state.ordersArray[i]['currency']} ${this.state.ordersArray[i]['amount']}` 
      let driverName = ''
      if (this.state.ordersArray[i]['driver']){
        if (this.state.ordersArray[i]['driver'] != null){
          driverName = this.state.ordersArray[i]['driver']['name']
        }
      }
      views.push(
        <FlatList
          data={[
            this.state.ordersArray[i]['order_id'],
            this.state.ordersArray[i]['order_reference'],
            this.state.ordersArray[i]['branch']['name'],
            this.state.ordersArray[i]['customer']['name'],
            this.state.ordersArray[i]['customer']['mobile'],
            status,
            DeliveryOrderStatus.deliveryType(this.state.ordersArray[i]['delivery_type']),
            DeliveryOrderStatus.paymentType(this.state.ordersArray[i]['payment_type']),
            amount,
            dateConversionFromTimeStamp(this.state.ordersArray[i]['order_time']),
            dateConversionFromTimeStamp(this.state.ordersArray[i]['delivery_slot_from']),
            dateConversionFromTimeStamp(this.state.ordersArray[i]['delivery_slot_to']),
            driverName,
            this.state.ordersArray[i]['address']['formatted_address'],
            `${this.state.ordersArray[i]['distance']} KM`,
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          keyExtractor={(item, index) => index + 999989}
          key={'C'}
          style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white' }}
        />,
      )
    }
    return views;
  };
  columnCell = ({ item, index }) => {
     if (index == 0) {
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() => this.viewOrderBtnAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  gradientButtons = name => {
    if (this.state.ordersArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />
      } else if (name['id'] == 1 && this.state.ordersArray.length == 0 && pageNo > 1) {
        return <View />
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{ height: 30, width: 100, borderRadius: 5, marginRight: 30 }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{ color: 'white' }}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  };
  EmptyListRender = () => {
    if (this.state.ordersArray.length == 0) {
      if (this.state.haveData) {
        return <View style={{ height: '100%' }}>
          <EmptyListUI showImage={true} titleString={'No order found'} subtitleString={''} />
        </View>
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  }
  renderListView = () => {
    return <ScrollView horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>
  }
  renderKanbanBoard = () => {
    if (this.state.statusArray.length != 0) {
      return <View style={{ height: '100%' }}>
        <Board
          style={{ backgroundColor: 'white', height: '100%' }}
          laneStyle={{ height: windowHeight / 2 }}
          data={this.state.statusArray[0]}
          cardDraggable={true}
          onCardMoveAcrossLanes={this.onCardMoveAcrossLanes}
          onCardClick={this.onCardClick}
          hideCardDeleteIcon={true} />
      </View>
    } else {
      return <View />
    }
  }
  renderMainView = () => {
    if (this.state.showKanbanView) {
      return <View style={{ flex: 1, width: '100%', height: '100%' }}>
        <this.renderKanbanBoard />
      </ View>
    } else {
      return <this.renderListView />
    }
  }
  renderHeaderBtn = () => {
    return (<View style={{ flexDirection: 'row', marginRight: 10, zIndex: 100, marginTop: itsMobileView ? 10 : -5 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View />
        <View>
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this.setState({ showKanbanView: false })}>
            <Image resizeMode={'cover'} style={tableStyle.viewIconsStyle} source={!this.state.showKanbanView ? selectedListIcon : listIcon} />
            <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: !this.state.showKanbanView ? colors.AppNewGreen : colors.Appgray }]}>List View</Text>
          </TouchableOpacity>
          <View style={{ backgroundColor: !this.state.showKanbanView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
        </View>
        <View style={{ width: 10 }} />
      </View>
    </View>)
  }
  renderHeaderRightBtn = () => {
    return (<View style={{ flexDirection: 'row', marginLeft: 5, zIndex: 100, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={[tableStyle.configurationBarViewStyle]}>
        <View style={{ position: 'relative', flexDirection: 'row-reverse' }}>
          <View>
            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
              onPress={() => this.fiterBtnAction()}>
              <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: this.state.showFilterView ? colors.AppNewGreen : colors.Appgray }]}>Filter</Text>
            </TouchableOpacity>
            <View style={{ backgroundColor: this.state.showFilterView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
          </View>
          {this.renderFilterView()}
        </View>
      </View>
    </View>)
  }
  searchViewRender = () => {
    var sView = []
      sView.push(<View style={{width:itsMobileView ? '100%' : '100%'}}>
        <TextInput
          style={{width: '100%', marginLeft: 0, height: 25, paddingLeft:5, paddingRight:18,outline:'none'}}
          placeholder="Type order id...."
          placeholderTextColor={colors.placeholderColor}
          value={this.state.searchKey}
          autoFocus={this.state.showSearchView}
          onChangeText={text => this.onSearchTextChange(text)}
        />
        <TouchableOpacity style={[tableStyle.textBoxButton,{display: this.state.searchKey.length == 0 ? 'none' : 'flex'}]} 
        onPress={() => this.onCancelText()}>
          <Image style={{ width: 16, height: 16, marginRight:10}} source={closeIcon} />          
        </TouchableOpacity>
      </View>)
    return <View style={[tableStyle.searchBarViewStyle,{marginLeft:0}]}>
      {sView}
    </View>
  }
  renderHeaderView = () => {
    return (<View style={[tableStyle.orderHeaderViewstyle, { marginTop: 5 }]}>
      <View style={[tableStyle.rightTopViewStyle, { justifyContent: 'space-between', flex: 1, marginRight: 0 }]}>
        <View style={{ flexDirection: 'row' }}>
          {/* <this.searchViewRender /> */}
        </View>
        <View style={{ flexDirection: 'row', alignItems:'center' }}>
          <this.searchViewRender />
          <View style={{width:20,height:20}}/>
          <this.renderHeaderRightBtn />
          <View style={{width:20,height:20}}/>
          {this.renderExportAndDownloadBtn(1)}
        </View>
      </View>
    </View>)
  }
  
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
      </View>)
    } else {
      return <View />
    }
  }
  renderExportAndDownloadBtn = (id) => {
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors}
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() =>  id == 1 ? this.exportBtnAction() : this.downloadBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{id == 1 ? 'Export CSV' :'Download'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderReportView = () => {
    return <View style={[tableStyle.passwordViewStyle, { display: this.state.showDownloadView ? 'flex' : 'none' }]}>
      <View style={{ backgroundColor: colors.AppWhite, height: 200, width: 300, borderRadius: 10 }}>
        <View style={{flexDirection:'row',justifyContent:'space-between', margin: 16}}>
          <Text style={{textAlign:'center', marginLeft:90}}>Order Report</Text>
          <TouchableOpacity onPress={() => this.setState({showDownloadView:false})}>
            <Image source={closeIcon} style={{width:20, height:20}}/>
          </TouchableOpacity>
        </View>
        <View style={{ alignSelf: 'center', margin: 16, marginTop: 30, paddingLeft: 10 }}>
          {this.renderExportAndDownloadBtn(2)}
        </View>
        <View style={{alignItems:'center',marginTop: 20}}>
        <Text style={CommonStyleSheet.subTitleStyle}>Download link will expire after {`${this.state.linkValidTime}`} minutes</Text>
        </View>
      </View>
    </View>
  }
  renderSelectFilterView = () => {
    var views = []
    var ssAry = []
    if (this.state.appliedStartDate.length != 0 && this.state.appliedEndDate.length != 0) {
      let sd = Moment(this.state.appliedStartDate).format(dateFormat)
      let ed = Moment(this.state.appliedEndDate).format(dateFormat)
      ssAry.push({ 'title': `${sd} - ${ed}`, 'id': 1, 'color': colors.AppLightOrange,type:3 })
    }
    if (this.state.appliedBranch.length != 0) {
      for (let obj of this.state.appliedBranch){
      let indx = this.state.branchesArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.branchesArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:1})
      }
    }
    if (this.state.appliedDriver.length != 0) {
      for (let obj of this.state.appliedDriver){
      let indx = this.state.driverArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.driverArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:2})
      }
    }
    if (this.state.appliedFleets.length != 0) {
      for (let obj of this.state.appliedFleets){
      let indx = this.state.fleetsArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.fleetsArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:4})
      }
    }
    if (this.state.appliedDeliveryType.length != 0) {
      for (let obj of this.state.appliedDeliveryType){
      let indx = this.state.deliveryTypeArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.deliveryTypeArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:5})
      }
    }
    if (this.state.appliedPaymentType.length != 0) {
      for (let obj of this.state.appliedPaymentType){
      let indx = this.state.paymentTypeArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.paymentTypeArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:6})
      }
    }
    if (this.state.appliedStatusType.length != 0) {
      for (let obj of this.state.appliedStatusType){
      let indx = this.state.statusArray.findIndex(x => x['id'] == obj) 
      let brnch = this.state.statusArray[indx]
        ssAry.push({ 'title': brnch['name'], 'id':brnch['id']  ,'color': colors.lightYellow, type:7})
      }
    }
    for (let a = 0; a < ssAry.length; a++) {
      let obj = ssAry[a]
      views.push(<View style={{ backgroundColor: obj['color'], borderRadius: 8, padding: 5, flexDirection: 'row', marginRight:5 }}>
        <Text style={[CommonStyleSheet.filterTitleStyle,{paddingLeft: 2 }]}>{obj['title']}</Text>
        <TouchableOpacity onPress={() => this.removeFilterValueBtnAcion(obj)}>
          <Image style={{ width: 14, height: 14, marginLeft: 10 }} source={closeIcon} />
        </TouchableOpacity>
      </View>)
    }
    return <View style={{ width: '97%', marginLeft: 10, flexDirection:'row'}}>
      {views}
    </View>
  }
  render() {
    if (this.state.showOrderDetail) {
      return (<OrderDetail backBtnAction={() => this.backBtnHandler()} orderID={this.state.orderID} />)
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          {this.renderReportView()}
          <this.renderHeaderView />
          <this.renderSelectFilterView/>
          <View style={tableStyle.containerMainView}>
            <ToastContainer />
            {this.renderMainView()}
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <Appload enable={this.state.dataLoad} titleMessage={this.state.loaderTitle}/>
        </SafeAreaView>
      );
    }
  }
}
const commonWidth = 8.8;
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  filterSubItemViewStyle:{
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    paddingBottom: 5,
    alignItems:'center' 
  },

});

const tableHeaderString = [
  'Order#',
  'Reference',
  'Branch',
  'Customer Name',
  'Customer Mobile',
  'Status',
  'Delivery Type',
  'Payment Type',
  'Amount',
  'Ordered. At',
  'Delivery Slot Start',
  'Delivery Slot End',
  'Driver',
  'Delivered Address',
  'Distance'
]

const OrderBGColor = [,
  '#e7e5e4',
  '#fecaca',
  '#e9d5ff',
  '#fde68a',
  '#ddd6fe',
  '#99f6e4',
  '#fef08a',
  '#d9f99d',
  '#f5d0fe',
  '#a5f3fc',
  '#bae6fd',
  '#bfdbfe',
  '#c7d2fe',
  '#fbcfe8',
  '#fecdd3',
  '#bbf7d0',
  '#a7f3d0',
  '#fed7aa',
]
const FilterStaticArray = [
  'Branch',
  'Driver',
  'Delivery Date',
  'Fleet',
  'Delivery Type',
  'Payment Type',
  'Status',
  'Clear Filter',
]
