import React, {Component} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  FlatList,
  Image,
  Dimensions,
  ScrollView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import {AppToast} from '../../HelperClasses/AppToast';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import Switch from 'react-switch';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import HeaderView from '../HeaderView';
import AppConstants from '../../Constants/AppConstants';
import {Gradient} from 'react-gradient';
import SaveButton from '../../Component/SaveButton';

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'payments';
let AccountTypesArray = ['Standard', 'Express'];
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class RazorpaySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      showDropDown: false,
      hideShowPublickey: false,
      hideShowSecretkey: false,
      razorpay_key_id: '',
      razorpay_key_secret: '',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=payments',
      'get',
      '',
      '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        razorpay_key_id: configs['razorpay_key_id'] || '',
        razorpay_key_secret: configs['razorpay_key_secret'] || '',
      });
    }
    this.setState({dataLoad: true});
  };

  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    var valueArray = [
      this.state.razorpay_key_id,
      this.state.razorpay_key_secret,
    ];

    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      let scValue = false;
      if (i == 2 || i == 1 || i == 8) {
        scValue = true;
      }
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: scValue,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
      // this.alertView('Uploaded successfully');
    } else {
      this.deleteKeysAPI(configs);
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(urlParm, 'delete');
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
    this.props.closeSetting();
  }
  //MARK:-  Buttons Action Method 
  submitBtnAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  hideShowEyeBtnAction(id) {
    if (id == 1) {
      this.setState({hideShowPublickey: !this.state.hideShowPublickey});
    } else {
      this.setState({hideShowSecretkey: !this.state.hideShowSecretkey});
    }
  }
  openDropDown = id => {
    this.setState({showDropDown: !this.state.showDropDown});
  };
  didSelectDropDown = item => {
    // console.log('index', item);
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedAccountType: item,
    });
  };
  toolTipBtnAction() {
    window.open(AppConstants.stripeYoutubeLink, '_blank');
  }
  //MARK:-  UIs 
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={styles.showDropDownViewStyle}>
          <FlatList
            data={AccountTypesArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Text
          style={
            this.state.selectedAccountType == ''
              ? [
                  commonStyle.accountTypeplaceholderTxtStyle,
                  {outline: 0, width: '100%'},
                ]
              : [
                  commonStyle.accountTypetxtFieldStyle,
                  {outline: 0, width: '100%'},
                ]
          }>
          {this.state.selectedAccountType == ''
            ? 'Select account type'
            : this.state.selectedAccountType}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? styles.upArraowIconStyle
              : styles.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };

  accountTypesTextFields = () => {
    if (this.state.selectedAccountType == AccountTypesArray[0]) {
      return (
        <View>
          <View style={[commonStyle.subContainerViewStyle]}>
            <Text style={commonStyle.titleNameStyle}>
              Connect message standard
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter connect message standard"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name => this.setState({standardConnectMsg: name})}
              value={this.state.standardConnectMsg}
            />
          </View>
          <View style={{height: 20}} />
          <View style={commonStyle.subContainerViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              Disonnect message standard
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter disonnect message standard"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name =>
                this.setState({standardDisConnectMsg: name})
              }
              value={this.state.standardDisConnectMsg}
            />
          </View>
        </View>
      );
    } else if (this.state.selectedAccountType == AccountTypesArray[1]) {
      return (
        <View>
          <View style={commonStyle.subContainerViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              Connect message express
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter connect message express"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name => this.setState({expressConnectMsg: name})}
              value={this.state.expressConnectMsg}
            />
          </View>
          <View style={{height: 20}} />
          <View style={commonStyle.subContainerViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              Disonnect message express
            </Text>
            <TextInput
              style={[
                commonStyle.txtFieldStyle,
                {outline: 0, marginLeft: 0, width: '100%'},
              ]}
              placeholder="Enter disonnect message express"
              placeholderTextColor={colors.placeholderColor}
              onChangeText={name => this.setState({expressDisConnectMsg: name})}
              value={this.state.expressDisConnectMsg}
            />
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSaveConfigBtn = () => {
    return (
      <View>
        <SaveButton
          saveBtn={() => this.submitBtnAction()}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{height: windowHeight / 1.18}}>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={{height: 20}} />

            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}> Key ID</Text>
              <View
                style={[
                  commonStyle.customTxtFieldViewStyle,
                  {marginTop: 0, width: '100%'},
                ]}>
                <TextInput
                  style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                  placeholder="Enter key ID"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({razorpay_key_id: name})}
                  value={this.state.razorpay_key_id}
                  secureTextEntry={!this.state.hideShowPublickey}
                />
                <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(1)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowPublickey ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Key Secret</Text>
              <View
                style={[
                  commonStyle.customTxtFieldViewStyle,
                  {marginTop: 0, width: '100%'},
                ]}>
                <TextInput
                  style={[commonStyle.customTxtFieldStyle, {outline: 0}]}
                  placeholder="Enter secret key"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name =>
                    this.setState({razorpay_key_secret: name})
                  }
                  value={this.state.razorpay_key_secret}
                  secureTextEntry={!this.state.hideShowSecretkey}
                />
                <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(2)}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowSecretkey ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={{height: 40}} />
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
  showDropDownViewStyle: {
    backgroundColor: colors.AppWhite,
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: '100%',
    marginTop: 38,
    marginLeft: 0,
    zIndex: 9000,
  },
  upArraowIconStyle: {
    width: 15,
    height: 15,
    marginRight: 20,
    alignSelf: 'center',
    transform: [{rotate: '180deg'}],
  },
  downArraowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
    marginRight: 20,
  },
  hideShowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
  },
});

let keysArray = ['razorpay_key_id', 'razorpay_key_secret'];
