import networkService from '../../Network/NetworkManager';
import {all_categories_map} from '../../utils/AllCategoriesMap';
const APPURL = require('../../Constants/URLConstants');

export const loadAccountCategoriesApi = async ({
  current_state_values,
  update_state_values,
}) => {
  var path = APPURL.URLPaths.categories + 'accounts';

  const responseJson = await networkService.networkCall(path, 'get');
  if (responseJson.status == true) {
    let category_response = all_categories_map(
      responseJson.data.categories,
      true,
    );

    update_state_values(category_response);
  }
};

export const loadListingsCategoriesApi = async ({
  current_state_values,
  update_state_values,
}) => {
  var path = APPURL.URLPaths.categories + 'listings';

  const responseJson = await networkService.networkCall(path, 'get');
  if (responseJson.status == true) {
    let category_response = all_categories_map(
      responseJson.data.categories,
      true,
    );

    update_state_values(category_response);
  }
};
