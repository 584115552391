import Cookies from 'universal-cookie';
import AppConstants from './AppConstants';
var CryptoJS = require('crypto-js');
const cookies = new Cookies();

// Get Selected Tenant
export const getSelectedTenantDetails = tenants => {
  let evn = AppConstants.isDebug ? 'd' : 'p';
  let ckTenant = cookies.get(`ck_${evn}_t`);

  if (ckTenant != undefined) {
    const decrypted = CryptoJS.AES.decrypt(
      ckTenant.toString(),
      AppConstants.ckPrivateKey,
    );
    var selected_tenant_id = decrypted.toString(CryptoJS.enc.Utf8);

    return tenants?.filter(tenant => tenant.id == selected_tenant_id);
  } else {
    return tenants?.filter(tenant => tenant.default);
  }
};
