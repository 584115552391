import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  FlatList,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import commonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SelectSearchView from 'react-select';

import Switch from 'react-switch';
import CustomAlertView from '../../Component/CustomAlertView';
import {ToastContainer} from 'react-toastify';
import AddFleet from '../Fleets/AddFleet';
import {addMixpanelEvent} from '../../Models/MixPannelEnum';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let regexp = /^[0-9\.]+$/;

export default class AddBranch extends Component {
  constructor(props) {
    super(props);
    const isEdit = this.props.isEdit;
    this.state = {
      activeStatus: true,
      dataLoad: true,
      name: '',
      branchId: '',
      lat: '',
      long: '',
      timeZoneArray: [],
      selectedTimeZone: [],
      id: '',
      showNextViewBool: false,
      addFleetBool: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    branchData: PropTypes.any,
    isEdit: PropTypes.bool,
    closeBtnAction: PropTypes.func,
  };

  componentDidMount() {
    this.loadTimezones();
    const branchD = this.props.branchData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (this.state.name.length == 0) {
        this.state.selectedTimeZone = {
          value: branchD['time_zone'],
          label: branchD['time_zone'],
        };
        let coordinates = branchD['coordinates'];
        this.setState({
          name: branchD['name'],
          branchId: branchD['id'],
          id: branchD['id'],
          activeStatus: branchD['active'],
          lat: coordinates['latitude'] || '',
          long: coordinates['longitude'] || '',
        });
      }
    }
  }
  loadTimezones() {
    var a = 0;
    for (let item of staticTimeZone) {
      let catData = {
        value: item,
        label: item,
      };
      a++;
      this.state.timeZoneArray.push(catData);
    }
    this.state.selectedTimeZone = this.state.timeZoneArray[0];
    this.setState({updateUI: !this.state.updateUI});
  }
  addBranchApi = async () => {
    this.setState({dataLoad: false});
    var dict = {
      name: this.state.name,
      id: this.state.branchId,
      active: this.state.activeStatus,
    };
    if (this.state.selectedTimeZone['label']) {
      dict['time_zone'] = this.state.selectedTimeZone['label'];
    }
    let coordinates = {
      latitude: this.state.lat,
      longitude: this.state.long,
    };
    dict['coordinates'] = coordinates;
    var path = APPURL.URLPaths.deliveryBranches;

    if (this.state.id !== '') {
      path = APPURL.URLPaths.deliveryBranches + this.state.id;
    }
    let requestMethod = this.state.id !== '' ? 'PATCH' : 'post';
    const responseJson = await networkService.networkCall(
      path,
      requestMethod,
      JSON.stringify({branch: dict}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      AppToast();
      if (this.state.id !== '') {
        this.props.backBtnAction(true);
      } else {
        this.setState({showNextViewBool: true});
      }
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(
      `${this.state.id !== '' ? 'Edit' : 'Add'} Branch`,
      properties,
    );
  }

  //MARK:-  Button Actions
  submitButtonAction = () => {
    if (this.state.branchId.length == 0) {
      AppToast('Please enter branch id');
    } else if (this.state.name.length == 0) {
      AppToast('Please enter name');
    } else if (this.state.lat.length == 0) {
      AppToast('Please enter lat');
    } else if (this.state.long.length == 0) {
      AppToast('Please enter long');
    } else {
      this.addBranchApi();
    }
  };
  handleSelectChange = selectedOption => {
    this.state.selectedTimeZone = selectedOption;
    this.setState({updateUI: !this.state.updateUI});
  };
  onHandleTextChange(text, id) {
    let value = text;
    if (id == 1) {
      if (text == '' || regexp.test(value)) {
        this.setState({lat: text});
      }
    } else {
      if (text == '' || regexp.test(value)) {
        this.setState({long: text});
      }
    }
  }
  addFleetBtnAction() {
    this.state.branchId = '';
    this.state.name = '';
    this.state.lat = '';
    this.state.long = '';
    this.setState({
      addFleetBool: !this.state.addFleetBool,
      showNextViewBool: false,
    });
  }
  // UI Renders
  renderTimeZoneDropDownView = id => {
    return (
      <View style={styles.dropDownViewStyle}>
        <View style={{width: '100%', marginLeft: -10}}>
          <SelectSearchView
            value={this.state.selectedTimeZone}
            onMenuOpen={() => console.log('open drop')}
            onChange={itm => this.handleSelectChange(itm)}
            options={this.state.timeZoneArray}
            isMulti={false}
            placeholder={`Select Time Zone`}
            styles={colourStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: colors.AppGreenBorder,
                primary: colors.AppGreenBorder,
              },
            })}
          />
        </View>
      </View>
    );
  };

  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderAlertView = () => {
    return (
      <CustomAlertView
        showNextViewBool={this.state.showNextViewBool}
        titleValue={'Nice 🎉, Successfully added branch'}
        nextBtnTitle={'Add Fleet'}
        closeBtnAction={() => this.props.closeBtnAction(true)}
        nextBtnAction={() => this.addFleetBtnAction()}
      />
    );
  };
  render() {
    let isEdit = this.props.isEdit;
    if (this.state.addFleetBool) {
      return (
        <View>
          <ToastContainer />
          <AddFleet
            backBtnAction={() => this.addFleetBtnAction()}
            closeBtnAction={() => this.props.closeBtnAction(true)}
          />
        </View>
      );
    }
    return (
      <SafeAreaView style={styles.Container}>
        {this.renderAlertView()}
        <Header_View
          title={isEdit ? 'Edit Branch' : 'Add Branch'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}
        />
        <ScrollView>
          <View style={styles.mainView}>
            <View style={{height: 15, width: '100%'}} />
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Branch ID'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.branchId}
                placeholder="Enter branch id (Example: ABC123)"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.setState({branchId: text})}
              />
            </View>
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Name'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.name}
                placeholder="Enter branch/store name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({name: name})}
              />
            </View>
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Coordinates'} />
              <View style={{flexDirection: 'row', width: '80%'}}>
                <TextInput
                  style={[
                    appConfigStyle.textFieldAddCurrencyStyle,
                    {width: 140, marginLeft: -5, outline: 0},
                  ]}
                  value={this.state.lat}
                  placeholder="Lat"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={text => this.onHandleTextChange(text, 1)}
                />
                <TextInput
                  style={[
                    appConfigStyle.textFieldAddCurrencyStyle,
                    {width: 140, outline: 0},
                  ]}
                  value={this.state.long}
                  placeholder="Long"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={text => this.onHandleTextChange(text, 2)}
                />
              </View>
            </View>
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Time Zone'} />
              <View
                style={{
                  width: itsMobileView ? '100%' : '80%',
                  marginTop: itsMobileView ? 10 : 0,
                }}>
                {this.renderTimeZoneDropDownView()}
              </View>
            </View>
            <View style={{height: 20}} />
            <View style={[commonStyleSheet.switchViewStyle, {zIndex: 10}]}>
              <Text style={commonStyleSheet.titleNameStyle}>Status</Text>
              <View
                style={{
                  width: itsMobileView ? '12%' : '80%',
                  flexDirection: 'row',
                }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() =>
                    this.setState({activeStatus: !this.state.activeStatus})
                  }
                  checked={this.state.activeStatus}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{height: 100}} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    height: windowHeight,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    flex: 1,
    zIndex: 1,
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 120,
  },
  dropDownViewStyle: {
    marginTop: 5,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
});
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};

const staticTimeZone = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'America/Antigua',
  'America/Anguilla',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Africa/Luanda',
  'Antarctica/McMurdo',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville',
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'America/Aruba',
  'Europe/Mariehamn',
  'Asia/Baku',
  'Europe/Sarajevo',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Africa/Ouagadougou',
  'Europe/Sofia',
  'Asia/Bahrain',
  'Africa/Bujumbura',
  'Africa/Porto-Novo',
  'America/St_Barthelemy',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Kralendijk',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Africa/Gaborone',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Whitehorse',
  'America/Dawson',
  'America/Vancouver',
  'Indian/Cocos',
  'Africa/Kinshasa',
  'Africa/Lubumbashi',
  'Africa/Bangui',
  'Africa/Brazzaville',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Africa/Douala',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Busingen',
  'Africa/Djibouti',
  'Europe/Copenhagen',
  'America/Dominica',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Africa/Asmara',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Africa/Addis_Ababa',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Africa/Libreville',
  'Europe/London',
  'America/Grenada',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Europe/Guernsey',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Nuuk',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Africa/Banjul',
  'Africa/Conakry',
  'America/Guadeloupe',
  'Africa/Malabo',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'Europe/Zagreb',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Europe/Isle_of_Man',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'Europe/Jersey',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Asia/Phnom_Penh',
  'Pacific/Tarawa',
  'Pacific/Kanton',
  'Pacific/Kiritimati',
  'Indian/Comoro',
  'America/St_Kitts',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Kuwait',
  'America/Cayman',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay',
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Vientiane',
  'Asia/Beirut',
  'America/St_Lucia',
  'Europe/Vaduz',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Africa/Maseru',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Europe/Podgorica',
  'America/Marigot',
  'Indian/Antananarivo',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Europe/Skopje',
  'Africa/Bamako',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'Pacific/Saipan',
  'America/Martinique',
  'Africa/Nouakchott',
  'America/Montserrat',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'Africa/Blantyre',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Africa/Niamey',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Asia/Muscat',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Volgograd',
  'Europe/Astrakhan',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Africa/Kigali',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'Atlantic/St_Helena',
  'Europe/Ljubljana',
  'Arctic/Longyearbyen',
  'Europe/Bratislava',
  'Africa/Freetown',
  'Europe/San_Marino',
  'Africa/Dakar',
  'Africa/Mogadishu',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'America/Lower_Princes',
  'Asia/Damascus',
  'Africa/Mbabane',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Africa/Lome',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Africa/Dar_es_Salaam',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Africa/Kampala',
  'Pacific/Midway',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'Europe/Vatican',
  'America/St_Vincent',
  'America/Caracas',
  'America/Tortola',
  'America/St_Thomas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Asia/Aden',
  'Indian/Mayotte',
  'Africa/Johannesburg',
  'Africa/Lusaka',
  'Africa/Harare',
];
