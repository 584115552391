import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../HeaderView';
import { AppToast } from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import { onHandleNumberFieldChange } from '../../HelperClasses/SingletonClass';
import Switch from "react-switch";
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import { Gradient } from 'react-gradient';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import SelectSearchView from 'react-select';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';


const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth ? true : false

export default class AddCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      status: true,
      default: true,
      name: '',
      dataLoad: false,
      id: '',
      updatingBool: false,
      rank: '',
      countryArray: [],
      selectedCountry: '',
      selectedCountryID: '',
      countryID: '',
      selectedCountryArray:[],
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    countryData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    const catData = this.props.countryData
    const isEdit = this.props.isEdit
    if (isEdit) {
      if (!this.state.updatingBool) {
        this.setState({
          name: catData['name'],
          default: catData['default'],
          status: catData['active'],
          id: catData['id'],
          rank: catData['order_by'],
          countryID: catData['country_id'],
          updatingBool: true,
        });
      }
    }
    this.loadCountriesApi()
  }
  loadCountriesApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.countryURl, 'get')
    this.setState({ countryArray: [] });
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['countries']
      for (let obj of objectD) {
        var catData = obj
        catData['label'] = obj['name']
        catData['value'] = obj['id']
        this.state.countryArray.push(catData)
      }
      if (this.state.countryArray.length != 0) {
        if (this.state.id !== '') {
          let index = this.state.countryArray.findIndex(x => x['id'] == this.state.countryID)
          if (index != -1) {
            let item = this.state.countryArray[index]
            this.state.selectedCountryArray = item
            this.setState({ selectedCountry: item['name'], selectedCountryID: item['id'] })
          }
        } else {
          let item = this.state.countryArray[0]
          this.state.selectedCountryArray = item
          this.setState({ selectedCountry: item['name'], selectedCountryID: item['id'] })
        }
      }
    }
    this.setState({ dataLoad: true });
  };
  addAppCountryApi = async image => {
    var dict = {
      country_id: this.state.selectedCountryID,
      active: this.state.status,
      default: this.state.default,
    };
    if (this.state.rank != 0) {
      dict['order_by'] = this.state.rank;
    }
    var path = APPURL.URLPaths.appCountry;

    if (this.state.id !== '') {
      path = APPURL.URLPaths.appCountry + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({ country: dict }),
      '',
    );
    // console.log('category', responseJson);
    this.setState({ dataLoad: true });
    if (responseJson['status'] == true) {
      this.props.backBtnAction(true);
      this.customAlert();
    } else {
      // this.alertView(responseJson);
    }
  };
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({ dataLoad: false });
    this.addAppCountryApi();
  };

  handleSelectChange = (item) => {
    this.state.selectedCountryArray = item
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedCountry: item['name'],
      selectedCountryID: item['id'],
    })
    this.setState({updateUI: !this.state.updateUI})
  }

  // UI Renders
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={appConfigStyle.showDropDownViewStyle}>
          <FlatList
            data={this.state.countryArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{ margin: 5, height: 30, marginBottom: 5 }}>
        <View style={{ width: '100%' }}>
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text style={this.state.selectedCountry == '' ? appConfigStyle.placeholderTxtStyle : appConfigStyle.txtFieldStyleDropDown}>
          {this.state.selectedCountry == '' ? 'Select country' : this.state.selectedCountry}
        </Text>
        <Image
          style={this.state.showDropDown == true ? appConfigStyle.upArraowIconStyle : appConfigStyle.downArraowIconStyle}
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };

  titleLblRender = ({ title }) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{ color: 'red', paddingTop: 5 }}> *</Text>
        </Text>
      </View>
    )
  }
  renderSaveConfigBtn = () => {
    return <View>
      <SaveButton saveBtn={() => this.submitBtnAction()} saveBtnTitle={'Save'} />
    </View>
  }
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView>
          <View style={commonStyle.sidepanmViewStyle}>
            <View style={{ height: 10 }} />
            {/* <View style={[commonStyle.configContainerViewStyle, { zIndex: 10010 }]}>
              <Text style={commonStyle.titleNameStyle}>Country Name</Text>
              <TouchableOpacity
                style={[commonStyle.dropDownViewStyle, { width: '100%' }]}
                onPress={() => this.setState({ showDropDown: !this.state.showDropDown })}>
                <this.renderValueLabel id={0} />
                <this.customDropDown id={0} />
              </TouchableOpacity>
            </View> */}
            <View style={[commonStyle.configContainerViewStyle, { zIndex: 10010 }]}>
                <this.titleLblRender title={'Country Name'} />
                <View style={{ width: '100%' }}>
                  <SelectSearchView
                    value={this.state.selectedCountryArray}
                    onChange={itm => this.handleSelectChange(itm)}
                    options={this.state.countryArray}
                    isMulti={false}
                    placeholder={`Select Language`}
                    styles={colourStyles}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: colors.AppGreenBorder,
                        primary: colors.AppGreenBorder,
                      },
                    })}
                  />
                </View>
              </View>
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Order</Text>
              <TextInput
                style={[commonStyle.txtFieldStyle, { outline: 0, marginLeft: 0, width: '100%' }]}
                value={this.state.rank}
                placeholder="Enter order"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={txt => this.setState({ rank: onHandleNumberFieldChange(txt) })}
              />
            </View>
            <View style={{ height: 20 }} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Status(Not Active / Active)</Text>
              <View style={{ width: itsMobileView ? '12%' : '14%' }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({ status: !this.state.status })}
                  checked={this.state.status}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{ height: 20 }} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Default</Text>
              <View style={{ width: itsMobileView ? '12%' : '14%' }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({ default: !this.state.default })}
                  checked={this.state.default}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{ height: 20 }} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
  },
  subViewStyle: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    zIndex: 19292,
  },
})
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
}

