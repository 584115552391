import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Text,
  FlatList,
  TextInput,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import AppConstants from '../../Constants/AppConstants';
import searchIcon from '../../assets/search.png';
import close_Icon from '../../assets/closeIcon.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';
import deleteIcon from '../../assets/deleteGrayIcon.png';
import {
  loadAccountsApi,
  loadListingsApi,
} from '../ApisFunctions/LoadListings&Accounts';
import {collections_styles} from './CollectionStyles';

// Global scope variables
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const SearchView = ({
  collection_state = {},
  setCollectionState,
  showSideView,
  setShowSideView,
  selectedSearchArray,
  setSelectedSearchArray,
  isDataLoaded,
  setIsDataLoaded,
}) => {
  // destructure state
  const {
    expirable,
    medium,
    images,
    scope_type,
    condition_type,
    manual_ids,
    start_at,
    end_at,
    active,
    view_type,
    background_color,
    background_image,
    conditions,
    order_by,
    title,
    description,
    slug,
  } = collection_state;

  //
  const [searchKey, setSearchKey] = useState('');
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  const [listingsAndAccountsArray, setListingsAndAccountsArray] = useState([]);

  // handleChangeValue
  const handleChangeValue = ({value, key_name}) => {
    setCollectionState(prev => ({...prev, [key_name]: value}));
  };

  // Api Call effect base on search key
  useEffect(() => {
    if (searchKey !== '' && scope_type === 4) {
      setIsDataLoaded(false);
      loadListingsApi({
        key: searchKey,
        current_state_values: listingsAndAccountsArray,
        update_state_values: values => {
          setListingsAndAccountsArray(values);
          setShowSearchDropDown(true);
          setIsDataLoaded(true);
        },
      });
    } else if (searchKey !== '' && scope_type === 1) {
      setIsDataLoaded(false);
      loadAccountsApi({
        key: searchKey,
        current_state_values: listingsAndAccountsArray,
        update_state_values: values => {
          setListingsAndAccountsArray(values);
          setShowSearchDropDown(true);
          setIsDataLoaded(true);
        },
      });
    }
  }, [searchKey]);

  // renderSearchDropDown (for listings and accounts)
  const renderSearchDropDown = ({item, index}) => {
    let textcolor = manual_ids?.includes(item.id)
      ? colors.AppRed
      : colors.AppTitleBlack;

    return (
      <TouchableOpacity
        onPress={() => {
          if (!manual_ids.includes(item.id)) {
            setSelectedSearchArray(prev =>
              prev?.length > 0 ? [...prev, item] : [item],
            );
            handleChangeValue({
              value:
                manual_ids?.length > 0 ? [...manual_ids, item.id] : [item.id],
              key_name: 'manual_ids',
            });
          }
        }}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[CommonStyleSheet.plainSubTextStyle, {color: textcolor}]}>
            {item?.title || item?.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  // SelectedSearchCellItem
  const SelectedSearchCellItem = ({item, index}) => {
    let descp = scope_type == 4 ? item.list_price.formatted : item.description;
    return (
      <View style={collections_styles.cellItemStyle}>
        <TouchableOpacity
          style={{flexDirection: 'row', flex: 1}}
          //   onPress={() => this.didSelectAccountListing(item.id)}
        >
          <Image
            style={TableCommonStyleSheet.itemImageViewStyle}
            source={item.images[0] || ''}
          />
          <View style={{justifyContent: 'center', marginLeft: 10, flex: 1}}>
            <Text
              style={[
                CommonStyleSheet.plainTextStyle,
                {color: colors.AppNewGreen},
              ]}>
              {item.title || item.name}
            </Text>
            <View style={{height: 5}} />
            <Text
              style={TableCommonStyleSheet.gridPriceViewStyle}
              numberOfLines={1}>
              {descp}
            </Text>
          </View>
        </TouchableOpacity>
        <View style={{alignItems: 'center', marginTop: 5}}>
          <TouchableOpacity
            style={TableCommonStyleSheet.deleteViewStyle}
            onPress={() => {
              setSelectedSearchArray(
                selectedSearchArray.filter(el => el.id !== item.id),
              );

              setCollectionState(prev => ({
                ...prev,
                manual_ids: prev.manual_ids.filter(el => el != item.id),
              }));
            }}>
            <Image
              style={TableCommonStyleSheet.iconStyle}
              source={deleteIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View>
      <View style={{marginTop: 20, width: '90%'}}>
        <View style={{zIndex: 10}}>
          <View style={collections_styles.searchBarViewStyle}>
            <Image
              style={{width: 16, height: 16, marginLeft: 16}}
              source={searchIcon}
            />
            <TextInput
              style={{width: '100%', margin: 10, height: 30, outline: 0}}
              placeholder="Search Key"
              value={searchKey}
              placeholderTextColor={colors.placeholderColor}
              onChangeText={text => setSearchKey(text)}
            />
            <TouchableOpacity
              style={{
                display: searchKey.length != 0 ? 'flex' : 'none',
              }}
              onPress={() => {
                setSearchKey('');
                setShowSearchDropDown(false);
              }}>
              <Image
                style={{width: 16, height: 16, marginRight: 16}}
                source={close_Icon}
              />
            </TouchableOpacity>
            {showSearchDropDown && (
              <View style={collections_styles.searchDropDownViewStyle}>
                {listingsAndAccountsArray?.length > 0 ? (
                  <FlatList
                    data={listingsAndAccountsArray}
                    horizontal={false}
                    renderItem={renderSearchDropDown}
                    extraData={collection_state}
                    showsVerticalScrollIndicator={true}
                    scrollEnabled={true}
                  />
                ) : (
                  <Text
                    style={[
                      CommonStyleSheet.plainTextStyle,
                      {
                        color: colors.AppNewGreen,
                        textAlign: 'center',
                        paddingTop: 50,
                      },
                    ]}>
                    Not found
                  </Text>
                )}
              </View>
            )}
          </View>
        </View>
        <View style={{zIndex: 1, height: windowHeight / 1.3}}>
          {selectedSearchArray?.length > 0 && (
            <View style={{flex: 1}}>
              <FlatList
                data={selectedSearchArray}
                horizontal={false}
                renderItem={SelectedSearchCellItem}
                extraData={selectedSearchArray}
                showsVerticalScrollIndicator={true}
                scrollEnabled={true}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default SearchView;
