import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
  TextInput,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../../assets/editIcon.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../../Component/AppEmptyList';
import { ToastContainer } from 'react-toastify';
import { Gradient } from 'react-gradient';
import AddDriver from './AddDriver';
import { AppToast } from '../../HelperClasses/AppToast';
import selectedListIcon from '../../assets/selectedListIcon.png';
import AppConstants from '../../Constants/AppConstants';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import DriverDetail from './DriverDetail';
import closeIcon from '../../assets/closeIcon.png';
import { addMixpanelEvent, MixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false
let perPage = 50
const Unassigned = 'Unassigned'

export default class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driversArray: [],
      dataLoad: false,
      selectedDriverIndex: 0,
      isEdit: false,
      addDriverBool: false,
      stopPage: false,
      haveData: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      totalRecords: 0,
      showDriverDetailBool:false,
      searchKey: '',
      typingTimeout: 0,
      showSearchView:false,
    }
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo()
    this.initApi()
    this.checkModule()
  }
  mixPannelInfo(){
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.driver}`,{'page':pageNo})
  }
  initApi(){
    pageNo = 1
    this.loadDriverApi()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.PROMOS, res => {
      this.setState({ writeP: res[1], deleteP: res[2] })
    })
  }
  //MARK:-  APIS Method 
  loadDriverApi = async (type) => {
    this.setState({ dataLoad: false });
    var path = `?page=${pageNo}&per_page=${perPage}&type=driver`
    if (this.state.searchKey.length != 0){
      path = path + `&search_key=${this.state.searchKey}`
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriver + path, 'get')
    this.setState({ driversArray: [] });
    // console.log('responseJson', responseJson)
    if (responseJson['status'] == true) {
      var driverD = responseJson['data']['users']
      this.state.totalRecords = responseJson['data']['total_records'];
      if (driverD.length != 0) {
        this.setState({ stopPage: false });
        for (let objc of driverD) {
          this.state.driversArray.push(objc);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1 
        this.setState({ stopPage: true });
      }
      this.state.haveData = this.state.driversArray.length == 0 ? true : false
    } else {
      AppToast(responseJson)
    }
    this.setState({ dataLoad: true });
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({ addDriverBool: false })
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadDriverApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadDriverApi();
      }
    }
    this.mixPannelInfo()
    this.setState({ updateUI: !this.state.updateUI });
  }
  addDriverBtnAction() {
    this.setState({ addDriverBool: !this.state.addDriverBool });
    this.setState({ isEdit: false });
    this.loadDriverApi();
  }
  driverDetailbackBtnAction() {
    this.setState({ showDriverDetailBool: false});
    this.loadDriverApi();
  }
  editButtonAction = index => {
    // console.log('index', index);
    let id_Index = this.state.driversArray.findIndex(x => x.id === index);
    this.setState({ isEdit: true, selectedDriverIndex: id_Index });
    this.setState({ addDriverBool: true });
  };
  ViewDriverDetailBtnAction(id){
    let id_Index = this.state.driversArray.findIndex(x => x.id === id)
    this.setState({showDriverDetailBool:true,selectedDriverIndex:id_Index })
  }
  onSearchTextChange = text => {
    this.setState({ searchKey: text })
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(function () {
        this.initApi()
      }.bind(this), 1000)
    })
  }
  onCancelText() {
    if (this.state.searchKey.length != 0) {
      this.state.searchKey = ''
      this.initApi()
    }
    this.setState({showSearchView: false})
  }
  /*  UI   */

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{ margin: 0 }}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}

      />
    );
  };
  tableHeaderCell = ({ item, index }) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    )
  }
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.driversArray.length; i++) {
      var fleet =  Unassigned
      var branch = Unassigned
      if (this.state.driversArray[i]['fleet']) {
         fleet = `${this.state.driversArray[i]['fleet']['fleet_type']} (${this.state.driversArray[i]['fleet']['plate_number']})`
      }
      if (this.state.driversArray[i]['branch']) {
        branch = this.state.driversArray[i]['branch']['name']
     }
      views.push(
        <FlatList
          data={[
            this.state.driversArray[i]['id'],
            this.state.driversArray[i]['mobile'],
            this.state.driversArray[i]['email'],
            branch,
            fleet,
            this.state.driversArray[i]['id'],
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{ borderBottomWidth: 1, borderBottomColor: colors.SimonGray, backgroundColor: 'white'}}
          keyExtractor={(index) => index + 9989}
          key={'H'}
        />,
      )
    }
    return views
  }
  columnCell = ({ item, index }) => {
   if (index == 5) {
      return (
        <View style={styles.columnTitleViewStyle}>
          {this.renderEditDeleteView(item)}
        </View>
      );
    } else if (index == 0) {
      let id_Index = this.state.driversArray.findIndex(x => x.id === item);
      let name = this.state.driversArray[id_Index]['name']
      return (
        <TouchableOpacity style={styles.columnTitleViewStyle} onPress={() => this.ViewDriverDetailBtnAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{name}</Text>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={[tableStyle.columnViewTitleStyle,{color:item == Unassigned ?colors.AppRed : colors.blackColor}]}>
            {item}
          </Text>
        </View>
      );
    }
  }
  gradientButtons = name => {
    if (this.state.driversArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />
      } else if (name['id'] == 1 && this.state.driversArray.length == 0 && pageNo > 1) {
        return <View />
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{ height: 30, width: 100, borderRadius: 10, marginRight: 30 }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{ color: 'white' }}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />
    }
  }
  renderEditDeleteView = (item) => {
    return <View>
      <TouchableOpacity onPress={() => this.editButtonAction(item)}>
        <Image style={tableStyle.iconStyle} resizeMode="center" source={edit_Icon} />
      </TouchableOpacity>
    </View>
  }
  renderAddDriverBtn = () => {
    return <View style={tableStyle.gradientViewBGStyle}>
      <Gradient
        gradients={colors.GradientColors} // required
        property="background"
        gradientType={'linear'}
        duration={2333000}
        angle="0deg">
        <TouchableOpacity onPress={() => this.addDriverBtnAction()}>
          <Text style={tableStyle.saveTxtStyle}>{'Add Driver'}</Text>
        </TouchableOpacity>
      </Gradient>
    </View>
  }
  renderHeaderBtn = () => {
    return (<View style={{ flexDirection: 'row', zIndex: 100, marginTop: itsMobileView ? 10 : 0 }}>
      <View style={tableStyle.configurationBarViewStyle}>
        <View style={{ width: 10 }} />
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image style={tableStyle.viewIconsStyle} source={selectedListIcon} />
            <Text style={CommonStyleSheet.themeColorTextStyle}>Driver</Text>
          </View>
          <View style={{ backgroundColor: colors.AppNewGreen, height: 2, width: '100%', marginTop: 5 }} />
        </View>
      </View>
    </View>)
  }
  searchViewRender = () => {
    var sView = []
      sView.push(<View style={{width:itsMobileView ? '100%' : '100%'}}>
        <TextInput
          style={{width: '100%', marginLeft: 0, height: 25, paddingLeft:5, paddingRight:18,outline:'none'}}
          placeholder="Search...."
          placeholderTextColor={colors.placeholderColor}
          value={this.state.searchKey}
          autoFocus={this.state.showSearchView}
          onChangeText={text => this.onSearchTextChange(text)}
        />
        <TouchableOpacity style={[tableStyle.textBoxButton,{display: this.state.searchKey.length == 0 ? 'none' : 'flex'}]} 
        onPress={() => this.onCancelText()}>
          <Image style={{ width: 16, height: 16, marginRight:10}} source={closeIcon} />          
        </TouchableOpacity>
      </View>)
    return <View style={[tableStyle.searchBarViewStyle,{marginLeft:0}]}>
      {sView}
    </View>
  }
  renderHeaderView = () => {
    return (<View style={tableStyle.headerViewstyle}>
      <View style={{ flexDirection: 'row', alignItems: 'center', zIndex: 4 }}>
        {/* <this.searchViewRender /> */}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <this.searchViewRender />
        <View style={{width:20,height:20}}/>
        <this.renderAddDriverBtn />
      </View>
    </View>)
  }
  EmptyListRender = () => {
    if (this.state.driversArray.length == 0) {
      if (this.state.haveData) {
        return (<View style={{ height: '100%', justifyContent: 'center' }}>
          <EmptyListUI showImage={true} titleString={'No driver found, add and manage it'} subtitleString={''} />
        </View>);
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  }

  renderListView = () => {
    return <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
      <View>
        <View style={tableStyle.headerListContainer}>
          <this.tableViewHeader />
        </View>
        <ScrollView style={styles.tableViewHeaderStyle}>
          <this.columnDataView />
        </ScrollView>
      </View>
    </ScrollView>

  }
  renderBottomView = () => {
    if (this.state.totalRecords > perPage) {
      return (<View style={tableStyle.bottomViewStyle}>
        <this.gradientButtons name={'Next'} id={1} />
        <this.gradientButtons name={'Previous'} id={2} />
      </View>)
    } else {
      return <View />
    }
  }

  render() {
    if (this.state.addDriverBool) {
      return (
        <View>
          <ToastContainer />
          <AddDriver
            closeBtnAction={() => this.addDriverBtnAction()}
            isEdit={this.state.isEdit}
            driverData={this.state.driversArray[this.state.selectedDriverIndex]}
            backBtnAction={() => this.addDriverBtnAction()}
          />
        </View>
      )
    } else if (this.state.showDriverDetailBool) {
      return (
        <View>
          <ToastContainer />
          <DriverDetail
            driverID={this.state.driversArray[this.state.selectedDriverIndex]['id']}
            backBtnAction={() => this.driverDetailbackBtnAction()}
          />
        </View>
      )
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <this.renderHeaderView />
          <View style={tableStyle.containerMainView}>
            <this.renderListView />
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}
const commonWidth = windowWidth/6.0
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : commonWidth,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
    padding:10,
  },
});

const tableHeaderString = [
  'Name',
  'Mobile',
  'Email',
  'Branch Name',
  'Fleet',
  'Action'
];

