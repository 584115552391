import React from 'react';
import placeholder from '../assets/pPlaceholder.png';
import {getThumbnailImage} from '../HelperClasses/SingletonClass';
import {currentOrderStatus} from '../Constants/Status';
import {useNavigate} from 'react-router';

const UserOrderList = ({orders_list}) => {
  const navigate = useNavigate();
  return (
    orders_list?.length > 0 && (
      <div className=" p-[25px]  ">
        <p>User Orders List</p>

        <div className=" mt-2">
          {orders_list?.map(order_item => {
            let order_details = order_item?.order_details?.[0]?.listing;
            let grandTotal = order_item?.grand_total?.formatted;
            let status = currentOrderStatus(order_item['order_status']);
            let pstatus = order_item?.payment_status == 2 ? 'Paid' : 'UnPaid';
            let image = order_details?.images?.[0] || '';
            let fileURl = image ? getThumbnailImage(image) : placeholder;
            let id = order_item?.id;
            return (
              <div
                className=" p-[10px] rounded-[10px] shadow-app-gray  max-w-4xl flex items-center justify-start gap-[10px] mb-[5px] cursor-pointer "
                onClick={() => navigate(`/orders/${id}`)}>
                <img
                  className=" h-20 w-20 rounded-[10px]"
                  src={fileURl}
                  alt=""
                />

                <div className=" flex-grow flex items-center justify-between">
                  <div className=" space-y-[5px]">
                    <p className=" text-primary">{order_details?.title}</p>
                    <p className=" text-primary text-xs">{grandTotal}</p>
                    <p className="  text-xs">{status}</p>
                  </div>
                  <div className=" flex flex-col  items-end gap-2">
                    <p className=" text-primary">
                      Order Reference: {order_item?.reference_number}{' '}
                    </p>
                    <p
                      className={
                        pstatus == 'Paid' ? 'btn-active' : 'btn-orange'
                      }>
                      {pstatus}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default UserOrderList;
