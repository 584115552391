import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  FlatList,
} from 'react-native';
import NaviHeader from '../Component/NavigationHeader';
import colors from '../HelperClasses/AppColor';
import ReactFileReader from 'react-file-reader';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../Settings/HeaderView';
import {AppToast} from '../HelperClasses/AppToast';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import commonStyle from '../StyleSheets/CommonStyleSheet';
import MediaManagerView from '../Component/MediaManagerView';
import Switch from 'react-switch';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import AppConfigStyleSheet from '../StyleSheets/AppConfigStyleSheet';
import {Gradient} from 'react-gradient';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import SaveButton from '../Component/SaveButton';
import AppConstants from '../Constants/AppConstants';
import downArrow_Icon from '../assets/downArrowIcon.png';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
var pageNo = 1;

export default class AddCurrencyClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedEntriesCount: 10,
      search: '',
      selectedImage: null,
      status: true,
      default: true,
      name: '',
      code: '',
      exchangeRate: '',
      precision: '',
      thousandSeparator: '',
      decimalPoint: '',
      format: '',
      dataLoad: true,
      id: '',
      orderBy: '',
      showMediaManager: false,
      mediaArray: [],
      isDropdownOpen: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    currencyData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    const currencyData = this.props.currencyData;
    this.loadMediaAPi();
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (this.state.name.length == 0) {
        this.setState({
          name: currencyData['name'],
          code: currencyData['code'],
          decimalPoint: currencyData['decimal_point'],
          default: currencyData['default'],
          status: currencyData['active'],
          exchangeRate: currencyData['exchange_rate'],
          format: currencyData['format'],
          precision: currencyData['precision'],
          thousandSeparator: currencyData['thousand_separator'],
          id: currencyData['id'],
          orderBy: currencyData['order_by'],
          selectedImage: currencyData['image_path'],
        });
      }
    }
  }
  loadMediaAPi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,
      'get',
      '',
      '',
    );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var mediaD = responseJson['data']['media'];
      if (mediaD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of mediaD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
    }
    this.setState({dataLoad: true});
  };
  addCurrencyApi = async image => {
    var dict = {
      name: this.state.name,
      default: this.state.default,
      active: this.state.status,
      order_by: 0,
      code: this.state.code,
      exchange_rate: this.state.exchangeRate,
      precision: this.state.precision,
      thousand_separator: this.state.thousandSeparator,
      decimal_point: this.state.decimalPoint,
      format: this.state.format,
    };

    if (image.length != 0) {
      dict['image_path'] = image;
    }
    var path = APPURL.URLPaths.currencies;

    if (this.state.id !== '') {
      path = APPURL.URLPaths.currencies + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({currency: dict}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.props.backBtnAction(true);
      this.customAlert();
    } else {
      this.mixpanelInfo(false);
      this.alertView(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    let event =
      this.state.id !== ''
        ? MixpanelEvent.editCurrency
        : MixpanelEvent.addCurrency;
    addMixpanelEvent(event, properties);
  }
  uploadImageAPI = async () => {
    this.setState({dataLoad: false});
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'].replace(
            / /g,
            '',
          ),
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        'this.state.bToken',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          // console.log('file_upload_res', file_upload_res);
          this.addCurrencyApi(result[0]['fileUri']);
        });
      } else {
        this.alertView(responseJson);
      }
    } else {
      this.addCurrencyApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };

  customAlert() {
    AppToast();
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Button Actions
  openDropDown = id => {
    this.setState({isDropdownOpen: !this.state.isDropdownOpen});
  };
  submitButtonAction = () => {
    // if (this.state.name == '') {
    //   this.alertView('Enter currency name');
    // } else if (this.state.code == '') {
    //   this.alertView('Enter currency code');
    // } else if (this.state.exchangeRate == '') {
    //   this.alertView('Enter exchange rate');
    // } else if (this.state.precision == '') {
    //   this.alertView('Enter precision');
    // } else if (this.state.thousandSeparator == '') {
    //   this.alertView('Enter thousand separator');
    // } else if (this.state.decimalPoint == '') {
    //   this.alertView('Enter decimal point');
    // } else if (this.state.format == '') {
    //   this.alertView('Enter currency format');
    // } else {
    //   this.setState({dateLoad: false});
    //   this.addCurrencyApi();
    // }
    this.uploadImageAPI();
  };
  statusToggle = () => {
    // console.log('statusToggel');
    this.setState({status: !this.state.status});
  };
  defaultToggle = () => {
    // console.log('defaultToggel');
    this.setState({default: !this.state.default});
  };
  onPick = image => {
    this.setState({selectedImage: image});
  };
  showMediaBtnAction() {
    if (this.state.mediaArray != 0) {
      this.setState({showMediaManager: true});
    } else {
      AppToast('You dont have media file.');
    }
  }
  // UI Renders
  imageView = props => {
    var view = [];
    view.push(
      <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
        <View style={commonStyle.addImageViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg', '.gif', '.svg']}
            base64={true}
            handleFiles={this.onPick}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Add Photo
            </Text>
          </ReactFileReader>
        </View>
        <View style={{width: 20, height: 20}} />
        <View style={commonStyle.addImageViewStyle}>
          <TouchableOpacity
            style={{width: 100}}
            onPress={() => this.showMediaBtnAction()}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Select from media
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{width: 20, height: 20}} />
      </View>,
    );
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      view.push(
        <View>
          <View style={{height: 10, width: 10}} />
          <Image
            source={{
              uri:
                fileName != null
                  ? this.state.selectedImage.base64
                  : this.state.selectedImage,
            }}
            style={commonStyle.ImageViewStyle}
          />
        </View>,
      );
    }
    return (
      <View style={{flexDirection: itsMobileView ? 'column' : 'column'}}>
        {view}
      </View>
    );
  };

  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  loaderView = () => {
    return <Appload enable={this.state.dataLoad} />;
  };
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9920,
          }}>
          <MediaManagerView
            mediaData={this.state.mediaArray}
            closeBtn={() => this.setState({showMediaManager: false})}
            onSelectBtn={path =>
              this.setState({showMediaManager: false, selectedImage: path})
            }
          />
        </View>
      );
    } else {
      return <View />;
    }
  };

  renderSaveConfigBtn = () => {
    return (
      <View>
        <SaveButton
          saveBtn={() => this.submitButtonAction()}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };

  renderValueLabel = id => {
    return (
      <View
        style={[
          appConfigStyle.textFieldAddCurrencyStyle,
          {
            marginLeft: 0,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        ]}>
        <Text
          style={
            this.state.code == ''
              ? commonStyle.placeholderTxtStyle
              : commonStyle.dropDowntxtFieldStyle
          }>
          {this.state.code == '' ? 'Select' : this.state.code}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? commonStyle.upArraowIconStyle
              : commonStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  customDropDown = id => {
    if (this.state.isDropdownOpen == true) {
      return (
        <View
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: 'rgba(230, 230, 230, 1)',
            width: '100%',
            marginLeft: 0,
            zIndex: 900,
            height: 160,
          }}>
          <FlatList
            data={AppConstants.CURRENCY_Codes}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={true}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };

  didSelectDropDown = item => {
    if (item) {
      this.setState({
        isDropdownOpen: false,
        code: item,
      });
    }
  };

  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text style={commonStyle.dropdownCellItemTextStyle}>{item}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView style={{height: windowHeight / 1.18}}>
          <View style={commonStyle.sidepanmViewStyle}>
            {this.mediaManagerView()}
            <View style={{height: 15, width: '100%'}} />
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Name'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.name}
                placeholder="Currency Name"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({name: name})}
              />
            </View>
            <View style={{height: 15, width: '100%'}} />
            <View style={commonStyle.subViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Upload Media </Text>
              <View style={{width: '72%'}}>
                <this.imageView />
              </View>
            </View>
            <View style={{height: 15, width: '100%'}} />
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Code'} />

              <View style={{width: itsMobileView ? '100%' : '80%'}}>
                <TouchableOpacity
                  style={styles.dropDownViewStyle}
                  onPress={() => this.openDropDown(2)}>
                  <this.renderValueLabel id={2} />
                  <this.customDropDown id={2} />
                </TouchableOpacity>
              </View>
              {/* <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                placeholder="USD"
                value={this.state.code}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={code => this.setState({code: code})}
              /> */}
            </View>
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Exchange Rate'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                placeholder="1"
                value={this.state.exchangeRate}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={exchangeRate =>
                  this.setState({exchangeRate: exchangeRate})
                }
              />
            </View>
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Precision'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                placeholder="2"
                value={this.state.precision}
                placeholderTextColor={colors.placeholderColor}
                onChangeText={precision =>
                  this.setState({precision: precision})
                }
              />
            </View>
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Thousand Separator'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.thousandSeparator}
                placeholder=","
                placeholderTextColor={colors.placeholderColor}
                onChangeText={thousandSeparator =>
                  this.setState({thousandSeparator: thousandSeparator})
                }
              />
            </View>
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Decimal Point'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.decimalPoint}
                placeholder="."
                placeholderTextColor={colors.placeholderColor}
                onChangeText={decimalPoint =>
                  this.setState({decimalPoint: decimalPoint})
                }
              />
            </View>
            <View style={{height: 20}} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>
                Status(Not Active / Active){' '}
              </Text>
              <View style={{width: itsMobileView ? '12%' : '80%'}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={this.statusToggle}
                  checked={this.state.status}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{height: 10, width: '100%'}} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Default</Text>
              <View style={{width: itsMobileView ? '12%' : '80%'}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={this.defaultToggle}
                  checked={this.state.default}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{height: 10, width: '100%'}} />
            <View style={commonStyle.subViewStyle}>
              <this.titleLblRender title={'Format'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.format}
                placeholder="USD {amount}"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={format => this.setState({format: format})}
              />
            </View>
            <View style={{height: 20, width: '100%'}} />
          </View>
        </ScrollView>
        <this.loaderView />
      </SafeAreaView>
    );
  }
}
const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
export const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to 
  resize from the original image resolution */
  canvas.width = 250;
  canvas.height = 250;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg');
  });
};
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    marginTop: 0,
  },
  addImageViewStyle: {
    height: 100,
    width: 100,
    margin: 10,
    alignItems: 'center',
    marginLeft: 0,
    justifyContent: 'center',
    backgroundColor: colors.Lightgray,
    borderRadius: 20,
  },
  addImageStyle: {
    flexDirection: 'column',
  },
  ImageViewStyle: {
    height: 100,
    width: 100,
    borderRadius: 20,
  },
});
