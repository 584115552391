import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Dimensions,
  Image,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import deleteIcon from './../assets/delete_icon.png';
import {AppToast} from './../HelperClasses/AppToast';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import AppConstants from '../Constants/AppConstants';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
export default class NavigationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regexp: /^[0-9-\b]+$/,
      updateUI: false,
    };
  }
  static propTypes = {
    id: PropTypes.string,
    count: PropTypes.array,
    addBtn: PropTypes.func,
    deleteBtn: PropTypes.func,
    valueArray: PropTypes.array,
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={CommonStyleSheet.plainTextStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  fromValue(value, id) {
    var txtValue = '';
    if (value == '' || this.state.regexp.test(value)) {
      txtValue = value;
    }
    let slab = this.props.valueArray['slab'];
    let slabData = slab[id];
    slabData['fromValue'] = txtValue;
    this.props.valueArray['slab'][id] = slabData;
    this.setState({updateUI: !this.state.updateUI});
  }
  toValue(value, id) {
    var txtValue = '';
    if (value == '' || this.state.regexp.test(value)) {
      txtValue = value;
    }
    let slab = this.props.valueArray['slab'];
    let slabData = slab[id];
    slabData['toValue'] = txtValue;
    this.props.valueArray['slab'][id] = slabData;
    this.setState({updateUI: !this.state.updateUI});
  }
  fromValueEndEditing(id) {
    console.log('fromValueEndEditing', id);
    console.log('yo =', this.props.valueArray['slab'][id]);
    if (this.props.valueArray['slab'].length > 1) {
      if (this.props.valueArray['slab'][id - 1]['toValue']) {
        let toValue = this.props.valueArray['slab'][id - 1]['toValue'];
        let fromValue = this.props.valueArray['slab'][id]['fromValue'];
        // console.log('coming here');
        if (fromValue <= toValue) {
          if (toValue != -1) {
            let slab = this.props.valueArray['slab'];
            let slabData = slab[id];
            slabData['fromValue'] = '';
            this.props.valueArray['slab'][id] = slabData;
            this.setState({ updateUI: !this.state.updateUI });
            AppToast('From value should be greater then last to value');
          }
        }
      }
    }
  }
  toValueEndEditing(id) {
    let toValue = this.props.valueArray['slab'][id]['toValue'];
    let fromValue = this.props.valueArray['slab'][id]['fromValue'];
    if (fromValue >= toValue) {
      // console.log('toValue=>', toValue);
      if (toValue != -1) {
        let slab = this.props.valueArray['slab'];
        let slabData = slab[id];
        slabData['toValue'] = '';
        this.props.valueArray['slab'][id] = slabData;
        this.setState({updateUI: !this.state.updateUI});
        AppToast('To value should be greater then from value');
      }
    }
  }
  valuePercentage(value, id) {
    var txtValue = '';
    if (value == '' || this.state.regexp.test(value)) {
      txtValue = value;
    }
    let slab = this.props.valueArray['slab'];
    let slabData = slab[id];
    slabData['valuePercentage'] = txtValue;
    this.props.valueArray['slab'][id] = slabData;
    // console.log('empry =>', this.props.valueArray);
    this.setState({updateUI: !this.state.updateUI});
  }

  addSlabViewRender = ({count, id}) => {
    // console.log('count =valueArray=>', count, id, this.props.valueArray);
    if (count != 0) {
      var slabViews = [];
      for (let i = 0; i < count; i++) {
        // console.log('t', this.props.valueArray['slab'][i]['valuePercentage']);
        slabViews.push(
          <View
            style={{
              borderTopColor: colors.SimonGray,
              borderTopWidth: 1,
              margin: 16,
              zIndex: 12,
            }}>
            <View style={{marginTop: 0}}>
            </View>
            <View
              style={{
                flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
              }}>
              <View style={styles.ValueViewStyle}>
                <this.titleLblRender title={'From Value'} />
                <TextInput
                  style={CommonStyleSheet.commissionValueTxtFieldStyle}
                  placeholder="From Value"
                  placeholderTextColor={colors.placeholderColor}
                  value={this.props.valueArray['slab'][i]['fromValue']}
                  onChangeText={value => this.fromValue(value, i)}
                  onBlur={() => this.fromValueEndEditing(i)}
                />
              </View>
              <View style={{width: 20, height: 20}} />
              <View style={styles.ValueViewStyle}>
                <View style={{ flexDirection: 'row' }}>
                  <this.titleLblRender title={'ToValue'} />
                  <Text style={CommonStyleSheet.hintTitleStyle}>
                    (-1 for unlimited)
                  </Text>
                </View>
                <TextInput
                  style={[CommonStyleSheet.commissionValueTxtFieldStyle,{outline:0}]}
                  placeholder="To Value"
                  placeholderTextColor={colors.placeholderColor}
                  value={this.props.valueArray['slab'][i]['toValue']}
                  onChangeText={toValue => this.toValue(toValue, i)}
                  onBlur={() => this.toValueEndEditing(i)}
                />
              </View>
              <View style={{width: 20, height: 20}} />
              <View style={styles.ValueViewStyle}>
                <this.titleLblRender title={'Value (%)'} />
                <TextInput
                  style={[CommonStyleSheet.commissionValueTxtFieldStyle,{outline:0}]}
                  placeholder="Value"
                  placeholderTextColor={colors.placeholderColor}
                  value={this.props.valueArray['slab'][i]['valuePercentage']}
                  onChangeText={value => this.valuePercentage(value, i)}
                />
              </View>
              <View style={{width: 20, height: 20}} />
              <View style={styles.addDeleteViewStyle}>
                <TouchableOpacity
                  style={styles.addViewStyle}
                  onPress={() => this.props.addBtn(id)}>
                  <Image style={{width:20, height: 20}} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.addViewStyle}
                  onPress={() => this.props.deleteBtn(id, i)}>
                  <Image style={styles.iconImage} source={deleteIcon} resizeMode={'center'}/>
                </TouchableOpacity>
              </View>
            </View>
          </View>,
        );
      }
      return slabViews;
    } else {
      return <View />;
    }
  };

  render() {
    return (
      <View>
        <this.addSlabViewRender count={this.props.count} id={this.props.id} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  ValueViewStyle: {
    margin: 5,
    flex: 2,
  },
  addViewStyle: {
    margin: 10,
  },
  iconImage: {
    height: 25,
    width: 25,
  },
  addDeleteViewStyle: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
});
