import React, {useEffect, useState} from 'react';
import {getTenantLanguages} from '../../Settings/Actions/getLanguages';

const LanguageHeader = ({
  selected_language,
  setSelectedLanguage,
  setDefaultLanguage,
  languages,
}) => {
  // language data and states
  // const { data: tenant_languages } = useGetTenantLanguagesQuery({})

  return (
    languages?.length > 1 && (
      <div className="flex flex-col gap-1 ">
        <div className="flex items-center justify-start  w-full bg-white py-2 ">
          {languages?.map(ln => (
            <button
              onClick={() => setSelectedLanguage(ln.code)}
              className={
                selected_language === ln.code
                  ? ' px-4 font-bold text-lg  py-1.5 rounded-t-lg border-2 border-b-0 border-primary text-primary'
                  : 'px-4 py-2 border-b-2 border-primary'
              }
              type="button">
              {ln?.name}
            </button>
          ))}
          <div className="x-4 py-5 border-b-2 border-primary w-full"></div>
        </div>
        {/* <p className="text-sm  italic text-gray-500">(translation is currently in beta)</p> */}
      </div>
    )
  );
};

export default LanguageHeader;
