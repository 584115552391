import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import AppConstants from '../Constants/AppConstants';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  FlatList,
  Linking,
  Animated,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import orderIcon from './../assets/orderIcon.png';
import reportIcon from './../assets/reportIcon.png';
import openLinkIcon from './../assets/whiteOpenLinkIcon.png';

import branchesIcon from './../assets/branchesIcon.png';
import auditIcon from './../assets/auditIcon.png';
import driver from './../assets/driver.png';
import fleetsIcon from './../assets/fleetsIcon.png';
import rosterIcon from './../assets/rosterIcon.png';
import starIcon from './../assets/starIcon.png';

import {getCookiesValue} from '../HelperClasses/UserPrefrences';
import {DeliverySideMenuArray} from '../Constants/ConstantArray';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import {sideMenus} from '../Constants/SideMenusArray';
import {useLocation, useNavigate, useNavigation} from 'react-router-dom';
import {B2CMenus} from '../Constants/B2CMenus';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').width;

// Need to update latter
var driveryMenuArray = [
  {name: DeliverySideMenuArray[0], image: starIcon},
  {name: DeliverySideMenuArray[1], image: orderIcon},
  {name: DeliverySideMenuArray[2], image: branchesIcon},
  {name: DeliverySideMenuArray[3], image: fleetsIcon},
  {name: DeliverySideMenuArray[4], image: driver},
  {name: DeliverySideMenuArray[5], image: rosterIcon},
  {name: DeliverySideMenuArray[6], image: reportIcon},
  {name: DeliverySideMenuArray[7], image: auditIcon},
];

var sidemenuBGColor = colors.AppNewGreen;

const optionsCursorTrueWithMargin = {
  followCursor: true,
  shiftX: 20,
  shiftY: -0,
};
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

const SideMenubar3 = props => {
  // States
  const [sideMenubarState, setSideMenubarState] = useState({
    updateValue: false,
    selectIndex: 1,
    menuMainArray: [],
    subMenus: [],
    hoverView: '',
    showMultipleAppView: false,
    hoverIndex: 0,
    tooltipPositionX: 0,
    tooltipPositionY: 0,
    tooltipOpacity: new Animated.Value(0),
  });

  const navigate = useNavigate();
  const navigation = useNavigation();
  const location = useLocation();

  //
  useEffect(() => {
    if (AppConstants.projectType == ProjectTypeModel.delivery) {
      setSideMenubarState(prevState => ({
        ...prevState,
        menuMainArray: driveryMenuArray,
        updateValue: !updateValue,
      }));
    } else {
      checkModuleRule();
    }
  }, [0]);

  const checkModuleRule = () => {
    setSideMenubarState(prevState => ({
      ...prevState,
      menuMainArray: AppConstants.appType ? B2CMenus : sideMenus,
      updateValue: !updateValue,
    }));
  };

  // Functions

  function changeEnvBtnAction(value) {
    sidemenuBGColor = value ? colors.AppNewGreen : colors.blackColor;
    setSideMenubarState(prevState => ({
      ...prevState,
      menuMainArray: sideMenus,
      updateValue: !updateValue,
    }));
  }
  function checkEnvironment() {
    var checkEnv = AppConstants.isDevelopment ? true : props.changeEnvBtn;
    if (!AppConstants.isDevelopment) {
      let evn = getCookiesValue(AppConstants.tradlyEVN);
      checkEnv = evn == 'sandbox' ? false : true;
    }
    return checkEnv;
  }

  // Mixpanel
  function mixpannelInfo(value) {
    // addMixpanelEvent(MixpanelEvent.sideMenu, {action: value});
  }

  // button select
  function didSelectSideMenu(item) {
    // mixpannelInfo(item['title']);
    item?.isOpenNewTab
      ? window.open(item.href, '_blank')
      : item.href && navigate(item.href);

    if (item.href) {
      setSideMenubarState(prevState => ({
        ...prevState,
        subMenus: [],
        hoverView: '',
        hoverIndex: 0,
        tooltipPositionX: 0,
        tooltipPositionY: 0,
      }));
    }
  }

  //
  function onMouseEnter(index, item, event) {
    setSideMenubarState(prevState => ({
      ...prevState,
      subMenus: [...item.sub_menus],
      hoverView: item['title'],
      hoverIndex: index,
      tooltipPositionX: event.nativeEvent.pageX,
      tooltipPositionY: event.nativeEvent.pageY,
      //   tooltipOpacity: Animated.timing(tooltipOpacity, {
      //     toValue: 1,
      //     duration: 200,
      //   }).start(),
    }));
  }
  function onMouseLeave(index, item, event) {
    if (subMenus?.length == 0) {
      setSideMenubarState(prevState => ({
        ...prevState,
        subMenus: [],
        hoverView: '',
        hoverIndex: 0,
        tooltipPositionX: 0,
        tooltipPositionY: 0,
      }));
    } else {
      setSideMenubarState(prevState => ({
        ...prevState,
      }));
    }
  }

  function onMouseLeaveFromSubMenu() {
    setSideMenubarState(prevState => ({
      ...prevState,
      subMenus: [],
      hoverView: '',
      hoverIndex: 0,
      tooltipPositionX: 0,
      tooltipPositionY: 0,
    }));
  }

  //MARK:-  UI  

  //   Render sub menuview
  const RenderSubMenuView = () => {
    ///subMenus hoverView hoverIndex
    if (subMenus?.length > 0) {
      return (
        <View
          style={[
            styles.submenuViewContainerStyle,
            {
              display: hoverView !== '' ? 'flex' : 'none',
              marginTop:
                Number(tooltipPositionY) -
                (Number(tooltipPositionY) - (Number(tooltipPositionY) - 50)),
            },
          ]}
          onMouseLeave={() => onMouseLeaveFromSubMenu()}>
          <View
            style={{
              padding: 10,
              borderBottomWidth: 1,
              borderBottomColor: colors.SimonGray,
            }}>
            <Text style={{color: colors.SimonGray, paddingLeft: 10}}>
              {hoverView}
            </Text>
          </View>
          {subMenus.map(item => {
            return (
              <View style={{margin: 10, marginBottom: 0, marginLeft: 16}}>
                <TouchableOpacity
                  style={{flexDirection: 'row', alignItems: 'center'}}
                  onPress={() => didSelectSideMenu(item)}>
                  <Image style={{width: 30, height: 30}} source={item.icon} />
                  <Text style={CommonStyleSheet.subMenuTextStyle}>
                    {item.title}
                  </Text>
                  <Image
                    style={{
                      display: item.isOpenNewTab ? 'flex' : 'none',
                      width: 16,
                      height: 16,
                      tintColor: 'white',
                    }}
                    source={openLinkIcon}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
        </View>
      );
    } else {
      return <></>;
    }
  };

  // Single menu item
  const ListCell = ({item, index}) => {
    let checkEnv = checkEnvironment();

    let hover = hoverView == item['title'];

    return (
      <TouchableOpacity
        disabled={item.sub_menus?.length > 0 ? true : false}
        onMouseLeave={event => onMouseLeave(index, item, event)}
        onMouseEnter={event => onMouseEnter(index, item, event)}
        style={
          location.pathname == item['href'] || hover
            ? styles.selectedListViewStyle
            : styles.listViewStyle
        }
        onPress={() => didSelectSideMenu(item)}>
        <View
          style={
            location.pathname == item['href'] || hover
              ? styles.leftSelectView
              : checkEnv
              ? styles.leftUnSelectView
              : styles.leftUnSelectBlackView
          }
        />
        <View style={{width: 40}}>
          <Image
            style={styles.imageStyle}
            resizeMode="contain"
            source={item['icon']}
          />
        </View>
        <View style={{marginLeft: 10}}>
          <Text
            numberOfLines={1}
            style={[
              CommonStyleSheet.plainTextStyle,
              {color: colors.SimonGray, flex: 1},
            ]}>
            {item['title']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  // Single menu item
  const CustomListCellButton = ({item, customFunc}) => {
    return (
      <TouchableOpacity
        style={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'end',
          height: commonHeight,
          flex: 1,
          marginTop: 5,
          marginBottom: 5,
        }}
        onPress={() => {
          customFunc();
        }}>
        <View style={{width: '100%'}}>
          <Image
            style={styles.imageStyle}
            resizeMode="contain"
            source={item['icon']}
          />
        </View>
        <View style={{marginLeft: 10}}>
          <Text
            numberOfLines={1}
            style={[
              CommonStyleSheet.plainTextStyle,
              {color: colors.SimonGray, flex: 1},
            ]}>
            {item['title']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const onMobileListCell = ({item, index}) => {
    let checkEnv = checkEnvironment();

    let hover = hoverView == item['title'];

    return (
      <TouchableOpacity
        disabled={item.sub_menus?.length > 0 ? true : false}
        style={
          location.pathname == item['href'] || hover
            ? styles.selectedListViewStyle
            : styles.listViewStyle
        }
        onPress={() => didSelectSideMenu(item)}>
        <View
          style={
            location.pathname == item['href'] || hover
              ? styles.leftSelectView
              : checkEnv
              ? styles.leftUnSelectView
              : styles.leftUnSelectBlackView
          }
        />
        <View style={{width: 40}}>
          <Image
            style={styles.imageStyle}
            resizeMode="contain"
            source={item['icon']}
          />
        </View>
        <View style={{marginLeft: 10}}>
          <Text
            numberOfLines={1}
            style={[
              CommonStyleSheet.plainTextStyle,
              {color: colors.SimonGray, flex: 1},
            ]}>
            {item['title']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  //
  const {
    updateValue,
    menuMainArray,
    subMenus,
    hoverView,
    showMultipleAppView,
    hoverIndex,
    selectIndex,
    tooltipOpacity,
    tooltipPositionX,
    tooltipPositionY,
  } = sideMenubarState;

  //
  let checkEnv = checkEnvironment();

  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        backgroundColor: checkEnv ? colors.AppNewGreen : colors.blackColor,
      }}>
      <View style={checkEnv ? styles.container : styles.blackContainer}>
        <View style={{height: 10}} />
        <View
          style={{
            height:
              AppConstants.projectType == ProjectTypeModel.delivery
                ? '80%'
                : '80%',
            paddingBottom: 80,
          }}>
          <FlatList
            data={menuMainArray?.filter(
              item => ![15, 17, 18, 19, 20].includes(item.id) && item.isShow,
            )}
            horizontal={false}
            renderItem={itsMobileView ? onMobileListCell : ListCell}
            extraData={sideMenubarState}
            showsVerticalScrollIndicator={false}
          />
        </View>
        <View
          style={{
            position: 'fixed',
            bottom: 0,
            width: 200,
            height: 'auto',
            backgroundColor: colors.AppNewGreen,
          }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}>
            <ListCell
              item={sideMenus.filter(item => item.id == 19)[0]}
              index={19}
            />
            <ListCell
              item={sideMenus.filter(item => item.id == 20)[0]}
              index={20}
            />
            <ListCell
              item={sideMenus.filter(item => item.id == 15)[0]}
              index={15}
            />
          </View>
          <View
            style={{
              marginTop: 30,
            }}>
            <TouchableOpacity
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                height: commonHeight,
                paddingRight: 20,
              }}
              onPress={() => {
                props.customBtnFunction();
              }}>
              <Image
                style={styles.imageStyle}
                resizeMode="contain"
                source={sideMenus.filter(item => item.id == 18)[0]['icon']}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      {/* 🔶💥 */}

      {hoverView !== '' && <RenderSubMenuView />}
      {/* {renderSubMenuView} */}
      {/* < multipleAppView /> */}
    </View>
  );
};

export default SideMenubar3;

const commonHeight = 30;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.AppNewGreen,
    marginBottom: 0,
  },
  blackContainer: {
    flex: 1,
    backgroundColor: colors.blackColor,
    marginBottom: 100,
  },
  listViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    height: commonHeight,
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  selectedListViewStyle: {
    backgroundColor: colors.lightYellow,
    flexDirection: 'row',
    alignItems: 'center',
    height: commonHeight,
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  leftSelectView: {
    marginLeft: 0,
    width: 4,
    height: commonHeight,
    backgroundColor: colors.AppYellow,
    marginRight: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  leftUnSelectView: {
    marginLeft: 0,
    width: 4,
    height: commonHeight,
    backgroundColor: colors.AppNewGreen,
    marginRight: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  leftUnSelectBlackView: {
    marginLeft: 0,
    width: 4,
    height: commonHeight,
    backgroundColor: colors.blackColor,
    marginRight: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  imageStyle: {
    width: 20,
    height: 20,
    alignSelf: 'center',
  },
  submenuViewContainerStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    minHeight: 140,
    width: 240,
    marginLeft: 210,
    borderRadius: 10,
    paddingBottom: 10,
  },
  hoverViewContainerStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    // height: 40,
    padding: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
  },
  multipleAppViewStyle: {
    position: 'absolute',
    backgroundColor: colors.AppNewGreen,
    minHeight: 100,
    padding: 16,
    minWidth: 200,
    borderRadius: 5,
    marginLeft: 210,
    alignSelf: 'flex-end',
    bottom: 75,
    justifyContent: 'center',
  },
});
