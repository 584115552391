import React from 'react';

const Title = ({children}) => {
  return (
    <h1 className=" text-black text-2xl md:text-4xl font-bold text-center ">
      {children}
    </h1>
  );
};

export default Title;
