import uploadImage from '../../../Actions/uploadImage';
import ToastPopup from '../../../HelperClasses/AppLoaderClasses/ToastPopup';
import networkService from '../../../Network/NetworkManager';
const APPURL = require('../../../Constants/URLConstants');

export const createNewAttrGroup = async ({
  form_data,
  selected_attr,
  setIsLoading,
  onSuccess,
  isEdit,
  id,
}) => {
  try {
    setIsLoading(true);
    let form_data_copy = {...form_data};

    if (form_data?.icon_path?.files) {
      const file = form_data?.icon_path?.files;
      let img_resp = await uploadImage({
        files: [{name: file?.name, type: file.type}],
        full_files: [file],
      });
      form_data_copy.icon_path = img_resp[0];
    } else {
      form_data_copy.icon_path = form_data?.icon_path?.value;
    }

    //
    const responseJson = isEdit
      ? await networkService.networkCall(
          APPURL.URLPaths.attributes_group + `/${id}`,
          'put',
          JSON.stringify({attribute_group: form_data_copy}),
        )
      : await networkService.networkCall(
          APPURL.URLPaths.attributes_group,
          'post',
          JSON.stringify({attribute_group: form_data_copy}),
        );

    setIsLoading(false);
    if (responseJson.status) {
      // Bulk ct update

      if (selected_attr?.length > 0) {
        const responseBulkJson = await networkService.networkCall(
          APPURL.URLPaths.attributes + APPURL.URLPaths.bulk,
          'PATCH',
          JSON.stringify({
            attributes: selected_attr?.map(item => ({
              id: item,
              attribute_group_id: responseJson?.data?.attribute_group?.id,
            })),
          }),
        );
      }
      onSuccess();
    } else {
      ToastPopup({type: 'error', message: 'Something went wrong'});
    }
  } catch (error) {
    setIsLoading(false);
  }
};

//
export const loadAttributesGroup = ({...params}) => {
  return networkService.networkCall(
    APPURL.URLPaths.attributes_group + `?type=${params?.type ?? 'listings'}`,
    'get',
  );
};

// delete attribute group
export const deleteAttributeGroup = async ({id, onSuccess}) => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes_group + `/${id}`,
      'delete',
    );
    if (!responseJson.status) {
      ToastPopup({type: 'error', message: 'Something went wrong'});
    } else {
      ToastPopup({type: 'success', message: 'Deleted successfully'});
    }
  } catch (error) {
    ToastPopup({type: 'error', message: 'Something went wrong'});
  }
};

// get details
export const getAttributeGroupDetails = async ({id, type, onSuccess}) => {
  try {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes_group + `/${id}` + `?type=${type}`,
      'get',
    );
    if (!responseJson.status) {
      ToastPopup({type: 'error', message: 'Something went wrong'});
    } else {
      return responseJson;
    }
  } catch (error) {
    ToastPopup({type: 'error', message: 'Something went wrong'});
  }
};
