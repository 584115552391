import networkService from '../../Network/NetworkManager';
import {all_categories_map} from '../../utils/AllCategoriesMap';
const APPURL = require('../../Constants/URLConstants');

// loadListingsApi
export const loadListingsApi = async ({
  key,
  current_state_values,
  update_state_values,
}) => {
  var path = APPURL.URLPaths.listings + `1&search_key=${key}`;
  const responseJson = await networkService.networkCall(path, 'get');
  if (responseJson.status == true) {
    update_state_values(responseJson.data.listings);
  }
};

// loadAccountsApi
export const loadAccountsApi = async ({
  key,
  current_state_values,
  update_state_values,
}) => {
  var path =
    APPURL.URLPaths.accountListings + `1&search_key=${key}&type=accounts`;
  const responseJson = await networkService.networkCall(path, 'get');
  if (responseJson.status == true) {
    update_state_values(responseJson.data.accounts);
  }
};
