import React, {useEffect, useRef, useState} from 'react';
import Transition from '../Transition';
import gen_open_ai_resp from './open-ai';
import {ICONS} from '../../Constants/Icons';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';
import Markdown from 'markdown-to-jsx';
import SecondaryButton from '../../UI/Buttons/SecondaryButton';

const GenerateText = ({system_instruction, user_id, user_message}) => {
  const [open_form, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ai_instructions, setAIInstructions] = useState({
    system_instruction: system_instruction,
    user_message: user_message,
    user_id: user_id,
    model: 'gpt-4o-mini',
    max_tokens: 1000,
  });
  const [ai_resp, setAIResp] = useState(null);

  //

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({keyCode}) => {
      if (!open_form || keyCode !== 27) return;
      setAIInstructions({
        system_instruction: system_instruction,
        user_message: user_message,
        user_id: user_id,
        model: 'gpt-4o-mini',
        max_tokens: 1000,
      });
      setOpenForm(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  //
  const [openSide, setOpenSide] = useState('');

  useEffect(() => {
    function handleClickOutside(event) {
      if (trigger.current && !trigger.current.contains(event.target)) {
        // setOpenSide('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger]);

  const onClickGenerate = async () => {
    setIsLoading(true);
    const resp = await gen_open_ai_resp(ai_instructions);
    setIsLoading(false);
    setAIResp(resp);
    setIsLoading(false);
  };

  return (
    <>
      <div className={'  inline-flex '}>
        <button
          ref={trigger}
          className=" text-gray-400"
          onClick={() => {
            onClickGenerate();
            setOpenForm(!open_form);
          }}>
          {ICONS.ai_star}
        </button>

        {/*  */}
        <Transition
          show={open_form}
          tag="div"
          className={`origin-top-right z-10 absolute top-full  w-full  md:w-[450px]  bg-white border border-gray-200  rounded shadow-lg overflow-hidden mt-1 ${
            openSide === 'right' ? 'right-0' : 'left-0'
          }`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0">
          {' '}
          <div className="  flex flex-col" ref={dropdown}>
            <div className=" px-4 py-2.5 bg-gray-100 flex items-center justify-between ">
              <p className=" text-sm font-semibold text-black">Suggestions</p>

              {/*  */}
              <div className=" flex item gap-4">
                {!isLoading && (
                  <SecondaryButton
                    onClick={() => onClickGenerate()}
                    className=" !py-1 !px-2 !text-sm">
                    Regenerate
                  </SecondaryButton>
                )}
                {/* close button */}
                <button
                  className={'    flex flex-col  items-center justify-center  '}
                  onClick={() => {
                    setAIInstructions({
                      system_instruction: system_instruction,
                      user_message: user_message,
                      user_id: user_id,
                      model: 'gpt-4o-mini',
                      max_tokens: 1000,
                    });
                    setAIResp(null);
                    setOpenForm(!open_form);
                  }}>
                  {ICONS.close}
                  <span className=" text-xs  font-light">ESC</span>
                </button>
              </div>
            </div>

            {isLoading && (
              <div className="   p-4  animate-pulse w-full space-y-2">
                <span className=" block w-full h-3 bg-[#3B3269] bg-opacity-[20%]  rounded-md" />
                <span className=" block w-full h-3 bg-[#3B3269] bg-opacity-[20%]  rounded-md" />
                <span className=" block w-full h-3 bg-[#3B3269] bg-opacity-[20%]  rounded-md" />
                <span className=" block w-full h-3 bg-[#3B3269] bg-opacity-[20%]  rounded-md" />
                <span className=" block w-full h-3 bg-[#3B3269] bg-opacity-[20%]  rounded-md" />
              </div>
            )}
            {ai_resp?.ai_response && !isLoading && (
              <div className="p-4 max-h-[500px] overflow-y-auto  prose">
                <Markdown>{ai_resp?.ai_response}</Markdown>
              </div>
            )}
          </div>
        </Transition>
      </div>
    </>
  );
};

export default GenerateText;
