/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import Modal from '../../../Component/UI/modals/Modal';
import AddressForm from './AddressForm/AddressForm';
import AppLoader from '../../../HelperClasses/AppLoaderClasses/AppLoader';
import networkService from '../../../Network/NetworkManager';
const APPURL = require('../../../Constants/URLConstants');

const AddressBox2 = ({order_details, load_order_details}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const {register, handleSubmit, setValue} = useForm();
  // account_shipment
  let account_shipment = order_details?.shipments[0];

  // Onsubmit new address
  const onSubmit = async data => {
    setShowShippingAddressForm(false);

    setIsLoading(true);
    const id = account_shipment?.pickup_address?.id ?? '';

    const path = id
      ? `${APPURL.URLPaths.addresses}/${id}`
      : APPURL.URLPaths.addresses;

    const addressResponseJson = await networkService.networkCall(
      path,
      id ? 'PUT' : 'POST',
      JSON.stringify({
        address: {
          ...Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== ''),
          ),
          type: 'pickup',
          current: true,
        },
      }),
    );

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.order +
        `${order_details?.id}/shipments/${account_shipment?.id}`,
      'PATCH',
      JSON.stringify({
        operation: 'update_pickup_address',
        shipment: {pickup_address_id: addressResponseJson.data.address.id},
      }),
    );

    if (responseJson) {
      setIsLoading(false);
      load_order_details();
      setShowShippingAddressForm(false);
    }
  };

  //Decide address
  const type = order_details?.shipping_method.type;
  let address;
  if (type === 'pickup') {
    address = order_details?.shipments[0]?.pickup_address ?? {};
  } else {
    address = account_shipment?.shipping_address;
  }

  return (
    <>
      <AppLoader enable={isLoading ? false : true} />
      {showShippingAddressForm && (
        <Modal onCloseModal={() => setShowShippingAddressForm(false)}>
          <div className=" max-w-fit max-h-fit  absolute right-0 left-0  top-0 bottom-0 mx-auto my-auto w-full  grid place-items-start bg-white  backdrop-blur-sm z-50  ">
            <AddressForm
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              setShowShippingAddressForm={setShowShippingAddressForm}
              pickup_address={account_shipment?.pickup_address}
              setValue={setValue}
            />
          </div>
        </Modal>
      )}

      <div className="w-full h-min-[50px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7    py-5  border-opacity-40">
        <div className="flex  ">
          <p className=" text-lg text-black font-semibold   ">
            {order_details?.shipping_method.name} Address
          </p>
        </div>
        <div className="mt-4">
          {account_shipment ? (
            Object?.keys(address)?.length > 0 ? (
              <>
                {address?.name && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold     w-24">
                      Name :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                      {address?.name}
                    </p>
                  </div>
                )}
                {address?.address_line_1 && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      Address 1 :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                      {address?.address_line_1}
                    </p>
                  </div>
                )}
                {address?.address_line_2 && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24 ">
                      Address 2:
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words ">
                      {address?.address_line_2}
                    </p>
                  </div>
                )}
                {address?.country && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      Country :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.country}
                    </p>
                  </div>
                )}
                {address?.post_code && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      Zip code :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.post_code}
                    </p>
                  </div>
                )}
                {address?.formatted_address && (
                  <div className=" flex justify-start items-start py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24 flex-none">
                      Full Address :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.formatted_address?.split(',').join(', ')}
                    </p>
                  </div>
                )}
                {address?.phone_number && (
                  <div className=" flex justify-start  items-start  py-1  ">
                    <p className=" text-sm text-black font-semibold    w-24">
                      Phone Number :
                    </p>
                    <p className=" text-sm text-black font-semibold  ml-2  text-opacity-70 break-words">
                      {address.phone_number}
                    </p>
                  </div>
                )}
              </>
            ) : (
              'Address not found'
            )
          ) : (
            'Address not applicable'
          )}
        </div>
        {order_details?.shipping_method.type === 'pickup' &&
          account_shipment?.status !== 7 && (
            <div className="mt-4">
              <button
                className=" btn-primary-outline"
                onClick={() => {
                  setShowShippingAddressForm(true);
                }}>
                {Object.keys(address).length === 0
                  ? 'Add Pickup Address'
                  : 'Edit Pickup Address'}
              </button>
            </div>
          )}
      </div>
    </>
  );
};

export default AddressBox2;
