/* eslint-disable react/jsx-pascal-case */
import React, {Component, useEffect, useState} from 'react';
import {View, ScrollView, Dimensions, SafeAreaView} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import Header_View from './CollectionHeaderView';
import {AppToast} from './../HelperClasses/AppToast';
import Moment from 'moment';
import AppConstants from '../Constants/AppConstants';
import SidePanView from '../Component/SidePanView';

import {slug as slugChecker} from 'github-slugger';
import {ToastContainer} from 'react-toastify';
import CollectionDetails from './components/CollectionDetails';
import CollectionSetup from './components/CollectionSetup';
import CollectionCondition from './components/CollectionCondition';
import SearchView from './components/SearchView';
import {
  loadAccountCategoriesApi,
  loadListingsCategoriesApi,
} from './ApisFunctions/LoadCategories';
import {uploadImageAPI} from '../utils/uploadImage';
import {
  addEditCollectionApi,
  getCollectionDetailAPI,
  getManualDataAPI,
} from './ApisFunctions/Add&EditCollection';
import {collections_styles} from './components/CollectionStyles';
import {useNavigate, useParams} from 'react-router-dom';
import {MixpanelEvent, addMixpanelEvent} from '../Models/MixPannelEnum';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

const CreateCollection2 = ({isEdit}) => {
  const navigate = useNavigate();
  const params = useParams();

  // main state for fields management
  const [collection_state, setCollectionState] = useState({
    expirable: false,
    medium: [1, 2, 3],
    images: [],
    scope_type: 1,
    condition_type: 2,
    manual_ids: [],
    start_at: '',
    end_at: '',
    active: true,
    view_type: 'horizontal',
    background_color: '#FFFFFF',
    background_image: '',
    conditions: {},
    order_by: 0,
    title: '',
    description: '',
    slug: '',
  });

  const {
    expirable,
    medium,
    images,
    scope_type,
    condition_type,
    manual_ids,
    start_at,
    end_at,
    active,
    view_type,
    background_color,
    background_image,
    conditions,
    order_by,
    title,
    description,
    slug,
  } = collection_state;

  // categories state:
  const [account_categories, setAccountCategories] = useState([]);
  const [listing_categories, setListingCategories] = useState([]);
  const [selectedSearchArray, setSelectedSearchArray] = useState([]);
  const [showSideView, setShowSideView] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  //
  useEffect(() => {
    // fetch account categories
    loadAccountCategoriesApi({
      current_state_values: account_categories,
      update_state_values: values => setAccountCategories(values),
    });
    // fetch listing categories
    loadListingsCategoriesApi({
      current_state_values: listing_categories,
      update_state_values: values => setListingCategories(values),
    });

    if (isEdit) {
      getCollectionDetailAPI({
        collectionID: params?.collectionID,
        current_state_values: collection_state,
        update_state_values: value => setCollectionState(value),
      });
    }

    setIsDataLoaded(true);
  }, []);

  useEffect(() => {
    if (isEdit) {
      setSelectedImage(images?.length > 0 ? images[0] : '');
    }
  }, [collection_state]);

  useEffect(() => {
    if (isEdit && condition_type === 2) {
      getManualDataAPI({
        collectionID: params?.collectionID,
        current_state_values: selectedSearchArray,
        update_state_values: values => setSelectedSearchArray(values),
        collection_type: scope_type,
      });
    }
  }, [collection_state?.scope_type]);

  // Save Functionality
  const saveBtnAction = async () => {
    if (title.length == 0) {
      AppToast('Title should not be empty');
    } else if (medium?.length === 0) {
      AppToast('Select atleast one platfrom');
    } else if (expirable && start_at > end_at) {
      AppToast('End Date must be greater then start date');
    } else if (condition_type === 2 && manual_ids?.length == 0) {
      AppToast(
        `Select minimum one ${scope_type === 1 ? 'account' : 'listing'}`,
      );
    }
    // else if (condition_type === 1 && (Object.keys(conditions)?.length == 0 ||)) {
    //   AppToast(
    //     `Select minimum one ${scope_type === 1 ? 'account' : 'listing'}`,
    //   );
    // }
    else {
      setIsDataLoaded(false);
      if (selectedImage?.base64) {
        let image_path = await uploadImageAPI({image_file: selectedImage});
        try {
          await addEditCollectionApi({
            data: {
              ...collection_state,
              images: [image_path],
              slug: slugChecker(slug || title),
            },
            isEdit,
          });

          mixpanelInfo(collection_state);
          window?.FM?.trackCustomEvent('Collection Created', {
            email: AppConstants.userEmail,
            collection: collection_state,
          });

          setIsDataLoaded(true);
          navigate(-1);
        } catch (error) {
          setIsDataLoaded(true);

          AppToast(error?.message ?? 'Something is wrong ');
          navigate(-1);
        }
      } else {
        try {
          await addEditCollectionApi({
            data: {...collection_state, slug: slugChecker(slug || title)},
            isEdit,
          });

          mixpanelInfo(collection_state);
          window?.FM?.trackCustomEvent('Collection Created', {
            email: AppConstants.userEmail,
            collection: collection_state,
          });

          setIsDataLoaded(true);
          navigate(-1);
        } catch (error) {
          AppToast(error?.message ?? 'Something is wrong ');
          setIsDataLoaded(true);
        }
      }
    }
  };

  // Mixpanel function
  function mixpanelInfo(collection_data) {
    let properties = {collection_data: collection_data};
    let eventName = isEdit
      ? MixpanelEvent.editCollection
      : `${MixpanelEvent.addCollection}`;
    addMixpanelEvent(eventName, properties);
  }

  // console.log('====================================');
  // console.log(collection_state);
  // console.log('====================================');

  return (
    <SafeAreaView style={collections_styles.Container}>
      <ToastContainer />
      <View style={{zIndex: 10}}>
        {showSideView && (
          <SidePanView
            dissmissView={() => setShowSideView(!showSideView)}
            showSidepanView={showSideView}
            customView={
              <SearchView
                collection_state={collection_state}
                setCollectionState={setCollectionState}
                showSideView={showSideView}
                setShowSideView={setShowSideView}
                selectedSearchArray={selectedSearchArray}
                setSelectedSearchArray={setSelectedSearchArray}
                isDataLoaded={isDataLoaded}
                setIsDataLoaded={setIsDataLoaded}
              />
            }
            title={'Search'}
          />
        )}
      </View>
      <View>
        <Header_View
          title={isEdit ? 'Edit Collection' : 'Create Collection'}
          isEdit={false}
          preview_path={''}
          backBtn={() => navigate(-1)}
          saveBtnTitle={isEdit ? 'Update' : 'Create'}
          saveBtn={() => saveBtnAction()}
        />
        <View style={tableStyle.subContainerView}>
          <ScrollView>
            <View
              style={{
                flexDirection: itsMobileView ? 'column' : 'row',
                flex: 1,
              }}>
              <View style={collections_styles.leftMainView}>
                <CollectionDetails
                  collection_state={collection_state}
                  setCollectionState={setCollectionState}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  selectedSearchArray={selectedSearchArray}
                  setSelectedSearchArray={setSelectedSearchArray}
                />
                <View style={{height: 20}} />
                <CollectionCondition
                  collection_state={collection_state}
                  setCollectionState={setCollectionState}
                  showSideView={showSideView}
                  setShowSideView={setShowSideView}
                  account_categories={account_categories}
                  listing_categories={listing_categories}
                  selectedSearchArray={selectedSearchArray}
                  setSelectedSearchArray={setSelectedSearchArray}
                  isEdit={isEdit}
                />
              </View>
              <CollectionSetup
                collection_state={collection_state}
                setCollectionState={setCollectionState}
              />
            </View>
          </ScrollView>
        </View>
        <Appload enable={isDataLoaded} />
      </View>
    </SafeAreaView>
  );
};

export default CreateCollection2;
