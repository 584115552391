import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import ReactFileReader from 'react-file-reader';
import close_Icon from '../../assets/closeIcon.png';
import {addMixpanelEvent, MixpanelEvent} from '../../Models/MixPannelEnum';
import {PaidFeatureTextView} from '../../Component/CommonComponents';
import ToolTipViewBtn from '../../Component/ToolTipViewBtn';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'email';

const feedbackTypeArray = ['Email', 'Phone', 'Email or Phone'];

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class EmailConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      fromName: '',
      fromEmailAddress: '',
      selectedFeedbackType: '',
      logoImage: null,
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + KeyGroup,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.state.fromName = configs['email_from_name'] || '';
      this.state.fromEmailAddress = configs['email_from'] || '';
      this.state.logoImage = configs['email_header_logo'] || '';
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  uploadFilesAPI = async () => {
    var imgParm = [];
    var uploadBase64 = [];
    if (this.state.logoImage != null) {
      let fileName = this.state.logoImage.base64;
      if (fileName != null) {
        var logoIconDict = {
          name: this.state.logoImage['fileList'][0]['name'].replace(/ /g, ''),
          type: this.state.logoImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.logoImage.base64,
          key: 'logo',
        });
        imgParm.push(logoIconDict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res =
              await networkService.uploadFileWithSignedURL(
                result[i]['signedUrl'],
                imgParm[i]['type'],
                await res.blob(),
              );
            this.state.logoImage = result[i]['fileUri'];
            this.addOrUpdateAppDetailApi();
          });
        }
      } else {
        this.setState({dataLoad: true});
        this.alertView(responseJson);
      }
    } else {
      this.addOrUpdateAppDetailApi();
    }
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    if (this.state.fromName.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: 'email_from_name',
        value: this.state.fromName,
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.fromEmailAddress.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: 'email_from',
        value: this.state.fromEmailAddress,
        secured: false,
      };
      configs.push(fDict);
    }
    if (this.state.logoImage.length != 0) {
      let fDict = {
        key_group: KeyGroup,
        key: 'email_header_logo',
        value: this.state.logoImage,
        secured: false,
      };
      configs.push(fDict);
    }
    if (configs.length != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.config,
        'post',
        JSON.stringify({configs: configs}),
        '',
      );
      this.setState({dataLoad: true});
      if (responseJson['status'] == true) {
        addMixpanelEvent(MixpanelEvent.emailConfiguration, {success: true});
        this.setState({dataLoad: true});
        this.alertView('');
      } else {
        addMixpanelEvent(MixpanelEvent.emailConfiguration, {success: false});
        this.alertView(responseJson);
      }
    } else {
      this.setState({dataLoad: true});
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
  }
  onPickFile = (image, id) => {
    if (id == 0) {
      this.setState({logoImage: image});
    }
  };
  downloadBtnAction = pathURL => {
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    }
  };
  cancelBtnAction = id => {
    if (id == 0) {
      this.setState({logoImage: null});
    }
  };
  openLink() {
    window.open(AppConstants.changeEmailConfigurationURL, '_blank');
  }
  //MARK:-  UIs 

  imageViewPicker = id => {
    var fileMsg = 'Choose file 50*50';
    if (id['id'] == 0) {
      if (this.state.logoImage != null) {
        if (this.state.logoImage.fileList) {
          fileMsg =
            this.state.logoImage != null
              ? this.state.logoImage.fileList[0]['name']
              : fileMsg;
        }
      }
    }
    var msgTxt = id['id'] == 1 ? fileMsg : fileMsg;
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file => this.onPickFile(file, id['id'])}>
            <View style={{height: 5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height: 5}} />
          </ReactFileReader>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  renderSelectedImageView = id => {
    var image = null;
    if (id['id'] == 0) {
      if (this.state.logoImage != null && id['id'] == 0) {
        image = this.state.logoImage;
      }
    }
    if (image != null) {
      let fileName = image.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <TouchableOpacity onPress={() => this.cancelBtnAction(id['id'])}>
            <Image style={{width: 14, height: 14}} source={close_Icon} />
          </TouchableOpacity>
          <Image
            source={{uri: fileName != null ? image.base64 : image}}
            resizeMode={'contain'}
            style={appConfigStyle.SelectedImageStyle}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  render() {
    return (
      <View
        style={{
          width: itsMobileView ? '95%' : '70%',
          marginBottom: 50,
          marginLeft: 10,
        }}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={tableStyle.listTitleText}>Email Whitelabelling</Text>
          {PaidFeatureTextView()}
        </View>
        {/* <Text style={appConfigStyle.subTitleStyle}>
          Whenever you change the FROM EMAIL ADDRESS, it need to be verified by us in Amazon SNS console.
          Do submit a support ticket for it to work.
          <TouchableOpacity onPress={() => this.openLink()}>
            <Text style={{color:colors.blueColor}}> Submit Support Ticket</Text>
          </TouchableOpacity>
        </Text> */}
        {/* <View style={appConfigStyle.horizontalLineViewStyle} /> */}
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5, marginTop: 5}}>
            <View style={{marginTop: -20, zIndex: 1}}>
              <Text style={appConfigStyle.titleTxtStyle}>From name</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter from name "
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({fromName: name})}
                value={this.state.fromName}
              />
            </View>
            <View style={{marginTop: 30, zIndex: 1}}>
              <View
                style={{flexDirection: 'row', flex: 1.5, alignItems: 'center'}}>
                <Text style={appConfigStyle.titleTxtStyle}>
                  From email address
                </Text>
                <ToolTipViewBtn
                  title={'From email address'}
                  description={
                    'You have chosen a custom domain for your "From email address." To ensure your emails are delivered, please verify your domain with us. Contact our support team for assistance with the verification process.'
                  }
                />
              </View>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter from email address"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={email => this.setState({fromEmailAddress: email})}
                value={this.state.fromEmailAddress}
              />
            </View>
            <View style={{marginTop: 30}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                {' '}
                Email Header Logo{' '}
              </Text>
              <this.imageViewPicker id={0} />
              <this.renderSelectedImageView id={0} />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: '100%',
    zIndex: 2883,
  },
});
