import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Image,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import ReactFileReader from 'react-file-reader';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import { addMixpanelEvent, MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'iOS';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false



export default class iOSIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      hideShowPassword: false,
      bundleID: '',
      distCertificatePassword: '',
      pushKeyID: '',
      teamID: '',
      appleID: '',
      distCertificate: null,
      provisioningCertificate: null,
      pushKeyCertificate: null,
      appStoreLink: '',
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup},general`, 'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        bundleID: configs['bundle_id'] || '',
        appleID: configs['ios_app_id'] || '',
        distCertificatePassword: configs['dist_certificate_password'] || '',
        pushKeyID: configs['push_key_id'] || '',
        teamID: configs['team_id'] || '',
        provisioningCertificate: configs['dist_provisioning_profile'] || null,
        distCertificate: configs['dist_certificate'] || null,
        pushKeyCertificate: configs['ios_push_key'] || null,
        appStoreLink: configs['appstore_link'] || '',
      })
    }
    this.setState({dataLoad: true});
  };

  uploadFilesAPI = async () => {
    // console.log('calling 2 time');
    var imgParm = [];
    var uploadBase64 = [];
    if (this.state.distCertificate != null) {
      let fileName = this.state.distCertificate.base64;
      if (fileName != null) {
        var androidIconDict = {
          name: this.state.distCertificate['fileList'][0]['name'],
          type: 'file/mobileprovision',
          // image: this.state.selectedAppIconAndroid.base64,
        };
        uploadBase64.push({
          file: this.state.distCertificate.base64,
          key: 'dist_certificate',
        });
        imgParm.push(androidIconDict);
      }
    }
    if (this.state.provisioningCertificate != null) {
      let fileName = this.state.provisioningCertificate.base64;
      if (fileName != null) {
        var iosIconDict = {
          name: this.state.provisioningCertificate['fileList'][0]['name'],
          type: 'file/cer',
          // type: this.state.provisioningCertificate['fileList'][0]['type'],
          // image: this.state.selectedAppIconIos.base64,
        };
        uploadBase64.push({
          file: this.state.provisioningCertificate.base64,
          key: 'dist_provisioning_profile',
        });
        imgParm.push(iosIconDict);
      }
    }
    if (this.state.pushKeyCertificate != null) {
      let fileName = this.state.pushKeyCertificate.base64;
      if (fileName != null) {
        var iosIconDict = {
          name: this.state.pushKeyCertificate['fileList'][0]['name'].replace(/ /g,''),
          type: 'file/p8'
          // image: this.state.selectedAppIconIos.base64,
        };
        uploadBase64.push({
          file: this.state.pushKeyCertificate.base64,
          key: 'ios_push_key',
        });
        imgParm.push(iosIconDict);
      }
    }

    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        var uploadDataParm = [];

        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              imgParm[i]['type'],
              await res.blob(),
            );
            uploadIncrement++;
            // console.log('file_upload_res', file_upload_res);
            let fDict = {
              key_group: KeyGroup,
              key: uploadBase64[i]['key'],
              value: result[i]['fileUri'],
              secured: false,
            };
            // console.log('fDict =>', fDict);

            uploadDataParm.push(fDict);
            if (uploadIncrement === uploadBase64.length) {
              // console.log('uploadDataParm =>', uploadDataParm);
              this.addOrUpdateAppDetailApi(uploadDataParm);
            }
          });
        }
      } else {
        this.setState({dataLoad: true});
        // console.log('respo error', responseJson);
        this.alertView(responseJson);
      }
    } else {
      var uploadDataParm = [];
      var keyAry = ['dist_certificate', 'dist_provisioning_profile', 'ios_push_key'];
      var valueAry = [
        this.state.distCertificate || '',
        this.state.provisioningCertificate || '',
        this.state.pushKeyCertificate || '',
      ];
      for (let i = 0; i < keyAry.length; i++) {
        if (valueAry[i] != null && valueAry[i].length != 0) {
          // console.log('valueAry[i].length', valueAry[i].length);
          let fDict = {
            key_group: KeyGroup,
            key: keyAry[i],
            value: valueAry[i],
            secured: false,
          };
          uploadDataParm.push(fDict);
        }
      }
      this.addOrUpdateAppDetailApi(uploadDataParm);
    }
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    if (filesData != undefined){
      configs = filesData;
    }
    let valueArray = [
      this.state.bundleID,
      this.state.distCertificatePassword,
      this.state.pushKeyID,
      this.state.teamID,
      this.state.appStoreLink,
    ];
    let keysArray = ['bundle_id', 'dist_certificate_password', 'push_key_id', 'team_id','appstore_link'];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: i == 1 ? true : false,
        };
        // console.log('fDict =>', fDict);
        configs.push(fDict);
      }
    }
    if (this.state.appleID.length != 0) {
      let fDict = {
        key_group: 'general',
        key: 'ios_app_id',
        value: this.state.appleID,
        secured:false,
      };
      configs.push(fDict);
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,'post',  JSON.stringify({configs: configs}))
    // console.log('configs', responseJson);
    if (responseJson['status'] == true) {
        mobileIntegrationMixPanelEvent(MixpanelEvent.appstoreIntegration)
      this.deleteKeysAPI(configs);
    } else {
      this.setState({dataLoad: true})
      this.alertView(responseJson);
    } 
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = []
    for (let q = 0; q < parameterKeys.length; q++) {
      deleteParms.push(parameterKeys[q])
    }
    console.log(deleteParms,'deleteParms')

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete')
      // console.log('configs', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
  }
  onPickFile = (image, id) => {
    if (id == 0) {
      this.setState({distCertificate: image});
    } else if (id == 1) {
      this.setState({provisioningCertificate: image});
    } else {
      this.setState({pushKeyCertificate: image});
    }
  };
  downloadBtnAction = (pathURL) => {
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    }
  }
  cancelBtnAction = (id) => {
    console.log('id == ///',id);
    if (id == 0) {
      this.setState({distCertificate: null});
    } else if (id == 1) {
      this.setState({provisioningCertificate: null});
    } else {
      this.setState({pushKeyCertificate: null});
    }
  }
  //MARK:-  UIs 
  renderDownloadView = (data) => {
    if (data['bool']) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.downloadBtnAction(data['url'])}>
            <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppNewGreen}]}>Download</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.cancelBtnAction(data['id'])}>
            <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppRed}]}>Cancel</Text>
          </TouchableOpacity>
        </View>
      );
    }else {
      return <View />
    }
  }
  imageViewPicker = id => {
    var fileTypes = '';
    var fileMsg = 'Choose file';
    var showDownload = false;
    var URLLink = ''
    if (id['id'] == 0) {
      fileTypes = '';
      if (this.state.distCertificate != null) {
        if (this.state.distCertificate.fileList) {
          fileMsg =
            this.state.distCertificate != null
              ? this.state.distCertificate.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.distCertificate;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    } else if (id['id'] == 1) {
      fileTypes = '';
      if (this.state.provisioningCertificate != null) {
        if (this.state.provisioningCertificate.fileList) {
          fileMsg =
            this.state.provisioningCertificate != null
              ? this.state.provisioningCertificate.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.provisioningCertificate;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    } else if (id['id'] == 2) {
      fileTypes = '';
      if (this.state.pushKeyCertificate != null) {
        if (this.state.pushKeyCertificate.fileList) {
          fileMsg =
            this.state.pushKeyCertificate != null
              ? this.state.pushKeyCertificate.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.pushKeyCertificate;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    }

    var msgTxt = id['id'] == 1 ? fileMsg : fileMsg;
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={fileTypes}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file => this.onPickFile(file, id['id'])
            }>
            <View style={{height:5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height:5}} />
          </ReactFileReader>
          <this.renderDownloadView bool={showDownload} url={URLLink} id={id['id']}/>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Appstore Provisioning</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plain2TextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Bundle id</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter bundle id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={id => this.setState({bundleID: id})}
                value={this.state.bundleID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Distribution certificate password</Text>
              <View style={styles.customTxtFieldViewStyle}>
                <TextInput
                  style={[CommonStyleSheet.customTxtFieldStyle,{outline:0}]}
                  placeholder="Enter distribution certificate password"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={pwd => this.setState({distCertificatePassword: pwd})}
                  value={this.state.distCertificatePassword}
                  secureTextEntry={!this.state.hideShowPassword}
                />
                <TouchableOpacity onPress={() => this.setState({hideShowPassword: !this.state.hideShowPassword})}>
                  <Image
                    style={styles.hideShowIconStyle}
                    resizeMode="contain"
                    source={this.state.hideShowPassword ? showIcon : hideIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Push key id</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter push key id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={key => this.setState({pushKeyID: key})}
                value={this.state.pushKeyID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Team id</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter team id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={id => this.setState({teamID: id})}
                value={this.state.teamID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Apple id</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter apple id"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={id => this.setState({appleID: id})}
                value={this.state.appleID}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Distribution certificate
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={0} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Distribution provisioning profile
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={1} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Push key</Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={2} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Your App Store link</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter your app Appstore link"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={id => this.setState({appStoreLink: id})}
                value={this.state.appStoreLink}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  customTxtFieldViewStyle: {
    padding: 10,
    marginTop: 16,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.AppGreenBorder,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  hideShowIconStyle: {
    width: 15,
    height: 15,
    alignSelf: 'center',
  },
});

let parameterKeys = [
  'bundle_id',
  'dist_certificate_password',
  'push_key_id',
  'team_id',
  'ios_app_id',
  'dist_certificate',
  'dist_provisioning_profile',
  'ios_push_key',
  'appstore_link'
];
