import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Platform,
  Alert,
  SafeAreaView,
  Dimensions,
  Image,
  TouchableOpacity,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Chart from 'react-apexcharts'
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import branchesIcon from '../../assets/branchesIcon.png';
import orderIcon from '../../assets/orderIcon.png';
import driverIcon from '../../assets/driver.png';
import fleetsIcon from '../../assets/fleetsIcon.png';

import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import { getDateArray } from '../../HelperClasses/SingletonClass';
import { addMixpanelEvent, MixPannelEnum } from '../../Models/MixPannelEnum';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;

let fixedWidth = 800;
let itsMobileView = windowWidth < fixedWidth  ?  true : false


let datesArray = getDateArray


export default class DeliveryDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoad: false,
      subTitleAry: [],
      selectedDate:'',
      selectDateIndex: 0,
      series: [{data: []}],
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 180,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
        },
      },
      
    };
  }
  componentDidMount() {
    addMixpanelEvent(`${MixPannelEnum.view} ${MixPannelEnum.dashboard}`,{'type':'Chart View'})

    datesArray = getDateArray()
    this.loadChartApi()
    this.loadOrderCountApi()
  }
  //MARK:-  APIs Method 
  loadChartApi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriverDashboard,'get');
    if (responseJson['status'] == true) {
      let data = responseJson['data']
      this.state.subTitleAry.push(data['branches'] || 0);
      this.state.subTitleAry.push(data['fleets'] || 0)
      this.state.subTitleAry.push(data['drivers'] || 0)
      this.state.subTitleAry.push(data['orders'] || 0)
      this.state.subTitleAry.push(data['orders_delivered'] || 0)
      this.state.subTitleAry.push(data['orders_on_time'] || 0)
      this.setState({dataLoad: true})
    }
    this.setState({dataLoad: true})
  }
  loadOrderCountApi = async () => {
    this.setState({dataLoad: false})
    var selectedDate = ''
    if (this.state.selectDateIndex != -1) {
       selectedDate = datesArray[this.state.selectDateIndex]
    } else {
      selectedDate = datesArray[0]
    }
    let path = `?date_start=${selectedDate}&date_end=${datesArray[0]}`
    const responseJson = await networkService.networkCall(APPURL.URLPaths.deliveryDriverDashboardOrderCount + path,'get')
    this.state.series = []
    if (responseJson['status'] == true) {
      let orderCounts = responseJson['data']['order_counts']
      var totalAry = []
      var deliveredAry = []
      for (let obj of orderCounts) {
        let totalOrders = { x: obj['day'], y:obj['total']}
        totalAry.push(totalOrders)
        let deliveredOrders = { x: obj['day'], y:obj['delivered']}
        deliveredAry.push(deliveredOrders)
      }
      let ttDic = {
        'name':'Total Orders',
        'data':totalAry
      }
      let ddDic = {
        'name':'Delivered Orders',
        'data':deliveredAry
      }
      this.state.series.push(ttDic)
      this.state.series.push(ddDic)
      this.setState({dataLoad: true})
    }
    this.setState({dataLoad: true})
  }
  alertView(title){
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  backBtnHandler() {
    // this.setState({addPromoBannerBool: false})
  }
  didSelectDate(id){
    this.state.selectDateIndex = id
    this.loadOrderCountApi()
    this.setState({selectDateIndex: id})
  }

  //MARK:-   * UI  */

  renderDateButtons = () => {
    var views = []
    let titleAry = ['TD','1W','15D','1M', '6M', '1Y']
    for (let a=0; a<titleAry.length;a++){
      let check = this.state.selectDateIndex == a ? true : false
      views.push(<TouchableOpacity style={[styles.datesBtnStyle,{backgroundColor: check ? colors.BGBlueColor : colors.AppWhite}]} 
        onPress={() => this.didSelectDate(a)}>
        <Text style={[CommonStyleSheet.subTitleStyle,{color:check ? colors.AppWhite : colors.AppTitleBlack}]}>{titleAry[a]}</Text>
      </TouchableOpacity>)
    }
    return views
  }
  renderDailyChartView = () => {
    if (this.state.subTitleAry.length != 0) {
      return (<View style={styles.mainViewStyle}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginTop: 10, flex: 1, padding: 10 }}>
            <View style={styles.charViewStyle}>
              <View style={{ flexDirection: 'row'}}>
                {this.renderDateButtons()}
              </View>
              <View style={{height:20}}/>
              <Chart options={this.state.options} series={this.state.series} type="area" height={320} />
            </View>
          </View>
        </View>
      </View>)
    } else {
      return <View />
    }
  }
  renderReportView = () => {
    var view = [];
    for (let a = 0; a <= this.state.subTitleAry.length - 1; a++) {
      view.push(
        <View style={styles.settingViewsStyle}>
          <Image style={styles.iconImageViewStyle} resizeMode="center" source={titleArray[a]['image']}/>
          <View style={styles.subViewStyle}>
            <Text style={CommonStyleSheet.subTitleStyle}>{titleArray[a]['name']}</Text>
            <Text style={[CommonStyleSheet.plainTextStyle,{marginTop:10}]}>{this.state.subTitleAry[a]}</Text>
          </View>
        </View>,
      );
    }
    return view;
  }
  renderMainView = () => {
    return (<SafeAreaView style={styles.Container}>
      <View style={styles.mainView}>
        <View style={{ alignSelf: 'flex-start' }}>
          <Text style={tableStyle.listTitleText}>Dashboard</Text>
        </View>
        <ScrollView>
          <View style={styles.containerView}>
            <this.renderReportView />
          </View>
          <View style={{flex: 1, flexDirection: itsMobileView ? 'column' : 'row'}}>
            {this.renderDailyChartView()}
            {/* {this.renderYearlyChartView()} */}
          </View>
        </ScrollView>
      </View>
      <Appload enable={this.state.dataLoad} />
    </SafeAreaView>)
  }

  render() {
    return <this.renderMainView />
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  mainViewStyle: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'white',
  },
  mainView: {
    paddingLeft: 20,
    paddingRight:20,
    margin: 20,
    marginTop:0,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    height:itsMobileView ? '100%' : windowheight - 90,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignItems: 'center',
  },
  containerView: {
    flexDirection: itsMobileView ? 'column' : 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    width:itsMobileView ? windowWidth - 50 : windowWidth < fixedWidth ,
  },
  settingViewsStyle: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 80,
    width: 200,
    alignItems: 'center',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.4,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  subViewStyle: {
    flexDirection: 'column',
    flex:1,
    paddingLeft: 10
  },
  iconImageViewStyle: {
    height: 24,
    width: 24,
    marginLeft: 16,
  },
  charViewStyle: {
    width: itsMobileView ? windowWidth /1.3 : windowWidth / 1.1,
    height: 400,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    padding: 10,
    shadowColor: colors.Appgray,
    justifyContent:'flex-start',
    shadowOpacity: 0.4,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    marginLeft: 10,
  },
  datesBtnStyle: {
    height: 20,
    backgroundColor: colors.BGBlueColor,
    margin: 5,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:2,
    borderColor: colors.SimonGray,
    borderWidth:1
  }
})
const titleArray = [
  {'name': 'Branches', 'image':branchesIcon},
  {'name': 'Fleets', 'image':fleetsIcon},
  {'name': 'Drivers', 'image':driverIcon},
  {'name': 'Orders', 'image':orderIcon},
  {'name': 'Orders Delivered', 'image':orderIcon},
  {'name': 'Orders on Time', 'image':orderIcon},
]