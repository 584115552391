import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import Header_View from '../HeaderView';
import gTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import Developers from './Developer';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import HelperLinkViews from '../../Component/HelperLinkView';
import { ToastContainer} from 'react-toastify';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import AppColor from '../../HelperClasses/AppColor';


export default class DeveloperMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      writeP:AppConstants.defaultWrite,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.APIVIEW, res => {
      this.setState({ writeP: res[1]})
    })
  }
  //MARK:-  APIs Method 
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveButtonAction();
    }
  }
  didSelectMenuItem = index => {
    this.setState({subMenuSelectedIndex: index});
  };
  /*  UI   */

  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= subMenuTextArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex == a
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
          <Image style={SettingsStyleSheet.subMenuIconStyle} source={this.state.subMenuSelectedIndex == a ? greenTickIcon : subMenuImageArray[a]} />
          <Text style={this.state.subMenuSelectedIndex == a ? SettingsStyleSheet.subMenuSelectedtextStyle: SettingsStyleSheet.subMenutextStyle}>
             {subMenuTextArray[a]} </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };

  mainViewRender = () => {
    if (this.state.subMenuSelectedIndex == 0) {
      return <Developers ref={child => (this.child = child)} />;
    }
  };
  render() {
    // console.log('data load', this.state.updateUI);
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={'API Settings'}
          backBtn={this.props.backBtn}
          showSaveBtn={false}
          saveBtn={() => this.saveBtnAction()}
        />
        <View style={{flexDirection: 'column'}}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{flex: 2, backgroundColor: 'white'}}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <HelperLinkViews  title={'API'}/>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    backgroundColor:AppColor.AppLightGreen,
  },
});

let subMenuTextArray = ['Keys'];
let subMenuImageArray = [gTick_Icon];
