import React from 'react';

const SelectDropDown = ({
  className,
  list,
  selected_value,
  name,
  id,
  setValue,
  default_text,
  title,
  isRequired,
}) => {
  return (
    <div className="space-y-1 w-full  ">
      <p
        className={[
          ' text-gray-600',
          isRequired &&
            "flex-none relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[12px] ",
        ].join(' ')}>
        {' '}
        {title}
      </p>
      <select
        name={name}
        id={id}
        onChange={e => setValue(e.target.value)}
        className="form-input py-2 w-full">
        <option selected disabled>
          {default_text}
        </option>
        {list.map(li => {
          return (
            <option selected={selected_value === li.value} value={li.value}>
              {li.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectDropDown;
