import React from 'react';
import {getThumbnailImage} from '../HelperClasses/SingletonClass';
import placeholder from '../assets/pPlaceholder.png';

const UserDetails = ({user_details}) => {
  let mobileNo = user_details?.mobile || '';
  let fileURl = user_details?.profile_pic
    ? getThumbnailImage(user_details?.profile_pic ?? '')
    : placeholder;
  return (
    <div className=" p-[25px]">
      <img src={fileURl} className=" h-[100px] w-[100px] rounded-full" alt="" />

      <div className="mt-5 space-y-[10px]">
        <div className=" flex items-center justify-start gap-2">
          <span className=" min-w-14">Name:</span>
          <span>{`${user_details.first_name ?? ''} ${
            user_details.last_name ?? ''
          }`}</span>
        </div>
        <div className=" flex items-center justify-start gap-2">
          <span className=" min-w-14">Email:</span>
          <span>{user_details?.email ?? 'N/A'}</span>
        </div>
        <div className=" flex items-center justify-start gap-2">
          <span className=" min-w-14">Mobile:</span>
          <span>
            {user_details?.mobile
              ? `${user_details.dial_code}${user_details?.mobile}`
              : 'N/A'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
