import networkService from '../Network/NetworkManager';
const APPURL = require('../Constants/URLConstants');

// const uploadImage = async ({imgParm}) => {
//   const responseJson = await networkService.networkCall(
//     APPURL.URLPaths.S3signedUploadURL,
//     'POST',
//     JSON.stringify({files: imgParm}),
//   );
//   if (responseJson['status'] == true) {
//     var result = responseJson['data']['result'];
//     fetch(this.state.selectedImage.base64).then(async res => {
//       const file_upload_res = await networkService.uploadFileWithSignedURL(
//         result[0]['signedUrl'],
//         imgParm[0]['type'],
//         await res.blob(),
//       );
//       this.addCategoryApi(result[0]['fileUri']);
//     });
//   } else {
//     this.setState({dataLoad: true});
//   }
// };

const uploadImage = async ({files, full_files}) => {
  try {
    const urls = [];

    const response = await networkService.networkCall(
      APPURL.URLPaths.S3signedUploadURL,
      'POST',
      JSON.stringify({files: files}),
    );

    if (!response.error) {
      const all_path_response = response.data.result;

      for (let index = 0; index < all_path_response.length; index++) {
        const path = all_path_response[index];
        const res = await fetch(path?.signedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': full_files[index].type, // Fixed the header name
          },
          body: full_files[index],
        });

        if (res.status === 200) {
          urls.push(path?.fileUri);
        }
      }
    }

    return urls;
  } catch (error) {
    // console.error('Error:', error)
    // // You might want to handle the error appropriately, e.g., throw it or return a default value
    // throw error
  }
};

export default uploadImage;
