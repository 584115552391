/* eslint-disable react/prop-types */
import React from 'react';

import PricingItems from './PricingItems';
import {currentOrderStatus} from '../../../Constants/Status';
import {changeDateFormat} from '../../../HelperClasses/SingletonClass';

const OrderSummary = ({
  order_details,
  setShowError,
  setError_message,
  setShowSuccess,
  setSuccess_message,
}) => {
  return (
    <div>
      <div className="w-full h-min-[300px] bg-white  shadow-c-sm rounded-card   p-4   sm:p-7  border-opacity-40">
        <div className="flex justify-between items-center">
          <p className=" text-lg text-black font-semibold   ">Order Summary</p>
          {(order_details?.legacy || order_details?.order_status == 6) && (
            <p className="     rounded-button text-primary font-semibold">
              {currentOrderStatus(order_details?.order_status)}
            </p>
          )}
        </div>
        <div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">Order created</p>
            <p className=" text-sm text-black font-semibold   text-opacity-70">
              {changeDateFormat(order_details?.created_at, 'DD/MM/YYYY')}
            </p>
          </div>
          <div className=" flex justify-between items-center py-2  ">
            <p className=" text-sm text-black font-semibold  ">Order time</p>
            <p className=" text-sm text-black font-semibold   text-opacity-70">
              {changeDateFormat(order_details?.created_at, 'hh:mm A')}
            </p>
          </div>
          <PricingItems order_details={order_details} />
        </div>
      </div>
      {/* {order_details?.legacy && order_details?.next_status.length > 0 && (
        <div className="w-full h-min-[100px] bg-white  shadow-c-sm rounded-button  p-4   sm:p-7   border-opacity-40 flex justify-between items-center mt-5 gap-3">
          <span className=" text-lg text-primary font-semibold  whitespace-nowrap">
          Change Order Status :
          </span>

          <select
            className="
                    block
                      w-[50%] sm:max-w-[200px]
                    
                    rounded-button
                    
                    border-primary
                     text-primary
                  "
            onChange={e => status_change(e, order_details)}>
            <option value={false} selected hidden>
              {General_text.general.select}
            </option>
            {order_details?.next_status.length > 0 ? (
              order_details?.next_status.map(status => {
                return (
                  <option key={Math.random()} value={status}>
                    {changeStatus(status)}
                  </option>
                );
              })
            ) : (
              <option disabled>{Orders_text.orderdetail.no_status}</option>
            )}
          </select>
        </div>
      )} */}
    </div>
  );
};

export default OrderSummary;
