import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Image,
  FlatList,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import {onHandleNumberFieldChange} from '../../HelperClasses/SingletonClass';
import Switch from 'react-switch';
import {addMixpanelEvent, MixPannelEnum} from '../../Models/MixPannelEnum';
import SelectSearchView from 'react-select';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import {Gradient} from 'react-gradient';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import SaveButton from '../../Component/SaveButton';
import AppConstants from '../../Constants/AppConstants';
import ReactFileReader from 'react-file-reader';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import {ICONS} from '../../Constants/Icons';
import ImagePicker from 'react-native-image-crop-picker';

const APPURL = require('../../Constants/URLConstants');
const APPConstants = require('../../Constants/AppConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class AddLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      status: true,
      default: false,
      editDefalut: false,
      dataLoad: false,
      id: '',
      rank: '',
      LanguageArray: [],
      updatingBool: false,
      selectedLanguage: '',
      selectedLanguageID: '',
      code: '',
      selectedLanguageArray: [],
      selectedImage: null,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    languageData: PropTypes.any,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    const catData = this.props.languageData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (!this.state.updatingBool) {
        this.setState({
          status: catData['active'],
          default: catData['default'],
          id: catData['id'],
          rank: catData['order_by'],
          code: catData['code'],
          selectedImage: catData['image_path'],
          updatingBool: true,
        });
      }
    }
    this.loadLanguageApi();
  }
  //MARK:- Api Methods
  loadLanguageApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.language,
      'get',
    );
    this.setState({LanguageArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['languages'];
      for (let obj of objectD) {
        var catData = obj;
        catData['label'] = obj['name'];
        catData['value'] = obj['id'];
        this.state.LanguageArray.push(catData);
      }
      if (this.state.LanguageArray.length != 0) {
        if (this.state.id !== '') {
          let index = this.state.LanguageArray.findIndex(
            x => x.code == this.state.code,
          );
          if (index != -1) {
            let item = this.state.LanguageArray[index];
            this.state.selectedLanguageArray = item;
            this.setState({
              selectedLanguage: item['name'],
              selectedLanguageID: item['id'],
            });
          }
        } else {
          let item = this.state.LanguageArray[0];
          this.state.selectedLanguageArray = item;
          this.setState({
            selectedLanguage: item['name'],
            selectedLanguageID: item['id'],
          });
        }
      }
    }
    this.setState({dataLoad: true});
  };
  addLanguageApi = async image_path => {
    var dict = {
      language_id: this.state.selectedLanguageID,
      active: this.state.status,
      default: this.state.default,
    };
    if (this.state.rank != 0) {
      dict['order_by'] = this.state.rank;
    }
    if (image_path) {
      dict.image_path = image_path;
    }

    var path = APPURL.URLPaths.tanantslanguage;

    if (this.state.id !== '') {
      path = APPURL.URLPaths.tanantslanguage + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({language: dict}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.props.backBtnAction(true);
      this.customAlert();
    } else {
      this.mixpanelInfo(false);
      // this.alertView(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(
      `${this.state.id !== '' ? 'Edit' : 'Add'} ${MixPannelEnum.language}`,
      properties,
    );
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  submitBtnAction = () => {
    this.setState({dataLoad: false});
    this.uploadImageAPI();
  };
  didSelectDropDown = item => {
    // console.log('index', item);
    this.setState({
      showDropDown: !this.state.showDropDown,
      selectedLanguage: item['name'],
      selectedLanguageID: item['id'],
    });
  };
  handleSelectChange = selectedOption => {
    this.state.selectedLanguageArray = selectedOption;
    this.setState({
      selectedLanguage: selectedOption['name'],
      selectedLanguageID: selectedOption['id'],
    });
    this.setState({updateUI: !this.state.updateUI});
  };

  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['fileList'][0]['name'].replace(
            / /g,
            '',
          ),
          type: this.state.selectedImage['fileList'][0]['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          this.addLanguageApi(result[0]['fileUri']);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.addLanguageApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };
  // UI Renders
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderSaveConfigBtn = () => {
    return (
      <View>
        <SaveButton
          saveBtn={() => this.submitBtnAction()}
          saveBtnTitle={'Save'}
        />
      </View>
    );
  };

  onPick = image => {
    this.setState({selectedImage: image});
  };
  imagePicker = props => {
    ImagePicker.openPicker({
      width: 300,
      height: 300,
      cropping: true,
      includeBase64: true,
    }).then(image => {
      // console.log('image -=== ', image);
      this.setState({selectedImage: 'data:image/png;base64,' + image.data});
    });
  };

  imageView = props => {
    var view = [];
    view.push(
      <View style={{flexDirection: 'row'}}>
        {this.state?.selectedImage ? (
          <View>
            <View
              style={{
                width: itsMobileView ? 20 : 0,
                height: itsMobileView ? 20 : 0,
              }}
            />
            <Image
              source={
                this.state.selectedImage
                  ? this.state.selectedImage.base64
                    ? this.state.selectedImage.base64
                    : this.state.selectedImage
                  : this.state.selectedImage
              }
              style={commonStyle.ImageViewStyle}
            />
            <TouchableOpacity
              style={CommonStyleSheet.cancelViewStyle}
              onPress={() => {
                this.setState({
                  selectedImage: null,
                });
              }}>
              <Image
                source={ICONS.closeIcon}
                style={CommonStyleSheet.closeBtnStyle}
              />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={commonStyle.addImageViewStyle}>
            <ReactFileReader
              fileTypes={['.png', '.jpg', '.gif', '.svg']}
              base64={true}
              handleFiles={this.onPick}>
              <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
                Add Photo
              </Text>
            </ReactFileReader>
          </View>
        )}
      </View>,
    );

    return (
      <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
        {view}
      </View>
    );
  };
  render() {
    const isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <View style={commonStyle.sidepanSaveBtnViewStyle}>
          {this.renderSaveConfigBtn()}
        </View>
        <ScrollView>
          <View style={[commonStyle.sidepanmViewStyle]}>
            <View style={{height: 15, width: '100%'}} />

            <View
              style={[commonStyle.configContainerViewStyle, {zIndex: 10010}]}>
              <this.imageView />
              <this.titleLblRender title={'Language'} />
              <View style={{width: '100%'}}>
                <SelectSearchView
                  value={this.state.selectedLanguageArray}
                  onChange={itm => this.handleSelectChange(itm)}
                  options={this.state.LanguageArray}
                  isMulti={false}
                  placeholder={`Select Language`}
                  styles={colourStyles}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: colors.AppGreenBorder,
                      primary: colors.AppGreenBorder,
                    },
                  })}
                />
              </View>
            </View>
            <View style={{height: 5}} />
            <View style={commonStyle.configContainerViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Order</Text>
              <TextInput
                style={[
                  commonStyle.txtFieldStyle,
                  {outline: 0, marginLeft: 0, width: '100%'},
                ]}
                value={this.state.rank}
                placeholder="Enter order"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={txt =>
                  this.setState({rank: onHandleNumberFieldChange(txt)})
                }
              />
            </View>
            <View style={{height: 20}} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Status</Text>
              <View style={{width: itsMobileView ? '12%' : '14%'}}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({status: !this.state.status})}
                  checked={this.state.status}
                  height={22}
                  width={50}
                />
              </View>
            </View>
            <View style={{height: 20}} />
            <View style={commonStyle.switchViewStyle}>
              <Text style={commonStyle.titleNameStyle}>Default</Text>
              <View
                style={{
                  width: itsMobileView ? '12%' : '14%',
                  flexDirection: 'row',
                }}>
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({default: !this.state.default})}
                  checked={this.state.default}
                  height={22}
                  width={50}
                />
                <View style={{width: 20}} />
                <Text style={{textAlign: 'left', color: colors.AppRed}}>
                  {this.state.editDefalut ? APPConstants.languageMessage : ''}
                </Text>
              </View>
            </View>
            <View style={{height: 20, width: '100%'}} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
});
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
