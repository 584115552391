import React, {useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  TextInput,
  TouchableOpacity,
  Image,
  FlatList,
} from 'react-native';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import colors from '../../HelperClasses/AppColor';
import AppConstants from '../../Constants/AppConstants';
import DescriptionView from '../../Component/DescriptionView';
import ImageSelection from '../../Shared/ImageSelection';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import {collections_styles} from './CollectionStyles';

// Global scope variables
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const CollectionDetails = ({
  collection_state = {},
  setCollectionState,
  selectedImage,
  setSelectedImage,
  selectedSearchArray,
  setSelectedSearchArray,
}) => {
  // destructure state
  const {
    expirable,
    medium,
    images,
    scope_type,
    condition_type,
    manual_ids,
    start_at,
    end_at,
    active,
    view_type,
    background_color,
    background_image,
    conditions,
    order_by,
    title,
    description,
    slug,
  } = collection_state;

  // handleChangeValue
  const handleChangeValue = ({value, key_name}) => {
    setCollectionState(prev => ({...prev, [key_name]: value}));
  };

  // this local component State
  const [showDropDown, setShowDropDown] = useState(false);

  //renderScopeDropDown
  const renderScopeDropDown = ({item}) => {
    return (
      <TouchableOpacity
        onPress={() => {
          setCollectionState(prev => ({
            ...prev,
            scope_type: item.value,
            conditions: {},
            condition_type: 2,
            manual_ids: [],
          }));
          setSelectedSearchArray([]);
          setShowDropDown(!showDropDown);
        }}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Text
            style={[CommonStyleSheet.plainSubTextStyle, {textAlign: 'left'}]}>
            {item.label}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  //

  return (
    <View style={{width: '100%', zIndex: 90000000}}>
      <Text style={CommonStyleSheet.plainTextStyle}>Collection details</Text>
      <View>
        <View style={{margin: 16}}>
          <Text style={AppConfigStyleSheet.titleTxtStyle}>Image</Text>
          <View style={{width: '80%', marginTop: '16px'}}>
            <ImageSelection
              selectedImage={selectedImage}
              setSelectedImage={value => setSelectedImage(value)}
            />
          </View>
        </View>
      </View>
      <View style={collections_styles.firstViewStyle}>
        <View style={{margin: 16}}>
          <Text style={AppConfigStyleSheet.titleTxtStyle}>Title</Text>
          <TextInput
            style={[AppConfigStyleSheet.txtFieldStyle, {outline: 0}]}
            placeholder="Enter title name"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={txt =>
              handleChangeValue({value: txt, key_name: 'title'})
            }
            value={title}
          />
        </View>
        <View style={{margin: 16}}>
          <Text style={AppConfigStyleSheet.titleTxtStyle}>Slug</Text>
          <TextInput
            style={[AppConfigStyleSheet.txtFieldStyle, {outline: 0}]}
            placeholder="Enter slug"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={txt =>
              handleChangeValue({value: txt, key_name: 'slug'})
            }
            value={slug}
          />
        </View>
        <View style={{margin: 16}}>
          <Text style={AppConfigStyleSheet.titleTxtStyle}>Description </Text>
          <DescriptionView
            value={description}
            onChange={txt =>
              handleChangeValue({value: txt, key_name: 'description'})
            }
          />
        </View>
        <View style={{margin: 16}}>
          <Text style={AppConfigStyleSheet.titleTxtStyle}>Scope </Text>
          <TouchableOpacity
            onPress={() => setShowDropDown(!showDropDown)}
            style={collections_styles.dropDownView}>
            <Text style={{margin: 10}}>
              {scope_type == 1 ? 'Accounts' : 'Listings'}
            </Text>
            <Image
              style={
                showDropDown == true
                  ? AppConfigStyleSheet.upArraowIconStyle
                  : AppConfigStyleSheet.downArraowIconStyle
              }
              resizeMode="contain"
              source={downArrow_Icon}
            />
          </TouchableOpacity>
          {showDropDown && (
            <View style={collections_styles.dropDownViewStyle}>
              <FlatList
                data={
                  AppConstants.appType
                    ? [{label: 'Listings', value: 4}]
                    : [
                        {label: 'Accounts', value: 1},
                        {label: 'Listings', value: 4},
                      ]
                }
                horizontal={false}
                renderItem={renderScopeDropDown}
                extraData={scope_type}
                showsVerticalScrollIndicator={false}
                scrollEnabled={true}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default CollectionDetails;
