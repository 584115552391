import React from 'react';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const CardWithButton = ({
  button_type = 'primary',
  component_styles,
  data,
  oncClickHandler,
}) => {
  return (
    <div
      className={`px-4  md:px-8 py-6 border border-gray-200 rounded space-y-3  bg-[#FAFBFB]  w-full ${component_styles}`}>
      <h3 className=" text-2xl   font-extralight   text-black ">
        {data?.title}
      </h3>
      <p className="  text-xs md:text-base">{data?.description}</p>

      {button_type == 'primary' && (
        <PrimaryButton onClick={oncClickHandler}>
          {data?.btn_title}
        </PrimaryButton>
      )}
      {button_type === 'secondary' && (
        <SecondaryButton onClick={oncClickHandler}>
          {data?.btn_title}
        </SecondaryButton>
      )}
    </div>
  );
};

export default CardWithButton;
