import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Platform,
  Alert,
  Image,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import ReactFileReader from 'react-file-reader';
import base64 from 'react-native-base64';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import checked_Icon from '../../assets/checkedIcon.png';
import unchecked_Icon from '../../assets/uncheckedIcon.png';
import { addMixpanelEvent, MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';
import { PaidFeatureTextView } from '../../Component/CommonComponents';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';


const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'firebase';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false



export default class FirebaseIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      updateUI: false,
      firebaseAccountString: '',
      androidJsonFile: null,
      iosPlistFile: null,
      switchArray: [false, false, false, false, false, false],
      dataLoad: false,
      saveBtnCallBack: false,
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,
      'get', '',  '',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.state.switchArray[0] =
      configs['create_firebase_project'] || false;
      this.state.switchArray[1] =
      configs['create_android_app'] || false;
      this.state.switchArray[2] =
      configs['create_ios_app'] || false;
      this.state.switchArray[3] =
      configs['create_realtime_database'] || false;
      this.state.switchArray[4] =
      configs['apply_realtime_database_rules'] || false;
      this.setState({
        iosPlistFile: configs['ios_app_google_plist'] || null,
        androidJsonFile: configs['android_app_google_json'] || null,
        firebaseAccountString: configs['firebase_service_account'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  uploadFilesAPI = async () => {
    // console.log('calling 2 time');
    var imgParm = [];
    var uploadBase64 = [];
    if (this.state.androidJsonFile != null) {
      let fileName = this.state.androidJsonFile.base64;
      if (fileName != null) {
        var androidIconDict = {
          name: this.state.androidJsonFile['fileList'][0]['name'],
          type: this.state.androidJsonFile['fileList'][0]['type'],
          // image: this.state.selectedAppIconAndroid.base64,
        };
        uploadBase64.push({
          file: this.state.androidJsonFile.base64,
          key: 'android_app_google_json',
        })
        imgParm.push(androidIconDict)
      }
    }
    if (this.state.iosPlistFile != null) {
      let fileName = this.state.iosPlistFile.base64;
      if (fileName != null) {
        var iosIconDict = {
          name: this.state.iosPlistFile['fileList'][0]['name'].replace(/ /g,''),
          type: 'application/plist',
          // image: this.state.selectedAppIconIos.base64,
        };
        uploadBase64.push({
          file: this.state.iosPlistFile.base64,
          key: 'ios_app_google_plist',
        });
        imgParm.push(iosIconDict);
      }
    }

    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        var uploadDataParm = [];

        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              imgParm[i]['type'],
              await res.blob(),
            );
            uploadIncrement++;
            let fDict = {
              key_group: KeyGroup,
              key: uploadBase64[i]['key'],
              value: result[i]['fileUri'],
              secured: false,
            }

            uploadDataParm.push(fDict);
            if (uploadIncrement === uploadBase64.length) {
              this.addOrUpdateAppDetailApi(uploadDataParm);
            }
          });
        }
      } else {
        this.setState({dataLoad: true});
        // console.log('respo error', responseJson);
        this.alertView(responseJson);
      }
    } else {
      var uploadDataParm = []
      this.addOrUpdateAppDetailApi(uploadDataParm)
    }
  };
  addOrUpdateAppDetailApi = async filesData => {
    var configs = []
    if (filesData != undefined){
      configs = filesData
    }
    console.log('configs ==>>> ', configs)
    for (let i = 0; i < keysArray.length; i++) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: this.state.switchArray[i],
          secured: false,
        }
        configs.push(fDict)
    }
    if (this.state.firebaseAccountString.length != 0) {
      let fDict2 = {
        key_group: KeyGroup,
        key: 'firebase_service_account',
        value: this.state.firebaseAccountString,
        secured: true,
      }
      configs.push(fDict2)
    }
    if (configs.length != 0) {
      const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }))
      this.setState({ dataLoad: true })
      if (responseJson['status'] == true) {
        mobileIntegrationMixPanelEvent(MixpanelEvent.firebaseIntegration)
        this.deleteKeysAPI(configs);
      } else {
        this.setState({ dataLoad: true });
      }
    } else {
      this.deleteKeysAPI(configs)
    }
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = []
    for (let q = 0; q < deleteKeysArray.length; q++) {
      deleteParms.push(deleteKeysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
    // }
  }
  switchSattusBtnAction(id) {
    this.state.switchArray[id] = !this.state.switchArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  onReadFile = (image, id) => {
    // console.log('onPick =>', image, id);
    let ab1 = image.base64;
    let ab2 = ab1.replace('data:application/json;base64,', '');
    let ab3 = base64.decode(ab2);
    // console.log('ab3', ab3);
    this.setState({firebaseAccountString: ab3});
  };
  onPickFile = (image, id) => {
    // console.log('onPickSplash =>', image);
    if (id == 1) {
      this.setState({androidJsonFile: image});
    } else {
      this.setState({iosPlistFile: image});
    }
  };
  downloadBtnAction = (pathURL) => {
    // console.log('pathURL', pathURL);
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    }
  }
  cancelBtnAction = (id) => {
    if (id == 1) {
      this.setState({androidJsonFile: null})
    }else {
      this.setState({iosPlistFile: null})
    }
  }
  //MARK:-  UIs 
  renderAllSwitches = props => {
    var views = [];
    for (let i = 0; i < switchTitleStrings.length; i++) {
      views.push(
        <View style={{flexDirection: 'row', marginTop: 20}}>
          <TouchableOpacity
            style={{flexDirection: 'row', alignItems: 'center'}}
            onPress={() => this.switchSattusBtnAction(i)}>
            <Image
              style={styles.checkBoxViewStyle}
              resizeMode="contain"
              source={ this.state.switchArray[i] ? checked_Icon : unchecked_Icon}
            />
          </TouchableOpacity>
          <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[i]}</Text>
        </View>,
      );
    }
    return views;
  };
  imageViewPicker = id => {
    var fileTypes = '.json';
    var fileMsg = 'Choose file';
    var showDownload = false;
    var URLLink = ''
    if (id['id'] == 1) {
      fileTypes = '.json';
      if (this.state.androidJsonFile != null) {
        if (this.state.androidJsonFile.fileList){
          fileMsg =
            this.state.androidJsonFile != null
              ? this.state.androidJsonFile.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.androidJsonFile;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    } else if (id['id'] == 2) {
      fileTypes = '.plist';
      if (this.state.iosPlistFile != null) {
        if (this.state.iosPlistFile.fileList){
          fileMsg =
            this.state.iosPlistFile != null
              ? this.state.iosPlistFile.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.iosPlistFile;
          URLLink = uri
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1)
          showDownload = true
        }
      }
    } else if (id['id'] == 0) {
      fileTypes = '.json';
      fileMsg = this.state.firebaseAccountString != '' ? 'File uploaded' : fileMsg;
      showDownload = this.state.firebaseAccountString != '' ? true : false;
    }
    var msgTxt = id['id'] == 1 ? fileMsg : fileMsg;
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={fileTypes}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file =>
              id['id'] == 0
                ? this.onReadFile(file)
                : this.onPickFile(file, id['id'])
            }>
            <View style={{height:5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height:5}} />
          </ReactFileReader>
          <this.renderDownloadView bool={showDownload} url={URLLink} id={id['id']}/>
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  renderDownloadView = (data) => {
    if (data['bool']) {
      if (data['id'] == 0) {
        return (
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.setState({firebaseAccountString: ''})}>
            <Text style={[TableCommonStyleSheet.saveBtnTxtStyle,{ color: colors.AppRed}]}>Cancel</Text>
          </TouchableOpacity>)
      } else {
        return (
          <View style={{flexDirection: 'row'}}>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.downloadBtnAction(data['url'])}>
            <Text style={[TableCommonStyleSheet.saveBtnTxtStyle,{ color: colors.AppNewGreen}]}>Download</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.cancelBtnAction(data['id'])}>
            <Text style={[TableCommonStyleSheet.saveBtnTxtStyle,{color: colors.AppRed}]}>Cancel</Text>
          </TouchableOpacity>
          </View>
        );
      }
    } else {
      return <View />
    }
  }
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <View style={{ flexDirection: 'row',alignItems:'center' }}>
          <Text style={tableStyle.listTitleText}>Firebase integration</Text>
          {PaidFeatureTextView()}
        </View>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plain2TextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', marginTop: -50, flex: 1}}>
            <View style={{marginTop: 0}}>
              <this.renderAllSwitches />
            </View>
            <View style={{marginTop: 50}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Firebase service account
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={0} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Android app json file
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={1} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                iOS app plist file
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={2} />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  checkBoxViewStyle: {
    width: 20,
    height: 20,
    margin: 10,
  },
});

const switchTitleStrings = [
  'Create firebase project',
  'Create android app',
  'Create iOS app',
  'Create realtime database',
  'Apply firebase database rules',
];
let keysArray = [
  'create_firebase_project',
  'create_android_app',
  'create_ios_app',
  'create_realtime_database',
  'apply_realtime_database_rules',
];
let deleteKeysArray = [
  'create_firebase_project',
  'create_android_app',
  'create_ios_app',
  'create_realtime_database',
  'apply_realtime_database_rules',
  'ios_app_google_plist',
  'android_app_google_json',
  'firebase_service_account',
];