import React from 'react';

const NavigationHeader = ({children, className}) => {
  return (
    <div
      className={[
        'w-full bg-white  px-2 py-3 rounded-md  h-auto  shadow-md sticky top-0 z-50',
        className,
      ].join(' ')}>
      {children}
    </div>
  );
};

export default NavigationHeader;
