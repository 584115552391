import React from 'react';
import {useNavigate, useLocation, Outlet} from 'react-router-dom';
import CategoryList from './CategoryList';

const CategoryList2 = ({type}) => {
  const {pathname} = useLocation();
  return (
    <>
      <CategoryList type={type} />
      {pathname.includes('listing-category/translation') && (
        <div className=" m-0  fixed w-full h-screen overflow-y-auto z-50 top-0 left-0 right-0 bg-black/5 flex justify-end ">
          <div className="  w-[95%] md:w-[80%]  h-full bg-white  p-6   ">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryList2;
