import React, {Component} from 'react';
import {StyleSheet, View, Text, TextInput, Dimensions} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import {
  addMixpanelEvent,
  MixpanelEvent,
  revenuModelMixPanelEvent,
} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');

let keyGroup = 'listings';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class SpecialFees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      specialFeesFixed: '',
      specialFeesPercentage: '',
      regexp: /^[0-9\.]+$/,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=' + keyGroup,
      'get',
    );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        specialFeesFixed: configs['listing_special_fee_fixed'],
        specialFeesPercentage: configs['listing_special_fee_percent'],
      });
      // console.log('this.state.configs =>', configs);
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateApi = async () => {
    var configs = [];
    var valueArray = [
      Number(this.state.specialFeesFixed) || '',
      Number(this.state.specialFeesPercentage) || '',
    ];
    console.log(this.state.specialFeesFixed, 'valueArray', valueArray);
    for (let a = 0; a < valueArray.length; a++) {
      if (valueArray[a].length != 0) {
        let fDict = {
          key_group: keyGroup,
          key: keyStringArray[a],
          value: valueArray[a],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      revenuModelMixPanelEvent(MixpanelEvent.specialFees);
      this.deleteKeysAPI(configs);
    } else {
      this.deleteKeysAPI(configs);
      this.setState({dataLoad: true});
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keyStringArray.length; q++) {
      deleteParms.push(keyStringArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    let parms = deleteParms.toString();
    let urlParm = APPURL.URLPaths.config + '?key=' + parms;
    const responseJson = await networkService.networkCall(
      urlParm,
      'delete',
      '',
      '',
    );
    this.setState({dataLoad: true});
    this.alertView('Uploaded successfully');
  };
  alertView(title) {
    AppToast();
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateApi();
  }
  onHandleTextChange = (id, text) => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      if (id == 1) {
        this.setState({specialFeesFixed: text});
      } else {
        this.setState({specialFeesPercentage: text});
      }
    }
  };
  //MARK:-  UI 
  render() {
    return (
      <View
        style={{
          width: itsMobileView ? '95%' : '70%',
          marginBottom: 130,
          marginLeft: 10,
        }}>
        <Text style={tableStyle.listTitleText}>Special Fees</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Special fees fixed{' '}
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter special fees (example: 5)"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(1, text)}
                value={this.state.specialFeesFixed}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Special fees percentage{' '}
              </Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter special fee (example: 10%)"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={text => this.onHandleTextChange(2, text)}
                value={this.state.specialFeesPercentage}
              />
            </View>
            <View style={{marginTop: 50}}>
              <Text style={CommonStyleSheet.text16SubTitleStyle}>Example</Text>
              <Text
                style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 10}]}>
                You can add special fee on top of listing price. For example: A
                listed product is $100. You can add two additional fee per
                listing.
              </Text>
              <Text
                style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 10}]}>
                Additional Fixed price = $10
              </Text>
              <Text
                style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 10}]}>
                Additional Fixed % = 10%
              </Text>
              <Text
                style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 10}]}>
                Final price is $120
              </Text>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  verifyEmailStyle: {
    height: 30,
    width: 150,
    backgroundColor: colors.AppNewGreen,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
});

let keyStringArray = [
  'listing_special_fee_fixed',
  'listing_special_fee_percent',
];
