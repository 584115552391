import React, {useState, useEffect, useRef} from 'react';

const MultiSelectFromList = ({
  list,
  selected_value,
  name,
  id,
  setValue,
  default_text,
  isRequired,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selected_value || []);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectItem = item => {
    let newSelectedItems;
    if (selectedItems.includes(item?.value)) {
      newSelectedItems = selectedItems.filter(i => i !== item?.value);
    } else {
      newSelectedItems = [...selectedItems, item.value];
    }
    setSelectedItems(newSelectedItems);
    setValue(newSelectedItems);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedLabels = selectedItems
    .map(item => list.find(i => i.value === item)?.label)
    .filter(Boolean)
    .join(', ');

  return (
    <div className="relative w-full space-y-2" ref={dropdownRef}>
      <p
        className={[
          ' text-gray-600',
          isRequired &&
            "flex-none relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[12px] ",
        ].join(' ')}>
        {title}
      </p>
      <div
        name={name}
        id={id}
        onClick={handleToggleDropdown}
        className="w-full border rounded-md border-gray-300 p-2 cursor-pointer">
        {selectedItems.length > 0 ? selectedLabels : default_text}
      </div>
      {isOpen && (
        <div className="absolute top-full left-0 w-full border rounded-md border-gray-300 bg-white mt-1 z-10">
          <ul className="max-h-60 overflow-y-auto">
            {list.map((item, index) => (
              <li key={index} className="p-2 border-b border-gray-200">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item.value)}
                    onChange={() => handleSelectItem(item)}
                    className="form-checkbox"
                  />
                  <span>{item.label}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectFromList;
