import React from 'react';
import Table from '../UI/Table/Table';
import {listing_table_columns} from '../Constants/ListingListColumns';
import CopyButton from '../UI/Buttons/CopyButton';
import AppConstants from '../Constants/AppConstants';
import {get_listing_path} from '../utils/getListingPath';
import {getThumbnailImage} from '../HelperClasses/SingletonClass';
import placeholder from '../assets/pPlaceholder.png';
import DeleteButton from '../UI/Buttons/DeleteButton';
import networkService from '../Network/NetworkManager';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import EditButton from '../UI/Buttons/EditButton';
import {CONFIG_CONSTANT} from '../Constants/ConfigsConstants';
import ApproveButton from '../UI/Buttons/ApproveRejectButton';
const APPURL = require('../Constants/URLConstants');

const ListingsList = ({
  onListingClick,
  listing_list,
  setLoading,
  onLoadListing,
  onListingEditClick,
}) => {
  const listingTableColumns =
    CONFIG_CONSTANT.SUPERADMIN?.listing_columns ?? listing_table_columns;

  // delete listing
  const deleteListingApi = async listingID => {
    const confirm = window.confirm('Are you sure you want to this listing?');
    if (confirm == true) {
      setLoading(true);
      var path = APPURL.URLPaths.listingsModerate + '/' + listingID;
      const responseJson = await networkService.networkCall(path, 'delete');
      if (responseJson['status'] == true) {
        let key = `${MixPannelEnum.delete} ${MixPannelEnum.listings}`;
        addMixpanelEvent(MixpanelEvent.viewListingsDetail, {[key]: true});
        setLoading(false);
        onLoadListing();
      } else {
        setLoading(false);
      }
    }
  };

  // appprove / reject
  const aproveRejectListingsApi = async ({status, listing_id}) => {
    let msg = `Do you want to ${
      status == 3 ? 'reject' : 'approve'
    } this Listing ?`;
    const confirm = window.confirm(msg);

    if (confirm) {
      setLoading(true);
      let fDict = {status: status};
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.listingsModerate + `/${listing_id}/moderate`,
        'post',
        JSON.stringify({listing: fDict}),
        '',
      );

      if (responseJson['status'] == true) {
        let text = status == 3 ? MixPannelEnum.approve : MixPannelEnum.reject;
        let key = `${text} ${MixPannelEnum.listings}`;
        addMixpanelEvent(MixpanelEvent.viewListingsDetail, {[key]: true});
        setLoading(false);
        onLoadListing();
      }
    }
  };

  return (
    <Table
      header_data={listingTableColumns
        ?.filter(it => it.feature_activated)
        .map(item => {
          const center_keys = [
            'website',
            'image',
            'status',
            'listing_id',
            'listing_status',
            'actions',
            'currency',
            'sold',
          ];
          return {
            title: item.title,
            key: item.key_name,
            className: [
              'text-sm font-medium    px-4 py-3  flex-grow  whitespace-nowrap min-w-[140px] ',
              center_keys?.includes(item?.key_name)
                ? 'text-center'
                : 'text-start',
            ].join(' '),
          };
        })}
      table_header_style={'  '}
      table_style=" block border-0  rounded-none  !mt-0 table-auto w-full  max-h-full h-full overflow-auto  overflow-auto ">
      {listing_list?.map(listing => {
        return (
          <tr className="  w-full   border-b border-[#e6e7e7]  bg-white">
            {listingTableColumns
              ?.filter(it => it?.feature_activated)
              ?.map(column => {
                switch (column.key_name) {
                  case 'title':
                    return (
                      <td
                        key={column.key_name}
                        onClick={() => onListingClick(listing?.id)}
                        className="p-2 text-primary min-w-[300px] w-full cursor-pointer hover:underline">
                        {listing?.title}
                      </td>
                    );

                  case 'website':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 whitespace-nowrap my-auto">
                        <div className="flex items-center justify-center">
                          <CopyButton copy_item={get_listing_path({listing})} />
                        </div>
                      </td>
                    );

                  case 'list_price':
                    return (
                      <td
                        key={column.key_name}
                        className="px-4 whitespace-nowrap text-AppTitleBlack">
                        {listing?.list_price?.formatted}
                      </td>
                    );

                  case 'offer_percent':
                    return (
                      <td
                        key={column.key_name}
                        className="px-4 whitespace-nowrap text-AppTitleBlack">
                        {listing?.offer_percent}%
                      </td>
                    );

                  case 'image':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 whitespace-nowrap">
                        <img
                          src={
                            listing?.images?.[0]
                              ? getThumbnailImage(listing?.images?.[0])
                              : placeholder
                          }
                          alt={listing?.title}
                          className="h-[50px] w-[50px] rounded-[10px] mx-auto"
                        />
                      </td>
                    );

                  case 'offer_price':
                    return (
                      <td
                        key={column.key_name}
                        className="px-4 whitespace-nowrap text-AppTitleBlack">
                        {listing?.offer_price?.formatted}
                      </td>
                    );

                  case 'status':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 whitespace-nowrap">
                        {listing?.active ? (
                          <p className="btn-active p-1 rounded w-fit mx-auto">
                            Active
                          </p>
                        ) : (
                          <p className="btn-deactivate p-1 rounded w-fit mx-auto">
                            Inactive
                          </p>
                        )}
                      </td>
                    );

                  case 'listing_id':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 px-4 whitespace-nowrap text-AppTitleBlack text-center">
                        {listing?.id}
                      </td>
                    );

                  case 'listing_status':
                    return (
                      <td key={column.key_name} className="p-2">
                        {listing?.status === 2 ? (
                          <p className="btn-active p-1 rounded w-fit mx-auto">
                            Approved
                          </p>
                        ) : listing?.status === 1 ? (
                          <div className=" flex items-center justify-center gap-3">
                            <ApproveButton
                              is_approve={true}
                              onClickButton={() =>
                                aproveRejectListingsApi({
                                  status: 2,
                                  listing_id: listing?.id,
                                })
                              }
                            />

                            <ApproveButton
                              is_approve={false}
                              onClickButton={() =>
                                aproveRejectListingsApi({
                                  status: 3,
                                  listing_id: listing?.id,
                                })
                              }
                            />
                          </div>
                        ) : (
                          <p className="btn-deactivate p-1 rounded w-fit mx-auto">
                            Rejected
                          </p>
                        )}
                      </td>
                    );

                  case 'accounts':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 px-4 whitespace-nowrap text-AppTitleBlack">
                        {listing?.account?.name ?? 'N/A'}
                      </td>
                    );

                  case 'currency':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 px-4 whitespace-nowrap text-AppTitleBlack text-center">
                        {listing?.offer_price?.currency}
                      </td>
                    );

                  case 'sold':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 px-4 text-AppTitleBlack text-center">
                        {listing?.sold ? 'Yes' : 'No'}
                      </td>
                    );

                  case 'goal_amount':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 px-4 text-AppTitleBlack">
                        {listing?.goal_price?.formatted}
                      </td>
                    );

                  case 'actions':
                    return (
                      <td
                        key={column.key_name}
                        className="p-2 px-4 text-AppTitleBlack">
                        <div className="flex items-center justify-center gap-2">
                          <DeleteButton
                            onClickDelete={() => deleteListingApi(listing?.id)}
                          />
                          <EditButton
                            onClickEdit={() => onListingEditClick(listing)}
                          />
                        </div>
                      </td>
                    );

                  default:
                    return null;
                }
              })}
          </tr>
        );
      })}
    </Table>
  );
};

export default ListingsList;
