import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent } from '../../Models/MixPannelEnum';
import Switch from "react-switch";

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'email';

const feedbackTypeArray = ['Email', 'Phone', 'Email or Phone']
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false

let defaultBuyer = 'Thank you for your order!'
let defaultSeller = 'New Order Received'


export default class SystemEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      buyerSubject: defaultBuyer,
      sellerSubject:defaultSeller,
      buyerEnable: false,
      sellerEnable: false,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=' + KeyGroup,'get','','');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.state.buyerSubject = configs['buyer_order_placed_email_subject'] || defaultBuyer;
      this.state.sellerSubject = configs['seller_order_received_email_subject'] || defaultSeller;
      this.state.buyerEnable = configs['buyer_order_placed_email_enabled'] || false;
      this.state.sellerEnable = configs['seller_order_received_email_enabled'] || false;
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
 //MARK:-  APIs Method 
  addOrUpdateApi = async () => {
  this.setState({dataLoad: false});
  var configs = [];
  if (this.state.buyerSubject.length != 0) {
    let fDict = {
      key_group: KeyGroup,
      key: 'buyer_order_placed_email_subject',
      value: this.state.buyerSubject,
      secured: false,
    };
    configs.push(fDict);
  }
  if (this.state.sellerSubject.length != 0) {
    let fDict = {
      key_group: KeyGroup,
      key: 'seller_order_received_email_subject',
      value: this.state.sellerSubject,
      secured: false,
    };
    configs.push(fDict);
  }
  let keysAry = ['buyer_order_placed_email_enabled','seller_order_received_email_enabled'];
  let valueAry = [this.state.buyerEnable,this.state.sellerEnable];
    for (let a= 0; a < keysAry.length; a++) {
      let fDict = {
        key_group: KeyGroup,
        key: keysAry[a],
        value: valueAry[a],
        secured: false,
      };
      configs.push(fDict);
    }
  const responseJson = await networkService.networkCall(
    APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}))
  this.setState({dataLoad: true});
  if (responseJson['status'] == true) {
    addMixpanelEvent(MixpanelEvent.systemEmails,{'success':true})
    AppToast()
  } else {
    addMixpanelEvent(MixpanelEvent.systemEmails,{'success':false})
    this.setState({dataLoad: true})
  }
};
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateApi();
  }
 
  //MARK:-  UIs 
 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>System Emails</Text>
        <Text style={appConfigStyle.subTitleStyle}> - Your footer address comes from Business Details  </Text>
        <Text style={appConfigStyle.subTitleStyle}> - Your logo comes from email header config  </Text>
        <Text style={appConfigStyle.subTitleStyle}> - The header color comes from the primary color config  </Text>
        <Text style={appConfigStyle.subTitleStyle}> - The header title comes from business name  </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{ flexDirection: 'column' }}>
            <View style={{ marginTop: -20, zIndex: 1, }}>
              <Text style={appConfigStyle.titleTxtStyle}>Buyer - Thank you for your order!</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter buyer subject "
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({ buyerSubject: name })}
                value={this.state.buyerSubject}
              />
            </View>
            <View style={{ marginTop: 20, zIndex: 1, }}>
              <Text style={appConfigStyle.titleTxtStyle}>Seller - Order Received Email Subject</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter seller subject "
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({ sellerSubject: name })}
                value={this.state.sellerSubject}
              />
            </View>
            <View style={{ flexDirection: itsMobileView ? 'column' : 'row', marginTop: 20}}>
              <View style={styles.settingViewsStyle} >
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({buyerEnable: !this.state.buyerEnable})}
                  checked={this.state.buyerEnable}
                  height={22}
                  width={50}
                />
                <Text style={[tableStyle.applyTxtStyle,{marginTop:60}]}>Oder confirmation email for buyer</Text>
              </View>
              <View style={{ width: 20 }} />
              <View style={styles.settingViewsStyle} >
                <Switch
                  onColor={colors.AppNewGreen}
                  onChange={() => this.setState({sellerEnable: !this.state.sellerEnable})}
                  checked={this.state.sellerEnable}
                  height={22}
                  width={50}
                />
                <Text style={[tableStyle.applyTxtStyle,{marginTop:60}]}>Oder confirmation email for seller</Text>
              </View>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  settingViewsStyle: {
    margin: 5,
    backgroundColor: colors.AppLightGreen,
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 200,
    width: 200,
    alignItems: 'flex-end',
    // justifyContent: 'center',
    padding: 10,
  },
});
