import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  SafeAreaView,
  Image,
} from 'react-native';
import styles from '../StyleSheets/NewStyleSheet';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import {Gradient} from 'react-gradient';
import OrderDetail from './OrderDetails';
import {dateConversionFromTimeStamp} from './../HelperClasses/SingletonClass';
import statusModel from './../Models/StatusModel';
import appMessage from '../Constants/AppConstantsMessage';
import Board from 'react-trello';
import listIcon from '../assets/listIcon.png';
import kanbanIcon from '../assets/kanbanIcon.png';
import selectedListIcon from '../assets/selectedListIcon.png';
import selectedkanbanIcon from '../assets/selectedkanbanIcon.png';
import DatePicker, {CalendarContainer} from 'react-datepicker';
import Moment from 'moment';
import {TwoThumbInputRange} from 'react-two-thumb-input-range';
import close_Icon from '../assets/closeIcon.png';
import whiteTick from '../assets/whiteTick.png';
import downArrow_Icon from '../assets/downArrowIcon.png';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import SegmentBtnView from '../Component/SegmentBtnView';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import checked_Icon from '../assets/checkedIcon.png';
import unchecked_Icon from '../assets/uncheckedIcon.png';
import StatusModel from './../Models/StatusModel';
import 'react-datepicker/dist/react-datepicker.css';
import AppConstants from '../Constants/AppConstants';
import {withRouter} from '../wrappers/withRouter';
import {currentOrderStatus} from '../Constants/Status';

const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let laneHeight = windowHeight / 1.55;

let dateFormat = 'yyyy-MM-DD';
let PerPage = 25;

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntriesCount: 10,
      ordersArray: [],
      tempArray: [],
      dataLoad: false,
      isEdit: false,
      createCollectionBool: false,
      orderID: 0,
      stopPage: false,
      showOrderDetail: false,
      orderID: 0,
      haveData: false,
      statusArray: [],
      showKanbanView: false,
      showFilterView: false,
      showSortView: false,
      selectedFilterID: -1,
      filterStartDate: '', //new Date(),
      filterEndDate: '', // new Date(),
      filterPriceValue: [0, 1000],
      filterStatusValue: -1,
      appliedDate: false,
      appliedStatus: false,
      appliedPrice: false,
      showDropDown: false,
      perPage: PerPage,
      totalRecords: 0,
      selectedOrderStatusArray: [],
    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    pageNo = 1;
    this.loadOrdersApi();
  }

  //MARK:-  APIS Method 
  loadOrdersApi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.ordersList + `${pageNo}&per_page=${this.state.perPage}`,
      'get',
      '',
      '',
    );
    this.setState({ordersArray: []});
    if (responseJson['status'] == true) {
      var ordrD = responseJson['data']['orders'];
      this.state.totalRecords = responseJson['data']['total_records'];
      if (ordrD.length != 0) {
        this.setState({ordersArray: []});
        this.setState({stopPage: false});
        this.statusdata(ordrD);
        for (let i = 0; i < ordrD.length; i++) {
          this.state.ordersArray.push(ordrD[i]);
          this.state.tempArray.push(ordrD[i]);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.ordersArray.length == 0 ? true : false;

      // mixpanel
      let view_type;
      if (this.state.showKanbanView) {
        view_type = MixPannelEnum.kanbanView;
      } else {
        view_type = MixPannelEnum.listView;
      }
      addMixpanelEvent(MixpanelEvent.viewOrder, {
        page: pageNo,
        type: view_type,
      });
    }
    this.setState({dataLoad: true});
  };
  statusdata(data) {
    this.state.statusArray = [];
    let ssArray = [];
    var commonArray = [];
    var a = 1;
    while (a < 9) {
      let objc = [];
      commonArray.push(objc);
      a++;
    }
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      let status = item['order_status'];
      let dic = {
        id: item['id'],
        title: item['reference_number'],
        description: ['payment_status'] == 2 ? 'Pay' : 'UnPaid',
        draggable: false,
      };
      let dicAry = commonArray[status];
      if (dicAry != undefined) {
        dicAry.push(dic);
        commonArray[status] = dicAry;
      }
    }
    var k = 1;
    while (k < 9) {
      let status = currentOrderStatus(k);
      let randomColor = OrderBGColor[k];
      let pendingDic = {
        id: 'lane' + k,
        title: status,
        label: '',
        cards: commonArray[k],
        style: {backgroundColor: randomColor, maxHeight: laneHeight},
      };
      ssArray.push(pendingDic);
      k++;
    }
    this.state.statusArray.push({lanes: ssArray});
    this.setState({updateUI: !this.state.updateUI});
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({showOrderDetail: false});
  }
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadOrdersApi();
      }
    } else {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadOrdersApi();
      }
    }
    this.setState({updateUI: !this.state.updateUI});
  }
  viewOrderBtnAction = id => {
    let ind = this.state.ordersArray.findIndex(x => x.reference_number == id);
    let OR = this.state.ordersArray[ind]['id'];
    this.props.navigate(`/orders/${OR}`);
  };
  onCardClick = (cardId, metadata, laneId) => {
    this.props.navigate(`/orders/${cardId}`);
  };
  didSelectFilter(id) {
    this.setState({
      selectedFilterID: this.state.selectedFilterID == id ? -1 : id,
    });
    if (id == 3) {
      this.clearBtnAction(5);
    }
  }
  statusBtnAction(id) {
    this.setState({
      filterStatusValue: this.state.filterStatusValue == id ? -1 : id,
    });
  }
  applyBtnAction(id) {
    if (id == 1) {
      this.state.appliedDate = true;
    }
    if (id == 2) {
      this.state.appliedStatus = true;
    }
    if (id == 4) {
      this.state.appliedPrice = true;
    }
    if (!this.state.appliedDate) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
    }
    if (!this.state.appliedStatus) {
      this.state.filterStatusValue = -1;
    }
    if (!this.state.appliedDate) {
      this.state.filterPriceValue = [0, 1000];
    }

    this.setState({showFilterView: false, selectedFilterID: -1});
  }
  clearBtnAction(id) {
    if (id == 1) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
      this.state.appliedDate = false;
    }
    if (id == 2) {
      this.state.filterStatusValue = -1;
      this.state.appliedStatus = false;
    }
    if (id == 4) {
      this.state.filterPriceValue = [0, 1000];
      this.state.appliedPrice = false;
    }
    if (id == 5) {
      this.state.filterStatusValue = -1;
      this.state.selectedOrderStatusArray = [];
    }
    this.setState({showFilterView: false, selectedFilterID: -1});
  }
  fiterBtnAction() {
    if (!this.state.appliedDate) {
      this.state.filterStartDate = '';
      this.state.filterEndDate = '';
    }
    if (!this.state.appliedStatus) {
      this.state.filterStatusValue = -1;
    }
    if (!this.state.appliedDate) {
      this.state.filterPriceValue = [0, 1000];
    }
    this.setState({
      showFilterView: !this.state.showFilterView,
      selectedFilterID: -1,
    });
  }
  onValueChange = values => {
    this.setState({filterPriceValue: values});
  };
  didSelectPerPage = (item, index) => {
    this.state.perPage = item;
    this.setState({showDropDown: false});
    pageNo = 1;
    this.loadOrdersApi();
  };
  didSelectOrderStatus = item => {
    let ind = this.state.selectedOrderStatusArray.findIndex(x => x == item);
    if (ind != -1) {
      this.state.selectedOrderStatusArray.splice(ind, 1);
    } else {
      this.state.selectedOrderStatusArray.push(item);
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  /*  UI   */
  renderFilterView = () => {
    return (
      <View style={[tableStyle.filterContainerViewStyle, {marginTop: 5}]}>
        <View
          style={{
            display: this.state.showFilterView ? 'flex' : 'none',
            flexDirection: 'row',
          }}>
          <View
            style={[
              tableStyle.filterViewStyle,
              {paddingTop: 10, flexDirection: 'row-reverse'},
            ]}>
            <FlatList
              data={FilterStaticArray}
              renderItem={this.renderFilterCell}
              extraData={this.state}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item, index) => index + 9999}
              key={'FL'}
            />
            <View
              style={{
                height: '100%',
                width: this.state.selectedFilterID == -1 ? 0 : 1,
                backgroundColor: colors.SimonGray,
              }}
            />
            {this.renderStatusView()}
            {this.renderOrderStatusView()}
            {this.renderDateView()}
          </View>
        </View>
      </View>
    );
  };
  renderClearApplyButton = id => {
    return (
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        <TouchableOpacity onPress={() => this.clearBtnAction(id)}>
          <Text style={tableStyle.clearTxtStyle}>Clear</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.applyBtnAction(id)}>
          <Text style={tableStyle.applyTxtStyle}>Apply</Text>
        </TouchableOpacity>
      </View>
    );
  };
  renderFilterCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectFilter(index)}
        style={{
          margin: 10,
          marginLeft: 9,
          marginTop: 0,
          marginRight: 9,
          backgroundColor:
            this.state.selectedFilterID == index
              ? colors.SimonGray
              : colors.TransparentColor,
          padding: 5,
          borderRadius: 2,
        }}>
        <Text>{item}</Text>
      </TouchableOpacity>
    );
  };
  renderStatusView = () => {
    return (
      <View
        style={{
          width: 170,
          display: this.state.selectedFilterID == 0 ? 'flex' : 'none',
        }}>
        <View style={{margin: 10}}>
          <Text style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 0}]}>
            Payment Status
          </Text>
          <View style={{flexDirection: 'row', marginTop: 20, marginLeft: -5}}>
            <TouchableOpacity
              style={
                this.state.filterStatusValue == 1
                  ? tableStyle.selectedStatusViewStyle
                  : tableStyle.unSelectedStatusViewStyle
              }
              onPress={() => this.statusBtnAction(1)}>
              <Text>{'UnPaid'}</Text>
            </TouchableOpacity>
            <View style={{width: 20}} />
            <TouchableOpacity
              style={
                this.state.filterStatusValue == 2
                  ? tableStyle.selectedStatusViewStyle
                  : tableStyle.unSelectedStatusViewStyle
              }
              onPress={() => this.statusBtnAction(2)}>
              <Text>{'Pay'}</Text>
            </TouchableOpacity>
          </View>
          <View style={{zIndex: 12, marginTop: 90}}>
            {this.renderClearApplyButton(2)}
          </View>
        </View>
      </View>
    );
  };
  renderOrderStatusView = () => {
    var views = [];
    for (let a = 1; a < 9; a++) {
      let orderName = currentOrderStatus(a);
      let ind = this.state.selectedOrderStatusArray.findIndex(
        x => x == orderName,
      );
      views.push(
        <View
          style={{
            borderBottomColor: colors.SimonGray,
            borderBottomWidth: 1,
            marginLeft: 10,
          }}>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 4,
            }}
            onPress={() => this.didSelectOrderStatus(orderName)}>
            <Text>{orderName}</Text>
            <Image
              style={{width: 20, height: 20}}
              source={ind != -1 ? checked_Icon : unchecked_Icon}
            />
          </TouchableOpacity>
          <View style={{height: 5, width: 5}} />
        </View>,
      );
    }

    return (
      <View
        style={{
          width: 220,
          display: this.state.selectedFilterID == 1 ? 'flex' : 'none',
        }}>
        <View style={{margin: 10, marginRight: 2}}>
          <Text style={[CommonStyleSheet.plainSubTextStyle, {marginTop: 0}]}>
            Order Status
          </Text>
          <View style={{flexDirection: 'row', marginTop: 20, marginLeft: -5}}>
            <ScrollView style={{height: 200, paddingRight: 10}}>
              {views}
            </ScrollView>
          </View>
          <View style={{zIndex: 12, marginTop: 20}}>
            {this.renderClearApplyButton(2)}
          </View>
        </View>
      </View>
    );
  };
  renderCalendarContainerView = ({children, className}) => {
    return (
      <View
        style={{
          padding: 20,
          backgroundColor: 'red',
          flexDirection: 'row-reverse',
        }}>
        <View
          style={{
            width: 340,
            height: 400,
            position: 'relative',
            backgroundColor: 'pink',
            padding: 10,
          }}>
          <CalendarContainer className={className}>
            <View>{children}</View>
          </CalendarContainer>
        </View>
      </View>
    );
  };
  renderDateView = () => {
    let strtD =
      this.state.filterStartDate.length == 0
        ? 'Select Date'
        : Moment(this.state.filterStartDate).format(dateFormat);
    let endD =
      this.state.filterEndDate.length == 0
        ? 'Select Date'
        : Moment(this.state.filterEndDate).format(dateFormat);
    return (
      <View
        style={{
          width: 160,
          display: this.state.selectedFilterID == 2 ? 'flex' : 'none',
        }}>
        <View style={{zIndex: 126}}>
          <Text style={CommonStyleSheet.dateTitleStyle}>Start Date</Text>
          <View style={[tableStyle.dateTextField, {height: 35}]}>
            {/* <this.renderCalendarContainerView /> */}
            <DatePicker
              popperPlacement="left-start"
              selected={this.state.filterStartDate}
              onChange={date => this.setState({filterStartDate: date})}
              dateFormat="MM/dd/yyyy h:mm:ss"
              showTimeInput={false}
              customInput={
                <View style={{height: 20, width: 100}}>
                  {' '}
                  <Text>{strtD}</Text>
                </View>
              }
            />
          </View>
        </View>
        <View style={{zIndex: 123}}>
          <Text style={CommonStyleSheet.dateTitleStyle}>End Date</Text>
          <View style={[tableStyle.dateTextField, {height: 35}]}>
            <DatePicker
              popperPlacement="left-start"
              selected={this.state.filterEndDate}
              onChange={date => this.setState({filterEndDate: date})}
              dateFormat="MM/dd/yyyy h:mm:ss"
              showTimeInput={false}
              customInput={
                <View style={{height: 20, width: 100}}>
                  {' '}
                  <Text>{endD}</Text>
                </View>
              }
            />
          </View>
        </View>
        <View
          style={{
            zIndex: 12,
            marginLeft: 10,
            marginTop: 20,
            marginRight: 20,
            marginBottom: 20,
          }}>
          {this.renderClearApplyButton(1)}
        </View>
      </View>
    );
  };
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 9999}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.ordersArray.length; i++) {
      var formatted = dateConversionFromTimeStamp(
        this.state.ordersArray[i]['created_at'],
      );
      let status = currentOrderStatus(
        this.state.ordersArray[i]['order_status'],
      );
      views.push(
        <FlatList
          data={[
            this.state.ordersArray[i]['reference_number'],
            this.state.ordersArray[i]['grand_total']['formatted'],
            status,
            this.state.ordersArray[i]['payment_status'] == 2
              ? 'Paid'
              : 'Not Paid',
            this.state.ordersArray[i]['payment_method']['name'],
            this.state.ordersArray[i]?.shipping_method?.name ?? 'N/A',
            formatted,
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          keyExtractor={(item, index) => index + 999999}
          key={'C'}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 4 || index == 5) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item != 'Pay'
                ? tableStyle.columnOrangeBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 0) {
      return (
        <TouchableOpacity
          style={styles.columnTitleViewStyle}
          onPress={() => this.viewOrderBtnAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.columnViewTitleStyle}>{item}</Text>
        </View>
      );
    }
  };
  gradientButtons = name => {
    if (this.state.ordersArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />;
      } else if (
        name['id'] == 1 &&
        this.state.ordersArray.length == 0 &&
        pageNo > 1
      ) {
        return <View />;
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{color: 'white'}}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  EmptyListRender = () => {
    if (this.state.ordersArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '70%'}}>
            <EmptyListUI
              showImage={true}
              titleString={appMessage.emptyOrdersTitleString}
              subtitleString={appMessage.ordersMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  renderListView = () => {
    return (
      <ScrollView horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  renderKanbanBoard = () => {
    if (this.state.statusArray.length != 0) {
      return (
        <View style={{height: '100%'}}>
          <Board
            style={{backgroundColor: 'white', height: '100%'}}
            laneStyle={{height: windowHeight / 2}}
            data={this.state.statusArray[0]}
            cardDraggable={true}
            onCardClick={this.onCardClick}
            hideCardDeleteIcon={true}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderMainView = () => {
    if (this.state.showKanbanView) {
      return (
        <View style={{flex: 1, width: '100%', height: '100%'}}>
          <this.renderKanbanBoard />
        </View>
      );
    } else {
      return <this.renderListView />;
    }
  };
  renderHeaderBtn = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginRight: 10,
          zIndex: 100,
          marginTop: itsMobileView ? 5 : 0,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          <View>
            <SegmentBtnView
              title={'List'}
              isSelected={!this.state.showKanbanView}
              icon={!this.state.showKanbanView ? selectedListIcon : listIcon}
              tapAction={() => {
                addMixpanelEvent(MixpanelEvent.viewOrder, {
                  type: MixPannelEnum.listView,
                });
                this.setState({showKanbanView: false});
              }}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Board'}
              isSelected={this.state.showKanbanView}
              icon={this.state.showKanbanView ? selectedkanbanIcon : kanbanIcon}
              tapAction={() => {
                addMixpanelEvent(MixpanelEvent.viewOrder, {
                  type: MixPannelEnum.boardView,
                });
                this.setState({showKanbanView: true});
              }}
            />
          </View>
          <View style={{width: 10}} />
        </View>
      </View>
    );
  };
  renderHeaderRightBtn = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginRight: 0,
          zIndex: 100,
          marginTop: itsMobileView ? 10 : -5,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          <View />
          <View style={{position: 'relative'}}>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => this.fiterBtnAction()}>
              <Text
                style={[
                  CommonStyleSheet.tabBtnViewStyle,
                  {
                    color: this.state.showFilterView
                      ? colors.AppNewGreen
                      : colors.Appgray,
                  },
                ]}>
                Filter
              </Text>
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: this.state.showFilterView
                  ? colors.AppNewGreen
                  : colors.TransparentColor,
                height: 2,
                width: '100%',
                marginTop: 5,
              }}
            />
            <View style={{flexDirection: 'row-reverse'}}>
              {this.renderFilterView()}
            </View>
          </View>
          {/* <View style={{ width: 20 }} />
        <View>
          <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this.setState({ showSortView: !this.state.showSortView })}>
            <Text style={[CommonStyleSheet.tabBtnViewStyle,{color: this.state.showSortView ? colors.AppNewGreen : colors.Appgray}]}>Sort</Text>
          </TouchableOpacity>
          <View style={{ backgroundColor: this.state.showSortView ? colors.AppNewGreen : colors.TransparentColor, height: 2, width: '100%', marginTop: 5 }} />
        </View> */}
        </View>
      </View>
    );
  };
  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View
          style={[
            tableStyle.headerSubContainer,
            {
              justifyContent: itsMobileView ? 'flex-start' : 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
            },
          ]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
              Orders{' '}
            </Text>
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
                Index: 4,
                flex: 1,
                marginLeft: itsMobileView ? 10 : 0,
                marginTop: itsMobileView ? -5 : 0,
              }}>
              <this.renderHeaderBtn />
            </View>
          </View>
          <View />
        </View>
      </View>
    );
  };
  renderSelectFilterView = () => {
    var views = [];
    var ssAry = [];
    if (this.state.appliedDate) {
      if (
        this.state.filterStartDate.length != 0 &&
        this.state.filterEndDate.length != 0
      ) {
        let sd = Moment(this.state.filterStartDate).format(dateFormat);
        let ed = Moment(this.state.filterEndDate).format(dateFormat);
        ssAry.push({
          title: `${sd} - ${ed}`,
          id: 1,
          color: colors.AppLightOrange,
        });
      }
    }
    if (this.state.appliedStatus) {
      if (this.state.filterStatusValue != -1) {
        let statusV = this.state.filterStatusValue == 1 ? 'UnPaid' : 'Pay';
        ssAry.push({title: statusV, id: 2, color: colors.LightGreen});
      }
    }
    if (this.state.appliedPrice) {
      if (
        this.state.filterPriceValue[0] != 0 ||
        this.state.filterPriceValue[1] != 1000
      ) {
        let price = `$${this.state.filterPriceValue[0]} - $${this.state.filterPriceValue[1]}`;
        ssAry.push({title: price, id: 4, color: colors.lightRed});
      }
    }
    for (let obj of ssAry) {
      views.push(
        <View
          style={{
            backgroundColor: obj['color'],
            borderRadius: 9,
            padding: 5,
            flexDirection: 'row',
            margin: 5,
          }}>
          <Text style={CommonStyleSheet.filterTitleStyle}>{obj['title']}</Text>
          <TouchableOpacity>
            <Image
              style={{width: 16, height: 16, marginLeft: 10}}
              source={close_Icon}
            />
          </TouchableOpacity>
        </View>,
      );
    }

    return (
      <View style={{width: '97%', marginLeft: 20, flexDirection: 'row'}}>
        {views}
      </View>
    );
  };
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.perPageContainerViewStyle}>
          <View style={{height: 4}} />
          <FlatList
            data={['25', '50', '100']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 999}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectPerPage(item, index)}
        style={{margin: 3}}>
        <View
          style={[
            tableStyle.perPageDropDownViewStyle,
            {
              backgroundColor:
                item == this.state.perPage ? colors.BGBlueColor : '',
            },
          ]}>
          <Image
            source={whiteTick}
            style={{height: 16, width: item == this.state.perPage ? 16 : 0}}
          />
          <Text style={CommonStyleSheet.dropdownCellWhiteTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderPerView = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 20,
        }}>
        <Text>Show rows</Text>
        <View style={{flexDirection: 'column', zIndex: 9999}}>
          <TouchableOpacity
            onPress={() =>
              this.setState({showDropDown: !this.state.showDropDown})
            }
            style={tableStyle.perPagedropDownView}>
            <Text> {this.state.perPage} </Text>
            <Image
              style={
                this.state.showDropDown == true
                  ? appConfigStyle.upArraowIconStyle
                  : appConfigStyle.downArraowIconStyle
              }
              resizeMode="contain"
              source={downArrow_Icon}
            />
          </TouchableOpacity>
          <this.entriesDropDown />
        </View>
      </View>
    );
  };
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (
        <View style={tableStyle.bottomViewStyle}>
          <this.gradientButtons name={'Next'} id={1} />
          <this.gradientButtons name={'Previous'} id={2} />
          <this.renderPerView />
        </View>
      );
    } else {
      return <View />;
    }
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <this.renderHeaderView />
        {/* <this.renderSelectFilterView /> */}
        <View style={tableStyle.containerMainView}>
          {this.renderMainView()}
          <this.EmptyListRender />
          <this.renderBottomView />
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

export default withRouter(Orders);

const tableHeaderString = [
  'Reference #',
  'Amount',
  'Order Status',
  'Payment Status',
  'Payment Method',
  'Shipping Method',
  'Date',
];
const OrderBGColor = [
  ,
  '#e7e5e4',
  '#fecaca',
  '#fed7aa',
  '#fde69a',
  '#fef09a',
  '#d9f99d',
  '#bbf7d0',
  '#a7f3d0',
  '#99f6e4',
  '#a5f3fc',
  '#bae6fd',
  '#bfdbfe',
  '#c7d2fe',
  '#ddd6fe',
  '#e9d5ff',
  '#f5d0fe',
  '#fbcfe9',
  '#fecdd3',
];
const FilterStaticArray = [
  'Payment Status',
  'Order Status',
  'Date',
  'Clear Filter',
];
