import { useEffect } from "react";
import { createPortal } from "react-dom";

export const usePortal = () => {
	const portalElement = document.getElementById("portal-items");

	const elementDiv = document.createElement("div");

	useEffect(() => {
		portalElement.appendChild(elementDiv);

		return () => {
			portalElement.removeChild(elementDiv);
		};
	}, [elementDiv, portalElement]);

	return {
		render: (children) => {
			return createPortal(children, elementDiv);
		},
	};
};

