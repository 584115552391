import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import Header_View from '../HeaderView';
import error_Icon from './../../assets/errorIcon.png';
import gTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import { ToastContainer} from 'react-toastify';
import HelperLinkViews from '../../Component/HelperLinkView';
import WebGeneralConfigs from './WebGeneralConfigs';
import WebSocialConfig from './WebSocialConfig';
import WebGTM from './WebGTM';
import WebFooter from './WebFooter';
import AssetsSettings from '../GlobalSettings/AssetsSetting';
import { checkUserRoleModule } from '../../HelperClasses/SingletonClass';
import { MODULEKEYS } from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import AppColor from '../../HelperClasses/AppColor';

export default class WebConfigs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: 0,
      writeP:AppConstants.defaultWrite,
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
  };
  componentDidMount() {
    this.checkModule()
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.WEBCONFIG, res => {
      this.setState({ writeP: res[1]})
    })
  }
  //MARK:-  APIs Method 
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    if (this.state.subMenuSelectedIndex == 0) {
      this.child.saveButtonAction();
    }
    if (this.state.subMenuSelectedIndex == 1) {
      this.child.saveButtonAction();
    }
    if (this.state.subMenuSelectedIndex == 2) {
      this.child.saveButtonAction();
    }
    if (this.state.subMenuSelectedIndex == 3) {
      this.child.saveButtonAction();
    }
    if (this.state.subMenuSelectedIndex == 4) {
      this.child.saveButtonAction();
    }
  }
  didSelectMenuItem = index => {
    this.setState({subMenuSelectedIndex: index});
  };
  /*  UI   */

  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= subMenuTextArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex == a
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() => this.didSelectMenuItem(a)}>
           <Image style={SettingsStyleSheet.subMenuIconStyle} source={this.state.subMenuSelectedIndex == a ? greenTickIcon : gTick_Icon} />
          <Text style={this.state.subMenuSelectedIndex == a ? SettingsStyleSheet.subMenuSelectedtextStyle: SettingsStyleSheet.subMenutextStyle}>
            {subMenuTextArray[a]}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };

  mainViewRender = () => {
    if (this.state.subMenuSelectedIndex == 0) {
      return <WebGeneralConfigs ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 1) {
      return <WebSocialConfig ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 2222) {
      return <WebGTM ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 2) {
      return <WebFooter ref={child => (this.child = child)} />;
    } else if (this.state.subMenuSelectedIndex == 4) {
      return <AssetsSettings ref={child => (this.child = child)}/>;
    }
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={'Web Customisation'}
          backBtn={this.props.backBtn}
          showSaveBtn={this.state.writeP}
          saveBtn={() => this.saveBtnAction()}
          showBackBtn={this.props.showBackBtn}
        />
        <View style={{flexDirection: 'column'}}>
          <ToastContainer />
          <ScrollView style={SettingsStyleSheet.mainView}>
            <View style={SettingsStyleSheet.mainContainerView}>
              <View style={SettingsStyleSheet.subMenuContainerStyle}>
                <View>
                  <this.subMenuViewRender />
                </View>
              </View>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{flex: 2, backgroundColor: 'white'}}>
                <this.mainViewRender />
              </View>
            </View>
          </ScrollView>
        </View>
        <HelperLinkViews  title={'Web Configuration'}/>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}
const styles = StyleSheet.create({
  Container: {
    backgroundColor: AppColor.AppLightGreen,
  },
});

let subMenuTextArray = [
  'General',
  'Social',
  // 'GTM',
  'Footer',
  // 'Assets',
];
