import React, {useState, useEffect} from 'react';
import {
  Text,
  View,
  Animated,
  Easing,
  TouchableOpacity,
  Image,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import SettingStyleSheet from '../StyleSheets/SettingStyleSheet';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import closeIcon from '../assets/closeBlackIcon.png';
import {useNavigate} from 'react-router-dom';
import colors from '../HelperClasses/AppColor';
import AppConstants from '../Constants/AppConstants';

const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;
let itsMobileView = windowWidth < AppConstants.mobileMaxWidth ? true : false;

const configViewRightMargin = -420;

const SidePan = ({
  children,
  openSidepan,
  setOpenSidepan,
  closeSipan,
  sidepan_width,
}) => {
  const [sidePan_states, setSidepanStates] = useState({
    rightPosition: sidepan_width
      ? new Animated.Value(-sidepan_width)
      : new Animated.Value(configViewRightMargin),
  });

  const navigate = useNavigate();

  //
  const {rightPosition} = sidePan_states;

  useEffect(() => {
    if (openSidepan == true) {
      mooveRL();
    }
  }, [openSidepan]);

  const stopAnimation = () => {
    Animated.timing(rightPosition, {
      toValue: configViewRightMargin,
      duration: 300,
      easing: Easing.linear,
    }).start();
    closeSipan ? closeSipan() : setOpenSidepan(!openSidepan);
  };

  function mooveRL() {
    Animated.timing(rightPosition, {
      toValue: 0,
      duration: 300,
      easing: Easing.linear,
    }).start();
  }

  return (
    <View
      style={[
        SettingStyleSheet.blackContainerViewStyle,
        {
          display: openSidepan ? 'flex' : 'none',
          justifyContent: 'end',
          // position: 'fixed',
          // zIndex: 9999999,
        },
      ]}>
      <TouchableOpacity
        style={
          sidepan_width
            ? AppConstants.rootViewComponent == 3
              ? {width: windowWidth - sidepan_width - 200, height: windowheight}
              : {width: windowWidth - sidepan_width - 50, height: windowheight}
            : SettingStyleSheet.transparentBtnViewStyle
        }
        onPress={() => stopAnimation()}
      />
      <Animated.View
        style={[
          sidepan_width
            ? {
                backgroundColor: colors.AppLightGreen,
                width: itsMobileView ? windowWidth - 40 : sidepan_width,
                height: windowheight,
                padding: 20,
                paddingTop: 10,
              }
            : SettingStyleSheet.animationViewStyle,
          // {right: rightPosition},
        ]}>
        <View style={{marginTop: 0, zIndex: 10}}>{children}</View>
      </Animated.View>
    </View>
  );
};

export default SidePan;
