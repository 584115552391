import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions,
  Alert,
  Platform,
  SafeAreaView,
  Image,
  TouchableOpacity,
  FlatList,
  ImageBackground,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import PropTypes from 'prop-types';
import EmptyListUI from '../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';

import deleteGrayIcon from '../assets/deleteGrayIcon.png';
import jsonIcon from '../assets/jsonIcon.png';
import copyPasteIcon from '../assets/copyPaste.png';
import pdfIcon from '../assets/pdfICon.png';
import listIcon from '../assets/listIcon.png';
import gridIcon from '../assets/gridIcon.png';
import selectedListIcon from '../assets/selectedListIcon.png';
import selectedGridIcon from '../assets/selectedGridIcon.png';

import {Gradient} from 'react-gradient';
import {AppToast} from '../HelperClasses/AppToast';

import ReactFileReader from 'react-file-reader';
import AddMedia from '../MediaManager/AddMedia';
import appMessage from '../Constants/AppConstantsMessage';
import {
  checkUserRoleModule,
  convertFilesToBase64,
  getThumbnailImage,
} from '../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../Models/Enum';
import AppConstants from '../Constants/AppConstants';
import ConstantArray from '../Constants/ConstantArray';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import SegmentBtnView from '../Component/SegmentBtnView';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import FilePicker from '../Component/FilePicker';
import HelperLinkView from '../Component/HelperLinkView';
import Zoom from 'react-medium-image-zoom';

const APPURL = require('./../Constants/URLConstants');
const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false;

export default class MediaManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      mediaArray: [],
      dataLoad: true,
      stopPage: false,
      orderID: 0,
      haveData: false,
      showAddMedia: false,
      selectedArray: [],
      addMediaArray: [],
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      showGridView: false,
      hoverV: '',
    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
    backBtnHandler: PropTypes.func,
  };
  componentDidMount() {
    pageNo = 1;
    this.loadMediaAPi();
    this.checkModule();
    // * Mixpanel
    let view_type;
    if (this.state.showGridView) {
      view_type = MixPannelEnum.gridView;
    } else {
      view_type = MixPannelEnum.listView;
    }
    addMixpanelEvent(MixpanelEvent.viewMedia, {
      type: view_type,
    });
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.MEDIA, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  backBtnHandler() {
    this.setState({showAddMedia: false});
  }
  //MARK:-  APIS Method 
  loadMediaAPi = async () => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,
      'get',
    );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['media'];
      if (collectionD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of collectionD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.mediaArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  deleteMediaApi = async id => {
    this.setState({dataLoad: false});
    var path = APPURL.URLPaths.media + '/' + id;
    const responseJson = await networkService.networkCall(
      path,
      'delete',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      this.loadMediaAPi();
    } else {
      this.setState({dataLoad: true});
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:- Button Actions
  addMediaBtnHandler() {
    this.state.addMediaArray = [];
    this.setState({showAddMedia: !this.state.showAddMedia});
    this.loadMediaAPi();
  }
  deleteMediaBtnAction = id => {
    if (Platform.OS === 'web') {
      const r = window.confirm('Are you sure you want to delete this media?');
      if (r == true) {
        this.deleteMediaApi(id);
      }
    }
  };
  copyMediaBtnAction = url => {
    navigator.clipboard.writeText(url);
    AppToast('Copied');
  };
  onPick = acceptedFiles => {
    // console.log('acceptedFiles', acceptedFiles)
    if (this.state.addMediaArray.length == 0) {
      acceptedFiles.forEach(file => {
        this.state.addMediaArray.push(file);
      });
      this.setState({showAddMedia: true});
    }
  };
  onMouseLeave = () => {
    this.setState({hoverV: ''});
  };
  onMouseEnter = item => {
    this.setState({hoverV: item});
  };
  /*  UI   */

  EmptyListRender = () => {
    if (this.state.mediaArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <EmptyListUI
              showImage={true}
              titleString={appMessage.emptyMediaTitleString}
              subtitleString={appMessage.mediaMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  didSelectElement(index) {
    this.state.selectedArray[index] = !this.state.selectedArray[index];
    this.setState({updateUI: !this.state.updateUI});
  }
  renderElementsView = () => {
    var view = [];
    for (let a = 0; a <= this.state.mediaArray.length - 1; a++) {
      let media = this.state.mediaArray[a];
      let uri = media['url'];
      let name = uri.substring(uri.lastIndexOf('/') + 1);
      var fileExt = name.split('.').pop();
      var showImage = true;
      var icon = jsonIcon;
      if (fileExt == 'json') {
        icon = jsonIcon;
        showImage = false;
      } else if (fileExt == 'pdf') {
        icon = pdfIcon;
        showImage = false;
      }
      let check = this.state.hoverV == name;
      view.push(
        <View
          onMouseLeave={() => this.onMouseLeave(name)}
          onMouseEnter={() => this.onMouseEnter(name)}>
          <View
            style={[
              styles.elementViewsStyle,
              {shadowOpacity: check ? 0.7 : 0.2},
            ]}>
            <View>
              <Zoom zoomMargin={10}>
                <Image
                  style={styles.iconImageViewStyle}
                  source={showImage ? {uri: uri} : icon}
                />
              </Zoom>
              <Text
                style={[
                  CommonStyleSheet.plainSubTextStyle,
                  {padding: 5, paddingBottom: 10},
                ]}>
                {name}
              </Text>
            </View>
            <View style={styles.editViewStyle}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flex: 1,
                }}>
                <TouchableOpacity
                  style={styles.closeBtnViewStyle}
                  onPress={() => this.copyMediaBtnAction(uri)}>
                  <Image
                    source={copyPasteIcon}
                    style={{width: 15, height: 15}}
                  />
                </TouchableOpacity>
                <View style={{display: this.state.deleteP ? 'flex' : 'none'}}>
                  <TouchableOpacity
                    style={styles.closeBtnViewStyle}
                    onPress={() => this.deleteMediaBtnAction(media['id'])}>
                    <Image
                      source={deleteGrayIcon}
                      style={{width: 15, height: 15}}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>,
      );
    }
    return view;
  };

  renderUploadMediaBtn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <View style={styles.dottedViewStyle}>
              <FilePicker data={files => this.onPick(files)} />
            </View>
          </Gradient>
        </View>
      </View>
    );
  };
  renderHeaderBtn = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginRight: 10,
          zIndex: 100,
          marginTop: 5,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          <View>
            <SegmentBtnView
              title={'List'}
              isSelected={!this.state.showGridView}
              icon={!this.state.showGridView ? selectedListIcon : listIcon}
              tapAction={() => this.setState({showGridView: false})}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <SegmentBtnView
              title={'Grid'}
              isSelected={this.state.showGridView}
              icon={this.state.showGridView ? selectedGridIcon : gridIcon}
              tapAction={() => this.setState({showGridView: true})}
            />
          </View>
          <View style={{width: 10}} />
        </View>
      </View>
    );
  };
  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View
          style={[
            tableStyle.headerSubContainer,
            {
              justifyContent: itsMobileView ? 'flex-start' : 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
            },
          ]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
              Media Manager{' '}
            </Text>
            {/* <ToolTipViewBtn title={AppConstantsMessage.collectionsTitleToopTipMsg} description={AppConstantsMessage.collectionsSubTitleToolTipMsg} /> */}
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
                Index: 4,
                flex: 1,
                marginLeft: itsMobileView ? 10 : 0,
              }}>
              <this.renderHeaderBtn />
            </View>
          </View>
          <View
            style={{
              alignSelf: itsMobileView ? 'flex-start' : 'flex-end',
              marginTop: itsMobileView ? 10 : 0,
            }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <this.renderUploadMediaBtn />
            </View>
          </View>
        </View>
      </View>
    );
  };
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View
        style={[
          styles.tableViewTitleViewStyle,
          {width: index == 0 ? windowWidth - 300 : 114},
        ]}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.mediaArray.length; i++) {
      let url = this.state.mediaArray[i]['url'];
      let name = url.substring(url.lastIndexOf('/') + 1);
      views.push(
        <FlatList
          data={[name, url, this.state.mediaArray[i]['id']]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
          keyExtractor={(item, index) => index + 9989}
          key={'H'}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 2) {
      let id_Index = this.state.mediaArray.findIndex(x => x.id === item);
      let url = this.state.mediaArray[id_Index]['url'];
      return (
        <View style={styles.columnTitleViewStyle}>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity
              style={styles.closeBtnViewStyle}
              onPress={() => this.copyMediaBtnAction(url)}>
              <Image source={copyPasteIcon} style={{width: 15, height: 15}} />
            </TouchableOpacity>
            <View style={{display: this.state.deleteP ? 'flex' : 'none'}}>
              <TouchableOpacity
                style={styles.closeBtnViewStyle}
                onPress={() => this.deleteMediaBtnAction(item)}>
                <Image
                  source={deleteGrayIcon}
                  style={{width: 15, height: 15}}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    } else if (index == 1) {
      let name = item.substring(item.lastIndexOf('/') + 1);
      var fileExt = name.split('.').pop();
      var showImage = true;
      var icon = jsonIcon;
      if (fileExt == 'json') {
        icon = jsonIcon;
        showImage = false;
      } else if (fileExt == 'pdf') {
        icon = pdfIcon;
        showImage = false;
      }
      let fileURl = getThumbnailImage(item);
      return (
        <View style={styles.columnTitleViewStyle}>
          <ImageBackground
            style={[tableStyle.itemImageViewStyle, {borderRadius: 0}]}
            source={showImage ? {uri: fileURl} : icon}>
            <Zoom zoomMargin={10}>
              <Image style={{height: 50, width: 50}} source={{uri: item}} />
            </Zoom>
          </ImageBackground>
        </View>
      );
    } else {
      return (
        <View style={[styles.columnTitleViewStyle, {width: windowWidth - 300}]}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderListView = () => {
    return (
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  renderGridView = () => {
    return (
      <View style={tableStyle.mainScrollViewStyle}>
        <ScrollView>
          <View style={styles.containerView}>{this.renderElementsView()}</View>
        </ScrollView>
      </View>
    );
  };
  renderMainView = () => {
    if (this.state.showGridView) {
      return <this.renderGridView />;
    } else {
      return <this.renderListView />;
    }
  };
  render() {
    // console.log('data load', this.state.dataLoad);
    if (this.state.showAddMedia) {
      return (
        <AddMedia
          backBtnAction={() => this.addMediaBtnHandler()}
          selectedArray={this.state.addMediaArray}
        />
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <this.renderHeaderView />
          <View style={tableStyle.mainView}>
            <this.renderMainView />
            <this.EmptyListRender />
          </View>
          <HelperLinkView title={'Media'} />
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}

const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  containerView: {
    flexDirection: windowWidth < fixedWidth ? 'row' : 'row',
    flexWrap: 'wrap',
    width:
      windowWidth < fixedWidth ? windowWidth - 10 : windowWidth < fixedWidth,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: 114,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: 114,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
  },
  editViewStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: 120,
    margin: 10,
  },
  elementViewsStyle: {
    margin: 20,
    backgroundColor: colors.AppWhite,
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 0,
    width: 140,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 10,
  },
  iconImageViewStyle: {
    height: 140,
    width: 140,
    borderRadius: 10,
    padding: 10,
  },
  closeBtnViewStyle: {
    height: 20,
    width: 20,
    backgroundColor: 'white',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dottedViewStyle: {
    margin: 7,
    borderRadius: 10,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
  },
});

const tableHeaderString = ['Name', 'Image', 'Action'];
