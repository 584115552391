import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TextInput,
  Dimensions,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import commonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import SelectSearchView from 'react-select';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import {addMixpanelEvent} from '../../Models/MixPannelEnum';
import TextTitleStyleSheet from '../../StyleSheets/TextTitleStyleSheet';
import AppConstants from '../../Constants/AppConstants';

const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

let regexp = /^[0-9\.]+$/;
export default class AddFleet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStatus: true,
      dataLoad: false,
      branchId: '',
      dialCode: '',
      mobileRegex: '',
      mobile: '',
      name: '',
      email: '',
      id: '',
      countriesArray: [],
      selectedCountry: '',
      branchesArray: [],
      selectedBranch: '',
      fleetsArray: [],
      selectedFleets: '',
      showPasswordView: false,
      newPassword: '',
      isEdit: false,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    driverData: PropTypes.any,
    isEdit: PropTypes.bool,
    closeBtnAction: PropTypes.func,
  };
  componentDidMount() {
    const driverD = this.props.driverData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      if (this.state.name.length == 0) {
        this.setState({
          name: driverD['name'],
          email: driverD['email'],
          mobile: driverD['mobile'],
          id: driverD['id'],
          dialCode: driverD['dial_code'],
          isEdit: true,
        });
      }
    }
    this.loadCountriesApi();
    if (!this.state.isEdit) {
      this.loadBranchsApi();
    }
  }
  // APIS
  loadCountriesApi = async () => {
    let path = `${APPURL.URLPaths.deliveryCountry}`;
    const responseJson = await networkService.networkCall(path, 'get');
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['countries'];
      for (let objc of branchD) {
        var dic = objc;
        dic['value'] = objc['id'];
        dic['label'] = objc['code3'];
        this.state.countriesArray.push(dic);
      }
      if (this.state.countriesArray.length != 0) {
        this.state.selectedCountry = this.state.countriesArray[0];
      }
      this.setState({dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };
  loadBranchsApi = async () => {
    this.setState({dataLoad: false});
    let path = `${APPURL.URLPaths.deliveryBranches}?page=${1}&per_page=${50}`;
    const responseJson = await networkService.networkCall(path, 'get');
    if (responseJson['status'] == true) {
      var branchD = responseJson['data']['branches'];
      for (let objc of branchD) {
        var dic = objc;
        dic['value'] = objc['id'];
        dic['label'] = objc['name'];
        this.state.branchesArray.push(dic);
        if (this.state.branchId == objc['id']) {
          this.state.selectedBranch = dic;
          // this.loadFeeltsApi(dic['value'])
        }
      }
      if (this.state.branchesArray.length != 0) {
        if (this.state.branchId.length == 0) {
          this.state.selectedBranch = this.state.branchesArray[0];
          this.loadFeeltsApi(this.state.selectedBranch['value']);
        }
      }
      this.setState({dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };
  loadFeeltsApi = async branchID => {
    this.setState({dataLoad: false});
    let path = `${
      APPURL.URLPaths.deliveryFleets
    }?page=${1}&per_page=${50}&branch_id=${branchID}`;
    const responseJson = await networkService.networkCall(path, 'get');
    if (responseJson['status'] == true) {
      var fleetsD = responseJson['data']['fleets'];
      for (let objc of fleetsD) {
        if (objc['assigned_to_driver'] == false) {
          var dic = objc;
          let fleet = `${objc['fleet_type']} (${objc['plate_number']})`;
          dic['value'] = objc['id'];
          dic['label'] = fleet;
          this.state.fleetsArray.push(dic);
        }
      }
      if (this.state.fleetsArray.length != 0) {
        this.state.selectedFleets = this.state.fleetsArray[0];
      }
      this.setState({dataLoad: true});
    } else {
      this.setState({dataLoad: true});
    }
  };
  addDriverApi = async () => {
    this.setState({dataLoad: false});
    var dict = {
      name: this.state.name,
      mobile: this.state.mobile,
      dial_code: this.state.selectedCountry['dial_code'],
    };
    if (this.state.email.length != 0) {
      dict['email'] = this.state.email;
    }
    if (!this.state.isEdit) {
      dict['branch'] = {id: this.state.selectedBranch['value']};
      dict['fleet'] = {id: this.state.selectedFleets['value']};
    }
    var path =
      APPURL.URLPaths.deliveryDriver + APPURL.URLPaths.delivertRegisterDriver;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.deliveryDriver + this.state.id;
    }
    let requestMethod = this.state.id !== '' ? 'PATCH' : 'post';
    const responseJson = await networkService.networkCall(
      path,
      requestMethod,
      JSON.stringify({user: dict}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      AppToast();
      if (!this.state.isEdit) {
        this.state.newPassword = responseJson['data']['password'];
        this.setState({showPasswordView: true});
      } else {
        this.props.backBtnAction(true);
      }
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(
      `${this.state.id !== '' ? 'Edit' : 'Add'} Driver`,
      properties,
    );
  }

  //MARK:-  Button Actions
  submitButtonAction = () => {
    if (this.state.name.length == 0) {
      AppToast('Please enter name');
    } else if (this.state.mobile.length == 0) {
      AppToast('Please enter mobile number');
    } else {
      this.addDriverApi();
    }
  };
  handleSelectChange = (selectedOption, id) => {
    if (id == 1) {
      this.state.selectedCountry = selectedOption;
    } else if (id == 2) {
      this.state.selectedFleets = '';
      this.state.fleetsArray = [];
      this.state.selectedBranch = selectedOption;
      this.setState({updateUI: !this.state.updateUI});
      this.loadFeeltsApi(selectedOption['id']);
    } else {
      this.state.selectedFleets = selectedOption;
    }
    this.setState({updateUI: !this.state.updateUI});
  };
  onHandleTextChange(text) {
    let value = text;
    if (text == '' || regexp.test(value)) {
      this.setState({capacity: text});
    }
  }
  copyBtnAction = () => {
    AppToast('Copied');
    navigator.clipboard.writeText(this.state.newPassword);
    this.setState({showPasswordView: false});
    // this.props.backBtnAction(true);
    this.props.closeBtnAction(true);
  };
  // UI Renders
  rendeDropDownView = id => {
    var optionArray = '';
    var valueArray = [];
    var placeHolder = '';
    if (id == 1) {
      valueArray = this.state.selectedCountry;
      optionArray = this.state.countriesArray;
      placeHolder = 'Country';
    } else if (id == 2) {
      valueArray = this.state.selectedBranch;
      optionArray = this.state.branchesArray;
      placeHolder = 'Select Branch';
    } else if (id == 3) {
      valueArray = this.state.selectedFleets;
      optionArray = this.state.fleetsArray;
      placeHolder = 'Select Fleet';
    }
    return (
      <View style={styles.dropDownViewStyle}>
        <View style={{width: '100%', marginLeft: -10}}>
          <SelectSearchView
            value={valueArray}
            // onMenuOpen={() => console.log('open drop')}
            onChange={itm => this.handleSelectChange(itm, id)}
            options={optionArray}
            isMulti={false}
            placeholder={placeHolder}
            styles={colourStyles}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: colors.AppGreenBorder,
                primary: colors.AppGreenBorder,
              },
            })}
          />
        </View>
      </View>
    );
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyleSheet.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  renderCopyCloseBtn = () => {
    return (
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={colors.GradientColors}
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={() => this.copyBtnAction()}>
            <Text style={tableStyle.saveBtnTxtStyle}>{'Copy & Close'}</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    );
  };
  renderPasswordView = () => {
    return (
      <View
        style={[
          tableStyle.passwordViewStyle,
          {display: this.state.showPasswordView ? 'flex' : 'none'},
        ]}>
        <View
          style={{
            backgroundColor: colors.AppWhite,
            height: 230,
            width: 300,
            borderRadius: 10,
          }}>
          <View style={{alignSelf: 'center', margin: 16}}>
            <Text
              style={[commonStyleSheet.plainTextStyle, {textAlign: 'center'}]}>
              New Password
            </Text>
            <View
              style={{
                borderRadius: 5,
                padding: 10,
                borderColor: colors.SimonGray,
                borderWidth: 1,
                marginTop: 30,
              }}>
              <Text
                style={[
                  commonStyleSheet.plainTextStyle,
                  {textAlign: 'center'},
                ]}>
                {this.state.newPassword}
              </Text>
            </View>
            <Text
              style={[
                commonStyleSheet.subTitleStyle,
                {textAlign: 'center', marginTop: 10},
              ]}>
              Please provide this password to driver to access the app
            </Text>
          </View>
          <View
            style={{
              alignSelf: 'center',
              margin: 16,
              marginTop: 10,
              paddingLeft: 10,
            }}>
            {this.renderCopyCloseBtn()}
          </View>
        </View>
      </View>
    );
  };
  renderBranchAndFleetView = () => {
    return (
      <View
        style={{display: !this.state.isEdit ? 'flex' : 'none', zIndex: 1000}}>
        <View style={[styles.subViewStyle, {zIndex: 1950}]}>
          <this.titleLblRender
            title={'Select branch the driver is\nassigned to handle job'}
          />
          <View
            style={{
              width: itsMobileView ? '100%' : '80%',
              marginTop: itsMobileView ? 10 : 0,
            }}>
            {this.rendeDropDownView(2)}
          </View>
        </View>
        <View style={[styles.subViewStyle, {zIndex: 1001}]}>
          <this.titleLblRender
            title={'Select the fleet the driver\nis going to use'}
          />
          <View
            style={{
              width: itsMobileView ? '100%' : '80%',
              marginTop: itsMobileView ? 10 : 0,
            }}>
            {this.rendeDropDownView(3)}
          </View>
        </View>
      </View>
    );
  };

  render() {
    let isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <Header_View
          title={isEdit ? 'Edit Driver' : 'Add Driver'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}
        />
        <ScrollView>
          {this.renderPasswordView()}
          <View style={styles.mainView}>
            <View style={{height: 15, width: '100%'}} />
            <View style={styles.subViewStyle}>
              <this.titleLblRender title={'Name'} />
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.name}
                placeholder="Enter name of the driver"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({name: name})}
              />
            </View>
            <View style={[styles.subViewStyle, {zIndex: 2982}]}>
              <this.titleLblRender title={'Mobile'} />
              <View
                style={{
                  width: itsMobileView ? '100%' : '80%',
                  marginTop: itsMobileView ? 10 : 0,
                  flexDirection: 'row',
                }}>
                <View style={{width: 100}}>{this.rendeDropDownView(1)}</View>
                <TextInput
                  style={[
                    appConfigStyle.textFieldAddCurrencyStyle,
                    {outline: 0, width: '100%'},
                  ]}
                  value={this.state.mobile}
                  placeholder="Enter mobile"
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={text => this.setState({mobile: text})}
                />
              </View>
            </View>
            <View style={styles.subViewStyle}>
              <Text style={commonStyleSheet.titleNameStyle}>Email</Text>
              <TextInput
                style={[appConfigStyle.textFieldAddCurrencyStyle, {outline: 0}]}
                value={this.state.email}
                placeholder="Enter email"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({email: name})}
              />
            </View>
            {this.renderBranchAndFleetView()}
            <View style={{height: 20}} />
          </View>
        </ScrollView>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  Container: {
    // flex: 1,
    height: windowHeight,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    padding: 2,
    flex: 1,
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 120,
  },
  dropDownViewStyle: {
    marginTop: 5,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    width: itsMobileView ? windowWidth / 1.2 : windowWidth / 2,
    marginLeft: itsMobileView ? 0 : -10,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 2,
    height: 80,
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  iconImageViewStyle: {
    height: 40,
    width: 40,
  },
});
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};
