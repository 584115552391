import React from 'react';

const TextInput = ({placeholder, type, onChangeText, value}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={e => onChangeText(e.target.value)}
      className="w-full border  rounded-md  border-gray-300"
    />
  );
};

export default TextInput;
