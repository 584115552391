import React, {useState} from 'react';
import {ICONS} from '../Constants/Icons';
import Modal from '../Component/UI/modals/Modal';
import ListingsColumnConfiguration from '../Settings/GlobalSettings/ConfigurationSetup/ListingsColumnConfiguration';

const CustomizeTable = () => {
  const [isOpenSidepan, setISOpenSidepan] = useState(false);
  return (
    <div>
      <button
        className=" md:-mt-[10px] p-2 text-sm flex items-center gap-2 hover:bg-gray-100/90 mr-2 rounded"
        onClick={() => setISOpenSidepan(true)}>
        {ICONS.adjustment_vertical}Customize table
      </button>

      {isOpenSidepan && (
        <Modal onCloseModal={() => setISOpenSidepan(false)}>
          <div className=" max-w-[400px]  absolute right-0 top-0 w-full h-full grid place-items-start bg-white  backdrop-blur-sm z-50 ">
            <ListingsColumnConfiguration
              onCloseModal={() => setISOpenSidepan(false)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CustomizeTable;
