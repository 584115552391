import React from 'react';
import {useNavigate} from 'react-router-dom';
import openLinkIcon from '../../assets/whiteOpenLinkIcon.png';
import {CONFIG_CONSTANT} from '../../Constants/ConfigsConstants';

const SubMenu = ({
  sub_menu,
  main_menu,
  background_color,
  text_color,
  setCurrentMenuPosition,
  group_id,
}) => {
  const navigate = useNavigate();
  const menu_click = menu => {
    const sub_menu = document.getElementById(group_id);
    // Remove the class 'group-hover:block'
    sub_menu.classList.remove('group-hover:block');

    // If the menu item has a URL and no sub-menus, navigate
    if (menu.href && (!menu?.sub_menus || menu?.sub_menus?.length === 0)) {
      menu?.isOpenNewTab
        ? window.open(menu.href, '_blank')
        : navigate(menu.href);
    }
    // Re-add the class 'group-hover:block' after 1 second
    setTimeout(() => {
      sub_menu.classList.add('group-hover:block');
    }, 1000);
  };
  const sideMenuSetup = CONFIG_CONSTANT.SUPERADMIN?.sidebar_setup ?? [];

  return (
    <div
      className=" min-h-[150px]  w-[255px] rounded-lg    "
      style={{background: background_color, color: text_color}}>
      <p className="   border-b border-white py-2 px-4 ">{main_menu.title}</p>

      <div className=" mt-3 px-4 flex flex-col gap-3 pb-3">
        {sub_menu
          ?.filter(s_menu =>
            sideMenuSetup?.length > 0
              ? sideMenuSetup?.find(
                  setup_menu =>
                    (setup_menu?.key_name === s_menu?.key_name &&
                      setup_menu?.feature_activated == true) ||
                    s_menu?.is_fixed,
                ) !== undefined
              : true,
          )
          ?.map(menu => {
            return (
              <div
                className="flex items-center justify-start gap-4  cursor-pointer"
                onClick={() => menu_click(menu)}>
                <img className=" w-[30px] " src={menu.icon} alt="" />
                <div className="flex items-center justify-start">
                  <p className=" min-w-[160px]">{menu.title}</p>
                  {menu?.isOpenNewTab && (
                    <img className="h-4 " src={openLinkIcon} alt="" />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SubMenu;
