import React from 'react';
import {Link} from 'react-router-dom';

const LinkableButton = ({children, className, href}) => {
  return (
    <Link
      target={'_blank'}
      to={href}
      className={`px-4  bg-transparent text-gray-700 underline  hover:text-primary duration-500 ${className}`}>
      {children}
    </Link>
  );
};

export default LinkableButton;
