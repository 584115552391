
import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Dimensions,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, seoMixPanelEvent } from '../../Models/MixPannelEnum';
const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false
let listingTitle = '{listing_title}'
let listingDescription = '{listing_description}'


let KeyGroup = 'seo';
export default class SEOListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      metaListTitle: listingTitle,
      metaListDescription: listingDescription,
      metaListCatTitle: '',
      metaListCatDescription: '',

    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,'get','', '', );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        metaListTitle: configs['meta_listing_title'] || listingTitle,
        metaListDescription: configs['meta_listing_description'] || listingDescription,
        metaListCatTitle: configs['meta_listing_category_title'] || '',
        metaListCatDescription  : configs['meta_listing_category_description'] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    let valueArray = [
      this.state.metaListTitle,
      this.state.metaListDescription,
      this.state.metaListCatTitle,
      this.state.metaListCatDescription,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    if (configs.length == 0){
      this.deleteKeysAPI(configs);
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}),'');
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      seoMixPanelEvent(MixpanelEvent.seoListing)
      this.deleteKeysAPI(configs)
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall( urlParm, 'delete','','');
      this.setState({dataLoad: true});
      this.alertView();
    } else {
      this.setState({dataLoad: true});
      this.alertView();
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    if (!this.state.metaListTitle.includes(listingTitle)) {
      AppToast('Meta listing title should contain ' + listingTitle)
    } else if (!this.state.metaListCatDescription.includes(listingDescription)) {
      AppToast('Meta listing description should contain ' + listingDescription)
    } else {
      this.setState({dataLoad: false})
      this.addOrUpdateAppDetailApi()
    }
  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' :'70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Listing</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {'You can add suffix or prefix with default braces for scripting. Example: Purchase from {listing_title}'}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Listing Title</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Listing Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaListTitle: name})}
                value={this.state.metaListTitle}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Listing Description</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Listing Description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaListDescription: name})}
                value={this.state.metaListDescription}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Listing Category Title</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Listing Category Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaListCatTitle: name})}
                value={this.state.metaListCatTitle}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Listing Category Description</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Listing Category Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaListCatDescription: name})}
                value={this.state.metaListCatDescription}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'meta_listing_title',
  'meta_listing_description',
  'meta_listing_category_title',
  'meta_listing_category_description',
];



