import React from 'react';
import {StyleSheet, TouchableOpacity, Image} from 'react-native';
import ToastPopup from '../../HelperClasses/AppLoaderClasses/ToastPopup';
import deleteIcon from '../../assets/delete_icon.png';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';

const DeleteButton = ({onClickDelete}) => {
  return (
    <TouchableOpacity onPress={onClickDelete}>
      <Image
        style={TableCommonStyleSheet.iconStyle}
        resizeMode="center"
        source={deleteIcon}
      />
    </TouchableOpacity>
  );
};

export default DeleteButton;
