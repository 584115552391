import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Linking,
  Alert,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import greenhelp_Icon from './../assets/greenhelpIcon.png';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import AppReaderView from '../Component/AppReaderView';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';

const windowHeight = Dimensions.get('window').height;

const APPConstants = require('../Constants/AppConstants');
export default class HelperLinkView extends Component {
  static propTypes = {
    title: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      showDocurl: '',
    };
  }
  linkBtnAction = async () => {
    var pathURL = APPConstants.commissionsHelperLink;
    if (this.props.title == 'Listings') {
      pathURL = APPConstants.listingsHelperLink;
    } else if (this.props.title == 'Accounts') {
      pathURL = APPConstants.accountsHelperLink;
    } else if (this.props.title == 'Payment Methods') {
      pathURL = APPConstants.paymentMethodHelperLink;
    } else if (this.props.title == 'Shipping Methods') {
      pathURL = APPConstants.shippingMethodHelperLink;
    } else if (this.props.title == 'Commissions') {
      pathURL = APPConstants.commissionsHelperLink;
    } else if (this.props.title == 'Collections') {
      pathURL = APPConstants.collectionsHelperLink;
    } else if (this.props.title == 'Subscription') {
      pathURL = APPConstants.subscriptionHelperLink;
    } else if (this.props.title == 'Promotions') {
      pathURL = APPConstants.promoBannersHelperLink;
    } else if (this.props.title == 'Languages') {
      pathURL = APPConstants.languageHelperLink;
    } else if (this.props.title == 'Currency') {
      pathURL = APPConstants.currencyHelperLink;
    } else if (this.props.title == 'API') {
      pathURL = APPConstants.apiRefrenceLink;
    } else if (this.props.title == 'Website') {
      pathURL = APPConstants.websiteHelperLink;
    } else if (this.props.title == 'Location') {
      pathURL = APPConstants.locationHelperLink;
    } else if (this.props.title == 'Stripe') {
      pathURL = APPConstants.stripeHelperLink;
    } else if (this.props.title == 'Firebase') {
      pathURL = APPConstants.firebaseHelperLink;
    } else if (this.props.title == 'Appstore') {
      pathURL = APPConstants.appstoreHelperLink;
    } else if (this.props.title == 'Branch') {
      pathURL = APPConstants.branchHelperLink;
    } else if (this.props.title == 'Review') {
      pathURL = APPConstants.reviewRatingsHelperLink;
    } else if (this.props.title == 'Media') {
      pathURL = APPConstants.mediaHelperLink;
    } else if (this.props.title == 'Coupon') {
      pathURL = APPConstants.couponHelperLink;
    } else if (this.props.title == 'Tax') {
      pathURL = APPConstants.taxHelperLink;
    }
    if (Platform.OS == 'web') {
      addMixpanelEvent(MixpanelEvent.learnMore, {action: pathURL});
      window.open(pathURL, '_blank');
    } else {
      const supported = await Linking.canOpenURL(pathURL);
      if (supported) {
        await Linking.openURL(pathURL, '_blank');
      } else {
        Alert.alert(`Don't know how to open this URL: ${pathURL}`);
      }
    }
    addMixpanelEvent(MixpanelEvent.learnMore, {action: pathURL});
    this.setState({showDocurl: pathURL});
    // this.child.showHidebtnAction()
  };
  renderInfoView = () => {
    return (
      <View style={{marginTop: 1 - (windowHeight - 70)}}>
        <AppReaderView
          showDocurl={this.state.showDocurl}
          ref={child => (this.child = child)}
        />
      </View>
    );
  };
  render() {
    let title =
      this.props.title == 'Commissions' ? 'Revenue Model' : this.props.title;
    return (
      <View
        style={{
          margin: 5,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: -2,
        }}>
        <TouchableOpacity
          style={styles.headerView}
          onPress={() => this.linkBtnAction()}>
          <Image
            style={tableStyle.iconStyle}
            resizeMode="center"
            source={greenhelp_Icon}
          />
          <View style={{width: 10}} />
          <Text>
            Learn more about
            <Text
              style={[
                CommonStyleSheet.textLabelH3ViewStyle,
                {color: colors.AppNewGreen},
              ]}>{` ${title}`}</Text>
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  headerView: {
    borderRadius: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.Lightgray,
    flexDirection: 'row',
  },
});
