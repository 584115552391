import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TextInput,
  Platform,
  Alert,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';

const AppConstants = require('../../Constants/AppConstants');

const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'social';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class WebGeneralConfigs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textFieldArray : ['','','','','','','','',''],
      dataLoad: false,
      updateUI: false

    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + '?key_group=' + KeyGroup,'get');
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      // console.log('this.state.configs =>', configs);
      this.state.textFieldArray[0] = configs[keysArray[0]] || '';
      this.state.textFieldArray[1] = configs[keysArray[1]] || '';
      this.state.textFieldArray[2] = configs[keysArray[2]] || '';
      this.state.textFieldArray[3] = configs[keysArray[3]] || '';
      this.state.textFieldArray[4] = configs[keysArray[4]] || '';
      this.state.textFieldArray[5] = configs[keysArray[5]] || '';
      this.state.textFieldArray[6] = configs[keysArray[6]] || '';
      this.state.textFieldArray[7] = configs[keysArray[7]] || '';
      this.state.textFieldArray[8] = configs[keysArray[8]] || '';
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateApi = async () => {
    var configs = [];
    for (let a = 0; a < this.state.textFieldArray.length; a++) {
      if (this.state.textFieldArray[a].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[a],
          value: this.state.textFieldArray[a],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    // console.log('configs ==> ', configs)
    if (configs.length != 0) {
      this.setState({ dataLoad: false });
      const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({ configs: configs }));
      this.setState({ dataLoad: true });
      if (responseJson['status'] == true) {
        // AppToast();
        this.deleteKeysAPI(configs);
      } else {
        this.setState({ dataLoad: true });
      }
    } else {
      this.deleteKeysAPI(configs);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      // console.log('configs', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {   
    this.addOrUpdateApi()
  }
  onHandleTextChange = (text,id) => {
    this.state.textFieldArray[id]  = text;
    this.setState({updateUI: !this.state.updateUI});
  };
  renderTextFields = () => {
    var views = [];
    for (let a = 0; a < titlesArray.length; a++) {
      let value = this.state.textFieldArray[a]
      let title = titlesArray[a];
      let placeholder =  'Enter ' + title;
      views.push(<View style={{ marginTop: 20 }}>
        <Text style={appConfigStyle.titleTxtStyle}>{title}</Text>
        <TextInput
          style={[appConfigStyle.txtFieldStyle,{outline:0}]}
          placeholder={placeholder}
          placeholderTextColor={colors.placeholderColor}
          onChangeText={text => this.onHandleTextChange(text,a)}
          value={value}
        />
      </View>)
    }
    return views
  }
  //MARK:-  UI 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 130, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Social Info</Text>
        <Text style={appConfigStyle.subTitleStyle}> {AppConstants.plainTextString}  </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5, marginTop: -40}}>
            {this.renderTextFields()}
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

const keysArray = [
  'facebook_pageurl',
  'twitter_handleurl',
  'instagram_url',
  'youtube_channelurl',
  'tiktok_url',
  'spotify_url',
  'whatsapp_number',
  'telegram_url',
  'snapchat',
]
const titlesArray = [
  'Facebook page URL',
  'Twitter handle URL',
  'Instagram URL',
  'Youtube channel URL',
  'Tiktok URL',
  'Spotify URL',
  'Whatsapp number',
  'Telegram URL',
  'Snapchat',
]