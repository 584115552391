import React from 'react';
import {Switch} from 'react-native';

const BooleanField = ({
  current_data,
  update_data,
  title,
  note,
  id,
  placeholder,
  type,
  isRequired,
}) => {
  return (
    <div className="space-y-1  ">
      <p
        className={[
          ' text-gray-600',
          isRequired &&
            "flex-none relative after:content-['*'] after:text-red-500 after:-top-[5px] after:-right-[12px] ",
        ].join(' ')}>
        {title}
      </p>
      <div
        className="relative flex justify-between items-center group w-18 cursor-pointer"
        onClick={() => update_data(!current_data)}>
        <input
          type="checkbox"
          class="absolute hidden left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"
          checked={current_data}
        />
        <span class="w-[50px] h-6 flex items-center flex-shrink-0   p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-5 after:h-5 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-6 group-hover:after:translate-x-1"></span>
      </div>

      {note && <p className="text-xs mt-2 italic">{note}</p>}
    </div>
  );
};

export default BooleanField;
