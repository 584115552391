import React, {useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import SidePan from '../Shared/SidePan';

const ParentTest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default ParentTest;
