import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Image,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import commonStyle from '../../StyleSheets/CreateTenantCommonStyleSheet'
//'../StyleSheets/CreateTenantCommonStyleSheet';
import googleMap_Icon from '../../assets/googleMapIcon.png';
import openStreetMap_Icon from '../../assets/openStreetMapIcon.png';
import {AppToast} from '../../HelperClasses/AppToast';
import Switch from "react-switch";
import {MixpanelEvent, mobileIntegrationMixPanelEvent } from '../../Models/MixPannelEnum';
import { PaidFeatureTextView } from '../../Component/CommonComponents';
import hideIcon from '../../assets/hideEye.png';
import showIcon from '../../assets/showEye.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');

let titleArray = ['Google Maps', 'Openstreet map'];
let imagesArray = [googleMap_Icon, openStreetMap_Icon];
const APPURL = require('../../Constants/URLConstants');
let KeyGroup = 'address';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;
let itsMobileView = windowWidth < fixedWidth  ?  true : false


let mapType = ['google_map','openstreet_map']

export default class MapsIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      branchAppID: '',
      selectedMapType: 1,
      mapLocationSwitch: false,
      googleMapKey: '',
      googleMapReverseKey:'',
      googleMapPlacesKey:'',
      switchArray: [false, false, false],
      hideShowGeoReverse: false,
      hideShowMapPlaces: false,
    };
  }
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config + `?key_group=${KeyGroup}`, 'get')
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs']
      this.state.switchArray[0] = keysArray[1] || false
      this.state.switchArray[1] = keysArray[2] || false
      this.state.switchArray[2] = keysArray[3] || false
      if (configs['map_type']) {
        this.state.selectedMapType = configs['map_type'] == 'google_map' ? 0 : 1
      }
      this.setState({
        googleMapKey: configs['google_map_api_key'] || '',
        googleMapReverseKey: configs['google_map_reverse_geocode_api_key'] || '',
        googleMapPlacesKey: configs['google_map_places_api_key'] || '',
      })
      console.log('configs', configs)
     }
    this.setState({dataLoad: true})
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = []
    var valueData = []
    valueData.push(this.state.selectedMapType == 0 ? mapType[0] :mapType[1])
    for (let i = 0; i < this.state.switchArray.length; i++) {
      valueData.push(this.state.switchArray[i])
    }
    if (this.state.selectedMapType == 0) {
      if (this.state.googleMapKey.length != 0) {
        let fDict2 = {
          key_group: KeyGroup,
          key: fieldsArray[0],
          value: this.state.googleMapKey,
          secured: false,
        }
        configs.push(fDict2)
      }
      if (this.state.googleMapReverseKey.length != 0) {
        let fDict2 = {
          key_group: KeyGroup,
          key: fieldsArray[1],
          value: this.state.googleMapReverseKey,
          secured: true,
        }
        configs.push(fDict2)
      }
      if (this.state.googleMapPlacesKey.length != 0) {
        let fDict2 = {
          key_group: KeyGroup,
          key: fieldsArray[2],
          value: this.state.googleMapPlacesKey,
          secured: false,
        }
        configs.push(fDict2)
      }
    }
    for (let i = 0; i < keysArray.length; i++) {
      let fDict2 = {
        key_group: KeyGroup,
        key: keysArray[i],
        value: valueData[i],
        secured: false,
      }
      configs.push(fDict2)
    }


    const responseJson = await networkService.networkCall(APPURL.URLPaths.config,  'post',  JSON.stringify({configs: configs}) )
    // console.log('configs', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      mobileIntegrationMixPanelEvent(MixpanelEvent.mapIntegration)
      if (this.state.selectedMapType == 0) {
        this.deleteKeysAPI(configs)
      } else {
        this.setState({dataLoad: true})
        this.alertView('Uploaded successfully')
      }
    } else {
      this.setState({dataLoad: true});
      this.alertView(responseJson);
    }
  }
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < paramArray.length; q++) {
      deleteParms.push(paramArray[q])
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key']
      let index = deleteParms.indexOf(name)
      deleteParms.splice(index, 1)
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString()
      console.log('parms',parms)
      let urlParm = APPURL.URLPaths.config + '?key=' + parms
      const responseJson = await networkService.networkCall(urlParm, 'delete')
      console.log('responseJson === >',responseJson)
      this.alertView('Uploaded successfully')
    } else {
      this.alertView('Uploaded successfully')
    }
    this.setState({dataLoad: true})
  }
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  didSelectSetting = id => {
    // console.log('id', id);
    this.setState({selectedMapType: id});
  };
  switchSattusBtnAction(id) {
    this.state.switchArray[id] = !this.state.switchArray[id];
    this.setState({updateUI: !this.state.updateUI});
  }
  hideShowEyeBtnAction(id) {
    if (id == 1) {
      this.setState({hideShowGeoReverse: !this.state.hideShowGeoReverse});
    } else {
      this.setState({hideShowMapPlaces: !this.state.hideShowMapPlaces});
    }
  }
  //MARK:-  UIs 
  renderDeliveryView = () => {
    var view = [];
    for (let a = 0; a < 2; a++) {
      view.push(
        <TouchableOpacity style={ this.state.selectedMapType == a  ? commonStyle.highlightCustomViewStyle : commonStyle.customViewStyle}
          onPress={id => this.didSelectSetting(a)}>
          <Image style={commonStyle.iconImageViewStyle} resizeMode="contain" source={imagesArray[a]}  />
          <Text style={commonStyle.customViewTitleStyle}>{titleArray[a]}</Text>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  renderGoogleMapField = () => {
    // if (this.state.selectedMapType == 0) {
    return (
      <View style={{marginTop: 20,display:this.state.selectedMapType == 0 ? 'flex' : 'none' }}>
        <Text style={appConfigStyle.titleTxtStyle}> {this.state.selectedMapType == 0 ? 'Google' : ''} API Key
        </Text>
        <TextInput
          style={[appConfigStyle.txtFieldStyle,{outline:0}]}
          placeholder="Enter API Key"
          placeholderTextColor={colors.placeholderColor}
          onChangeText={name => this.setState({googleMapKey: name})}
          value={this.state.googleMapKey}
        />
        <View style={{ height: 20 }} />
        <Text style={appConfigStyle.titleTxtStyle}>Google Map Reverse Geocode API Key </Text>
        <View style={CommonStyleSheet.customTxtFieldViewStyle}>
          <TextInput
            style={[CommonStyleSheet.customTxtFieldStyle, { outline: 0 }]}
            placeholder="Enter Google Map Reverse Geocode API Key "
            placeholderTextColor={colors.placeholderColor}
            onChangeText={name => this.setState({ googleMapReverseKey: name })}
            value={this.state.googleMapReverseKey}
            secureTextEntry={!this.state.hideShowGeoReverse}
          />
          <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(1)}>
            <Image
              style={{ height: 20, width: 20 }}
              resizeMode="contain"
              source={this.state.hideShowLivekey ? showIcon : hideIcon}
            />
          </TouchableOpacity>
        </View>
        <View style={{ height: 20 }} />
        <Text style={appConfigStyle.titleTxtStyle}>Google Map Places API Key</Text>
        <View style={CommonStyleSheet.customTxtFieldViewStyle}>
          <TextInput
            style={[CommonStyleSheet.customTxtFieldStyle, { outline: 0 }]}
            placeholder="Enter Google Map Places API Key"
            placeholderTextColor={colors.placeholderColor}
            onChangeText={name => this.setState({ googleMapPlacesKey: name })}
            value={this.state.googleMapPlacesKey}
            secureTextEntry={!this.state.hideShowMapPlaces}
          />
          <TouchableOpacity onPress={() => this.hideShowEyeBtnAction(2)}>
            <Image
              style={{ height: 20, width: 20 }}
              resizeMode="contain"
              source={this.state.hideShowLivekey ? showIcon : hideIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
    )
    // } else {
    //   return <View></View>;
    // }
  };
  renderAllSwitches = props => {
    var views = []
    let loopCount = this.state.selectedMapType == 0 ? 3 : 1
    for (let i = 0; i < 3; i++) {
      views.push(
        <View style={{flexDirection: 'row', marginTop: 20}}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.switchSattusBtnAction(i)}
            checked={this.state.switchArray[i]}
            height={22}
            width={50}
          />
          <Text style={appConfigStyle.statusTxtStyle}>{switchTitleStrings[i]}</Text>
        </View>,
      );
    }
    return views
  }
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={tableStyle.listTitleText}>MAP Integration</Text>
          <View style={{display: this.state.selectedMapType == 0 ? 'flex' : 'none' }}>
            {PaidFeatureTextView()}
          </View>
        </View>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20, flexDirection: itsMobileView ? 'column' :'row'}}>
              <this.renderDeliveryView />
            </View>
            <this.renderGoogleMapField />
            <this.renderAllSwitches />
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let paramArray = [
  'google_map_api_key',
  'map_type',
  'google_map_reverse_geocode_api_key',
  'google_map_places_api_key',
  'near_by_stores_collection_enabled',
  'listing_map_location_selector_enabled',
  'account_map_location_selector_enabled',
]
let fieldsArray = [
  'google_map_api_key',
  'google_map_reverse_geocode_api_key',
  'google_map_places_api_key',
]
let keysArray = [
  'map_type',
  'near_by_stores_collection_enabled',
  'listing_map_location_selector_enabled',
  'account_map_location_selector_enabled',
]

const switchTitleStrings = [
  'Activate nearby accounts in home page',
  'Activate location in listing submission',
  'Activate location in account submission ',
];
