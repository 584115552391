import React, {useEffect, useState} from 'react';
import {getConfigsByKey} from '../../Actions/getConfigsByKey';
import SwitchButton from '../../../Shared/Switch';
import ConfigurationBox from './ConfigurationBox';
import PageHeader from '../../../Component/Layout/PageHeader';
import {saveConfigsData} from '../../Actions/SaveConfigsData';
import AppLoader from '../../../HelperClasses/AppLoaderClasses/AppLoader';
import {AllMenus} from '../../../Constants/AllMenus';
import {SaveConfigsDataByKey} from '../../Actions/SaveConfigsDataByKey';
import {CONFIG_CONSTANT} from '../../../Constants/ConfigsConstants';
import ColorConfigurationSetup from './ColorConfigurationSetup';
import {listing_table_columns} from '../../../Constants/ListingListColumns';
import {ICONS} from '../../../Constants/Icons';
import SaveButton from '../../../Component/SaveButton';
import SecondryButton from '../../../Component/SecondryButton';

const ListingsColumnConfiguration = ({onCloseModal}) => {
  const [activated_list, setActivatedList] = useState([]);
  const [deactivated_list, setDeactivatedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drag_index, setDragIndex] = useState(null);
  useEffect(() => {
    loadConfigs();
  }, []);

  //loadConfigs();
  const loadConfigs = async () => {
    setIsLoading(true);

    getConfigsByKey('superadmin').then(config => {
      setActivatedList(
        config?.listing_columns?.length > 0
          ? config?.listing_columns?.filter(mn => mn.feature_activated)
          : [
              ...listing_table_columns?.map(it => ({
                plan: '',
                device: '',
                country: '',
                title: it.title,
                key_name: it?.key_name,
                parent_key: null,
                feature_activated: true,
              })),
            ],
      );
      setDeactivatedList(
        config?.listing_columns?.length > 0
          ? config?.listing_columns?.filter(mn => !mn.feature_activated)
          : [],
      );

      setIsLoading(false);
    });
  };

  const onSave = async ({is_reset = false}) => {
    setIsLoading(true);
    const simplifiedObject = {};

    for (const key in activated_list) {
      simplifiedObject[key] = activated_list[key].value;
    }

    await SaveConfigsDataByKey({
      form_data: simplifiedObject,
      config_key_group: 'superadmin',
      config_key_name: 'listing_columns',
      value: is_reset
        ? listing_table_columns?.map(it => ({
            plan: '',
            device: '',
            country: '',
            title: it.title,
            key_name: it?.key_name,
            parent_key: null,
            feature_activated: true,
          }))
        : [...activated_list, ...deactivated_list],
    });

    loadConfigs();
    window.location.reload();
  };

  //

  const handleDragStart = (event, item_index) => {
    setDragIndex(item_index);
  };

  // allowDrop: Allow the drop action by preventing default behavior
  const allowDrop = e => {
    e.preventDefault();
  };

  // onDrop: Handle sorting and updating the array
  const onDrop = dropIndex => {
    const updatedArray = [...activated_list];

    // Remove the dragged item from its original position
    const draggedItem = updatedArray.splice(drag_index, 1)[0];

    // Insert the dragged item into its new position
    updatedArray.splice(dropIndex, 0, draggedItem);

    // Update the state with the sorted array
    setActivatedList(updatedArray);
    setDragIndex(null);
  };

  //
  const onCheckClick = menu => {
    const isAvailable = activated_list?.find(
      m => m.key_name == menu?.key_name && m.feature_activated,
    );

    if (isAvailable) {
      setActivatedList([
        ...activated_list?.filter(m => m.key_name !== menu?.key_name),
      ]);
      setDeactivatedList([
        ...deactivated_list,
        {...menu, feature_activated: false},
      ]);
    } else {
      setDeactivatedList([
        ...deactivated_list?.filter(m => m.key_name !== menu?.key_name),
      ]);
      setActivatedList([...activated_list, {...menu, feature_activated: true}]);
    }
  };

  return (
    <div className=" w-full p-4    relative">
      <p className=" text-2xl  font-medium  text-primary   w-full font-sans">
        Customize Table
      </p>
      <div className=" mt-2   h-[calc(100vh-110px)] overflow-y-auto rounded-md    flex flex-col gap-8">
        <div className=" flex flex-col gap-2">
          <p className=" font-medium  text-AppTitleBlack   w-full font-sans">
            Fields visible in table
          </p>
          {activated_list
            .filter(men => men.feature_activated)
            .map((menu, index) => {
              return (
                <div
                  key={menu?.key_name}
                  className="flex items-center justify-start gap-2  py-1.5"
                  draggable
                  onDragStart={event => handleDragStart(event, index)}
                  onDragOver={allowDrop}
                  onDrop={() => onDrop(index)}
                  onChange={() => onCheckClick(menu)}>
                  {ICONS?.drag}
                  <input
                    type="checkbox"
                    className="border-AppNewGreen !outline-none ring-0 rounded-sm "
                    name=""
                    id=""
                    checked={
                      activated_list?.find(
                        con => con?.key_name === menu?.key_name,
                      )?.feature_activated
                    }
                  />

                  <p className=" text-base   font-normal  text-AppTitleBlack font-sans items-center">
                    {menu.title}
                  </p>
                </div>
              );
            })}
        </div>
        {deactivated_list?.length > 0 && (
          <div className=" flex flex-col gap-2">
            <p className=" font-medium  text-AppTitleBlack   w-full font-sans">
              Fields not shown in table
            </p>
            {deactivated_list.map((menu, index) => {
              return (
                <div
                  key={menu?.key_name}
                  className="flex items-center    :justify-start gap-2  py-1.5">
                  <input
                    type="checkbox"
                    className="border-AppNewGreen !outline-none ring-0 rounded-sm ml-1 "
                    name=""
                    id=""
                    checked={
                      activated_list?.find(
                        con => con?.key_name === menu?.key_name,
                      )?.feature_activated
                    }
                    onChange={() => onCheckClick(menu)}
                  />

                  <p className=" text-base   font-normal  text-AppTitleBlack font-sans items-center">
                    {menu.title}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <button
          onClick={() => onSave({is_reset: true})}
          className="text-primary/90 hover:text-primary py-1.5  ">
          Reset to default
        </button>

        <div className=" flex items-center gap-2">
          <SecondryButton
            btnAction={() => onCloseModal()}
            btnTitle={'Cancel'}
            showSaveBtn={true}
          />
          <SaveButton
            saveBtn={() => onSave({is_reset: false})}
            saveBtnTitle={'Apply'}
            showSaveBtn={true}
          />
        </div>
      </div>{' '}
      {isLoading && (
        <div className="h-full w-full bg-black/5 absolute inset-0   z-0">
          <AppLoader enable={!isLoading} />
        </div>
      )}
    </div>
  );
};

export default ListingsColumnConfiguration;
