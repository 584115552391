import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  Image,
  TextInput,
  Dimensions,
  Alert,
  Platform,
} from 'react-native';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import {Gradient} from 'react-gradient';
import EmptyListUI from '../Component/AppEmptyList';
import {AppToast} from '../HelperClasses/AppToast';
import {
  dateConversionFromTimeStamp,
  getThumbnailImage,
} from '../HelperClasses/SingletonClass';
import placeholder from '../assets/pPlaceholder.png';
import appMessage from '../Constants/AppConstantsMessage';
import HeaderView from '../Component/AppHeader';
import UserDetail from './../Users/UserDetail';
import starIcon from '../assets/star.png';
import listIcon from '../assets/listIcon.png';
import gridIcon from '../assets/gridIcon.png';
import selectedListIcon from '../assets/selectedListIcon.png';
import selectedGridIcon from '../assets/selectedGridIcon.png';
import whiteTick from '../assets/whiteTick.png';
import downArrow_Icon from '../assets/downArrowIcon.png';
import appConfigStyle from '../StyleSheets/AppConfigStyleSheet';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import HelperLinkView from '../Component/HelperLinkView';
import AppConstants from '../Constants/AppConstants';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let itsMobileView = windowWidth < fixedWidth ? true : false;
let PerPage = 25;

class ReviewRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewsArray: [],
      updateUI: false,
      dataLoad: false,
      stopPage: false,
      selectedID: 0,
      haveData: false,
      showUserDetail: false,
      userID: '',
      userDetailData: {},
      showGridView: false,
      showDropDown: false,
      perPage: PerPage,
      totalRecords: 0,
      showFilterView: false,
      selectedType: FilterStaticArray[0]['value'],
    };
  }
  componentDidMount() {
    this.getApi();
    this.mixPannelInfo();
  }
  getApi() {
    pageNo = 1;
    this.loadReviewsApi();
  }
  mixPannelInfo() {
    addMixpanelEvent(MixpanelEvent.viewReview, {page: pageNo});
  }
  //MARK:-  APIS Method 
  loadReviewsApi = async () => {
    this.setState({dataLoad: false});
    let type = this.state.selectedType.toLowerCase();
    var path = `?page=${pageNo}&per_page=${this.state.perPage}&type=${type}`;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.reviews + path,
      'get',
    );
    if (responseJson['status'] == true) {
      var objD = responseJson['data']['reviews'];
      this.state.totalRecords = responseJson['data']['total_records'];
      this.setState({reviewsArray: []});
      if (objD.length != 0) {
        this.setState({stopPage: false});
        for (let objc of objD) {
          this.state.reviewsArray.push(objc);
        }
      } else {
        pageNo = pageNo - 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.reviewsArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:- Button Actions
  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadReviewsApi();
      }
    } else if (index == 2) {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadReviewsApi();
      }
    }
    this.mixPannelInfo();
    this.setState({updateUI: !this.state.updateUI});
  }

  backBtnHandler() {
    // this.setState({addPromoBannerBool: false})
  }
  viewUserDetailBtnAction = user => {
    this.props.navigate(`/user/${user['id']}`);
  };
  didSelectPerPage = (item, index) => {
    this.state.perPage = item;
    this.setState({showDropDown: false});
    pageNo = 1;
    this.loadReviewsApi();
  };
  didSelectFilter(id) {
    this.state.selectedType = FilterStaticArray[id]['value'];
    this.setState({showFilterView: false});
    this.loadReviewsApi();
  }
  /*  UI   */

  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{marginLeft: 0}}
        keyExtractor={(item, index) => index + 98999}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.reviewsArray.length; i++) {
      let user = this.state.reviewsArray[i]['user'];
      views.push(
        <FlatList
          data={[
            user,
            this.state.reviewsArray[i]['rating'],
            this.state.reviewsArray[i]['title'],
            this.state.reviewsArray[i]['content'],
            this.state.reviewsArray[i]['images'][0] || '',
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
          keyExtractor={(item, index) => index + 9989}
          key={'C'}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 4) {
      let fileURl = getThumbnailImage(item);
      return (
        <View style={styles.columnTitleViewStyle}>
          <Image
            style={tableStyle.itemImageViewStyle}
            source={item.length == 0 ? placeholder : fileURl}
          />
        </View>
      );
    } else if (index == 0) {
      let name = `${item['first_name']} ${item['last_name']}`;
      return (
        <TouchableOpacity
          style={styles.firstCellViewStyle}
          onPress={() => this.viewUserDetailBtnAction(item)}>
          <Text style={tableStyle.mainTitleStyle}>{name}</Text>
        </TouchableOpacity>
      );
    } else if (index == 1) {
      var starView = [];
      for (let b = 0; b <= item; b++) {
        starView.push(
          <View style={{flexDirection: 'row', margin: 5}}>
            <Image source={starIcon} style={{height: 15, width: 15}} />
          </View>,
        );
      }
      return <View style={styles.columnTitleViewStyle}>{starView}</View>;
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.ratingDescriptionTextStyle}>{item}</Text>
        </View>
      );
    }
  };
  gradientButtons = name => {
    if (name['id'] == 2 && pageNo == 1) {
      return <View />;
    } else if (
      name['id'] == 1 &&
      this.state.reviewsArray.length == 0 &&
      pageNo > 1
    ) {
      return <View />;
    }
    return (
      <View>
        <View style={tableStyle.grandientBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            style={{height: 30, width: 100, borderRadius: 3, marginRight: 30}}
            angle="0deg">
            <TouchableOpacity
              style={tableStyle.nextPreviousViewStyle}
              onPress={() => this.nextPreviousBtnAction(name['id'])}>
              <Text style={tableStyle.saveBtnTxtStyle}>{name['name']}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };

  EmptyListRender = () => {
    if (this.state.reviewsArray.length == 0) {
      if (this.state.haveData && pageNo == 1) {
        return (
          <View style={{height: '70%'}}>
            <EmptyListUI
              showImage={true}
              titleString={appMessage.emptyReviewRatingTitleString}
              subtitleString={appMessage.reviewRatingMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  renderHeaderBtn = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          marginRight: 10,
          zIndex: 100,
          marginTop: itsMobileView ? 10 : -5,
        }}>
        <View style={tableStyle.configurationBarViewStyle}>
          <View>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => this.setState({showGridView: false})}>
              <Image
                resizeMode={'cover'}
                style={tableStyle.viewIconsStyle}
                source={!this.state.showGridView ? selectedListIcon : listIcon}
              />
              <Text
                style={[
                  CommonStyleSheet.tabBtnViewStyle,
                  {
                    color: !this.state.showGridView
                      ? colors.AppNewGreen
                      : colors.Appgray,
                  },
                ]}>
                List
              </Text>
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: !this.state.showGridView
                  ? colors.AppNewGreen
                  : colors.TransparentColor,
                height: 2,
                width: '100%',
                marginTop: 5,
              }}
            />
          </View>
          <View style={{width: 20}} />
          <View>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() =>
                this.setState({showGridView: true, showKanbanView: false})
              }>
              <Image
                resizeMode={'cover'}
                style={tableStyle.viewIconsStyle}
                source={this.state.showGridView ? selectedGridIcon : gridIcon}
              />
              <Text
                style={[
                  CommonStyleSheet.tabBtnViewStyle,
                  {
                    color: this.state.showGridView
                      ? colors.AppNewGreen
                      : colors.Appgray,
                  },
                ]}>
                Grid
              </Text>
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: this.state.showGridView
                  ? colors.AppNewGreen
                  : colors.TransparentColor,
                height: 2,
                width: '100%',
                marginTop: 5,
              }}
            />
          </View>
          <View style={{width: 10}} />
        </View>
      </View>
    );
  };
  renderGridElements = () => {
    var view = [];

    for (let a = 0; a <= this.state.reviewsArray.length - 1; a++) {
      let item = this.state.reviewsArray[a];
      let photo = item['images'][0] || '';
      let user = item['user'];
      let name = `${user['first_name']} ${user['last_name']}`;
      let fileURl = getThumbnailImage(photo);
      var starView = [];
      let rating = item['rating'];
      for (let b = 0; b <= rating; b++) {
        starView.push(
          <View style={{flexDirection: 'row', margin: 2}}>
            <Image source={starIcon} style={{height: 10, width: 10}} />
          </View>,
        );
      }
      view.push(
        <TouchableOpacity
          style={tableStyle.gridContentViewsStyle}
          onPress={() => this.viewUserDetailBtnAction(item['id'])}>
          <Image
            style={tableStyle.gridImageViewStyle}
            source={photo.length == 0 ? placeholder : fileURl}
          />
          <View style={{padding: 5}}>
            <Text style={tableStyle.themeTitleTxtStyle} numberOfLines={1}>
              {name}
            </Text>
          </View>
          <View
            style={{
              padding: 5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text numberOfLines={1} style={tableStyle.gridPriceViewStyle}>
              {' '}
              {item['title']}
            </Text>
            <View style={{justifyContent: 'flex-end', flexDirection: 'row'}}>
              {starView}
            </View>
          </View>
        </TouchableOpacity>,
      );
    }
    return view;
  };
  renderGridView = () => {
    return (
      <ScrollView>
        <View style={tableStyle.gridViewStyle}>
          <this.renderGridElements />
        </View>
      </ScrollView>
    );
  };
  renderListView = () => {
    return (
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View style={{paddingLeft: 10}}>
          <View
            style={{
              height: 40,
              backgroundColor: colors.AppLightGreen,
              borderTopStartRadius: 5,
              borderTopEndRadius: 5,
            }}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  entriesDropDown = props => {
    if (this.state.showDropDown == true) {
      return (
        <View style={tableStyle.perPageContainerViewStyle}>
          <View style={{height: 4}} />
          <FlatList
            data={['25', '50', '100']}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectPerPage(item, index)}
        style={{margin: 3}}>
        <View
          style={[
            tableStyle.perPageDropDownViewStyle,
            {
              backgroundColor:
                item == this.state.perPage ? colors.BGBlueColor : '',
            },
          ]}>
          <Image
            source={whiteTick}
            style={{height: 16, width: item == this.state.perPage ? 16 : 0}}
          />
          <Text style={CommonStyleSheet.dropdownCellWhiteTextStyle}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderPerView = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 20,
        }}>
        <Text>Show rows</Text>
        <View style={{flexDirection: 'column', zIndex: 9999}}>
          <TouchableOpacity
            onPress={() =>
              this.setState({showDropDown: !this.state.showDropDown})
            }
            style={tableStyle.perPagedropDownView}>
            <Text> {this.state.perPage} </Text>
            <Image
              style={
                this.state.showDropDown == true
                  ? appConfigStyle.upArraowIconStyle
                  : appConfigStyle.downArraowIconStyle
              }
              resizeMode="contain"
              source={downArrow_Icon}
            />
          </TouchableOpacity>
          <this.entriesDropDown />
        </View>
      </View>
    );
  };
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (
        <View style={tableStyle.bottomViewStyle}>
          <this.gradientButtons name={'Next'} id={1} />
          <this.gradientButtons name={'Previous'} id={2} />
          <this.renderPerView />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderMainView = () => {
    if (this.state.showGridView) {
      return <this.renderGridView />;
    } else {
      return <this.renderListView />;
    }
  };
  renderHeaderView = () => {
    return (
      <View
        style={[
          tableStyle.headerContainer,
          {
            justifyContent: itsMobileView ? 'flex-start' : 'space-between',
            flexDirection: 'row',
          },
        ]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 4,
            marginLeft: 10,
          }}>
          <Text style={tableStyle.titleTextStyle}>{'Reviews & Ratings'}</Text>
        </View>
        {this.renderTypeView()}
      </View>
    );
  };
  renderTypeView = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: itsMobileView ? 5 : 0,
        }}>
        <View style={{position: 'relative', marginRight: 0, marginTop: 7}}>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
            onPress={() =>
              this.setState({showFilterView: !this.state.showFilterView})
            }>
            <Text
              style={[
                CommonStyleSheet.tabBtnViewStyle,
                {
                  color: this.state.showFilterView
                    ? colors.AppNewGreen
                    : colors.Appgray,
                },
              ]}>
              {this.state.selectedType}
            </Text>
            <View style={{marginLeft: 5, marginTop: 0}}>
              <Image
                style={
                  this.state.showFilterView == true
                    ? appConfigStyle.upArraowIconStyle
                    : appConfigStyle.downArraowIconStyle
                }
                resizeMode="contain"
                source={downArrow_Icon}
              />
            </View>
          </TouchableOpacity>
          <this.renderFilterView />
        </View>
      </View>
    );
  };
  renderFilterView = () => {
    if (this.state.showFilterView) {
      return (
        <View style={tableStyle.filterContainerViewStyle}>
          <View style={{display: this.state.showFilterView ? 'flex' : 'none'}}>
            <View
              style={[
                tableStyle.filterViewStyle,
                {flexDirection: 'row-reverse'},
              ]}>
              <FlatList
                data={FilterStaticArray}
                renderItem={this.renderSortCell}
                extraData={this.state}
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => index + 129}
                key={'FL'}
                style={{marginBottom: 10}}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderSortCell = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectFilter(index)}
        style={{
          margin: 5,
          backgroundColor:
            this.state.selectedType == item['value']
              ? colors.SimonGray
              : colors.TransparentColor,
          padding: 5,
          borderRadius: 2,
        }}>
        <Text>{item['value']}</Text>
      </TouchableOpacity>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{zIndex: 10}}>
          <this.renderHeaderView />
        </View>
        <View style={{zIndex: 1}}>
          <View style={tableStyle.mainView}>
            {this.renderMainView()}
            <this.EmptyListRender />
            <this.renderBottomView />
          </View>
          <HelperLinkView title={'Review'} />
          <Appload enable={this.state.dataLoad} />
        </View>
      </SafeAreaView>
    );
  }
}

export default withRouter(ReviewRating);

const commonWidth = 5.0;

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  headerViewstyle: {
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 777,
  },
  subViewStyle: {
    backgroundColor: 'white',
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 10,
    borderWidth: 1,
    width: windowWidth < fixedWidth ? '100%' : '20%',
    height: 40,
    alignItems: 'center',
    flexDirection: 'row',
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    // justifyContent: 'center',
    // padding: 0,
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  firstCellViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    // padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    // justifyContent: 'center',
  },
});

const tableHeaderString = ['Name', 'Rating', 'Title', 'Content', 'Image'];
const FilterStaticArray = [
  {key: 'accounts', value: 'Accounts'},
  {key: 'listings', value: 'Listings'},
];
