import React, {useState} from 'react';
import HeaderView from '../HeaderView';
import TextInput from '../../UI/Form/TextInput';
import TextArea from '../../UI/Form/TextArea';
import SubscriptionForm from './SubscriptionForm';
import {AppToast} from '../../HelperClasses/AppToast';
import {ToastContainer} from 'react-toastify';
import {checkSubscriptionForm} from './actions/checkSubscriptionForm';
import submitSubscriptionListing from './actions/submitSubscriptionListing';
import AppLoader from '../../HelperClasses/AppLoaderClasses/AppLoader';
import ToastPopup from '../../HelperClasses/AppLoaderClasses/ToastPopup';

const AddSubscriptionListing = ({onCloseModal, load_subscription_list}) => {
  const [subscriptin_listing_form, setSubscriptin_listing_form] = useState({
    title: '',
    description: '',
    amount: 0,
    sku: '',
    image_path: '',
    allowed_listings: 0,
    fee_fixed_per_order: 0,
    commission_percent_per_order: 0,
    currency_id: null,
    expiry_days: 0,
    type: '',
    active: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  // onUpdateForm
  const onUpdateForm = ({key, value}) => {
    setSubscriptin_listing_form({
      ...subscriptin_listing_form,
      [key]: value,
    });
  };

  // saveListingButtonAction
  const saveListingButtonAction = () => {
    const checkup = checkSubscriptionForm({
      form_data: subscriptin_listing_form,
    });
    if (checkup) {
      submitSubscriptionListing({
        form_data: subscriptin_listing_form,
        setIsLoading,
        onSuccess,
      });
    }
  };

  const onSuccess = () => {
    ToastPopup({
      type: 'success',
      message: 'Subscription Listing Added Successfully',
    });
    load_subscription_list();
    onCloseModal();
  };

  return (
    <div className="  w-full h-full py-4">
      <HeaderView
        title={'Add New Subscription'}
        saveBtnTitle={'Save'}
        saveBtn={() =>
          !isLoading
            ? saveListingButtonAction()
            : ToastPopup({type: 'warning', message: 'Please wait...'})
        }
        backBtn={() => onCloseModal()}
      />
      {/* add Listing form */}
      <SubscriptionForm
        form_state={subscriptin_listing_form}
        onUpdateForm={onUpdateForm}
      />

      <ToastContainer />
      <AppLoader enable={!isLoading} />
    </div>
  );
};

export default AddSubscriptionListing;
