import React from 'react';

const SelectFromList = ({
  list,
  selected_value,
  name,
  id,
  setValue,
  default_text,
}) => {
  return (
    <select
      name={name}
      id={id}
      onChange={e => setValue(e.target.value)}
      className="w-full  border rounded-md border-gray-300">
      <option selected disabled>
        {default_text}
      </option>
      {list.map(li => {
        return (
          <option selected={selected_value === li.value} value={li.value}>
            {li.label}
          </option>
        );
      })}
    </select>
  );
};

export default SelectFromList;
