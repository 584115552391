import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  Dimensions,
  Platform,
  SafeAreaView,
  Alert,
  TextInput,
} from 'react-native';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import Header_View from '../HeaderView';
import gTick_Icon from './../../assets/grayTickIcon.png';
import greenTickIcon from './../../assets/greenTickIcon.png';
import PropTypes from 'prop-types';
import colors from '../../HelperClasses/AppColor';
import SettingsStyleSheet from '../../StyleSheets/SettingStyleSheet';
import {ToastContainer} from 'react-toastify';
import networkService from '../../Network/NetworkManager';
import LanguageField from './LanguageFields';
import {AppToast} from '../../HelperClasses/AppToast';
import {checkUserRoleModule} from '../../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../../Models/Enum';
import AppConstants from '../../Constants/AppConstants';
import SettingStyleSheet from '../../StyleSheets/SettingStyleSheet';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import searchIcon from '../../assets/search.png';
import AppEmptyList from '../../Component/AppEmptyList';
import closeIcon from '../../assets/closeIcon.png';
import ConstantArray from '../../Constants/ConstantArray';
import BackBtnView from '../../Component/BackBtnView';
import {withRouter} from '../../wrappers/withRouter';
import HelperVideoView from '../../Component/HelperVideoView';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let perHeight = (windowHeight * 20) / 100;

let itsMobileView = windowWidth < fixedWidth ? true : false;
class AppTranslation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuSelectedIndex: '',
      translationsGroupArray: [],
      screensNameArray: [],
      updateUI: false,
      dataLoad: false,
      writeP: AppConstants.defaultRead,
      typingTimeout: 0,
      haveData: false,
      searchKey: '',
      selectedTranslationGroupValue: '',
      languageArray: '',
    };
  }
  static propTypes = {
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
  };
  componentDidMount() {
    this.loadLanguageApi();
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.TRANSLATIONMANAGE, res => {
      this.setState({writeP: res[1]});
    });
  };
  //MARK:-  APIs Method 
  loadLanguageApi = async () => {
    this.state.languageArray = [];
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tanantslanguage,
      'get',
    );
    if (responseJson['status'] == true) {
      var ppData = responseJson['data']['languages'];
      for (let obj of ppData) {
        this.state.languageArray.push(obj);
      }
      if (this.state.languageArray.length != 0) {
        this.loadAppTranslationGroupApi();
      }
    } else {
      this.setState({dataLoad: true});
    }
  };
  loadAppTranslationGroupApi = async () => {
    let code = this.state.languageArray.map(x => x['code']);
    let group = `language=${code}&search_key=${this.state.searchKey}`;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clientTranslationValuesGroupedByLanguage + group,
      'get',
    );
    console.log('clientTranslationValuesGrouped', responseJson);
    this.setState({translationsGroupArray: []});
    if (responseJson['status'] == true) {
      var objectD = responseJson['data']['values_grouped_by_languages'];
      for (let objc of objectD) {
        let dd = objc['default'] ?? [];
        if (dd.length != 0) {
          this.state.translationsGroupArray.push(objc);
        }
      }
      if (this.state.translationsGroupArray.length != 0) {
        this.state.translationsGroupArray.sort(function (a, b) {
          return a['name'].localeCompare(b['name']);
        });
        this.state.screensNameArray = [...this.state.translationsGroupArray];
        // this.setState({
        //   subMenuSelectedIndex: this.state.translationsGroupArray[0]['key'],
        //   selectedTranslationGroupValue:this.state.translationsGroupArray[0],
        // });
        if (this.state.subMenuSelectedIndex.length == 0) {
          this.didSelectMenuItem(this.state.translationsGroupArray[0]);
        }
      } else {
        this.setState({dataLoad: true, haveData: true});
      }
    }
    this.setState({dataLoad: true, haveData: true});
  };
  updateAppTranslationAPI = async () => {
    this.setState({dataLoad: false});
    let transArray = [];
    for (let objc of this.state.translationsGroupArray) {
      let groupKey = objc['key'];
      let tempAry = {};
      for (let str of this.state.languageArray) {
        var ssAray = [];
        var a = 0;
        if (objc[str['code']]) {
          let pdata = objc[str['code']];
          for (let obj of pdata) {
            if (obj['edit']) {
              let textData = {
                value: obj['value'],
                key: obj['key'],
              };
              ssAray.push(textData);
            }
          }
        }
        if (ssAray.length != 0) {
          tempAry[`${str['code']}`] = ssAray;
          tempAry['group'] = groupKey;
          transArray.push(tempAry);
        }
      }
    }
    let code = this.state.languageArray.map(x => x['code']);
    let param = JSON.stringify({
      languages: code,
      client_translations: transArray,
    });
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.updateAppTranslations,
      'put',
      param,
    );
    if (responseJson['status'] == true) {
      this.setState({dataLoad: true});
      AppToast();
    } else {
      this.setState({dataLoad: true});
      AppToast(responseJson);
    }
  };

  //MARK:-  Buttons Action Method 
  saveBtnAction() {
    if (this.state.subMenuSelectedIndex.length != 0) {
      this.updateAppTranslationAPI();
      // this.child.saveButtonAction();
    }
  }
  didSelectMenuItem = item => {
    this.setState({
      selectedTranslationGroupValue: item,
      subMenuSelectedIndex: item['key'],
    });
    this.setState({updateUI: !this.state.updateUI});
    if (this.state.subMenuSelectedIndex?.length != 0) {
      setTimeout(
        function () {
          //Start the timer
          if (this.child?.reloadData != undefined) {
            this.child?.reloadData();
          }
        }.bind(this),
        100,
      );
    }
  };

  updatetranslationArray = data => {
    let pindex = this.state.translationsGroupArray.findIndex(
      x => x['key'] == data['groupKey'],
    );
    let l1 = this.state.translationsGroupArray[pindex];
    let l2 = l1[data['code']];
    let tIndex = l2.findIndex(x => x['key'] == data['key']);
    let value = l2[tIndex];
    value['value'] = data['text'];
    value['edit'] = true;
    l2[tIndex] = value;
    l1[data['code']] = l2;
    this.state.translationsGroupArray[pindex] = l1;
    // this.setState({updateUI: !this.state.updateUI});
  };

  /*  UI   */

  onSearchTextChange = text => {
    this.setState({searchKey: text});
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      typingTimeout: setTimeout(
        function () {
          this.searchFormArray(text);
        }.bind(this),
        500,
      ),
    });
  };
  searchFormArray(text) {
    // this.state.translationsGroupArray = this.state.screensNameArray.filter(
    //   data => data.name.toLowerCase().match(text.toLowerCase()),
    // );
    this.setState({
      selectedTranslationGroupValue: '',
      subMenuSelectedIndex: '',
    });
    this.setState({updateUI: !this.state.updateUI, dataLoad: false});
    this.loadAppTranslationGroupApi();
    // this.reloadValueAPI();
  }
  reloadValueAPI() {
    this.setState({dataLoad: false});
    this.loadAppTranslationGroupApi();
  }
  subMenuViewRender = () => {
    let subViews = [];
    for (let a = 0; a <= this.state.translationsGroupArray.length - 1; a++) {
      subViews.push(
        <TouchableOpacity
          style={
            this.state.subMenuSelectedIndex ==
            this.state.translationsGroupArray[a]['key']
              ? SettingsStyleSheet.selectedSubMenuViewStyle
              : SettingsStyleSheet.subMenuViewStyle
          }
          onPress={() =>
            this.didSelectMenuItem(this.state.translationsGroupArray[a])
          }>
          <Image
            style={SettingsStyleSheet.subMenuIconStyle}
            resizeMode="contain"
            source={
              this.state.subMenuSelectedIndex ==
              this.state.translationsGroupArray[a]['key']
                ? greenTickIcon
                : gTick_Icon
            }
          />
          <Text
            style={
              this.state.subMenuSelectedIndex ==
              this.state.translationsGroupArray[a]['key']
                ? SettingStyleSheet.subMenuSelectedtextStyle
                : SettingsStyleSheet.subMenutextStyle
            }>
            {this.state.translationsGroupArray[a]['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return subViews;
  };

  mainViewRender = () => {
    if (this.state.subMenuSelectedIndex.length != 0) {
      return (
        <LanguageField
          ref={child => (this.child = child)}
          keyPath={this.state.subMenuSelectedIndex}
          translationValue={this.state.selectedTranslationGroupValue}
          languageArray={this.state.languageArray}
          updatetranslationArray={data => this.updatetranslationArray(data)}
          reloadAPI={() => this.reloadValueAPI()}
        />
      );
    } else {
      // return <View />
      if (this.state.haveData) {
        return (
          <View style={{width: windowWidth / 2}}>
            <AppEmptyList />
          </View>
        );
      } else {
        return <View />;
      }
    }
  };
  renderSaveBtn = () => {
    return (
      <View style={{display: this.state.writeP ? 'flex' : 'none'}}>
        <View style={tableStyle.gradientViewBGStyle}>
          <Gradient
            gradients={colors.GradientColors} // required
            property="background"
            gradientType={'linear'}
            duration={2333000}
            angle="0deg">
            <TouchableOpacity onPress={() => this.saveBtnAction()}>
              <Text style={tableStyle.saveBtnTxtStyle}>{'Save'}</Text>
            </TouchableOpacity>
          </Gradient>
        </View>
      </View>
    );
  };
  searchViewRender = () => {
    return (
      <View style={styles.searchBarViewStyle}>
        <Image
          resizeMode={'cover'}
          style={{width: 16, height: 16, marginLeft: 10}}
          source={searchIcon}
        />
        <TextInput
          style={{
            width: '90%',
            margin: 10,
            marginLeft: 2,
            height: 25,
            paddingLeft: 5,
            outline: 'none',
          }}
          placeholder="Type to search..."
          placeholderTextColor={colors.placeholderColor}
          value={this.state.searchKey}
          onChangeText={text => this.onSearchTextChange(text)}
        />
        <TouchableOpacity
          style={[
            tableStyle.textBoxButton,
            {
              marginTop: 0,
              display: this.state.searchKey.length != 0 ? 'flex' : 'none',
            },
          ]}
          onPress={() => this.onSearchTextChange('')}>
          <Image
            style={{width: 16, height: 16, marginRight: 10}}
            source={closeIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={tableStyle.navigationViewStyle}>
          <View style={[tableStyle.headerContainer]}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <TouchableOpacity
                style={tableStyle.backBtnBackViewStyle}
                onPress={() => {
                  this.props.navigate(-1);
                }}>
                <BackBtnView />
              </TouchableOpacity>
              <View style={SettingStyleSheet.headerViewStyle}>
                <Text style={tableStyle.titleTextStyle}>
                  {ConstantArray.SideAppSubMenuArray[2]}
                </Text>
              </View>
              <HelperVideoView
                helperVideoBtnAction={() =>
                  window.open(AppConstants.StringsCustomizationVideo, '_blank')
                }
              />
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <this.searchViewRender />
              {this.renderSaveBtn()}
            </View>
          </View>
        </View>
        {/* <Header_View
          title={'App Translation'}
          backBtn={this.props.backBtn}
          saveBtn={() => this.saveBtnAction()}
          showSaveBtn={this.state.writeP}
          showBackBtn={this.props.showBackBtn}
        /> */}
        <View style={{flexDirection: 'column'}}>
          <ToastContainer />
          <View style={styles.mainView}>
            <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
              <ScrollView style={{height: '100%'}}>
                <View style={SettingsStyleSheet.subMenuContainerStyle}>
                  <View>
                    <this.subMenuViewRender />
                  </View>
                </View>
              </ScrollView>
              <View style={SettingsStyleSheet.horizontalLineView}></View>
              <View style={{width: itsMobileView ? '100%' : windowWidth - 350}}>
                <ScrollView style={{height: '100%'}}>
                  <this.mainViewRender />
                </ScrollView>
              </View>
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
    //}
  }
}

export default withRouter(AppTranslation);
const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    padding: 20,
    margin: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    flexDirection: 'row',
    height: windowHeight - perHeight,
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    width: windowWidth < fixedWidth ? '100%' : windowWidth / 4,
    height: 30,
    marginRight: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
});
