import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Image,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import ReactFileReader from 'react-file-reader';
import close_Icon from '../../assets/closeIcon.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'onboarding';
export default class AssetsSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      logoImage: null,
      smallLogoImage: null,
      faviconImage: null,
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,
      'get', '',  '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        logoImage: configs['logo'] || null,
        smallLogoImage: configs['logo_small'] || null,
        faviconImage: configs['favicon'] || null,
      });
    }
    this.setState({dataLoad: true});
  };
 
  uploadFilesAPI = async () => {
    var imgParm = [];
    var uploadBase64 = [];
    if (this.state.logoImage != null) {
      let fileName = this.state.logoImage.base64;
      if (fileName != null) {
        var logoIconDict = {
          name: this.state.logoImage['fileList'][0]['name'],
          type: this.state.logoImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.logoImage.base64,
          key: 'logo',
        });
        imgParm.push(logoIconDict);
      }
    }
    if (this.state.smallLogoImage != null) {
      let fileName = this.state.smallLogoImage.base64;
      if (fileName != null) {
        var smallIconDict = {
          name: this.state.smallLogoImage['fileList'][0]['name'],
          type: this.state.smallLogoImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.smallLogoImage.base64,
          key: 'logo_small',
        });
        imgParm.push(smallIconDict);
      }
    }
    if (this.state.faviconImage != null) {
      let fileName = this.state.faviconImage.base64;
      if (fileName != null) {
        var faviconIconDict = {
          name: this.state.faviconImage['fileList'][0]['name'].replace(/ /g,''),
          type: this.state.faviconImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.faviconImage.base64,
          key: 'favicon',
        });
        imgParm.push(faviconIconDict);
      }
    }

    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,'POST', JSON.stringify({files: imgParm}), '');
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        var uploadDataParm = [];

        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res = await networkService.uploadFileWithSignedURL(
              result[i]['signedUrl'],
              imgParm[i]['type'],
              await res.blob(),
            );
            uploadIncrement++;
            // console.log('file_upload_res', file_upload_res);
            let fDict = {
              key_group: KeyGroup,
              key: uploadBase64[i]['key'],
              value: result[i]['fileUri'],
              secured: false,
            };
            // console.log('fDict =>', fDict);

            uploadDataParm.push(fDict);
            if (uploadIncrement === uploadBase64.length) {
              // console.log('uploadDataParm =>', uploadDataParm);
              this.addOrUpdateAppDetailApi(uploadDataParm);
            }
          });
        }
      } else {
        this.setState({dataLoad: true});
        // console.log('respo error', responseJson);
        this.alertView(responseJson);
      }
    } else {
      var uploadDataParm = [];
      var keyAry = parameterKeys;
      var valueAry = [
        this.state.logoImage || '',
        this.state.smallLogoImage || '',
        this.state.faviconImage || '',
      ];
      for (let i = 0; i < keyAry.length; i++) {
        if (valueAry[i] != null && valueAry[i].length != 0) {
          // console.log('valueAry[i].length', valueAry[i].length);
          let fDict = {
            key_group: KeyGroup,
            key: keyAry[i],
            value: valueAry[i],
            secured: false,
          };
          uploadDataParm.push(fDict);
        }
      }
      this.addOrUpdateAppDetailApi(uploadDataParm);
    }
  };
  addOrUpdateAppDetailApi = async filesData => {
    // console.log('filesData =>', filesData);
    var configs = [];
    if (filesData != undefined){
      configs = filesData;
    }
    if (configs.length == 0) {
      this.deleteKeysAPI(configs);
    }
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
      '',
    );
    // console.log('configs', responseJson);
    if (responseJson['status'] == true) {
      this.deleteKeysAPI(configs);
    } else {
      this.setState({dataLoad: true});
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < parameterKeys.length; q++) {
      deleteParms.push(parameterKeys[q]);
    }

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(urlParm,'delete', '','');
      // console.log('configs', responseJson);
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
  }
  onPickFile = (image, id) => {
    if (id == 0) {
      this.setState({logoImage: image});
    } else if (id == 1) {
      this.setState({smallLogoImage: image});
    } else {
      this.setState({faviconImage: image});
    }
  };
  downloadBtnAction = (pathURL) => {
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    }
  }
  cancelBtnAction = (id) => {
    if (id == 0) {
      this.setState({logoImage: null});
    } else if (id == 1) {
      this.setState({smallLogoImage: null});
    } else {
      this.setState({faviconImage: null});
    }
  }
  //MARK:-  UIs 
  renderDownloadView = (data) => {
    if (data['bool']) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.downloadBtnAction(data['url'])}>
            <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppNewGreen }]}>Download</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ margin: 5 }} onPress={() => this.cancelBtnAction(data['id'])}>
            <Text style={[tableStyle.saveBtnTxtStyle,{ color: colors.AppRed}]}>Cancel</Text>
          </TouchableOpacity>
        </View>
      );
    }else {
      return <View />
    }
  }
  imageViewPicker = id => {
    var fileMsg = 'Choose file';
    if (id['id'] == 0) {
      if (this.state.logoImage != null) {
        if (this.state.logoImage.fileList) {
          fileMsg = this.state.logoImage != null ? this.state.logoImage.fileList[0]['name'] : fileMsg;
        } 
      }
    } else if (id['id'] == 1) {
      if (this.state.smallLogoImage != null) {
        if (this.state.smallLogoImage.fileList) {
          fileMsg = this.state.smallLogoImage != null ? this.state.smallLogoImage.fileList[0]['name'] : fileMsg;
        } 
      }
    } else if (id['id'] == 2) {
      if (this.state.faviconImage != null) {
        if (this.state.faviconImage.fileList) {
          fileMsg = this.state.faviconImage != null ? this.state.faviconImage.fileList[0]['name'] : fileMsg;
        } 
      }
    }

    var msgTxt = id['id'] == 1 ? fileMsg : fileMsg;
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file => this.onPickFile(file, id['id'])
            }>
            <View style={{height:5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height:5}} />
          </ReactFileReader>
          {/* <this.renderDownloadView bool={showDownload} url={URLLink} id={id['id']}/> */}
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  renderSelectedImageView = id => {
    var image = null;
    if (id['id'] == 0) {
      if (this.state.logoImage != null && id['id'] == 0) {
        image = this.state.logoImage;
      }
    } else if (id['id'] == 1) {
      if (this.state.smallLogoImage != null && id['id'] == 1) {
        image = this.state.smallLogoImage;
      }
    } else if (id['id'] == 2) {
      if (this.state.faviconImage != null && id['id'] == 2) {
        image = this.state.faviconImage;
      }
    }
    if (image != null) {
      let fileName = image.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <TouchableOpacity  onPress={() => this.cancelBtnAction(id['id'])}>
            <Image style={{ width: 14, height: 14 }}  source={close_Icon} />
          </TouchableOpacity>
          <Image source={{ uri: fileName != null ? image.base64 : image }}
            resizeMode={'contain'}
            style={appConfigStyle.SelectedImageStyle} />
        </View>
      );
    } else {
      return <View />;
    }
  }
  render() {
    return (
      <View style={{width: '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Assets</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plain2TextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>            
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Logo
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={0} />
              <this.renderSelectedImageView id={0} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Small logo
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={1} />
              <this.renderSelectedImageView id={1} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Favicon
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={2} />
              <this.renderSelectedImageView id={2} />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let parameterKeys = [
  'logo',
  'logo_small',
  'favicon',
];

