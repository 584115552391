import networkService from '../Network/NetworkManager';
import {fetch_all_categories} from '../utils/fetch_all_categories';
const APPURL = require('../Constants/URLConstants');

//

export const loadCategoriesApi = async ({type}) => {
  const responseJson = await networkService.networkCall(
    APPURL.URLPaths.categories + `${type}&parent=0`,
    'get',
    '',
    '',
  );

  // console.log('responseJson', responseJson);
  if (responseJson['status'] == true) {
    var listD = responseJson['data']['categories'];
    return fetch_all_categories(listD);
  } else {
    return [];
  }
};
