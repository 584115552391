import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';

const windowWidth = Dimensions.get('window').width;
export default class AppHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    saveBtn: PropTypes.func,
    saveBtnTitle: PropTypes.string,
    showBtn:PropTypes.bool,
  };
  renderActionBtn = () => {
    return <View style={{display:this.props.showBtn ? 'flex' : 'none'}}>
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={colors.GradientColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={this.props.saveBtn}>
            <Text style={tableStyle.saveBtnTxtStyle}>
              {this.props.saveBtnTitle}
            </Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    </View>
  }
  render() {
    if (this.props.saveBtnTitle == undefined) {
      return (
        <View style={styles.headerView}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={tableStyle.titleTextStyle}>{this.props.title}</Text>
          </View>
        </View>
      );
    } else {
      return (
        <View style={styles.headerView}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={tableStyle.titleTextStyle}>{this.props.title}</Text>
          </View>
          {this.renderActionBtn()}
        </View>
      );
    }
  }
}
const styles = StyleSheet.create({
  headerView: {
    marginLeft: 20,
    marginRight: 20,
    // backgroundColor: 'white',
    flexDirection: 'row',
    paddingLeft:10,
    paddingRight:10,
    // borderRadius: 10,
    // borderColor: colors.SimonGray,
    // borderWidth: 1,
    // height: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
