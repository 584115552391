import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Alert,
  Image,
  FlatList,
  Dimensions,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import ReactFileReader from 'react-file-reader';
import close_Icon from '../../assets/closeIcon.png';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';

const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'general';
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;

let regexp = /^[0-9\.]+$/;
export default class WebGeneralConfigs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      webIconImage: null,
      webLogoImage: null,
      webCoverImage: null,
      webFontTitle: [fontList[0]],
      webFontBody: [fontList[0]],
      webPrimaryFont: [fontList[0]],
      webName: '',
      showDropDown: false,
      showFontBody: false,
      logoWidth: '',
      logoHeight: '',
      showPrimaryFont: false,
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      if (configs['web_font_title']) {
        let fontTitle = configs['web_font_title'] || [fontList[0]];
        this.state.webFontTitle = fontTitle.split(',');
      }
      if (configs['web_font_body']) {
        let fontbody = configs['web_font_body'] || [fontList[0]];
        this.state.webFontBody = fontbody.split(',');
      }
      if (configs['primary_web_font']) {
        let primaryfont = configs['primary_web_font'] || [fontList[0]];
        this.state.webPrimaryFont = primaryfont.split(',');
      }

      this.setState({
        webIconImage: configs['web_icon'] || null,
        webLogoImage: configs['web_logo'] || null,
        webCoverImage: configs['web_coverimage'] || null,
        // webFontTitle: configs['web_font_title'] ||  fontList[0],
        // webFontBody: configs['web_font_body'] || fontList[0],
        webName: configs['website_name'] || '',
        logoWidth: configs['logo_width'] || '',
        webName: configs['logo_height'] || '',
      });
    }
    this.setState({dataLoad: true});
  };

  uploadFilesAPI = async () => {
    var imgParm = [];
    var uploadBase64 = [];
    var uploadDataParm = [];

    if (this.state.webIconImage != null) {
      let fileName = this.state.webIconImage.base64;
      if (fileName != null) {
        var logoIconDict = {
          name: this.state.webIconImage['fileList'][0]['name'],
          type: this.state.webIconImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.webIconImage.base64,
          key: parameterKeys[0],
        });
        imgParm.push(logoIconDict);
      } else {
        let fDict = {
          key_group: KeyGroup,
          key: parameterKeys[0],
          value: this.state.webIconImage,
          secured: false,
        };
        uploadDataParm.push(fDict);
      }
    }
    if (this.state.webLogoImage != null) {
      let fileName = this.state.webLogoImage.base64;
      if (fileName != null) {
        var smallIconDict = {
          name: this.state.webLogoImage['fileList'][0]['name'],
          type: this.state.webLogoImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.webLogoImage.base64,
          key: parameterKeys[1],
        });
        imgParm.push(smallIconDict);
      } else {
        let fDict = {
          key_group: KeyGroup,
          key: parameterKeys[1],
          value: this.state.webLogoImage,
          secured: false,
        };
        uploadDataParm.push(fDict);
      }
    }
    if (this.state.webCoverImage != null) {
      let fileName = this.state.webCoverImage.base64;
      if (fileName != null) {
        var faviconIconDict = {
          name: this.state.webCoverImage['fileList'][0]['name'].replace(
            / /g,
            '',
          ),
          type: this.state.webCoverImage['fileList'][0]['type'],
        };
        uploadBase64.push({
          file: this.state.webCoverImage.base64,
          key: parameterKeys[2],
        });
        imgParm.push(faviconIconDict);
      } else {
        let fDict = {
          key_group: KeyGroup,
          key: parameterKeys[2],
          value: this.state.webCoverImage,
          secured: false,
        };
        uploadDataParm.push(fDict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
        '',
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        var uploadIncrement = 0;
        for (let i = 0; i < imgParm.length; i++) {
          fetch(uploadBase64[i]['file']).then(async res => {
            const file_upload_res =
              await networkService.uploadFileWithSignedURL(
                result[i]['signedUrl'],
                imgParm[i]['type'],
                await res.blob(),
              );
            uploadIncrement++;
            let fDict = {
              key_group: KeyGroup,
              key: uploadBase64[i]['key'],
              value: result[i]['fileUri'],
              secured: false,
            };

            uploadDataParm.push(fDict);
            if (uploadIncrement === uploadBase64.length) {
              this.addOrUpdateAppDetailApi(uploadDataParm);
            }
          });
        }
      } else {
        this.setState({dataLoad: true});
        this.alertView(responseJson);
      }
    } else {
      var uploadDataParm = [];
      var keyAry = parameterKeys;
      var valueAry = [
        this.state.webIconImage || '',
        this.state.webLogoImage || '',
        this.state.webCoverImage || '',
      ];
      for (let i = 0; i < keyAry.length; i++) {
        if (valueAry[i] != null && valueAry[i].length != 0) {
          // console.log('valueAry[i].length', valueAry[i].length);
          let fDict = {
            key_group: KeyGroup,
            key: keyAry[i],
            value: valueAry[i],
            secured: false,
          };
          uploadDataParm.push(fDict);
        }
      }
      this.addOrUpdateAppDetailApi(uploadDataParm);
    }
  };
  addOrUpdateAppDetailApi = async filesData => {
    var configs = [];
    if (filesData != undefined) {
      configs = filesData;
    }
    console.log('filesData', filesData);
    if (configs.length == 0) {
      this.deleteKeysAPI(configs);
    }

    var valueAry = [
      '',
      '',
      '',
      this.state.webName,
      this.state.logoWidth,
      this.state.logoHeight,
    ];
    if (this.state.webFontTitle.length != 0) {
      valueAry[0] = this.state.webFontTitle.toString();
    }
    if (this.state.webFontBody.length != 0) {
      valueAry[1] = this.state.webFontBody.toString();
    }
    if (this.state.webPrimaryFont.length != 0) {
      valueAry[1] = this.state.webPrimaryFont.toString();
    }
    let keysAry = [
      'web_font_title',
      'web_font_body',
      'primary_web_font',
      'website_name',
      'logo_width',
      'logo_height',
    ];

    for (let a = 0; a < keysAry.length; a++) {
      if (valueAry[a].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysAry[a],
          value: valueAry[a],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    if (configs.length != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.config,
        'post',
        JSON.stringify({configs: configs}),
      );
      if (responseJson['status'] == true) {
        this.deleteKeysAPI(configs);
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.deleteKeysAPI(configs);
    }
  };
  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < parameterKeys.length; q++) {
      deleteParms.push(parameterKeys[q]);
    }

    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }

    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall(
        urlParm,
        'delete',
        '',
        '',
      );
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    } else {
      this.setState({dataLoad: true});
      this.alertView('Uploaded successfully');
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    this.setState({dataLoad: false});
    this.uploadFilesAPI();
  }
  onPickFile = (image, id) => {
    if (id == 0) {
      this.setState({webIconImage: image});
    } else if (id == 1) {
      this.setState({webLogoImage: image});
    } else {
      this.setState({webCoverImage: image});
    }
  };
  downloadBtnAction = pathURL => {
    if (Platform.OS == 'web') {
      window.open(pathURL, '_blank');
    }
  };
  cancelBtnAction = id => {
    if (id == 0) {
      this.setState({webIconImage: null});
    } else if (id == 1) {
      this.setState({webLogoImage: null});
    } else {
      this.setState({webCoverImage: null});
    }
  };
  showEntriesDropDown(id) {
    this.setState({showDropDown: !this.state.showDropDown});
  }
  didSelectDropDown = item => {
    if (this.state.showFontBody) {
      let ind = this.state.webFontBody.findIndex(x => x == item);
      if (ind == -1) {
        this.state.webFontBody.push(item);
      } else {
        this.state.webFontBody.splice(ind, 1);
      }
      this.setState({showFontBody: false});
    } else {
      let ind = this.state.webFontTitle.findIndex(x => x == item);
      if (ind == -1) {
        this.state.webFontTitle.push(item);
      } else {
        this.state.webFontTitle.splice(ind, 1);
      }
      this.setState({showDropDown: false});
    }
  };
  onHandleTextChange(text, id) {
    let value = text;
    if (id == 0) {
      if (text == '' || regexp.test(value)) {
        this.setState({logoWidth: text});
      }
    } else if (id == 1) {
      if (text == '' || regexp.test(value)) {
        this.setState({logoHeight: text});
      }
    }
  }
  //MARK:-  UIs 
  renderDownloadView = data => {
    if (data['bool']) {
      return (
        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity
            style={{margin: 5}}
            onPress={() => this.downloadBtnAction(data['url'])}>
            <Text
              style={[tableStyle.saveBtnTxtStyle, {color: colors.AppNewGreen}]}>
              Download
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{margin: 5}}
            onPress={() => this.cancelBtnAction(data['id'])}>
            <Text style={[tableStyle.saveBtnTxtStyle, {color: colors.AppRed}]}>
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else {
      return <View />;
    }
  };
  imageViewPicker = id => {
    var fileMsg = 'Choose file';
    if (id['id'] == 0) {
      if (this.state.webIconImage != null) {
        if (this.state.webIconImage.fileList) {
          fileMsg =
            this.state.webIconImage != null
              ? this.state.webIconImage.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.webIconImage;
          console.log('uri ==>> ', uri);
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1);
        }
      }
    } else if (id['id'] == 1) {
      if (this.state.webLogoImage != null) {
        if (this.state.webLogoImage.fileList) {
          fileMsg =
            this.state.webLogoImage != null
              ? this.state.webLogoImage.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.webLogoImage;
          console.log('uri ==>> ', uri);
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1);
        }
      }
    } else if (id['id'] == 2) {
      if (this.state.webCoverImage != null) {
        if (this.state.webCoverImage.fileList) {
          fileMsg =
            this.state.webCoverImage != null
              ? this.state.webCoverImage.fileList[0]['name']
              : fileMsg;
        } else {
          let uri = this.state.webCoverImage;
          console.log('uri ==>> ', uri);
          fileMsg = uri.substring(uri.lastIndexOf('/') + 1);
        }
      }
    }

    var msgTxt = id['id'] == 1 ? fileMsg : fileMsg;
    if (Platform.OS === 'web') {
      return (
        <View style={appConfigStyle.dottedViewStyle}>
          <ReactFileReader
            fileTypes={['.png', '.jpg']}
            base64={true}
            style={appConfigStyle.dottedViewStyle}
            handleFiles={file => this.onPickFile(file, id['id'])}>
            <View style={{height: 5}} />
            <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
            <View style={{height: 5}} />
          </ReactFileReader>
          {/* <this.renderDownloadView bool={showDownload} url={URLLink} id={id['id']}/> */}
        </View>
      );
    } else {
      return (
        <TouchableOpacity style={appConfigStyle.dottedViewStyle}>
          <Text style={appConfigStyle.chooseFileTxtStyle}>{msgTxt}</Text>
        </TouchableOpacity>
      );
    }
  };
  renderSelectedImageView = id => {
    var image = null;
    if (id['id'] == 0) {
      if (this.state.webIconImage != null && id['id'] == 0) {
        image = this.state.webIconImage;
      }
    } else if (id['id'] == 1) {
      if (this.state.webLogoImage != null && id['id'] == 1) {
        image = this.state.webLogoImage;
      }
    } else if (id['id'] == 2) {
      if (this.state.webCoverImage != null && id['id'] == 2) {
        image = this.state.webCoverImage;
      }
    }
    if (image != null) {
      let fileName = image.base64;
      return (
        <View style={appConfigStyle.selectImagesViewStyle}>
          <TouchableOpacity onPress={() => this.cancelBtnAction(id['id'])}>
            <Image style={{width: 14, height: 14}} source={close_Icon} />
          </TouchableOpacity>
          <Image
            source={{uri: fileName != null ? image.base64 : image}}
            resizeMode={'contain'}
            style={appConfigStyle.SelectedImageStyle}
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  entriesDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={styles.dropDownViewStyle}>
          <FlatList
            data={fontList}
            horizontal={false}
            renderItem={(item, index) => this.renderDropDown(item, index, id)}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  bodyDropDown = id => {
    if (this.state.showFontBody == true) {
      return (
        <View style={styles.dropDownViewStyle}>
          <FlatList
            data={fontList}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
            keyExtractor={(item, index) => index + 989}
            key={'E'}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    var color = colors.AppTitleBlack;
    if (this.state.showFontBody) {
      let ind = this.state.webFontBody.findIndex(x => x == item);
      color = ind != -1 ? colors.AppRed : color;
    }
    if (this.state.showPrimaryFont) {
      let ind = this.state.webPrimaryFont.findIndex(x => x == item);
      color = ind != -1 ? colors.AppRed : color;
    } else {
      let ind = this.state.webFontTitle.findIndex(x => x == item);
      color = ind != -1 ? colors.AppRed : color;
    }
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30}}>
        <View
          style={{
            justifyContent: 'center',
            width: '100%',
            borderBottomColor: colors.SimonGray,
            borderBottomWidth: 1,
            padding: 5,
          }}>
          <Text
            style={[
              CommonStyleSheet.dropdownCellItemTextStyle,
              {color: color},
            ]}>
            {item}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  render() {
    return (
      <View
        style={{
          width: itsMobileView ? '95%' : '70%',
          marginBottom: 50,
          marginLeft: 10,
        }}>
        <Text style={tableStyle.listTitleText}>Web Configs</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Favicon</Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={0} />
              <this.renderSelectedImageView id={0} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Logo</Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={1} />
              <this.renderSelectedImageView id={1} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Logo Width</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Width"
                placeholderTextColor={colors.placeholderColor}
                value={this.state.logoWidth}
                onChangeText={text => this.onHandleTextChange(text, 0)}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Logo Height</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Height"
                placeholderTextColor={colors.placeholderColor}
                value={this.state.logoHeight}
                onChangeText={text => this.onHandleTextChange(text, 1)}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>
                Web Featured Image for Social Share - Open Graph
              </Text>
              <View style={{height: 10}} />
              <this.imageViewPicker id={2} />
              <this.renderSelectedImageView id={2} />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Business Name</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle, {outline: 0}]}
                placeholder="Enter web name"
                placeholderTextColor={colors.placeholderColor}
                value={this.state.webName}
                onChangeText={text => this.setState({webName: text})}
              />
            </View>
            <View style={{marginTop: 20, zIndex: 120}}>
              <Text style={appConfigStyle.titleTxtStyle}>Title Font</Text>
              <TouchableOpacity
                onPress={() =>
                  this.setState({showDropDown: !this.state.showDropDown})
                }
                style={appConfigStyle.txtFieldStyle}>
                <View
                  style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text>
                    {this.state.webFontTitle.length == 0
                      ? 'Select title font'
                      : this.state.webFontTitle.toString()}
                  </Text>
                  <View style={{justifyContent: 'center'}}>
                    <Image
                      style={
                        this.state.showDropDown == true
                          ? appConfigStyle.upArraowIconStyle
                          : appConfigStyle.downArraowIconStyle
                      }
                      resizeMode="contain"
                      source={downArrow_Icon}
                    />
                  </View>
                </View>
              </TouchableOpacity>
              <this.entriesDropDown id={1} />
            </View>
            <View style={{marginTop: 20, zIndex: 110}}>
              <Text style={appConfigStyle.titleTxtStyle}>Body Font</Text>
              <TouchableOpacity
                onPress={() =>
                  this.setState({showFontBody: !this.state.showFontBody})
                }
                style={appConfigStyle.txtFieldStyle}>
                <View
                  style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text>
                    {this.state.webFontBody.length == 0
                      ? 'Select body font'
                      : this.state.webFontBody.toString()}
                  </Text>
                  <View style={{justifyContent: 'center'}}>
                    <Image
                      style={
                        this.state.showFontBody == true
                          ? appConfigStyle.upArraowIconStyle
                          : appConfigStyle.downArraowIconStyle
                      }
                      resizeMode="contain"
                      source={downArrow_Icon}
                    />
                  </View>
                </View>
              </TouchableOpacity>
              <this.bodyDropDown id={2} />
            </View>
            <View style={{marginTop: 20, zIndex: 110}}>
              <Text style={appConfigStyle.titleTxtStyle}>Primay Font</Text>
              <TouchableOpacity
                onPress={() =>
                  this.setState({showPrimaryFont: !this.state.showPrimaryFont})
                }
                style={appConfigStyle.txtFieldStyle}>
                <View
                  style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text>
                    {this.state.webPrimaryFont.length == 0
                      ? 'Select body font'
                      : this.state.webPrimaryFont.toString()}
                  </Text>
                  <View style={{justifyContent: 'center'}}>
                    <Image
                      style={
                        this.state.showPrimaryFont == true
                          ? appConfigStyle.upArraowIconStyle
                          : appConfigStyle.downArraowIconStyle
                      }
                      resizeMode="contain"
                      source={downArrow_Icon}
                    />
                  </View>
                </View>
              </TouchableOpacity>
              <this.bodyDropDown id={3} />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    marginTop: 72,
    marginLeft: 0,
    zIndex: 9000,
  },
});

let parameterKeys = [
  'web_icon',
  'web_logo',
  'web_coverimage',
  'web_font_title',
  'web_font_body',
  'website_name',
  'logo_width',
  'logo_height',
];

const fontList = [
  'roboto',
  'Open Sans',
  'Montserrat',
  'Poppins',
  'Slabo 27px',
  'Raleway',
  'PT Sans',
  'Merriweather',
  'Noto Sans',
  'Nunito Sans',
  'Oswald',
  'Lato',
  'Space Mono',
  'Work Sans',
  'Eczar',
];
