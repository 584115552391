import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import colors from '../HelperClasses/AppColor';
import toolTip from './../assets/toolTip.png';

import tableStyle from '../StyleSheets/TableCommonStyleSheet';
import {Gradient} from 'react-gradient';
import HelperVideoView from '../Component/HelperVideoView';
import TableCommonStyleSheet from '../StyleSheets/TableCommonStyleSheet';
import BackBtnView from '../Component/BackBtnView';
import SaveButton from '../Component/SaveButton';
import AppConstants from '../Constants/AppConstants';
import {withRouter} from '../wrappers/withRouter';
import CloseBtnView from '../Component/CloseBtnView';
import {RWebShare} from 'react-web-share';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class HeaderView extends Component {
  static propTypes = {
    title: PropTypes.string,
    backBtn: PropTypes.func,
    showBackBtn: PropTypes.bool,
    saveBtn: PropTypes.func,
    toolTipBtn: PropTypes.func,
    saveBtnTitle: PropTypes.string,
    showSaveBtn: PropTypes.bool,
    showToolTipBtn: PropTypes.bool,
    toolTipBtn: PropTypes.func,
    showHelperVideoBtn: PropTypes.bool,
    helperVideoBtnAction: PropTypes.func,
    btnDeActive: PropTypes.bool,
    deActiveBtnAction: PropTypes.func,
    isClose: PropTypes.bool,
  };

  showSaveBtnRender = () => {
    return (
      <SaveButton
        saveBtn={this.props.saveBtn}
        showSaveBtn={this.props.showSaveBtn}
        deActiveBtnAction={this.props.deActiveBtnAction}
        btnDeActive={this.props.btnDeActive}
        saveBtnTitle={this.props.saveBtnTitle}
      />
    );
  };
  renderToolTip = () => {
    return (
      <TouchableOpacity
        style={{
          display: this.props.showToolTipBtn ? 'flex' : 'none',
          width: 20,
          height: 20,
          marginRight: 10,
        }}
        onPress={this.props.toolTipBtn}>
        <Image
          style={{height: 20, width: 20, marginTop: 2}}
          resizeMode="center"
          source={toolTip}
        />
      </TouchableOpacity>
    );
  };
  renderHelperVideoBtn = () => {
    return (
      <View style={{display: this.props.showHelperVideoBtn ? 'flex' : 'none'}}>
        <HelperVideoView
          helperVideoBtnAction={() => this.props.helperVideoBtnAction()}
        />
      </View>
    );
  };

  renderShareBtnView = () => {
    return (
      <View style={{display: 'flex'}}>
        <View style={tableStyle.secondButtonViewStyle}>
          <RWebShare
            data={{url: this.props.preview_path}}
            onClick={() => console.log('shared successfully!')}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}>
              {/* <Image source={shareIcon} style={{ height: 20, width: 20 }} /> */}
              <Text style={tableStyle.secondBtnTextStyle}>Share</Text>
            </View>
          </RWebShare>
        </View>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.headerView}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <TouchableOpacity
            style={[
              styles.backBtnBackViewStyle,
              {display: this.props.showBackBtn ? 'none' : 'flex'},
            ]}
            onPress={
              this.props.backBtn
                ? this.props.backBtn
                : () => this.props.navigate(-1)
            }>
            {this.props.isClose ? <CloseBtnView /> : <BackBtnView />}
          </TouchableOpacity>
          <Text style={TableCommonStyleSheet.listTitleText}>
            {this.props.title}
          </Text>
          {this.renderToolTip()}
          {this.renderHelperVideoBtn()}
        </View>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {this.props.isEdit && this.renderShareBtnView()}
          <this.showSaveBtnRender />
        </View>
      </View>
    );
  }
}

export default withRouter(HeaderView);

const styles = StyleSheet.create({
  headerView: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'white',
    flexDirection: 'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    minHeight: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 0,
    flexWrap: 'wrap',
  },
  backBtnBackViewStyle: {
    height: 40,
    width: 40,
    marginLeft: 20,
    backgroundColor: colors.UltraGreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  backBtnStyle: {
    height: 15,
    width: 15,
  },
  saveBtnStyle: {
    backgroundColor: colors.AppNewGreen,
    height: 40,
    width: 140,
    borderRadius: 5,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
