import React from 'react';
import {StyleSheet, View, Text, Image, Dimensions} from 'react-native';
import CommonStyle from '../StyleSheets/CommonStyleSheet';
import emptyMsgIcon from '../assets/emptyMsgIcon.png';
import colors from '../HelperClasses/AppColor';
import emptyAccont from '../assets/emptyAccont.png';
import emptyListing from '../assets/emptyListing.png';
import emptyPromo from '../assets/emptyPromo.png';
import emptyCollection from '../assets/emptyCollection.png';
import TextTitleStyleSheet from '../StyleSheets/TextTitleStyleSheet';
import emptyCoupon from '../assets/emptyCoupon.png';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const Error = () => {
  return (
    <View style={styles.imageMainStyle}>
      <View
        style={{
          maxWidth: '500px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Image
          style={{
            width: 200,
            height: 150,
            marginTop: 100,
          }}
          source={emptyMsgIcon}
          resizeMode={'contain'}
        />
        <View style={{height: 0}} />
        <Text
          style={[
            CommonStyle.headerTitleStyle,
            {textAlign: 'center', paddingTop: 2},
          ]}>
          404
        </Text>
        <View style={{height: 10}} />
        <Text style={[CommonStyle.subTitleStyle, {textAlign: 'center'}]}>
          We're sorry, the page you are looking for is currently unavailable.
          Please check the URL and try again, or come back later. If you
          continue to experience this issue, please contact our support team for
          assistance. Thank you for your understanding.
        </Text>
      </View>
    </View>
  );
};

export default Error;

const styles = StyleSheet.create({
  mainStyle: {
    position: 'absolute',
    top: 100,
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageMainStyle: {
    position: 'relative',
    left: 0,
    height: '80%',
    width: '100%',
    alignItems: 'center',
  },
});
