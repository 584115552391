
import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  Platform,
  Alert,
  Dimensions
} from 'react-native';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import PropTypes from 'prop-types';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import {AppToast} from '../../HelperClasses/AppToast';
import { addMixpanelEvent, MixpanelEvent, seoMixPanelEvent } from '../../Models/MixPannelEnum';
import colors from '../../HelperClasses/AppColor';


const AppConstants = require('../../Constants/AppConstants');
const APPURL = require('../../Constants/URLConstants');

let KeyGroup = 'seo';

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth  ?  true : false


export default class SEOCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      dataLoad: false,
      metaColTitle: '',
      metaColDescription: '',
    };
    this.saveButtonAction = this.saveButtonAction.bind(this);
  }
  static propTypes = {
    backBtn: PropTypes.func,
    saveBtnCB: PropTypes.bool,
  };
  componentDidMount() {
    this.loadConfigApi();
  }

  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + `?key_group=${KeyGroup}`,'get','', '', );
    this.setState({currencyArray: []});
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      this.setState({
        metaColTitle: configs[keysArray[0]] || '',
        metaColDescription: configs[keysArray[1]] || '',
      });
    }
    this.setState({dataLoad: true});
  };
  addOrUpdateAppDetailApi = async () => {
    var configs = [];
    let valueArray = [
      this.state.metaColTitle,
      this.state.metaColDescription,
    ];
    // var configs = [];
    for (let i = 0; i < keysArray.length; i++) {
      if (valueArray[i].length != 0) {
        let fDict = {
          key_group: KeyGroup,
          key: keysArray[i],
          value: valueArray[i],
          secured: false,
        };
        configs.push(fDict);
      }
    }
    if (configs.length == 0){
      this.deleteKeysAPI(configs)
    }
    const responseJson = await networkService.networkCall(APPURL.URLPaths.config, 'post', JSON.stringify({configs: configs}),'');
    this.setState({dataLoad: true})
    if (responseJson['status'] == true) {
      seoMixPanelEvent(MixpanelEvent.seoCollections)
      this.deleteKeysAPI(configs)
    } else {
      this.setState({dataLoad: true})
      this.alertView(responseJson)
    }
  }

  deleteKeysAPI = async Parameter => {
    var deleteParms = [];
    for (let q = 0; q < keysArray.length; q++) {
      deleteParms.push(keysArray[q]);
    }
    for (let a = 0; a < Parameter.length; a++) {
      let name = Parameter[a]['key'];
      let index = deleteParms.indexOf(name);
      deleteParms.splice(index, 1);
    }
    if (deleteParms.length != 0) {
      let parms = deleteParms.toString();
      let urlParm = APPURL.URLPaths.config + '?key=' + parms;
      const responseJson = await networkService.networkCall( urlParm, 'delete','','');
      this.setState({dataLoad: true});
      this.alertView();
    } else {
      this.setState({dataLoad: true});
      this.alertView();
    }
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      // alert(title);
      AppToast();
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  saveButtonAction() {
    // console.log('calling api');
    this.setState({dataLoad: false});
    this.addOrUpdateAppDetailApi();
  }
  //MARK:-  UIs 
  render() {
    return (
      <View style={{width: itsMobileView ? '95%' : '70%', marginBottom: 50, marginLeft: 10}}>
        <Text style={tableStyle.listTitleText}>Collections</Text>
        <Text style={appConfigStyle.subTitleStyle}>
          {AppConstants.plainTextString}
        </Text>
        <View style={appConfigStyle.horizontalLineViewStyle} />
        <View style={appConfigStyle.subViewStyle}>
          <View style={{flexDirection: 'column', flex: 1.5}}>
            <View style={{marginTop: -20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Collection Title</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Collection Title"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaColTitle: name})}
                value={this.state.metaColTitle}
              />
            </View>
            <View style={{marginTop: 20}}>
              <Text style={appConfigStyle.titleTxtStyle}>Meta Collection Description</Text>
              <TextInput
                style={[appConfigStyle.txtFieldStyle,{outline:0}]}
                placeholder="Enter Meta Collection Description"
                placeholderTextColor={colors.placeholderColor}
                onChangeText={name => this.setState({metaColDescription: name})}
                value={this.state.metaColDescription}
              />
            </View>
          </View>
        </View>
        <Appload enable={this.state.dataLoad} />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: 'white',
  },
});

let keysArray = [
  'meta_collection_title',
  'meta_collection_description',
];
