import React from 'react';

const PrimaryButton = ({children, onClick}) => {
  return (
    <button
      onClick={onClick}
      className="px-4 py-2 bg-primary text-white border border-primary hover:text-primary  hover:bg-transparent duration-500 rounded-md  ">
      {children}
    </button>
  );
};

export default PrimaryButton;
