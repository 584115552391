import React from 'react';
import {button1} from '../../../StyleSheets/TextTitleStyleSheet';

const IconButton = ({icon, onClick, className}) => {
  return (
    <button className={className} onClick={onClick}>
      {icon}
    </button>
  );
};

export default IconButton;
