import ToastPopup from '../../../HelperClasses/AppLoaderClasses/ToastPopup';

export const checkSubscriptionForm = ({form_data}) => {
  if (
    form_data?.image_path === '' ||
    form_data?.image_path?.value?.replace(/\s/g, '').length <= 0
  ) {
    ToastPopup({type: 'error', message: 'Image is required'});
    return false;
  }
  if (
    form_data?.title === '' ||
    form_data?.title?.replace(/\s/g, '').length <= 0
  ) {
    ToastPopup({type: 'error', message: 'Title is required'});
    return false;
  }
  if (
    form_data?.description === '' ||
    form_data?.description?.replace(/\s/g, '').length <= 0
  ) {
    ToastPopup({type: 'error', message: 'Description is required'});
    return false;
  }
  if (form_data?.amount === '') {
    ToastPopup({type: 'error', message: 'Amount is required'});
    return false;
  }
  if (form_data?.sku === '' || form_data?.sku?.replace(/\s/g, '').length <= 0) {
    ToastPopup({type: 'error', message: 'SKU is required'});
    return false;
  }

  if (form_data?.allowed_listings === '' || form_data?.allowed_listings <= 0) {
    ToastPopup({
      type: 'error',
      message: 'Allowed Listings should be more than 0',
    });
    return false;
  }
  if (form_data?.fee_fixed_per_order === '') {
    ToastPopup({type: 'error', message: 'Fee Fixed Per Order is required'});
    return false;
  }
  if (form_data?.commission_percent_per_order === '') {
    ToastPopup({
      type: 'error',
      message: 'Commission Percent Per Order is required',
    });
    return false;
  }
  if (form_data?.currency_id === '' || form_data?.currency_id === null) {
    ToastPopup({type: 'error', message: 'Currency is required'});
    return false;
  }
  if (form_data?.expiry_days === '' || form_data?.expiry_days <= 0) {
    ToastPopup({type: 'error', message: 'Expiry Days is required'});
    return false;
  }
  if (
    form_data?.type === '' ||
    form_data?.type?.replace(/\s/g, '').length <= 0
  ) {
    ToastPopup({type: 'error', message: 'Type is required'});
    return false;
  }

  return true;
};
